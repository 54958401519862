import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  SkeletonCircle,
  SkeletonText, Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";

function YoutubeHome({formDetails, appLoading, ads, index, handleIndex, businessName, isHomeAd}) {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%' overflow="auto">
                <Box backgroundColor='gray.300' borderBottom='1px #e3e8f1 solid' textAlign='center' borderTopRadius="25px"
                     alignSelf="center">
                  <Flex justifyItems="center" px={3} justifyContent="space-between" py={3} backgroundColor="white"
                        borderTopRadius="25">
                    <Flex alignItems="center" justifyContent="center">
                      <Image src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="YouTube-Icon" height={8}
                             width={8}/>
                      <Box ml={1} fontWeight="bolder">YouTube</Box>
                    </Flex>
                    <Flex alignItems="center" justifyContent="center">
                      <SkeletonCircle size='6'/>
                    </Flex>
                  </Flex>
                </Box>
                <Box width="100%" height="50vh" bg='white' px={2}>
                  <Box display="flex" gap="5" padding='4' height="15%">
                    <SkeletonCircle size='10'/>
                    <SkeletonText mt='1' noOfLines={2} spacing='3' skeletonHeight='3' width="70%"/>
                  </Box>
                  <Box mt={4} height="75%">
                    {(ads && ads.length !== 0) ?
                      <Box overflow="hidden" width={"100%"} height={"100%"}>
                        <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                                onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                          {
                            ads.map((item) => {
                              return (
                                <SwiperSlide>
                                  <Image
                                    height="100%"
                                    width={"100%"}
                                    objectFit={"fill"}
                                    src={item?.imageUrl}
                                    className="previewImage"
                                  />
                                </SwiperSlide>
                              )
                            })
                          }
                        </Swiper>
                      </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                    }
                  </Box>
                </Box>
                {!isHomeAd && <Flex width="100%" bg="#c3ddf1" justifyContent="space-between" px={2} py={1} cursor="pointer"
                                    _hover={{textDecoration: 'underline'}}>
                  <Text color="#0077ff"
                        fontWeight="500">{youtubeShortsTypes.find((i) => i.key === formDetails.call_to_action?.type)?.name}</Text>
                  <Image src={`${process.env.PUBLIC_URL}/images/share_icon.svg`} height="20px" width="20px"
                         color="#0077ff"/>
                </Flex>}
                <Flex mx={3} mt={1} fontWeight="600" justifyContent="space-between" alignItems="center">
                  <Text>{formDetails.headlines[0]}</Text>
                  {!isHomeAd && <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Back Button" height={4}
                                       width={4}/>}
                </Flex>
                <Box mx={3} mb={2} fontSize="14px"> {formDetails.descriptions[0]} </Box>
                {!isHomeAd && <Flex justifyItems="baseline" m={3}>
                  <Box pr={2} fontWeight="bolder" fontSize="14px"> Ad </Box>
                  <Box fontSize="14px">
                    <li>{businessName}</li>
                  </Box>
                </Flex>}
                {isHomeAd && <Flex justifyContent="space-between" m={3}>
                  <Box fontSize="12px">
                    {businessName}
                    <Box fontWeight="bolder" fontSize="12px" bg="#eddb00" color='white' px={1} width="fit-content" borderRadius="3px"> Ad </Box>
                  </Box>
                  <button style={{color: "#006dff", backgroundColor: "#eaf6ff", border: "1px solid #006dff", padding: '0vh 3vh', fontSize: '14px', fontWeight: "500"}}>{youtubeShortsTypes.find((i) => i.key === formDetails.call_to_action?.type)?.name}</button>
                </Flex>}
                <Box height="25%" bg='gray.300' mx={2}/>
                <Flex justifyContent="space-around" p={4}>
                  <SkeletonCircle />
                  <SkeletonCircle />
                  <SkeletonCircle />
                  <SkeletonCircle />
                  <SkeletonCircle />
                </Flex>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default YoutubeHome;
