import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
  formDetails: {},
  mediaDetails: [],
  handlePreviewData: null,
};

export const socialDisplayImageSlice = createSlice({
  name: AD_UPLOAD_TYPE.SOCIAL_DISPLAY_IMAGE,
  initialState,
  reducers: {
    settingSocialDisplayImageForm: (state, action) => {
      state.formDetails = action.payload
    },
    settingSocialDisplayImageMedia: (state, action) => {
      state.mediaDetails = action.payload
    },
    settingSocialDisplayImagePreview: (state, action) => {
      state.handlePreviewData = action.payload
    },
    deleteAllSocialDisplayImageField: (state, action) => {
      state.formDetails = {};
      state.mediaDetails = [];
      state.handlePreviewData = null
    }
  },
});

export const { settingSocialDisplayImageForm, settingSocialDisplayImageMedia, settingSocialDisplayImagePreview, deleteAllSocialDisplayImageField} = socialDisplayImageSlice.actions;

// The reducer
export const socialDisplayImageReducer = socialDisplayImageSlice.reducer;
