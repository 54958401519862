import React from 'react';
import {Box} from "@chakra-ui/layout";
import {Flex, Image, SkeletonCircle, Spacer, Text} from "@chakra-ui/react";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import "./style.css";
import ReactPlayer from "react-player";

function SdImage250250(props) {
    const { formDetails, mediaDetails, handlePreviewData} = props;
    const selectedLogos = formDetails?.channelIcon?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads/` + formDetails.channelIcon.imageUrl :
        process.env.PUBLIC_URL + "/images/display.png";

    return (
        <Box bg='white' borderRadius='5' w='250px' h="250px">
            <Flex alignItems='center' gap='2' px='2' pt='2' h={"10%"}>
                <Image src={selectedLogos} height={8}
                       width={8} rounded="full"/>
                <Flex direction='column'>
                    <Text fontSize='12' fontWeight='600'>{formDetails.channel_name}</Text>
                    <Text fontSize='10' color='gray.500'>10.6k followers</Text>
                </Flex>
            </Flex>
            <Box mt='2' fontSize='13' px='2'>{formDetails.caption}</Box>
            {!props.video ?
                <Flex justifyContent="center" alignItems="center" h="60%">
                    <Image src={handlePreviewData[0].imageUrl} maxW='100%' maxH='100%' objectFit='cover' />
                </Flex> :
                <Box mt={3} height="50%" overflow="hidden" position="relative" backgroundImage={Array.isArray(handlePreviewData) && handlePreviewData[0]?.imageUrl} backgroundSize="cover">
                    <ReactPlayer className="background-react-player" width={"100%"} height={"100%"} url={handlePreviewData} playing loop style={{ objectFit: 'cover', position: 'absolute'}} />
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Box position="absolute" bottom={0} zIndex={3} px={2} bg="#ffffff90" width="100%">
                            <Text fontSize={12} fontWeight={600}>{formDetails.headline}</Text>
                            <Text fontSize={12}>{formDetails.description}</Text>
                        </Box>
                    </Box>
                </Box>
            }
            <Flex alignItems='center' justifyContent='between' textAlign='center' p='1' mx='1' h={"15%"}>
                <Flex alignItems='center' justifyContent='center' gap='2'>
                    <Flex alignItems='center'>
                        <Box zIndex='3'><Image src={`${process.env.PUBLIC_URL}/images/fb_like.png`} height={4} width={4}/></Box>
                        <Box marginStart='-1' zIndex='2'><Image src={`${process.env.PUBLIC_URL}/images/love.png`} height={4} width={4}/></Box>
                        <Box marginStart='-1' zIndex='1'><Image src={`${process.env.PUBLIC_URL}/images/surprised.png`} height={4} width={4} /></Box>
                    </Flex>
                    <Text fontSize='10' fontWeight='600' color='#a3a3a3'>11 &nbsp; 1 Comment</Text>
                </Flex>
                <Spacer />
                <Box bg='#e5e7ea' borderRadius='3' p='1'>
                    <Text fontSize='11' fontWeight='bold'>{youtubeShortsTypes.find((i) => i.key === formDetails?.type)?.name}</Text>
                </Box>
            </Flex>
        </Box>
    );
}

export default SdImage250250;
