import React from 'react';
import {Container, Grid, GridItem, Heading, Image, Skeleton, SkeletonCircle, Spacer, Text} from "@chakra-ui/react";
import {Box, Flex} from "@chakra-ui/layout";
import {PREVIEW_BG_COLOR} from "../../../../../../../../layout/constant/MenuList";
import {range} from "lodash";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";

function YoutubeSearchMonitor(props) {
    const {formDetails, appLoading, ads, index, handleIndex, businessName} = props;
    const arr = [...range(1, 20)]

    return (
        <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                <Heading fontSize="lg" my={4} color={"#757998"}>
                    Youtube - search
                </Heading>
                <Box
                    borderWidth="2px"
                    borderColor="gray"
                    p={10}
                    background={PREVIEW_BG_COLOR}
                    position={"relative"}
                >
                    <Container centerContent>
                        <Box minH='600px' h='600px' minW='900px' backgroundColor='white' border='3px #e3e8f1 solid' borderRadius="25" p='4'>
                            <Box h='100%' alignSelf="center" backgroundColor='white' border='3px #e3e8f1 solid' borderRadius="25" overflow='hidden'>
                                <Box bg='' borderBottom='3px #e3e8f1 solid' p='3'>
                                    <Flex alignItems='center' gap='3'>
                                        <Image src={`${process.env.PUBLIC_URL}/images/three_line_icon.svg`} alt="Back Button" height={8}
                                               width={8} pl={2} />
                                        <Flex alignItems="center" justifyContent="center">
                                            <Image src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="YouTube-Icon" height={8}
                                                   width={8}/>
                                            <Box ml={1} fontWeight="bolder">YouTube</Box>
                                        </Flex>
                                        <Flex alignItems='center'>
                                            <Box bg='' h='30px' w='240px' border='1px #e3e8f1 solid' borderLeftRadius='7'/>
                                            <Box bg='gray.300' h='30px' w='45px' borderRightRadius='7'/>
                                        </Flex>
                                        <SkeletonCircle size="6"/>
                                        <Spacer />
                                        <SkeletonCircle size="5"/>
                                    </Flex>
                                </Box>
                                <Grid
                                    templateColumns='repeat(10, 1fr)'
                                    gap={4}
                                    overflow='hidden'
                                >
                                    <GridItem colSpan={2} >
                                        {[1,2,3,4].map((_, index) => (
                                            <Flex key={index} alignItems='center' gap='3' p='2'>
                                                <SkeletonCircle size="4"/>
                                                <SkeletonCircle size='4' flex='1'/>
                                            </Flex>
                                        ))}
                                        <hr/>
                                        {[1,2,3,4,5,6].map((_, index) => (
                                            <Flex key={index} alignItems='center' gap='3' p='2'>
                                                <SkeletonCircle size="4"/>
                                                <SkeletonCircle size='4' flex='1'/>
                                            </Flex>
                                        ))}
                                        <hr/>
                                        {[1,2,3,4,5,6,7,8].map((_, index) => (
                                            <Flex key={index} alignItems='center' gap='3' p='2'>
                                                <SkeletonCircle size="4"/>
                                                <SkeletonCircle size='4' flex='1'/>
                                            </Flex>
                                        ))}
                                        <hr/>
                                    </GridItem>
                                    <GridItem colSpan={8} p='4'>
                                        <Box>
                                            <Flex gap='3' py='15px'>
                                                <Skeleton height='16px' w='16px'/>
                                                <SkeletonCircle size='4' flex='1' />
                                                <Box flex='1'></Box>
                                            </Flex>
                                            <hr/>
                                        </Box>
                                        <Flex direction='column' gap='3' mt='5'>
                                            <Flex gap='2'>
                                                {(ads && ads.length !== 0) ?
                                                    <Box overflow="hidden" width={"150px"} height={"100%"} borderRadius='5'>
                                                        <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                                                                onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                                                            {
                                                                ads.map((item) => {
                                                                    return (
                                                                        <SwiperSlide>
                                                                            <Image
                                                                                height="90px"
                                                                                width="150px"
                                                                                objectFit={"fill"}
                                                                                src={item?.imageUrl}
                                                                                className="previewImage"
                                                                            />
                                                                        </SwiperSlide>
                                                                    )
                                                                })
                                                            }
                                                        </Swiper>
                                                    </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                                                }
                                                <Flex direction='column'>
                                                    <Box fontSize='14px' fontWeight="600"> {Array.isArray(formDetails.headlines) ? formDetails.headlines[0] : formDetails.headline} </Box>
                                                    <Box fontSize="12px"> {Array.isArray(formDetails.descriptions) ? formDetails.descriptions[0] : formDetails.description} </Box>
                                                    <Flex gap='1' fontSize="12px"> <Text as='b'>Sponsored </Text><Text>&bull; {businessName}</Text></Flex>
                                                </Flex>
                                            </Flex>

                                            {arr.map((_, index) => (
                                                <Flex key={index} gap='2' alignItems='center'>
                                                    <Skeleton height='90px' borderRadius='5' w='150px'/>
                                                    <Flex direction='column' gap='2'>
                                                        <Skeleton height='15px' w='80px' borderRadius='4'/>
                                                        <Skeleton height='15px' w='170px' borderRadius='4'/>
                                                        <Skeleton height='15px' w='330px' borderRadius='4'/>
                                                        <Skeleton height='15px' w='220px' borderRadius='4'/>
                                                    </Flex>
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </GridItem>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </GridItem>
        </Grid>
    );
}

export default YoutubeSearchMonitor;
