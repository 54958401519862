import { createSlice } from "@reduxjs/toolkit";
import {adUploadSlice} from "../../../../../../../../store/adUploads";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const demandGenImageSlice = createSlice({
    name: AD_UPLOAD_TYPE.DEMAND_GEN_IMAGE,
    initialState,
    reducers: {
        settingDemandGenFormDetails: (state, action) => {
            state.formDetails = action.payload
        },
        settingDemandGenMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingDemandGenPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllDemandGenImageFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { deleteAllDemandGenImageFields,settingDemandGenFormDetails,settingDemandGenMedia,settingDemandGenPreview} = demandGenImageSlice.actions;

// The reducer
export const demandGenImageReducer = demandGenImageSlice.reducer;
