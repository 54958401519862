import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {Form, FormikProvider, useFormik} from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading, Text,
} from "@chakra-ui/react";
import {InputControl, SelectControl} from "formik-chakra-ui";
import {
  demandGenAdValueLengths,
  facebookVideoAdValueLengths,
  youtubeShortsValueLengths,
} from "../../../../../constant/InitialValues";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {
  settingDemandGenVideoForm
} from "./store";
import TooltipComponent from "../../TooltipComponent";
import {Input} from "@chakra-ui/input";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import {CloseIcon} from "@chakra-ui/icons";
import WithAppContext from "../../../../../../../helpers/appContext";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";

const initialValue = {
  adName: "",
  displayName: "",
  long_headlines: [""],
  headlines: [""],
  descriptions: [""],
  link: "",
  type: "",
  channel_name: "",
  channel_icon: null,
};

function DemandGenVideoAdDetails({
                                   activeStep,
                                   steps,
                                   prevStep,
                                   nextStep,
                                   label,
                                   autoFillData,
                                   context
                                 }) {
  const {id, adUploadId} = useParams();
  const {mutateAsync} = useUploadImage();

  const clientId = useSelector((state) => state.client.clientId);
  const {formDetails} = useSelector((state) => state.demandGenVideoAd);

  const {data, refetch} = useGetAdUpload(clientId, id, adUploadId);
  const [youtubeVideoLinkInputs, setYoutubeVideoLinkInputs] = useState([""]);
  const [longHeadlinesInputs, setLongHeadlinesInputs] = useState([""]);
  const [headlinesInputs, setHeadlinesInputs] = useState([""]);
  const [descriptionsInputs, setDescriptionsInputs] = useState([""]);
  const {ErrorToaster} = context;

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingDemandGenVideoForm({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          descriptions: data?.adUpload?.detail?.descriptions,
          headlines: data?.adUpload?.detail?.headlines,
          long_headlines: data?.adUpload?.detail?.long_headlines,
          link: data?.adUpload?.detail?.link,
          type: data?.adUpload?.detail?.call_to_action?.type,
          youtubeVideoLink: data?.adUpload?.detail?.youtubeVideoLink,
          channel_name: data?.adUpload?.detail?.channel_name,
          channelIcon: data?.adUpload?.detail?.channel_icon,
          channel_icon: data?.adUpload?.detail?.channel_icon?.files,
        })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    adName: yup.string()
      .trim()
      .min(4)
      .max(255)
      .required()
      .matches(
        /^[!@$%^&#()-=+{};:,<.>0-9a-zA-Z\s]+$/i,
        "Emojis are not allowed as ad Name"
      )
      .label("Ad name"),
    headlines: yup
      .array()
      .of(
        yup.string().trim().min(3).max(40).required().label("Headlines")
      )
      .min(1, "At least one headlines is required")
      .max(5, "Maximum of 5 headlines allowed")
      .required()
      .label("Headlines"),
    channel_name: yup.string()
      .trim()
      .max(25)
      .optional()
      .label("Channel Name"),
    long_headlines: yup
      .array()
      .of(
        yup.string().trim().min(3).max(90).required().label("Long Headlines")
      )
      .min(1, "At least one long headlines is required")
      .max(5, "Maximum of 5 long headlines allowed")
      .required()
      .label("Long Headlines"),
    descriptions: yup
      .array()
      .of(
        yup.string().trim().min(3).max(90).required().label("Descriptions")
      )
      .min(1, "At least one descriptions is required")
      .max(5, "Maximum of 5 descriptions allowed")
      .required()
      .label("Descriptions"),
    link: yup
      .string()
      .trim()
      .required("Landing page url is required.")
      .max(255)
      .matches(
         /https?:\/\/(www\.)?[-a-z0-9@:%._\+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-a-z0-9()@:%_\+.~#?&//=]*)/,
         "Landing page url is not valid and must contain only lowercase letters!"
      ).label('Landing page url'),
    youtubeVideoLink: yup
      .array()
      .of(
        yup.string().trim().min(3).max(125).required().label("Youtube Video Link")
      )
      .min(1, "At least one youtube video link is required")
      .max(5, "Maximum of 5 youtube video links allowed")
      .required()
      .label("Youtube Video Links"),
    channel_icon: yup
      .mixed()
      .test(
        "fileType",
        "Only image files are allowed",
        (value) => {
          if (!value) return true; // Allow empty value
          const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
          return allowedTypes.includes(value?.type || value?.mimetype);
        }
      )
      .nullable(true)
      .label("Channel Icon"),
    type: yup.string().max(125).optional().label("Call to action").transform((value) => (value ? value.toLowerCase() : value)),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (values.channel_icon?.name) {
        await mutateAsync(
          {
            clientId: clientId,
            campaignBriefId: id,
            adFile: [values.channel_icon],
            type: "youtubeShortsChannelIcon",
          }, {
            onSuccess: (data, variables, context) => {
              dispatch(
                settingDemandGenVideoForm({
                  ...data?.adUpload?.detail,
                  ...values,
                  channelIcon: data.fileInfoList[0],
                  channel_icon: null,
                })
              );
            },
            onError: (err) => {
              console.log('error OnError', err)
            }
          });
      } else {
        dispatch(
          settingDemandGenVideoForm({
            ...data?.adUpload?.detail,
            ...values,
            channel_icon: null
          })
        );
      }
      nextStep();
    },
  });

  const {errors, values, touched, handleSubmit, isValid, setFieldValue} =
    formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("headlines", formDetails.headlines || autoFillData?.headlines || [""]);
    setFieldValue("long_headlines", formDetails.long_headlines || autoFillData?.long_headlines || [""]);
    setFieldValue("descriptions", formDetails.descriptions || autoFillData?.descriptions || [""]);
    setFieldValue("youtubeVideoLink", formDetails.youtubeVideoLink || autoFillData?.youtubeVideoLink || [""]);
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
    setFieldValue("channel_name", formDetails.channel_name || autoFillData?.channel_name || "");
    setFieldValue("channel_icon", formDetails.channelIcon?.files || "");
    setFieldValue("channelIcon", formDetails.channelIcon || "");
    setFieldValue("type", formDetails.type || autoFillData?.type || "");
    setYoutubeVideoLinkInputs(formDetails.youtubeVideoLink || autoFillData?.youtubeVideoLink || [""])
    setDescriptionsInputs(formDetails.descriptions || [""])
    setHeadlinesInputs(formDetails.headlines || [""])
    setLongHeadlinesInputs(formDetails.long_headlines || [""])
  }, [formDetails, autoFillData]);

  const handleYoutubeVideoLinkChange = (e, index) => {
    const newInputs = [...youtubeVideoLinkInputs];
    newInputs[index] = e.target.value;
    setYoutubeVideoLinkInputs(newInputs);
  };

  const handleAddYoutubeVideoLinkInput = () => {
    if (youtubeVideoLinkInputs.length < 5) {
      setYoutubeVideoLinkInputs([...youtubeVideoLinkInputs, ""]);
    }
  };

  const handleRemoveYoutubeVideoLinkInput = (indexToRemove) => {
    const updatedInputs = youtubeVideoLinkInputs.filter((_, index) => index !== indexToRemove);
    setYoutubeVideoLinkInputs(updatedInputs);
    setYoutubeVideoLinkInputs(updatedInputs)
  };

  useEffect(() => {
    setFieldValue("youtubeVideoLink", youtubeVideoLinkInputs);
  }, [youtubeVideoLinkInputs]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFieldValue('channel_icon', file);
  };

  const handleLongHeadlinesChange = (e, index) => {
    const newInputs = [...longHeadlinesInputs];
    newInputs[index] = e.target.value;
    setLongHeadlinesInputs(newInputs);
  };

  const handleAddLongHeadlinesInput = () => {
    if (longHeadlinesInputs.length < 5) {
      setLongHeadlinesInputs([...longHeadlinesInputs, ""]);
    }
  };

  const handleRemoveLongHeadlinesInput = (indexToRemove) => {
    const updatedInputs = longHeadlinesInputs.filter((_, index) => index !== indexToRemove);
    setLongHeadlinesInputs(updatedInputs);
  };

  const handleHeadlinesChange = (e, index) => {
    const newInputs = [...headlinesInputs];
    newInputs[index] = e.target.value;
    setHeadlinesInputs(newInputs);
  };

  const handleAddHeadlinesInput = () => {
    if (headlinesInputs.length < 5) {
      setHeadlinesInputs([...headlinesInputs, ""]);
    }
  };

  const handleRemoveHeadlinesInput = (indexToRemove) => {
    const updatedInputs = headlinesInputs.filter((_, index) => index !== indexToRemove);
    setHeadlinesInputs(updatedInputs);
  };

  const handleDescriptionsChange = (e, index) => {
    const newInputs = [...descriptionsInputs];
    newInputs[index] = e.target.value;
    setDescriptionsInputs(newInputs);
  };

  const handleAddDescriptionsInput = () => {
    if (descriptionsInputs.length < 5) {
      setDescriptionsInputs([...descriptionsInputs, ""]);
    }
  };

  const handleRemoveDescriptionsInput = (indexToRemove) => {
    const updatedInputs = descriptionsInputs.filter((_, index) => index !== indexToRemove);
    setDescriptionsInputs(updatedInputs);
  };

  useEffect(() => {
    setFieldValue("long_headlines", longHeadlinesInputs);
  }, [longHeadlinesInputs]);

  useEffect(() => {
    setFieldValue("headlines", headlinesInputs);
  }, [headlinesInputs]);

  useEffect(() => {
    setFieldValue("descriptions", descriptionsInputs);
  }, [descriptionsInputs]);

  console.log("Errors", errors)
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{base: 6, lg: 4}}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 255 characters",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />
                    <CircularProgress
                      max={youtubeShortsValueLengths.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        youtubeShortsValueLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        youtubeShortsValueLengths?.adName
                          ? youtubeShortsValueLengths?.adName -
                          values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Headlines</FormLabel>
                {headlinesInputs && headlinesInputs.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`headlines${index}`}
                          name={`headlines${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Headlines ${index + 1} - max 40 characters`
                          }}
                          placeholder={`Headlines ${index + 1} - max 40 characters`}
                          onChange={(e) => handleHeadlinesChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={demandGenAdValueLengths?.headlines}
                          value={text.length}
                          color={
                            text.length > demandGenAdValueLengths?.headlines
                              ? "red.400"
                              : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            {text.length > demandGenAdValueLengths?.headlines
                              ? demandGenAdValueLengths?.headlines - text.length
                              : text.length}
                          </CircularProgressLabel>
                        </CircularProgress>
                        {headlinesInputs.length > 1 && (
                          <Button onClick={() => handleRemoveHeadlinesInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )
                })}
                {headlinesInputs.length < 5 && (
                  <Button onClick={handleAddHeadlinesInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Descriptions</FormLabel>
                {descriptionsInputs && descriptionsInputs.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`descriptions${index}`}
                          name={`descriptions${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Descriptions ${index + 1} (Optional) - max 90 characters`
                          }}
                          placeholder={`Descriptions ${index + 1} (Optional) - max 90 characters`}
                          onChange={(e) => handleDescriptionsChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={demandGenAdValueLengths?.descriptions}
                          value={text.length}
                          color={
                            text.length > demandGenAdValueLengths?.descriptions
                              ? "red.400"
                              : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            {text.length > demandGenAdValueLengths?.descriptions
                              ? demandGenAdValueLengths?.descriptions - text.length
                              : text.length}
                          </CircularProgressLabel>
                        </CircularProgress>
                        {descriptionsInputs.length > 1 && (
                          <Button onClick={() => handleRemoveDescriptionsInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )
                })}
                {descriptionsInputs.length < 5 && (
                  <Button onClick={handleAddDescriptionsInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Long Headlines</FormLabel>
                {longHeadlinesInputs && longHeadlinesInputs.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`long_headlines${index}`}
                          name={`long_headlines${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Long Headlines ${index + 1} - max 90 characters`
                          }}
                          placeholder={`Long Headlines ${index + 1} - max 90 characters`}
                          onChange={(e) => handleLongHeadlinesChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={demandGenAdValueLengths?.long_headlines}
                          value={text.length}
                          color={
                            text.length > demandGenAdValueLengths?.long_headlines
                              ? "red.400"
                              : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            {text.length > demandGenAdValueLengths?.long_headlines
                              ? demandGenAdValueLengths?.long_headlines - text.length
                              : text.length}
                          </CircularProgressLabel>
                        </CircularProgress>
                        {longHeadlinesInputs.length > 1 && (
                          <Button onClick={() => handleRemoveLongHeadlinesInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )
                })}
                {longHeadlinesInputs.length < 5 && (
                  <Button onClick={handleAddLongHeadlinesInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Youtube Video Links</FormLabel>
                {youtubeVideoLinkInputs && youtubeVideoLinkInputs.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`youtubeVideoLink${index}`}
                          name={`youtubeVideoLink${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Youtube Video Link ${index + 1} - max 125 characters`
                          }}
                          placeholder={`Youtube Video Link ${index + 1} - max 125 characters`}
                          onChange={(e) => handleYoutubeVideoLinkChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={facebookVideoAdValueLengths?.youtubeVideoLink}
                          value={text.length}
                          color={
                            text.length > facebookVideoAdValueLengths?.youtubeVideoLink
                              ? "red.400"
                              : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            {text.length > facebookVideoAdValueLengths?.youtubeVideoLink
                              ? facebookVideoAdValueLengths?.youtubeVideoLink - text.length
                              : text.length}
                          </CircularProgressLabel>
                        </CircularProgress>
                        {youtubeVideoLinkInputs.length > 1 && (
                          <Button onClick={() => handleRemoveYoutubeVideoLinkInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )
                })}
                {youtubeVideoLinkInputs.length < 5 && (
                  <Button onClick={handleAddYoutubeVideoLinkInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="channel_name" mb={0}>Channel Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="channel_name"
                      name="channel_name"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Channel Name (Optional) - max 25 characters",
                      }}
                      onChange={(e) =>
                        setFieldValue("channel_name", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={demandGenAdValueLengths?.channel_name}
                      value={values?.channel_name?.length}
                      color={
                        values?.channel_name?.length >
                        demandGenAdValueLengths?.channel_name
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.channel_name?.length >
                        demandGenAdValueLengths?.channel_name
                          ? demandGenAdValueLengths?.channel_name -
                          values?.channel_name?.length
                          : values?.channel_name?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="channel_icon" mb={0}>Channel Icon</FormLabel>
                  <div className="input-box">
                    <Box py={2}>
                      <Input
                        id="channel_icon"
                        name="channel_icon"
                        type="file"
                        accept="image/*"
                        display="none"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="channel_icon" style={{cursor: "pointer"}}>
                        <Box
                          as="span"
                          padding="8px 16px"
                          border="1px solid #ccc"
                          borderRadius="4px"
                          fontSize="0.825rem"
                          color="#706f7b"
                          _hover={{borderColor: "blue.400"}}
                        >
                          Upload Channel Icon
                        </Box>
                      </label>
                    </Box>
                    {values.channel_icon && (
                      <>
                        <Text>{values.channel_icon.name || values.channel_icon.originalname}</Text>
                        <Grid justifyContent="center">
                          <CloseIcon
                            cursor="pointer"
                            className="close-icon"
                            height={'12px'}
                            width={'12px'}
                            onClick={() => {
                              setFieldValue('channel_icon', null);
                              setFieldValue('channelIcon', null);
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </div>
                  {errors.channel_icon && <Text color='#E53E3E' fontSize="14px" mt="8px">{errors.channel_icon}</Text>}
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL",
                      }}
                      onChange={(e) =>
                        setFieldValue("link", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={youtubeShortsValueLengths?.link}
                      value={values?.link?.length}
                      color={
                        values?.link?.length >
                        youtubeShortsValueLengths?.link
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.link?.length >
                        youtubeShortsValueLengths?.link
                          ? youtubeShortsValueLengths?.link -
                          values?.link?.length
                          : values?.link?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="type" mb={0}>Call to Action (Optional)</FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="type"
                      name="type"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("type", e.target.value);
                      }}
                    >
                      {youtubeShortsTypes.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            <Button
              mr={4}
              colorScheme="green"
              type="submit"
              variant="solid"
              borderRadius={0}
              px={10}
              isDisabled={Object.keys(errors).length > 0}
            >
              Next
            </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default WithAppContext(DemandGenVideoAdDetails);
