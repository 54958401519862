import React from "react";
import { ViewMode } from "gantt-task-react";
import { Button, Flex, Box, Checkbox, Text } from "@chakra-ui/react";

export const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
}) => {
  return (
    <Flex justify="flex-end" gap={2}>
      <Button size="xs" onClick={() => onViewModeChange(ViewMode.Hour)}>
        Hour
      </Button>
      <Button size="xs" onClick={() => onViewModeChange(ViewMode.QuarterDay)}>
        Quarter of Day
      </Button>
      <Button size="xs" onClick={() => onViewModeChange(ViewMode.HalfDay)}>
        Half of Day
      </Button>
      <Button size="xs" onClick={() => onViewModeChange(ViewMode.Day)}>
        Day
      </Button>
      <Button size="xs" onClick={() => onViewModeChange(ViewMode.Week)}>
        Week
      </Button>
      <Button size="xs" onClick={() => onViewModeChange(ViewMode.Month)}>
        Month
      </Button>
      <Button size="xs" onClick={() => onViewModeChange(ViewMode.Year)}>
        Year
      </Button>
      <Button size="xs" onClick={() => onViewModeChange(ViewMode.QuarterYear)}>
        Quarter Year
      </Button>
      <Box size="xs" ml={5} display="flex" alignItems="center">
        <Checkbox
          isChecked={isChecked}
          onChange={() => onViewListChange(!isChecked)}
          size="sm"
          colorScheme="blue"
        />
        <Text isTruncated ml={2}>
          Show Task List
        </Text>
      </Box>
    </Flex>
  );
};
