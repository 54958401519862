import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Switch,
  Text,
  useToast,
  Flex,
  Box, Tag, TagLeftIcon,
} from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {CheckCircleIcon, SearchIcon, SmallAddIcon, SmallCloseIcon, WarningIcon} from "@chakra-ui/icons";
import { FiEye } from "react-icons/fi";
import Datatable from "../../../../components/Datatable";
import { useAdUploadList } from "../../../../hooks/campaign-briefs/useAdUploadList";
import "../../style/AdUploadList.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import DeleteModel from "../../../../components/PopupModal/DeleteModel";
import { useDeleteAdUpload } from "../../../../hooks/campaign-briefs/useDeleteAdUpload";
import { titleCase } from "../../../../helpers/utils";
import "./StepByStepAdUploadComponent/tooltipstyle.css";
import { useRecoilValue } from "recoil";
import { profile } from "../../../../atoms/authAtom";
import {
  settingBusinessDetails,
  settingCampaignBudget,
  settingCampaignDetails,
  settingCampaignObjective,
  settingDemographics,
  settingDemographyDetails,
  settingMediaStrategies,
  settingMediaStrategyDetails,
  settingNotes,
} from "../../../../store/adCampaign";
import axios from "../../../../helpers/axios";
import { useGetSingleCampaign } from "../../../../hooks/campaign-briefs/useGetSingleCampaign";
import GaugeChart from "react-gauge-chart";
import MyCalendarTimeLine from "./CreativeCalendarTimeline/CreativeTimelineView";
import {AD_UPLOAD_STATUS} from "../../../../constant";
import {TagLabel} from "@chakra-ui/tag";
import TickIcon from "../../../../assets/images/tick-circle.svg";
import CrossIcon from "../../../../assets/images/cross-circle.svg";
import {useUpdateAdUploadStatus} from "../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import ApproveModel from "../../../../components/PopupModal/ApproveModel";
import * as PropTypes from "prop-types";
import AdApproveModel from "../../../../components/PopupModal/AdApproveModel";
import WithAppContext from "../../../../helpers/appContext";
import RowButton from "./RowButton";


AdApproveModel.propTypes = {
  onDestroy: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.any,
  status: PropTypes.any
};
const StepByStepAdUpload = ({context}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const { data: dataOfCampaign, refetch: refetchDataOfCampaign } =
    useGetSingleCampaign(clientId, id);
  const [isClient, setIsClient] = useState(true);

  const { data, refetch } = useAdUploadList(clientId, id);
  const [isAdApproveModalOpen, setAdApproveModal] = useState(false);
  const [adName, setAdName] = useState(null);
  const [adUploadId, setAdUploadId] = useState(null);
  const [statusToUpdate, setStatusToUpdate] = useState(null);
  const { mutate: updateAdUploadStatus } = useUpdateAdUploadStatus();
  const { data: campaignData, refetch: campaignRefetch } = useGetSingleCampaign(
    clientId,
    id
  );

  const dispatch = useDispatch();

  const [search, setSearch] = useState();
  const [adUploadList, setAdUploadList] = useState();
  const {
    access_info: { roles },
  } = useRecoilValue(profile);
  const [viewTimeline, setViewTimeline] = useState(false);

  const handleToggleViewMode = () => {
    setViewTimeline(!viewTimeline);
  };
  const [allIds, setAllIds] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const allIdsParam = searchParams.get('allIds');

    if (allIdsParam) {
      const campaignIds = allIdsParam.split(',');
      setAllIds(campaignIds);
    }
  }, []);

  useEffect(() => {
    setIsClient(roles.includes("Client"));
  }, [roles]);

  useEffect(() => {
    refetch();
    campaignRefetch();
    refetchDataOfCampaign();
  }, [clientId, viewTimeline]);

  useEffect(() => {
    if (campaignData && campaignData.campaign) {
      setAllData();
      getSingleBusiness(campaignData?.campaign?.business_id);
    }
  }, [campaignData]);

  useEffect(() => {
    setAdUploadList(data?.adUploads);
  }, [data]);

  useEffect(() => {
    if (search?.trim()) {
      const searchedAdUploads = adUploadList.filter((el) => {
        if (el?.ad_upload_type?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.description?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.message?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.name?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.status?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.created_at?.toLowerCase().includes(search.trim())) {
          return true;
        }
      });
      setAdUploadList(searchedAdUploads);
    } else {
      setAdUploadList(data?.adUploads);
    }
  }, [search]);

  const handleRedirectUrl = (data) => {
    const adUploadId = data?.row.original.id;
    return `/campaign-briefs/${id}/edit-adUpload/${adUploadId}`;
  };

  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [adsName, setAdsName] = useState(null);
  const [deleteAdUploadId, setDeleteAdUploadId] = useState(null);

  function handleDeleteModel(adName, adId) {
    setDeleteModal(true);
    setAdsName(adName);
    setDeleteAdUploadId(adId);
  }

  function launchAd(adId) {
    console.log("Launch Ad id Working ");
    axios
        .post(`/client/${clientId}/${adId}/launchAd`, {
          withCredentials: false,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("API called")
          }
        })
        .catch((err) => {
          return err.response;
        });
  }

  function onDestroyModel() {
    setDeleteModal(false);
    setAdApproveModal(false);
  }

  const { data: deleteData, refetch: refetchDelete } = useDeleteAdUpload(
    clientId,
    id,
    deleteAdUploadId
  );
  let toast = useToast();

  const onCloseDeleteModal = () => {
    refetchDelete()
      .then((resp) => {
        if (resp?.data?.status === 200) {
          refetch();
          toast({
            isClosable: true,
            status: "success",
            variant: "top-accent",
            position: "top-right",
            description: "Deleted Successfully!",
          });
          setDeleteModal(false);
        } else {
          setDeleteModal(false);
          toast({
            isClosable: true,
            status: "error",
            variant: "top-accent",
            position: "top-right",
            description: resp?.data?.data?.message || "Something goes wrong!",
          });
        }
      })
      .catch((err) => {
        setDeleteModal(false);
      });
  };

  function setAllData() {
    const { detail } = campaignData.campaign;
    dispatch(settingCampaignDetails(detail.campaignDetails));
    dispatch(settingCampaignObjective(detail.objective));
    dispatch(settingCampaignBudget(detail.campaignBudget));
    dispatch(settingDemographics(detail.demographics));
    dispatch(settingMediaStrategies(detail.mediaStrategies));
    dispatch(settingDemographyDetails(detail.demographicDetails));
    dispatch(settingMediaStrategyDetails(detail.mediaStrategyDetails));
    dispatch(settingNotes(detail.notes));
  }

  function getSingleBusiness(id) {
    axios
      .get(`/client/business/${id}`, {
        withCredentials: false,
      })
      .then((res) => {
        if (res.status === 200 && !_.isEmpty(res.data.data.business)) {
          dispatch(settingBusinessDetails(res.data.data.business));
        }
      })
      .catch((err) => {
        return err.response;
      });
  }

  const onCloseAdApproveModal = () => {
    updateAdUploadStatus({
      clientId,
      campaignId: id,
      adUploadId: adUploadId,
      status: statusToUpdate,
    }, {
      onSuccess: () => {
        refetch();
        setAdApproveModal(false);
      },
      onError: () => {
        refetch();
        setAdApproveModal(false);
      },
    })
  };

  function handleAdApproveModel(adName, adUploadId, status) {
    console.log('Name', adName)
    setAdApproveModal(true);
    setAdName(adName);
    setAdUploadId(adUploadId);
    setStatusToUpdate(status);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Creative Name",
        accessor: "name",
      },
      {
        Header: "Channel",
        accessor: "detail.channel",
        Cell: (data) => {
          const arr = [
            "meta",
            "pinterest",
            "google",
            "instagram",
            "facebook",
            "fb",
            "tiktok",
            "display",
            "discovery",
            "youtube_shorts",
            "demand_gen",
            "audio",
            "performance_max",
            "social_display",
            "microsoft",
            "linkedin",
            "snapchat",
          ];
          if (data.value && arr) {
            for (let el of arr) {
              if (data.row.original.detail.channel.includes(el)) {
                const title =
                  el === "fb" ? "facebook" : el === "dv360" ? "display" : el;
                return (
                  <div className="channel-type">
                    <Image
                      src={`/images/${title}.png`}
                      boxSize="22px"
                      objectFit="cover"
                      alt="upload-icon"
                    />
                    <span>{titleCase(title).replace("_", " ")}</span>
                  </div>
                );
              }
            }
          }
        },
      },
      {
        Header: "Creative Type",
        accessor: "detail.creative.creativeType",
        Cell: (data) => {
          const creative = data.row.original.detail?.creative?.creativeType;
          let suffix;
          if (creative?.split("_" || " ")?.length === 1) {
            suffix =
              creative?.split(" ")[0] === "dv360"
                ? "Static Image"
                : creative?.split(" ")[0] === "AUDIO"
                  ? "Audio Ad"
                    : creative?.split(" ")[0] === "MICROSOFT"
                      ? "Microsoft Ad" : "Google Display Ad";
            return suffix;
          } else {
            let parts = creative?.split("_");
            let suffix = parts && parts[parts.length - 1].toUpperCase();
            switch (suffix) {
              case "AUDIO":
                return "Display Audio";
              case "YOUTUBE":
                return "Youtube Video";
              case "IMAGE":
                return "Static Image";
              case "VIDEO":
                return "Video";
              case "CAROUSEL":
                return "Carousel";
              case "BOTH":
                return "Image + Video";
              default:
                return "Static Image";
            }
          }

          // return <>{data.row.original.ad_upload_type === "DV360"
          //     ? "DisplayImage" : data.row.original.ad_upload_type === "DV360Video" ? "DisplayVideo" : data.row.original.ad_upload_type === "DV360Audio" ? "DisplayAudio" : data.row.original.ad_upload_type === "DV360YoutubeVideo" ? "DisplayYoutubeVideo"
          //         : data.row.original.ad_upload_type}</>
        },
      },
      {
        Header: "Targeting Method",
        accessor: "targetingMethod",
        Cell: (data) => {
          const arr1 =
            data.row?.original?.detail?.creative?.targetingMethod?.map(
              (e) => e.label
            );
          return arr1?.join(" , ");
        },
      },
      {
        Header: "Status",
        accessor: "detail.creative.pendingDays",
        Cell: (data) => {
          return (
            <div>
              <GaugeChart
                style={{ width: "80px" }}
                id="gauge-chart3"
                nrOfLevels={3}
                colors={["red", "orange", "green"]}
                percent={data.row.original.detail?.creative?.pendingFlightDays}
                textColor={"black"}
                hideText={true}
              />
            </div>
          );
        },
      },
      // {
      //     Header: "Status",
      //     accessor: "status",
      //     Cell: (data) => {
      //         return (
      //             <>
      //                 {AD_UPLOAD_STATUS.CREATED === data.value.toUpperCase() &&
      //                 <Tag size="lg" variant='subtle' color={"white"} backgroundColor={"#FFC108"} w="100%"
      //                      borderRadius={100}>
      //                     <TagLeftIcon boxSize='16px' as={WarningIcon}/>
      //                     <TagLabel>Action Needed</TagLabel>
      //                 </Tag>
      //                 }
      //                 {AD_UPLOAD_STATUS.APPROVED === data.value.toUpperCase() &&
      //                 <Tag size="lg" variant='subtle' color="white" backgroundColor={"#63C68B"} w="100%"
      //                      borderRadius={100}>
      //                     <TagLeftIcon boxSize='16px' as={CheckCircleIcon}/>
      //                     <TagLabel>Approved</TagLabel>
      //                 </Tag>
      //                 }
      //                 {AD_UPLOAD_STATUS.REJECTED === data.value.toUpperCase() &&
      //                 <Tag size="lg" variant='subtle' backgroundColor="#A7A9BD" color="white" w="100%"
      //                      borderRadius={100}>
      //                     <TagLeftIcon boxSize='16px' as={SmallCloseIcon} backgroundColor={"white"}
      //                                  color={"#A7A9BD"} borderRadius={100}/>
      //                     <TagLabel>Closed</TagLabel>
      //                 </Tag>
      //                 }
      //             </>
      //         );
      //     },
      // },
      // {
      //     Header: "Added on",
      //     accessor: "created_at",
      //     Cell: (data) => {
      //         return moment(data.row.original.created_at).format("YYYY-MM-DD")
      //     },
      // },
      {
        Header: "From",
        accessor: "detail.creative.startDate",
        Cell: (data) => {
          return moment(data.row.original.detail?.creative?.startDate).format(
            "YYYY-MM-DD"
          );
        },
      },
      {
        Header: "To",
        accessor: "detail.creative.endDate",
        Cell: (data) => {
          return moment(data.row.original.detail?.creative?.endDate).format(
            "YYYY-MM-DD"
          );
        },
      },
      {
        Header: "Approval",
        accessor: "status",
        Cell: (data) => {
          switch (data.row.original.status.toUpperCase()) {
            case AD_UPLOAD_STATUS.CREATED:
              const adId = data.row.original.id
              const adName = data.row.original.name
              return (
                  <HStack>
                    <Image src={TickIcon} boxSize="7"
                           onClick={() => handleAdApproveModel(adName, adId, _.capitalize(AD_UPLOAD_STATUS.APPROVED))} cursor="pointer"
                    />
                    <Image src={CrossIcon} boxSize="6"
                           onClick={() => handleAdApproveModel(adName, adId, _.capitalize(AD_UPLOAD_STATUS.REJECTED))} cursor="pointer"
                    />
                  </HStack>
              )
            case AD_UPLOAD_STATUS.APPROVED:
              return (
                  <Tag
                      backgroundColor={"#63C68B"}
                      size="md"
                      color={"white"}
                      borderRadius={100}
                  >
                    <TagLeftIcon boxSize="3" as={CheckCircleIcon} />
                    <TagLabel fontSize="xx-small">Approved</TagLabel>
                  </Tag>
              )
            case AD_UPLOAD_STATUS.REJECTED:
              return (
                  <Tag
                      backgroundColor="#A7A9BD"
                      size="md"
                      color={"white"}
                      borderRadius={100}
                  >
                    <TagLeftIcon
                        boxSize="3"
                        as={SmallCloseIcon}
                        backgroundColor={"white"}
                        color={"#A7A9BD"}
                        borderRadius={100}
                    />
                    <TagLabel fontSize="xx-small">Declined</TagLabel>
                  </Tag>
              )
          }
        },
      },
      {
        Header: () => <Text>Actions</Text>,
        accessor: "actions",
        Cell: (data) => {
          const url = handleRedirectUrl(data);
          const adName = data.row.values?.name;
          const adId = data?.row?.original?.id;
          return <RowButton url={url} adName={adName} adId={adId} handleDeleteModel={handleDeleteModel} data={data} context={context} refetch={refetch} />
        },
      },
    ],
    []
  );

  const events = [
    {
      start: moment("2022-10-10T09:00:00").toDate(),
      end: moment("2022-10-11T10:00:00").toDate(),
      data: {
        appointment: {
          id: 1,
          status: "P",
          location: "New York",
          resource: "Dr Alex",
          address: "Building 5\nStreet 44\nNear Express Highway\nNew York",
          bgColor: "#BCD",
        },
      },
      isDraggable: true,
      isResizable: true,
      resourceId: 1,
    },
    {
      start: moment("2022-10-12T10:30:00").toDate(),
      end: moment("2022-10-13T11:00:00").toDate(),
      data: {
        appointment: {
          id: 2,
          status: "CI",
          location: "Washington",
          resource: "Dr David",
          address: "Block 1\nSStreet 32\nLong Island\nNew York",
          bgColor: "#FBC",
        },
      },
      isDraggable: true,
      isResizable: true,
      resourceId: 2,
    },
  ];

  return (
    <div className="ad-upload-list">
      <DeleteModel
        heading={"Ad set"}
        name={adsName}
        isOpen={isDeleteModalOpen}
        onClose={() => onCloseDeleteModal()}
        onDestory={() => onDestroyModel()}
      />
      <Heading
        color="#757998"
        fontWeight="600"
        fontFamily="Lora"
        size="lg"
        mt={5}
      >
        Channels
      </Heading>
      <div className="search scrollable">
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
          mt={6}
        >
          <InputGroup width={880}>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" ml={8} />}
            />
            <Input
              ml={2}
              borderRadius={100}
              name="search"
              type="tel"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>

          <Stack direction="row" justifyContent="end" spacing={2}>
            <HStack spacing="2" align="center">
              <Switch
                id="view-timeline-switch"
                isChecked={viewTimeline}
                onChange={handleToggleViewMode}
                colorScheme="teal"
                size="md"
              />
              <Text
                fontWeight="bold"
                color={viewTimeline ? "teal.500" : "gray.500"}
              >
                Timeline
              </Text>
            </HStack>

            <Button
              style={{ minWidth: "inherit" }}
              backgroundColor={"#3B82F6"}
              borderRadius={0}
              leftIcon={<SmallAddIcon boxSize={8} />}
              onClick={() => allIds?.length ? navigate(`/campaign-briefs/${id}/ad-upload/new?allIds=${allIds}`) : navigate(`/campaign-briefs/${id}/ad-upload/new`)}
            >
              Add Creative
            </Button>
          </Stack>
        </Stack>
      </div>
      {!viewTimeline && (
        <Datatable
          data={adUploadList || []}
          columns={columns}
          headerColor={"brand.primary"}
          paginationDataCount={10}
        />
      )}
      {viewTimeline && (
        <MyCalendarTimeLine
          eventsData={adUploadList || []}
          startDate={dataOfCampaign.campaign.start_date}
          endDate={dataOfCampaign.campaign.end_date}
          clientId={clientId}
          campaignId={id}
        />
      )}
      <AdApproveModel
          name={adName}
          status={statusToUpdate}
          isOpen={isAdApproveModalOpen}
          onClose={() => onCloseAdApproveModal()}
          onDestroy={() => onDestroyModel()}
      />
    </div>
  );
};

export default WithAppContext(StepByStepAdUpload);
