import {
    Box, Button,
    Container, Flex,
    Image, Link, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PREVIEW_BG_COLOR, TEXT_COLOR, INDICATOR_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import {deleteTheField} from "../../../../../../../store/adUploads"
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { deleteAllLinkedInVideoFields } from "./store";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";
import LinkedInImageDesktop from "../LinkedinImage/components/LinkedInImageDesktop";
import LinkedInImageMobile from "../LinkedinImage/components/LinkedInImageMobile";
import {fetchImageMetadata} from "../../DemandGen/DemandGenImage/DemandGenImageAdPreview";
import {CreateMultipleCreatives} from "../../SocialDisplay/SocialDisplayImage/SocialDisplayImageAdPreview";

export async function fetchVideoMetadata(videoFiles) {
    const videoFileInfoPromises = videoFiles.map((el) => {
        return new Promise((resolve) => {
            const video = document.createElement('video');
            video.onloadedmetadata = () => {
                const { videoWidth, videoHeight } = video;
                console.log('width and height', videoWidth, videoHeight);
                resolve({
                    ...el,
                    metadata: { height: videoHeight, width: videoWidth },
                });
            };
            video.src = el.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads${el.imageUrl}` : `${process.env.REACT_APP_API_URL}/uploads/${el.file.filename}` ;
            console.log("video.src ",video.src)
        });
    });

    const videoFileInfoList = await Promise.all(videoFileInfoPromises);
    console.log("videoFileInfoList ", videoFileInfoList);
    return videoFileInfoList;
}


const LinkedInVideoAdPreview = ({ activeStep, steps, prevStep, nextStep, label, context}) => {

    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const { id: campaignId, adUploadId } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)
    const { allCampaignIds } = useSelector((state) => state.adUpload)

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    const { data, refetch } = useGetAdUpload(clientId, campaignId, adUploadId);

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.linkedInVideoAd)
    const {creative, selectedSocialMedia} = useSelector((state)=> state.adUpload)

    useEffect(() => {
        setAds(handlePreviewData)
    }, [handlePreviewData]);

    const getSlider = () => {
        if (ads?.length) {
            const slides = ads.map((el, ind) => (
                <Text
                    height={1}
                    borderRadius={5}
                    px={4}
                    mx={1}
                    mt={8}
                    key={ind}
                    background={ind === index ? INDICATOR_BG_COLOR : "silver"}
                    onClick={() => setIndex(ind)}
                    cursor="pointer"
                />
            ));
            return slides;
        }
    };

    const {mutateAsync} = useUploadImage();

    const onSubmitImages = async (multiFiles, type) => {
        let payload = [];
        let otherFile = [];
        let files = [];

        console.log("Okay here", multiFiles)
        multiFiles.forEach((file) => {
            if (!file?.files) {
                payload.push(file);
            } else {
                otherFile = [...otherFile, file]
            }
        })


        if(payload && payload.length){
            await mutateAsync(
                {
                    clientId: clientId,
                    campaignBriefId: campaignId,
                    adFile: payload,
                    type: type
                },
                {
                    onSuccess: (data, variables, context) => {
                        payload = data.fileInfoList;
                        setAppLoading(false)
                    },
                    onError: (error) => {
                        setAppLoading(false)
                        setDescription(error?.response?.data?.message);
                    }
                }
            );
            files = [...otherFile, ...payload]
        }else{
            files = [...otherFile]
        }
        return files;
    }

    const navigate = useNavigate();
    const dispatch = useDispatch()


    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if(adUploadId) {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
                    state: {isShowUpload: true}
                })
            }
            dispatch(deleteAllLinkedInVideoFields())
            dispatch(deleteTheField())
        }
        else {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`)
            }
            dispatch(deleteAllLinkedInVideoFields())
            dispatch(deleteTheField())
        }
    }

    async function handleSubmit() {
        setAppLoading(true);
        console.log('mediaDetails', mediaDetails)
        const imageFiles = await onSubmitImages(mediaDetails.imageURL, "linkedinVideosThumbnail");
        const videoFiles = await onSubmitImages(mediaDetails.fileURL, "linkedinVideos");

        const imageInfo = await fetchImageMetadata(imageFiles);
        console.log("imageInfo ", imageInfo);

        const videoInfo = await fetchVideoMetadata(videoFiles);
        console.log("videoInfo ", videoInfo);

        let payload = {
            name: formDetails.adName,
            description: formDetails.description,
            link: formDetails.link,
            detail: {
                name: formDetails.adName,
                description: formDetails.description,
                headline: formDetails.headline,
                introductory_text: formDetails.introductory_text,
                channel_name: formDetails.channel_name,
                channel_icon: formDetails.channelIcon,
                call_to_action: {
                    type: formDetails.type,
                    link: formDetails.link,
                },
                imageInfo, videoInfo,
                creative,
                channel: selectedSocialMedia
            },
        };

        if (!adUploadId) {
            payload = {
                ...payload,
                ad_upload_type: AD_UPLOAD_TYPE.LINKEDIN_VIDEO,
            };
        }

        console.log('Payload', payload)
        if(allCampaignIds?.length){
            CreateMultipleCreatives(allCampaignIds, method, url, clientId, payload, setSuccessModal, setErrorModal, setAppLoading, setDescription, dispatch);
        } else {
            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSuccessModal(true);
                        setAppLoading(false)
                    }
                })
                .catch((error) => {
                    setDescription(error.response.data.message);
                    setErrorModal(true);
                    setAppLoading(false)
                });
        }
    }

    return (
        <>
            <Tabs isFitted variant='enclosed'>
                <TabList>
                    <Tab>
                        <Box align="center">
                            <Image src={`${process.env.PUBLIC_URL}/images/phone_icon.png`} alt="Phone Icon" height={8} width={8} margin={2}/>
                            <h1>Mobile</h1>
                        </Box>
                    </Tab>
                    <Tab>
                        <Box align="center">
                            <Image src={`${process.env.PUBLIC_URL}/images/computer_icon.png`} alt="Computer Icon" height={8} width={8} margin={2}/>
                            <h1>Desktop</h1>
                        </Box>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <LinkedInImageMobile
                            formDetails={formDetails}
                            appLoading={appLoading}
                            ads={ads}
                            index={index}
                            type="video"
                        />
                    </TabPanel>
                    <TabPanel>
                        <LinkedInImageDesktop
                            formDetails={formDetails}
                            appLoading={appLoading}
                            ads={ads}
                            index={index}
                            type="video"
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <Flex width="100%" justify="flex-end" py={10}>
                <Button
                    mr={4}
                    onClick={prevStep}
                    colorScheme='blue' variant='outline' borderRadius={0} px={10}
                >
                    Back
                </Button>
                <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                    {adUploadId ? 'Update' : 'Submit'}
                </Button>
            </Flex>
            <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => onCloseSuccessModal()}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModal(false)}
                description={description}
            />
        </>
    );
};

export default WithAppContext(LinkedInVideoAdPreview);
