import React from 'react';
import {Heading} from "@chakra-ui/react";
import {TEXT_COLOR} from "../../../layout/constant/MenuList";
import Plot from "react-plotly.js";

function ScatterPlot({ result }) {
    if (!result || result.length === 0 || Object.keys(result[0]).length < 2) return null;

    const data = result.map(row => ({x: row[Object.keys(row)[0]], y: row[Object.keys(row)[1]]}));

    return (
        <div>
            <Heading fontSize="md" my={4} color={TEXT_COLOR}>
                Scatter Plot Visualization
            </Heading>
            <Plot
                data={[
                    {
                        x: data.map(d => d.x),
                        y: data.map(d => d.y),
                        mode: 'markers',
                        type: 'scatter'
                    }
                ]}
                layout={{
                    title: 'Scatter Plot',
                    xaxis: {title: Object.keys(result[0])[0]},
                    yaxis: {title: Object.keys(result[0])[1]}
                }}
            />
        </div>
    );
}

export default ScatterPlot;
