import { useQuery } from "react-query";
import axios from "../../helpers/axios";

export const useGetCampaignCreatives = (clientId, campaignId) => {
    return useQuery(["campaign-creatives", clientId, campaignId], async () => {
        if(campaignId && clientId){
            return axios
                .get(`/client/${clientId}/campaign-brief/${campaignId}/campaign-creatives`, {
                    withCredentials: false,
                })
                .then((res) => {
                    if (res.status === 200) {
                        return res.data.data;
                    }
                })
                .catch((err) => {
                    return err.response;
                });
        }
    });
};