import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTheField,
  selectSocialMedia,
} from "../../../../../store/adUploads";
import {useParams} from "react-router-dom";
import {useGetSingleCampaign} from "../../../../../hooks/campaign-briefs/useGetSingleCampaign";
import {
  settingBusinessDetails,
  settingCampaignBudget,
  settingCampaignDetails,
  settingCampaignObjective,
  settingDemographics, settingDemographyDetails, settingMediaStrategies, settingMediaStrategyDetails, settingNotes
} from "../../../../../store/adCampaign";
import axios from "../../../../../helpers/axios";
import _ from "lodash";
import CreativeType from "../SetupCampaign/CreativeType";
import CreativeDetails from "../SetupCampaign/CreativeDetails";

function SocialPlatform({ activeStep, steps, prevStep, nextStep, label , DV360YoutubeStep }) {
  const { id } = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const { data, refetch } = useGetSingleCampaign(clientId, id);
  const { campaignBudget } = useSelector((state) => state.adCampaign);
  const { selectedSocialMedia, creative } = useSelector(
      (state) => state.adUpload
  );

  const [isCreativeTypeOpen, setIsCreativeTypeOpen] = useState(false);


  const openCreativeType = (item) => {
    setIsCreativeTypeOpen(true);
  };

  useEffect(() => {
    refetch();
  }, [clientId]);

  useEffect(() => {
    if (data && data.campaign) {
      setAllData();
      getSingleBusiness(data?.campaign?.business_id);
    }
  }, [data]);

  const channels = [
    ...new Set(
        campaignBudget?.selectedChannels?.map(
            (e) => e.channel && e.channel.toLowerCase().replace(/ /g, "_")
        )
    ),
  ];

  function handleClick(index, adType) {
    handleSelect(index, adType);
  }

  function setAllData() {
    const { detail } = data.campaign;
    dispatch(settingCampaignDetails(detail.campaignDetails));
    dispatch(settingCampaignObjective(detail.objective));
    dispatch(settingCampaignBudget(detail.campaignBudget));
    dispatch(settingDemographics(detail.demographics));
    dispatch(settingMediaStrategies(detail.mediaStrategies));
    dispatch(settingDemographyDetails(detail.demographicDetails));
    dispatch(settingMediaStrategyDetails(detail.mediaStrategyDetails));
    dispatch(settingNotes(detail.notes));
  }

  function getSingleBusiness(id) {
    axios
        .get(`/client/business/${id}`, {
          withCredentials: false,
        })
        .then((res) => {
          if (res.status === 200 && !_.isEmpty(res.data.data.business)) {
            dispatch(settingBusinessDetails(res.data.data.business));
          }
        })
        .catch((err) => {
          return err.response;
        });
  }

  const dispatch = useDispatch();

  const [selectedTab, setSelectTab] = useState();

  function handleSelect(index, adType) {
    setSelectTab(adType);
    dispatch(selectSocialMedia(adType));
  }

  useEffect(() => {
    dispatch(deleteTheField());
  }, []);

  return (
      <Box w="100%" p={4}>
        <Box
            display="flex"
            mb={6}
            justifyContent="space-between"
            alignItems="center"
        >
          {!isCreativeTypeOpen && ( <Heading fontSize="3xl" color={"#757998"}>
            List of available channels
          </Heading>)}

        </Box>

        {!isCreativeTypeOpen && (
            <SimpleGrid columns={[1, 1, 1, 2]} spacing="20px">
              {channels &&
              channels.map((item, index) => {
                return (
                    <Box
                        cursor={"pointer"}
                        border={"2px solid rgba(167, 169, 189, 0.2)"}
                        style={selectedTab === item ? { borderColor: "blue" } : null}
                        borderRadius={"10px"}
                        height="80px"
                        display={"flex"}
                        p={4}
                        key={item}
                        onClick={() => handleClick(index, item)}
                        onDoubleClick={() => openCreativeType(item)}
                    >
                      <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap={"10px"}
                          color={"#757998"}
                      >
                        <Image
                            src={
                              channels.includes(item)
                                  ? `${process.env.PUBLIC_URL}/images/${item}.png`
                                  : `${process.env.PUBLIC_URL}/images/display.png`
                            }
                            height="40px"
                        />
                        {item && item.replace(/_/g, " ")}
                      </Box>
                    </Box>
                );
              })}
            </SimpleGrid>
        )}

        {isCreativeTypeOpen && (
            <Box>
              <CreativeDetails
                  activeStep={activeStep}
                  steps={steps}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  label="Creative Type"
              />
            </Box>
        )}
        <Flex width="100%" justify="flex-end" py={10}></Flex>
      </Box>
  );
}

export default SocialPlatform;
