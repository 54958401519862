// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaign-option {
  color: #363849 !important;
  border: 2px solid rgba(167, 169, 189, 0.2) !important;
  border-radius: 4px;
  font-weight: 500;
  font-family: Poppins;
}

.add-campaign-option {
  color: #1f95ff !important;
  border: 2px solid rgba(167, 169, 189, 0.2) !important;
  border-radius: 4px;
  font-weight: 500;
  font-family: Poppins;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AdCampaigns/adCampaign.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qDAAqD;EACrD,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,qDAAqD;EACrD,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".campaign-option {\n  color: #363849 !important;\n  border: 2px solid rgba(167, 169, 189, 0.2) !important;\n  border-radius: 4px;\n  font-weight: 500;\n  font-family: Poppins;\n}\n\n.add-campaign-option {\n  color: #1f95ff !important;\n  border: 2px solid rgba(167, 169, 189, 0.2) !important;\n  border-radius: 4px;\n  font-weight: 500;\n  font-family: Poppins;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
