import React, {useEffect} from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box, Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  SkeletonCircle,
  SkeletonText, Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import {CloseIcon} from "@chakra-ui/icons";
import ShareIcon from "../../../../../../../layout/components/ShareIcon";
import {useSelector} from "react-redux";

function YoutubeAd({formDetails, appLoading, index, handleIndex, businessName, youtubeVideoLink}) {
  const [ads, setAds] = React.useState([]);
  const {handlePreviewData} = useSelector((state) => state.performanceMaxAd)

  const selectedLogos = (Array.isArray(handlePreviewData?.selectedLogos) && handlePreviewData?.selectedLogos[0]?.imageUrl) ?
      (handlePreviewData?.selectedLogos[0]?.imageUrl) :
      process.env.PUBLIC_URL + "/images/display.png";

  useEffect(() => {
    const imageUrls = youtubeVideoLink.map((item) => {
      let video_id = item.split('v=')[1];
      let ampersandPosition = video_id?.indexOf('&');
      if(ampersandPosition && ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
      return {imageUrl: `https://img.youtube.com/vi/${video_id}/sddefault.jpg`}
    })
    setAds(imageUrls)
  }, [youtubeVideoLink]);

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%' overflow='hidden'>
                <Box width="100%" height="100%" boxShadow='sm' bg='white' borderTopRadius="25" position="relative" className={"Ok"} zIndex="1">
                  <Box>
                    {(ads && ads.length !== 0) ?
                      <Box overflow="hidden" width={"100%"}>
                        <Swiper navigation={true} modules={[Navigation]}
                                onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                          {
                            ads.map((item) => {
                              return (
                                <SwiperSlide>
                                  <Image
                                    borderTopRadius="25"
                                    height="70%"
                                    width={"100%"}
                                    objectFit={"fill"}
                                    src={item?.imageUrl}
                                    className="previewImage"
                                  />
                                </SwiperSlide>
                              )
                            })
                          }
                        </Swiper>
                      </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                    }
                  </Box>
                  <Flex position="absolute" top="30%" right="0%" bg="black" zIndex="2" border="1px solid white" boxShadow="0px 1px 6px #ffffff" p={4} py={2}
                        justifyContent="center" alignItems="center" gap={1}>
                    <Text color="white" fontSize="10px">Skip Ad </Text>
                    <Image src={`${process.env.PUBLIC_URL}/images/play_skip_forward_icon.svg`} alt="Play-Icon" height={4}
                           width={4}/>
                  </Flex>
                  <Box position="absolute" bg="yellow" zIndex="2"px={20} py={0.5} my={-1}
                       justifyContent="center" alignItems="center" gap={1} />
                  <Flex width="100%" justifyContent="end" alignItems="center" px={5} pt={3} cursor="pointer" gap={3}
                        _hover={{textDecoration: 'underline'}}>
                    <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.svg`} height="20px" width="20px" transform="rotate(90deg)"/>
                    <CloseIcon height="12px" width="12px" color="gray"/>
                  </Flex>
                  <Center>
                    <Image src={selectedLogos} height={12}
                           width={12} rounded="full"/>
                  </Center>
                  <Center><Text color="black" mt={2} fontSize="16px">{formDetails.channel_name}</Text></Center>
                  <Center><Text color="black" mt={5} fontSize="20px">{formDetails.headlines[0]}</Text></Center>
                  <Center><Box bg="#3b6dc8" textColor="white" textAlign="center" mx={4} p={1} px={3} mt={10} width="fit-content" alignItems="center">
                    <button style={{ display: "flex", justifyContent: "center", alignItems: 'center', gap: "1vh"}}>
                      {youtubeShortsTypes.find((item) => item.key === formDetails?.type).name}
                      <ShareIcon color="#ffffff" height="20px" width="20px"/>
                    </button>
                  </Box></Center>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default YoutubeAd;
