import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import Root from "./Root";
import './index.css';
import {AppContextProvider} from './helpers/app.contexts'
import {GoogleOAuthProvider} from "@react-oauth/google";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-59K6SM85', // Replace with your GTM ID
  };
  
  TagManager.initialize(tagManagerArgs);
const msalInstance = new PublicClientApplication(msalConfig);

createRoot(document.getElementById("root")).render(
    // <StrictMode>
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_CLIENT_ID}`}>
        <MsalProvider instance={msalInstance}>
        <AppContextProvider>
            <Root/>
        </AppContextProvider>
        </MsalProvider>
    </GoogleOAuthProvider>
    // </StrictMode>
);
