import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {AudioFileUpload} from "./AudioFileUpload";
import {settingAudioFormMedia, settingAudioPreviewData} from "./store";


function AudioAdMedia({activeStep, steps, prevStep, nextStep, label}) {

    const { adUploadId } = useParams()

    const { mediaDetails } = useSelector((state) => state.audioAd)
    const [hashArray, setHashArray] = useState([]);


    const schema = yup.object().shape({
        audioFiles: yup.array().min(1).required("Ad name is required."),
        audioThumbnail: yup.array().min(1).required("Ad name is required."),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            audioFiles: [],
            audioThumbnail: []
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingAudioFormMedia(values))
            dispatch(settingAudioPreviewData(values));
            nextStep()
        })
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;

    useEffect(() => {
        setFieldValue("audioFiles", mediaDetails?.audioFiles ? mediaDetails?.audioFiles : [])
        setFieldValue("audioThumbnail", mediaDetails?.audioThumbnail ? mediaDetails?.audioThumbnail : []);
    }, [mediaDetails])


    return (
        <Box className="file-upload-box">
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <AudioFileUpload
                        valuesData={values}
                        getHashArray={(value) => setHashArray(value)}
                        type="audio"
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                    />

                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}

                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                            Next
                        </Button>
                    </Flex>
                </Form>

            </FormikProvider>

        </Box>
    )
}

export default AudioAdMedia
