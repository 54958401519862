// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://rsms.me/inter/inter.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Inter, sans-serif";
}

.rbc-calendar {
  min-height: 600px;
}

.rbc-event {
  padding: 0px !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: transparent !important;
}

.rbc-time-slot {
  background-color: #eaf3fa;
  color: #74a4c3;
  border-color: aqua !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-events-container {
  width: 100% !important;
}

.rbc-timeslot-group {
  min-height: 120px !important;
}

.draggable.dragging {
  opacity: 1;
}

.doTTXD {
  position: relative !important;
}

.react-calendar-timeline .rct-header-root {
  background: black;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: lightcyan;
  opacity: 0.2;
  z-index: 1;
}

@media screen and (min-width: 992px) {
  .css-2oqyjj {
    padding: 10px 100px !important;
  }
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,6BAA6B;EAC7B,uBAAuB;EACvB,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,6BAA6B;AAC/B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,qBAAqB;EACrB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["@import url(\"https://rsms.me/inter/inter.css\");\r\n\r\nbody {\r\n  font-family: \"Inter, sans-serif\";\r\n}\r\n\r\n.rbc-calendar {\r\n  min-height: 600px;\r\n}\r\n\r\n.rbc-event {\r\n  padding: 0px !important;\r\n  border-radius: 0px !important;\r\n  border: none !important;\r\n  background-color: transparent !important;\r\n}\r\n\r\n.rbc-time-slot {\r\n  background-color: #eaf3fa;\r\n  color: #74a4c3;\r\n  border-color: aqua !important;\r\n}\r\n\r\n.rbc-event-label {\r\n  display: none !important;\r\n}\r\n\r\n.rbc-events-container {\r\n  width: 100% !important;\r\n}\r\n\r\n.rbc-timeslot-group {\r\n  min-height: 120px !important;\r\n}\r\n\r\n.draggable.dragging {\r\n  opacity: 1;\r\n}\r\n\r\n.doTTXD {\r\n  position: relative !important;\r\n}\r\n\r\n.react-calendar-timeline .rct-header-root {\r\n  background: black;\r\n}\r\n\r\n.loader-container {\r\n  width: 100%;\r\n  height: 100vh;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  position: fixed;\r\n  background: lightcyan;\r\n  opacity: 0.2;\r\n  z-index: 1;\r\n}\r\n\r\n@media screen and (min-width: 992px) {\r\n  .css-2oqyjj {\r\n    padding: 10px 100px !important;\r\n  }\r\n}\r\n\r\n@keyframes spin-anim {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
