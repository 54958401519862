import {
  Box, Button, Flex,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useRecoilValue} from "recoil";
import {useGetAdUpload} from "../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {useUploadImage} from "../../../../../../hooks/campaign-briefs/useUploadImage";
import {profile} from "../../../../../../atoms/authAtom";
import {deleteAllDiscoveryField} from "./store";
import {AD_UPLOAD_TYPE} from "../../../../../../constant";
import SuccessModal from "../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../components/PopupModal/ErrorModal";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../../../../../helpers/axios";
import WithAppContext from "../../../../../../helpers/appContext";
import Discovery from "./components/Discovery";
import {deleteTheField} from "../../../../../../store/adUploads";
import {fetchImageMetadata} from "../DemandGen/DemandGenImage/DemandGenImageAdPreview";
import {CreateMultipleCreatives} from "../SocialDisplay/SocialDisplayImage/SocialDisplayImageAdPreview";

const DiscoveryAdPreview = ({activeStep, steps, prevStep, nextStep, label, context}) => {
  const [index, setIndex] = useState(0);
  const [ads, setAds] = useState([]);
  const [method, setMethod] = useState();
  const [url, setUrl] = useState();
  const {id: campaignId, adUploadId} = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const {setAppLoading, appLoading} = context;
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [description, setDescription] = useState("");
  const {
    access_info: {roles, clients: userClients},
  } = useRecoilValue(profile);
  const [isClient, setIsClient] = useState(false)
  const { allCampaignIds } = useSelector((state) => state.adUpload);

  useEffect(() => {
    setIsClient(roles.includes("Client"));
  }, [roles]);

  const {data, refetch} = useGetAdUpload(clientId, campaignId, adUploadId);

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (adUploadId) {
      setMethod("PUT");
      setUrl(
        `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
      );
    } else {
      setMethod("POST");
      setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
    }
  }, [adUploadId, clientId]);

  const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.discoveryAd)
  const {creative, selectedSocialMedia} = useSelector((state) => state.adUpload)
  const {selectedImages, selectedLogos} = formDetails

  useEffect(() => {
    setAds(handlePreviewData.selectedImages)
  }, [handlePreviewData]);

  const {mutateAsync} = useUploadImage();

  const onSubmitImages = async (multiFiles, oldFiles) => {

    let payload = [];
    let otherFile = [];
    let files = [];

    multiFiles.forEach((file) => {
      if (file?.filename) {
        otherFile.push(file);
      } else {
        payload.push(file);
      }
    })

    if (payload && payload.length) {
      await mutateAsync(
        {
          clientId: clientId,
          campaignBriefId: campaignId,
          adFile: payload,
          type: "demandGen",
        },
        {
          onSuccess: (data, variables, context) => {
            payload = data.fileInfoList;
            setAppLoading(false)
          },
          onError: (error) => {
            setAppLoading(false)
            setDescription(error?.response?.data?.message);
          }
        }
      );
      files = [...otherFile, ...payload]
    } else {
      files = [...otherFile]
    }
    return files;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch()


  const onCloseSuccessModal = () => {
    setSuccessModal(false)

    if (adUploadId) {
      if(allCampaignIds?.length){
        navigate(`/campaign-briefs`)
      }else {
        navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
          state: {isShowUpload: true}
        })
      }
      dispatch(deleteAllDiscoveryField())
      dispatch(deleteTheField())
    } else {
      if(allCampaignIds?.length){
        navigate(`/campaign-briefs`)
      }else {
        navigate(`/campaign-briefs/${campaignId}/ad-upload`)
      }
      dispatch(deleteAllDiscoveryField())
      dispatch(deleteTheField())
    }
  }

  async function handleSubmit() {
    setAppLoading(true);
    const imageFiles = await onSubmitImages(mediaDetails.selectedImages, selectedImages);
    const logoFiles = await onSubmitImages(mediaDetails.selectedLogos, selectedLogos);
    const mediaIds = imageFiles.map((el) => el.imageHash);

    const imageFileInfoList = await fetchImageMetadata(imageFiles);
    console.log("imageFileInfoList ", imageFileInfoList)

    const logoFileInfoList = await fetchImageMetadata(logoFiles);
    console.log("logoFileInfoList ", logoFileInfoList)

    let payload = {
      name: formDetails.adName,
      description: formDetails.descriptions[0],
      title: formDetails.title,
      link: formDetails.link,
      detail: {
        name: formDetails.adName,
        headlines: formDetails.headlines,
        descriptions: formDetails.descriptions,
        long_headlines: formDetails.long_headlines,
        channel_name: formDetails.channel_name,
        title: formDetails.title,
        call_to_action: {
          type: formDetails.type,
          link: formDetails.link,
        },
        mediaIds: mediaIds,
        selectedImages: imageFileInfoList,
        selectedLogos: logoFileInfoList,
        creative,
        channel: selectedSocialMedia
      },
    };

    if (!adUploadId) {
      payload = {
        ...payload,
        ad_upload_type: AD_UPLOAD_TYPE.DISCOVERY,
      };
    }

    if(allCampaignIds?.length){
      CreateMultipleCreatives(allCampaignIds, method, url, clientId, payload, setSuccessModal, setErrorModal, setAppLoading, setDescription, dispatch);
    } else {
      await instance({
        method: method,
        url: url,
        withCredentials: false,
        data: payload,
      })
          .then((res) => {
            if (res.status === 200) {
              setSuccessModal(true);
              setAppLoading(false)
            }
          })
          .catch((error) => {
            setDescription(error.response.data.message);
            setErrorModal(true);
            setAppLoading(false)
          });
    }
  }
  const handleIndex = i => setIndex(i)

  return (
    <>
      <Discovery
          formDetails={formDetails}
          appLoading={appLoading}
          ads={ads}
          index={index}
          handleIndex={handleIndex}
          businessName={formDetails.channel_name}
      />
      <Flex width="100%" justify="flex-end" py={10}>
        <Button
          mr={4}
          onClick={prevStep}
          colorScheme='blue' variant='outline' borderRadius={0} px={10}
        >
          Back
        </Button>
        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
          {adUploadId ? 'Update' : 'Submit'}
        </Button>
      </Flex>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={description}
      />
    </>
  );
};

export default WithAppContext(DiscoveryAdPreview);
