import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    css,
    Flex, FormControl, FormLabel, Grid,
    GridItem,
    Heading, Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay, SimpleGrid,
    Text
} from "@chakra-ui/react";
import {ModalHeader} from "@chakra-ui/modal";
import {Input} from "@chakra-ui/input";
import {Form, FormikProvider, useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import axios from "../../helpers/axios";
import _ from "lodash";
import {useGetIndustriesCategories} from "../../hooks/config-management/useGetIndustryCategories";
import MultiSelectInputBox from "../MultiSelectInputBox";
import {settingCloneDetails} from "../../store/adCampaign";
import ErrorModal from "../PopupModal/ErrorModal";

const method = "get";
const headers = {
    "X-CSCAPI-KEY": process.env.REACT_APP_GEOGRAPHY_API_KEY,
};

function CloneCampaignModal({isOpen, onClose, diff = 1}) {
    const [channels, setChannels] = useState([]);
    const [countries, setCountries] = useState([]);
    const {demographics, campaignBudget, objective, businessDetails, cloneDetails} = useSelector(
        (state) => state.adCampaign
    );
    const {data: subCategories} = useGetIndustriesCategories();
    const [subCategoryType, setSubCategoryType] = useState([]);
    const config = {method, headers};
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorSubmit, setErrorSubmit] = useState("");
    const [allSelected, isAllSelected] = useState(false);

    const dispatch = useDispatch();
    const schema = yup.object().shape({
        totalBudget: yup.number().min(1).required().label("Budget"),
        selectedChannels: yup.array().required().label("Channels"),
        selectedAudience: yup.string().trim().min(3).max(50).required().label("Audience"),
        selectedCountries: yup.array().min(1).required().label("Counties"),
        locationGroup: yup.string().trim().min(3).max(150).required().label("Location Group"),
    });

    const formik = useFormik({
        initialValues: {
            totalBudget: null,
            selectedChannels: [],
            selectedAudience: demographics.audience || '',
            selectedCountries: [],
            locationGroup: ""
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingCloneDetails([values]))
            // dispatch(settingCloneDetails([...cloneDetails, values]))
            onClose()
        })
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        handleBlur,
        setFieldValue,
    } = formik;

    console.log('Error', errors)
    useEffect(() => {
        const payload = {
            objective,
            budget: values.totalBudget / (diff + 1),
            industry: businessDetails.industry,
        };
        axios
            .post("/spreadsheet-config/demographics/channels", payload)
            .then((res) => {
                setChannels(res.data);
                console.log('res', res.data)
            });
    }, [values.totalBudget]);

    const activeTab = {
        border: "2px solid #1F95FF",
        boxSizing: "border-box",
    };

    // useEffect(() => {
    //     if (!_.isEmpty(cloneDetails)) {
    //         setFieldValue('selectedChannels', cloneDetails.selectedChannels);
    //     }
    // }, [values.totalBudget]);

    useEffect(() => {
        setFieldValue('totalBudget', cloneDetails[0]?.totalBudget || 0)
        setFieldValue('selectedChannels', cloneDetails[0]?.selectedChannels || [])
        setFieldValue('selectedAudience', cloneDetails[0]?.selectedAudience || '')
        setFieldValue('selectedCountries', cloneDetails[0]?.selectedCountries || [])
        setFieldValue('locationGroup', cloneDetails[0]?.locationGroup || '')
    }, [cloneDetails])

    useEffect(() => {
        axios("https://api.countrystatecity.in/v1/countries", config).then(
            (res) => {
                setCountries(res.data);
            }
        );
    }, []);

    useEffect(() => {
        setSubCategoryType(subCategories?.data);
    }, [subCategories]);

    function handleClick(value) {
        const channelNames = values.selectedChannels.map((e) => e.name);
        if (channelNames.includes(value.name)) {
            const filterChannels = values.selectedChannels.filter(
                (e) => e.name !== value.name
            );
            setFieldValue('selectedChannels', filterChannels);
        } else {
            setFieldValue('selectedChannels', [...values.selectedChannels, value]);
        }
    }

    function handleAudience(value) {
        setFieldValue('selectedAudience', value);
    }

    useEffect(() => {
        AddMoreBudget();
    }, [values.selectedChannels]);

    function AddMoreBudget() {
        if (!!values.selectedChannels && campaignBudget.duration) {
            const recommendedChannels = values.selectedChannels.map(
                (e) =>
                    parseInt(e?.minimumDaily?.replace("$", "")) * campaignBudget.duration
            );
            const appliedBudget = recommendedChannels.reduce(
                (previousValue, currentValue) => {
                    return previousValue + currentValue;
                },
                0
            );
            if (values.totalBudget < appliedBudget) {
                const updatedChannels = values.selectedChannels.filter(
                    (e, index) => index < values.selectedChannels.length - 1
                );
                setFieldValue('selectedChannels', updatedChannels);
                setIsErrorModalOpen(true);
                setErrorSubmit(
                    `Please Add $${
                        appliedBudget - values.totalBudget
                    } more to select this channel`
                );
            }
        }
    }

    function handleCloseErrorModal() {
        setIsErrorModalOpen(false);
        setErrorSubmit("");
    }

    function handleSelectAll() {
        if (allSelected) {
            setFieldValue('selectedChannels', []);
        } else {
            // Calculate the total required budget for all channels
            const neededBudget = channels.reduce((acc, channel) => {
                const dailyBudget = parseInt(channel?.minimumDaily?.replace("$", "")) || 0;
                return acc + dailyBudget * campaignBudget.duration;
            }, 0);

            // Check if the available budget is sufficient
            if (values.totalBudget < neededBudget) {
                setIsErrorModalOpen(true);
                setErrorSubmit(
                    `Please add $${neededBudget - values.totalBudget} more to select all channels.`
                );
            } else {
                setFieldValue('selectedChannels', channels);
            }
        }
    }

    function arraysHaveSameEntities(arr1, arr2) {
        const set1 = new Set(arr1);
        const set2 = new Set(arr2);

        return set1.size === set2.size && [...set1].every(value => set2.has(value));
    }

    useEffect(() => {
        if (values.selectedChannels.length > 0 && arraysHaveSameEntities(values.selectedChannels, channels)) {
            isAllSelected(true)
        } else {
            isAllSelected(false)
        }
    }, [values.selectedChannels])

    return (
        <>
            <Modal isCentered isOpen={isOpen} size="2xl">
                <ModalOverlay/>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <ModalContent>
                            <ModalHeader>
                                Replicate the current Campaign for other Country
                            </ModalHeader>
                            <ModalBody
                                css={css({
                                    display: "flex",
                                    flexDirection: "column",
                                    // alignItems: "center",
                                    justifyContent: "center",
                                })}
                            >
                                <FormControl isRequired>
                                    <Flex gap={1}>
                                        <Image
                                            mt={1}
                                            src={`${process.env.PUBLIC_URL}/images/budget.svg`}
                                            height="18px"
                                        />
                                        <FormLabel>Total Budget</FormLabel>
                                    </Flex>
                                    <Input
                                        placeholder="Total Budget"
                                        isInvalid={errors.totalBudget && touched.totalBudget}
                                        name="totalBudget"
                                        type="number"
                                        id="totalBudget"
                                        value={values.totalBudget}
                                        onChange={(e) => {
                                            setFieldValue("totalBudget", e.target.value);
                                        }}
                                    />
                                    {errors.totalBudget && touched.totalBudget && (
                                        <Text color="red.500" fontSize="sm" mt={2}>
                                            {errors.totalBudget}
                                        </Text>
                                    )}
                                </FormControl>
                                {!!channels.length > 0 && (
                                    <>
                                        <Flex
                                            justifyContent="space-between"
                                            mt={8}
                                            mb={2}
                                        >
                                            <Text
                                                fontSize="lg"
                                                fontWeight={500}
                                                color="brand.label"
                                            >
                                                Auto Recommended Channels
                                            </Text>
                                            <Button onClick={handleSelectAll}>
                                                {allSelected ? `Deselect All`: `Select All`}
                                            </Button>
                                        </Flex>
                                        <SimpleGrid columns={[1, 2, 2, 2, 3]} spacing="20px">
                                            {channels.map((item, index) => {
                                                const channel = values.selectedChannels.map((e) => e.name && e.name.replace(/ /g, "_"));
                                                const channels = [
                                                    "meta",
                                                    "amazon",
                                                    "display",
                                                    "instagram",
                                                    "tiktok",
                                                    "facebook",
                                                    "google",
                                                    "waze",
                                                    "youtube",
                                                    "discovery",
                                                    "pinterest",
                                                    "quora",
                                                    "reddit",
                                                    "twitch",
                                                    "twitter",
                                                    "wechat",
                                                    "weibo",
                                                    "snapchat",
                                                    "linkedin",
                                                    "microsoft",
                                                    "facebook_dynamic",
                                                    "instagram_dynamic",
                                                    "youtube_shorts",
                                                    "demand_gen",
                                                    "audio",
                                                    "performance_max",
                                                    "social_display",
                                                    "discovery",
                                                ];
                                                return (
                                                    <Box
                                                        cursor={"pointer"}
                                                        border="1px solid"
                                                        borderColor="gray.300"
                                                        borderRadius="lg"
                                                        minH="70px"
                                                        display={"flex"}
                                                        p={4}
                                                        style={channel.includes(item.name.replace(/ /g, "_")) ? activeTab : null}
                                                        onClick={() => handleClick(item)}
                                                    >
                                                        <Box
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            gap={"14px"}
                                                            color={"brand.label"}
                                                        >
                                                            <Image
                                                                src={
                                                                    channels.includes(item?.channel?.toLowerCase().replace(/ /g, "_"))
                                                                        ? `${
                                                                            process.env.PUBLIC_URL
                                                                        }/images/${item?.channel?.toLowerCase().replace(/ /g, "_")}.png`
                                                                        : `${process.env.PUBLIC_URL}/images/display.png`
                                                                }
                                                                height="34px"
                                                            />
                                                            <Flex direction="column" gap={1}>
                                                                <Heading
                                                                    fontSize="sm"
                                                                    fontFamily={"poppins"}
                                                                    color={"brand.label"}
                                                                    fontWeight={400}
                                                                >
                                                                    {item.name}
                                                                </Heading>
                                                            </Flex>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </SimpleGrid>
                                        {(errors.selectedChannels && touched.selectedChannels) &&
                                        <Text color="red.500" marginTop="2" textAlign={"center"}>
                                            {errors.selectedChannels}
                                        </Text>
                                        }
                                    </>
                                )}
                                {!!subCategoryType?.length && (
                                    <>
                                        <Text
                                            fontSize="lg"
                                            fontWeight={500}
                                            color="brand.label"
                                            mt={8}
                                            mb={2}
                                        >
                                            Auto Recommended Audience
                                        </Text>
                                        <Heading fontSize={"lg"} fontWeight="500">
                                        </Heading>
                                        <SimpleGrid
                                            columns={[1, 1, 2, 2, 3]}
                                            alignItems={"flex-start"}
                                            spacing="20px"
                                        >
                                            {subCategoryType.map((item, index) => (
                                                <Box
                                                    minHeight="76px"
                                                    display={"flex"}
                                                    borderColor="gray.300"
                                                    borderWidth="2px"
                                                    borderRadius="lg"
                                                    flexDirection={"column"}
                                                    padding={4}
                                                    cursor={"pointer"}
                                                    gap={3}
                                                    key={item?.id}
                                                    style={
                                                        values.selectedAudience === item.subCategory ? activeTab : null
                                                    }
                                                    onClick={() => handleAudience(item.subCategory)}
                                                >
                                                    <Flex gap={4} alignItems={"center"} position={"relative"}>
                                                        <Box
                                                            padding={2}
                                                            backgroundColor={"gray.100"}
                                                            borderRadius={8}
                                                        >
                                                            <Image
                                                                src={`${
                                                                    process.env.PUBLIC_URL
                                                                }/images/${businessDetails.industry
                                                                    .toLowerCase()
                                                                    .replace("-", "")}.png`}
                                                                height="30px"
                                                            />
                                                        </Box>
                                                        <Heading
                                                            fontSize={"sm"}
                                                            fontWeight={400}
                                                            fontFamily={"poppins"}
                                                            color={"brand.label"}
                                                        >
                                                            {item.subCategory}
                                                        </Heading>
                                                    </Flex>
                                                </Box>
                                            ))}
                                        </SimpleGrid>
                                        {(errors.selectedAudience && touched.selectedAudience) &&
                                        <Text color="red.500" marginTop="2" textAlign={"center"}>
                                            {errors.selectedAudience}
                                        </Text>
                                        }
                                    </>
                                )}
                                <Grid
                                    templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}}
                                    gap={6}
                                >
                                    <GridItem>
                                        <FormControl maxWidth={500} mt={4} isRequired>
                                            <FormLabel>Countries</FormLabel>
                                            <MultiSelectInputBox
                                                name="countries"
                                                value={values.selectedCountries}
                                                options={countries?.map((el) => {
                                                    return {
                                                        label: el.name,
                                                        value: el.name,
                                                    };
                                                })}
                                                placeholder="-- Select Countries --"
                                                onChange={(e) => {
                                                    setFieldValue('selectedCountries', e.map((v) => v))
                                                }}
                                            />
                                        </FormControl>
                                        {(errors.selectedCountries && touched.selectedCountries) &&
                                        <Text color="red.500" marginTop="2" textAlign={"center"}>
                                            {errors.selectedCountries}
                                        </Text>
                                        }
                                    </GridItem>
                                    <GridItem>
                                        <FormControl isRequired maxWidth={500} mt={4}>
                                            <FormLabel>Location Group</FormLabel>
                                            <Input
                                                name="locationGroup"
                                                borderColor="#e7e7e9"
                                                value={values.locationGroup}
                                                onChange={(e) =>
                                                    setFieldValue('locationGroup', e.target.value)
                                                }
                                                borderRadius={1}
                                                color={"#757998"}
                                                fontWeight={500}
                                            />
                                        </FormControl>
                                        {(errors.locationGroup && touched.locationGroup) &&
                                        <Text color="red.500" marginTop="2" textAlign={"center"}>
                                            {errors.locationGroup}
                                        </Text>
                                        }
                                    </GridItem>
                                </Grid>
                                <FormControl>
                                    <Button float="right" type='submit' mt={4} mx={1}>
                                        Submit
                                    </Button>
                                    <Button float="right" type='button' mt={4} mx={1} onClick={onClose}>
                                        Cancel
                                    </Button>
                                </FormControl>
                            </ModalBody>
                        </ModalContent>
                    </Form>
                </FormikProvider>
            </Modal>
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => handleCloseErrorModal()}
                description={errorSubmit}
            />
        </>
    );
}

export default CloneCampaignModal;
