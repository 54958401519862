import React, { useState } from "react";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { Box } from "@chakra-ui/layout";
import { ViewSwitcher } from "./ViewSwitcher";
import instance from "../../../../../helpers/axios";
import moment from "moment";
import { useToast } from "@chakra-ui/react";
const MyCalendarTimeLine = ({
  eventsData,
  startDate,
  endDate,
  clientId,
  campaignId,
}) => {
  const toast = useToast();
  const [view, setView] = React.useState(ViewMode.Day);
  const [isChecked, setIsChecked] = React.useState(true);
  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }
  const convertObject = (original) => {
    const percentage = parseInt(original.detail.creative.pendingDays);
    const startDate = new Date(original.detail.creative.startDate);
    const endDate = new Date(original.detail.creative.endDate);
    const totalDays = Math.floor((endDate - startDate) / (24 * 60 * 60 * 1000));
    const pendingDays = (percentage / 100) * totalDays;
    return {
      start: startDate,
      end: endDate,
      name: original.name,
      id: original.id,
      type: original.detail.channel,
      progress: parseFloat(pendingDays.toFixed(2)),
      isDisabled: false,
      project: "Kedet",
      styles: {
        progressColor: "#ffbb54",
        progressSelectedColor: "#ff9e0d",
      },
    };
  };
  const [events, setEvents] = useState(() => {
    return eventsData.map(convertObject);
  });

  function adjustForWeekends(date, daysToAdd) {
    let adjustedDate = moment(date);
    let remainingDays = Math.abs(daysToAdd);

    while (remainingDays > 0) {
      adjustedDate = daysToAdd >= 0 ? adjustedDate.add(1, 'day') : adjustedDate.subtract(1, 'day');
      if (adjustedDate.day() === 6 || adjustedDate.day() === 0) {
        continue;
      }
      remainingDays--;
    }
    return adjustedDate;
  }

  const handleCreativeChange = async (creative) => {
    const creativeChanged = events.find((t) => t.id === creative.id);
    const prevDataIndex = events.indexOf(creativeChanged) - 1
    let prevData;
    if (prevDataIndex >= 0) {
      prevData = events[prevDataIndex]
    }
    const isPastDate = moment(moment(new Date())).unix() > moment(creative.start).unix()
    let newCreatives = events.map((t) => (t.id === creative.id ? creative : t));
    const [start, end] = [new Date(startDate), new Date(endDate)];
    if (
      creativeChanged.start.getTime() !== start.getTime() ||
      creativeChanged.end.getTime() !== end.getTime()
    ) {
      const changedCreative = { ...creativeChanged, start, end };
      newCreatives = newCreatives.map((t) =>
        t.id === creative.project ? changedCreative : t
      );
      try {
        // Make an API call to update the event details
        const originalCreativeData = eventsData.find(
          (e) => e.id === creative.id
        );

        console.log("original event to be updated", originalCreativeData);

        const formatedStartDate = moment(creative.start).format("YYYY-MM-DD");
        const formatedEndDate = moment(creative.end).format("YYYY-MM-DD");

        const isAfter =
          moment(formatedStartDate).unix() >=
          moment(startDate).add(2, "days").unix();
        const isBefore =
          moment(formatedEndDate).unix() <= moment(endDate).unix();

        if (!isAfter || !isBefore) {
          console.log("Start and End date must be within the campaign period");
          toast({
            title: "Start and End date must be within the campaign period",
            description: `Start Date: ${moment(startDate)
              .add(2, "days")
              .format("ddd, D MMM YYYY")} \n End Date: ${moment(endDate).format(
              "ddd, D MMM YYYY"
            )}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        if (isPastDate) {
          toast({
            title: "Can not set start date for the past date",
            description: `Current Date: ${moment(new Date())
              .format("ddd, D MMM YYYY")}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        if (prevData) {
          const twoDaysAfterPrevStart = adjustForWeekends(prevData.start, 2);
          const threeDaysBeforePrevStart = adjustForWeekends(prevData.start, -3);
          const formattedStartUnix = moment(formatedStartDate).unix();
          const twoDaysAfterPrevUnix = twoDaysAfterPrevStart.unix();
          const threeDaysBeforePrevUnix = threeDaysBeforePrevStart.unix();

          if (!(twoDaysAfterPrevUnix < formattedStartUnix) &&
              !(threeDaysBeforePrevUnix > formattedStartUnix)) {
            const prevStartDateFormatted = moment(prevData.start).format("ddd, D MMM YYYY");
            const twoDaysAfterFormatted = twoDaysAfterPrevStart.format("ddd, D MMM YYYY");
            const threeDaysBeforeFormatted = threeDaysBeforePrevStart.format("ddd, D MMM YYYY");

            toast({
              title: `There should be a 2-day gap (Excluding weekends) between the start dates of the previous ad and the new ad.`,
              description: `Date of Previous ad: (${prevStartDateFormatted}) \nNew Date can be: ${twoDaysAfterFormatted} \nor \n${threeDaysBeforeFormatted}`,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
        }

        const newCreativeData = {
          ...originalCreativeData,
          detail: {
            ...originalCreativeData.detail,
            creative: {
              ...originalCreativeData.detail.creative,
              startDate: formatedStartDate,
              endDate: formatedEndDate,
            },
          },
        };

        delete newCreativeData.message;
        delete newCreativeData.id;
        delete newCreativeData.other_info;
        delete newCreativeData.state_reason;
        delete newCreativeData.ad_upload_type;
        delete newCreativeData.fb_ads;
        delete newCreativeData.dv_creatives;
        delete newCreativeData.dv_ads;
        delete newCreativeData.campaign_brief_id;
        delete newCreativeData.deleted_at;
        delete newCreativeData.created_at;
        delete newCreativeData.created_by;
        delete newCreativeData.updated_at;
        delete newCreativeData.updated_by;
        delete newCreativeData.state;
        delete newCreativeData.status;

        console.log(
          "new event to be updated",
          originalCreativeData.detail.creative.startDate
        );

        console.log("new event to be updated", originalCreativeData);

        await instance({
          method: "PUT",
          url: `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${creative.id}`,
          withCredentials: false,
          data: newCreativeData,
        })
          .then((res) => {
            if (res.status === 200) {
              console.log(res);
              console.log("updated successfully");
              toast({
                title: "Creative updated successfully",
                description: `Start Date: ${moment(creative.start).format(
                  "ddd, D MMM YYYY"
                )} \n End Date: ${moment(creative.end).format(
                  "ddd, D MMM YYYY"
                )}`,
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            console.error("updating start and end date failed!");
          });
      } catch (error) {
        console.error("Error updating event time:", error);
        console.error("Error updating event time:", error);
        // Handle error as needed
      }
    }
    setEvents(newCreatives);
  };

  return (
    <Box>
      <ViewSwitcher
        onViewModeChange={(viewMode) => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      />
      <Box height="500px" overflowY="scroll" w="100%" m={2}>
        <Gantt
          tasks={events}
          viewMode={view}
          listCellWidth={isChecked ? "155px" : ""}
          columnWidth={columnWidth}
          onDateChange={handleCreativeChange}
        />
      </Box>
    </Box>
  );
};

export default MyCalendarTimeLine;
