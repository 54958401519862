import React from 'react';
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  SkeletonCircle,
  SkeletonText, Text
} from "@chakra-ui/react";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation} from "swiper";
import FullPageLoader from "../../../../../../../../components/FullPageLoader";
import {PREVIEW_BG_COLOR} from "../../../../../../../../layout/constant/MenuList";

function YoutubeHome({formDetails, appLoading, ads, index, index1, handleIndex, businessName, carouselCards}) {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box backgroundColor='gray.300' border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%'>
                <Box backgroundColor='gray.300' borderBottom='1px #e3e8f1 solid' textAlign='center' borderTopRadius="25px"
                     alignSelf="center">
                  <Flex justifyItems="center" px={3} justifyContent="space-between" py={3} backgroundColor="white"
                        borderTopRadius="25">
                    <Flex alignItems="center" justifyContent="center">
                      <Image src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="YouTube-Icon" height={8}
                             width={8}/>
                      <Box ml={1} fontWeight="bolder">YouTube</Box>
                    </Flex>
                    <Flex alignItems="center" justifyContent="center">
                      <SkeletonCircle size='6'/>
                    </Flex>
                  </Flex>
                </Box>
                <Box width="100%" height="70%" mt="20%" borderTop="1px solid gray.300" borderBottom="1px solid gray.300"
                     boxShadow='sm' bg='white'>
                  <Box display="flex" gap="5" padding='4' height="15%">
                    <SkeletonCircle size='10'/>
                    <SkeletonText mt='1' noOfLines={2} spacing='3' skeletonHeight='3' width="70%"/>
                  </Box>
                  <Box mt={2} height="55%">
                    {(ads && ads.length !== 0) ? (
                      <Flex overflow="hidden" width="100%" height="100%">
                        <Swiper navigation={true} modules={[Navigation]} style={{ height: '100%' }} onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                          {ads.map((item, index) => (
                            <SwiperSlide key={index}>
                              <Box
                                height="90%"
                                width="90%"
                                borderRadius="lg"
                                boxShadow="md"
                                bg="white"
                                margin="5"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <Image
                                  height="80%"
                                  width="100%"
                                  objectFit="cover"
                                  src={item[index1]?.imageUrl}
                                  className="previewImage"
                                />
                                <Text height="20%" width="100%" textAlign="center" mt={2}>{carouselCards[index].headline}</Text>
                              </Box>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Flex>
                    ) : (
                      <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden" />
                    )}
                  </Box>
                  <Box mx={5} mt={1} fontWeight="600"> {formDetails.headline} </Box>
                  <Box mx={5} fontSize="14px"> {formDetails.description} </Box>
                  <Flex justifyItems="baseline">
                    <Box mx={5} fontWeight="bolder" fontSize="14px"> Sponsored </Box>
                    <Box fontSize="14px"><li>{businessName}</li></Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default YoutubeHome;
