import {WrapContext} from "./store/WrapContext";
import GoogleCSV from "./GoogleCSV";
import React from "react";

function GoogleAdDetails({ activeStep, steps, prevStep, nextStep, label}) {
    return (
        <WrapContext>
            <GoogleCSV activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
        </WrapContext>
    )
}

export default GoogleAdDetails