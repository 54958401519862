import { createSlice } from "@reduxjs/toolkit";
import {adUploadSlice} from "../../../../../../../../store/adUploads";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const dynamicInstagramVideoSlice = createSlice({
    name: AD_UPLOAD_TYPE.DYNAMIC_INSTAGRAM_VIDEO,
    initialState,
    reducers: {
        settingDynamicInstagramVideoForm: (state, action) => {
            state.formDetails = action.payload
        },
        settingDynamicInstagramVideoMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingDynamicInstagramVideoPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllDynamicInstagramVideoFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { settingDynamicInstagramVideoForm,settingDynamicInstagramVideoMedia,settingDynamicInstagramVideoPreview,deleteAllDynamicInstagramVideoFields} = dynamicInstagramVideoSlice.actions;

// The reducer
export const dynamicInstagramVideoReducer = dynamicInstagramVideoSlice.reducer;
