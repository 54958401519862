import * as Yup from "yup";

const createValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().email().required().label("Email address"),
  password: Yup.string().required().label("Password"),
  isSyncClientsOn: Yup.bool().required(),
  // roles: Yup.array().required().label("Roles"),
  // clients: Yup.array().label("Clients"),
});

export default createValidationSchema;
