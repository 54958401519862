import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box,
  Container, Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  SkeletonText, Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import ShareIcon from "../../../../../../../layout/components/SearchIcon";

function Display({children}) {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {/*{appLoading && <FullPageLoader/>}*/}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"} />
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%' overflow="hidden">
                <Box backgroundColor='gray.300' borderBottom='1px #e3e8f1 solid' textAlign='center'
                     borderTopRadius="25px"
                     alignSelf="center">
                  <Flex p={3} justifyContent="space-between" alignItems="center" backgroundColor="gray.200"
                        borderTopRadius="25">
                    <Image src={`${process.env.PUBLIC_URL}/images/three_line_icon.svg`} alt="YouTube-Icon" height={5}
                           width={5}/>
                    <Box p={1} fontWeight="bolder" width="50%" bg="gray.300"/>
                    <ShareIcon color="#cbd5e0" height={5} width={5}/>
                  </Flex>
                </Box>
                <Box width="100%" height="93%" bg='white' px={2} overflow="auto">
                  <Box gap="5" py='4'>
                    <SkeletonText noOfLines={4} spacing='2' skeletonHeight='2' width="100%"/>
                  </Box>
                  { children }
                  <Divider my={3}/>
                  <SkeletonText noOfLines={4} spacing='2' skeletonHeight='2' width="100%"/>
                  <SkeletonText noOfLines={2} spacing='2' skeletonHeight='2' width="100%" pt={3}/>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default Display;
