import {
  Button,
  Checkbox,
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MultiSelectInputBox from "../../components/MultiSelectInputBox";
import instance from "../../helpers/axios";
import { Formik, Form, Field } from "formik";
import {useGetUserDetails} from "../../hooks/users/useGetUserDetails";

const NotificationUpdate = () => {
  const toast = useToast();
  const clients = useSelector((state) => state.client.clients);
  const [selectedClients, setSelectedClients] = useState([]);
  const user = JSON.parse(localStorage.getItem("client"));
  const { data, refetch } = useGetUserDetails(user?.id);

  useEffect(() => {
      if (data?.receive_mail_for?.clients?.length) {

          const clientsData = data.receive_mail_for.clients.map((el) => {
              const clientData = clients.filter((val) => val.id === el);
              const obj = {
                  value: clientData[0]?.id,
                  label: clientData[0]?.name,
              }
              return obj;
          });
          setSelectedClients(clientsData);
      }
  }, [data]);


    const handleUpdate = async (values) => {
      await instance({
          method: "PUT",
          url: `/user/me/receiveMailFor`,
          data: {
              mailClients: values.clients.map((el) => el.value),
          },
      })
        .then((response) => {
          toast({
            isClosable: true,
            status: "success",
            variant: "top-accent",
            position: "top-right",
            title: "Success",
            description: response.data?.data?.message,
          });
          refetch();
        })
        .catch((error) => {
          toast({
            isClosable: true,
            status: "error",
            variant: "top-accent",
            position: "top-right",
            description: error.response.data.message,
          });
        });
  };

  return (
      <Formik
          initialValues={{
            clients: selectedClients,
          }}
          onSubmit={handleUpdate}
          enableReinitialize={true}
      >
        {({ setFieldValue, values }) => (
            <Form>
              <Fragment>
                <VStack align="stretch">
                  <Text
                      fontSize={{
                        base: "lg",
                        md: "xl",
                      }}
                      fontWeight="600"
                      color="brand.heading"
                  >
                    Notifications
                  </Text>
                  <Grid templateColumns="repeat(6, 1fr)" gap={4} mt="4">
                    <GridItem colSpan={4}>
                      <Text
                          color="brand.label"
                          fontSize={{
                            base: "xs",
                            md: "sm",
                          }}
                      >
                        Notify me when:
                      </Text>
                    </GridItem>
                    <VStack colSpan={1}>
                      <Text
                          color="brand.label"
                          fontSize={{
                            base: "xs",
                            md: "sm",
                          }}
                      >
                        Email
                      </Text>
                    </VStack>
                    <VStack colSpan={1}>
                      <Text
                          color="brand.label"
                          fontSize={{
                            base: "xs",
                            md: "sm",
                          }}
                          whiteSpace="nowrap"
                      >
                        In-App
                      </Text>
                    </VStack>
                  </Grid>
                  <Divider w="full" />
                  <Grid templateColumns="repeat(6, 1fr)" gap={{ base: 4, md: 8 }}>
                    <GridItem colSpan={4} mt={4}>
                      <Text color={"brand.text"} fontSize={{ base: "xs", md: "sm" }}>
                        New comment I’m @mentioned in
                      </Text>
                    </GridItem>
                    <VStack colSpan={1} mt={4}>
                      <Checkbox
                          aria-label="New comment I’m @mentioned in email"
                          defaultChecked
                      />
                    </VStack>
                    <VStack colSpan={1} mt={4}>
                      <Checkbox
                          aria-label="New comment I’m @mentioned in app"
                          defaultChecked
                      />
                    </VStack>
                    <GridItem colSpan={6}></GridItem>
                  </Grid>

                  <Grid templateColumns="repeat(6, 1fr)" gap={{ base: 4, md: 8 }}>
                    <GridItem colSpan={4}>
                      <Text color={"brand.text"} fontSize={{ base: "xs", md: "sm" }}>
                        New comment on subscribed board
                      </Text>
                    </GridItem>
                    <VStack colSpan={1}>
                      <Checkbox
                          aria-label="New comment on subscribed board email"
                          defaultChecked
                      />
                    </VStack>
                    <VStack colSpan={1}>
                      <Checkbox
                          aria-label="New comment on subscribed board app"
                          defaultChecked
                      />
                    </VStack>
                    <GridItem colSpan={6}></GridItem>
                  </Grid>

                  <Grid templateColumns="repeat(6, 1fr)" gap={{ base: 4, md: 8 }}>
                    <GridItem colSpan={4}>
                      <Text color={"brand.text"} fontSize={{ base: "xs", md: "sm" }}>
                        Added to a new board
                      </Text>
                    </GridItem>
                    <VStack colSpan={1}>
                      <Checkbox aria-label="Added to a new board email" defaultChecked />
                    </VStack>
                    <VStack colSpan={1}>
                      <Checkbox aria-label="Added to a new board app" defaultChecked />
                    </VStack>
                    <GridItem colSpan={6}></GridItem>
                  </Grid>

                  <Grid templateColumns="repeat(6, 1fr)" gap={{ base: 4, md: 8 }}>
                    <GridItem colSpan={4}>
                      <Text color={"brand.text"} fontSize={{ base: "xs", md: "sm" }}>
                        File added to subscribed board
                      </Text>
                    </GridItem>
                    <VStack colSpan={1}>
                      <Checkbox
                          aria-label="File added to subscribed board email"
                          defaultChecked
                      />
                    </VStack>
                    <VStack colSpan={1}>
                      <Checkbox
                          aria-label="File added to subscribed board app"
                          defaultChecked
                      />
                    </VStack>
                    <GridItem colSpan={6}></GridItem>
                  </Grid>

                  <Grid templateColumns="repeat(6, 1fr)" gap={{ base: 4, md: 8 }}>
                    <GridItem colSpan={4} mb={4}>
                      <Text color={"brand.text"} fontSize={{ base: "xs", md: "sm" }}>
                        New user is added
                      </Text>
                    </GridItem>
                    <VStack colSpan={1} mb={4}>
                      <Checkbox aria-label="New user is added email" defaultChecked />
                    </VStack>
                    <VStack colSpan={1} mb={4}>
                      <Checkbox aria-label="New user is added app" defaultChecked />
                    </VStack>
                  </Grid>

                        <Grid templateColumns="repeat(6, 1fr)"  mt={4}>
                            <GridItem colSpan={6}>
                                <Text color={"brand.text"} fontSize={{ base: "xs", md: "sm" }}>
                                    Campaign/Creative is added or updated
                                </Text>
                            </GridItem>
                            <GridItem colSpan={6}>
                                    <Field name="clients">
                                        {({ field }) => (
                                            <MultiSelectInputBox
                                                name="clients"
                                                options={clients?.map((el) => {
                                                    return {
                                                        label: el.name,
                                                        value: el.id,
                                                    };
                                                })}
                                                placeholder={`-- Select One --`}
                                                value={values.clients}
                                                onChange={(e) => {
                                                    setFieldValue("clients", e);
                                                    setSelectedClients(e);
                                                }}
                                            />
                                        )}
                                    </Field>
                            </GridItem>
                        </Grid>

                  <Divider w="full" />
                </VStack>
                <HStack justify="end" mt="4">
                    <Button type="submit">Update</Button>
                </HStack>
              </Fragment>
            </Form>
        )}
      </Formik>
  );
};

export default NotificationUpdate;
