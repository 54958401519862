import {HamburgerIcon, SearchIcon} from "@chakra-ui/icons";
import { HStack } from "@chakra-ui/layout";
import {
    FormControl,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton, MenuItem, MenuList,
    Select
} from "@chakra-ui/react";
import React, {useState} from "react";
import filterIcon from "../assets/images/filter_icon.svg";

const SearchAndDropdown = ({ setSearch, searchOption, selectedOption, handleChange }) => {
    return (
        <HStack
            mb="4"
            flexDir={{
                base: "column-reverse",
                md: "row",
            }}
            align={{
                base: "end",
                md: "center",
            }}
            spacing={4}
        >
            <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray.300" />}
                />
                <Input
                    name="search"
                    type="tel"
                    placeholder={`Search by ${selectedOption.toLowerCase()}`}
                    style={{ marginRight: '10px' }}
                    onChange={setSearch}
                />

                <FormControl maxW="3rem">
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={<Image src={filterIcon} width={6} height={6} />}
                            variant='outline'
                        />
                        <MenuList>
                            {searchOption.map((objective, index) => (
                                <MenuItem
                                    value={objective}
                                    key={index}
                                    icon={<HamburgerIcon />}
                                    onClick={handleChange}
                                >
                                    {objective}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </FormControl>
            </InputGroup>
        </HStack>
    );
};

export default SearchAndDropdown;
