import {MicrosoftWrapContext} from "./store/WrapContext";
import React from "react";
import MicrosoftCSV from "./MicrosoftCSV";

function MicrosoftAdDetails({ activeStep, steps, prevStep, nextStep, label}) {
    return (
        <MicrosoftWrapContext>
            <MicrosoftCSV activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
        </MicrosoftWrapContext>
    )
}

export default MicrosoftAdDetails
