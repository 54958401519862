// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desktop {
    width: 900px;
    padding: 20px;
    border: 1px solid black;
    background: #ececec;
}

.desktop-top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: white;
    padding: 10px;
}

.desktop-top-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.desktop-top-header span {
    color: forestgreen;
}

.header {
    font-size: 20px;
    color: #1a0dab;
}

.fade {
    animation-name: fadeIn;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}


.sitelink-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 40px;
}

.box h2 {
    color: #1a0dab;
}

.tableData tr th:last-child {
    position: sticky;
    right: 0;
    z-index: 2;
    background: #EBF8FF;
}

.tableData tr td:last-child {
    position: sticky;
    right: 0;
    z-index: 2;
    background: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Microsoft/MicrosoftCsv/css/desktopView.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,mCAAmC;AACvC;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ;;;AAGA;IACI,aAAa;IACb,qCAAqC;IACrC,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,QAAQ;IACR,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,QAAQ;IACR,UAAU;IACV,iBAAiB;AACrB","sourcesContent":[".desktop {\r\n    width: 900px;\r\n    padding: 20px;\r\n    border: 1px solid black;\r\n    background: #ececec;\r\n}\r\n\r\n.desktop-top {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n    background: white;\r\n    padding: 10px;\r\n}\r\n\r\n.desktop-top-header {\r\n    display: grid;\r\n    grid-template-columns: repeat(2, 1fr);\r\n}\r\n\r\n.desktop-top-header span {\r\n    color: forestgreen;\r\n}\r\n\r\n.header {\r\n    font-size: 20px;\r\n    color: #1a0dab;\r\n}\r\n\r\n.fade {\r\n    animation-name: fadeIn;\r\n    animation-duration: 5s;\r\n    animation-iteration-count: infinite;\r\n}\r\n\r\n@keyframes fadeIn {\r\n    0% {\r\n        opacity: 0;\r\n    }\r\n    30% {\r\n        opacity: 1;\r\n    }\r\n    100% {\r\n        opacity: 1;\r\n    }\r\n}\r\n\r\n\r\n.sitelink-box {\r\n    display: grid;\r\n    grid-template-columns: repeat(2, 1fr);\r\n    gap: 20px 40px;\r\n}\r\n\r\n.box h2 {\r\n    color: #1a0dab;\r\n}\r\n\r\n.tableData tr th:last-child {\r\n    position: sticky;\r\n    right: 0;\r\n    z-index: 2;\r\n    background: #EBF8FF;\r\n}\r\n\r\n.tableData tr td:last-child {\r\n    position: sticky;\r\n    right: 0;\r\n    z-index: 2;\r\n    background: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
