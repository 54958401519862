import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  facebookVideoAdValueLengths,
} from "../../../../../constant/InitialValues";
import {
  facebookAccountIds,
} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import { settingDemandGenFormDetails, settingDemandGenMedia } from "./store";
import TooltipComponent from "../../TooltipComponent";
import {settingEditMediaType} from "../../../../../../../store/adUploads";

const initialValue = {
  adName: "",
  headline: "",
  description: "",
  type: "",
  link: "",
};

const schema = yup.object().shape({
  adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
  headline: yup.string().trim().min(3).max(32).optional().label("Headline"),
  description: yup
    .string()
    .trim()
    .min(3)
    .max(128)
    .required()
    .label("Description"),
  type: yup.string().required().label("Type"),
  link: yup
    .string()
    .required("Landing page url is required.")
    .max(255)
    .matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      "Landing page url is not valid!"
    )
    .label("Landing page url"),
});

function DemandGenCarouselAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.demandGenCarouselAd);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);
  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        settingDemandGenFormDetails({ ...data?.adUpload?.detail, ...values })
      );
      nextStep();
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isValid,
    handleBlur,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("headline", formDetails.headline || autoFillData?.headline || "");
    setFieldValue("description", formDetails.description || autoFillData?.description || "");
    setFieldValue("type", formDetails.type || autoFillData?.type || "");
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
  }, [formDetails, autoFillData]);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
          settingDemandGenFormDetails({
            ...data?.adUpload?.detail,
            adName: data?.adUpload?.detail?.name,
            description: data?.adUpload?.detail?.description,
            headline: data?.adUpload?.detail?.headline,
            type: data?.adUpload?.detail?.call_to_action?.type,
            link: data?.adUpload?.detail?.call_to_action?.link,
          })
      );
      dispatch(
          settingDemandGenMedia({
            carouselCards: data?.adUpload?.detail?.carouselCards,
          })
      );
      dispatch(settingEditMediaType(data?.adUpload?.ad_upload_type));
    }
  }, [data]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    htmlFor="adName"
                    color="gray"
                    fontSize="sm"
                    display={"none"}
                  >
                    Ad Name - max 512 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "  Ad Name - max 512 characters",
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={facebookVideoAdValueLengths.adName}
                      value={values.adName.length}
                      color={
                        values.adName.length >
                        facebookVideoAdValueLengths.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.adName.length >
                        facebookVideoAdValueLengths.adName
                          ? facebookVideoAdValueLengths.adName -
                            values.adName.length
                          : values.adName.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="headline" display={"none"}>
                    Headline - max 27 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="headline"
                      name="headline"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Headline (Optional) - max 27 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.headline}
                      value={values?.headline?.length}
                      color={
                        values?.headline?.length >
                        facebookVideoAdValueLengths?.headline
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.headline?.length >
                        facebookVideoAdValueLengths?.headline
                          ? facebookVideoAdValueLengths?.headline -
                          values?.headline?.length
                          : values?.headline?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" display={"none"}>
                    Description Text - max 27 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="description"
                      name="description"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Description Text (Optional) - max 27 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.description}
                      value={values?.description?.length}
                      color={
                        values?.description?.length >
                        facebookVideoAdValueLengths?.description
                          ? "red.400"
                          : "blue.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.description?.length >
                        facebookVideoAdValueLengths?.description
                          ? facebookVideoAdValueLengths?.description -
                          values?.description?.length
                          : values?.description?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" display={"none"}>
                    Landing Page URL - max 255 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL - max 255 characters",
                      }}
                      onChange={(e) => setFieldValue("link", e.target.value)}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths.landingPageURL}
                      value={values.link.length}
                      color={
                        values.link.length > facebookVideoAdValueLengths.landingPageURL
                          ? "red.400"
                          : "blue.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.link.length > facebookVideoAdValueLengths.landingPageURL
                          ? facebookVideoAdValueLengths.landingPageURL -
                            values.link.length
                          : values.link.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="type">Call to Action</FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="type"
                      name="type"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("type", e.target.value);
                      }}
                    >
                      {facebookAccountIds.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default DemandGenCarouselAdDetails;
