import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { Form, FormikProvider, useFormik } from "formik";
import calculateAspectRatios from "calculate-aspect-ratio";
import { InputControl, SelectControl } from "formik-chakra-ui";
import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ErrorModal from "../../../../../../components/PopupModal/ErrorModal";
import SuccessModal from "../../../../../../components/PopupModal/SuccessModal";
import {
  AD_UPLOAD_STATUS_COLOR,
  AD_UPLOAD_TYPE,
  FB_IMAGE_SIZE,
  FB_VIDEO_DURATION,
  FB_VIDEO_SIZE,
} from "../../../../../../constant";
import instance from "../../../../../../helpers/axios";
import { useUpdateAdUploadStatus } from "../../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import { useUploadImage } from "../../../../../../hooks/campaign-briefs/useUploadImage";
import {
  facebookVideoAdInitialValue,
  facebookVideoAdValueLengths,
} from "../../../../constant/InitialValues";
import { UploadVideoPreview } from "./UploadFiles/UploadVideoPreview";
import { facebookAccountIds } from "../../../../constant/SelectValues";
import { UploadThumbnailPreview } from "./UploadFiles/UploadThumbnailPreview";
import { useCreateAdPreview } from "../../../../../../hooks/campaign-briefs/useCreateAdPreview";
import WithAppContext from "../../../../../../helpers/appContext";

const FacebookVideoAdDetails = ({
  data,
  setTabIndex,
  tabIndex,
  handlePreviewData,
  url,
  method,
  campaignId,
  context,
}) => {
  const toast = useToast();

  const { id } = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const navigate = useNavigate();
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [landingPageURL, setLandingPageURL] = useState("");
  const { mutateAsync } = useUploadImage();
  const { mutate } = useUpdateAdUploadStatus();
  const { mutateAsync: mutateAsyncPreview } = useCreateAdPreview();
  const { setAppLoading } = context;

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    primaryText: yup
      .string()
      .trim()
      .min(3)
      .max(125)
      .required()
      .label("Primary text"),
    headline: yup.string().trim().min(3).max(32).label("Headline"),
    description: yup
      .string()
      .trim()
      .min(3)
      .max(18)
      .required()
      .label("Description"),
    videoURL: yup.object().required().nullable(),
    thumbnailURL: yup.object().required().nullable(),
    facebookAccountId: yup.string().required().label("Type"),
    landingPageURL: yup
      .string()
      .trim()
      .required("Landing page url is required.")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      )
      .label("Landing page url"),
  });

  const formik = useFormik({
    initialValues: facebookVideoAdInitialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        setAppLoading(true);
        let payload = {
          name: values.adName,
          description: values.primaryText,
          detail: {
            message: values.primaryText,
            headline: values.headline,
            link_description: values.description,
            image_url: values.thumbnailFile.imageUrl || values.thumbnailFile,
            video_id: values.videoFile.video_id || values.videoFile,
            thumbnailFile: values.thumbnailURL,
            videoFile: values.videoURL,
            call_to_action: {
              type: values.facebookAccountId,
              value: {
                link: values.landingPageURL,
              },
            },
          },
        };
        if (!data?.id) {
          payload = {
            ...payload,
            ad_upload_type: AD_UPLOAD_TYPE.FBVIDEO,
          };
        }
        await instance({
          method: method,
          url: url,
          withCredentials: false,
          data: payload,
        })
          .then((res) => {
            if (res.status === 200) {
              setSuccessModal(true);
            }
            setAppLoading(false);
          })
          .catch((error) => {
            setLandingPageURL(error.response.data.message);
            setErrorModal(true);
            setAppLoading(false);
          });
      } catch (error) {
        setAppLoading(false);
      }
    },
  });

  const {
    errors,
    values,
    isValid,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setErrors,
  } = formik;

  useEffect(() => {
    if (data && data.id) {
      const { name, description, detail } = data;
      setFieldValue("adName", name);
      setFieldValue("primaryText", description);
      setFieldValue("description", detail.link_description);
      setFieldValue("headline", detail.headline);
      setFieldValue("videoURL", detail.videoFile);
      setFieldValue("thumbnailURL", detail.thumbnailFile);
      setFieldValue("landingPageURL", detail.call_to_action.value.link);
      setFieldValue("facebookAccountId", detail.call_to_action.type);
      setFieldValue("thumbnailFile", detail.image_url);
      setFieldValue("videoFile", detail.video_id);
    }
  }, [data]);

  // show preview

  useEffect(() => {
    if (tabIndex === 1) {
      const getImages = async () => {
        setAppLoading(true);
        const payload = {
          name: values.adName,
          object_story_spec: {
            video_data: {
              message: values.primaryText,
              link_description: values.description,
              image_url: values.thumbnailFile.imageUrl || values.thumbnailFile,
              video_id: values.videoFile.video_id || values.videoFile,
              call_to_action: {
                type: values.facebookAccountId,
                value: {
                  link: values.landingPageURL,
                },
              },
            },
          },
        };

        const previewsImages = [];
        try {
          await mutateAsyncPreview(
              {
                clientId: clientId,
                campaignBriefId: campaignId,
                data: payload,
                type: "fbCarouselAd",
              },
              {
                onSuccess: (data) => {
                  if (data && data.previews) {
                    for (const i in Object.keys(data.previews)) {
                      previewsImages.push(Object.values(data.previews)[i]);
                    }
                  } else {
                    toast({
                      status: "error",
                      variant: "top-accent",
                      position: "top-right",
                      description: "Please fill all required ad details",
                    });
                  }
                  handlePreviewData(previewsImages);
                  setAppLoading(false);
                },
                onError: (error) => {
                  toast({
                    status: "error",
                    variant: "top-accent",
                    position: "top-right",
                    description: "Somethings went wrong",
                  });
                  setAppLoading(false);
                },
              }
          );
        } catch (e) {
          setAppLoading(false);
        }
      };
      getImages();
    }
  }, [tabIndex]);

  const adUploadStatusColor = (item) => {
    return item
      ? AD_UPLOAD_STATUS_COLOR[_.toUpper(item)]
      : AD_UPLOAD_STATUS_COLOR["OTHER"];
  };

  const handleStatus = (status) => {
    try {
      mutate(
          {
            clientId,
            campaignId,
            adUploadId: data?.id,
            status,
          },
          {
            onSuccess: () => {
              navigate(`/campaign-briefs/${campaignId}`, {
                state: { isShowUpload: true },
              });
            },
          }
      );
    } catch (e) {
      setAppLoading(false);
    }
  };

  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    navigate(`/campaign-briefs/${campaignId}`, {
      state: { isShowUpload: true },
    });
  };

  const onDrop = useCallback(async (accFiles) => {
    accFiles.map((file) => {
      setAppLoading(true);
      let fileType = file.type.split("/").shift();
      if (fileType === "video") {
        const objectURL = URL.createObjectURL(file);
        const mySound = new Audio([objectURL]);

        let video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          return video;
        };
        video.src = URL.createObjectURL(file);

        if (file) {
          mySound.addEventListener("canplaythrough", async () => {
            const ratio = calculateAspectRatios(
              video.videoHeight,
              video.videoWidth
            );
            if (
              (ratio === "1:1" || ratio === "5:4") &&
              file.size <= FB_VIDEO_SIZE &&
              mySound.duration <= FB_VIDEO_DURATION
            ) {
              try {
                await mutateAsync(
                    {
                      clientId: clientId,
                      campaignBriefId: id,
                      adFile: accFiles,
                      type: "fbAdVideos",
                    },
                    {
                      onSuccess: (data, variables, context) => {
                        setFieldValue("videoURL", data.file);
                        setFieldValue("videoFile", data);
                        setAppLoading(false);
                        toast({
                          status: "success",
                          variant: "top-accent",
                          position: "top-right",
                          description: "Video Uploaded Successfully.",
                        });
                      },
                      onError: () => {
                        setAppLoading(false);
                      },
                    }
                );
              } catch (e) {
                setAppLoading(false);
              }
            } else {
              setAppLoading(false);
              toast({
                status: "error",
                variant: "top-accent",
                position: "top-right",
                description: "Please upload valid video.",
              });
            }
          });
        }
      } else {
        const img = new Image();
        img.onload = async () => {
          const { naturalWidth: width, naturalHeight: height } = img;
          if (width >= 1048 && height >= 1048 && file.size <= FB_IMAGE_SIZE) {
            await mutateAsync(
              {
                clientId: clientId,
                campaignBriefId: id,
                adFile: accFiles,
                type: "fbAdImages",
              },
              {
                onSuccess: (data, variables, context) => {
                  setFieldValue("thumbnailURL", data.file);
                  setFieldValue("thumbnailFile", data);
                  setAppLoading(false);
                  toast({
                    status: "success",
                    variant: "top-accent",
                    position: "top-right",
                    description: "Image Upload Successfully.",
                  });
                },
                onError: () => {
                  setAppLoading(false);
                },
              }
            );
          }
        };
        img.src = URL.createObjectURL(file);
      }
    });
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={4}
        mb={10}
      >
        <Heading color={"gray"} fontSize="xl">
          Current status:
          <span style={{ marginLeft: "10px" }}>
            <Icon
              viewBox="0 0 200 200"
              mr={2}
              color={adUploadStatusColor(data && data.status)}
            >
              <path
                fill="currentColor"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
            {(data && data.status) || "Draft"}
          </span>
        </Heading>
        {data && _.toUpper(data.status) === "CREATED" && (
          <Box display="flex">
            <Button
              size="sm"
              mr={2}
              colorScheme="green"
              backgroundColor="green.400"
              borderRadius={4}
              disabled={!clientId || !campaignId || !data?.id}
              onClick={() => handleStatus("Approved")}
            >
              Approve
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              backgroundColor="red.400"
              borderRadius={4}
              disabled={!clientId || !campaignId || !data?.id}
              onClick={() => handleStatus("Rejected")}
            >
              Reject
            </Button>
          </Box>
        )}
      </Box>
      <Grid className="fb-upload-detail-form">
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Grid
              templateColumns="repeat(6, 1fr)"
              gap={4}
              className="fb-upload"
            >
              <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="adName">
                      Ad Name - max 512 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="adName"
                        name="adName"
                        placeholder=""
                        inputProps={{
                          variant: "outline",
                          type: "text",
                        }}
                        onBlur={handleBlur}
                      />
                      <CircularProgress
                        max={facebookVideoAdValueLengths.adName}
                        value={values.adName.length}
                        color={
                          values.adName.length >
                          facebookVideoAdValueLengths.adName
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values.adName.length >
                          facebookVideoAdValueLengths.adName
                            ? facebookVideoAdValueLengths.adName -
                              values.adName.length
                            : values.adName.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>

                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="primaryText">
                      Primary Text - max 125 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="primaryText"
                        name="primaryText"
                        placeholder=""
                        inputProps={{
                          variant: "outline",
                          type: "text",
                        }}
                      />
                      <CircularProgress
                        max={facebookVideoAdValueLengths.primaryText}
                        value={values.primaryText.length}
                        color={
                          values.primaryText.length >
                          facebookVideoAdValueLengths.primaryText
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values.primaryText.length >
                          facebookVideoAdValueLengths.primaryText
                            ? facebookVideoAdValueLengths.primaryText -
                              values.primaryText.length
                            : values.primaryText.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="headline">
                      Headline - max 32 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="headline"
                        name="headline"
                        placeholder=""
                        inputProps={{
                          variant: "outline",
                          type: "text",
                        }}
                      />
                      <CircularProgress
                        max={facebookVideoAdValueLengths.headline}
                        value={values.headline.length}
                        color={
                          values.headline.length >
                          facebookVideoAdValueLengths.headline
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values.headline.length >
                          facebookVideoAdValueLengths.headline
                            ? facebookVideoAdValueLengths.headline -
                              values.headline.length
                            : values.headline.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="description">
                      Description - max 18 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="description"
                        name="description"
                        placeholder=""
                        inputProps={{
                          variant: "outline",
                          type: "text",
                        }}
                      />
                      <CircularProgress
                        max={facebookVideoAdValueLengths.description}
                        value={values.description.length}
                        color={
                          values.description.length >
                          facebookVideoAdValueLengths.description
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values.description.length >
                          facebookVideoAdValueLengths.description
                            ? facebookVideoAdValueLengths.description -
                              values.description.length
                            : values.description.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel
                      htmlFor="landingPageURL"
                      color="gray"
                      fontSize="sm"
                    >
                      Landing Page URL - max 255 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="landingPageURL"
                        name="landingPageURL"
                        placeholder=""
                        inputProps={{
                          variant: "outline",
                          type: "text",
                        }}
                      />
                      <CircularProgress
                        max={facebookVideoAdValueLengths.landingPageURL}
                        value={values.landingPageURL.length}
                        color={
                          values.landingPageURL.length >
                          facebookVideoAdValueLengths.landingPageURL
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values.landingPageURL.length >
                          facebookVideoAdValueLengths.landingPageURL
                            ? facebookVideoAdValueLengths.landingPageURL -
                              values.landingPageURL.length
                            : values.landingPageURL.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel
                      htmlFor="facebookAccountId"
                      color="gray"
                      fontSize="sm"
                    >
                      Type
                    </FormLabel>
                    <div className="input-box">
                      <SelectControl
                        id="facebookAccountId"
                        name="facebookAccountId"
                        selectProps={{
                          placeholder: "-- Select One --",
                          variant: "outline",
                          border: "2px",
                          borderRadius: 0,
                          borderColor: "gray",
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#757998",
                          marginRight: "100px",
                        }}
                        onChange={(e) => {
                          setFieldValue("facebookAccountId", e.target.value);
                        }}
                      >
                        {facebookAccountIds.map((el) => (
                          <option value={el.key} key={el.key}>
                            {el.name}
                          </option>
                        ))}
                      </SelectControl>
                      <CircularProgress opacity={0}>
                        <CircularProgressLabel>0</CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem
                  css={css({
                    float: "right",
                    marginRight: "68px",
                  })}
                >
                  <Button
                    size="small"
                    css={css({
                      background: "#24a0ed !important",
                      borderRadius: "32px",
                      width: "134px",
                      height: "33px",
                      marginRight: "10px",
                    })}
                    disabled={!values.videoURL && !values.thumbnailURL}
                    onClick={() => setTabIndex(1)}
                  >
                    Preview
                  </Button>
                  <Button
                    size="small"
                    css={css({
                      background: "#4CAF50 !important",
                      borderRadius: "32px",
                      width: "134px",
                      height: "33px",
                    })}
                    type="submit"
                    disabled={!isValid}
                  >
                    {data?.id ? "Update Ad" : "Submit Ad"}
                  </Button>
                </GridItem>
              </GridItem>
              <GridItem w="full" colSpan={{ base: 6, lg: 2 }}>
                <Box className="file-upload-box">
                  <UploadVideoPreview
                    type="facebook-video"
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setErrors={setErrors}
                    onDrop={onDrop}
                  />
                </Box>
                <Box className="file-upload-box" marginTop="20px">
                  <UploadThumbnailPreview
                    type="facebook-video-thumbnail"
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setErrors={setErrors}
                    onDrop={onDrop}
                  />
                </Box>
              </GridItem>
            </Grid>
          </Form>
        </FormikProvider>
      </Grid>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={landingPageURL}
      />
    </>
  );
};

export default WithAppContext(FacebookVideoAdDetails);
