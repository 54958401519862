import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  dv360AdUploadalueLengths,
  dv360AdUploadInitialValues,
  pinterestAdUploadvalueLengths,
} from "../../../../../constant/InitialValues";
import {
  CreativeType,
  hostingSource,
} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import { settingPinterestFormDetails, settingPinterestMedia } from "./store";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";
import TooltipComponent from "../../TooltipComponent";

const initialValue = {
  adName: "",
  title: "",
  description: "",
  link: "",
};

function PinterestImageAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.pinterestImageAd);

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(100).required().label("Ad name"),
    title: yup.string().trim().min(3).max(100).required().label("Title"),
    description: yup.string().trim().min(3).max(500).required().label("Description"),
    link: yup
      .string()
      .trim()
      .required("Landing page url is required")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      )
      .label("Landing page url"),
  });

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (adUploadId) {
      const imageFile = data?.adUpload?.detail?.fileInfoList.map(
        (el) => el?.files
      );
      dispatch(
        settingPinterestFormDetails({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          title: data?.adUpload?.detail?.title,
          description: data?.adUpload?.detail?.description,
          link: data?.adUpload?.detail?.link,
        })
      );
      dispatch(
        settingPinterestMedia({
          imageURL: imageFile,
          fileInfoList: data?.adUpload?.detail?.fileInfoList,
        })
      );
      // dispatch(settingEditMediaType(data?.adUpload?.ad_upload_type));
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        settingPinterestFormDetails({
          ...values,
          fileInfoList: data?.adUpload?.detail?.fileInfoList,
        })
      );
      nextStep();
    },
});

  const { errors, values, touched, handleSubmit, isValid, setFieldValue } =
    formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("title", formDetails.title || autoFillData?.title || "");
    setFieldValue("description", formDetails.description || autoFillData?.description || "");
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
  }, [formDetails, autoFillData]);

  return (
    <>
      {/*{adUploadId && (*/}
      {/*  <Box*/}
      {/*    display="flex"*/}
      {/*    mb={6}*/}
      {/*    justifyContent="space-between"*/}
      {/*    alignItems="center"*/}
      {/*  >*/}
      {/*    <Heading fontSize="xl" color={"#757998"}>*/}
      {/*      Please review the ad details carefully.*/}
      {/*    </Heading>*/}
      {/*    <Heading fontSize="md" color="gray.400">*/}
      {/*      3 step to complete*/}
      {/*    </Heading>*/}
      {/*  </Box>*/}
      {/*)}*/}

      {/*{!adUploadId && (*/}
      {/*  <Box*/}
      {/*    display="flex"*/}
      {/*    mb={6}*/}
      {/*    justifyContent="space-between"*/}
      {/*    alignItems="center"*/}
      {/*  >*/}
      {/*    <Heading fontSize="xl" color={"#757998"}>*/}
      {/*      Please fill in your ad details.*/}
      {/*    </Heading>*/}
      {/*    <Heading fontSize="md" color="gray.400">*/}
      {/*      3 steps to complete*/}
      {/*    </Heading>*/}
      {/*  </Box>*/}
      {/*)}*/}

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Ad Name - max 100 characters ( Your first 40 characters are what usually show up in feeds. )",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={pinterestAdUploadvalueLengths.adName}
                      value={values.adName.length}
                      color={
                        values.adName.length >
                        pinterestAdUploadvalueLengths.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.adName.length >
                        pinterestAdUploadvalueLengths.adName
                          ? pinterestAdUploadvalueLengths.adName -
                            values.adName.length
                          : values.adName.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="title" mb={0}>Title</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="title"
                      name="title"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Title - max 100 characters ( Your first 40 characters are what usually show up in feeds. )",
                      }}
                      onChange={(e) => setFieldValue("title", e.target.value)}
                    />
                    <CircularProgress
                      max={pinterestAdUploadvalueLengths.title}
                      value={values.title.length}
                      color={
                        values.title.length >
                        pinterestAdUploadvalueLengths.title
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.title.length >
                        pinterestAdUploadvalueLengths.title
                          ? pinterestAdUploadvalueLengths.title -
                            values.title.length
                          : values.title.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" mb={0}>Description</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="description"
                      name="description"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Description - max 500 characters ( People will usually see the first 50 characters when they click on your Pin. )",
                      }}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={pinterestAdUploadvalueLengths.description}
                      value={values.description.length}
                      color={
                        values.description.length >
                        pinterestAdUploadvalueLengths.description
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.description.length >
                        pinterestAdUploadvalueLengths.description
                          ? pinterestAdUploadvalueLengths.description -
                            values.description.length
                          : values.description.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL - max 255 characters",
                      }}
                      onChange={(e) => setFieldValue("link", e.target.value)}
                    />
                    <CircularProgress
                      max={pinterestAdUploadvalueLengths.link}
                      value={values.link.length}
                      color={
                        values.link.length > pinterestAdUploadvalueLengths.link
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.link.length > pinterestAdUploadvalueLengths.link
                          ? pinterestAdUploadvalueLengths.link -
                            values.link.length
                          : values.link.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
              {/*<Button*/}
              {/*  mr={4}*/}
              {/*  onClick={prevStep}*/}
              {/*  colorScheme="blue"*/}
              {/*  variant="outline"*/}
              {/*  borderRadius={0}*/}
              {/*  px={10}*/}
              {/*>*/}
              {/*  Back*/}
              {/*</Button>*/}
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default PinterestImageAdDetails;
