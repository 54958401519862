import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const dynamicInstagramBothSlice = createSlice({
    name: AD_UPLOAD_TYPE.DYNAMIC_INSTAGRAM_BOTH,
    initialState,
    reducers: {
        settingDynamicInstagramBothFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingDynamicInstagramBothFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingDynamicInstagramBothPreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllDynamicInstagramBothField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingDynamicInstagramBothFormDetail, settingDynamicInstagramBothFormMedia, settingDynamicInstagramBothPreviewData, deleteAllDynamicInstagramBothField} = dynamicInstagramBothSlice.actions;

// The reducer
export const dynamicInstagramBothReducer = dynamicInstagramBothSlice.reducer;
