import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  dv360AdUploadalueLengths,
  dv360AdUploadInitialValues,
  facebookVideoAdValueLengths,
  pinterestAdUploadvalueLengths,
} from "../../../../../constant/InitialValues";
import {
  CreativeType,
  facebookAccountIds,
  hostingSource,
} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import { settingInstagramFormDetails, settingInstagramMedia } from "./store";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";
import { settingFacebookImageFormMedia } from "../../Facebook/FacebookImage/store";
import TooltipComponent from "../../TooltipComponent";

const initialValue = {
  adName: "",
  primaryText: "",
  type: "",
  link: "",
};

const schema = yup.object().shape({
  adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
  primaryText: yup
    .string()
    .trim()
    .trim()
    .min(3)
    .max(125)
    .required()
    .label("Primary text"),
  type: yup.string().required().label("Type"),
  headline: yup.string()
      .trim().min(3).max(32).optional().label("Headline"),
  description: yup
      .string()
      .trim()
      .min(3)
      .max(125)
      .optional()
      .label("Description"),
  link: yup
    .string()
    .trim()
    .required("Landing page url is required.")
    .max(255)
    .matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      "Landing page url is not valid!"
    )
    .label("Landing page url"),
});

function InstagramImageAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData,
  adCopyData,
  popupDetail
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.instagramImageAd);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);
  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingInstagramFormDetails({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          primaryText: data?.adUpload?.detail?.message,
          headline: data?.adUpload?.detail?.headline,
          description: data?.adUpload?.detail?.description,
          type: data?.adUpload?.detail?.call_to_action?.type,
          link: data?.adUpload?.detail?.call_to_action?.value?.link,
        })
      );
      dispatch(
        settingInstagramMedia({
          thumbnailURL: data?.adUpload?.detail?.thumbnailFile,
          thumbnailFile: data?.adUpload?.detail?.image_hash,
        })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        settingInstagramFormDetails({ ...data?.adUpload?.detail, ...values })
      );
      nextStep();
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isValid,
    handleBlur,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("primaryText", adCopyData?.primary_text ||formDetails.primaryText || autoFillData?.primaryText || "");
    setFieldValue("headline", adCopyData?.headline || formDetails.headline || autoFillData?.headline || "");
    setFieldValue("description", adCopyData?.description ||formDetails.description || autoFillData?.description || "");
    setFieldValue("type", popupDetail.cta|| formDetails.type || autoFillData?.type || "");
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
  }, [formDetails, autoFillData , adCopyData , popupDetail]);

  return (
    <>
      {/*{adUploadId && (*/}
      {/*  <Box*/}
      {/*    display="flex"*/}
      {/*    mb={6}*/}
      {/*    justifyContent="space-between"*/}
      {/*    alignItems="center"*/}
      {/*  >*/}
      {/*    <Heading fontSize="xl" color={"#757998"}>*/}
      {/*      Please review the ad details carefully.*/}
      {/*    </Heading>*/}
      {/*    <Heading fontSize="md" color="gray.400">*/}
      {/*      3 step to complete*/}
      {/*    </Heading>*/}
      {/*  </Box>*/}
      {/*)}*/}

      {/*{!adUploadId && (*/}
      {/*  <Box*/}
      {/*    display="flex"*/}
      {/*    mb={6}*/}
      {/*    justifyContent="space-between"*/}
      {/*    alignItems="center"*/}
      {/*  >*/}
      {/*    <Heading fontSize="xl" color={"#757998"}>*/}
      {/*      Please fill in your ad details.*/}
      {/*    </Heading>*/}
      {/*    <Heading fontSize="md" color="gray.400">*/}
      {/*      3 steps to complete*/}
      {/*    </Heading>*/}
      {/*  </Box>*/}
      {/*)}*/}

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    htmlFor="adName"
                    color="gray"
                    fontSize="sm"
                    display={"none"}
                  >
                    Ad Name - max 512 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "  Ad Name - max 512 characters",
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={facebookVideoAdValueLengths.adName}
                      value={values.adName.length}
                      color={
                        values.adName.length >
                        facebookVideoAdValueLengths.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.adName.length >
                        facebookVideoAdValueLengths.adName
                          ? facebookVideoAdValueLengths.adName -
                            values.adName.length
                          : values.adName.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="primaryText" display={"none"}>
                    Primary Text - max 125 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="primaryText"
                      name="primaryText"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Primary Text - max 125 characters",
                      }}
                      onChange={(e) =>
                        setFieldValue("primaryText", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths.primaryText}
                      value={values.primaryText.length}
                      color={
                        values.primaryText.length >
                        facebookVideoAdValueLengths.primaryText
                          ? "red.400"
                          : "blue.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.primaryText.length >
                        facebookVideoAdValueLengths.primaryText
                          ? facebookVideoAdValueLengths.primaryText -
                            values.primaryText.length
                          : values.primaryText.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="headline" display={"none"}>
                    Headline - max 27 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                        id="headline"
                        name="headline"
                        placeholder=""
                        inputProps={{
                          variant: "outline",
                          type: "text",
                          placeholder: "Headline (Optional) - max 27 characters",
                        }}
                    />
                    <CircularProgress
                        max={facebookVideoAdValueLengths?.headline}
                        value={values?.headline?.length}
                        color={
                          values?.headline?.length >
                          facebookVideoAdValueLengths?.headline
                              ? "red.400"
                              : "green.400"
                        }
                    >
                      <CircularProgressLabel>
                        {values?.headline?.length >
                        facebookVideoAdValueLengths?.headline
                            ? facebookVideoAdValueLengths?.headline -
                            values?.headline?.length
                            : values?.headline?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" display={"none"}>
                    Description Text - max 27 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                        id="description"
                        name="description"
                        placeholder=""
                        inputProps={{
                          variant: "outline",
                          type: "text",
                          placeholder: "Description Text (Optional) - max 27 characters",
                        }}
                    />
                    <CircularProgress
                        max={facebookVideoAdValueLengths?.description}
                        value={values?.description?.length}
                        color={
                          values?.description?.length >
                          facebookVideoAdValueLengths?.description
                              ? "red.400"
                              : "blue.400"
                        }
                    >
                      <CircularProgressLabel>
                        {values?.description?.length >
                        facebookVideoAdValueLengths?.description
                            ? facebookVideoAdValueLengths?.description -
                            values?.description?.length
                            : values?.description?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" display={"none"}>
                    Landing Page URL - max 255 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL - max 255 characters",
                      }}
                      onChange={(e) => setFieldValue("link", e.target.value)}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths.link}
                      value={values.link.length}
                      color={
                        values.link.length > facebookVideoAdValueLengths.link
                          ? "red.400"
                          : "blue.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.link.length > facebookVideoAdValueLengths.link
                          ? facebookVideoAdValueLengths.link -
                            values.link.length
                          : values.link.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="type">Call to Action</FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="type"
                      name="type"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("type", e.target.value);
                      }}
                    >
                      {facebookAccountIds.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            {/*{!adUploadId && (*/}
            {/*  <Button*/}
            {/*    mr={4}*/}
            {/*    onClick={prevStep}*/}
            {/*    colorScheme="blue"*/}
            {/*    variant="outline"*/}
            {/*    borderRadius={0}*/}
            {/*    px={10}*/}
            {/*  >*/}
            {/*    Back*/}
            {/*  </Button>*/}
            {/*)}*/}
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default InstagramImageAdDetails;
