import React from 'react';
import {Box, Flex, GridItem, Heading, Image, SkeletonCircle, Text} from "@chakra-ui/react";
import {Grid} from "@chakra-ui/layout";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import ReactPlayer from "react-player";

function Image72890(props) {
    const { formDetails, mediaDetails, handlePreviewData} = props;
    const selectedLogos = formDetails?.channelIcon?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads/` + formDetails.channelIcon.imageUrl :
        process.env.PUBLIC_URL + "/images/display.png";

    return (
        <>
            <Flex bg='white' borderRadius='5' h='90px' w='728px' px='1'>
                <Flex gap='3' overflow="hidden">
                    <Flex alignItems='center' justifyContent='center' w={"40%"}>
                        {!props.video ? <Image src={handlePreviewData[0].imageUrl}/> :
                            <ReactPlayer
                                className='react-player videos'
                                url={handlePreviewData}
                                playing={true}
                                loop={true}
                            />
                        }
                    </Flex>
                    <Box position='relative' overflow='hidden' w={"40%"}>
                        <Flex alignItems='center' gap='2' pt='1'>
                            <Image src={selectedLogos} height={8}
                                   width={8} rounded="full"/>
                            <Flex direction='column'>
                                <Text fontSize='11' fontWeight='600'>{formDetails.channel_name}</Text>
                                <Text fontSize='10' color='gray.500'>10.6k followers</Text>
                            </Flex>
                        </Flex>
                        <Box mt='1' fontSize='12' px='2'>{formDetails.caption}</Box>
                        <Box bg='white' py='1' position='absolute' bottom='0' zIndex='3' w='100%'>
                            <Flex alignItems='center' gap='2'>
                                <Flex alignItems='center'>
                                    <Box><Image src={`${process.env.PUBLIC_URL}/images/fb_like.png`} height={4} width={4}/></Box>
                                    <Box marginStart='-1' zIndex='-1'><Image src={`${process.env.PUBLIC_URL}/images/love.png`} height={4} width={4}/></Box>
                                    <Box marginStart='-1' zIndex='-2'><Image src={`${process.env.PUBLIC_URL}/images/surprised.png`} height={4} width={4} /></Box>
                                </Flex>
                                <Text fontSize='10' fontWeight='bold' color='#a3a3a3'>11 &nbsp; 1 Comment</Text>
                            </Flex>
                        </Box>
                    </Box>
                    <Flex direction='column' bg='#f8f8f8' alignItems='center' justifyContent='center' my='1' w={"20%"}>
                        <Text fontSize='10' fontWeight='600' align='center'>{formDetails.headline}</Text>
                        <Text fontSize='10' align='center'>{formDetails.description}</Text>
                        <Box bg='#e5e7ea' borderRadius='3' py='1' px='2' mt='1'>
                            <Text fontSize='10' fontWeight='bold'>{youtubeShortsTypes.find((i) => i.key === formDetails?.type)?.name}</Text>
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}

export default Image72890;
