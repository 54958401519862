import {
    Button,
    css,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    Box,
} from "@chakra-ui/react";
import * as React from "react";
import _ from "lodash";
import {AD_UPLOAD_STATUS} from "../../constant";

const ApproveModel = ({ name, isOpen, onClose, onDestroy, status }) => {
    const statusToShow = status === _.capitalize(AD_UPLOAD_STATUS.APPROVED) ? "approve" : "reject"
    return (
        <Modal isCentered isOpen={isOpen} onClose={onDestroy} size="md">
            <ModalOverlay />
            <ModalContent
                position="relative"
                padding={"30px "}
                mx={{ base: "6", md: "0" }}
            >
                <Text
                    cursor={"pointer"}
                    onClick={onDestroy}
                    style={{
                        fontSize: "32px",
                        position: "absolute",
                        top: "5%",
                        right: "5%",
                    }}
                >
                    {" "}
                    &times;{" "}
                </Text>
                <ModalBody
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    })}
                >
                    <Heading size="md" margin="15px 0">
                        {_.capitalize(statusToShow) + " brief"}?
                    </Heading>
                    <Box textAlign={"center"}>
                        <Text>Are you sure you want to {statusToShow} "{name}"? </Text>
                        <Text> You can't undo this action. </Text>
                    </Box>
                </ModalBody>
                <Flex
                    justifyContent={"center"}
                    gap={"20px"}
                    marginBottom={"30px"}
                    marginTop={"30px"}
                >
                    <Button
                        width={"35%"}
                        variant={"outline"}
                        onClick={onDestroy}
                        borderRadius={"4px"}
                        padding={"10px 40px"}
                    >
                        Back
                    </Button>
                    <Button
                        width={"35%"}
                        css={css({
                            background: "#F9665E !important",
                            color: "white",
                        })}
                        padding={"10px 40px"}
                        borderRadius={"4px"}
                        onClick={onClose}
                    >
                        {_.capitalize(statusToShow)}
                    </Button>
                </Flex>
            </ModalContent>
        </Modal>
    );
};

export default ApproveModel;
