import {Box, Button} from "@chakra-ui/react";
import React, {useCallback, useEffect, useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import {FACEBOOK_IMAGE_HEIGHT, FACEBOOK_IMAGE_WIDTH} from "../../../../../../../constant";
import * as yup from "yup";
// import DV360ImageAdFileUpload from "./DV360ImageAdFileUpload";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import WithAppContext from "../../../../../../../helpers/appContext";
import {settingDynamicFacebookImageFormMedia} from "./store";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import DynamicFacebookImageAdFileUpload from "./DynamicFacebookImageAdFileUpload";


function DynamicFacebookImageAdMedia({activeStep, steps, prevStep, nextStep, label, context}) {

    const { adUploadId } = useParams()

    const { mediaDetails } = useSelector((state) => state.dynamicFacebookImageAd)

    const schema = yup.object().shape({
        selectedImages: yup.array().of(
            yup.object().shape({
                // thumbnailFile: yup.string().required('Thumbnail File is required'),
                // thumbnailURL: yup.string().required('thumbnail URL is required'),
            })
        ).min(1, 'At least one image must be selected'
        ).max(12, 'Maximum of 12 images can be selected'),
        // thumbnailURL: yup.object().nullable(),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            selectedImages: []
        },
        validationSchema: schema,
        onSubmit: (values => {
            console.log('Vaulues Here', values)
            dispatch(settingDynamicFacebookImageFormMedia({
                selectedImages: values?.selectedImages, ...values
            }))
            nextStep()
        })
    });

    const {setAppLoading, SuccessToaster, ErrorToaster, appLoading} = context
    const clientId = useSelector((state) => state.client.clientId);
    const {id} = useParams();
    const {mutateAsync} = useUploadImage();

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setErrors,
        setFieldValue,
    } = formik;


    useEffect(() => {
        setFieldValue("selectedImages", mediaDetails?.selectedImages ? mediaDetails?.selectedImages : []);
        setFieldValue("thumbnailURL", mediaDetails?.thumbnailURL ? mediaDetails?.thumbnailURL : null);
        setFieldValue("thumbnailFile", mediaDetails?.thumbnailFile ? mediaDetails?.thumbnailFile: null);
    }, [mediaDetails])


    const onDrop = useCallback(async (accFiles) => {
        console.log('file----->', accFiles)
        const img = new Image()
        setAppLoading(true)
        img.onload = async () => {
            const {naturalWidth: width, naturalHeight: height} = img
            if (width < FACEBOOK_IMAGE_WIDTH && height < FACEBOOK_IMAGE_HEIGHT) {
                ErrorToaster('Minimum 600px image width and height')
                setAppLoading(false)
            } else {
                setAppLoading(true);
                try {
                    await mutateAsync(
                        {
                            clientId: clientId,
                            campaignBriefId: id,
                            adFile: accFiles,
                            type: "multipleFbAdImages",
                        }, {
                            onSuccess: (data) => {
                                const dataHere = [...values.selectedImages, ...data];
                                if (dataHere.length > 12) {
                                    ErrorToaster('Maximum of 12 images can be selected')
                                } else {
                                    setFieldValue('selectedImages', dataHere);
                                    setFieldValue('thumbnailURL', data[0].file)
                                    setFieldValue('thumbnailFile', data[0])
                                    SuccessToaster('Image Upload Successfully.')
                                }
                                setAppLoading(false);
                            },
                            onError: (error) => {
                                console.log("error", error);
                                // ErrorToaster(error || 'something went wrong')
                                setAppLoading(false);
                            }
                        });
                } catch (e) {
                    setAppLoading(false);
                }
            }
        }
        accFiles.map((file) => {
            img.src = URL.createObjectURL(file)
        })
    }, [values]);

    useEffect(() => {
        if (values.selectedImages.length === 0) {
            setErrors({"selectedImages": "At least one image must be selected"})
        }
    },[values])

    console.log('Selected Images----->', values.selectedImages)
    console.log('Error------------>', errors)
    return (
    <>
        {appLoading && <FullPageLoader/>}

        <Box className="file-upload-box">

            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <DynamicFacebookImageAdFileUpload
                        getHashArray={(value) => {
                            setFieldValue('thumbnailURL', value)
                        }}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        type="facebookImage"
                        values={values.selectedImages}
                        setErrors={setErrors}
                        onDrop={onDrop}
                    />
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10} isDisabled={errors.selectedImages}>
                            Next
                        </Button>
                    </Flex>
                </Form>
            </FormikProvider>
        </Box>
    </>
    )
}

export default WithAppContext(DynamicFacebookImageAdMedia)
