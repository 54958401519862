import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Image,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Checkbox
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTheField, handleAllCampaignIds,
  selectSocialMedia,
} from "../../../../../store/adUploads";
import {useNavigate, useParams} from "react-router-dom";
import {useGetSingleCampaign} from "../../../../../hooks/campaign-briefs/useGetSingleCampaign";
import {
  settingBusinessDetails,
  settingCampaignBudget,
  settingCampaignDetails,
  settingCampaignObjective,
  settingDemographics, settingDemographyDetails, settingMediaStrategies, settingMediaStrategyDetails, settingNotes
} from "../../../../../store/adCampaign";
import axios from "../../../../../helpers/axios";
import _ from "lodash";
import CreativeType from "../SetupCampaign/CreativeType";
import CreativeDetails from "../SetupCampaign/CreativeDetails";
import {useGetCampaignList} from "../../../../../hooks/campaign-briefs/useGetCampaignList";

function SocialPlatform({ activeStep, steps, prevStep, nextStep, label , DV360YoutubeStep }) {
  const { id } = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const { data, refetch } = useGetSingleCampaign(clientId, id);
  const { campaignBudget } = useSelector((state) => state.adCampaign);
  const { selectedSocialMedia, creative } = useSelector(
      (state) => state.adUpload
  );

  const [isCreativeTypeOpen, setIsCreativeTypeOpen] = useState(false);

  const openCreativeType = (item) => {
    setIsCreativeTypeOpen(true);
  };

  const { data: allCampaigns, refetch: refetchAllCampaigns } = useGetCampaignList(clientId);
  const [allIds, setAllIds] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [campaignOptionIds, setCampaignOptionIds] = useState([]);

  useEffect(() => {
    if (allCampaigns) {
      setCampaignData(allCampaigns?.campaigns);
    }
  }, [allCampaigns]);


  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const allIdsParam = searchParams.get('allIds');

    if (allIdsParam) {
      const campaignIds = allIdsParam.split(',');
      setAllIds(campaignIds);
      // setSelectedCampaigns(campaignIds);
    }
  }, []);

  useEffect(() => {
    refetch();
  }, [clientId]);

  useEffect(() => {
    if (data && data.campaign) {
      setAllData();
      getSingleBusiness(data?.campaign?.business_id);
    }
  }, [data]);

  let commonChannels = [];
  let campaigns = [];
  if (campaignData && allIds.length) {
    const singleCampaign =  campaignData?.filter((item) => item.id === id);
    campaigns =  singleCampaign[0]?.subRows;

    if (campaigns?.length >= 2) {
      const channels1 = campaigns[0]?.detail?.campaignBudget?.selectedChannels || [];
      const channels2 = campaigns[1]?.detail?.campaignBudget?.selectedChannels || [];

      commonChannels = channels1.concat(channels2);

      console.log("Common channels are:", commonChannels);
    } else {
      console.log("Not enough campaigns to compare.");
    }
  }


  const channels = [
    ...new Set(allIds.length ? commonChannels?.map((e) => e.channel && e.channel.toLowerCase().replace(/ /g, "_"))
                                   : campaignBudget?.selectedChannels?.map((e) => e.channel && e.channel.toLowerCase().replace(/ /g, "_"))
    ),
  ];

  let CampOptionIds = [];
  const openModal = (item) => {
    CampOptionIds = getCampaignOptions(item);
    setCampaignOptionIds(CampOptionIds);
    setIsModalOpen(true);
  }

  function getCampaignOptions(item) {
    const campOptions = [];

    campaigns?.map((el)=>{
      const channelNames = el?.detail?.campaignBudget?.selectedChannels.map((element)=>{
        return element.channel.toLowerCase().replace(/ /g, "_")})
      if(channelNames.includes(item)){
        campOptions.push(el.id);
      }
    })
    return campOptions;
  }

  function handleClick(index, adType) {
    handleSelect(index, adType);
  }

  function setAllData() {
    const { detail } = data.campaign;
    dispatch(settingCampaignDetails(detail.campaignDetails));
    dispatch(settingCampaignObjective(detail.objective));
    dispatch(settingCampaignBudget(detail.campaignBudget));
    dispatch(settingDemographics(detail.demographics));
    dispatch(settingMediaStrategies(detail.mediaStrategies));
    dispatch(settingDemographyDetails(detail.demographicDetails));
    dispatch(settingMediaStrategyDetails(detail.mediaStrategyDetails));
    dispatch(settingNotes(detail.notes));
  }

  function getSingleBusiness(id) {
    axios
        .get(`/client/business/${id}`, {
          withCredentials: false,
        })
        .then((res) => {
          if (res.status === 200 && !_.isEmpty(res.data.data.business)) {
            dispatch(settingBusinessDetails(res.data.data.business));
          }
        })
        .catch((err) => {
          return err.response;
        });
  }

  const dispatch = useDispatch();

  const [selectedTab, setSelectTab] = useState();

  function handleSelect(index, adType) {
    setSelectTab(adType);
    dispatch(selectSocialMedia(adType));
  }

  useEffect(() => {
    dispatch(deleteTheField());
  }, []);

  const handleCheckboxChange = (e, campaignId) => {
    if (e.target.checked) {
      setSelectedCampaigns((prev) => [...prev, campaignId]);
      console.log("Selected Campaigns are", selectedCampaigns);
    } else {
      setSelectedCampaigns((prev) => prev.filter((id) => id !== campaignId));
    }
  };

  const handleSubmit = () => {
    const selectedIds = selectedCampaigns.join(",");
    if(selectedCampaigns.length == 1){
      const newUrl = `/campaign-briefs/${selectedCampaigns[0]}/ad-upload/new?allIds=${selectedIds}`;
      navigate(newUrl);
    } else {
      const newUrl = `/campaign-briefs/${id}/ad-upload/new?allIds=${selectedIds}`;
      navigate(newUrl);
    }
    setIsModalOpen(false);
    const { detail } = data.campaign;
    const channels = [
      ...new Map(commonChannels.map(item => [item.name, item])).values()
    ];
    const campaignBudget = {
      ...detail.campaignBudget,
      selectedChannels: channels
    }
    dispatch(settingCampaignBudget(campaignBudget));
    dispatch(handleAllCampaignIds(selectedCampaigns))
    openCreativeType();
  };

  return (
      <Box w="100%" p={4}>
        <Box
            display="flex"
            mb={6}
            justifyContent="space-between"
            alignItems="center"
        >
          {!isCreativeTypeOpen && ( <Heading fontSize="3xl" color={"#757998"}>
            List of available channels
          </Heading>)}

        </Box>

        {!isCreativeTypeOpen && (
            <SimpleGrid columns={[1, 1, 1, 2]} spacing="20px">
              {channels &&
              channels.map((item, index) => {
                return (
                    <Box
                        cursor={"pointer"}
                        border={"2px solid rgba(167, 169, 189, 0.2)"}
                        style={selectedTab === item ? { borderColor: "blue" } : null}
                        borderRadius={"10px"}
                        height="80px"
                        display={"flex"}
                        p={4}
                        key={item}
                        onClick={() => handleClick(index, item)}
                        onDoubleClick={() => allIds.length ? openModal(item) : openCreativeType(item)}
                    >
                      <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap={"10px"}
                          color={"#757998"}
                      >
                        <Image
                            src={
                              channels.includes(item)
                                  ? `${process.env.PUBLIC_URL}/images/${item}.png`
                                  : `${process.env.PUBLIC_URL}/images/display.png`
                            }
                            height="40px"
                        />
                        {item && item.replace(/_/g, " ")}
                      </Box>
                    </Box>
                );
              })}
            </SimpleGrid>
        )}

        {isCreativeTypeOpen && (
            <Box>
              <CreativeDetails
                  activeStep={activeStep}
                  steps={steps}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  label="Creative Type"
              />
            </Box>
        )}

        <Modal isCentered isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Select Campaign</ModalHeader>
            <ModalBody>
                {campaigns && campaigns.map((el) => {
                  return campaignOptionIds.some(campId=> el?.id === campId) ? (
                    <Checkbox
                        key={el.id}
                        // isChecked={selectedCampaigns.includes(el.id)}
                        onChange={(e) => handleCheckboxChange(e, el.id)}
                        width="100%"
                        textAlign="center"
                    >
                      {el?.hasSubRows ? el.name+"_"+el?.detail?.demographics?.location?.locationGroup : el.name}
                    </Checkbox>
                  )
                  : null
                })}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" isDisabled={selectedCampaigns.length < 1} onClick={handleSubmit} me={2}>
                Submit
              </Button>
              <Button onClick={() => setIsModalOpen(false)}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Flex width="100%" justify="flex-end" py={10}></Flex>
      </Box>
  );
}

export default SocialPlatform;
