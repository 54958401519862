import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  dv360AdUploadalueLengths,
  dv360AdUploadInitialValues,
  dv360AdVideoInitialValue,
  dv360AudioAdUploadInitialValues,
} from "../../../../../constant/InitialValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";
import { settingDV360YoutubeVideo } from "./store";
import TooltipComponent from "../../TooltipComponent";

function DV360YoutubeVideoDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData
}) {
  const { id, adUploadId } = useParams();

  const [method, setMethod] = useState();
  const [url, setUrl] = useState();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.dv360YoutubeVideo);
  const { mediaType } = useSelector((state) => state.adUpload);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingDV360YoutubeVideo({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
        })
      );
    }
  }, [data]);

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).required("Ad name is required"),
    landingPageURL: yup
      .string()
      .trim()
      .required("Landing page url is required.")
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid! for example https://example.com"
      ),
    youtubeURL: yup
      .string()
      .trim()
      .required("Youtube url is required")
      .matches(
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
        "Youtube url is not valid! for example https://example.com"
      ),
  });

  useEffect(() => {
    refetch();
  }, []);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: dv360AdVideoInitialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(settingDV360YoutubeVideo(values));
      // nextStep(activeStep + 2)
      nextStep();
    },
  });

  const { errors, values, isValid, handleSubmit, handleBlur, setFieldValue } =
    formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("landingPageURL", formDetails.landingPageURL || autoFillData?.landingPageURL || "");
    setFieldValue("youtubeURL", formDetails.youtubeURL || autoFillData?.youtubeURL || "");
  }, [formDetails, autoFillData]);

  return (
    <>
      {/*{adUploadId && (*/}
      {/*  <Box*/}
      {/*    display="flex"*/}
      {/*    mb={6}*/}
      {/*    justifyContent="space-between"*/}
      {/*    alignItems="center"*/}
      {/*  >*/}
      {/*    <Heading fontSize="xl" color={"#757998"}>*/}
      {/*      Please review the ad details carefully.*/}
      {/*    </Heading>*/}
      {/*    <Heading fontSize="md" color="gray.400">*/}
      {/*      2 steps to complete*/}
      {/*    </Heading>*/}
      {/*  </Box>*/}
      {/*)}*/}

      {/*{!adUploadId && (*/}
      {/*  <Box*/}
      {/*    display="flex"*/}
      {/*    mb={6}*/}
      {/*    justifyContent="space-between"*/}
      {/*    alignItems="center"*/}
      {/*  >*/}
      {/*    <Heading fontSize="xl" color={"#757998"}>*/}
      {/*      Please fill in your ad details.*/}
      {/*    </Heading>*/}
      {/*    <Heading fontSize="md" color="gray.400">*/}
      {/*      2 steps to complete*/}
      {/*    </Heading>*/}
      {/*  </Box>*/}
      {/*)}*/}

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 512 characters",
                      }}
                      onBlur={handleBlur}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={dv360AdUploadalueLengths.adName}
                      value={values.adName.length}
                      color={
                        values.adName.length > dv360AdUploadalueLengths.adName
                          ? "red.400"
                          : "blue.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.adName.length > dv360AdUploadalueLengths.adName
                          ? dv360AdUploadalueLengths.adName -
                            values.adName.length
                          : values.adName.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="youtubeURL" mb={0}>Youtube URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="youtubeURL"
                      name="youtubeURL"
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Youtube URL",
                      }}
                    />
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="landingPageURL" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="landingPageURL"
                      name="landingPageURL"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL",
                      }}
                    />
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            {/*{!adUploadId && (*/}
            {/*  <Button*/}
            {/*    mr={4}*/}
            {/*    onClick={prevStep}*/}
            {/*    colorScheme="blue"*/}
            {/*    variant="outline"*/}
            {/*    borderRadius={0}*/}
            {/*    px={10}*/}
            {/*  >*/}
            {/*    Back*/}
            {/*  </Button>*/}
            {/*)}*/}

              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default DV360YoutubeVideoDetails;
