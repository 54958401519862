import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentClientDetails: {}
};

export const aiAnalyticsSlice = createSlice({
    name: "ai-analytics",
    initialState,
    reducers: {
        settingCurrentClientDetail: (state, action) => {
            state.currentClientDetails = action.payload
        },
        deleteCurrentClientDetail: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingCurrentClientDetail, deleteCurrentClientDetail} = aiAnalyticsSlice.actions;

// The reducer
export const currentClientDetailReducer = aiAnalyticsSlice.reducer;
