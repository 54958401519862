import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
    handleFacebookPreviewData: null,
    handleInstagramPreviewData: null,
};

export const metaCarouselSlice = createSlice({
    name: AD_UPLOAD_TYPE.META_CAROUSEL,
    initialState,
    reducers: {
        settingMetaCarouselForm: (state, action) => {
            state.formDetails = action.payload
        },
        settingMetaCarouselMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingMetaCarouselPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        settingMetaFacebookCarouselPreview: (state, action) => {
            state.handleFacebookPreviewData = action.payload
        },
        settingMetaInstagramCarouselPreview: (state, action) => {
            state.handleInstagramPreviewData = action.payload
        },
        deleteAllMetaCarouselField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingMetaCarouselForm, settingMetaCarouselMedia, settingMetaCarouselPreview, deleteAllMetaCarouselField, settingMetaInstagramCarouselPreview, settingMetaFacebookCarouselPreview} = metaCarouselSlice.actions;

// The reducer
export const metaCarouselReducer = metaCarouselSlice.reducer;
