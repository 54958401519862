import { CloseIcon, DownloadIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button, Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip
} from "@chakra-ui/react";
import FileSaver from 'file-saver';
import React, { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import {BLUE_BUTTON_COLOR, GREY_TEXT_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import JPGIcon from "../../../../../../../assets/images/jpg.svg";
import PNGIcon from "../../../../../../../assets/images/png.svg";

export function UploadVideoThumbnail({data, onDrop, errors, touched, values, setFieldValue }) {
  const [thumbnailFile, setThumbnailFile] = useState('')
  const [fileName, setFileName] = useState('')

  const acceptImageFiles = {
    "image/png": [".png"],
    "image/jpeg": [".jpg"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptImageFiles,
    maxSize: 30 * 1024 * 1024,
    disabled: values.thumbnailURL && true,
  });

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    if (values && values.thumbnailURL) {
      const file  = values.thumbnailURL;
      const getFileURL =  file && `${process.env.REACT_APP_API_URL}/uploads/${file?.filename}`;
      const getFileName = file?.originalname
      setThumbnailFile(getFileURL)
      setFileName(getFileName)
    }
  }, [values])

  return (
    <>
      <Grid
        border='2px'
        borderColor={(errors.thumbnailURL && touched.thumbnailURL) ? 'red.500' : 'gray.200'}
        padding="20px"
        borderStyle="dashed"
      >
        <div {...getRootProps()}>
          <Input {...getInputProps()} />
          <Box className="file-upload-content" justifyContent="center" display="flex" flexDirection="column" alignItems="center" textAlign="center" >
            <Image
              boxSize="100px"
              objectFit="cover"
              src={FileUploadIcon}
              alt="upload-icon"
            />
            <Heading fontSize="xl" display="flex" alignItems="center" my={4} color={GREY_TEXT_COLOR}>
              Drag thumbnail files to upload
              <Tooltip label={
                <Box>
                  <Text color="#000"><b>Image file type:</b> JPG or PNG</Text>
                  <Text color="#000"><b>Resolution:</b> At least 1080 x 1080 pixels</Text>
                  <Text color="#000"><b>Image maximum file size:</b> 30 MB</Text>
                </Box>} closeDelay={500} bg='#e2e8f0'>
                <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px" />
              </Tooltip>
            </Heading>
            <Text fontSize="sm" mb={1} color={GREY_TEXT_COLOR}>
              - OR -
            </Text>
            <Button onClick={() => {
            }} disabled={values.thumbnailURL && true}  bgColor={BLUE_BUTTON_COLOR} color="white" size="small" mt={4} px={10} py={2} borderRadius={100}>Browse Files</Button>
          </Box>
        </div>
      </Grid>
      {(errors.thumbnailURL && touched.thumbnailURL) &&
        <Text color="red.500" marginTop="2" textAlign={"center"}>
          Card image is required
        </Text>
      }
      {values.thumbnailURL &&
        <Grid className="file-box">
          <Flex marginTop="5" marginBottom="5">
            {
              values?.imageURL?.type === "image/jpeg" && <Image
                  boxSize="50px"
                  objectFit="cover"
                  src={JPGIcon}
                  alt="Dan Abramov"
              />
            }
            {
              values?.imageURL?.type === "image/png" && <Image
                  boxSize="50px"
                  objectFit="cover"
                  src={PNGIcon}
                  alt="Dan Abramov"
              />
            }
            {
              values?.imageURL?.mimetype === "image/jpeg" && <Image
                  boxSize="50px"
                  objectFit="cover"
                  src={JPGIcon}
                  alt="Dan Abramov"
              />
            }
            {
              values?.imageURL?.mimetype === "image/png" && <Image
                  boxSize="50px"
                  objectFit="cover"
                  src={PNGIcon}
                  alt="Dan Abramov"
              />
            }
            <Grid marginLeft="2">
              <Grid>
                <Text
                  fontSize="15px"
                  textOverflow="ellipsis"
                  overflow='hidden'
                  whiteSpace="nowrap"
                  className="file-name"
                >
                  {values.thumbnailURL.originalname}
                </Text>
              </Grid>
              <Text fontSize="sm">
                {formatBytes(values.thumbnailURL.size)}
              </Text>
            </Grid>
            <Spacer />
            <Grid>
              <Grid justifyContent="center">
                <CloseIcon
                  cursor="pointer"
                  className="close-icon"
                  size="small"
                  onClick={() => {
                    setFieldValue('thumbnailURL', '')
                  }}
                />
              </Grid>
              <Grid>
                <DownloadIcon
                  onClick={() => FileSaver.saveAs(thumbnailFile, fileName)}
                  cursor="pointer"
                  className="close-icon"
                  size="small"
                  w={6}
                  h={6}
                />
              </Grid>
            </Grid>
          </Flex>
          <Box marginTop={"-20px"}>
            <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
            <Progress
                className="loading"
                value={100}
                colorScheme='green'
                size='sm'
            />
          </Box>
        </Grid >
      }
    </>
  );
}
