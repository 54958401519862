import React, { useEffect, useState } from "react";
import { HStack, Heading } from "@chakra-ui/layout";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Select, Text,
  useToast,
} from "@chakra-ui/react";
import CampaignModal from "../../layout/components/CampaignModal";
import { useGetClientBusiness } from "../../hooks/clients/useGetClientBusiness";
import { useDispatch, useSelector } from "react-redux";
import instance from "../../helpers/axios";
import * as yup from "yup";
import { Form, Formik } from "formik";
import InputBox from "../../components/InputBox";
import {
  settingCampaignBudget,
  settingCampaignDetails,
  settingDemographics,
} from "../../store/adCampaign";
import "./adCampaign.css";
import _ from "lodash";

const initialValue = {
  campaignName: "",
  selectedBusiness: "",
};

const schema = yup.object().shape({
  campaignName: yup.string().trim().min(3).required().label("Campaign name"),
  selectedBusiness: yup.string().required("Campaign Business is required"),
});

function CampaignDetails({
  activeStep,
  prevStep,
  nextStep,
  handleNotes,
  getSingleBusiness,
}) {
  const dispatch = useDispatch();

  const { campaignDetails, campaignBudget, demographics, businessDetails } =
    useSelector((state) => state.adCampaign);
  const [initialFormValue, setInitialFormValue] = useState(initialValue);

  const [open, setOpen] = useState(false);
  const [business, setBusiness] = useState([]);
  const clientId = useSelector((state) => state.client.clientId);
  const toast = useToast();

  const { data, refetch } = useGetClientBusiness(clientId);

  useEffect(() => {
    if (campaignDetails.campaignName) {
      setInitialFormValue({ ...campaignDetails, selectedBusiness: businessDetails.id });
    } else {
      setInitialFormValue(initialValue);
    }
  }, [campaignDetails, businessDetails]);

  useEffect(() => {
    refetch();
  }, [clientId]);

  useEffect(() => {
    setBusiness(data?.businesses);
    if (_.isEmpty(businessDetails) && !!data?.businesses) {
      setInitialFormValue(prev => ({ ...prev, selectedBusiness: data?.businesses[0]?.id }));
    }
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(businessDetails)) {
      setInitialFormValue(prev => ({ ...prev, selectedBusiness: businessDetails.id }));
    }
  }, [businessDetails]);

  const handleBusinessSelect = (e) => {
    setInitialFormValue(prev => ({ ...prev, selectedBusiness: e.target.value }));
    getSingleBusiness(e.target.value);
    dispatch(
      settingCampaignBudget({ ...campaignBudget, selectedChannels: [] })
    );
    dispatch(settingDemographics({ ...demographics, audience: "" }));
    if (e.target.value === "add-business") {
      handleOpenDialog();
    }
  };

  function handleOpenDialog() {
    setOpen(true);
  }

  function handleCloseDialog() {
    setOpen(false);
    if(business && business.length !== 0) {
      getSingleBusiness(business[0].id);
    }
  }

  // useEffect(() => {
  //   if (business?.length === 0) setInitialFormValue(prev => ({ ...prev, selectedBusiness: 'select-business' }));
  // }, [handleCloseDialog, business]);

  const handleAddNewBusiness = async (values) => {
    await instance({
      method: "POST",
      url: `client/${clientId}/business/new`,
      data: {
        name: values.name,
        contactName: values.contactName,
        phone: values.phone,
        email: values.email,
        industry: values.industry,
        address: values.address,
      },
    })
      .then((response) => {
        handleCloseDialog();
        toast({
          isClosable: true,
          status: "success",
          variant: "top-accent",
          position: "top-right",
          title: "Success",
          description: response.data?.data?.message,
        });
        refetch();
        getSingleBusiness(response.data.data.id);
      })
      .catch((error) => {
        toast({
          isClosable: true,
          status: "error",
          variant: "top-accent",
          position: "top-right",
          description: error.response.data.message,
        });
      });
  };

  function onSubmit(values) {
    dispatch(settingCampaignDetails({ ...values }));
    getSingleBusiness(values.selectedBusiness);
    nextStep();
  }

  console.log('businessDetails', businessDetails)

  return (
    <Box px="8">
      <Heading mt={5} fontSize="2xl">
        Campaign Details
      </Heading>
      <Formik
        initialValues={initialFormValue}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, errors, touched }) => (
          <Box as={Form} noValidate fontFamily={"poppins"}>
            <Grid
              //   templateColumns="repeat(2, 1fr)"
              templateColumns={{ base: "repeat(1,1fr)", md: "repeat(2,1fr)" }}
              gap={6}
              mt={10}
              minH={{ base: "150px", md: "460px" }}
            >
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Business Name</FormLabel>
                  <Select
                    id="detail.objective"
                    name="detail.objective"
                    placeholder={business?.length === 0 && "Select Business"}
                    value={values.selectedBusiness}
                    onChange={handleBusinessSelect}
                    borderColor={
                      errors.selectedBusiness && touched.selectedBusiness
                          ? "red.500"
                          : "gray.200"
                    }
                  >
                    {!!business?.length ? (
                      <>
                        {business.map((objective) => (
                          <option
                            className="campaign-option"
                            key={objective.id}
                            value={objective.id}
                          >
                            {objective.name}
                          </option>
                        ))}
                        <option
                          className="add-campaign-option"
                          key="add-business"
                          value="add-business"
                        >
                          Add New Business +
                        </option>
                      </>
                    ) : (
                      <>
                        <option
                            className="add-campaign-option"
                            key="add-business"
                            value="add-business"
                        >
                          Add New Business +
                        </option>
                      </>
                    )}
                  </Select>
                </FormControl>
                <Text fontSize="sm" color="red">{errors.selectedBusiness}</Text>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Campaign Name</FormLabel>
                  <InputBox name="campaignName" />
                </FormControl>
              </GridItem>
            </Grid>
            <Divider my={8} />
            <Box
              style={{ display: "flex", justifyContent: "space-between" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Button
                fontWeight={400}
                fontFamily={"poppins"}
                leftIcon={
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/notes.svg`}
                    height="18px"
                  />
                }
                onClick={handleNotes}
              >
                Add Notes
              </Button>
              <HStack>
                <Button
                  variant="outline"
                  onClick={() => prevStep()}
                  isDisabled={activeStep === 0}
                  flex="1"
                >
                  Back
                </Button>
                <Button flex="1" type="submit" _hover={{ bg: "" }} isDisabled={Object.keys(errors).length}>
                  Next
                </Button>
              </HStack>
            </Box>
          </Box>
        )}
      </Formik>

      <CampaignModal
        onClose={handleCloseDialog}
        reportData={{
          name: "",
          contactName: "",
          email: "",
          phone: "",
          industry: "",
          address: "",
        }}
        buttonType={"Save"}
        onSubmit={handleAddNewBusiness}
        dialogTitle={"Add Another Business"}
        isOpen={open}
      />
    </Box>
  );
}

export default CampaignDetails;
