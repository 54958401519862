import {CloseIcon, DownloadIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {
  Box, Button,
  Flex, Grid, Heading, Image as ReactImage, Input, Progress, Spacer, Text, Tooltip, useToast
} from "@chakra-ui/react";
import FileSaver from 'file-saver';
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import WithAppContext from "../../../../../../../helpers/appContext";
import {GREY_TEXT_COLOR, TEXT_COLOR, BLUE_BUTTON_COLOR} from "../../../../../../../layout/constant/MenuList";
import {LINKEDIN_IMAGE_SIZE} from "../../../../../../../constant";
import JPGIcon from "../../../../../../../assets/images/jpg.svg";
import PNGIcon from "../../../../../../../assets/images/png.svg";

const LinkedInImageAdFileUpload = ({errors, touched, values, setFieldValue, context}) => {
  const toast = useToast()
  const {setAppLoading, ErrorToaster} = context;

  const onDrop = async (accFiles) => {
    accFiles.map((file) => {
      const img = new Image()
      img.onload = async () => {
        const {naturalWidth: width, naturalHeight: height} = img
        const ratio = width / height;

        let isValid;

        // if (ratio < 1) {
        //   isValid = width >= 640 && width <= 7680 && height >= 360 && height <= 4320;
        // } else if (ratio === 1) {
        //   isValid = width >= 360 && width <= 4320 && height >= 360 && height <= 4320;
        // } else {
        //   isValid = width >= 360 && width <= 2430 && height >= 640 && height <= 4320;
        // }
        //
        // if (!isValid) {
        //   ErrorToaster('Please provide a valid aspect ratio.');
        // } else {
        //   setFieldValue('imageURL', [...values.imageURL, file]);
        // }

        setFieldValue('imageURL', [...values.imageURL, file]);
      }
      img.src = URL.createObjectURL(file);
    });
  }

  const acceptImageFiles = {
    "image/png": [".png"],
    "image/jpeg": [".jpg"],
    "image/gif": [".gif"],
  };

  const {getRootProps, getInputProps, fileRejections} = useDropzone({
    onDrop,
    accept: acceptImageFiles,
    maxSize: LINKEDIN_IMAGE_SIZE, // 5MB
    disabled: values.imageURL.length >= 1 // 1 max count
  });

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleRemoveFile = (idx) => {
    const arr = [...values.imageURL];
    const newArr = arr.filter((el, index) => index !== idx);
    setFieldValue('imageURL', newArr);
  }

  // dropZone Errors
  useEffect(() => {
    fileRejections && fileRejections.map(({errors}) => {
      errors.map((err) => {
        toast({
          status: 'error',
          variant: 'top-accent',
          position: 'top-right',
          description: err.code + ',' + err.message || 'Please upload valid image.'
        })
      })
    })
  }, [fileRejections])


  return (
    <>
      <Box className="upload-main">
        <Grid padding="20px" paddingY={"80px"} border={"2px dashed"}
              borderColor={(errors.imageURL && touched.imageURL) ? 'red.500' : 'gray.200'}>
          <div {...getRootProps()}>
            <Input {...getInputProps()} />

            <div className="file-upload-content">
              <ReactImage
                boxSize="100px"
                objectFit="cover"
                src={FileUploadIcon}
                alt="upload-icon"
              />
              <Heading
                fontSize="xl"
                display="flex"
                alignItems="center"
                color={GREY_TEXT_COLOR}
                my={4}
              >
                Drag Image Files to Upload
                <Tooltip label={
                  <Box>
                    <Text color="#000"><b>Image file type:</b>jpg, png, or gif</Text>
                    <Text color="#000"><b>Image maximum file size:</b> 5 MB</Text>
                    <Text color="#000"><b>Aspect Ratio: </b>1.91:1, 1:1, 1:1.91</Text>
                  </Box>} closeDelay={500} bg='#e2e8f0'>
                  <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                </Tooltip>
              </Heading>
              <Text
                fontSize="sm"
                mb={4}
                color={GREY_TEXT_COLOR}
              >
                - OR -
              </Text>
              <Button bgColor={BLUE_BUTTON_COLOR} color="white" size="small" px={10} py={2}
                      disabled={values.imageURL.length >= 1} borderRadius={100}>Browse Files</Button>
            </div>
          </div>
          {(errors.imageURL && touched.imageURL) &&
          <Text color="red.500" marginTop="2" textAlign="center">
            {errors.imageURL || 'please valid file'}
          </Text>
          }
        </Grid>

        <div className="image-listing">
          {
            values.imageURL.map((file, index) => {
              let imgURL = '';
              let fileName = '';
              let fileSize;
              let fileType;
              if (file && file.filename) {
                imgURL = `${process.env.REACT_APP_API_URL}/uploads/${file.filename}`;
                fileName = file.filename;
                fileSize = formatBytes(file?.files?.size)
                fileType = file?.files?.mimetype
              } else {
                imgURL = URL.createObjectURL(file)
                fileName = file.name
                fileSize = formatBytes(file.size)
                fileType = file.type
              }

              return (
                <Box display={"flex"} alignItems={"center"} gap={"16px"} className="file-box" key={index}>
                  {
                    fileType === "image/jpeg" && <ReactImage
                      boxSize="50px"
                      objectFit="cover"
                      src={JPGIcon}
                      alt="Dan Abramov"
                    />
                  }
                  {
                    fileType === "image/png" && <ReactImage
                      boxSize="50px"
                      objectFit="cover"
                      src={PNGIcon}
                      alt="Dan Abramov"
                    />
                  }
                  <Box flexGrow={1}>
                    <Flex marginTop="5" marginBottom="5">
                      <Grid marginLeft="2">
                        <Grid alignItems='center' display="flex">
                          <Text
                            fontSize="lg"
                            className="file-name"
                          >
                            {fileName}
                          </Text>
                        </Grid>
                        {file &&
                        <Text fontSize="sm">
                          {fileSize}
                        </Text>
                        }
                      </Grid>
                      <Spacer/>
                      <Grid>
                        <Grid justifyContent="center">
                          <CloseIcon
                            cursor="pointer"
                            className="close-icon"
                            size="small"
                            onClick={() => handleRemoveFile(index)}
                          />
                        </Grid>
                        <Grid>
                          <DownloadIcon
                            onClick={() => FileSaver.saveAs(imgURL, fileName)}
                            cursor="pointer"
                            className="close-icon"
                            size="small"
                            w={6}
                            h={6}
                          />
                        </Grid>
                      </Grid>
                    </Flex>
                    <Box marginTop={"-20px"}>
                      <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
                      <Progress
                        className="loading"
                        value={100}
                        colorScheme='green'
                        size='sm'
                      />
                    </Box>

                  </Box>

                </Box>
              );
            })
          }
        </div>
      </Box>

    </>
  );
}

export default WithAppContext(LinkedInImageAdFileUpload)
