import {
  Avatar,
  Box,
  HStack,
  Icon,
  IconButton,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { SubmitButton } from "formik-chakra-ui";
import { useRecoilState } from "recoil";
import validationSchema from "../../validations/Setting/Details";
import Upload from "rc-upload";
import { HiCamera } from "react-icons/hi";
import { profile } from "../../atoms/authAtom";
import InputBox from "../../components/InputBox";

import instance from "../../helpers/axios";
import { useAuthCheck } from "../../hooks/useAuthCheck";
import { useState } from "react";
import { useEffect } from "react";
import { detailInitialValue } from "./constant/InititalValues";
import { Roles } from "../../constant";
import { getClients } from "../../store/client/clientThunk";
import { useDispatch } from "react-redux";

const DetailsTab = () => {
  const toast = useToast();
  const [details] = useRecoilState(profile);
  const { refetch } = useAuthCheck();
  const dispatch = useDispatch();
  const client = JSON.parse(localStorage.getItem("client"));
  const [, setAdmin] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isClient] = useState(client?.access_info?.roles?.includes("Client"));

  useEffect(() => {
    if (details?.access_info?.roles?.length) {
      setAdmin(
        details.access_info.roles.includes("Admin") ||
          details.access_info.roles.includes("Developer")
      );
    }
    if (details?.access_info?.clients?.length) {
      setSelectedClients(
        details.access_info.clients.map((el) => {
          return { value: el.id, label: el.name };
        })
      );
    }
    if (details?.access_info?.roles?.length) {
      setSelectedRoles(
        details.access_info.roles.map((el) => {
          const id = Roles.filter((e) => e.value === el);
          return { value: el, label: id?.[0]?.title };
        })
      );
    }
  }, [details, setAdmin]);

  const onSubmit = async (values, actions) => {
    await instance({
      method: "PUT",
      url: `/user/${details.id}`,
      data: {
        name: values.name,
        lastName: values.lastName,
        company: values.company,
        email: values.email,
        roles: selectedRoles.map((el) => el.value),
        clients: selectedClients.map((el) => el.value),
      },
    })
      .then((response) => {
        toast({
          isClosable: true,
          status: "success",
          variant: "top-accent",
          position: "top-right",
          title: "Success",
          description: response.data?.data?.message,
        });
        dispatch(getClients(details?.id));
        refetch();
      })
      .catch((error) => {
        toast({
          isClosable: true,
          status: "error",
          variant: "top-accent",
          position: "top-right",
          description: error.response.data.message,
        });
      });
  };

  const uploadProps = {
    customRequest: (fileData) => {
      const data = new FormData();
      data.append("pic", fileData.file, fileData.file.name);

      return instance({
        method: "PUT",
        url: `/user/me/profile-pic`,
        data: data,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${fileData._boundary}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            toast({
              isClosable: true,
              status: "success",
              variant: "top-accent",
              position: "top-right",
              title: "Success",
              description: response.data?.data?.message,
            });
            refetch();
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          toast({
            isClosable: true,
            status: "error",
            title: "Error",
            variant: "top-accent",
            position: "top-right",
            description:
              error.response?.data?.message || "Something went wrong!",
          });
        });
    },
    withCredentials: false,
  };

  const className = isClient ? "profile-input" : "";

  return (
    <HStack
      flexDirection={{ base: "column", lg: "row" }}
      mt={{
        base: 2,
        md: 6,
      }}
      spacing={{ base: 0, lg: "10" }}
      align={"start"}
    >
      <Box pos="relative">
        <Avatar
          size={{
            base: "xl",
            md: "2xl",
          }}
          src={
            details.other_info?.profile_pic_url &&
            `${process.env.REACT_APP_API_URL}/uploads/${details?.other_info?.profile_pic_url}`
          }
          name={details.name}
        />
        <Upload {...uploadProps}>
          <IconButton
            pos="absolute"
            right={{
              base: -2,
              md: 0,
            }}
            bottom={0}
            variant="solid"
            rounded="full"
            colorScheme="blackAlpha"
            aria-label="Search database"
            icon={<Icon as={HiCamera} />}
          />
        </Upload>
      </Box>

      <Formik
        initialValues={detailInitialValue(details)}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors }) => (
          <VStack
            as={Form}
            w="full"
            align={"start"}
            spacing={4}
            paddingY={{ base: "10", lg: 0 }}
          >
            <HStack
              spacing={0}
              gap={4}
              w="full"
              flexDir={{ base: "column", md: "row" }}
              align="start"
            >
              <InputBox name="name" label="First Name" />
              <InputBox name="lastName" label="Last Name" />
            </HStack>
            <InputBox
              className={className}
              name="email"
              label="Email"
              type="email"
            />
            <InputBox
              className={className}
              name="company"
              label="Company"
              type="company"
            />

            <HStack justify="end" w="full" mt="4">
              <SubmitButton colorScheme="blue">Update</SubmitButton>
            </HStack>
          </VStack>
        )}
      </Formik>
    </HStack>
  );
};

export default DetailsTab;
