import React from 'react';
import Display from "./Display";
import {Navigation} from "swiper";
import {Box, Image, Text} from "@chakra-ui/react";
import {Flex} from "@chakra-ui/layout";
import {Swiper, SwiperSlide} from "swiper/react";
import RightArrowIcon from "../../../../../../../layout/components/RightArrowIcon";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import {useSelector} from "react-redux";

function DisplayNativeAd373X310({formDetails, appLoading, ads, index, handleIndex, businessName}) {
  const {handlePreviewData} = useSelector((state) => state.performanceMaxAd)

  const selectedLogos = (Array.isArray(handlePreviewData?.selectedLogos) && handlePreviewData?.selectedLogos[0]?.imageUrl) ?
      (handlePreviewData?.selectedLogos[0]?.imageUrl) :
      process.env.PUBLIC_URL + "/images/display.png";

  return (
    <Display>
      <Box>
        <Box h="25vh">
          {(ads && ads.length !== 0) ?
            <Box overflow="hidden" width={"100%"} height={"100%"}>
              <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                      onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                {
                  ads.map((item) => {
                    return (
                      <SwiperSlide>
                        <Image
                          height="100%"
                          width={"100%"}
                          objectFit={"fill"}
                          src={item?.imageUrl}
                          className="previewImage"
                        />
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
          }
        </Box>
        <Flex m={3} gap="2" alignItems="center">
          <Image src={selectedLogos} height={12}
                 width={12} rounded="full"/>
          <Box>
            <Text fontWeight="600">{formDetails.headlines[0]}</Text>
            <Box fontWeight="200" fontSize="14px"> {formDetails.descriptions[0]} </Box>
          </Box>
        </Flex>
        <Flex justifyContent="space-between" mx={3}>
          <Text fontSize="14px">{businessName}</Text>
          <button style={{
            fontSize: '14px',
            fontWeight: "500",
            display: 'flex',
          }}>
            {youtubeShortsTypes.find((i) => i.key === formDetails.call_to_action?.type)?.name}
            <RightArrowIcon height="20px" width="20px" />
          </button>
        </Flex>
      </Box>
    </Display>
  );
}

export default DisplayNativeAd373X310;
