// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tooltip{
    all: initial;
}

.css-ki5q04{
    all: initial!important;
    width: 100%!important;
}`, "",{"version":3,"sources":["webpack://./src/pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/tooltipstyle.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,qBAAqB;AACzB","sourcesContent":["#tooltip{\n    all: initial;\n}\n\n.css-ki5q04{\n    all: initial!important;\n    width: 100%!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
