import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box, Center,
  Container, Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  SkeletonText, Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import ShareIcon from "../../../../../../../layout/components/SearchIcon";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import RightArrowIcon from "../../../../../../../layout/components/RightArrowIcon";
import {useSelector} from "react-redux";

function DisplayImageAd320x568({formDetails, appLoading, ads, index, handleIndex, businessName}) {
  const {handlePreviewData} = useSelector((state) => state.performanceMaxAd)

  const selectedLogos = (Array.isArray(handlePreviewData?.selectedLogos) && handlePreviewData?.selectedLogos[0]?.imageUrl) ?
      (handlePreviewData?.selectedLogos[0]?.imageUrl) :
      process.env.PUBLIC_URL + "/images/display.png";

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"} />
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%'>
                <Box backgroundColor='white' textAlign='center'
                     borderRadius="25px" height="100%"
                     alignSelf="center" overflow="auto">
                  <Box mx={2} px={2} overflow="auto" backgroundImage={ads[index]?.imageUrl} height="100%">
                    <Box mt={4} height="45%">
                      {(ads && ads.length !== 0) ?
                        <Box overflow="hidden" width={"100%"} height={"100%"}>
                          <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                                  onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                            {
                              ads.map((item) => {
                                return (
                                  <SwiperSlide>
                                    <Image
                                      borderTopRadius="10px"
                                      height="100%"
                                      width={"100%"}
                                      objectFit={"cover"}
                                      src={item?.imageUrl}
                                      className="previewImage"
                                    />
                                  </SwiperSlide>
                                )
                              })
                            }
                          </Swiper>
                        </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                      }
                    </Box>
                    <Box bg="white" py={2}>
                      <Center>
                        <Image src={selectedLogos} height={12}
                               width={12} rounded="full" my={2}/>
                      </Center>
                      <Text my={1} mx={3} fontWeight="700" fontSize="28px" color="gray.600">{formDetails.headlines[0]}</Text>
                      <Text my={5} mx={3} fontWeight="700" fontSize="20px" color="gray.400">{formDetails.descriptions[0]}</Text>
                      <Flex mx={3} my={3} fontWeight="400" justifyContent="space-around" alignItems="center">
                        <button
                          style={{ padding: "1vh 6vh", border: "1px solid gray", borderRadius: "5px", color: "gray" }}
                        >Close</button>
                        <button
                          style={{ padding: "1vh 3vh", border: "1px solid #f14300", backgroundColor: "#f14300", color: "white", borderRadius: "5px" }}
                        >Learn more</button>
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default DisplayImageAd320x568;
