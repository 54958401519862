import React from 'react';
import {Box, GridItem, Heading} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Grid} from "@chakra-ui/layout";

    function OuterUiForPreview({ children }) {
    return (
        <div>
            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                {/*{appLoading && <FullPageLoader />}*/}
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                    <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
                        Social Display Image
                    </Heading>
                    <Box
                        borderWidth="2px"
                        borderColor="gray"
                        p={10}
                        background={PREVIEW_BG_COLOR}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                    >
                        {/*{dimensions.width && dimensions.height &&*/}
                        {/*    <RenderImageComponent dimensions={dimensions} />*/}
                        {/*}*/}
                        { children }
                    </Box>
                </GridItem>
            </Grid>
        </div>
    );
}

export default OuterUiForPreview;
