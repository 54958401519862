export const CreativeUnit = [
    {label: "Display Ad", value: "Display"},
    {label: "Text", value: "Text"},
    {label: "Video", value: "Video"},
    {label: "GIF", value: "GIF"},
    {label: "Expanding", value: "Expanding"},
    {label: "HTML5", value: "HTML5"},
    {label: "Instant", value: "Instant"},
    {label: "Experience", value: "Experience"},
];

export const CreativeSizes = [
    {label: "300 x 600 (Display)", value: "Display300600"},
    {label: "728 x 90 (Display)", value: "Display728"},
    {label: "320 x 50 (Display)", value: "Display320"},
    {label: "300 x 250 (Display)", value: "Display300"},
    {label: "160 x 600 (Display)", value: "Display160"},
    {label: "320 x 100 (Display)", value: "Display320100"},
    {label: "336 x 280 (Display)", value: "Display336"},
    {label: "970 x 250 (Display)", value: "Display970"},
    {label: "100 x 100 (Native Logo)", value: "Native100"},
    {label: "627 x 627 (Native Square)", value: "Native627"},
    {label: "1200 x 627 (Native Header)", value: "Native1200"},
    {label: "1200 x 628 (Facebook Display)", value: "FacebookDisplay1200"},
    {label: "1200 x 1200 (Facebook Carousel)", value: "FacebookCarousel1200"},
    {label: "1080 x 1080 (Instagram Display)", value: "InstagramDisplay1080"},
    {label: "1080 x 1080 (Instagram Carousel)", value: "InstagramCarousel1080"},
    {label: "1920 x 1080 (Video)", value: "Video1920"},
    {label: "SRT Files (Video Captions)", value: "SRT"},
    {label: "Text (Search)", value: "Text"},
];

export const Months = [
    {value: "Jan", key: "Jan"},
    {value: "Feb", key: "Feb"},
    {value: "Mar", key: "Mar"},
    {value: "Apr", key: "Apr"},
    {value: "May", key: "May"},
    {value: "Jun", key: "Jun"},
    {value: "Jul", key: "Jul"},
    {value: "Aug", key: "Aug"},
    {value: "Sep", key: "Sep"},
    {value: "Oct", key: "Oct"},
    {value: "Nov", key: "Nov"},
    {value: "Dec", key: "Dec"},
];

export const AdCategory = [
    {value: "Stories", key: "STORIES"},
    {value: "Weather", key: "WEATHER"},
    {value: "In Stream", key: "IN_STREAM"},
    {value: "Bumper", key: "BUMPER"},
    {value: "Discovery", key: "DISCOVERY"},
    {value: "Flight", key: "FLIGHT"},
    {value: "OTA", key: "OTA"},
    {value: "Sequence", key: "SEQUENCE"},
];

export const TargetingMethod = [
    {value: "Audience", key: "AUDIENCE"},
    {value: "List", key: "LIST"},
    {value: "Lookalike", key: "LOOKALIKE"},
    {value: "Retargeting", key: "RETARGETING"},
    {value: "Dynamic Retargeting", key: "DYNAMIC_RETARGETING"},
    {value: "Placement", key: "PLACEMENT"},
    {value: "Keyword", key: "KEYWORD"},
];

export const CreativeType = [
    {value: "Static", key: "STATIC"},
    {value: "Carousel", key: "CAROUSEL"},
    {value: "Video", key: "VIDEO"},
    {value: "Cinemagraph", key: "CINEMAGRAPH"},
    {value: "Text", key: "TEXT"},
    {value: "Dynamic Static", key: "DYNAMIC_STATIC"},
    {value: "Dynamic Video", key: "DYNAMIC_VIDEO"},
    {value: "HTML", key: "HTML"},
    {value: "GIF", key: "GIF"},
    {value: "Audio", key: "AUDIO"},
];

export const CreativeGoal = [
    {value: "Unspecified", key: "CAMPAIGN_GOAL_TYPE_UNSPECIFIED"},
    {value: "App Install", key: "CAMPAIGN_GOAL_TYPE_APP_INSTALL"},
    {value: "Brand Awareness", key: "CAMPAIGN_GOAL_TYPE_BRAND_AWARENESS"},
    {value: "Offline Action", key: "CAMPAIGN_GOAL_TYPE_OFFLINE_ACTION"},
    {value: "Online Action", key: "CAMPAIGN_GOAL_TYPE_ONLINE_ACTION"},
];

export const PerformanceGoal = [
    {value: "CPM", key: "PERFORMANCE_GOAL_TYPE_CPM"},
    {value: "CPC", key: "PERFORMANCE_GOAL_TYPE_CPC"},
    {value: "CPA", key: "PERFORMANCE_GOAL_TYPE_CPA"},
    {value: "CPIAVC", key: "PERFORMANCE_GOAL_TYPE_CPIAVC"},
    {value: "CTR", key: "PERFORMANCE_GOAL_TYPE_CTR"},
    {value: "Viewability", key: "PERFORMANCE_GOAL_TYPE_VIEWABILITY"},
    {value: "Other", key: "PERFORMANCE_GOAL_TYPE_OTHER"},
];

export const creativeTypes = [
    {key: "UNSPECIFIED", name: "Unspecified"},
    {key: "STANDARD", name: "Standard"},
    {key: "EXPANDABLE", name: "Expandable"},
    {key: "VIDEO", name: "Video"},
    {key: "NATIVE", name: "Native"},
    {key: "TEMPLATED_APP_INSTALL", name: "Templated App Install"},
    {key: "NATIVE_SITE_SQUARE", name: "Native Site Square"},
    {
        key: "TEMPLATED_APP_INSTALL_INTERSTITIAL",
        name: "Templated App Install Interstitial",
    },
    {key: "LIGHTBOX", name: "Lightbox"},
    {key: "NATIVE_APP_INSTALL", name: "Native App Install"},
    {key: "NATIVE_APP_INSTALL_SQUARE", name: "Native App Install Square"},
    {key: "AUDIO", name: "Audio"},
    {key: "PUBLISHER_HOSTED", name: "Publisher Hosted"},
    {key: "NATIVE_VIDEO", name: "Native Video"},
    {key: "TEMPLATED_APP_INSTALL_VIDEO", name: "Templated App Install Video"},
];

export const hostingSource = [
    {key: "UNSPECIFIED", name: "Unspecified"},
    {key: "CM", name: "Cm"},
    {key: "THIRD_PARTY", name: "Third Party"},
    {key: "HOSTED", name: "Hosted"},
    {key: "RICH_MEDIA", name: "Rich Media"},
];

export const facebookAccountIds = [
    {key: "SHOP_NOW", name: "Shop now"},
    {key: "BOOK_TRAVEL", name: "Book travel"},
    {key: "LEARN_MORE", name: "Learn more"},
    {key: "SIGN_UP", name: "Sign up"},
    {key: "DOWNLOAD", name: "Download"},
    {key: "WATCH_MORE", name: "Watch more"},
    {key: "CALL_NOW", name: "Call now"},
    {key: "APPLY_NOW", name: "Apply now"},
    {key: "GET_OFFER", name: "Get offer"},
    {key: "BUY_TICKETS", name: "Buy tickets"},
    {key: "SUBSCRIBE", name: "Subscribe"},
    {key: "GET_QUOTE", name: "Get quote"},
    {key: "CONTACT_US", name: "Contact us"},
    {key: "ORDER_NOW", name: "Order now"},
    {key: "REQUEST_TIME", name: "Request time"},
    {key: "GET_SHOWTIMES", name: "Get showtimes"},
    {key: "LISTEN_NOW", name: "Listen now"},
    {key: "WHATSAPP_MESSAGE", name: "Whatsapp message"},
    {key: "SEE_MORE", name: "See more"},
    {key: "PAY_TO_ACCESS", name: "Pay to access"},
];

export const youtubeShortsTypes = [
    {key: "LEARN_MORE", name: "Learn more"},
    {key: "SHOP_NOW", name: "Shop now"},
    {key: "SIGN_UP", name: "Sign up"},
    {key: "BUY_NOW", name: "Buy Now"},
    {key: "WATCH_NOW", name: "Watch Now"},
    {key: "START_NOW", name: "Start Now"},
    {key: "BOOK_NOW", name: "Book Now"},
    {key: "DOWNLOAD", name: "Download"},
    {key: "ORDER_NOW", name: "Order now"},
    {key: "PLAY_NOW", name: "Play now"},
    {key: "SUBSCRIBE", name: "Subscribe"},
    {key: "APPLY_NOW", name: "Apply now"},
    {key: "DONATE_NOW", name: "Donate Now"},
];

export const linkedinVideoTypes = [
    { key: "APPLY", name: "Apply" },
    { key: "DOWNLOAD", name: "Download" },
    { key: "VIEW_QUOTE", name: "View Quote" },
    { key: "LEARN_MORE", name: "Learn More" },
    { key: "SIGN_UP", name: "Sign Up" },
    { key: "SUBSCRIBE", name: "Subscribe" },
    { key: "REGISTER", name: "Register" },
    { key: "JOIN", name: "Join" },
    { key: "ATTEND", name: "Attend" },
    { key: "REQUEST_DEMO", name: "Request Demo" }
];

export const DeviceOptions = [
    {value: "Mobile", key: "MOBILE"},
    {value: "Desktop", key: "DESKTOP"},
    {value: "Both Device", key: "BITH_DEVICE"},
    {value: "Connect TV", key: "CONNECT_TV"},
];

export const BrandAwarenessOptimizationGoalOptions = [
    {value: "Impressions", key: "IMPRESSIONS"},
    {value: "Post engagement", key: "POST_ENGAGEMENT"},
    {value: "Link clicks", key: "LINK_CLICKS"},
    {value: "Reach", key: "REACH"},
];

export const LinkClicksOptimizationGoalOptions = [
    {value: "Impressions", key: "IMPRESSIONS"},
    {value: "Post engagement", key: "POST_ENGAGEMENT"},
    {value: "Offsite conversions", key: "OFFSITE_CONVERSIONS"},
    {value: "Link clicks", key: "LINK_CLICKS"},
    {value: "Reach", key: "REACH"},
    {value: "Landing Page Views", key: "LANDING_PAGE_VIEWS"},
    {value: "App Installs", key: "APP_INSTALLS"},
    {value: "Engaged Users", key: "ENGAGED_USERS"},
];

export const VideoViewsOptimizationGoalOptions = [
    {value: "Thruplay", key: "THRUPLAY"},
];

export const LeadGenerationOptimizationGoalOptions = [
    {value: "Link Clicks", key: "LINK_CLICKS"},
    {value: "Quality Lead", key: "QUALITY_LEAD"},
    {value: "Quality Call", key: "QUALITY_CALL"},
    {value: "Lead Generation", key: "LEAD_GENERATION"},
];

export const ConversionsOptimizationGoalOptions = [
    {value: "Impressions", key: "IMPRESSIONS"},
    {value: "Post engagement", key: "POST_ENGAGEMENT"},
    {value: "Offsite conversions", key: "OFFSITE_CONVERSIONS"},
    {value: "Link clicks", key: "LINK_CLICKS"},
    {value: "Reach", key: "REACH"},
    {value: "Value", key: "VALUE"},
    {value: "Landing Page Views", key: "LANDING_PAGE_VIEWS"},
    {value: "Conversations", key: "CONVERSATIONS"},
];

export const EventType = [
    {value: "Rate", key: "RATE"},
    {value: "Tutorial Completion", key: "TUTORIAL_COMPLETION"},
    {value: "Contact", key: "CONTACT"},
    {value: "Customize Product", key: "CUSTOMIZE_PRODUCT"},
    {value: "Donate", key: "DONATE"},
    {value: "Find Location", key: "FIND_LOCATION"},
    {value: "Schedule", key: "SCHEDULE"},
    {value: "Start Trial", key: "START_TRIAL"},
    {value: "Submit Application", key: "SUBMIT_APPLICATION"},
    {value: "Subscribe", key: "SUBSCRIBE"},
    {value: "Add To Cart", key: "ADD_TO_CART"},
    {value: "Add To Wishlist", key: "ADD_TO_WISHLIST"},
    {value: "Initiated Checkout", key: "INITIATED_CHECKOUT"},
    {value: "Add Payment Info", key: "ADD_PAYMENT_INFO"},
    {value: "Purchase", key: "PURCHASE"},
    {value: "Lead", key: "LEAD"},
    {value: "Complete Registration", key: "COMPLETE_REGISTRATION"},
    {value: "Content View", key: "CONTENT_VIEW"},
    {value: "Search", key: "SEARCH"},
    {value: "Service Booking Request", key: "SERVICE_BOOKING_REQUEST"},
    {
        value: "Messaging Conversation Started 7d",
        key: "MESSAGING_CONVERSATION_STARTED_7D",
    },
    {value: "Level Achieved", key: "LEVEL_ACHIEVED"},
    {value: "Achievement Unlocked", key: "ACHIEVEMENT_UNLOCKED"},
    {value: "Spent Credits", key: "SPENT_CREDITS"},
    {value: "Listing Interaction", key: "LISTING_INTERACTION"},
    {value: "D2 Retention", key: "D2_RETENTION"},
    {value: "D7 Retention", key: "D7_RETENTION"},
    {value: "Other", key: "OTHER"},
];

export const ProductcatalogsalesOptimizationGoalOptions = [
    {value: "Impressions", key: "IMPRESSIONS"},
    {value: "Post engagement", key: "POST_ENGAGEMENT"},
    {value: "Offsite conversions", key: "OFFSITE_CONVERSIONS"},
    {value: "Link clicks", key: "LINK_CLICKS"},
    {value: "Reach", key: "REACH"},
    {value: "Value", key: "VALUE"},
    {value: "Conversations", key: "CONVERSATIONS"},
];

export const CampaignBudgetOptions = [
    {
        name: "detail.campaignBudget.googleSearchAds",
        label: "Google Search Ads",
        value: "Google Search Ads",
    },
    {
        name: "detail.campaignBudget.bingSearchAds",
        label: "Bing Search Ads",
        value: "Bing Search Ads",
    },
    {
        name: "detail.campaignBudget.fbAds",
        label: "Facebook Ads",
        value: "Facebook Ads",
    },
    {
        name: "detail.campaignBudget.instaAds",
        label: "Instagram Ads",
        value: "Instagram Ads",
    },
    {
        name: "detail.campaignBudget.displayAds",
        label: "Display Ads",
        value: "Display Ads",
    },
    {
        name: "detail.campaignBudget.twitterAds",
        label: "Twitter Ads",
        value: "Twitter Ads",
    },
    {
        name: "detail.campaignBudget.nativeAds",
        label: "Native Ads",
        value: "Native Ads",
    },
    {
        name: "detail.campaignBudget.spotifyAudioAds",
        label: "Spotify Audio Ads",
        value: "Spotify Audio Ads",
    },
    {
        name: "detail.campaignBudget.pinterestAds",
        label: "Pinterest Ads",
        value: "Pinterest Ads",
    },
    {
        name: "detail.campaignBudget.quoraAds",
        label: "Quora Ads",
        value: "Quora Ads",
    },
    {
        name: "detail.campaignBudget.displayVideo",
        label: "Video (Display)",
        value: "Video (Display)",
    },
    {
        name: "detail.campaignBudget.youtubeVideo",
        label: "Video (YouTube)",
        value: "Video (YouTube)",
    },
    {
        name: "detail.campaignBudget.fbVideo",
        label: "Video (Facebook)",
        value: "Video (Facebook)",
    },
    {
        name: "detail.campaignBudget.instaVideo",
        label: "Video (Instagram)",
        value: "Video (Instagram)",
    },
    {
        name: "detail.campaignBudget.overTheTop",
        label: "Over The Top",
        value: "Over The Top",
    },
    {
        name: "detail.campaignBudget.retargetting.googleSearchRt",
        label: "Google Search RT",
        value: "Google Search RT",
    },
    {
        name: "detail.campaignBudget.retargetting.bingSearchRt",
        label: "Bing Search RT",
        value: "Bing Search RT",
    },
    {
        name: "detail.campaignBudget.retargetting.fbRt",
        label: "Facebook RT",
        value: "Facebook RT",
    },
    {
        name: "detail.campaignBudget.retargetting.instaRt",
        label: "Instagram RT",
        value: "Instagram RT",
    },
    {
        name: "detail.campaignBudget.retargetting.displayRt",
        label: "Display RT",
        value: "Display RT",
    },
    {
        name: "detail.campaignBudget.retargetting.linkedinRt",
        label: "Linkedin RT",
        value: "Linkedin RT",
    },
    {
        name: "detail.campaignBudget.retargetting.nativeRt",
        label: "Native RT",
        value: "Native RT",
    },
    {
        name: "detail.campaignBudget.retargetting.pinterestRt",
        label: "Pinterest RT",
        value: "Pinterest RT",
    },
    {
        name: "detail.campaignBudget.retargetting.videoRt",
        label: "Video RT",
        value: "Video RT",
    },
    {
        name: "detail.campaignBudget.other.hotelAds",
        label: "Hotel Ads",
        value: "Hotel Ads",
    },
    {
        name: "detail.campaignBudget.other.travelAds",
        label: "Travel Ads",
        value: "Travel Ads",
    },
    {
        name: "detail.campaignBudget.other.shoppingAds",
        label: "Shopping Ads",
        value: "Shopping Ads",
    },
    {
        name: "detail.campaignBudget.other.fbEvents",
        label: "Facebook Events",
        value: "Facebook Events",
    },
    {
        name: "detail.campaignBudget.other.fbLookalike",
        label: "Facebook Lookalike",
        value: "Facebook Lookalike",
    },
    {
        name: "detail.campaignBudget.other.instaLookalike",
        label: "Instagram Lookalike",
        value: "Instagram Lookalike",
    },
    {
        name: "detail.campaignBudget.other.displayList",
        label: "Display List",
        value: "Display List",
    },
    {
        name: "detail.campaignBudget.other.fbList",
        label: "Facebook List",
        value: "Facebook List",
    },
    {
        name: "detail.campaignBudget.other.instaList",
        label: "Instagram List",
        value: "Instagram List",
    },
    {
        name: "detail.campaignBudget.other.weatherAds",
        label: "Weather Ads",
        value: "Weather Ads",
    },
    {
        name: "detail.campaignBudget.other.linkedinInmail",
        label: "Linkedin InMail",
        value: "Linkedin InMail",
    },
    {
        name: "detail.campaignBudget.other.linkedinSponsored",
        label: "Linkedin Sponsored",
        value: "Linkedin Sponsored",
    },
    {
        name: "detail.campaignBudget.other.fbLead",
        label: "Facebook Lead",
        value: "Facebook Lead",
    },
    {
        name: "detail.campaignBudget.other.instaLead",
        label: "Instagram Lead",
        value: "Instagram Lead",
    },
    {
        name: "detail.campaignBudget.other.instantExperience",
        label: "Instant Experience",
        value: "Instant Experience",
    },
];

export const FacebookAdSize = [
    {label: "320X100", value: "D320X100"},
    {label: "320X50", value: "D320X50"},
    {label: "300X250", value: "D300X250"},
    {label: "970X250", value: "D970X250"},
    {label: "728X90", value: "D728X90"},
    {label: "336X280", value: "D336X280"},
    {label: "300X600", value: "D300X600"},
    {label: "160X600", value: "D160X600"},
];

export const Geography = [
    {label: "Country", value: "Country"},
    {label: "Province", value: "Province"},
    {label: "City", value: "City"},
    {label: "Zip Code", value: "Zip Code"},
];

export const CampaignIndustry = [
    {label: "Travel", value: "Travel"},
    {label: "Ecommerce", value: "Ecommerce"},
    {label: "Real Estate", value: "Real Estate"},
    {label: "Financial Services", value: "Financial Services"},
    {label: "Non-Profit", value: "Non-Profit"},
    {label: "Mobile-app", value: "Mobile-app"},
    {label: "Sports & Fitness", value: "Sports & Fitness"},
    {label: "Health & Wellness", value: "Health & Wellness"},
    {label: "Food and Beverage", value: "Food and Beverage"},
    {label: "Business Services", value: "Business Services"}
];

export const TravelRecommendation = [
    {label: "Ski Resort", value: "Ski Resort"},
    {label: "Hotels & Accommodaion", value: "Hotels & Accommodaion"},
    {label: "Heli-Skiing", value: "Heli-Skiing"},
    {label: "Destination", value: "Destination"},
    {label: "Travel Agency", value: "Travel Agency"},
    {label: "Attractions", value: "Attractions"},
];

export const EcommerceRecommendation = [
    {label: "Online Stores", value: "Online Stores"},
    {label: "Fashion and Apparel", value: "Fashion and Apparel"},
    {label: "Tech and Gadgets", value: "Tech and Gadgets"},
    {label: "Home & Garden", value: "Home & Garden"},
    {label: "Beauty and Skincare", value: "Beauty and Skincare"},
    {label: "Pet Supplies & Products", value: "Pet Supplies & Products"},
    {label: "Electronics", value: "Electronics"},
    {label: "Consumer Products", value: "Consumer Products"},
];

export const RealEstateRecommendation = [
    {label: "Homes & Condos for sale", value: "Homes & Condos for sale"},
    {label: "Rentals ", value: "Rentals "},
    {label: "Commercial Property", value: "Commercial Property"},
    {label: "Real Estate Professionals", value: "Real Estate Professionals"},
];

export const FinancialServicesRecommendation = [
    {label: "Banking", value: "Banking"},
    {label: "Accounting Services ", value: "Accounting Services "},
    {label: "Education Loans", value: "Education Loans"},
    {label: "Crypto Currency", value: "Crypto Currency"},
    {label: "Financial Advisors", value: "Financial Advisors"},
    {label: "Loan and Credit Service", value: "Loan and Credit Service"},
    {label: "Investing", value: "Investing"},
];

export const NonProfitRecommendation = [
    {label: "Community Services", value: "Community Services"},
    {label: "Environmental  ", value: "Environmental "},
    {label: "Education", value: "Education"},
    {label: "Animal Rescue & Support", value: "Animal Rescue & Support"},
    {label: "Disaster Relief", value: "Disaster Relief"},
    {label: "LGBTQIA+", value: "LGBTQIA+"},
    {label: "Human Rights Organization", value: "Human Rights Organization"},
];

export const MobileAppRecommendation = [
    {label: "Mobile Games", value: "Mobile Games"},
    {label: "Social Media", value: "Social Media"},
    {label: "Health & Fitness Apps", value: "Health & Fitness Apps"},
    {label: "Educational App", value: "Educational App"},
    {label: "Productivity App", value: "Productivity App"},
    {label: "Food Delivery App", value: "Food Delivery App"},
    {label: "Travel and Navigation App", value: "Travel and Navigation App"},
    {label: "Photography App ", value: "Photography App "},
    {label: "Entertainment App", value: "Entertainment App"},
];

export const SportsAndFitnessRecommendation = [
    {label: "Professional Sports Leagues", value: "Professional Sports Leagues"},
    {label: "Fitness & Exercise", value: "Fitness & Exercise"},
    {label: "Sports Gear & Equipment", value: "Sports Gear & Equipment"},
    {label: "Sports Events - Tickets", value: "Sports Events - Tickets"},
];

export const HealthAndWellnessRecommendation = [
    {label: "Mental Health and Wellness", value: "Mental Health and Wellness"},
    {label: "Yoga and Meditation Practitioners", value: "Yoga and Meditation Practitioners"},
    {label: "Health Insurance", value: "Health Insurance"},
    {label: "Alternative Medicine Explorers", value: "Alternative Medicine Explorers"},
    {label: "Medical Services", value: "Medical Services"},
    {label: "Nutrition and Supplements", value: "Nutrition and Supplements"},
    {label: "Health Care", value: "Health Care"},
]
export const FoodAndBeverageRecommendation = [
    {label: "Restaurants", value: "Restaurants"},
    {label: "Alcoholic Beverages", value: "Alcoholic Beverages"},
    {label: "Coffee shop", value: "Coffee shop"},
    {label: "Packaged Food & Drink", value: "Packaged Food & Drink"},
];

export const BusinessServicesRecommendation = [
    {label: "Consulting", value: "Consulting"},
    {label: "Business Services", value: "Business Services"},
    {label: "B2B sales", value: "B2B sales"},
    {label: "marketing services", value: "marketing services"},
    {label: "Employment", value: "Employment"},
]

export const LocationOptions = [
    {label: "Everyone in this location", value: "Every one in this location"},
    {label: "People Recently in this location", value: "People Recently in this location"},
    {label: "People travelling in this location", value: "People travelling in this location"},
]

export const TargettingLocationRadius = [
    {value: "Miles10", label: "10 Miles"},
    {value: "Miles20", label: "20 Miles"},
    {value: "Miles30", label: "30 Miles"},
    {value: "Miles40", label: "40 Miles"},
    {value: "Miles50", label: "50 Miles"},
    {value: "Miles60", label: "60 Miles"},
    {value: "Miles70", label: "70 Miles"},
];

export const CloneGeography = [
    {label: "Country", value: "Country"},
    {label: "Province", value: "Province"},
];
