import {
    Grid,
    GridItem
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {useGlobalContext} from "./store/WrapContext";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import WithAppContext from "../../../../../../../helpers/appContext";
import MicrosoftCSVAdDetails from "./MicrosoftCSVAdDetails";


const MicrosoftCSV = ({SetTabIndex, activeStep, steps, prevStep, nextStep, label ,context}) => {
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const [previewData, setPreviewData] = useState('');
    const [tabIndex, setTabIndex] = useState(0);

    const {id, adUploadId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {data, refetch} = useGetAdUpload(clientId, id, adUploadId);
    const {appLoading} = context



    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${id}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${id}/ad-upload/microsoft`);
        }
    }, [adUploadId, clientId]);

    const {
        setInitialMasterData,
        setInitialExtensionData,
        setInitialSiteLinkData,
    } = useGlobalContext()


    useEffect(() => {
        if (data) {
            const {GoogleMaster, GoogleExtension, GoogleSiteLink} = data;
            setInitialMasterData(GoogleMaster);
            setInitialExtensionData(GoogleExtension);
            setInitialSiteLinkData(GoogleSiteLink);
        }
    }, [data]);

    const {state} = useLocation()


    useEffect(() => {
        if (state?.isUpdate) {
            setTabIndex(4)
        }
    }, [state]);

    return (
        <>
            {appLoading && <FullPageLoader/>}
            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                <GridItem w="full" colSpan={{base: 6, lg: 6}} mb={{base: 3, lg: 0}}>
                                <MicrosoftCSVAdDetails
                                    additioanData={data}
                                    campaignId={id}
                                    handlePreviewData={setPreviewData}
                                    data={data?.adUpload}
                                    url={url}
                                    method={method}
                                    tabIndex={tabIndex}
                                    setTabIndex={setTabIndex}
                                    activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}
                                />
                </GridItem>
            </Grid>
        </>
    );
};

export default WithAppContext(MicrosoftCSV);
