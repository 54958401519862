import React from 'react';
import {
  Box, Button,
  Flex, Grid,
  Image, Skeleton, SkeletonCircle, SkeletonText, Spacer,
  Text
} from "@chakra-ui/react";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";

function GmailMonitorIn({formDetails, ads, index1, businessName, handleGmailState, carouselCards}) {
  const selectedLogos = (Array.isArray(formDetails?.selectedLogos) && formDetails?.selectedLogos[0]?.imageUrl) ?
    (process.env.REACT_APP_API_URL + "/uploads/" + formDetails?.selectedLogos[0]?.imageUrl) :
    process.env.PUBLIC_URL + "/images/display.png";

  return (
    <>
      <Flex bg="transparent" height="15%" borderTopRadius="25px" alignItems="center" px={2}>
        <Image src={`${process.env.PUBLIC_URL}/images/three_line_icon.svg`} alt="Back Button" height={8}
               width={8} pl={2}/>
        <Image src={`${process.env.PUBLIC_URL}/images/gmail.png`} alt="Gmail Icon" height={8}
               width={10} pl={2}/>
        <Text color="gray.500" fontWeight="600" fontSize="20px" px={2}>Gmail</Text>
        <Flex bg={'gray.300'} height="40%" width="40%" borderRadius="5px" justifyItems="center" alignItems="center">
          <Image src={`${process.env.PUBLIC_URL}/images/search_1.png`} alt="Gmail Icon" height={4}
                 width={6} pl={2}/>
        </Flex>
        <Spacer/>
        <SkeletonCircle size="6"/>
      </Flex>
      <Flex bg="transparent" height="85%" borderTopRadius="25px" alignItems="center" overflow="hidden">
        <Box bg="transparent" width="30%" height="100%" borderTopRadius="25px" alignItems="center">
          <Skeleton height="20%" width="60%" borderRadius="10px" m={4} px={2}/>
          <Skeleton height="10%" width="90%" borderRightRadius="10px"/>
          {[...Array(3)].map((_, index) => (
            <Box m={4} px={2}>
              <Flex gap={2} alignItems="center">
                <SkeletonCircle size="6"/>
                <SkeletonText noOfLines={1} skeletonHeight='3' width="50%"/>
              </Flex>
            </Box>
          ))}
        </Box>
        <Box bg="white" width="68%" height="100%" borderTopRadius="25px">
          <Flex justifyItems="center" px={3} gap={8} py={5} backgroundColor="white"
                borderTopRadius="25" borderBottom="2px solid #dddddd">
            <Flex alignItems="center" justifyContent="center">
              <Image src={`${process.env.PUBLIC_URL}/images/back_icon.png`} alt="Back Button" height={6}
                     width={8} onClick={() => handleGmailState()} cursor="pointer"/>
            </Flex>
            <Flex alignItems="center" justifyContent="center" gap={5}>
              <Flex gap={2} alignItems="center">
                <Image src={`${process.env.PUBLIC_URL}/images/garbage_icon.png`} alt="Back Button" height={5}
                       width={5} />
                <Text>Delete</Text>
              </Flex>
              <Flex gap={2} alignItems="center">
              <Image src={`${process.env.PUBLIC_URL}/images/star_icon.png`} alt="Back Button" height={6}
                     width={6}/>
                <Text>Save to Inbox</Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between">
            <Box display="flex" gap="5" p={6} height="20%">
              <Image src={selectedLogos} height={8}
                     width={8} rounded="full"/>
              <Box>
                <Text fontWeight="500" fontSize={'14px'}>{businessName}</Text>
                <Text fontSize={'small'}>To me</Text>
              </Box>
            </Box>
            <Flex gap="5" p={6} height="20%">
              <Image src={`${process.env.PUBLIC_URL}/images/ad_icon.svg`} alt="Ad Icon" height={6}
                     width={6}/>
              <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Three dots icon" height={5}
                     width={5}/>
            </Flex>
          </Flex>
          <Box px={6}>
            <Box height="35vh" overflowY="auto">
              <Grid templateColumns="repeat(2, 1fr)">
                {ads.map((ad, index) => (
                  <Box key={index} mb={2}>
                    <Flex direction="column" flexGrow="1">
                      <Box width={"90%"} height={""}>
                        <Image
                          width="100%"
                          objectFit="cover"
                          src={ad[index1]?.imageUrl}
                          className="previewImage"
                          borderRadius="10px"
                        />
                      </Box>
                      <Box>
                          <Text fontSize="large" width="fit-content" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" mt={3}>{carouselCards[index].headline}</Text>
                        <Button border="1px solid" borderColor="gray.300" bg="white" borderRadius="100px" color="#4799ff" mx={2} my={2}>
                          {youtubeShortsTypes.find((i) => i.key === formDetails?.call_to_action?.type)?.name}
                        </Button>
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  );
}

export default GmailMonitorIn;
