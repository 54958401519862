import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box, Center, Circle,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link, Skeleton,
  SkeletonCircle,
  SkeletonText, Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";

function Discover({formDetails, appLoading, ads, index, handleIndex, businessName}) {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%'>
                <SkeletonText noOfLines={3} spacing='3' skeletonHeight='2' rounded="lg" p={4}/>
                <Flex p={3} gap={3} alignItems="center">
                  <SkeletonCircle size={5}/>
                  <SkeletonText noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="60%"/>
                </Flex>
                <Flex justifyContent="center" mt={2}>
                  <Box width="90%" height={"40vh"} bg='white' border={'1px solid'} borderColor={'gray.100'} boxShadow="0px 3px 5px #cbcbcb" borderRadius="10px">
                    <Flex height="70%" justifyContent="center">
                      {(ads && ads.length !== 0) ?
                        <Box overflow="hidden" width={"100%"} height={"100%"} alignItems="center">
                          <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                                  onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                            {
                              ads.map((item) => {
                                return (
                                  <SwiperSlide>
                                    <Image
                                      borderTopRadius="10px"
                                      height="100%"
                                      width={"100%"}
                                      objectFit={"fill"}
                                      src={item?.imageUrl}
                                      className="previewImage"
                                    />
                                  </SwiperSlide>
                                )
                              })
                            }
                          </Swiper>
                        </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                      }
                    </Flex>
                    <Box mx={3} my={4} mb={2} fontWeight="500"> {formDetails.headlines[0]} </Box>
                    <Flex gap={2} pb={4}>
                      <Box ml={3} mb={1} fontSize="11px" fontWeight="800"> Ad </Box>
                      <Text fontSize="12px"><li>{businessName}</li></Text>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default Discover;
