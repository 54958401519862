import React from 'react';
import {Box} from "@chakra-ui/react";

function ShareIcon({color = "#0077ff", height = "20px", width = "20px"}) {
  return (
    <Box height={height} width={width}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title/>
        <g data-name="Layer 2" id="Layer_2">
          <path
            d="M18,10a8,8,0,1,0-3.1,6.31l6.4,6.4,1.41-1.41-6.4-6.4A8,8,0,0,0,18,10Zm-8,6a6,6,0,1,1,6-6A6,6,0,0,1,10,16Z"
            fill={color}
          />
        </g>
      </svg>
    </Box>
  );
}

export default ShareIcon;
