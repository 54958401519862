import React from 'react';
import {
  Box, Center, Circle,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link, Skeleton,
  SkeletonCircle,
  SkeletonText, Text
} from "@chakra-ui/react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {PREVIEW_BG_COLOR} from "../../../../../../../../layout/constant/MenuList";
import FullPageLoader from "../../../../../../../../components/FullPageLoader";

function Discover({formDetails, appLoading, ads, index, index1, handleIndex, businessName, carouselCards}) {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='90%' overflowY="scroll">
                <Box backgroundColor='gray.300' textAlign='center'
                     borderTopRadius="25px"
                     alignSelf="center" position="relative">
                  <Flex justifyItems="center" px={3} justifyContent="center" backgroundColor="white"
                        borderTopRadius="25">
                    <Image src={`${process.env.PUBLIC_URL}/images/google_icon.png`} alt="Back Button" height={28}
                           width={40}/>
                  </Flex>
                  <Box float={"right"} top="35%" right="5%" position="absolute">
                    <SkeletonCircle size='7'/>
                  </Box>
                </Box>
                <Box width="100%" pb={6} bg='white'>
                  <Box bg="gray.200" textColor="white" display="flex" justifyContent="space-between"
                       justifyItems="center" borderRadius="25px" m={5} mb={2} p={3} px={5}>
                    <Text color="gray.600">Search</Text>
                    <Flex gap={3}>
                      <Skeleton height="20px" width="20px" />
                      <Skeleton height="20px" width="20px" />
                    </Flex>
                  </Box>
                  <Center>
                    <Box height="1px" width="90%" bg="gray.100" boxShadow="sm"/>
                  </Center>
                  <Flex mt={4} height="50%" justifyContent="center">
                    {(ads && ads.length !== 0) ?
                      <Box overflow="hidden" width={"90%"} height={"100%"} alignItems="center">
                        <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                                onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                          {
                            ads.map((item, index) => {
                              return (
                                <SwiperSlide>
                                  <Box
                                    height="90%"
                                    width="90%"
                                    borderRadius="lg"
                                    boxShadow="lg"
                                    bg="white"
                                    margin="5"
                                  >
                                    <Image
                                      height="100%"
                                      width="100%"
                                      objectFit="cover"
                                      src={item[index1]?.imageUrl}
                                      className="previewImage"
                                    />
                                    <Text height="20%" width="100%" textAlign="center" mt={2}>{carouselCards[index].headline}</Text>
                                  </Box>
                                </SwiperSlide>
                              )
                            })
                          }
                        </Swiper>
                      </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                    }
                  </Flex>
                  <Box mx={5} my={4} mb={2} fontWeight="500"> {formDetails.headline} </Box>
                  <Box mx={5} mb={1} fontSize="11px" fontWeight="800"> Sponsored </Box>
                  <Flex mx={5} justifyContent="space-between">
                    <Flex gap={2}>
                      {/*<Image src={`${process.env.REACT_APP_API_URL}/uploads/${formDetails?.selectedLogos[0]?.imageUrl}`} height={5} width={5}/>*/}
                      <Text fontSize="12px">{businessName}</Text>
                    </Flex>
                    <Flex gap={4}>
                      <Image src={`${process.env.PUBLIC_URL}/images/like_icon.svg`} height={5} width={5}/>
                      <Image src={`${process.env.PUBLIC_URL}/images/upload_icon.svg`} height={5} width={5}/>
                      <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.svg`} height={5} width={5} pt={1}/>
                    </Flex>
                  </Flex>
                </Box>
                <Center>
                  <Box height="1px" width="90%" bg="gray.100" boxShadow="sm"/>
                </Center>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default Discover;
