// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-react-player video {
    background-color: #1a0dab;
    object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/SocialDisplay/components/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":[".background-react-player video {\r\n    background-color: #1a0dab;\r\n    object-fit: cover;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
