import React, {useEffect} from 'react';
import { Navigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getLocalStorageClient} from "./AiAnalytics";
import {settingCurrentClientDetail} from "./store";
import {useRecoilValue} from "recoil";
import {profile} from "../../atoms/authAtom";
import {ROLES} from "../../constant";

function PrivateAnalytics({ children }) {
    const dispatch = useDispatch();
    const { currentClientDetails } = useSelector((state) => state.currentClientDetail)
    const { access_info } = useRecoilValue(profile);
    const roles = access_info?.roles?.length !== 0 ? access_info?.roles : [];

    useEffect(() => {
        const data = getLocalStorageClient()
        dispatch(settingCurrentClientDetail(data))
    }, [])

    if(
        roles && roles.length !== 0 &&
        (roles.includes(ROLES.DEVELOPER) || roles.includes(ROLES.ADMIN)) &&
        Object.keys(currentClientDetails).length &&
        !currentClientDetails.is_ai_enabled
    ) {
        return <Navigate to={"/"} />
    } else {
        return children
    }
}

export default PrivateAnalytics;
