import { Form, FormikProvider, useFormik } from "formik";
import { Button, FormControl, FormLabel, ModalHeader } from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import instance from "../../../../../../../../helpers/axios";
import WithAppContext from "../../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../../components/PopupModal/ErrorModal";

function ExtensionEditForm({
  editFormValue,
  setEditFormValue,
  extensionId,
  context,
  setIsEditFormOpen,
}) {
  const { calloutData, callData, locationData, structuredSnippetData } =
    editFormValue;

  const { setAppLoading, SuccessToaster, ErrorToaster } = context;
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);

  const initialValues = {
    location: locationData?.toString(),
    callout: calloutData?.toString(),
    call: callData?.toString(),
    structured_snippets: structuredSnippetData.toString(),
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleUpdate,
  });

  const onCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const clientId = useSelector((state) => state.client.clientId);
  const { id: campaignId, microsoftId, instagramId } = useParams();

  async function handleUpdate(values) {
    await instance({
      method: "PUT",
      url: `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${microsoftId}/extension/${extensionId}`,
      withCredentials: false,
      data: values,
    })
      .then((res) => {
        if (res.status === 200) setSuccessModal(true);
        setEditFormValue(false);
        setAppLoading(false);
        setIsEditFormOpen(false);
      })
      .catch((error) => {
        setErrorModal(true);
        setAppLoading(false);
      });
  }

  const {
    errors,
    values,
    isValid,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setErrors,
  } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <FormControl marginBottom={4}>
            <FormLabel htmlFor="location">Location</FormLabel>
            <div className="input-box">
              <InputControl
                id="location"
                name="location"
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onBlur={handleBlur}
              />
            </div>
          </FormControl>
          <FormControl marginBottom={4}>
            <FormLabel htmlFor="callout">Call out</FormLabel>
            <div className="input-box">
              <InputControl
                id="callout"
                name="callout"
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onBlur={handleBlur}
              />
            </div>
          </FormControl>
          <FormControl marginBottom={4}>
            <FormLabel htmlFor="call">Call</FormLabel>
            <div className="input-box">
              <InputControl
                id="call"
                name="call"
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onBlur={handleBlur}
              />
            </div>
          </FormControl>
          <FormControl marginBottom={4}>
            <FormLabel htmlFor="structured_snippets">
              Structured Snippet
            </FormLabel>
            <div className="input-box">
              <InputControl
                id="structured_snippets"
                name="structured_snippets"
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onBlur={handleBlur}
              />
            </div>
          </FormControl>
          <Button marginTop={5} type={"submit"}>
            {" "}
            Update{" "}
          </Button>
          <Button
            bg={"red"}
            marginTop={5}
            onClick={() => setIsEditFormOpen(false)}
            marginLeft={4}
          >
            {" "}
            Cancel{" "}
          </Button>
        </Form>
      </FormikProvider>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
      />
    </>
  );
}

export default WithAppContext(ExtensionEditForm);
