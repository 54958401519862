import {useParams} from "react-router-dom";
import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {Form, FormikProvider, useFormik} from "formik";
import {
    Box,
    Button,
    CircularProgress,
    CircularProgressLabel,
    css,
    FormControl,
    FormLabel,
    Grid,
    GridItem, Heading, Tooltip
} from "@chakra-ui/react";
import {InputControl, SelectControl, TextareaControl} from "formik-chakra-ui";
import {dv360AdUploadalueLengths, dv360AdUploadInitialValues} from "../../../../../constant/InitialValues";
import {CreativeType, hostingSource} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {settingEditMediaType, settingFormDetails, settingFormMedia} from "../../../../../../../store/adUploads";
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import TooltipComponent from "../../TooltipComponent"

function DV360ImageAdDetails({activeStep, steps, prevStep, nextStep, label, autoFillData}) {
    const {id, adUploadId} = useParams();

    const clientId = useSelector((state) => state.client.clientId);
    const {formDetails} = useSelector((state) => state.adUpload)

    const {data, refetch} = useGetAdUpload(clientId, id, adUploadId);

    const schema = yup.object().shape({
        adName: yup.string().trim().min(3).max(512).required().label('Ad name'),
        // description: yup.string().trim().min(3).max(128).required().label('Description'),
        displayName: yup.string().trim().min(3).max(1024).optional().label('Display name'),
        // creativeType: yup.string().required().label('Creative type'),
        hostingSource: yup.string().required().label('Hosting source'),
    });

    useEffect(() => {
        if (adUploadId) {
            refetch()
        }
    }, [adUploadId])

    useEffect(() => {
        if (adUploadId) {
            const imageFile = data?.adUpload?.detail?.fileInfoList?.map((el) => el?.files);
            dispatch(settingFormDetails({
                ...data?.adUpload?.detail,
                adName: data?.adUpload?.detail?.name,
                fileInfoList: data?.adUpload?.detail?.fileInfoList
            }))
            dispatch(settingFormMedia({imageURL: imageFile, fileInfoList: data?.adUpload?.detail?.fileInfoList}))
        }
    }, [data])

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: dv360AdUploadInitialValues,
        validationSchema: schema,
        onSubmit: (values) => {
            dispatch(settingFormDetails(values))
            nextStep()
        }
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;


    useEffect(() => {
        setFieldValue('adName', formDetails.adName || autoFillData?.name || "")
        // setFieldValue('description', formDetails.description || (Array.isArray(autoFillData?.description) ? autoFillData?.description[0] : autoFillData?.description) || "")
        setFieldValue('displayName', formDetails.displayName || autoFillData?.displayName || "")
            // setFieldValue('creativeType', formDetails.creativeType || autoFillData?.creativeType || "")
        setFieldValue('hostingSource', "CM")  // formDetails.hostingSource || autoFillData?.hostingSource || ""

    }, [formDetails, autoFillData])


    return (
        <>
            {/*{*/}
            {/*    adUploadId && (*/}
            {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
            {/*            <Heading fontSize="xl" color={"#757998"}>*/}
            {/*                Please review the ad details carefully.*/}
            {/*            </Heading>*/}
            {/*            <Heading fontSize="md" color="gray.400">*/}
            {/*                3 steps to complete*/}
            {/*            </Heading>*/}
            {/*        </Box>*/}
            {/*    )*/}
            {/*}*/}

            {/*{*/}
            {/*    !adUploadId && (*/}
            {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
            {/*            <Heading fontSize="xl" color={"#757998"}>*/}
            {/*                Please fill in your ad details.*/}
            {/*            </Heading>*/}
            {/*            <Heading fontSize="md" color="gray.400">*/}
            {/*                3 steps to complete*/}
            {/*            </Heading>*/}
            {/*        </Box>*/}
            {/*    )*/}
            {/*}*/}


            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid
                        gap={4}
                        className="fb-upload"
                    >
                        <GridItem
                            w="full"
                            colSpan={{base: 6, lg: 4}}
                        >
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="adName"
                                            name="adName"
                                            value={values.adName}
                                            placeholder=""
                                            inputProps={{
                                                variant: "outline",
                                                type: "text",
                                                placeholder: "Ad Name - max 512 characters"
                                            }}
                                            onChange={(e) => setFieldValue('adName', e.target.value)}
                                        />
                                        <TooltipComponent
                                            text={"The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."}/>
                                        <CircularProgress
                                            max={dv360AdUploadalueLengths.adName}
                                            value={values.adName.length}
                                            color={values.adName.length > dv360AdUploadalueLengths.adName ? "red.400" : "blue.400"}
                                        >
                                            <CircularProgressLabel>
                                                {values.adName.length > dv360AdUploadalueLengths.adName ? dv360AdUploadalueLengths.adName - values.adName.length : values.adName.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                            {/*<GridItem mb={2}>*/}
                            {/*    <FormControl>*/}
                            {/*        <FormLabel htmlFor="description" mb={0}>Description</FormLabel>*/}
                            {/*        <div className="input-box">*/}
                            {/*            <TextareaControl*/}
                            {/*                id="description"*/}
                            {/*                name="description"*/}
                            {/*                value={*/}
                            {/*                    values.description*/}
                            {/*                }*/}
                            {/*                placeholder=""*/}
                            {/*                textareaProps={{*/}
                            {/*                    variant: "outline",*/}
                            {/*                    type: "text",*/}
                            {/*                    placeholder: " Description - max 128 characters"*/}
                            {/*                }}*/}
                            {/*                onChange={(e) => setFieldValue('description', e.target.value)}*/}
                            {/*            />*/}
                            {/*            <CircularProgress*/}
                            {/*                max={dv360AdUploadalueLengths.description}*/}
                            {/*                value={values.description.length}*/}
                            {/*                color={*/}
                            {/*                    values.description.length > dv360AdUploadalueLengths.description ? "red.400" : "blue.400"}*/}
                            {/*            >*/}
                            {/*                <CircularProgressLabel>*/}
                            {/*                    {values.description.length > dv360AdUploadalueLengths.description ? dv360AdUploadalueLengths.description - values.description.length : values.description.length}*/}
                            {/*                </CircularProgressLabel>*/}
                            {/*            </CircularProgress>*/}
                            {/*        </div>*/}
                            {/*    </FormControl>*/}
                            {/*</GridItem>*/}
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel htmlFor="displayName" mb={0}>Display Name</FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="displayName"
                                            name="displayName"
                                            value={
                                                values.displayName
                                            }
                                            inputProps={{
                                                variant: "outline",
                                                type: "text",
                                                placeholder: "Display Name - max 1024 characters"
                                            }}
                                            onChange={(e) => setFieldValue('displayName', e.target.value)}
                                        />
                                        <CircularProgress
                                            max={dv360AdUploadalueLengths.displayName}
                                            value={values.displayName.length}
                                            color={values.displayName.length > dv360AdUploadalueLengths.displayName ? "red.400" : "blue.400"}
                                        >
                                            <CircularProgressLabel>
                                                {values.displayName.length > dv360AdUploadalueLengths.displayName ? dv360AdUploadalueLengths.displayName - values.displayName.length : values.displayName.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                            {/*<GridItem mb={2}>*/}
                            {/*    <FormControl>*/}
                            {/*        <FormLabel htmlFor="creativeType" mb={0}>Creative Type</FormLabel>*/}
                            {/*        <div className="input-box">*/}
                            {/*            <SelectControl*/}
                            {/*                id="creativeType"*/}
                            {/*                name="creativeType"*/}
                            {/*                selectProps={{*/}
                            {/*                    placeholder:*/}
                            {/*                        "-- Select One --",*/}
                            {/*                    variant: "outline",*/}
                            {/*                    border: "2px",*/}
                            {/*                    borderRadius: 0,*/}
                            {/*                    borderColor: "gray",*/}
                            {/*                    fontWeight: "600",*/}
                            {/*                    fontSize: "14px",*/}
                            {/*                    lineHeight: "16px",*/}
                            {/*                    color: "#757998",*/}
                            {/*                    marginRight:*/}
                            {/*                        "100px",*/}
                            {/*                }}*/}
                            {/*                onChange={(e) => setFieldValue('creativeType', e.target.value)}*/}
                            {/*            >*/}
                            {/*                {CreativeType.map((el) => (*/}
                            {/*                    <option value={el.key} key={el.key}> {el.value} </option>))}*/}
                            {/*            </SelectControl>*/}
                            {/*            <CircularProgress*/}
                            {/*                opacity={0}*/}
                            {/*            >*/}
                            {/*                <CircularProgressLabel>*/}
                            {/*                    0*/}
                            {/*                </CircularProgressLabel>*/}
                            {/*            </CircularProgress>*/}
                            {/*        </div>*/}
                            {/*    </FormControl>*/}
                            {/*</GridItem>*/}
                            {/*<GridItem mb={2}>*/}
                            {/*    <FormControl>*/}
                            {/*        <FormLabel htmlFor="hostingSource" mb={0}>Hosting Source 12</FormLabel>*/}
                            {/*        <div className="input-box">*/}
                            {/*            <SelectControl*/}
                            {/*                id="hostingSource"*/}
                            {/*                name="hostingSource"*/}
                            {/*                selectProps={{*/}
                            {/*                    placeholder:*/}
                            {/*                        "-- Select One --",*/}
                            {/*                    variant: "outline",*/}
                            {/*                    border: "2px",*/}
                            {/*                    borderRadius: 0,*/}
                            {/*                    borderColor: "gray",*/}
                            {/*                    fontWeight: "600",*/}
                            {/*                    fontSize: "14px",*/}
                            {/*                    lineHeight: "16px",*/}
                            {/*                    color: "#757998",*/}
                            {/*                    marginRight:*/}
                            {/*                        "100px",*/}
                            {/*                }}*/}
                            {/*                onChange={(e) => setFieldValue('hostingSource', e.target.value)}*/}
                            {/*            >*/}
                            {/*                {hostingSource.map(*/}
                            {/*                    (el) => (<option value={el.key} key={el.key}> {el.name} </option>))}*/}
                            {/*            </SelectControl>*/}
                            {/*            <CircularProgress*/}
                            {/*                opacity={0}*/}
                            {/*            >*/}
                            {/*                <CircularProgressLabel>*/}
                            {/*                    0*/}
                            {/*                </CircularProgressLabel>*/}
                            {/*            </CircularProgress>*/}
                            {/*        </div>*/}
                            {/*    </FormControl>*/}
                            {/*</GridItem>*/}
                        </GridItem>
                    </Grid>
                    <Flex width="100%" justify="flex-end" py={10}>
                        {/*{*/}
                        {/*    !adUploadId && <Button*/}
                        {/*        mr={4}*/}
                        {/*        onClick={prevStep}*/}
                        {/*        colorScheme='blue' variant='outline' borderRadius={0} px={10}*/}
                        {/*    >*/}
                        {/*        Back*/}
                        {/*    </Button>*/}
                        {/*}*/}

                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                            Next
                        </Button>
                    </Flex>
                </Form>
            </FormikProvider>

        </>
    )
}

export default DV360ImageAdDetails
