// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ad-upload-list .ad-name {
  display: flex;
  align-items: center;
}
.ad-upload-list .ad-name img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-right: 10px;
}
.ad-upload-list .search {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;
  overflow: auto;
}
.ad-upload-list .search .chakra-stack{
  width: 79%;
}
.ad-upload-list .search input {
  border-color: #79a9ff;
  border-width: 2px;
}
.ad-upload-list .search button.chakra-button {
  margin: 0 0 0 20px;
}

.channel-type{
  display: flex;
  align-items: center;
  gap: 6px;
}`, "",{"version":3,"sources":["webpack://./src/pages/CampaignBriefs/style/AdUploadList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,UAAU;AACZ;AACA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".ad-upload-list .ad-name {\n  display: flex;\n  align-items: center;\n}\n.ad-upload-list .ad-name img {\n  width: 32px;\n  height: 32px;\n  object-fit: cover;\n  margin-right: 10px;\n}\n.ad-upload-list .search {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  margin-bottom: 30px;\n  overflow: auto;\n}\n.ad-upload-list .search .chakra-stack{\n  width: 79%;\n}\n.ad-upload-list .search input {\n  border-color: #79a9ff;\n  border-width: 2px;\n}\n.ad-upload-list .search button.chakra-button {\n  margin: 0 0 0 20px;\n}\n\n.channel-type{\n  display: flex;\n  align-items: center;\n  gap: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
