import {
    Box, Button,
    Container, Flex,
    Grid,
    GridItem,
    Heading,
    Image, Spacer, Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PREVIEW_BG_COLOR, TEXT_COLOR, INDICATOR_BG_COLOR} from "../../../../../../layout/constant/MenuList";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../constant";
import {useUploadImage} from "../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../helpers/axios";
import {Link, useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../components/FullPageLoader";
import {deleteTheField} from "../../../../../../store/adUploads"
import {useGetAdUpload} from "../../../../../../hooks/campaign-briefs/useGetAdUpload";
import _ from "lodash";
import {deleteAllAudioField} from "./store";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../atoms/authAtom";
import {CreateMultipleCreatives} from "../SocialDisplay/SocialDisplayImage/SocialDisplayImageAdPreview";

const AudioPreview = ({ activeStep, steps, prevStep, nextStep, label, context}) => {


    const { handlePreviewData: audioPreviewData } = useSelector(((state) => state.audioAd))

    const [preview, setPreview] = useState(null);
    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const { id: campaignId, adUploadId } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const [thumbnailURL, setThumbnailURL] = useState("");
    const [audioURL, setAudioURL] = useState("");
    const { data, refetch } = useGetAdUpload(clientId, campaignId, adUploadId);
    const { mutateAsync } = useUploadImage();
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)
    const { allCampaignIds } = useSelector((state) => state.adUpload);

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);


    //handling preview
    useEffect(() => {
        if (audioPreviewData) {
            setPreview(audioPreviewData);
            const videoURL =
                audioPreviewData && audioPreviewData.audioFiles[0]?.imageUrl
                    ? `${process.env.REACT_APP_API_URL}/uploads${
                        audioPreviewData && audioPreviewData.audioFiles[0]?.imageUrl
                    }`
                    : URL.createObjectURL(audioPreviewData.audioFiles[0].file);
            const audioThumbnail =
                audioPreviewData && audioPreviewData.audioThumbnail[0]?.imageUrl
                    ? `${process.env.REACT_APP_API_URL}/uploads${
                        audioPreviewData && audioPreviewData.audioThumbnail[0]?.imageUrl
                    }`
                    : URL.createObjectURL(audioPreviewData.audioThumbnail[0].file);
            setThumbnailURL(audioThumbnail);
            setAudioURL(videoURL);
        }
    }, [audioPreviewData]);

    useEffect(() => {
        if(adUploadId) {
            refetch()
        }
    }, [adUploadId])

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.audioAd)
    const {creative,selectedSocialMedia} = useSelector((state)=> state.adUpload)


    const {fileInfoListAudio, fileInfoListThumbnail} = formDetails

    useEffect(() => {
        setAds(handlePreviewData)
    }, [handlePreviewData]);

    const getSlider = () => {
        if (ads?.length) {
            const slides = ads.map((el, ind) => (
                <Text
                    height={1}
                    borderRadius={5}
                    px={4}
                    mx={1}
                    mt={8}
                    key={ind}
                    background={ind === index ? INDICATOR_BG_COLOR : "silver"}
                    onClick={() => setIndex(ind)}
                    cursor="pointer"
                />
            ));
            return slides;
        }
    };

    const uploadFile = async (file, type) => {
        let audioFile = [];
        let mediaInfoList = [];
        let mediaIds = [];
        let mediaDimensions = [];
        if (file.length !== 0) {
            try {
                audioFile = await mutateAsync({
                    clientId: clientId,
                    campaignBriefId: campaignId,
                    adFile: file,
                    type: "audio",
                });
                mediaInfoList =
                    audioFile &&
                    audioFile.fileInfoList.map((el) => {
                        return {
                            ...el,
                            metadata: [{ height: "", width: "" }],
                        };
                    });
                mediaIds = audioFile && audioFile.fileInfoList.map((el) => el.imageHash);
                mediaDimensions =
                    audioFile &&
                    audioFile.fileInfoList.map(() => {
                        return {
                            width: "",
                            height: "",
                        };
                    });
            } catch (e) {
                setAppLoading(false);
            }
        }
        if (type === "audio") {
            return { audio: { mediaInfoList, mediaIds, mediaDimensions } };
        } else {
            return { thumbnail: { mediaInfoList, mediaIds, mediaDimensions } };
        }
    };

    const onHandleSubmit = async (values) => {
        const uploadArr = [];
        let audioData;
        let thumbnailData;
        if (_.isEqual(fileInfoListAudio, values.audioFiles)) {
            // const { detail } = data;
            audioData = { audio: formDetails.audio };
        } else {
            uploadArr.push(uploadFile(values.audioFiles, "audio"));
        }
        if (
            _.isEqual(fileInfoListThumbnail, values.audioThumbnail)
        ) {
            // const { detail } = data;
            thumbnailData = { thumbnail: formDetails.thumbnail };
        } else {
            uploadArr.push(uploadFile(values.audioThumbnail, "thumbnail"));
        }

        if (uploadArr.length !== 0) {
            const promise = await Promise.all(uploadArr);
            promise.forEach((res) => {
                if (!_.isEmpty(res?.audio)) {
                    const { audio } = res;
                    audioData = {
                        audio: {
                            mediaIds: audio.mediaIds,
                            fileInfoList: audio.mediaInfoList,
                            dimensions: audio.mediaDimensions,
                        },
                    };
                }
                if (!_.isEmpty(res?.thumbnail)) {
                    const { thumbnail } = res;
                    thumbnailData = {
                        thumbnail: {
                            mediaIds: thumbnail.mediaIds,
                            fileInfoList: thumbnail.mediaInfoList,
                            dimensions: thumbnail.mediaDimensions,
                        },
                    };
                }
            });
        }

        return {
            name: values.adName,
            detail: {
                name: values.adName,
                landingPageURL: values.landingPageURL,
                ...audioData,
                ...thumbnailData,
                creative,
                channel: selectedSocialMedia
            },
        };
    };

    const navigate = useNavigate();
    const dispatch = useDispatch()


    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if(adUploadId) {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
                    state: {isShowUpload: true}
                })
            }
            dispatch(deleteAllAudioField())
            dispatch(deleteTheField())
        }
        else {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`)
            }
            dispatch(deleteAllAudioField())
            dispatch(deleteTheField())
        }
    }

    async function handleSubmit() {
        try {
            setAppLoading(true);
            let payload = await onHandleSubmit({ ...formDetails, ...mediaDetails });
            if (!adUploadId) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.AUDIO,
                };
            }

            if(allCampaignIds?.length){
                CreateMultipleCreatives(allCampaignIds, method, url, clientId, payload, setSuccessModal, setErrorModal, setAppLoading, setDescription, dispatch);
            } else {
                await instance({
                    method: method,
                    url: url,
                    withCredentials: false,
                    data: payload,
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setSuccessModal(true);
                        }
                        setAppLoading(false);
                    })
                    .catch((error) => {
                        setErrorModal(true);
                        setAppLoading(false);
                        setDescription(error.response.data.message);
                    });
            }
        } catch (error) {
            setAppLoading(false);
        }
    }

    return (
        <>
            {/*{*/}
            {/*    adUploadId && (*/}
            {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
            {/*            <Heading fontSize="xl" color={"#757998"}>*/}
            {/*                Please review the ad details carefully.*/}
            {/*            </Heading>*/}
            {/*            <Heading fontSize="md" color="gray.400">*/}
            {/*                1 steps to complete*/}
            {/*            </Heading>*/}
            {/*        </Box>*/}
            {/*    )*/}
            {/*}*/}

            {/*{*/}
            {/*    !adUploadId && (*/}
            {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
            {/*            <Heading fontSize="xl" color={"#757998"}>*/}
            {/*                Please review your ad.*/}
            {/*            </Heading>*/}
            {/*            <Heading fontSize="md" color="gray.400">*/}
            {/*                1 steps to complete*/}
            {/*            </Heading>*/}
            {/*        </Box>*/}
            {/*    )*/}
            {/*}*/}

            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                {appLoading && <FullPageLoader />}
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                    <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
                        {ads?.[index]?.filename}
                    </Heading>
                    <Box
                        borderWidth="2px"
                        borderColor="gray"
                        p={10}
                        background={PREVIEW_BG_COLOR}
                        position={"relative"}
                    >
                        <Container height="100%">
                            <Flex alignItems="center" flexDirection="row" justify="center">
                                {preview && (
                                    <Box boxShadow="base" p="6" rounded="md" bg="white">
                                            <Image
                                                src={thumbnailURL}
                                                cursor="pointer"
                                                marginBottom="20px"
                                            />
                                        <audio style={{width: '100%'}} src={audioURL} controls />
                                    </Box>
                                )}
                            </Flex>
                        </Container>
                    </Box>

                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        {/*<Button mr={4} colorScheme='blue' variant='solid' borderRadius={0} px={10}>*/}
                        {/*    Save Draft*/}
                        {/*</Button>*/}
                        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                            { adUploadId ? 'Update' : 'Submit'}
                        </Button>
                    </Flex>

                </GridItem>
                <SuccessModal
                    isOpen={isSuccessModalOpen}
                    onClose={() => onCloseSuccessModal()}
                />
                <ErrorModal
                    isOpen={isErrorModalOpen}
                    onClose={() => setErrorModal(false)}
                    description={description}
                />
            </Grid>
        </>
    );
};

export default WithAppContext(AudioPreview);
