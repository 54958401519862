import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const dynamicFacebookBothSlice = createSlice({
    name: AD_UPLOAD_TYPE.DYNAMIC_FB_BOTH,
    initialState,
    reducers: {
        settingDynamicFacebookBothFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingDynamicFacebookBothFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingDynamicFacebookBothPreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllDynamicFacebookBothField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingDynamicFacebookBothFormDetail, settingDynamicFacebookBothFormMedia, settingDynamicFacebookBothPreviewData, deleteAllDynamicFacebookBothField} = dynamicFacebookBothSlice.actions;

// The reducer
export const dynamicFacebookBothReducer = dynamicFacebookBothSlice.reducer;
