import React, {useEffect, useMemo, useState} from 'react';
import {Box, Button, Divider, Heading, HStack, Image, Text, useToast} from "@chakra-ui/react";
import {TEXT_COLOR} from "../../layout/constant/MenuList";
import Datatable from "../../components/Datatable";
import {useGetFeedbacks} from "../../hooks/feedback/useGetFeedbacks";

function Feedback(props) {
    const [reports, setReports] = useState([]);
    const {data, refetch} = useGetFeedbacks();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setReports(data);
    }, [data]);

    const handleRedirectUrl = (data, index) => {
        return `${process.env.REACT_APP_API_URL}/uploads/${data.row.original.screenshots[index]?.filename}`
    }

    const columns = useMemo(
        () => [
            {
                Header: "Client Name",
                accessor: "feedback_client.name",
            },
            {
                Header: "User Email",
                accessor: "feedback_user.email",
            },
            {
                Header: "Issue Type",
                accessor: "reporting_issue_type",
            },
            {
                Header: "Issue",
                accessor: "message",
            },
            {
                Header: () => <Text>Screenshots</Text>,
                accessor: "actions",
                Cell: (data) => {
                    return (
                        <HStack>
                            {data.row.original.screenshots.length > 0 ? data.row.original.screenshots.map((item, index) => {
                                const url = handleRedirectUrl(data, index);
                                return <Box
                                    mt={1}
                                    w="50px"
                                    h="50px"
                                    cursor="pointer"
                                    _hover={{cursor: "pointer"}}
                                    onClick={() => {
                                        window.open(url, "_blank");
                                    }}
                                    title="Click to view"
                                >
                                    <Image
                                        src={`${process.env.REACT_APP_API_URL}/uploads/${item?.filename}`}
                                        height="100%"
                                        width="100%"
                                        objectFit="cover" // Adjust image to fill the box
                                    />
                                </Box>;
                            }) : <Text style={{display: "flex", alignItems: "center"}} w="50px" h="50px">No screenshots
                                attached</Text>}
                        </HStack>
                    )
                },
            },
        ], [data]
    );

    return (
        <div>
            <Box>
                <Heading color={TEXT_COLOR} fontWeight="500" size="lg" my={5} mb={7}>
                    Feedbacks
                </Heading>

                <Divider/>
                <Datatable
                    data={reports || []}
                    columns={columns}
                    headerColor={"brand.primary"}
                />
            </Box>
        </div>
    );
}

export default Feedback;
