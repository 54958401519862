import {
  Box, Button, Flex,
  Image as ChakraImage, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {deleteTheField} from "../../../../../../../store/adUploads";
import YoutubeHome from "../components/YoutubeHome";
import Gmail from "../components/Gmail";
import Discover from "../components/Discover";
import YoutubeWatch from "../components/YoutubeWatch";
import YoutubeHomeMonitor from "../components/Monitor/YoutubeHomeMonitor";
import YoutubeSearchMonitor from "../components/Monitor/YoutubeSearchMonitor";
import YoutubeWatchMonitor from "../components/Monitor/YoutubeWatchMonitor";
import {deleteAllDemandGenImageFields} from "./store";

export async function fetchImageMetadata(imageFiles) {
  const imageFileInfoPromises = imageFiles.map((el) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const { naturalWidth, naturalHeight } = img;
        console.log('width and height', naturalWidth, naturalHeight);
        resolve({
          ...el,
          metadata: { height: naturalHeight, width: naturalWidth },
        });
      };
      img.src = el.imageUrl.includes("https://") ?
          el.imageUrl :
          `${process.env.REACT_APP_API_URL}/uploads${el.imageUrl}`
    });
  });

  const imageFileInfoList = await Promise.all(imageFileInfoPromises);

  console.log('imageFileInfoList from fetchImageMetadata fn  --->', imageFileInfoList)
  return imageFileInfoList;
}

const DemandGenImageAdPreview = ({activeStep, steps, prevStep, nextStep, label, context}) => {
  const [index, setIndex] = useState(0);
  const [ads, setAds] = useState([]);
  const [method, setMethod] = useState();
  const [url, setUrl] = useState();
  const {id: campaignId, adUploadId} = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const {setAppLoading, appLoading} = context;
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [description, setDescription] = useState("");
  const {
    access_info: {roles, clients: userClients},
  } = useRecoilValue(profile);
  const [isClient, setIsClient] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    setIsClient(roles.includes("Client"));
  }, [roles]);

  const {data, refetch} = useGetAdUpload(clientId, campaignId, adUploadId);

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (adUploadId) {
      setMethod("PUT");
      setUrl(
        `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
      );
    } else {
      setMethod("POST");
      setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
    }
  }, [adUploadId, clientId]);

  const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.demandGenImageAd)
  const {creative, selectedSocialMedia} = useSelector((state) => state.adUpload)
  const {businessDetails} = useSelector((state)=> state.adCampaign)

  const {selectedImages, selectedLogos} = formDetails
  useEffect(() => {
    setAds(handlePreviewData.selectedImages)
  }, [handlePreviewData]);

  const {mutateAsync} = useUploadImage();

  const onSubmitImages = async (multiFiles, oldFiles) => {
    let payload = [];
    let otherFile = [];
    let files = [];

    multiFiles.forEach((file) => {
      if (file?.filename) {
        otherFile = [...oldFiles]
      } else {
        payload.push(file);
      }
    })


    if (payload && payload.length) {
      await mutateAsync(
        {
          clientId: clientId,
          campaignBriefId: campaignId,
          adFile: payload,
          type: "demandGen",
        },
        {
          onSuccess: (data, variables, context) => {
            payload = data.fileInfoList;
            setAppLoading(false)
          },
          onError: (error) => {
            setAppLoading(false)
            setDescription(error?.response?.data?.message);
          }
        }
      );
      files = [...otherFile, ...payload]
    } else {
      files = [...otherFile]
    }
    return files;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch()


  const onCloseSuccessModal = () => {
    setSuccessModal(false)

    if (adUploadId) {
      navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
        state: {isShowUpload: true}
      })
      dispatch(deleteAllDemandGenImageFields())
      dispatch(deleteTheField())
    } else {
      navigate(`/campaign-briefs/${campaignId}/ad-upload`)
      dispatch(deleteAllDemandGenImageFields())
      dispatch(deleteTheField())
    }
  }

  async function handleSubmit() {
    setAppLoading(true);
    const imageFiles = await onSubmitImages(mediaDetails.selectedImages, selectedImages);
    const logoFiles = await onSubmitImages(mediaDetails.selectedLogos, selectedLogos);
    const mediaIds = imageFiles.map((el) => el.imageHash);

    const imageFileInfoList = await fetchImageMetadata(imageFiles);

    console.log("imageFileInfoList ", imageFileInfoList);

    const logoFileInfoList = await fetchImageMetadata(logoFiles);

    console.log("logoFileInfoList ", logoFileInfoList);

    let payload = {
      name: formDetails.adName,
      description: formDetails.descriptions[0],
      title: formDetails.title,
      link: formDetails.link,
      detail: {
        name: formDetails.adName,
        headlines: formDetails.headlines,
        descriptions: formDetails.descriptions,
        title: formDetails.title,
        call_to_action: {
          type: formDetails.type,
          link: formDetails.link,
        },
        mediaIds: mediaIds,
        selectedImages: imageFileInfoList,
        selectedLogos: logoFileInfoList,
        creative,
        channel: selectedSocialMedia
      },
    };

    if (!adUploadId) {
      payload = {
        ...payload,
        ad_upload_type: AD_UPLOAD_TYPE.DEMAND_GEN_IMAGE,
      };
    }

    await instance({
      method: method,
      url: url,
      withCredentials: false,
      data: payload,
    })
      .then((res) => {
        if (res.status === 200) {
          setSuccessModal(true);
          setAppLoading(false)
        }
      })
      .catch((error) => {
        setDescription(error.response.data.message);
        setErrorModal(true);
        setAppLoading(false)
      });
  }
  const handleIndex = i => setIndex(i)

  return (
    <>
        <Tabs isFitted variant='enclosed'>
          <TabList>
            <Tab>
              <Box align="center">
                <ChakraImage src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="YouTube-Icon" height={8} width={8} margin={2}/>
                <h1>YouTube</h1>
              </Box>
            </Tab>
            <Tab>
              <Box align="center">
                <ChakraImage src={`${process.env.PUBLIC_URL}/images/discover.png`} alt="YouTube-Icon" height={8} width={8} margin={2}/>
                <h1>Discover</h1>
              </Box>
            </Tab>
            <Tab>
              <Box align="center">
                <ChakraImage src={`${process.env.PUBLIC_URL}/images/gmail.png`} alt="YouTube-Icon" height={8} width={8} margin={2}/>
                <h1>Gmail</h1>
              </Box>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex alignItems='center' gap='3' justifyContent='end'>
                <Box>
                  <Tabs variant='line' index={tabIndex}>
                    <TabList>
                      <Tab onClick={() => setTabIndex(0)}>
                        <ChakraImage src={`${process.env.PUBLIC_URL}/images/phone_icon.png`} alt="Phone-Icon" height={8} width={8}/>
                      </Tab>
                      <Tab onClick={() => setTabIndex(1)}>
                        <ChakraImage src={`${process.env.PUBLIC_URL}/images/computer_icon.png`} alt="Computer-Icon" height={8}
                               width={8}/>
                      </Tab>
                    </TabList>
                  </Tabs>
                </Box>
              </Flex>

              {tabIndex === 0 &&
                <Tabs>
                  <TabList align='end'>
                    <Tab>YouTube Home</Tab>
                    <Tab>YouTube Watch</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <YoutubeHome
                          formDetails={formDetails}
                          appLoading={appLoading}
                          ads={ads}
                          index={index}
                          handleIndex={handleIndex}
                          businessName={businessDetails.name}
                      />
                    </TabPanel>
                    <TabPanel>
                      <YoutubeWatch
                          formDetails={formDetails}
                          appLoading={appLoading}
                          ads={ads}
                          index={index}
                          handleIndex={handleIndex}
                          businessName={businessDetails.name}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              }
              {tabIndex === 1 &&
                <Tabs>
                  <TabList align='end'>
                    <Tab>YouTube Home</Tab>
                    <Tab>YouTube Search</Tab>
                    <Tab>YouTube Watch</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <YoutubeHomeMonitor
                          formDetails={formDetails}
                          appLoading={appLoading}
                          ads={ads}
                          index={index}
                          handleIndex={handleIndex}
                          businessName={businessDetails.name}/>
                    </TabPanel>
                    <TabPanel>
                      <YoutubeSearchMonitor
                          formDetails={formDetails}
                          appLoading={appLoading}
                          ads={ads}
                          index={index}
                          handleIndex={handleIndex}
                          businessName={businessDetails.name}
                      />
                    </TabPanel>
                    <TabPanel>
                      <YoutubeWatchMonitor
                          formDetails={formDetails}
                          appLoading={appLoading}
                          ads={ads}
                          index={index}
                          handleIndex={handleIndex}
                          businessName={businessDetails.name}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              }
            </TabPanel>
            <TabPanel>
              <Discover
                formDetails={formDetails}
                appLoading={appLoading}
                ads={ads}
                index={index}
                handleIndex={handleIndex}
                businessName={businessDetails.name}
              />
            </TabPanel>
            <TabPanel>
              <Gmail
                formDetails={formDetails}
                appLoading={appLoading}
                ads={ads}
                index={index}
                handleIndex={handleIndex}
                businessName={businessDetails.name}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      <Flex width="100%" justify="flex-end" py={10}>
        <Button
          mr={4}
          onClick={prevStep}
          colorScheme='blue' variant='outline' borderRadius={0} px={10}
        >
          Back
        </Button>
        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
          {adUploadId ? 'Update' : 'Submit'}
        </Button>
      </Flex>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={description}
      />
    </>
  );
};

export default WithAppContext(DemandGenImageAdPreview);
