import {
  Box,
  Checkbox,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Radio,
} from "@chakra-ui/react";
import { RadioGroupControl } from "formik-chakra-ui";
import React, { useEffect, useState } from "react";
import InputBox from "../../../../components/InputBox";
import MultiSelectInputBox from "../../../../components/MultiSelectInputBox";
import "../../style/CampaignDetails.css";
import { CreativeSizes, CreativeUnit } from "../../constant/SelectValues";
import { TEXT_COLOR } from "../../../../layout/constant/MenuList";

const Creative = ({ values, setFieldValue }) => {
  const [selectedCreativeUnit, setSelectedCreativUnit] = useState([]);
  const [selectedCreativeSize, setSelectedCreativeSize] = useState([]);

  useEffect(() => {
    if (values?.detail?.creativeSize?.length) {
      setSelectedCreativeSize(
        values.detail.creativeSize.map((el) => {
          const id = CreativeSizes.filter((e) => e.value === el);
          return { value: el, label: id?.[0]?.label };
        })
      );
    }
  }, [values?.detail?.creativeSize]);

  useEffect(() => {
    if (values?.detail?.creativeUnit?.length) {
      setSelectedCreativUnit(
        values.detail.creativeUnit.map((el) => {
          const id = CreativeUnit.filter((e) => e.value === el);
          return { value: el, label: id?.[0]?.label };
        })
      );
    }
  }, [values?.detail?.creativeUnit]);

  return (
    <Box p={4}>
      <Box mb={6} display="flex" justifyContent="space-between">
        <Heading fontSize="xl" color={TEXT_COLOR}>
          Creative
        </Heading>
        <Heading fontSize="md" color="gray.400">
          2 steps to complete
        </Heading>
      </Box>
      <Grid
        className="flex-for-mobile"
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        <GridItem
          mb={6}
          colSpan={2}
          display="flex"
          justifyContent="space-between"
        ></GridItem>
        <GridItem>
          <MultiSelectInputBox
            options={CreativeUnit}
            value={selectedCreativeUnit}
            label="Creative Unit"
            name="detail.creativeUnit"
            placeholder={`Select...`}
            onChange={(e) => {
              setSelectedCreativUnit(e.map((v) => v));
              setFieldValue(
                "detail.creativeUnit",
                e.map((el) => el.value)
              );
            }}
          />
        </GridItem>
        <GridItem>
          <MultiSelectInputBox
            options={CreativeSizes}
            value={selectedCreativeSize}
            label="What creative sizes will we have access to?"
            name="detail.creativeSize"
            placeholder={`Select...`}
            onChange={(e) => {
              setSelectedCreativeSize(e.map((v) => v));
              setFieldValue(
                "detail.creativeSize",
                e.map((el) => el.value)
              );
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <InputBox
            name="detail.linkToAdServing"
            placeholder="Link to Ad Serving"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Box className="creative-item">
            <FormLabel>
              Can we pixel your landing page? (Conversion, Events, and Re
              targeting)
            </FormLabel>
            <RadioGroupControl name="detail.canPixelLandingPage">
              <Radio colorScheme="yellow" value="Yes">
                Yes
              </Radio>
              <Radio colorScheme="yellow" value="No">
                No
              </Radio>
            </RadioGroupControl>
          </Box>
          <Box className="creative-item">
            <FormLabel>Do you use Google Tag Manager?</FormLabel>
            <Checkbox colorScheme="yellow">Yes</Checkbox>
            <InputBox name="detail.googleTagManager" />
          </Box>
          <Box className="creative-item">
            <FormLabel>Number of “Unique Page views” per month</FormLabel>
            <InputBox name="detail.numUniquePageViews" />
          </Box>
        </GridItem>
        <GridItem colSpan={2}>
          <InputBox
            name="detail.numberOfCreativeExecutions"
            placeholder="Number Of Creative Executions"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <InputBox
            name="detail.socialPage"
            placeholder="Social Page (Required)"
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Creative;
