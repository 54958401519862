import React, {useEffect, useMemo, useState} from 'react';
import {
    Box,
    Heading, Icon, Tag, TagLeftIcon,
    Text
} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {useNotification} from "../../hooks/useNotification";
import Datatable from "../../components/Datatable";
import _ from "lodash";
import moment from "moment";
import {AD_UPLOAD_STATUS} from "../../constant";
import {CheckCircleIcon, SmallCloseIcon, WarningIcon} from "@chakra-ui/icons";
import {TagLabel} from "@chakra-ui/tag";
import {getLocalStorageClient} from "../../layout/components/Sidebar";
import SearchAndDropdown from "../../components/SearchAndDropdown";

function StatusTag({ data }) {
    return (
        <Box>
            {AD_UPLOAD_STATUS.CREATED === data.value?.toUpperCase() && (
                <Tag
                    backgroundColor={"#FFC108"}
                    size="md"
                    color={"white"}
                    borderRadius={100}
                >
                    <TagLeftIcon boxSize="3" as={WarningIcon}/>
                    <TagLabel fontSize="xx-small">Action Needed</TagLabel>
                </Tag>
            )}
            {AD_UPLOAD_STATUS.APPROVED === data.value?.toUpperCase() && (
                <Tag
                    backgroundColor={"#63C68B"}
                    size="md"
                    color={"white"}
                    borderRadius={100}
                >
                    <TagLeftIcon boxSize="3" as={CheckCircleIcon}/>
                    <TagLabel fontSize="xx-small">Approved</TagLabel>
                </Tag>
            )}
            {AD_UPLOAD_STATUS.REJECTED === data.value?.toUpperCase() && (
                <Tag
                    backgroundColor="#A7A9BD"
                    size="md"
                    color={"white"}
                    borderRadius={100}
                >
                    <TagLeftIcon
                        boxSize="3"
                        as={SmallCloseIcon}
                        backgroundColor={"white"}
                        color={"#A7A9BD"}
                        borderRadius={100}
                    />
                    <TagLabel fontSize="xx-small">Declined</TagLabel>
                </Tag>
            )}
        </Box>
    )
}

const SearchOptions = {
    NAME: 'Name',
    EMAIL: 'Email',
    TIME: 'Time',
}

function NotificationsHistory(props) {
    let clientId = useSelector((state) => state.client.clientId);
    const [search, setSearch] = useState();
    const searchOption = [SearchOptions.NAME, SearchOptions.EMAIL, SearchOptions.TIME];
    const [selectedOption, setSelectedOption] = useState(SearchOptions.NAME);

    console.log('selectedOption', selectedOption, search)

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        console.log('setSelectedOption', event.target.value)
    };

    if (!clientId) {
        const client = getLocalStorageClient()
        clientId = client?.id
    }

    const [notificationList, setNotificationList] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const {data, refetch} = useNotification(clientId, setIsLoading);

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (Array.isArray(data)) setNotificationList(data)
    }, [data]);

    useEffect(() => {
        const trimmedSearch = search?.trim().toLowerCase();

        if (trimmedSearch) {
            const filterByName = (el) => el?.content?.name?.toLowerCase().includes(trimmedSearch);
            const filterByEmail = (el) => el?.user?.email?.toLowerCase().includes(trimmedSearch);
            const filterByTime = (el) => {
                const created_at = (moment(el.created_at).format("h:mm:ss A") + ", " + moment(el.created_at).format("MMMM DD YYYY")).toLowerCase()
                return created_at.includes(trimmedSearch);
            };

            const filters = {
                [SearchOptions.NAME]: filterByName,
                [SearchOptions.EMAIL]: filterByEmail,
                [SearchOptions.TIME]: filterByTime,
            };

            const filteredCampaign = data.filter(filters[selectedOption]);
            setNotificationList(filteredCampaign);
        } else {
            setNotificationList(data);
        }
    }, [search, selectedOption]);

    const columns = useMemo(
        () => [
            {
                Header: "Type",
                accessor: "ad_upload_id",
                Cell: (data) => {
                    return (
                        <Box maxW="150px" style={{fontWeight:"500",color:"#757998",fontFamily:"poppins"}}>
                            <Text noOfLines={1}>
                                {data.value ? "Creative" : "Campaign"}
                            </Text>
                        </Box>
                    );
                },
            },
            {
                Header: "Creative Name",
                accessor: "content.name",
                Cell: (data) => {
                    return (
                        <Box maxW="150px" style={{fontWeight:"500",color:"#757998",fontFamily:"poppins"}}>
                            <Text noOfLines={1}>
                                {data.value}
                            </Text>
                        </Box>
                    );
                },
            },
            {
                Header: "Status",
                accessor: "content.status",
                Cell: (data) => {
                    return (
                        <Box maxW="150px" style={{fontWeight:"600",fontFamily:"poppins"}}>
                            <Text noOfLines={1}>
                                {data.value === 'created' &&
                                <p><span style={{color:'#ff7800'}}>{_.capitalize(data.value)}</span></p>
                                }
                                {data.value === 'updated' &&
                                <p><span style={{color:'#3B82F6'}}>{_.capitalize(data.value)}</span></p>
                                }
                            </Text>
                        </Box>
                    );
                },
            },
            {
                Header: "Campaign Status",
                accessor: "content.campaign_status",
                Cell: (data) => {
                    return (
                        <StatusTag data={data}/>
                    );
                },
            },
            {
                Header: "Ad Status",
                accessor: "content.ad_status",
                Cell: (data) => {
                    return (
                        <StatusTag data={data}/>
                    );
                },
            },
            {
                Header: "Action by",
                accessor: "content.actionBy",
                Cell: (data) => {
                    return (
                        <Box maxW="150px" style={{fontWeight:"500",color:"#757998",fontFamily:"poppins"}}>
                            <Text noOfLines={1}>
                                {data.value}
                            </Text>
                        </Box>
                    );
                },
            },
            {
                Header: "Email",
                accessor: "user.email",
                Cell: (data) => {
                    return (
                        <Box maxW="150px" style={{fontWeight:"500",color:"#757998",fontFamily:"poppins"}}>
                            <Text noOfLines={1}>
                                {data.value}
                            </Text>
                        </Box>
                    );
                },
            },
            {
                Header: "Time",
                accessor: "created_at",
                Cell: (data) => {
                    return (
                        <>
                            <div style={{fontWeight:"500",color:"#757998",fontFamily:"poppins"}}>
                                {`${moment(data.row.original.created_at).format(
                                    "h:mm:ss A"
                                )}, ${moment(data.row.original.created_at).format(
                                    "MMMM DD YYYY"
                                )}`}
                            </div>
                        </>
                    );
                },
            }
        ], []);

    return (
        <div className="ad-upload-list">
            <Heading color="brand.heading" fontWeight="600" size="lg" my={5} mb={7}>
                History
            </Heading>
            <SearchAndDropdown
                setSearch={(e) => setSearch(e.target.value)}
                handleChange={handleChange}
                selectedOption={selectedOption}
                searchOption={searchOption}
            />
            <Datatable
                data={notificationList || []}
                columns={columns}
                headerColor={"brand.primary"}
                paginationDataCount={10}
            />
        </div>
    );
}

export default NotificationsHistory;
