import React, {useState} from 'react';
import {Box, Button, Container, Flex, Grid, GridItem, Heading, Image, Spacer, Text} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR, TEXT_COLOR} from "../../../../../../../../layout/constant/MenuList";
import {useSelector} from "react-redux";
import ReactPlayer from "react-player";

const MOBILE_SCREEN_COLOR = '#1f1c1c';

function LinkedInImageMobile({formDetails, ads, index, type = "image"}) {
  const [showMore, setShowMore] = useState(false);
  const toggleText = () => {
    setShowMore(!showMore);
  };

  const selectedLogos = formDetails?.channelIcon?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads/` + formDetails.channelIcon.imageUrl :
    process.env.PUBLIC_URL + "/images/display.png";

  console.log('formDetails', formDetails)
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%">
            <Flex
              alignItems="center"
              flexDirection="row"
              justify="center"
            >
              <Container height="100%" centerContent position="relative" width="auto">
                <Box>
                  <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                       height="50px"
                       borderRadius="50" pt={3} m={4} alignSelf="center"
                       position="absolute" top={'20%'} left="-1"/>
                  <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                       height="50px"
                       borderRadius="50" pt={3} m={4} alignSelf="center"
                       position="absolute" top={'30%'} left="-1"/>
                  <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                       height="30px"
                       borderRadius="50" pt={3} m={3} alignSelf="center"
                       position="absolute" top={'10%'} left="0"/>
                  <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                       height="50px"
                       borderRadius="50" pt={3} m={3} alignSelf="center"
                       position="absolute" top={'20%'} right="0"/>
                  <Box height='736px' width="400px"
                       backgroundColor={MOBILE_SCREEN_COLOR}
                       border='1px #e3e8f1 solid'
                       borderRadius="25">
                    <Flex justifyContent="center">
                      <Box backgroundColor='#4f4f4f' textAlign='center' width="5px"
                           height="5px" borderRadius="25" pt={1} m={3} my={7} alignSelf="center"/>
                      <Box backgroundColor='#4f4f4f' textAlign='center' width="75px"
                           height="2px" borderRadius="25" pt={1} m={3} my={7} alignSelf="center"/>
                    </Flex>
                    <Box backgroundColor='white' border='1px #e3e8f1 solid'
                         alignSelf="center" mx='3' height="83%"
                         overflow="auto"
                         style={{scrollbarWidth: "none"}}
                    >
                      <Flex justifyContent="space-between" gap="5" px={3} py={2}>
                        <Box fontSize="small">
                          <strong>War Room</strong> likes this
                        </Box>
                        <Flex fontSize={'small'} fontWeight="700" color="#0088ff" alignItems="center">
                          <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Back Button"
                                 height={4}
                                 width={4}
                          />
                        </Flex>
                      </Flex>
                      <hr/>
                      <Flex justifyContent="space-between" gap="5" px={3} py={2}>
                        <Flex gap={4}>
                          <Image src={selectedLogos} height={12}
                                 width={12} rounded="full"/>
                          <Box>
                            <Text fontSize={'medium'} fontWeight="700">{formDetails.channel_name}</Text>
                            <Text fontSize={'11px'} color={'gray'}>248 followers</Text>
                            <Text fontSize={'11px'} color={'gray'}>Promoted</Text>
                          </Box>
                        </Flex>
                        <Flex fontSize={'medium'} fontWeight="700" color="#0088ff" alignItems="center">
                          + follow
                        </Flex>
                      </Flex>
                      <Box px={3} color={"gray"} fontSize="12px" fontWeight="500">
                        {showMore ? formDetails.introductory_text : formDetails.introductory_text?.substring(0, 100)}
                        {formDetails.introductory_text?.length > 100 && (
                          <span onClick={toggleText} style={{cursor: 'pointer', color: 'gray', paddingLeft: "8px", fontWeight: '700'}}
                                onMouseEnter={(e) => { e.target.style.color = '#0088ff'; }}
                                onMouseLeave={(e) => { e.target.style.color = 'gray'; }}
                          >
                              {showMore ? 'See Less' : '... See More'}
                          </span>
                        )}
                      </Box>
                      <Box bg='white' pt={2}>
                        <Flex justifyContent="center">
                          {type === 'image' && ads && ads.length !== 0 && (
                            <Box>
                              <Image
                                src={ads[index]?.imageUrl}
                                alt={ads[index]?.filename}
                              />
                            </Box>
                          )}
                          {type === 'video' && ads && ads.length !== 0 && (
                            <Box>
                              <ReactPlayer
                                  light={<Image
                                      src={ads.thumbnail[0].imageUrl}
                                      alt={ads[index]?.filename}
                                  />}
                                  url={ads.video[0].videoURL}
                                  playing={true} loop={true}
                                  height="auto"
                                  width="auto"
                              />
                            </Box>
                          )}
                        </Flex>
                      </Box>
                      <Box px={3} pt={2} fontSize={'medium'} fontWeight="600">{formDetails.headline}</Box>
                      <Box px={3} fontSize={'small'} fontWeight="400" color={"gray"}>{formDetails?.link?.split('/')[formDetails?.link?.split('/')?.length - 1]}</Box>
                      <hr style={{margin: "10px 0 0 0"}} />
                      <Box display="flex" justifyContent="center">
                        <Image
                          src={`${process.env.PUBLIC_URL}/images/screenshot_for_linkedin.png`}
                          alt={ads[index]?.filename}
                        />
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" my={3}>
                      <Box backgroundColor='#1f1c1c' alignSelf="center" mx='3' borderRadius="50%"
                           border='2px #e3e8f1 solid' h="35px" w="35px"/>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Flex>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default LinkedInImageMobile;
