import {
    Box,
    Button, CircularProgress,
    CircularProgressLabel, css, FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading, Icon
} from "@chakra-ui/react";
import {Form, FormikProvider, useFormik} from "formik";
import {InputControl, SelectControl, TextareaControl} from "formik-chakra-ui";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import ErrorModal from "../../../../../../components/PopupModal/ErrorModal";
import SuccessModal from "../../../../../../components/PopupModal/SuccessModal";
import WithAppContext from "../../../../../../helpers/appContext";
import instance from "../../../../../../helpers/axios";
import {useUpdateAdUploadStatus} from "../../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import {pinterestAdUploadInitialValues, pinterestAdUploadvalueLengths} from "../../../../constant/InitialValues";
import {CreativeType, hostingSource} from "../../../../constant/SelectValues";
import PinterestAdFileUpload from "./PinterestAdFileUpload";
import {AD_UPLOAD_TYPE, DV360_IMAGE_SIZE, PINTEREST_IMAGE_SIZE} from "../../../../../../constant";
import {
    checkIfFilesAreCorrectType,
    checkIfFilesAreTooBig,
    validateFileSize
} from "../../../../../../helpers/fileValidation";
import {useUploadImage} from "../../../../../../hooks/campaign-briefs/useUploadImage";
import _ from "lodash";

const PinterestAdDetails = ({
                                data,
                                handlePreviewData,
                                url,
                                method,
                                clientId,
                                campaignId,
                                adUploadId,
                                tabIndex,
                                setTabIndex,
                                context
                            }) => {
    const navigate = useNavigate();
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const [approval, setApproval] = useState(false);

    const {mutate} = useUpdateAdUploadStatus();
    const {mutateAsync} = useUploadImage();
    const {setAppLoading} = context;
    const schema = yup.object().shape({
        adName: yup.string().trim().min(3).max(100).required().label('Ad name'),
        title: yup.string().trim().min(3).max(100).required().label('Title'),
        description: yup.string().trim().min(3).max(500).required().label('Description'),
        imageURL: yup.array()
            .nullable()
            .required("You need to provide a file")
            .test("fileSize", "The file is too large", validateFileSize(PINTEREST_IMAGE_SIZE))
            .test(
                "type", "Only the following formats are accepted: .jpeg, .jpg, .gif, .png",
                checkIfFilesAreCorrectType
            ).min(1).max(1).required(),
        link: yup
            .string()
            .trim()
            .required("Landing page url is required.")
            .max(255)
            .matches(
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                "Landing page url is not valid!"
            ).label('Landing page url'),
    });

    const onSubmitImages = async (multiFiles) => {
        let payload = [];
        let otherFile = [];
        let files = [];
        multiFiles.forEach((file) => {
            if (file?.fieldname) {
                otherFile = [...data?.detail?.fileInfoList]
            } else {
                payload.push(file);
            }
        })

        if (payload && payload.length) {
            try {
                await mutateAsync(
                    {
                        clientId: clientId,
                        campaignBriefId: campaignId,
                        adFile: payload,
                        type: "pinterest"
                    },
                    {
                        onSuccess: (data, variables, context) => {
                            payload = data.fileInfoList;
                            setAppLoading(false)
                        },
                        onError: () => {
                            setAppLoading(false)
                        }
                    }
                );
            } catch (e) {
                setAppLoading(false);
            }
            files = [...otherFile, ...payload]
        } else {
            files = [...otherFile]
        }
        return files;
    }

    const formik = useFormik({
        initialValues: pinterestAdUploadInitialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            setAppLoading(true)
            const files = await onSubmitImages(values.imageURL);
            // const mediaIds = files.map((el) => el.imageHash);
            const dimensions = files.map(() => {
                return {
                    width: "",
                    height: "",
                };
            });
            const fileInfoList = files.map((el) => {
                return {
                    ...el,
                    metadata: [{height: "", width: ""}],
                };
            });
            let payload = {
                name: values.adName,
                description: values.description,
                detail: {
                    name: values.adName,
                    title: values.title,
                    description: values.description,
                    link: values.link,
                    // mediaIds: mediaIds,
                    dimensions: dimensions,
                    fileInfoList
                },
            };
            if (!data?.id) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.PINTEREST_IMAGE,
                };
            }
            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSuccessModal(true);
                        setAppLoading(false)
                    }
                })
                .catch((error) => {
                    setDescription(error.response.data.message);
                    setErrorModal(true);
                    setAppLoading(false)
                });
        }
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;

    useEffect(() => {
        if (data && data.id) {
            setFieldValue('adName', data.name)
            setFieldValue('title', data.detail.title)
            setFieldValue('description', data.detail.description)
            setFieldValue('link', data.detail.link)
            const imageFile = data?.detail?.fileInfoList.map((el) => el.files);
            setFieldValue('imageURL', imageFile)
        }
    }, [data]);

    useEffect(() => {
        if (tabIndex === 1) {
            if (values && values.imageURL) {
                const files = values.imageURL.map((file) => {
                    if (file && file.fieldname) {
                        return {
                            imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${file.filename}`,
                            filename: file.originalname
                        }
                    } else {
                        return {
                            imageUrl: URL.createObjectURL(file),
                            filename: file.name
                        }
                    }
                })
                handlePreviewData({
                    images: files,
                    title: values.title,
                    description: values.description,
                    link: values.link,
                });
            }
        }
    }, [tabIndex]);

    const onCloseSuccessModal = () => {
        setSuccessModal(false)
        navigate(`/campaign-briefs/${campaignId}`, {
            state: {isShowUpload: true}
        })
    }

    const handleStatus = (status) => {
        try {
            mutate(
                {
                    clientId,
                    campaignId,
                    adUploadId: data?.id,
                    status,
                },
                {
                    onSuccess: () => {
                        navigate(`/campaign-briefs/${campaignId}`, {
                            state: {isShowUpload: true},
                        });
                    },
                }
            )
        } catch (e) {
            setAppLoading(false);
        }
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" my={4} mb={7}>
                <Heading color={"gray"} fontSize="xl">
                    Current status:
                    <span style={{marginLeft: "10px"}}>
                        <Icon
                            viewBox="0 0 200 200"
                            mr={2}
                            color={data?.status === "Created" ? "#59AB9E" : data?.status === "Approved" ? "#3F7EE6" : data?.status === "Rejected" ? "#FFA383" : "#FFA383"}
                        >
                            <path
                                fill="currentColor"
                                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                            />
                        </Icon>
                        {data?.status || "Draft"}
                    </span>
                </Heading>
                {data && _.toUpper(data.status) === "CREATED" &&
                    <Box display="flex">
                        <Button
                            size="sm"
                            mr={2}
                            colorScheme="green"
                            backgroundColor="green.400"
                            borderRadius={4}
                            disabled={!clientId || !campaignId || !data?.id}
                            onClick={() => handleStatus('Approved')}
                        >
                            Approve
                        </Button>
                        <Button
                            size="sm"
                            colorScheme="red"
                            backgroundColor="red.400"
                            borderRadius={4}
                            disabled={!clientId || !campaignId || !data?.id}
                            onClick={() => handleStatus('Rejected')}
                        >
                            Reject
                        </Button>
                    </Box>
                }
            </Box>
            <Grid className="fb-upload-detail-form">
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <Grid
                            templateColumns="repeat(6, 1fr)"
                            gap={4}
                            className="fb-upload"
                        >
                            <GridItem
                                w="full"
                                colSpan={{base: 6, lg: 4}}
                            >
                                <GridItem>
                                    <FormControl>
                                        <FormLabel
                                            htmlFor="adName"
                                          
                                        >
                                            Ad Name - max 100 characters ( Your first 40 characters are what usually
                                            show up in feeds. )
                                        </FormLabel>
                                        <div className="input-box">
                                            <InputControl
                                                id="adName"
                                                name="adName"
                                                value={values.adName}
                                                placeholder=""
                                                inputprops={{
                                                    variant: "outline",
                                                    type: "text",
                                                }}
                                                onChange={(e) => setFieldValue('adName', e.target.value)}
                                            />
                                            <CircularProgress
                                                max={pinterestAdUploadvalueLengths.adName}
                                                value={values.adName.length}
                                                color={values.adName.length > pinterestAdUploadvalueLengths.adName ? "red.400" : "green.400"}
                                            >
                                                <CircularProgressLabel>
                                                    {values.adName.length > pinterestAdUploadvalueLengths.adName ? pinterestAdUploadvalueLengths.adName - values.adName.length : values.adName.length}
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </div>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel
                                            htmlFor="title"
                                          
                                        >
                                            Title - max 100 characters ( Your first 40 characters are what usually show
                                            up in feeds. )
                                        </FormLabel>
                                        <div className="input-box">
                                            <InputControl
                                                id="title"
                                                name="title"
                                                value={values.title}
                                                placeholder=""
                                                inputprops={{
                                                    variant: "outline",
                                                    type: "text",
                                                }}
                                                onChange={(e) => setFieldValue('title', e.target.value)}
                                            />
                                            <CircularProgress
                                                max={pinterestAdUploadvalueLengths.adName}
                                                value={values.adName.length}
                                                color={values.adName.length > pinterestAdUploadvalueLengths.adName ? "red.400" : "green.400"}
                                            >
                                                <CircularProgressLabel>
                                                    {values.adName.length > pinterestAdUploadvalueLengths.adName ? pinterestAdUploadvalueLengths.adName - values.adName.length : values.adName.length}
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </div>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel
                                            htmlFor="description"
                                          
                                        >
                                            Description - max 500 characters ( People will usually see the first 50
                                            characters when they click on your Pin. )
                                        </FormLabel>
                                        <div className="input-box">
                                            <TextareaControl
                                                id="description"
                                                name="description"
                                                value={
                                                    values.description
                                                }
                                                placeholder=""
                                                inputprops={{
                                                    variant: "outline",
                                                    type: "text",
                                                }}
                                                onChange={(e) => setFieldValue('description', e.target.value)}
                                            />
                                            <CircularProgress
                                                max={pinterestAdUploadvalueLengths.description}
                                                value={values.description.length}
                                                color={
                                                    values.description.length > pinterestAdUploadvalueLengths.description ? "red.400" : "green.400"}
                                            >
                                                <CircularProgressLabel>
                                                    {values.description.length > pinterestAdUploadvalueLengths.description ? pinterestAdUploadvalueLengths.description - values.description.length : values.description.length}
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </div>
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl>
                                        <FormLabel
                                            htmlFor="link"
                                          
                                        >
                                            Landing Page URL - max 255 characters
                                        </FormLabel>
                                        <div className="input-box">
                                            <InputControl
                                                id="link"
                                                name="link"
                                                placeholder=""
                                                inputProps={{
                                                    variant: "outline",
                                                    type: "text",
                                                }}
                                            />
                                            <CircularProgress
                                                max={pinterestAdUploadvalueLengths.link}
                                                value={values.link.length}
                                                color={
                                                    values.link.length > pinterestAdUploadvalueLengths.link
                                                        ? "red.400"
                                                        : "green.400"
                                                }
                                            >
                                                <CircularProgressLabel>
                                                    {values.link.length >
                                                    pinterestAdUploadvalueLengths.link
                                                        ? pinterestAdUploadvalueLengths.link -
                                                        values.link.length
                                                        : values.link.length}
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </div>
                                    </FormControl>
                                </GridItem>
                                <GridItem
                                    css={css({
                                        float: "right",
                                        marginRight: "68px",
                                    })}
                                >
                                    <Button
                                        size="small"
                                        css={css({
                                            background:
                                                "#24a0ed !important",
                                            borderRadius: "32px",
                                            width: "134px",
                                            height: "33px",
                                            marginRight: "10px",
                                        })}
                                        disabled={!values.imageURL?.length}
                                        onClick={() => setTabIndex(1)}
                                    >
                                        Preview
                                    </Button>
                                    <Button
                                        size="small"
                                        css={css({
                                            background:
                                                "#4CAF50 !important",
                                            borderRadius: "32px",
                                            width: "134px",
                                            height: "33px",
                                        })}
                                        // disabled={!isValid}
                                        type="submit"
                                        onClick={() => {
                                            localStorage.setItem('chanelName', 'Pinterest Image')
                                        }}
                                    >
                                        {data?.id
                                            ? "Update Ad"
                                            : "Submit Ad"}
                                    </Button>
                                </GridItem>
                            </GridItem>
                            <GridItem
                                w="full"
                                colSpan={{base: 6, lg: 2}}
                            >
                                <Box className="file-upload-box">
                                    <PinterestAdFileUpload
                                        getHashArray={(value) => {
                                            setFieldValue('imageURL', value)
                                        }}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        touched={touched}
                                        type="pinterestImage"
                                        values={values}
                                    />
                                </Box>
                            </GridItem>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Grid>

            <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => onCloseSuccessModal()}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModal(false)}
                description={description}
            />
        </>
    );
};

export default WithAppContext(PinterestAdDetails);
