import { createSlice } from "@reduxjs/toolkit";
import {adUploadSlice} from "../../../../../../../../store/adUploads";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const dynamicInstagramImageSlice = createSlice({
    name: AD_UPLOAD_TYPE.DYNAMIC_INSTAGRAM_IMAGE,
    initialState,
    reducers: {
        settingDynamicInstagramFormDetails: (state, action) => {
            state.formDetails = action.payload
        },
        settingDynamicInstagramMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingDynamicInstagramPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllDynamicInstagramImageFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { deleteAllDynamicInstagramImageFields,settingDynamicInstagramFormDetails,settingDynamicInstagramMedia,settingDynamicInstagramPreview} = dynamicInstagramImageSlice.actions;

// The reducer
export const dynamicInstagramImageReducer = dynamicInstagramImageSlice.reducer;
