import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  dv360AdUploadalueLengths,
  dv360AdUploadInitialValues,
  metaVideoAdValueLengths,
} from "../../../../../constant/InitialValues";
import {
  CreativeType,
  facebookAccountIds,
  hostingSource,
} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import {
  settingMetaVideoFormDetail,
  settingMetaVideoFormMedia,
} from "./store";
import TooltipComponent from "../../TooltipComponent";

function MetaVideoAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.metaVideoAd);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  const initialValue = {
    adName: "",
    primaryText: "",
    description: "",
    headline: "",
    landingPageURL: "",
    facebookAccountId: "",
  };

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    primaryText: yup
      .string()
      .trim()
      .min(3)
      .max(125)
      .required()
      .label("Primary text"),
    headline: yup.string().trim().min(3).max(32).required().label("Headline"),
    description: yup
      .string()
      .trim()
      .min(3)
      .max(125)
      .required()
      .label("Description"),
    facebookAccountId: yup.string().required().label("Type"),
    landingPageURL: yup
      .string()
      .trim()
      .required("Landing page url is required")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      )
      .label("Landing page url"),
  });

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingMetaVideoFormDetail({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.name,
          description: data?.adUpload?.detail?.link_description,
          primaryText: data?.adUpload?.description,
          headline: data?.adUpload?.detail?.headline,
          landingPageURL: data?.adUpload?.detail?.call_to_action?.value?.link,
          facebookAccountId: data?.adUpload?.detail?.call_to_action?.type,
        })
      );
      dispatch(
        settingMetaVideoFormMedia({
          videoURL: data?.adUpload?.detail?.videoFile,
          thumbnailURL: data?.adUpload?.detail?.thumbnailFile,
          thumbnailFile: data?.adUpload?.detail?.image_url,
          videoFile: data?.adUpload?.detail?.video_id,
        })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        settingMetaVideoFormDetail({ ...values, ...data?.adUpload?.detail })
      );
      nextStep();
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isValid,
    handleBlur,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("primaryText", formDetails.primaryText || autoFillData?.primaryText || "");
    setFieldValue("headline", formDetails.headline || autoFillData?.headline || "");
    setFieldValue("description", formDetails.description || autoFillData?.description || "");
    setFieldValue("landingPageURL", formDetails.landingPageURL || autoFillData?.landingPageURL || "");
    setFieldValue("type", formDetails.type || autoFillData?.type || "");
    setFieldValue("facebookAccountId", formDetails.facebookAccountId || autoFillData?.facebookAccountId || "");
  }, [formDetails, autoFillData]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 512 characters",
                      }}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={metaVideoAdValueLengths?.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        metaVideoAdValueLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        metaVideoAdValueLengths?.adName
                          ? metaVideoAdValueLengths?.adName -
                            values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>

              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="primaryText" mb={0}>Primary Text</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="primaryText"
                      name="primaryText"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Primary Text - max 125 characters",
                      }}
                    />
                    <CircularProgress
                      max={metaVideoAdValueLengths?.primaryText}
                      value={values?.primaryText?.length}
                      color={
                        values?.primaryText?.length >
                        metaVideoAdValueLengths?.primaryText
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.primaryText?.length >
                        metaVideoAdValueLengths?.primaryText
                          ? metaVideoAdValueLengths?.primaryText -
                            values?.primaryText?.length
                          : values?.primaryText?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="headline" mb={0}>Headline</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="headline"
                      name="headline"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: " Headline - max 32 characters",
                      }}
                    />
                    <CircularProgress
                      max={metaVideoAdValueLengths?.headline}
                      value={values?.headline?.length}
                      color={
                        values?.headline?.length >
                        metaVideoAdValueLengths?.headline
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.headline?.length >
                        metaVideoAdValueLengths?.headline
                          ? metaVideoAdValueLengths?.headline -
                            values?.headline?.length
                          : values?.headline?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" mb={0}>Description</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="description"
                      name="description"
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Description - max 18 characters",
                      }}
                    />
                    <CircularProgress
                      max={metaVideoAdValueLengths?.description}
                      value={values?.description?.length}
                      color={
                        values?.description?.length >
                        metaVideoAdValueLengths?.description
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.description?.length >
                        metaVideoAdValueLengths?.description
                          ? metaVideoAdValueLengths?.description -
                            values?.description?.length
                          : values?.description?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="landingPageURL" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="landingPageURL"
                      name="landingPageURL"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: " Landing Page URL - max 255 characters",
                      }}
                    />
                    <CircularProgress
                      max={metaVideoAdValueLengths?.landingPageURL}
                      value={values?.landingPageURL?.length}
                      color={
                        values?.landingPageURL?.length >
                        metaVideoAdValueLengths?.landingPageURL
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.landingPageURL?.length >
                        metaVideoAdValueLengths?.landingPageURL
                          ? metaVideoAdValueLengths?.landingPageURL -
                            values?.landingPageURL?.length
                          : values?.landingPageURL?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="facebookAccountId"  mb={0}>Call to Action</FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="facebookAccountId"
                      name="facebookAccountId"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("facebookAccountId", e.target.value);
                      }}
                    >
                      {facebookAccountIds.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default MetaVideoAdDetails;
