import DV360AdFileUpload from "../../../DV360/DV360AdFileUpload";
import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {SocialDisplayVideoAdFileUpload} from "./SocialDisplayVideoAdFileUpload";
import {settingSocialDisplayVideoMedia, settingSocialDisplayVideoPreview} from "./store";
import {
    checkIfFilesAreCorrectVideoType,
    validateFileSize
} from "../../../../../../../helpers/fileValidation";
import ImageCropper from "../components/ImageCropper";


function SocialDisplayVideoAdMedia({activeStep, steps, prevStep, nextStep, label}) {
    const { mediaDetails } = useSelector((state) => state.socialDisplayVideoAd)
    const [dialogOpen, setDialogOpen] = useState(true);
    const [croppedImage, setCroppedImage] = useState(null);
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0
    });

    const schema = yup.object().shape({
        fileURL: yup.array()
          .required("You need to provide a file")
          .test("fileSize", "The file is too large", validateFileSize)
          .test(
            "type", "Only the video files are accepted",
            checkIfFilesAreCorrectVideoType
          ).min(1).max(12).required().label('Video'),
    });

    const dispatch = useDispatch();


    const formik = useFormik({
        initialValues: {
            fileURL: null,
            thumbnail_url: {}
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingSocialDisplayVideoMedia(values))
            if (values.fileURL) {
                let videoURL = values?.fileURL[0]?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads${values.fileURL[0]?.imageUrl}` : null;
                if (!videoURL) {
                    videoURL = URL.createObjectURL(values.fileURL[0])
                } else {
                    // setErrors({})
                }
                dispatch(settingSocialDisplayVideoPreview(videoURL));
            }
            nextStep()
        })
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;

    console.log('mediaDetails in AdMedia', mediaDetails)

    useEffect(() => {
        setFieldValue("fileURL", mediaDetails?.fileURL ? mediaDetails?.fileURL : null)
    }, [mediaDetails])

    function handleCloseDialog() {
        setDialogOpen(false)
        setCroppedImage(null)
    }

    function cropVideoBlob(videoBlob, width, height) {
        let video = document.createElement('video');
        video.src = URL.createObjectURL(videoBlob);

        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        let ctx = canvas.getContext('2d');

        video.play();

        let renderFrame = function() {
            ctx.drawImage(video, 0, 0, width, height);

            requestAnimationFrame(renderFrame);
        };
        renderFrame();
        return canvas;
    }


    return (
        <Box className="file-upload-box">
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <SocialDisplayVideoAdFileUpload
                        getHashArray={(value) => {
                            setFieldValue('fileURL', value)
                        }}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        type="social-display-video"
                        values={values}
                        // videoConfig={values?.thumbnail_url}
                    />

                    {/*{Array.isArray(values?.fileURL) && values?.fileURL[0] && <ImageCropper*/}
                    {/*  open={dialogOpen}*/}
                    {/*  setOpen={setDialogOpen}*/}
                    {/*  video={URL.createObjectURL(values?.fileURL[0])}*/}
                    {/*  onComplete={(videoConfig) => {*/}
                    {/*      setFieldValue('thumbnail_url', videoConfig);*/}
                    {/*      let croppedCanvas = cropVideoBlob(values?.fileURL[0], videoConfig.width, videoConfig.height);*/}
                    {/*      const urlIsHere = croppedCanvas.toDataURL('video/mp4');*/}
                    {/*      console.log('urlIsHere', urlIsHere)*/}
                    {/*      handleCloseDialog()*/}
                    {/*      // imagePromise.then((video) => {*/}
                    {/*      //     // const fileURL = URL.createObjectURL(video);*/}
                    {/*      //     // setFieldValue('thumbnail_url', fileURL);*/}
                    {/*      //     handleCloseDialog()*/}
                    {/*      // }).catch((e) => {*/}
                    {/*      //     console.log('Error', e)*/}
                    {/*      // });*/}
                    {/*  }}*/}
                    {/*  dimensions={{*/}
                    {/*      width: dimensions.width,*/}
                    {/*      height: dimensions.height*/}
                    {/*  }}*/}
                    {/*  croppedImage={croppedImage}*/}
                    {/*  setCroppedImage={setCroppedImage}*/}
                    {/*  handleCloseDialog={handleCloseDialog}*/}
                    {/*  containerStyle={{*/}
                    {/*      position: "relative",*/}
                    {/*      display: "flex",*/}
                    {/*      justifyContent: "center",*/}
                    {/*      width: 650,*/}
                    {/*      height: 650,*/}
                    {/*      background: "#333"*/}
                    {/*  }}*/}
                    {/*/>}*/}

                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                            Next
                        </Button>
                    </Flex>
                </Form>

            </FormikProvider>

        </Box>
    )
}

export default SocialDisplayVideoAdMedia
