import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  facebookVideoAdValueLengths,
  fbCarouselAdUploadValueLengths,
} from "../../../../../constant/InitialValues";
import {
  CreativeType,
  facebookAccountIds,
  hostingSource,
} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import { TEXT_COLOR } from "../../../../../../../layout/constant/MenuList";
import {
  settingFacebookCarouselForm,
  settingFacebookCarouselMedia,
} from "./store";
import TooltipComponent from "../../TooltipComponent";

export const facebookCarouselAdUploadInitialValues = {
  adName: "",
  primaryText: "",
  headline: "",
  // description: "",
  landingPageURL: "",
  facebookAccountId: "",
};

const validationSchema = yup.object().shape({
  adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
  primaryText: yup
    .string()
    .trim()
    .min(3)
    .max(125)
    .required()
    .label("Primary text"),
  headline: yup.string().trim().min(3).max(27).required().label("Headline"),
  landingPageURL: yup
    .string()
    .trim()
    .required("Landing page url is required.")
    .label("Landing page url")
    .matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      "Landing page url is not valid!"
    ),
});

function FacebookCarouselAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData,
  adCopyData,
  popupDetail
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.facebookCarouselAd);
  const [formData, setFromData] = useState(
    facebookCarouselAdUploadInitialValues
  );
  console.log(formDetails);
  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  useEffect(() => {
    if (adUploadId) {
      console.log("data?.adUpload",data?.adUpload);
      dispatch(
        settingFacebookCarouselForm({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.name,
          // primaryText: data?.adUpload?.detail?.description,
          // headline: data?.adUpload?.detail?.name,
          primaryText: data?.adUpload?.detail?.message,
          headline: data?.adUpload?.detail?.headline,
          landingPageURL: data?.adUpload?.detail?.link,
          facebookAccountId:
            data?.adUpload?.detail?.child_attachments[0].call_to_action?.type,
        })
      );
      dispatch(
        settingFacebookCarouselMedia({
          carouselCards: data?.adUpload?.detail?.child_attachments,
        })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(settingFacebookCarouselForm(values));
      nextStep();
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isValid,
    handleBlur,
    setFieldValue,
  } = formik;



  useEffect(() => {
    setFieldValue("adName", formDetails?.adName ? formDetails.adName : "");
    setFieldValue("primaryText", adCopyData?.primary_text || formDetails.primaryText || autoFillData?.primaryText || "");
    setFieldValue("headline", adCopyData?.headline || formDetails.headline || autoFillData?.headline || "");
    setFieldValue(
      "landingPageURL",
      formDetails?.landingPageURL ? formDetails.landingPageURL : autoFillData?.landingPageURL || ""
    );
    setFieldValue(
      "facebookAccountId",
      popupDetail?.cta || formDetails?.facebookAccountId ? formDetails.facebookAccountId : autoFillData?.facebookAccountId || ""
    );
  }, [formDetails, autoFillData , adCopyData , popupDetail]);

  return (
    <>
      {/*{adUploadId && (*/}
      {/*  <Box*/}
      {/*    display="flex"*/}
      {/*    mb={6}*/}
      {/*    justifyContent="space-between"*/}
      {/*    alignItems="center"*/}
      {/*  >*/}
      {/*    <Heading fontSize="xl" color={"#757998"}>*/}
      {/*      Please review the ad details carefully.*/}
      {/*    </Heading>*/}
      {/*    <Heading fontSize="md" color="gray.400">*/}
      {/*      3 steps to complete*/}
      {/*    </Heading>*/}
      {/*  </Box>*/}
      {/*)}*/}

      {/*{!adUploadId && (*/}
      {/*  <Box*/}
      {/*    display="flex"*/}
      {/*    mb={6}*/}
      {/*    justifyContent="space-between"*/}
      {/*    alignItems="center"*/}
      {/*  >*/}
      {/*    <Heading fontSize="xl" color={"#757998"}>*/}
      {/*      Please fill in your ad details.*/}
      {/*    </Heading>*/}
      {/*    <Heading fontSize="md" color="gray.400">*/}
      {/*      3 steps to complete*/}
      {/*    </Heading>*/}
      {/*  </Box>*/}
      {/*)}*/}

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    display={"none"}
                    htmlFor="adName"
                    color="gray"
                    fontSize="sm"
                  >
                    Ad Name - max 512 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      value={values.adName}
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 512 characters",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={fbCarouselAdUploadValueLengths?.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        fbCarouselAdUploadValueLengths?.adName
                          ? "red.400"
                          : "blue.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        fbCarouselAdUploadValueLengths?.adName
                          ? fbCarouselAdUploadValueLengths?.adName -
                            values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>

              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="primaryText" display={"none"}>
                    Primary Text - max 125 characters
                  </FormLabel>
                  <div className="input-box">
                    <TextareaControl
                      id="primaryText"
                      name="primaryText"
                      value={values.primaryText}
                      placeholder=""
                      textareaProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Primary Text - max 125 characters",
                      }}
                      onChange={(e) =>
                        setFieldValue("primaryText", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={fbCarouselAdUploadValueLengths?.primaryText}
                      value={values?.primaryText?.length}
                      color={
                        values?.primaryText?.length >
                        fbCarouselAdUploadValueLengths?.primaryText
                          ? "red.400"
                          : "blue.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.primaryText?.length >
                        fbCarouselAdUploadValueLengths?.primaryText
                          ? fbCarouselAdUploadValueLengths?.primaryText -
                            values?.primaryText?.length
                          : values?.primaryText?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="headline" display={"none"}>
                    Headline - max 27 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="headline"
                      name="headline"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: " Headline - max 27 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.headline}
                      value={values?.headline?.length}
                      color={
                        values?.headline?.length >
                        facebookVideoAdValueLengths?.headline
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.headline?.length >
                        facebookVideoAdValueLengths?.headline
                          ? facebookVideoAdValueLengths?.headline -
                            values?.headline?.length
                          : values?.headline?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" display={"none"}>
                    Landing Page URL
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="landingPageURL"
                      name="landingPageURL"
                      value={values.landingPageURL}
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL",
                      }}
                      onChange={(e) =>
                        setFieldValue("landingPageURL", e.target.value)
                      }
                    />
                    <Box h="48px" w="48px" />
                  </div>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="facebookAccountId">
                    Call to Action
                  </FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="facebookAccountId"
                      name="facebookAccountId"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("facebookAccountId", e.target.value);
                      }}
                    >
                      {facebookAccountIds.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            {/*{!adUploadId && (*/}
            {/*  <Button*/}
            {/*    mr={4}*/}
            {/*    onClick={prevStep}*/}
            {/*    colorScheme="blue"*/}
            {/*    variant="outline"*/}
            {/*    borderRadius={0}*/}
            {/*    px={10}*/}
            {/*  >*/}
            {/*    Back*/}
            {/*  </Button>*/}
            {/*)}*/}

              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default FacebookCarouselAdDetails;
