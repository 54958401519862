import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {Box, Button, Container, Flex, Grid, GridItem, Heading, Image, Spacer, Text} from "@chakra-ui/react";
import {INDICATOR_BG_COLOR, PREVIEW_BG_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";

const MOBILE_SCREEN_COLOR = '#1f1c1c'

function Mobile({formDetails, appLoading, ads, index, handleIndex, businessName, setIndex}) {
  const getSlider = () => {
    if (ads?.length) {
      const slides = ads.map((el, ind) => (
        <Text
          height={1}
          borderRadius={5}
          px={4}
          mx={1}
          mt={8}
          key={ind}
          background={ind === index ? INDICATOR_BG_COLOR : "silver"}
          onClick={() => setIndex(ind)}
          cursor="pointer"
        />
      ));
      return slides;
    }
  };

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader />}
      <GridItem w="full" colSpan={{ base: 6, lg: 6 }}>
        <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%">
            {/*<Flex alignItems="center" flexDirection="row" justify="center">*/}
            {/*  {ads && ads.length !== 0 && (*/}
            {/*    <Box border="2px" borderColor="#757998">*/}
            {/*      <Image*/}
            {/*        src={ads[index]?.imageUrl}*/}
            {/*        alt={ads[index]?.filename}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*  )}*/}
            {/*</Flex>*/}
            <Flex
              alignItems="center"
              flexDirection="row"
              justify="center"
            >
              <Container height="100%" centerContent position="relative" width="auto">
                <Box>
                  <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                       height="50px"
                       borderRadius="50" pt={3} m={4} alignSelf="center"
                       position="absolute" top={'20%'} left="-1"/>
                  <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                       height="50px"
                       borderRadius="50" pt={3} m={4} alignSelf="center"
                       position="absolute" top={'30%'} left="-1"/>
                  <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                       height="30px"
                       borderRadius="50" pt={3} m={3} alignSelf="center"
                       position="absolute" top={'10%'} left="0"/>
                  <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                       height="50px"
                       borderRadius="50" pt={3} m={3} alignSelf="center"
                       position="absolute" top={'20%'} right="0"/>
                  <Box height='736px' width="414px"
                       backgroundColor={MOBILE_SCREEN_COLOR}
                       border='1px #e3e8f1 solid'
                       borderRadius="25">
                    <Flex justifyContent="center">
                      <Box backgroundColor='#4f4f4f' textAlign='center' width="5px"
                           height="5px" borderRadius="25" pt={1} m={3} my={7} alignSelf="center"/>
                      <Box backgroundColor='#4f4f4f' textAlign='center' width="75px"
                           height="2px" borderRadius="25" pt={1} m={3} my={7} alignSelf="center"/>
                    </Flex>
                    <Box backgroundColor='white' border='1px #e3e8f1 solid'
                         alignSelf="center" mx='3' height="83%" overflow="auto">
                      <Box bg='white'>
                        <Flex justifyContent="center">
                          {ads && ads.length !== 0 && (
                              <Box border="2px" borderColor="#757998">
                                <Image
                                  src={ads[index]?.imageUrl}
                                  alt={ads[index]?.filename}
                                />
                              </Box>
                            )}
                        </Flex>
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" my={3}>
                      <Box backgroundColor='#1f1c1c' alignSelf="center" mx='3' borderRadius="50%" border='2px #e3e8f1 solid' h="35px" w="35px"/>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Flex>
          </Container>
          <Flex
            templateColumns="repeat(12, 1fr)"
            justifyContent="space-between"
          >
            <Button
              leftIcon={<ArrowBackIcon color="brand.primary" w={10} h={10} />}
              py={8}
              variant="ghost"
              position={"absolute"}
              top={"50%"}
              style={{ transform: "translateY(-50%)" }}
              onClick={() => setIndex(index > 1 ? index - 1 : 0)}
            />
            <Spacer />
            <Box
              display={"flex"}
              position={"absolute"}
              bottom={"-14px"}
              left={"50%"}
              style={{ transform: "translateX(-50%)" }}
            >
              {getSlider()}
            </Box>

            <Spacer />
            <Button
              leftIcon={
                <ArrowForwardIcon color="brand.primary" w={10} h={10} />
              }
              py={8}
              variant="ghost"
              position={"absolute"}
              top={"50%"}
              right={"0%"}
              style={{ transform: "translateY(-50%)" }}
              onClick={() =>
                setIndex(
                  ads && Object.keys(ads)?.length > index + 1
                    ? index + 1
                    : index
                )
              }
            />
          </Flex>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default Mobile;
