import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {
    checkIfFilesAreCorrectType, checkIfFilesAreCorrectTypeForLinkedin,
    checkIfFilesAreTooBigForLinkedin
} from "../../../../../../../helpers/fileValidation";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import LinkedInImageAdFileUpload from "./LinkedInImageAdFileUpload";
import {settingLinkedInMedia, settingLinkedInPreview} from "./store";


function LinkedInImageAdMedia({activeStep, steps, prevStep, nextStep, label}) {

    const { adUploadId } = useParams()
    const { mediaDetails } = useSelector((state) => state.linkedInImageAd)

    const schema = yup.object().shape({
        imageURL: yup.array()
            .nullable()
            .required("You need to provide a file")
            .test("fileSize", "The file is too large", checkIfFilesAreTooBigForLinkedin)
            .test(
                "type", "Only the following formats are accepted: .jpg, .png, or .gif",
                checkIfFilesAreCorrectTypeForLinkedin
            ).min(1).max(12).required().label("Image"),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            imageURL: []
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingLinkedInMedia(values))
            if (values && values.imageURL) {
                const files = values.imageURL.map((file) => {
                    if (file && file.filename) {
                        return {
                            imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${file.imageUrl}`,
                            filename: file.filename
                        }
                    } else {
                        return {
                            imageUrl: URL.createObjectURL(file),
                            filename: file.name
                        }
                    }
                })
                dispatch(settingLinkedInPreview(files));
            }
            nextStep()
        })
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;

    useEffect(() => {
        setFieldValue("imageURL", mediaDetails?.imageURL ? mediaDetails?.imageURL : [])
    }, [mediaDetails])

    return (
        <Box className="file-upload-box">
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <LinkedInImageAdFileUpload
                        getHashArray={(value) => {
                            setFieldValue('imageURL', value)
                        }}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        type="linkedin"
                        values={values}
                    />
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                            Next
                        </Button>
                    </Flex>
                </Form>

            </FormikProvider>

        </Box>
    )
}

export default LinkedInImageAdMedia
