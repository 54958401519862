import React, {useState} from "react";
import {cropImage} from "./CropUtils";
import Cropper from "react-easy-crop";
import {
  AlertDialog,
  AlertDialogContent,
  Text,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  AlertDialogBody
} from "@chakra-ui/react";
import {Box, Flex} from "@chakra-ui/layout";

function calculateCroppedDimensions(originalWidth, originalHeight) {
  switch (`${originalWidth}x${originalHeight}`) {
    case '970x250':
      return {width: 357.9961, height: 248};
    case '729x90':
      return {width: 268.18254, height: 88};
    case '300x600':
      return {width: 298, height: 373};
    case '320x480':
      return {width: 318, height: 253};
    case '250x250':
      return {width: 248, height: 132};
    case '336x280':
      return {width: 334, height: 162};
    default:
      return {width: 248, height: 162};
  }
}

function ImageCropper({
                        open,
                        setOpen,
                        image,
                        video,
                        onComplete,
                        containerStyle,
                        croppedImage,
                        setCroppedImage,
                        setImageMetadata,
                        handleCloseDialog,
                        dimensions,
                        ...props
                      }) {
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  return (
    <AlertDialog onOpenChange={setOpen} isOpen={open}>
      <AlertDialogOverlay>
        <AlertDialogContent maxWidth="fit-content">
          <AlertDialogHeader>
            <Text>Crop Image</Text>
          </AlertDialogHeader>

          <AlertDialogBody maxWidth="fit-content" maxHeight="fit-content">
            <Box style={containerStyle}>
              <Cropper
                image={image}
                video={video}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                cropShape="rect"
                cropSize={calculateCroppedDimensions(dimensions.width, dimensions.height)}
                onCropComplete={(_, croppedAreaPixels) => {
                  setImageMetadata(croppedAreaPixels);
                  setCroppedAreaPixels(croppedAreaPixels);
                }}
                // onZoomChange={setZoom}
                {...props}
              />
            </Box>
          </AlertDialogBody>
          <AlertDialogFooter gap={2}>
            <Button
              variant='outline'
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                image ? onComplete(cropImage(image, croppedAreaPixels, setCroppedImage)) : onComplete(croppedAreaPixels)
              }}
            >
              Crop
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ImageCropper;
