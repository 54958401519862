import MasterEditForm from "./MasterComponent/MasterEditForm";
import {WrapContext} from "./store/WrapContext";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {useSelector} from "react-redux";
import {useGetSingleMasterData} from "../../../../../../../hooks/campaign-briefs/useGetSingleMasterData";
import instance from "../../../../../../../helpers/axios";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";

function MasterEdit({context}) {

    const [formValue, setFormValue] = useState(null)
    const {state: masterId} = useLocation()

    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);

    const clientId = useSelector((state) => state.client.clientId);
    const {id: campaignId, microsoftId} = useParams();
    const {data, refetch} = useGetSingleMasterData(clientId, campaignId, microsoftId, masterId, setFormValue);


    const {setAppLoading, SuccessToaster, ErrorToaster} = context

    const navigate = useNavigate()

    const onCloseSuccessModal = () => {
        setSuccessModal(false)
        // navigate(`/campaign-brief/${campaignId}/ad-upload/google/${googleId}`, {
        //     state: {isUpdate: true},
        // });
    }

    // const onCloseSuccessModal = () => {
    //     setSuccessModal(false)
    //     navigate(`/campaign-briefs/${campaignId}`, {
    //         state: {isShowUpload: true},
    //     });
    // }


    useEffect(() => {
        refetch()
    }, []);

    async function handleUpdate(values) {
        delete values.id
        delete values.other_info
        delete values.state
        delete values.state_reason
        delete values.updated_at
        delete values.ad_upload_id
        delete values.created_at

        await instance({
            method: "PUT",
            url: `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${microsoftId}/master/${masterId}`,
            withCredentials: false,
            data: values,
        }).then((res) => {
            if (res.status === 200) setSuccessModal(true);
            setAppLoading(false);
        })
            .catch((error) => {
                setErrorModal(true);
                setAppLoading(false);
            });
    }

    if (!formValue) {
        return <FullPageLoader/>
    }

    return (
        <>
            <MasterEditForm masterAd={data} formValue={formValue} handleUpdate={handleUpdate}/>
            <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => onCloseSuccessModal()}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModal(false)}
            />
        </>
    )
}

export default WithAppContext(MasterEdit)
