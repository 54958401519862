import { createSlice } from "@reduxjs/toolkit";
import {adUploadSlice} from "../../../../../../../../store/adUploads";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const demandGenCarouselSlice = createSlice({
    name: AD_UPLOAD_TYPE.DEMAND_GEN_CAROUSEL,
    initialState,
    reducers: {
        settingDemandGenFormDetails: (state, action) => {
            state.formDetails = action.payload
        },
        settingDemandGenMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingDemandGenPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllDemandGenCarouselFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { deleteAllDemandGenCarouselFields,settingDemandGenFormDetails,settingDemandGenMedia,settingDemandGenPreview} = demandGenCarouselSlice.actions;

// The reducer
export const demandGenCarouselReducer = demandGenCarouselSlice.reducer;
