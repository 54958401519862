import {
  Heading,
  Button,
  Divider,
  Text,
  Input,
  InputLeftElement,
  InputGroup,
  Stack,
  Icon,
  TagLeftIcon,
  Tag,
  HStack,
  useToast,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton, Image,
} from "@chakra-ui/react";

import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useGetCampaignList } from "../../hooks/campaign-briefs/useGetCampaignList";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoMdAddCircle } from "react-icons/io";
import {
  CheckCircleIcon,
  CheckIcon, ChevronDownIcon, ChevronRightIcon, CloseIcon,
  SearchIcon,
  SmallCloseIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { useCreateClone } from "../../hooks/campaign-briefs/useCreateClone";
import { TagLabel } from "@chakra-ui/tag";
import { AD_UPLOAD_STATUS } from "../../constant";
import DeleteModel from "../../components/PopupModal/DeleteModel";
import { useDeleteCampaign } from "../../hooks/campaign-briefs/useDeleteCampaign";
import { useRecoilState, useRecoilValue } from "recoil";
import { profile } from "../../atoms/authAtom";
import { BsThreeDotsVertical } from "react-icons/bs";
import {useAdUploadList} from "../../hooks/campaign-briefs/useAdUploadList";
import SearchAndButton from "../../components/SearchAndButton";
import CampaignDataTable from "../../components/Datatable/CampaignDataTable";
import {Flex} from "@chakra-ui/layout";
import ApproveModel from "../../components/PopupModal/ApproveModel";
import TickIcon from "../../assets/images/tick-circle.svg";
import CrossIcon from "../../assets/images/cross-circle.svg";
import {useUpdateCampaign} from "../../hooks/campaign-briefs/useUpdateCampaign";

const CampaignBriefs = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const {access_info} = useRecoilValue(profile);

  const roles = access_info?.roles?.length !== 0 ? access_info?.roles : [];

  const [isClient, setIsClient] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const clientId = useSelector((state) => state.client.clientId);

  const { data, refetch } = useGetCampaignList(clientId);
  const { mutate, isLoading } = useCreateClone();
  const [ isMultiple, setIsMultiple ] = useState(false);
  const [ allCampaignIds, setAllCampaignIds ] = useState("");

  useEffect(() => {
    if (roles && roles?.length === 1) {
      setIsClient(
        roles.includes("CampaignManager") || roles.includes("Client")
      );
    } else {
      setIsClient(false);
    }
  }, [roles]);

  useEffect(() => {
    refetch();
    setTimeout(() => {
      refetch();
    }, 2000);
  }, []);

  useEffect(() => {
    setCampaignData(data?.campaigns);
  }, [data]);

  useEffect(() => {
    if (search?.trim()) {
      const searchedCampaign = campaignData.filter((el) => {
        if (el?.name?.toLowerCase().includes(search.trim().toLowerCase())) {
          return true;
        } else if (el?.end_date?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.start_date?.toLowerCase().includes(search.trim())) {
          return true;
        }
      });
      setCampaignData(searchedCampaign);
    } else {
      setCampaignData(data?.campaigns);
    }
  }, [search]);

  const checkStatusByTag = (value) => {
    return AD_UPLOAD_STATUS[value.toUpperCase()];
  };

  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isApproveModalOpen, setApproveModal] = useState(false);
  const [statusToUpdate, setStatusToUpdate] = useState(null);
  const [briefName, setBriefName] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [campId, setCampId] = useState(null)
  const { data: deleteData, refetch: refetchDelete } = useDeleteCampaign(
    clientId,
    campaignId
  );
  const { mutate: updateCampaignStatus } = useUpdateCampaign();
  const {data: adsData, refetch: adsRefetch} = useAdUploadList(clientId, campId)

  useEffect(()=> {
    adsRefetch()
  },[])

  useEffect(()=> {
    if(isMultiple){
      navigate(`/campaign-briefs/${campId}/ad-upload/new?allIds=${allCampaignIds}`)
    } else if(adsData?.adUploads){
        adsData?.adUploads.length !== 0 ? navigate(`/campaign-briefs/${campId}/ad-upload`) : navigate(`/campaign-briefs/${campId}/ad-upload/new`)
    }
  },[adsData])

  let toast = useToast();

  const onCloseDeleteModal = () => {
    refetchDelete()
      .then((resp) => {
        if (resp?.data?.status === 200) {
          refetch();
          toast({
            isClosable: true,
            status: "success",
            variant: "top-accent",
            position: "top-right",
            description: "Deleted Successfully!",
          });
          setDeleteModal(false);
        } else {
          setDeleteModal(false);
          toast({
            isClosable: true,
            status: "error",
            variant: "top-accent",
            position: "top-right",
            description: resp?.data?.data?.message || "Something goes wrong!",
          });
        }
      })
      .catch((err) => {
        setDeleteModal(false);
      });
  };

  const onCloseApproveModal = () => {
    updateCampaignStatus({
      clientId,
      campaignBriefId: campaignId,
      status: statusToUpdate,
    }, {
      onSuccess: () => {
        refetch();
        setApproveModal(false);
      },
      onError: () => {
        refetch();
        setApproveModal(false);
      },
    })
  };

  function handleDeleteModel(briefName, campId) {
    setDeleteModal(true);
    setBriefName(briefName);
    setCampaignId(campId);
  }

  function handleApproveModel(briefName, campId, status) {
    setApproveModal(true);
    setBriefName(briefName);
    setCampaignId(campId);
    setStatusToUpdate(status);
  }

  const onDestroyModel = () => {
    setDeleteModal(false);
    setApproveModal(false);
  }

  function handleChannelData(campaignId){
    setCampId(campaignId)
  }

  function handleMupltipleChannelData(campaignId){
    const CampData = data?.campaigns?.filter((el)=>el.id===campaignId);
    const campIds = CampData[0]?.subRows?.map((el)=>el.id);

    setIsMultiple(true);
    setAllCampaignIds(campIds);
    setCampId(campaignId)
  }

  const newColumns = useMemo(
      () => [
        {
          id: 'name',
          accessorKey: 'name',
          header: ({ table }) => (
              <>
                <Box maxW="150px">
                  <button onClick={table.getToggleAllRowsExpandedHandler()}>
                    {table.getIsAllRowsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  </button>{' '}
                  Campaign Name
                </Box>

              </>
          ),
          cell: ({ row, getValue }) => {
            return (
                <div
                    style={{
                      paddingLeft: `${row.depth * 2}rem`,
                    }}
                >
                  <Flex alignItems="center">
                    {row.getCanExpand() ? (
                        <button
                            onClick={row.getToggleExpandedHandler()}
                            style={{cursor: 'pointer'}}
                        >
                          {row.getIsExpanded() ? <ChevronDownIcon fontSize="20" color="gray"/> : <ChevronRightIcon fontSize="20" color="gray"/>}
                        </button>
                    ) : (
                        <button>
                          <svg width="20px" height="20px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.5 9.125C8.39746 9.125 9.125 8.39746 9.125 7.5C9.125 6.60254 8.39746 5.875 7.5 5.875C6.60254 5.875 5.875 6.60254 5.875 7.5C5.875 8.39746 6.60254 9.125 7.5 9.125ZM7.5 10.125C8.94975 10.125 10.125 8.94975 10.125 7.5C10.125 6.05025 8.94975 4.875 7.5 4.875C6.05025 4.875 4.875 6.05025 4.875 7.5C4.875 8.94975 6.05025 10.125 7.5 10.125Z"
                                fill="#000000"
                            />
                          </svg>
                        </button>
                    )}{' '}
                    {/*{`${getValue(row.id)}${row.subRows.length > 0 ? "_" + row.original.detail.demographics.location.locationGroup : ""}`}*/}
                    {`${getValue(row.id)}${row.original.hasSubRows ? "_" + row.original.detail.demographics.location.locationGroup : ""}`}
                    {/*{`${getValue(row.id)}`}*/}
                  </Flex>
                </div>
            )
          },
          footer: (props) => props.column.id,
        },
        {
          id: 'business_name',
          // accessorFn: (row) => row.lastName,
          accessorKey: 'detail.businessDetails.name',
          cell: (info) => info.getValue(),
          header: () => <span>Business Name</span>,
          footer: (props) => props.column.id,
        },
        {
          id: 'status',
          accessorKey: 'status',
          header: () => 'status',
          footer: (props) => props.column.id,
          cell: ({ row, getValue }) => {
            if (!(row.original.subRows?.length > 0)) {
              return (
                  <Box>
                    {AD_UPLOAD_STATUS.CREATED === row.original.status.toUpperCase() && (
                        <Tag
                            backgroundColor={"#FFC108"}
                            size="md"
                            color={"white"}
                            borderRadius={100}
                        >
                          <TagLeftIcon boxSize="3" as={WarningIcon}/>
                          <TagLabel fontSize="xx-small">Action Needed</TagLabel>
                        </Tag>
                    )}
                    {AD_UPLOAD_STATUS.APPROVED === row.original.status.toUpperCase() && (
                        <Tag
                            backgroundColor={"#63C68B"}
                            size="md"
                            color={"white"}
                            borderRadius={100}
                        >
                          <TagLeftIcon boxSize="3" as={CheckCircleIcon}/>
                          <TagLabel fontSize="xx-small">Approved</TagLabel>
                        </Tag>
                    )}
                    {AD_UPLOAD_STATUS.REJECTED === row.original.status.toUpperCase() && (
                        <Tag
                            backgroundColor="#A7A9BD"
                            size="md"
                            color={"white"}
                            borderRadius={100}
                        >
                          <TagLeftIcon
                              boxSize="3"
                              as={SmallCloseIcon}
                              backgroundColor={"white"}
                              color={"#A7A9BD"}
                              borderRadius={100}
                          />
                          <TagLabel fontSize="xx-small">Declined</TagLabel>
                        </Tag>
                    )}
                  </Box>
              );
            }
          }
        },
        {
          id: 'start_date',
          accessorKey: 'start_date',
          header: () => <span>Start Date</span>,
          footer: (props) => props.column.id,
        },
        {
          id: 'end_date',
          accessorKey: 'end_date',
          header: 'End Date',
          footer: (props) => props.column.id,
        },
        {
          id: 'approval_status',
          header: 'Approval',
          footer: (props) => props.column.id,
          cell: ({ row }) => {
            const campId = row.original.id;
            const status = row.original.status;
            const name = row.original.name;
            if (!(row.original.subRows?.length > 0)) {
              if (AD_UPLOAD_STATUS[status.toUpperCase()] === AD_UPLOAD_STATUS.CREATED) {
                return (
                    <HStack>
                      <Image src={TickIcon} boxSize="7" onClick={() => handleApproveModel(name, campId, _.capitalize(AD_UPLOAD_STATUS.APPROVED))} cursor="pointer"
                      />
                      <Image src={CrossIcon} boxSize="6" onClick={() => handleApproveModel(name, campId, _.capitalize(AD_UPLOAD_STATUS.REJECTED))} cursor="pointer"
                      />
                    </HStack>
                );
              }
            }
          },
          isPlaceholder: true
        },
        {
          id: 'actions',
          accessorKey: 'actions',
          header: 'Actions',
          footer: (props) => props.column.id,
          cell: ({ row, getValue }) => {
            const status = row.original.status;
            const name = row.original.name;
            const campId = row.original.id;
            if (!(row.original.subRows?.length > 0)) {
              return (
                  <HStack justify="end" pr="4">
                    <Menu>
                      <MenuButton
                          variant="ghost"
                          maxW="20px"
                          as={IconButton}
                          size="sm"
                      >
                        <Icon as={BsThreeDotsVertical} boxSize="4" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                            as={Link}
                            to={`/campaign-briefs/${row.original.id}`}
                        >
                          {AD_UPLOAD_STATUS.CREATED === status.toUpperCase()
                              ? "View/Edit"
                              : "View"}
                        </MenuItem>
                        <MenuItem onClick={()=> handleChannelData(campId)}
                        >
                          Creatives
                        </MenuItem>
                        <MenuItem onClick={()=> navigate(`/client/${clientId}/campaign-briefs/${row.original.id}/campaign-creatives`)}
                        >
                          Campaign Creatives
                        </MenuItem>
                        {!roles.includes("Client") && (
                            <>
                              <MenuItem onClick={() => handleDeleteModel(name, campId)}>
                                Delete
                              </MenuItem>
                              <MenuItem
                                  onClick={() => {
                                    try {
                                      mutate({
                                        clientId: row.original.client_id,
                                        campaignBriefId: row.original.id,
                                      });
                                    } catch (e) {
                                      console.log('Error', e)
                                    }
                                  }}
                              >
                                Clone
                              </MenuItem>
                            </>
                        )}
                      </MenuList>

                    </Menu>
                  </HStack>
              );
            } else {
              return (
                  <HStack justify="end" pr="4">
                    <Menu>
                      <MenuButton
                          variant="ghost"
                          maxW="20px"
                          as={IconButton}
                          size="sm"
                      >
                        <Icon as={BsThreeDotsVertical} boxSize="4" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={()=> handleMupltipleChannelData(campId)}>
                          Creatives
                        </MenuItem>
                      </MenuList>

                    </Menu>
                  </HStack>
              );
            }
          },
          isPlaceholder: true
        },
      ],
      [refetch, data, campaignData]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Campaign Name",
        accessor: "name",
        Cell: (data) => {
          return (
            <Box maxW="150px">
              <Text noOfLines={1} fontSize="xs">
                {data.value}
              </Text>
            </Box>
          );
        },
      },
      {
        Header: "Business Name",
        accessor: "detail.businessDetails.name",
        Cell: (data) => {
          return (
            <Box maxW="150px">
              <Text noOfLines={1} fontSize="xs">
                {data.value}
              </Text>
            </Box>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (data) => {
          console.log('data.value', data)
          return (
            <Box>
              {AD_UPLOAD_STATUS.CREATED === data.value.toUpperCase() && (
                <Tag
                  backgroundColor={"#FFC108"}
                  size="md"
                  color={"white"}
                  borderRadius={100}
                >
                  <TagLeftIcon boxSize="3" as={WarningIcon} />
                  <TagLabel fontSize="xx-small">Action Needed</TagLabel>
                </Tag>
              )}
              {AD_UPLOAD_STATUS.APPROVED === data.value.toUpperCase() && (
                <Tag
                  backgroundColor={"#63C68B"}
                  size="md"
                  color={"white"}
                  borderRadius={100}
                >
                  <TagLeftIcon boxSize="3" as={CheckCircleIcon} />
                  <TagLabel fontSize="xx-small">Approved</TagLabel>
                </Tag>
              )}
              {AD_UPLOAD_STATUS.REJECTED === data.value.toUpperCase() && (
                <Tag
                  backgroundColor="#A7A9BD"
                  size="md"
                  color={"white"}
                  borderRadius={100}
                >
                  <TagLeftIcon
                    boxSize="3"
                    as={SmallCloseIcon}
                    backgroundColor={"white"}
                    color={"#A7A9BD"}
                    borderRadius={100}
                  />
                  <TagLabel fontSize="xx-small">Declined</TagLabel>
                </Tag>
              )}
            </Box>
          );
        },
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (data) => {
          return (
            <Box maxW="150px">
              <Text noOfLines={1} fontSize="xs">
                {data.value}
              </Text>
            </Box>
          );
        },
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (data) => {
          return (
            <Box maxW="150px">
              <Text noOfLines={1} fontSize="xs">
                {data.value}
              </Text>
            </Box>
          );
        },
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: (data) => {
          const status = data.row.values.status;
          const name = data.row.values.name;
          const campId = data.row.original.id;
          return (
            <HStack justify="end" pr="4">
              <Menu>
                <MenuButton
                  variant="ghost"
                  maxW="20px"
                  as={IconButton}
                  size="sm"
                >
                  <Icon as={BsThreeDotsVertical} boxSize="4" />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={Link}
                    to={`/campaign-briefs/${data.row.original.id}`}
                  >
                    {AD_UPLOAD_STATUS.CREATED === status.toUpperCase()
                      ? "View/Edit"
                      : "View"}
                  </MenuItem>
                  <MenuItem onClick={()=> handleChannelData(campId)}
                  >
                    Creatives
                  </MenuItem>
                  <MenuItem onClick={()=> navigate(`/client/${clientId}/campaign-briefs/${data.row.original.id}/campaign-creatives`)}
                  >
                    Campaign Creatives
                  </MenuItem>
                  {!roles.includes("Client") && (
                    <>
                      <MenuItem onClick={() => handleDeleteModel(name, campId)}>
                        Delete
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          try {
                            mutate({
                              clientId: data.row.original.client_id,
                              campaignBriefId: data.row.original.id,
                            });
                          } catch (e) {
                            console.log('Error', e)
                          }
                        }}
                      >
                        Clone
                      </MenuItem>
                    </>
                  )}
                </MenuList>

              </Menu>

              {/* <Button
                as={Link}
                size="sm"
                variant="outline"
                aria-label="View details"
                icon={<FiEye />}
                to={`/campaign-briefs/${data.row.original.id}`}
                color="#3B82F6"
                py={5}
                px="2"
                w={100}
              >
                {AD_UPLOAD_STATUS.CREATED === status.toUpperCase()
                  ? "View/Edit"
                  : "View"}
              </Button> */}
              {/* {!roles.includes("Client") && (
                <>
                  <Button
                    size="sm"
                    variant="outline"
                    aria-label="View details"
                    icon={<FiEye />}
                    color="#FF8189"
                    py={5}
                    w={100}
                    // disabled={"true"}
                    onClick={() => handleDeleteModel(name, campId)}
                  >
                    Delete
                  </Button>
                  <Button
                    size="sm"
                    variant="outline"
                    isLoading={isLoading}
                    onClick={() => {
                      mutate({
                        clientId: data.row.original.client_id,
                        campaignBriefId: data.row.original.id,
                      });
                    }}
                    colorScheme="yellow"
                    py={5}
                  >
                    Clone
                  </Button>
                </>
              )} */}
            </HStack>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="ad-upload-list">
      <DeleteModel
        heading={"Delete Brief"}
        name={briefName}
        isOpen={isDeleteModalOpen}
        onClose={() => onCloseDeleteModal()}
        onDestroy={() => onDestroyModel()}
      />
      <ApproveModel
        name={briefName}
        status={statusToUpdate}
        isOpen={isApproveModalOpen}
        onClose={() => onCloseApproveModal()}
        onDestroy={() => onDestroyModel()}
      />
      <Heading color="brand.heading" fontWeight="600" size="lg" my={5} mb={7}>
        Campaigns
      </Heading>

      <SearchAndButton
        buttonText="Create new brief"
        btnClick={() => navigate("/campaign-briefs/new")}
        setSearch={(e) => setSearch(e.target.value)}
      />

      <Divider />
      <CampaignDataTable
        data={campaignData || []}
        newColumns={newColumns}
        headerColor={"brand.primary"}
      />
    </div>
  );
};

export default CampaignBriefs;
