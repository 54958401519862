import React, {useEffect, useState} from "react";
import {Heading} from "@chakra-ui/layout";
import "../../pages/AdCampaigns/adCampaign.css";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Select,
} from "@chakra-ui/react";
import {Geography} from "../../pages/CampaignBriefs/constant/SelectValues";
import {Input} from "@chakra-ui/input";
import axios from "../../helpers/axios";
import MultiSelectInputBox from "../MultiSelectInputBox";
import CloneCampaignModal from "./CloneCampaignModal";
import {useSelector} from "react-redux";

function CampaignGeography({
                               selectedGeography,
                               handleSelectedGeography,
                               setLocation,
                               location,
                               id
                           }) {
    const [showCountry, setShowCountry] = useState(false);
    const [isCloneModalOpen, setCloneModal] = useState(false);
    const [showMultipleCountry, setShowMultipleCountry] = useState(false);
    const [showProvince, setShowProvince] = useState(false);
    const [showCity, setShowCity] = useState(false);
    const [showZipCode, setShowZipCode] = useState(false);
    const [showLocationGroup, setShowLocationGroup] = useState(false);
    const [countries, setCountries] = useState([]);
    const [province, setProvince] = useState([]);
    const [city, setCity] = useState([]);
    const {cloneDetails} = useSelector((state) => state.adCampaign);

    useEffect(() => {
        if (selectedGeography) {
            if (selectedGeography === "Country") {
                setShowMultipleCountry(true);
                setShowProvince(false);
                setShowCity(false);
                setShowZipCode(false);
                setShowCountry(false);
            } else if (selectedGeography === "Province") {
                setShowCountry(true);
                setShowProvince(true);
                setShowCity(false);
                setShowZipCode(false);
                setShowMultipleCountry(false);
            } else if (selectedGeography === "City") {
                setShowCountry(true);
                setShowProvince(false);
                setShowCity(true);
                setShowZipCode(false);
                setShowMultipleCountry(false);
            } else {
                setShowCountry(true);
                setShowProvince(false);
                setShowCity(false);
                setShowZipCode(true);
                setShowMultipleCountry(false);
            }
            setShowLocationGroup(true);
        }
    }, [selectedGeography]);

    const method = "get";
    const headers = {
        "X-CSCAPI-KEY": process.env.REACT_APP_GEOGRAPHY_API_KEY,
    };

    const config = {method, headers};

    useEffect(() => {
        axios("https://api.countrystatecity.in/v1/countries", config).then(
            (res) => {
                setCountries(res.data);
            }
        );
    }, []);

    useEffect(() => {
        if (location.country) {
            if (selectedGeography.toLowerCase() === "city") {
                axios(
                    `https://api.countrystatecity.in/v1/countries/${location.country}/cities`,
                    config
                ).then((res) => {
                    setCity(res.data);
                });
            }
            if (selectedGeography.toLowerCase() === "province") {
                axios(
                    `https://api.countrystatecity.in/v1/countries/${location.country}/states`,
                    config
                ).then((res) => {
                    setProvince(res.data);
                });
            }
        }
    }, [location.country, selectedGeography]);

    return (
        <div>
            <div style={{margin: "8px 0"}}>
                <Heading fontSize={"lg"} fontWeight="500">
                    Campaign Geography
                </Heading>
                <FormControl maxW="460px" mt={6} isRequired>
                    <FormLabel>Add Location</FormLabel>
                    <Select
                        id="location"
                        name="location"
                        placeholder="Select"
                        value={selectedGeography}
                        onChange={handleSelectedGeography}
                    >
                        {Geography.map((objective) => (
                            <option
                                className="campaign-option"
                                key={objective.label}
                                value={objective.value}
                            >
                                {objective.label}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Grid
                templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}}
                gap={6}
            >
                {showMultipleCountry && (
                    <GridItem>
                        <FormControl maxWidth={500} mt={4} isRequired>
                            <FormLabel>Countries</FormLabel>
                            <MultiSelectInputBox
                                name="countries"
                                value={location.countries}
                                options={countries?.map((el) => {
                                    return {
                                        label: el.name,
                                        value: el.name,
                                    };
                                })}
                                placeholder="-- Select Countries --"
                                onChange={(e) => {
                                    setLocation({
                                        ...location,
                                        countries: e.map((v) => v),
                                        country: '',
                                        city: [],
                                        province: [],
                                    })
                                }}
                            />
                        </FormControl>
                    </GridItem>
                )}
                {showCountry && (
                    <GridItem>
                        <FormControl maxWidth={500} mt={4} isRequired>
                            <FormLabel>Country</FormLabel>
                            <Select
                                id="country"
                                name="country"
                                placeholder="Select Country"
                                value={location.country}
                                onChange={(e) =>
                                    setLocation({
                                        ...location,
                                        country: e.target.value,
                                        countries: [],
                                        city: [],
                                        province: [],
                                    })
                                }
                            >
                                {countries.map((objective) => (
                                    <option key={objective.id} value={objective.iso2}>
                                        {objective.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </GridItem>
                )}
                {showProvince && (
                    <>
                        <GridItem>
                            <FormControl maxWidth={500} mt={4} isRequired>
                                <FormLabel>Province</FormLabel>
                                <MultiSelectInputBox
                                    // label="Province"
                                    name="province"
                                    value={location.province}
                                    options={province?.map((el) => {
                                        return {
                                            label: el.name,
                                            value: el.name,
                                        };
                                    })}
                                    placeholder="-- Select Province --"
                                    onChange={(e) => {
                                        setLocation({...location, province: e.map((v) => v)});
                                    }}
                                />
                            </FormControl>
                        </GridItem>
                    </>
                )}
                {showCity && (
                    <>
                        <GridItem>
                            <FormControl maxWidth={500} mt={4} isRequired>
                                <FormLabel>City</FormLabel>
                                <MultiSelectInputBox
                                    // label="City"
                                    name="city"
                                    value={location.city}
                                    options={city?.map((el) => {
                                        return {
                                            label: el.name,
                                            value: el.name,
                                        };
                                    })}
                                    placeholder="-- Select City --"
                                    onChange={(e) => {
                                        setLocation({...location, city: e.map((v) => v)});
                                    }}
                                />
                            </FormControl>
                        </GridItem>
                    </>
                )}
                {showZipCode && (
                    <GridItem>
                        <FormControl isRequired maxWidth={500} mt={4}>
                            <FormLabel>Zip Code</FormLabel>
                            <Input
                                name="zipCode"
                                borderColor="#e7e7e9"
                                value={location.zipCode}
                                onChange={(e) =>
                                    setLocation({...location, zipCode: e.target.value})
                                }
                                borderRadius={1}
                                color={"#757998"}
                                fontWeight={500}
                            />
                        </FormControl>
                    </GridItem>
                )}
                {showLocationGroup && (
                    <GridItem>
                        <FormControl isRequired maxWidth={500} mt={4}>
                            <FormLabel>Location Group</FormLabel>
                            <Input
                              name="locationGroup"
                              borderColor="#e7e7e9"
                              value={location.locationGroup}
                              onChange={(e) =>
                                setLocation({...location, locationGroup: e.target.value})
                              }
                              borderRadius={1}
                              color={"#757998"}
                              fontWeight={500}
                            />
                        </FormControl>
                    </GridItem>
                )}
                <CloneCampaignModal
                    isOpen={isCloneModalOpen}
                    onClose={() => setCloneModal(!isCloneModalOpen)}
                />
            </Grid>
            {cloneDetails.length > 0 &&<Box mt={10}>
                <Heading fontSize={"lg"} fontWeight="500">
                    Replicated Campaign
                </Heading>
            </Box>}
            <Grid
                templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}}
                gap={6}
                mt={6}
            >
                {cloneDetails.length > 0 &&
                <>
                    {cloneDetails[0]?.selectedCountries?.length > 0 && (
                        <GridItem>
                            <FormControl maxWidth={500} mt={4} isRequired>
                                <FormLabel>Countries</FormLabel>
                                <MultiSelectInputBox
                                    name="countries"
                                    value={cloneDetails[0].selectedCountries}
                                    placeholder="-- Select Countries --"
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={true}
                                    isReadOnly={true}
                                />
                            </FormControl>
                        </GridItem>
                    )}
                    {cloneDetails[0]?.selectedCountry && (
                        <GridItem>
                            <FormControl maxWidth={500} mt={4} isRequired>
                                <FormLabel>Country</FormLabel>
                                <Select
                                    id="country"
                                    name="country"
                                    placeholder="Select Country"
                                    value={cloneDetails[0].selectedCountry}
                                    hideSelectedOptions={true}
                                    closeMenuOnSelect={true}
                                    isReadOnly={true}
                                    style={{ pointerEvents: 'none' }}
                                >
                                    {countries.map((objective) => (
                                        <option key={objective.id} value={objective.iso2}>
                                            {objective.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridItem>
                    )}
                    {cloneDetails[0]?.selectedProvince?.length > 0 && (
                        <>
                            <GridItem>
                                <FormControl maxWidth={500} mt={4} isRequired>
                                    <FormLabel>Province</FormLabel>
                                    <MultiSelectInputBox
                                        // label="Province"
                                        name="province"
                                        value={cloneDetails[0].selectedProvince}
                                        placeholder="-- Select Province --"
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={true}
                                        isReadOnly={true}
                                    />
                                </FormControl>
                            </GridItem>
                        </>
                    )}
                    {showLocationGroup && (
                        // <GridItem>
                        //     <FormControl isRequired maxWidth={500} mt={4}>
                        //         <FormLabel>Location Group</FormLabel>
                        //         <Input
                        //             name="locationGroup"
                        //             borderColor="#e7e7e9"
                        //             value={location.locationGroup}
                        //             onChange={(e) =>
                        //                 setLocation({...location, locationGroup: e.target.value})
                        //             }
                        //             borderRadius={1}
                        //             color={"#757998"}
                        //             fontWeight={500}
                        //         />
                        //     </FormControl>
                        // </GridItem>
                        <GridItem>
                            <FormControl isRequired maxWidth={500} mt={4}>
                                <FormLabel>Location Group</FormLabel>
                                <Input
                                name="locationGroup"
                                borderColor="#e7e7e9"
                                value={cloneDetails[0].locationGroup}
                                borderRadius={1}
                                color={"#757998"}
                                fontWeight={500}
                                isReadOnly={true}
                                />
                            </FormControl>
                        </GridItem>
                    )}
                </>
                }
            </Grid>
                {!id && <Button onClick={() => setCloneModal(!isCloneModalOpen)} w={'fit-content'} fontWeight={400}
                                fontFamily={"poppins"} fontSize="14" mt={2}>
                    {cloneDetails.length === 0 ? 'Clone Campaign' : 'Edit Clone Campaign'}
                </Button>}
            {/*<Grid*/}
            {/*    templateColumns={{base: "repeat(1, 1fr)", md: "repeat(2, 1fr)"}}*/}
            {/*    gap={6}*/}
            {/*    mt={6}*/}
            {/*>*/}
            {/*    {cloneDetails.length > 0 &&*/}
            {/*    <>*/}
            {/*        <GridItem>*/}
            {/*            <FormControl maxWidth={500} mt={4} isRequired>*/}
            {/*                <FormLabel>Countries</FormLabel>*/}
            {/*                <MultiSelectInputBox*/}
            {/*                    name="countries"*/}
            {/*                    value={cloneDetails[0].selectedCountries}*/}
            {/*                    placeholder="-- Select Countries --"*/}
            {/*                    closeMenuOnSelect={true}*/}
            {/*                    hideSelectedOptions={true}*/}
            {/*                    isReadOnly={true}*/}
            {/*                />*/}
            {/*            </FormControl>*/}
            {/*        </GridItem>*/}
            {/*        <GridItem>*/}
            {/*            <FormControl isRequired maxWidth={500} mt={4}>*/}
            {/*                <FormLabel>Location Group</FormLabel>*/}
            {/*                <Input*/}
            {/*                    name="locationGroup"*/}
            {/*                    borderColor="#e7e7e9"*/}
            {/*                    value={cloneDetails[0].locationGroup}*/}
            {/*                    borderRadius={1}*/}
            {/*                    color={"#757998"}*/}
            {/*                    fontWeight={500}*/}
            {/*                    isReadOnly={true}*/}
            {/*                />*/}
            {/*            </FormControl>*/}
            {/*        </GridItem>*/}
            {/*    </>*/}
            {/*    }*/}
            {/*    {!id && <Button onClick={() => setCloneModal(!isCloneModalOpen)} w={'fit-content'} fontWeight={400}*/}
            {/*             fontFamily={"poppins"} fontSize="14">*/}
            {/*        {cloneDetails.length === 0 ? 'Clone Campaign' : 'Edit Clone Campaign'}*/}
            {/*    </Button>}*/}
            {/*</Grid>*/}
        </div>
    );
}

export default CampaignGeography;
