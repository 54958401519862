import React, {useState} from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import GmailIn from "./GmailIn";
import GmailOut from "./GmailOut";

function Gmail({formDetails, appLoading, ads, index, handleIndex, businessName}) {
  const [isGmailOpen, setGmailOpen] = useState(false);
  const handleGmailState = () => {
    setGmailOpen(!isGmailOpen);
  }
  console.log('isGmailOpen', isGmailOpen)

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%' overflow="auto">
                {isGmailOpen ? <GmailIn
                  formDetails={formDetails}
                  ads={ads}
                  index={index}
                  handleIndex={handleIndex}
                  businessName={businessName}
                  handleGmailState={handleGmailState}
                /> : <GmailOut
                  formDetails={formDetails}
                  ads={ads}
                  index={index}
                  handleIndex={handleIndex}
                  businessName={businessName}
                  handleGmailState={handleGmailState}
                />}
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default Gmail;
