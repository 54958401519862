import {
  Divider,
  HStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Datatable from "../../components/Datatable";
import { useGetUserList } from "../../hooks/users/useGetUserList";
import { TEXT_COLOR } from "../../layout/constant/MenuList";
import RolesView from "./components/RolesView";
import UserTableNameWithProfile from "./components/UserTableNameWithProfile";
import "../../pages/CampaignBriefs/style/AdUploadList.css";
import { Roles } from "../../constant";
import { BsThreeDotsVertical } from "react-icons/bs";
import SearchAndButton from "../../components/SearchAndButton";
import DeleteModel from "../../components/PopupModal/DeleteModel";
import {useDeleteCampaign} from "../../hooks/campaign-briefs/useDeleteCampaign";
import {useGetCampaignList} from "../../hooks/campaign-briefs/useGetCampaignList";
import instance from "../../helpers/axios";
const Users = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: (data) => <UserTableNameWithProfile data={data} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: (data) => <Text fontSize="xs">{data.value}</Text>,
      },
      {
        Header: "Roles",
        accessor: "roles",
        Cell: (data) => {
          return <RolesView roles={data.row.original.access_info.roles} />;
        },
      },
      {
        Header: "State",
        accessor: "state",
        Cell: (data) => {
          return (
            <HStack>
              <Icon
                viewBox="0 0 200 200"
                mr={2}
                color={
                  data.row.original.state === "ACTIVE"
                    ? "#3F7EE6"
                    : data.row.original.state === "INACTIVE"
                    ? "red"
                    : data.row.original.state === "ON_HOLD"
                    ? "yellow.500"
                    : "#3F7EE6"
                }
              >
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
              <Text fontSize="xs"> {data.row.original.state}</Text>
            </HStack>
          );
        },
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: (data) => {
          return (
            <VStack justify="end" maxW="20px">
              <Menu>
                <MenuButton as={IconButton} variant="ghost" size="sm">
                  <Icon as={BsThreeDotsVertical} />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={Link}
                    to={`/user/${data.row.original.id}`}
                    aria-label="View details"
                  >
                    View/Edit
                  </MenuItem>
                  <MenuItem onClick={() => handleDeleteModel(data.row.original)}>Delete</MenuItem>
                </MenuList>
              </Menu>
            </VStack>
          );
        },
      },
    ],
    []
  );

  const [search, setSearch] = useState();
  const [users, setUsers] = useState();

  const { data, refetch } = useGetUserList();
  const navigate = useNavigate();
    const [isDeleteModalOpen, setDeleteModal] = useState(false);
    const [userName, setUserName] = useState(null);
    const [deletableId, setDeletableId] = useState(null);

    function handleDeleteModel(data) {
        setDeletableId(data.id);
        setDeleteModal(true);
        setUserName(data.name);
    }

    function onDestroyModel() {
        setDeleteModal(false);
    }

    function onCloseDeleteModal() {
        return instance({
            method: 'DELETE',
            url: `/user/${deletableId}`,
        })
            .then((res) => {
                if (res.status === 200) {
                    refetch();
                    console.log('Response', res)
                    setDeleteModal(false);
                } else {
                    throw new Error('Not authorized')
                }
            })
            .catch((err) => {
                console.log(err.response)
                throw err
            })
    }

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setUserInfo(data?.users);
  }, [data]);

  useEffect(() => {
    if (search?.trim()) {
      const searchedUsers = users.filter((el) => {
        if (el?.email?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.name?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.access_info?.roles?.includes(search.trim())) {
          return true;
        }
      });
      setUsers(searchedUsers);
    } else {
      setUserInfo(data?.users);
    }
  }, [search]);

  const setUserInfo = (data) => {
    const user = data?.map((el) => {
      const roles = [];
      el?.access_info?.roles?.forEach((val) => {
        const role = Roles.filter((e) => e.value === val);
        roles.push(role?.[0]?.title);
      });
      return { ...el, access_info: { roles } };
    });
    setUsers(user);
  };

  return (
    <div className="ad-upload-list">
        <DeleteModel
            heading={"Delete User"}
            name={userName}
            isOpen={isDeleteModalOpen}
            onClose={() => onCloseDeleteModal()}
            onDestory={() => onDestroyModel()}
        />
      <Heading color={TEXT_COLOR} fontWeight="500" size="lg" my={5} mb={7}>
        Users
      </Heading>

      <SearchAndButton
        buttonText="Add User"
        btnClick={() => navigate("/user")}
        setSearch={(e) => setSearch(e.target.value)}
      />

      <Divider />
      <Datatable
        data={users || []}
        columns={columns}
        headerColor={"brand.primary"}
      />
      {/* <CreateUserModal isOpen={isOpen} onClose={onClose} /> */}
    </div>
  );
};

export default Users;
