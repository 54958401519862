import React from 'react';
import {Box} from "@chakra-ui/react";

function ShareIcon({ color = "#0077ff", height= "20px", width= "20px" }) {
  return (
    <Box height={height} width={width}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title/>
        <path
          d="M18,21H6a3,3,0,0,1-3-3V6A3,3,0,0,1,6,3h4a1,1,0,0,1,0,2H6A1,1,0,0,0,5,6V18a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V14a1,1,0,0,1,2,0v4A3,3,0,0,1,18,21Z"
          fill={color}/>
        <path
          d="M21,4.05v5a1,1,0,0,1-.62.92.84.84,0,0,1-.38.08,1,1,0,0,1-.71-.29L17.45,8l-4.79,4.79a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L16,6.55,14.24,4.76A1,1,0,0,1,14,3.67,1,1,0,0,1,15,3.05h5a.73.73,0,0,1,.25,0,.37.37,0,0,1,.14,0,.94.94,0,0,1,.53.53.37.37,0,0,1,0,.14A.73.73,0,0,1,21,4.05Z"
          fill={color}/>
      </svg>
    </Box>
  );
}

export default ShareIcon;
