import React from 'react';
import {Box, Flex, Image, SkeletonCircle, Text} from "@chakra-ui/react";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import ReactPlayer from "react-player";

function SdImage970250(props) {
    const { formDetails, mediaDetails, handlePreviewData} = props;
    const selectedLogos = formDetails?.channelIcon?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads/` + formDetails.channelIcon.imageUrl :
        process.env.PUBLIC_URL + "/images/display.png";

    return (
        <Box bg='white' borderRadius='5' w="970px" h="250px" p='1'>
            <Flex gap='3' h={"100%"}>
                <Flex alignItems='center' justifyContent='center' w={"40%"} overflow="hidden">
                    {!props.video ? <Image src={handlePreviewData[0].imageUrl}/> :
                        <ReactPlayer
                            className='react-player videos'
                            url={handlePreviewData}
                            playing={true}
                            loop={true}
                        />
                    }
                </Flex>
                <Box position='relative' py='4' w={"40%"}>
                    <Flex alignItems='center' gap='2'>
                        <Image src={selectedLogos} height={8}
                               width={8} rounded="full"/>
                        <Flex direction='column'>
                            <Text fontSize='12' fontWeight='600'>{formDetails.channel_name}</Text>
                            <Text fontSize='10' color='gray.500'>10.6k followers</Text>
                        </Flex>
                    </Flex>
                    <Box mt='2' fontSize='13' px='2'>{formDetails.caption}</Box>
                    <Box bg='white' py='2' position='absolute' bottom='0' zIndex='3' w='100%'>
                        <Flex alignItems='center' justifyContent='center' gap='2'>
                            <Flex alignItems='center'>
                                <Box><Image src={`${process.env.PUBLIC_URL}/images/fb_like.png`} height={4} width={4}/></Box>
                                <Box marginStart='-1' zIndex='-1'><Image src={`${process.env.PUBLIC_URL}/images/love.png`} height={4} width={4}/></Box>
                                <Box marginStart='-1' zIndex='-2'><Image src={`${process.env.PUBLIC_URL}/images/surprised.png`} height={4} width={4} /></Box>
                            </Flex>
                            <Text fontSize='12' fontWeight='600' color='#a3a3a3'>11 &bull; 1 Comment</Text>
                        </Flex>
                    </Box>
                </Box>
                <Flex direction='column' justifyContent='center' bg='#f8f8f8' textAlign='center' px='6' w={"20%"}>
                    <Text fontSize='13' fontWeight='600'>{formDetails.headline}</Text>
                    <Text fontSize='13'>{formDetails.description}</Text>
                    <Box marginX='1' bg='#e5e7ea' borderRadius='3' p='1' mt='3'>
                        <Text fontSize='14' fontWeight='bold'>{youtubeShortsTypes.find((i) => i.key === formDetails?.type)?.name}</Text>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    );
}

export default SdImage970250;
