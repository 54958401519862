import {
    Avatar,
    AvatarBadge,
    Box,
    Flex,
    HStack,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Text,
    VStack,
} from '@chakra-ui/react'
import "./notificationStyle.css"
import moment from "moment";
import {Heading} from "@chakra-ui/layout";
import {useAdUploadList} from "../../hooks/campaign-briefs/useAdUploadList";
import {useNotification} from "../../hooks/useNotification";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom"

function CampaignNotification({item, content}) {
    const { campaign_brief_id, created_at, user} = item;
    const { name, status, campaign_status, actionBy } = content;
    if(campaign_status === "Approved" || campaign_status === "Rejected") {
        return(
            <Box borderBottom={"0.7px solid rgba(167, 169, 189, 0.2)"} paddingBottom={"4px"} display={"flex"} alignItems={"flex-start"} gap={4} marginBottom={6}>
                <Avatar
                    name={actionBy || "User"}
                    src={`${process.env.REACT_APP_API_URL}/uploads/${user?.other_info?.profile_pic_url}`}
                >
                </Avatar>
                <Text> {actionBy || "User"} has&nbsp;
                    <Text as="span" color={ campaign_status === "Approved" ? "green" : "red" }>{campaign_status}</Text>
                    &nbsp;
                    <Text as={"span"} color="blue" fontWeight={"bold"}>{name}</Text>
                    &nbsp;
                    Campaign
                    <Text>
                        {moment(created_at).fromNow()} </Text>
                </Text>
                <Link className="review-btn" to={`/campaign-briefs/${campaign_brief_id}`}> Review </Link>
            </Box>
        )
    }

    return(
            <Box borderBottom={"0.7px solid rgba(167, 169, 189, 0.2)"} paddingBottom={"4px"} display={"flex"} alignItems={"flex-start"} gap={4} marginBottom={6}>
                <Avatar
                    name={actionBy || "User"}
                    src={`${process.env.REACT_APP_API_URL}/uploads/${user?.other_info?.profile_pic_url}`}
                >
                </Avatar>
                <Text> {actionBy || "User"} has {status} <Text as={"span"} fontWeight={"bold"} color="blue">{name}</Text> Campaign
                    <Text>
                        {moment(created_at).fromNow()} </Text>
                </Text>
                <Link className="review-btn" to={`/campaign-briefs/${campaign_brief_id}`}> Review </Link>

            </Box>
    )
}

function AdNotification({item, content}) {
    const {ad_upload_id, campaign_brief_id, created_at, user} = item;

    const { name, status, ad_status, actionBy} = content;

    if(ad_status === "Approved" || ad_status === "Rejected") {
        return(
            <Box borderBottom={"0.7px solid rgba(167, 169, 189, 0.2)"} paddingBottom={"4px"} display={"flex"} alignItems={"flex-start"} gap={4} marginBottom={6}>
                <Avatar
                    name={actionBy || "User"}
                    src={`${process.env.REACT_APP_API_URL}/uploads/${user?.other_info?.profile_pic_url}`}
                >
                </Avatar>
                <Text > {actionBy || "User"} has&nbsp;
                     <Text as="span" color={ ad_status === "Approved" ? "green" : "red" }>{ad_status}</Text>
                     &nbsp;
                     <Text as={"span"} fontWeight={"bold"} color="blue">{name}</Text>
                    &nbsp;
                     Ad
                    <Text>{moment(created_at).fromNow()}</Text>
                </Text>
                <Link className="review-btn" to={`/campaign-briefs/${campaign_brief_id}/edit-adUpload/${ad_upload_id}`}> Review </Link>
            </Box>
        )
    }

    return  <Box borderBottom={"0.7px solid rgba(167, 169, 189, 0.2)"} paddingBottom={"4px"} display={"flex"} alignItems={"flex-start"} gap={4} marginBottom={6}>
        <Avatar
            name={actionBy || "User"}
            src={`${process.env.REACT_APP_API_URL}/uploads/${user?.other_info?.profile_pic_url}`}
        >
        </Avatar>
        <Text> {actionBy || "User"} has {status} <Text fontWeight={"bold"} as={"span"} color="blue">{name}</Text> Ad
            <Text>{moment(created_at).fromNow()}</Text>
        </Text>
        <Link className="review-btn" to={`/campaign-briefs/${campaign_brief_id}/edit-adUpload/${ad_upload_id}`}> Review </Link>
    </Box>
}

function NotificationList({ item, content }) {

    let has_campaign_status = content.hasOwnProperty("campaign_status");
    let has_ad_status = content.hasOwnProperty(("ad_status"));

    if(has_campaign_status) {
        return <CampaignNotification content={content} item={item} />
    }

    if(has_ad_status) {
        return <AdNotification item={item} content={content} />
    }

    return null
}

function Notification() {

    const clientId = useSelector((state) => state.client.clientId);
    const [notificationList, setNotificationList] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const {data, refetch} = useNotification(clientId, setIsLoading);

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        setNotificationList(data)
    }, [data]);


    return (
        <Box padding={4} className="notification-main">
            <Text fontWeight="semibold" fontSize="lg">
                Notifications
            </Text>
            <hr style={{margin: "10px 0"}} />
            <Box py={2}>
                {
                    isLoading &&  <Box overflow="hidden">
                        <Flex  align="center" justify="center">
                            <VStack spacing={4}>
                                <Spinner color="blue.500" size="lg" />
                                <Text>Loading...</Text>
                            </VStack>
                        </Flex>
                    </Box>
                }

                {
                    notificationList && notificationList.length > 0 && notificationList.map((item, index) => {
                        const {content} = item
                        return (
                            <NotificationList  key={index} item={item} content={content} />
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default Notification
