import React, {useEffect, useState} from 'react';
import {Box, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import AmPmNaming from "./AmPmNaming";
import UTMDocument from "./UTMDocument";
import instance from "../../helpers/axios";
import {useParams} from "react-router-dom";
import AdServing from "./AdServing";
import CampaignBrief from "./CampaignBrief";
import {useGetCampaignCreatives} from "../../hooks/campaign-briefs/useGetCampaignCreatives";
import CreativeDownload from "./CreativeDownload";

function CampaignCreatives() {
    const [isLoading, setIsLoading] = useState(false)
    const { id, clientId } = useParams();
    const { data, refetch } = useGetCampaignCreatives(clientId, id);

    useEffect(() => {
        refetch()
    },[])

    async function downloadCreatives(type) {
        setIsLoading(true);
        await instance({
            method: "post",
            url: `client/${clientId}/campaign-brief/${id}/${type}`,
            withCredentials: false,
            responseType: "blob",
        })
            .then((resp) => {
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `Creative ${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }

    return (
        <Box>
            <Tabs position='relative' variant='enclosed'>
                <TabList>
                    <Tab>UTM Document</Tab>
                    <Tab>Naming Convention</Tab>
                    <Tab>Ad Serving</Tab>
                    <Tab>Creative Download</Tab>
                    <Tab>Campaign Brief</Tab>
                </TabList>
                {/*<TabIndicator mt='-1.5px' height='2px' bg='blue.500' borderRadius='1px' />*/}
                <TabPanels>
                    <TabPanel>
                        <UTMDocument
                            data={data}
                            isLoading={isLoading}
                            downloadCreatives={downloadCreatives}
                            clientId={clientId}
                            id={id}
                        />
                    </TabPanel>
                    <TabPanel>
                        <AmPmNaming
                            data={data}
                            isLoading={isLoading}
                            downloadCreatives={downloadCreatives}
                            clientId={clientId}
                            id={id}
                        />
                    </TabPanel>
                    <TabPanel>
                        <AdServing
                            data={data}
                            isLoading={isLoading}
                            downloadCreatives={downloadCreatives}
                            clientId={clientId}
                            id={id}
                        />
                    </TabPanel>
                    <TabPanel>
                        <CreativeDownload
                            data={data}
                            clientId={clientId}
                            id={id}
                        />
                    </TabPanel>
                    <TabPanel>
                        <CampaignBrief
                            clientId={clientId}
                            id={id}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
}

export default CampaignCreatives;
