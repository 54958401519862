import InputBox from "../../../../components/InputBox";
import {GridItem, Box, Button, Flex, Text, IconButton} from "@chakra-ui/react";
import React, {useState} from "react";
import {AddIcon, CloseIcon} from "@chakra-ui/icons";
import _ from "lodash";
import {InputGroup, InputLeftElement, Input} from "@chakra-ui/input";

const BudgetAdOn = ({values, setFieldValue}) => {
    const [budget, setBudget] = useState([]);
    const AddNewBudgetLine = () => {
        const newArr = [...values];
        newArr.push({value: null, amount: null});
        setFieldValue('detail.campaignBudget.budgetAddOnArr', newArr);
        setBudget(newArr)
    }

    const RemoveBudgetLine = (i) => {
        const newArr = [...values];
        const arr = _.filter(newArr, (item, index) => index !== i);
        setFieldValue('detail.campaignBudget.budgetAddOnArr', arr);
        setBudget(arr);
    }

    return (
        <Box>
            <Text>Budget Add-Ons</Text>
            {budget.length !== 0 && budget.map((budget, index) => {
                return (
                    <Flex alignItems="center" gap={5}>
                        <GridItem w='100%'>
                            <InputBox
                                placeholder='Enter value'
                                name={`detail.campaignBudget.budgetAddOnArr.${[index]}.value`}
                            />
                        </GridItem>
                        <Text fontSize='2xl'>
                            =
                        </Text>
                        <GridItem w='100%'>
                            <InputBox
                                placeholder='Enter amount'
                                name={`detail.campaignBudget.budgetAddOnArr.${[index]}.amount`}
                                type="number"/>
                        </GridItem>
                        <IconButton
                            size="sm"
                            variant="raised"
                            isRound
                            color="white !important"
                            background="#ff000096"
                            onClick={() => RemoveBudgetLine(index)}
                            icon={<CloseIcon color="#ffffff"/>}
                        />
                    </Flex>
                )
            })}
            <Flex marginTop={3} gap={3} alignItems="center">
                <IconButton
                    size="sm"
                    variant="raised"
                    isRound
                    color="white !important"
                    background="#59ab9e"
                    onClick={AddNewBudgetLine}
                    icon={<AddIcon color="#ffffff"/>}
                />
                <Text>New Line</Text>
            </Flex>
            <Flex flexDirection="column" w="50%" marginTop={5} float="right">
                <Text>One-Time Free</Text>
                <Button className="total-budget">$ {_.sumBy(values, 'amount')} / month</Button>
            </Flex>
        </Box>
    );
};

export default BudgetAdOn;
