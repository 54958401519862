import {
    Box, Button, Center,
    css,
    Flex,
    Grid,
    GridItem,
    Heading,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {BiArrowBack} from "react-icons/bi";
import {useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
// import DynamicInstagramImageAdDetails from "../Instagram/Image/DynamicInstagramImageAdDetails";
// import AdPreview from "../AdPreview";
import GoogleCSVAdDetails from "./GoogleCSVAdDetails";
import GoogleAdsTableData from "./GoogleAdsTableData";
import {useGlobalContext, WrapContext} from "./store/WrapContext";
import GoogleTableData from "./GoogleTableData";
import {ArrowBackIcon} from "@chakra-ui/icons";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import AdPreview from "../../AdPreview";
import AuditLogsList from "../../../../AuditLogsList";
import WithAppContext from "../../../../../../../helpers/appContext";


const GoogleCSV = ({SetTabIndex, activeStep, steps, prevStep, nextStep, label ,context}) => {
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const [previewData, setPreviewData] = useState('');
    const [tabIndex, setTabIndex] = useState(0);

    const {initialMasterData} = useGlobalContext()

    const navigate = useNavigate();
    const {id, adUploadId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {data, refetch} = useGetAdUpload(clientId, id, adUploadId);
    const {appLoading} = context



    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${id}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${id}/ad-upload/google`);
        }
    }, [adUploadId, clientId]);

    const {
        setInitialMasterData,
        setInitialExtensionData,
        setInitialSiteLinkData,
        emptyExtensionData,
        emptySiteLinkData,
        selectedDataFunction
    } = useGlobalContext()


    useEffect(() => {
        if (data) {
            const {GoogleMaster, GoogleExtension, GoogleSiteLink} = data;
            setInitialMasterData(GoogleMaster);
            setInitialExtensionData(GoogleExtension);
            setInitialSiteLinkData(GoogleSiteLink);
        }
    }, [data]);

    const {state} = useLocation()


    useEffect(() => {
        if (state?.isUpdate) {
            setTabIndex(4)
        }
    }, [state]);


    // if (!data) {
    //     return <FullPageLoader/>
    // }

    return (
        <>
            {appLoading && <FullPageLoader/>}
            {/*{*/}
            {/*    adUploadId && (*/}
            {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
            {/*            <Heading fontSize="xl" color={TEXT_COLOR}>*/}
            {/*                Please Review your ad details carefully*/}
            {/*            </Heading>*/}
            {/*            <Heading fontSize="md" color="gray.400">*/}
            {/*                2 steps to complete*/}
            {/*            </Heading>*/}
            {/*        </Box>*/}
            {/*    )*/}
            {/*}*/}

            {/*{*/}
            {/*    !adUploadId && (*/}
            {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
            {/*            <Heading fontSize="xl" color={TEXT_COLOR}>*/}
            {/*                Please Fill in your ad details*/}
            {/*            </Heading>*/}
            {/*            <Heading fontSize="md" color="gray.400">*/}
            {/*                1 steps to complete*/}
            {/*            </Heading>*/}
            {/*        </Box>*/}
            {/*    )*/}
            {/*}*/}
            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                <GridItem w="full" colSpan={{base: 6, lg: 6}} mb={{base: 3, lg: 0}}>
                                <GoogleCSVAdDetails
                                    additioanData={data}
                                    campaignId={id}
                                    handlePreviewData={setPreviewData}
                                    data={data?.adUpload}
                                    url={url}
                                    method={method}
                                    tabIndex={tabIndex}
                                    setTabIndex={setTabIndex}
                                    activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}
                                />
                                {/*{adUploadId && <GoogleAdsTableData/>}*/}
                </GridItem>
            </Grid>
        </>
    );
};

export default WithAppContext(GoogleCSV);
