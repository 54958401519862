import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Heading } from "@chakra-ui/layout";
import {
    displayCreativeTypes,
    facebookCreativeTypes,
    googleCreativeTypes,
    instagramCreativeTypes,
    pinterestCreativeTypes,
    tiktokCreativeTypes,
    metaCreativeTypes,
    dynamicFacebookCreativeTypes,
    dynamicInstagramCreativeTypes,
    youtubeShortsTypes,
    demandGenTypes,
    snapchatCreativeTypes,
    performanceMaxTypes, socialDisplayTypes, microsoftCreativeTypes, audioTypes, linkedinTypes, discoveryTypes,
} from "../../../../../constant";
import {
    Box,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Image,
    Text,
    Select, Switch, Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/input";
import AdDetailsForm from "../StepByStepAdUploadComponent/AdDetailsForm";
import {
    selectMediaType,
    selectSocialMedia,
    settingCreativeData,
    settingEditMediaType,
    settingFormMedia

} from "../../../../../store/adUploads";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useGetAdUpload } from "../../../../../hooks/campaign-briefs/useGetAdUpload";
import MultiSelectInputBox from "../../../../../components/MultiSelectInputBox";
import { titleCase } from "../../../../../helpers/utils";
import {useGetCampaignCreatives} from "../../../../../hooks/campaign-briefs/useGetCampaignCreatives";
import {useGetCampaign} from "../../../../../hooks/campaign-briefs/useGetCampaign";
import {settingCampaignBudget} from "../../../../../store/adCampaign";
import axios from "../../../../../helpers/axios";
import {facebookAccountIds} from "../../../constant/SelectValues";


function CreativeDetails({ activeStep, steps, prevStep, nextStep, label }) {
    const dispatch = useDispatch();
    const clientId = useSelector((state) => state.client.clientId);
    const { id, adUploadId } = useParams();
    const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);
    const { campaignBudget } = useSelector((state) => state.adCampaign);
    const adCampaignData = useSelector((state) => state.adCampaign);
    const { selectedSocialMedia, creative } = useSelector((state) => state.adUpload);
    const initialValue = {
        creativeType: creative?.creativeType || "",
        targetingMethod: creative?.creativeType || [],
        startDate: creative?.startDate || "",
        endDate: creative?.endDate || "",
        promotion: creative?.promotion || "",

    };

    const [formData, setFormData] = useState(initialValue);
    const [creativeOptions, setCreativeOptions] = useState([]);
    const [targetingOptions, setTargetingOptions] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isSetNullDate, setNullDate] = useState(false);
    const [invalidDate, setInValidDate] = useState(false);
    const [isUpcoming, setIsUpcoming] = useState(false);
    const [isDiffError, setIsDiffError] = useState(false)
    const [lastDataStartDate, setLastDataStartDate] = useState("")
    const { data: allCampaignData, refetch: refetchCampaign} = useGetCampaignCreatives(clientId, id);
    const { data: campaignData, mutate: mutateGetCampaign } = useGetCampaign();
    const { isOpen, onOpen, onClose } = useDisclosure()


    useEffect(() => {
        if (allCampaignData && allCampaignData.length > 0) {
            const prevDataIndex = label ? (allCampaignData?.length - 1) : (allCampaignData?.length - 2);
            setLastDataStartDate(allCampaignData[prevDataIndex]?.flightStartDate);
        }
    }, [allCampaignData])


    useEffect(() => {
        refetch();
        refetchCampaign();
        if ( Object.keys(campaignBudget).length === 0) {
            mutateGetCampaign({
                clientId,
                campaignId: id,
            });
        }
        if (creative) setFormData(creative);
    }, [clientId, id, adUploadId]);

    useEffect(() => {
        if (campaignData?.campaign) {
            dispatch(settingCampaignBudget(campaignData?.campaign?.detail?.campaignBudget));
        }
    }, [campaignData])

    useEffect(() => {
        if (adUploadId && data?.adUpload) {
            dispatch(settingEditMediaType(data?.adUpload?.ad_upload_type));
            setFormData(data?.adUpload?.detail?.creative);
            dispatch(selectSocialMedia(data?.adUpload.detail.channel));
        }
    }, [adUploadId, data]);

    useEffect(() => {
        switch (selectedSocialMedia) {
            case "facebook":
                setCreativeOptions(facebookCreativeTypes);
                setIsUpcoming(false);
                break;
            case "facebook_dynamic":
                setCreativeOptions(dynamicFacebookCreativeTypes);
                setIsUpcoming(false);
                break;
            case "instagram":
                setCreativeOptions(instagramCreativeTypes);
                setIsUpcoming(false);
                break;
            case "instagram_dynamic":
                setCreativeOptions(dynamicInstagramCreativeTypes);
                setIsUpcoming(false);
                break;
            case 'meta':
                setCreativeOptions(metaCreativeTypes);
                setIsUpcoming(false)
                break;
            case "tiktok":
                setCreativeOptions(tiktokCreativeTypes);
                setIsUpcoming(false);
                break;
            case "snapchat":
                setCreativeOptions(snapchatCreativeTypes);
                setIsUpcoming(false);
                break;
            case "pinterest":
                setCreativeOptions(pinterestCreativeTypes);
                setIsUpcoming(false);
                break;
            case "google":
                setCreativeOptions(googleCreativeTypes);
                setIsUpcoming(false);
                break;
            case "microsoft":
                setCreativeOptions(microsoftCreativeTypes);
                setIsUpcoming(false);
                break;
            case "display":
                setCreativeOptions(displayCreativeTypes);
                setIsUpcoming(false);
                break;
            case "youtube_shorts":
                setCreativeOptions(youtubeShortsTypes);
                setIsUpcoming(false);
                break;
            case "demand_gen":
                setCreativeOptions(demandGenTypes);
                setIsUpcoming(false);
                break;
            case "performance_max":
                setCreativeOptions(performanceMaxTypes);
                setIsUpcoming(false);
                break;
            case "social_display":
                setCreativeOptions(socialDisplayTypes);
                setIsUpcoming(false);
                break;
            case "audio":
                setCreativeOptions(audioTypes);
                setIsUpcoming(false);
                break;
            case "linkedin":
                setCreativeOptions(linkedinTypes);
                setIsUpcoming(false);
                break;
            case "discovery":
                setCreativeOptions(discoveryTypes);
                setIsUpcoming(false);
                break;
            default:
                setIsUpcoming(true);
                setCreativeOptions([]);
                break;
        }
    }, [selectedSocialMedia]);

    useEffect(() => {
        if (selectedSocialMedia && campaignBudget?.selectedChannels) {
            const channelsName = campaignBudget.selectedChannels.filter(
                (e) => selectedSocialMedia?.toLowerCase() === e.channel?.toLowerCase().replace(/ /g, "_")
            );
            const targetingMethods = channelsName.map((e) => e.name);
            setTargetingOptions(targetingMethods);
        }
    }, [selectedSocialMedia, campaignBudget?.selectedChannels ]);


    useEffect(() => {
        (formData && !formData?.startDate || !formData?.endDate) ? setNullDate(true) : setNullDate(false)
        if (formData && formData.startDate && formData.endDate) {
            const from = moment(formData.startDate);
            const to = moment(formData.endDate);
            const diff = to.diff(from, "days");
            // const diffFromLastData = from.diff(moment(lastDataStartDate), "days");

            let diffFromLastData = from.diff(lastDataStartDate, "days");
            for (let i = 0; i <= diffFromLastData; i++) {
                let currentDate = moment(lastDataStartDate).add(i, 'days');
                let dayOfWeek = currentDate.day();

                if (dayOfWeek === 6 || dayOfWeek === 0) {
                    diffFromLastData--;
                }
            }

            const campaignFrom = moment(campaignBudget?.startDate);
            const campaignTo = moment(campaignBudget?.endDate);
            const campDateDiff = campaignTo.diff(campaignFrom, "days");

            dispatch(
                settingCreativeData({
                    ...formData,
                    pendingFlightDays: diff / campDateDiff,
                    pendingDays: `${((diff / campDateDiff) * 100).toFixed(0)} %`,
                })
            );


            const isAfter =
                from.unix() >= moment(campaignBudget?.startDate).add(2, "days").unix();
            const isBefore = to.unix() <= moment(campaignBudget?.endDate).unix();

            if (lastDataStartDate) {
                if (diff > 0) {
                    setInValidDate(!(isBefore && isAfter));
                    setIsError(false)
                    setIsDiffError((diffFromLastData <= 2 && diffFromLastData >= -2) && (isBefore && isAfter));
                } else {
                    if (isAfter && isBefore) {
                        setInValidDate(false)
                    }
                    setIsError(true)
                    setIsDiffError(false)
                }
            }
        }


        dispatch(settingFormMedia(formData.creativeType));
        dispatch(settingEditMediaType(formData.creativeType));
    }, [formData, lastDataStartDate]);
    console.log("!formData.startDate && !formData.endDate", !formData.startDate && !formData.endDate)
    console.log("formData.startDate", formData.startDate)
    console.log("formData.endDate", formData.endDate)
    return (
        <div>
            {isUpcoming ? (
                <p
                    style={{
                        textAlign: "center",
                        marginTop: "360px",
                        fontSize: "1.4rem",
                    }}
                >
                    {" "}
                    The <b>{selectedSocialMedia}</b> type ad upload feature is currently
                    in the development phase and will be available soon.
                </p>
            ) : (
                <div>
                    <Box>
                        <Grid
                            templateColumns={{
                                base: "1fr",
                                md: "repeat(2, 1fr)",
                                lg: "repeat(4,1fr)",
                            }}
                            gap={6}
                        >

                            <GridItem colSpan={{ base: 2, md: 2 }}>
                                <Flex >
                                    <Heading mb={{ base: 0, md: 2 }}>
                                        {selectedSocialMedia && titleCase(selectedSocialMedia).replace(/_/g, " ")}
                                    </Heading>

                                </Flex>
                            </GridItem>
                            <GridItem colSpan={{ base: 4, md: 1 }}>
                                <FormControl isRequired>
                                    <FormLabel>Start Date</FormLabel>
                                    <Input
                                        placeholder="Start Date"
                                        fontSize={15}
                                        type="date"
                                        min={moment(campaignBudget?.startDate)
                                            .add(2, "days")
                                            .format("YYYY-MM-DD")}
                                        max={moment(campaignBudget?.endDate).format("YYYY-MM-DD")}
                                        name="startDate"
                                        value={formData?.startDate}
                                        onChange={(e) =>
                                            setFormData({ ...formData, startDate: e.target.value })
                                        }
                                    />
                                    {isError && (
                                        <Text color="red.400" fontSize="sm" mt={2}>
                                            Start date should be earlier then End date
                                        </Text>
                                    )}
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 4, md: 1 }}>
                                <FormControl isRequired>
                                    <FormLabel>End Date</FormLabel>
                                    <Input
                                        placeholder="End Date"
                                        name="endDate"
                                        type="date"
                                        min={moment(campaignBudget?.startDate)
                                            .add(2, "days")
                                            .format("YYYY-MM-DD")}
                                        max={moment(campaignBudget?.endDate).format("YYYY-MM-DD")}
                                        value={formData?.endDate}
                                        onChange={(e) =>
                                            setFormData({ ...formData, endDate: e.target.value })
                                        }
                                    />
                                    {isError && (
                                        <Text color="red.500" fontSize="sm" mt={2}>
                                            End date should be greater then Start date
                                        </Text>
                                    )}
                                </FormControl>
                            </GridItem>
                        </Grid>
                    </Box>
                    <Grid templateColumns={"repeat(1,1fr)"} gap={6}>
                        <GridItem colSpan={3} my={4}>
                            {invalidDate && (
                                <Text color="red.500" fontSize="sm" float={"right"}>
                                    {`Flight date range should be between the campaign's date range (${campaignBudget?.startDate} - ${campaignBudget?.endDate})`}
                                </Text>
                            )}
                            {isDiffError && (
                                <Text color="red.500" fontSize="sm" float={'right'}>
                                    {`There should be a 2-day gap(Excluding weekends) between the start dates of the previous ad(${lastDataStartDate ? lastDataStartDate : ''}) and the new ad.`}
                                </Text>
                            )}
                            {isSetNullDate && (
                                <Text color="red.500" fontSize="sm" float={'right'}>
                                    {`Please select start and end dates to proceed.`}
                                </Text>
                            )}
                        </GridItem>
                    </Grid>
                    <Box>
                        <Grid
                            templateColumns={{ base: "1fr", lg: "repeat(3, 1fr)" }}
                            gap={6}
                        >
                            <GridItem colSpan={{ base: 3, md: 3, lg: 1 }}>
                                <FormControl isRequired>
                                    <Flex gap={1}>
                                        <Image
                                            mt={1}
                                            src={`${process.env.PUBLIC_URL}/images/gender.svg`}
                                            height="18px"
                                        />
                                        <FormLabel>Creative type</FormLabel>
                                    </Flex>
                                    <Select
                                        id="creativeType"
                                        name="creativeType"
                                        placeholder="Select Creative Type"
                                        fontWeight={500}
                                        variant="outline"
                                        border="2px"
                                        borderRadius={1}
                                        borderColor="#E7E7E9"
                                        value={formData?.creativeType}
                                        onChange={(e) =>
                                            setFormData({ ...formData, creativeType: e.target.value })
                                        }
                                    >
                                        {creativeOptions.map((creative) => (
                                            <option
                                                className="campaign-option"
                                                key={creative.name}
                                                value={creative.type}
                                            >
                                                {creative.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            {/*<GridItem colSpan={{base: 0, md: 0, lg: 1}}></GridItem>*/}
                            <GridItem colSpan={{ base: 3, md: 3, lg: 1 }} mt={-2}>
                                <FormControl isRequired>
                                    <Flex gap={1} alignItems="center">
                                        <Image
                                            mt={1}
                                            src={`${process.env.PUBLIC_URL}/images/gender.svg`}
                                            height="18px"
                                        />
                                        <FormLabel>Targeting Method</FormLabel>
                                    </Flex>
                                    <MultiSelectInputBox
                                        name="targetingMethod"
                                        value={formData?.targetingMethod}
                                        options={targetingOptions?.map((el) => ({
                                            label: el,
                                            value: el,
                                        }))}
                                        placeholder={`-- Select One --`}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                targetingMethod: e.map((v) => v),
                                            });
                                        }}
                                    />
                                    {!formData?.targetingMethod?.length > 0 && (
                                        <Text color="red.500" fontSize="sm" mt={2}>
                                            Targeting Method is Required
                                        </Text>
                                    )}
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 3, lg: 1 }}>
                                <FormControl>
                                    <FormLabel>Promotion</FormLabel>
                                    <Input
                                        name="promotion"
                                        borderColor="#e7e7e9"
                                        value={formData?.promotion}
                                        onChange={(e) =>
                                            setFormData({ ...formData, promotion: e.target.value })
                                        }
                                        borderRadius={1}
                                        color={"#757998"}
                                        fontWeight={500}
                                    />
                                </FormControl>
                            </GridItem>
                        </Grid>
                    </Box>
                    <Flex width="100%" justify="flex-end" py={40}>
                        <Button
                            colorScheme="green"
                            variant="solid"
                            isDisabled={!(formData?.creativeType && formData?.targetingMethod?.length > 0 && formData.startDate && formData.endDate)}
                            onClick={nextStep}
                            borderRadius={0}
                            px={10}
                        >
                            Next
                        </Button>
                    </Flex>
                    {/*<div style={{ margin: "2rem 0" }}>*/}
                    {/*    {!formData?.targetingMethod?.length > 0 && formData.startDate && formData.endDate && <AdDetailsForm*/}
                    {/*        activeStep={activeStep}*/}
                    {/*        steps={steps}*/}
                    {/*        prevStep={prevStep}*/}
                    {/*        nextStep={nextStep}*/}
                    {/*        adCopyData={adCopyData}*/}
                    {/*        label="Ad Details"*/}

                    {/*    />*/}

                    {/*    }*/}
                    {/*</div>*/}
                </div>
            )}
        </div>
    );
};

export default CreativeDetails;
