import {CloseIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {Box, Button, Flex, Grid, Heading, Image, Input, Progress, Text, Tooltip} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import ReactPlayer from 'react-player';
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import {BLUE_BUTTON_COLOR, GREY_TEXT_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {FB_VIDEO_SIZE} from "../../../../../../../constant";

export function DynamicFacebookBothAdVideoUpload({values, setFieldValue, errors, touched, setErrors, onDrop, handleOnClose}) {
    const [fileURL, setFileURL] = useState([])

    useEffect(() => {
        if (values && values.length > 0) {
            const urls = values.map((video) => {
                return `${process.env.REACT_APP_API_URL}/uploads/${video?.file?.filename}`;
            });
            setFileURL(urls)
            setErrors('selectedVideos', {})
        }
    }, [values])

    const acceptVideoFiles = {
        "video/mov": [".mov"],
        "video/mp4": [".mp4"],
    };

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: (acceptedFiles, rejectedFiles) => {
            onDrop(acceptedFiles, rejectedFiles, "Video");
        },
        accept: acceptVideoFiles,
        maxSize: FB_VIDEO_SIZE,
        disabled: values?.length === 2,
    });

    return (
        <>
            <Box className="upload-main" mb={6}>
                <Grid border='2px'
                      borderColor={(errors.selectedVideos) ? 'red.500' : 'gray.200'}
                      padding="20px" borderStyle="dashed">
                    <Grid>
                        <div {...getRootProps()}>
                            <Input {...getInputProps()} />

                            <div className="file-upload-content">
                                <Image
                                    boxSize="100px"
                                    objectFit="cover"
                                    src={FileUploadIcon}
                                    alt="upload-icon"
                                />
                                <Heading
                                    fontSize="xl"
                                    display="flex"
                                    alignItems="center"
                                    color={GREY_TEXT_COLOR}
                                    mb={4}
                                >
                                    Drag video files to upload
                                    <Tooltip label={
                                        <Box>
                                            <Text color="#000"><b>Video file type:-</b> .mov or .mp4.</Text>
                                            <Text color="#000"><b>Ratio:-</b> 1:1 (for desktop or mobile) or 4:5 (for
                                                mobile only)</Text>
                                            <Text color="#000"><b>Video maximum file size:-</b> 4 GB</Text>
                                            <Text color="#000"><b>File name:-</b> Can't be longer than 50
                                                characters</Text>
                                            <Text color="#000"><b>Duration:-</b> 1 second to 241 minutes</Text>
                                        </Box>} closeDelay={500} bg='#e2e8f0'>
                                        <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                                    </Tooltip>
                                </Heading>
                                <Heading
                                    fontSize="sm"
                                    mb={4}
                                    color={TEXT_COLOR}
                                >
                                    - OR -
                                </Heading>
                                <Button bgColor={BLUE_BUTTON_COLOR} color="white" size="small" px={10} py={2}
                                        disabled={values && true} borderRadius={100}>Browse Files</Button>
                            </div>
                        </div>
                    </Grid>
                    {(errors.selectedVideos) &&
                    (<Text color="red.500" marginTop="2" textAlign={"center"}>
                        Video File is required
                    </Text>)
                    }
                </Grid>
                {values && values.length > 0 && (
                    <Grid className="file-box" mx={5}>
                        {values.map((video, index) => (
                            <React.Fragment key={index}>
                                <Flex marginTop="5" marginBottom="5" justifyContent='center'>
                                    <Box maxHeight='300px'>
                                        <ReactPlayer
                                            className='react-player'
                                            url={fileURL[index]}
                                            width="300px"
                                            height="170px"
                                            controls
                                        />
                                    </Box>
                                    <Grid>
                                        <CloseIcon
                                            marginLeft="-19px"
                                            zIndex="999"
                                            marginTop="4px"
                                            padding="2px"
                                            bg="rgba(255, 255, 255, 0.45)"
                                            cursor="pointer"
                                            className="close-icon"
                                            size="small"
                                            onClick={() => handleOnClose(index)}
                                        />
                                    </Grid>
                                </Flex>
                                <Box marginTop={"-20px"}>
                                    <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
                                    <Progress
                                        className="loading"
                                        value={100}
                                        colorScheme='green'
                                        size='sm'
                                    />
                                </Box>
                            </React.Fragment>
                        ))}
                    </Grid>
                )}
            </Box>

        </>
    );
}
