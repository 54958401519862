import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useCallback, useEffect} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import {
    AD_UPLOAD_TYPE,
    FACEBOOK_IMAGE_HEIGHT,
    FACEBOOK_IMAGE_WIDTH, INSTAGRAM_IMAGE_HEIGHT,
    INSTAGRAM_IMAGE_WIDTH
} from "../../../../../../../constant";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {settingDynamicInstagramMedia} from "./store";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import WithAppContext from "../../../../../../../helpers/appContext";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import DynamicInstagramImageAdFileUpload from "./DynamicInstagramImageAdFileUpload";


function DynamicInstagramImageAdMedia({activeStep, steps, prevStep, nextStep, label, context}) {
    const { adUploadId } = useParams()

    const { mediaDetails } = useSelector((state) => state.dynamicInstagramImageAd)

    const schema = yup.object().shape({
        selectedImages: yup.array().of(
            yup.object().shape({
                // thumbnailFile: yup.string().required('Thumbnail File is required'),
                // thumbnailURL: yup.string().required('thumbnail URL is required'),
            })
        ).min(1, 'At least one image must be selected'
        ).max(12, 'Maximum of 12 images can be selected'),
        // thumbnailURL: yup.object().required().nullable(),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            selectedImages: []
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingDynamicInstagramMedia({
                selectedImages: values?.selectedImages, ...values
            }))
            nextStep()
        })
    });
    const {setAppLoading, SuccessToaster, ErrorToaster,appLoading} = context;
    const clientId = useSelector((state) => state.client.clientId);
    const {id} = useParams();
    const {mutateAsync} = useUploadImage();

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setErrors,
        setFieldValue,
    } = formik;


    useEffect(() => {
        setFieldValue("selectedImages", mediaDetails?.selectedImages ? mediaDetails?.selectedImages : []);
        setFieldValue("thumbnailURL", mediaDetails?.thumbnailURL ? mediaDetails?.thumbnailURL : null)
        setFieldValue("thumbnailFile", mediaDetails?.thumbnailFile ? mediaDetails?.thumbnailFile: null);
    }, [mediaDetails])


    const onDrop = useCallback(async (accFiles) => {
        accFiles.map((file) => {
            const img = new Image()
            setAppLoading(true)
            img.onload = async () => {
                const {naturalWidth: width, naturalHeight: height} = img
                if (width >= INSTAGRAM_IMAGE_WIDTH && height >= INSTAGRAM_IMAGE_HEIGHT) {
                    try {
                        await mutateAsync(
                            {
                                clientId: clientId,
                                campaignBriefId: id,
                                adFile: accFiles,
                                type: "multipleFbAdImages",
                            }, {
                                onSuccess: (data) => {
                                    const dataHere = [...values.selectedImages, ...data];
                                    if (dataHere.length > 12) {
                                        ErrorToaster('Maximum of 12 images can be selected')
                                    } else {
                                        setFieldValue('selectedImages', dataHere);
                                        setFieldValue('thumbnailURL', data[0].file)
                                        setFieldValue('thumbnailFile', data[0])
                                        SuccessToaster('Image Upload Successfully...')
                                    }
                                    setAppLoading(false);
                                },
                                onError: () => {
                                    setAppLoading(false);
                                }
                            });
                    } catch (e) {
                        setAppLoading(false);
                    }
                } else {
                    setAppLoading(false);
                    ErrorToaster('1080 X 1080 pixel getter then height and width')
                }
            }
            img.src = URL.createObjectURL(file)
        })
    }, [values]);

    useEffect(() => {
        if (values.selectedImages.length === 0) {
            setErrors({"selectedImages": "At least one image must be selected"})
        }
    },[values])

    return (
        <>
            <Box className="file-upload-box">
                {appLoading && <FullPageLoader/>}

                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <DynamicInstagramImageAdFileUpload
                            getHashArray={(value) => {
                                setFieldValue('thumbnailURL', value)
                            }}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            type="facebookImage"
                            values={values.selectedImages}
                            setErrors={setErrors}
                            onDrop={onDrop}
                        />
                        <Flex width="100%" justify="flex-end" py={10}>
                            <Button
                                mr={4}
                                onClick={prevStep}
                                colorScheme='blue' variant='outline' borderRadius={0} px={10}
                            >
                                Back
                            </Button>
                            <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                                Next
                            </Button>
                        </Flex>
                    </Form>

                </FormikProvider>

            </Box>
        </>
    )
}

export default WithAppContext(DynamicInstagramImageAdMedia)
