import {
    Box, Button, Flex,
    Image, Tab, TabList, TabPanel, TabPanels, Tabs
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";
import {deleteTheField} from "../../../../../../../store/adUploads";
import YoutubeHome from "../components/YoutubeHome";
import Discover from "../components/Discover";
import YoutubeSkippable from "../components/YoutubeSkipable";
import YoutubeShort from "../components/YoutubeShort";
import {deleteAllDemandGenVideoFields} from "./store";
import {CreateMultipleCreatives} from "../../SocialDisplay/SocialDisplayImage/SocialDisplayImageAdPreview";

const DemandGenVideoAdPreview = ({activeStep, steps, prevStep, nextStep, label, context}) => {
    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const {id: campaignId, adUploadId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {
        access_info: {roles, clients: userClients},
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)
    const { allCampaignIds } = useSelector((state) => state.adUpload);

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    const {data, refetch} = useGetAdUpload(clientId, campaignId, adUploadId);

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
              `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    const {formDetails, mediaDetails} = useSelector((state) => state.demandGenVideoAd)
    const {creative, selectedSocialMedia} = useSelector((state) => state.adUpload)
    const {businessDetails} = useSelector((state)=> state.adCampaign)

    console.log("Okay formDetails", formDetails)

    const { youtubeVideoLink } = formDetails
    useEffect(() => {
        const imageUrls = youtubeVideoLink.map((item) => {
            let video_id = item.split('v=')[1];
            let ampersandPosition = video_id?.indexOf('&');
            if(ampersandPosition && ampersandPosition !== -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
            return {imageUrl: `https://img.youtube.com/vi/${video_id}/sddefault.jpg`}
        })
        setAds(imageUrls)
    }, [youtubeVideoLink]);

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if (adUploadId) {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
                    state: {isShowUpload: true}
                })
            }
            dispatch(deleteAllDemandGenVideoFields())
            dispatch(deleteTheField())
        } else {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`)
            }
            dispatch(deleteAllDemandGenVideoFields())
            dispatch(deleteTheField())
        }
    }

    async function handleSubmit() {
        setAppLoading(true);
        let payload = {
            name: formDetails.adName,
            description: formDetails.descriptions[0],
            title: formDetails.title,
            link: formDetails.link,
            detail: {
                name: formDetails.adName,
                headlines: formDetails.headlines,
                long_headlines: formDetails.long_headlines,
                descriptions: formDetails.descriptions,
                channel_name: formDetails.channel_name,
                channel_icon: formDetails.channelIcon,
                youtubeVideoLink: formDetails.youtubeVideoLink,
                call_to_action: {
                    type: formDetails?.type
                },
                link: formDetails.link,
                creative,
                channel: selectedSocialMedia
            },
        };

        if (!adUploadId) {
            payload = {
                ...payload,
                ad_upload_type: AD_UPLOAD_TYPE.DEMAND_GEN_VIDEO,
            };
        }

        if(allCampaignIds?.length){
            CreateMultipleCreatives(allCampaignIds, method, url, clientId, payload, setSuccessModal, setErrorModal, setAppLoading, setDescription, dispatch);
        } else {
            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSuccessModal(true);
                        setAppLoading(false)
                    }
                })
                .catch((error) => {
                    setDescription(error.response.data.message);
                    setErrorModal(true);
                    setAppLoading(false)
                });
        }
    }
    const handleIndex = i => setIndex(i)

    console.log('FormData', formDetails, mediaDetails)
    return (
      <>
          <Tabs isFitted variant='enclosed'>
              <TabList>
                  <Tab>
                      <Box align="center">
                          <Image src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="YouTube-Icon" height={8} width={8} margin={2}/>
                          <h1>YouTube</h1>
                      </Box>
                  </Tab>
                  <Tab>
                      <Box align="center">
                          <Image src={`${process.env.PUBLIC_URL}/images/discover.png`} alt="YouTube-Icon" height={8} width={8} margin={2}/>
                          <h1>Discover</h1>
                      </Box>
                  </Tab>
              </TabList>
              <TabPanels>
                  <TabPanel>
                      <Tabs>
                          <TabList align='end'>
                              <Tab>YouTube Home</Tab>
                              <Tab>YouTube Skippable</Tab>
                              <Tab>YouTube Shorts</Tab>
                          </TabList>
                          <TabPanels>
                              <TabPanel>
                                  <YoutubeHome
                                    formDetails={formDetails}
                                    appLoading={appLoading}
                                    ads={ads}
                                    index={index}
                                    handleIndex={handleIndex}
                                    businessName={businessDetails.name}
                                  />
                              </TabPanel>
                              <TabPanel>
                                  <YoutubeSkippable
                                    formDetails={formDetails}
                                    appLoading={appLoading}
                                    ads={ads}
                                    index={index}
                                    handleIndex={handleIndex}
                                    businessName={businessDetails.name}
                                  />
                              </TabPanel>
                              <TabPanel>
                                  <YoutubeShort
                                    formDetails={formDetails}
                                    appLoading={appLoading}
                                    ads={ads}
                                    index={index}
                                    handleIndex={handleIndex}
                                    businessName={businessDetails.name}
                                  />
                              </TabPanel>
                          </TabPanels>
                      </Tabs>
                  </TabPanel>
                  <TabPanel>
                      <Discover
                        formDetails={formDetails}
                        appLoading={appLoading}
                        ads={ads}
                        index={index}
                        handleIndex={handleIndex}
                        businessName={businessDetails.name}
                      />
                  </TabPanel>
              </TabPanels>
          </Tabs>
          <Flex width="100%" justify="flex-end" py={10}>
              <Button
                mr={4}
                onClick={prevStep}
                colorScheme='blue' variant='outline' borderRadius={0} px={10}
              >
                  Back
              </Button>
              <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                  {adUploadId ? 'Update' : 'Submit'}
              </Button>
          </Flex>
          <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={() => onCloseSuccessModal()}
          />
          <ErrorModal
            isOpen={isErrorModalOpen}
            onClose={() => setErrorModal(false)}
            description={description}
          />
      </>
    );
};

export default WithAppContext(DemandGenVideoAdPreview);
