import {Box, Button, css, Grid, GridItem, Heading, Image, useToast} from "@chakra-ui/react";
import React, {useCallback, useEffect, useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {facebookCarouselFileInitialValues} from "../../../../../constant/InitialValues";
import FacebookCarouselFileUpload from "./FacebookCarouselFileUpload";
import {settingFacebookCarouselMedia} from "./store";
import DefaultImageIcon from "../../../../../../../assets/images/image-default-icon.png";
import DefaultVideoIcon from "../../../../../../../assets/images/video-default-icon.png";
import {CloseIcon} from "@chakra-ui/icons";

const initialValue = {
    carouselCards: []
}

const validationSchema = yup.object().shape({
    carouselCards: yup.array().min(2).max(10).required().label("Carousel cards"),
});


function FacebookVideoAdMedia({activeStep, steps, prevStep, nextStep, label, context}) {
    const [formData, setFormData] = useState(initialValue)
    const { adUploadId } = useParams()
    const toast = useToast()

    const {id} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const navigate = useNavigate();
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [landingPageURL, setLandingPageURL] = useState("");
    const {mutateAsync} = useUploadImage();
    const [cardModalType, seCardModalType] = useState('image');
    const [isShowCardModal, setIsShowCardModal] = useState(false);
    const [initialModalData, setInitialModalData] = useState(facebookCarouselFileInitialValues);
    const [activeCarouselCardId, setActiveCarouselCardId] = useState(null);

    const { mediaDetails } = useSelector((state) => state.facebookCarouselAd)
    const [hashArray, setHashArray] = useState([]);
    const {setAppLoading, appLoading} = context;

    const dispatch = useDispatch();

    const createCarouselAd = (data) => {
        let CarouselCardsData = [...values.carouselCards]
        if (activeCarouselCardId !== null) {
            CarouselCardsData[activeCarouselCardId] = data
        } else {
            CarouselCardsData.push(data)
        }
        setFieldValue('carouselCards', CarouselCardsData)
        setActiveCarouselCardId(null)
    }

    const formik = useFormik({
        initialValues: formData,
        validationSchema: validationSchema,
        onSubmit: (values => {
            dispatch(settingFacebookCarouselMedia(values))
            nextStep()
        })
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
        setErrors,
    } = formik;

    useEffect(() => {
        setFieldValue('carouselCards', mediaDetails?.carouselCards ? mediaDetails?.carouselCards : []);
    },[mediaDetails])


    const onDelete = (id) => {
        let CarouselCardsData = [...values.carouselCards]
        CarouselCardsData.splice(id, 1)
        setFieldValue('carouselCards', CarouselCardsData)
    }



    return (
        <Box className="file-upload-box">
            {
                appLoading && <FullPageLoader />
            }
            {
                adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please review the ad details carefully.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            2 steps to complete
                        </Heading>
                    </Box>
                )
            }

            {
                !adUploadId && (
                    <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">
                        <Heading fontSize="xl" color={"#757998"}>
                            Please upload your ad creative.
                        </Heading>
                        <Heading fontSize="md" color="gray.400">
                            2 steps to complete
                        </Heading>
                    </Box>
                )
            }

            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <GridItem w='100%' justifyContent="end" display="flex" alignItems="center"
                              marginBottom={errors.carouselCards ? '8px' : "20px"}>
                        <Button variant='outline' onClick={() => {
                            seCardModalType('image')
                            setIsShowCardModal(true)
                        }}>Add Image Card</Button>
                        <Button variant='outline' marginLeft="10px" onClick={() => {
                            seCardModalType('video')
                            setIsShowCardModal(true)
                        }}>Add Video Card</Button>
                    </GridItem>
                    <Grid
                        w='100%'
                        gap={4}
                        marginBottom="20px"
                        className="fb-upload"
                        // templateColumns='repeat(8, 1fr)'
                        display="flex"
                        flexWrap="wrap"
                    >
                        {values.carouselCards && values.carouselCards.map((item, index) => {
                            let fileType = '';
                            let fileData = {};
                            fileType = item.fileType;
                            if(fileType === 'video') {
                                fileData = {
                                    videoURL: item.videoURL || item.videoFile,
                                    headline: item.headline || item.name,
                                    description: item.description,
                                    landingPageURL: item.landingPageURL || item.link,
                                    thumbnailURL: item.thumbnailURL || item.imageFile,
                                }
                            } else {
                                fileData = {
                                    imageURL: item.imageURL || item.imageFile,
                                    headline: item.headline || item.name,
                                    description: item.description,
                                    landingPageURL: item.landingPageURL || item.link,
                                }
                            }

                            return (
                                <Box key={index}
                                    //  backgroundColor="rgba(0, 0, 0, 0.39)"
                                     borderWidth='1px'
                                     width="110px" borderRadius="10px" display="flex" justifyContent="space-evenly"
                                     overflow="hidden">
                                    <Flex
                                        alignItems='center'
                                        onClick={() => {
                                            setInitialModalData(fileData)
                                            seCardModalType(fileType)
                                            setIsShowCardModal(true)
                                            setActiveCarouselCardId(index)
                                        }}>
                                        {fileType === 'image' &&
                                        <Image
                                            height="80px"
                                            width="80px"
                                            objectFit="contain"
                                            src={DefaultImageIcon}
                                            alt="Default Image Icon"
                                            cursor="pointer"
                                        />
                                        }
                                        {fileType === 'video' &&
                                        <Image
                                            height="80px"
                                            width="80px"
                                            objectFit="contain"
                                            src={DefaultVideoIcon}
                                            alt="Default Video Icon"
                                            cursor="pointer"
                                        />
                                        }
                                    </Flex>
                                    <CloseIcon
                                        marginTop="4px"
                                        padding="2px"
                                        bg="rgba(255, 255, 255, 0.45)"
                                        cursor="pointer"
                                        className="close-icon"
                                        size="small"
                                        onClick={() => {
                                            onDelete(
                                                index
                                            )
                                        }
                                        }
                                    />
                                </Box>
                            )
                        })}
                    </Grid>
                    <GridItem w='100%'
                              display="flex"
                              justifyContent="flex-end"
                              marginBottom="20px"
                              color="#e53e3e"
                              fontSize="14px"
                              css={css({
                                  float: "right",
                              })}
                    >
                        {errors.carouselCards && errors.carouselCards}
                    </GridItem>
                    <FacebookCarouselFileUpload
                        setHashArray={setHashArray}
                        hashArray={hashArray}
                        type="facebookCarousel"
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        cardModalType={cardModalType}
                        isShowCardModal={isShowCardModal}
                        setIsShowCardModal={setIsShowCardModal}
                        createCarouselAd={createCarouselAd}
                        initialModalData={initialModalData}
                        setInitialModalData={setInitialModalData}
                        activeCarouselCardId={activeCarouselCardId}
                        setActiveCarouselCardId={setActiveCarouselCardId}
                    />
                    <GridItem
                        w="full"
                        colSpan={{base: 6, lg: 2}}
                    >
                    </GridItem>
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}

                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                            Next
                        </Button>
                    </Flex>
                </Form>

            </FormikProvider>

        </Box>
    )
}

export default WithAppContext(FacebookVideoAdMedia)
