import {
    Box, Button,
    Container, Flex,
    Grid,
    GridItem,
    Heading,
    Image, Link, Spacer, Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PREVIEW_BG_COLOR, TEXT_COLOR, INDICATOR_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {deleteTheField} from "../../../../../../../store/adUploads/index"
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {settingPinterestVideoFormDetail,settingPinterestVideoFormMedia,settingPinterestVideoHandlePreview,deleteAllPinterestVideoFields} from "./store";
import ReactPlayer from "react-player";
import {FaAngleDown} from "react-icons/fa";
import {BsThreeDots} from "react-icons/bs";
import {RiUpload2Fill} from "react-icons/ri";
import {GrLink} from "react-icons/gr"
import _ from "lodash";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";
import {fetchVideoMetadata} from "../../LinkedIn/LinkedinVideo/LinkedInVideoAdPreview";
import {CreateMultipleCreatives} from "../../SocialDisplay/SocialDisplayImage/SocialDisplayImageAdPreview";


const PinterestVideoAdPreview = ({ activeStep, steps, prevStep, nextStep, label, context}) => {

    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const { id: campaignId, adUploadId } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)
    const { allCampaignIds } = useSelector((state) => state.adUpload);

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    useEffect(() => {
        // refetch()
    }, [])

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    // const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.adUpload)
    const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.pinterestVideoAd)
    const {creative,selectedSocialMedia} = useSelector((state)=> state.adUpload)

    const { fileInfoList} = formDetails


    useEffect(() => {
        setAds(handlePreviewData)
    }, [handlePreviewData]);


    const {mutateAsync} = useUploadImage();


    const onHandleSubmit = async (values) => {
        let videoData;
        let mediaInfoList = [];
        let mediaIds = [];
        if (_.isEqual(fileInfoList, values.fileURL)) {
            videoData = { audio: formDetails.audio };
        } else {
            try {
                const videoFile = await mutateAsync({
                    clientId: clientId,
                    campaignBriefId: campaignId,
                    adFile: values.fileURL,
                    type: "pinterest",
                });

                mediaInfoList = videoFile && await fetchVideoMetadata(videoFile.fileInfoList);

                console.log('mediaInfoList', mediaInfoList)
                mediaIds = videoFile && videoFile.fileInfoList.map((el) => el.imageHash);

                videoData = {
                    audio: {
                        mediaIds: mediaIds,
                        fileInfoList: mediaInfoList,
                    },
                };
            } catch (e) {
                setAppLoading(false);
            }
        }
        return {
            name: formDetails.adName,
            title: formDetails.title,
            description: formDetails.description,
            link: formDetails.link,
            detail: {
                name: formDetails.adName,
                title: formDetails.title,
                description: formDetails.description,
                link: formDetails.link,
                ...videoData,
                creative,
                channel: selectedSocialMedia
            },
        };
    };


    const navigate = useNavigate();
    const dispatch = useDispatch()


    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if(adUploadId) {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
                    state: {isShowUpload: true}
                })
            }
            dispatch(deleteAllPinterestVideoFields())
            dispatch(deleteTheField())
        }
        else {
            // navigate(`/campaign-briefs/${campaignId}`, {
            //     state: {isShowUpload: true}
            // })
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`)
            }
            dispatch(deleteAllPinterestVideoFields())
            dispatch(deleteTheField())
        }

    }

    async function handleSubmit() {
        try {
            setAppLoading(true);
            let payload = await onHandleSubmit(mediaDetails);
            if (!adUploadId) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.PINTEREST_VIDEO,
                };
            }

            if(allCampaignIds?.length){
                CreateMultipleCreatives(allCampaignIds, method, url, clientId, payload, setSuccessModal, setErrorModal, setAppLoading, setDescription, dispatch);
            } else {
                await instance({
                    method: method,
                    url: url,
                    withCredentials: false,
                    data: payload,
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setSuccessModal(true);
                            // dispatch(settingPinterestVideoFormDetail({}))
                            // dispatch(settingPinterestVideoFormMedia([]))
                            // dispatch(settingPinterestVideoHandlePreview(null))
                        }
                        setAppLoading(false);
                    })
                    .catch((error) => {
                        // setLandingPageURL(error.response.data.message);
                        setErrorModal(true);
                        setAppLoading(false);
                        setDescription(error?.response?.data?.message);

                    });
            }
        } catch (error) {
            setAppLoading(false);
        }
    }

    return (
        <>
            {/*{*/}
            {/*    adUploadId && (*/}
            {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
            {/*            <Heading fontSize="xl" color={"#757998"}>*/}
            {/*                Please review your ad details carefully.*/}
            {/*            </Heading>*/}
            {/*            <Heading fontSize="md" color="gray.400">*/}
            {/*                1 steps to complete*/}
            {/*            </Heading>*/}
            {/*        </Box>*/}
            {/*    )*/}
            {/*}*/}

            {/*{*/}
            {/*    !adUploadId && (*/}
            {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
            {/*            <Heading fontSize="xl" color={"#757998"}>*/}
            {/*                Please review your ad.*/}
            {/*            </Heading>*/}
            {/*            <Heading fontSize="md" color="gray.400">*/}
            {/*                1 steps to complete*/}
            {/*            </Heading>*/}
            {/*        </Box>*/}
            {/*    )*/}
            {/*}*/}

            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                {appLoading && <FullPageLoader />}
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                    {/*<Heading fontSize="lg" my={4} color={TEXT_COLOR}>*/}
                    {/*    {fileInfoList[0]?.filename}*/}
                    {/*</Heading>*/}
                    <Box
                        borderWidth="2px"
                        borderColor="gray"
                        p={10}
                        background={PREVIEW_BG_COLOR}
                        position={"relative"}
                    >
                        <Container maxWidth="container.lg" px={8}>
                            <Flex
                                flexDirection="row"
                                justify="stretch"
                                minHeight="md"
                            >
                                <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden">
                                    <ReactPlayer
                                        className='react-player pinterest-video'
                                        url={handlePreviewData}
                                        controls
                                        playing={true}
                                    />
                                </Box>
                                <Box flex={1} borderRightRadius={20} overflow="hidden" p={8} background="white">
                                    <div className="pinterest-header">
                                        <div className="header-left-icon">
                                            <BsThreeDots/>
                                            <RiUpload2Fill/>
                                            <GrLink/>
                                        </div>
                                        <div className="header-right">
                                            <div className="option">
                                                <span>Seo</span> <FaAngleDown/>
                                            </div>
                                            <div className="save">
                                                <span>Save</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Flex flexDirection="column" marginBottom={10} className="main">
                                        {formDetails?.link &&
                                        <Link mb={2} className="url" textDecoration="underline" color="#111"
                                              href={formDetails.link} isExternal>
                                            {formDetails.link}
                                        </Link>}
                                        {formDetails?.title && <Heading fontSize="4xl" className="heading" color="#111">
                                            {formDetails.title}
                                        </Heading>}
                                        {formDetails?.description &&
                                        <Heading fontSize="md" className="description" color="#111" fontWeight="normal">
                                            {formDetails.description}
                                        </Heading>}
                                    </Flex>
                                    <div className="account">
                                        <div className="account-profile">
                                            <img
                                                src="https://i.pinimg.com/75x75_RS/4a/df/5b/4adf5bd3dbd13f55a65d5d220791c97d.jpg"/>
                                            <span>User Name</span>
                                        </div>
                                        <button className="follow-button">Follow</button>
                                    </div>
                                    <div className="comment">
                                        <span>Comment</span> <FaAngleDown/>
                                    </div>
                                    <div className="comment-box">
                                        <div className="user-name">
                                            <span>P</span>
                                        </div>
                                        <div className="comment-input-box">
                                            <input type="text" placeholder="Add a comment"/>
                                        </div>
                                    </div>
                                </Box>
                            </Flex>
                        </Container>
                    </Box>

                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        {/*<Button mr={4} colorScheme='blue' variant='solid' borderRadius={0} px={10}>*/}
                        {/*    Save Draft*/}
                        {/*</Button>*/}
                        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                            { adUploadId ? 'Update' : 'Submit'}
                        </Button>
                    </Flex>

                </GridItem>
                <SuccessModal
                    isOpen={isSuccessModalOpen}
                    onClose={() => onCloseSuccessModal()}
                />
                <ErrorModal
                    isOpen={isErrorModalOpen}
                    onClose={() => setErrorModal(false)}
                    description={description}
                />
            </Grid>
        </>
    );
};

export default WithAppContext(PinterestVideoAdPreview);
