import React, {useEffect, useMemo, useState} from 'react';
import Datatable from "../../components/Datatable";
import {Button, Heading, Input, InputGroup, InputLeftElement, Stack} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";

function UTMDocument({ isLoading, downloadCreatives, data }) {
    const [creatives, setCreatives] = useState([])
    const [search, setSearch] = useState();

    useEffect(() => {
        if (search?.trim()) {
            const searchString = search.trim().toLowerCase()
            const searchedCreative = data.filter((el) => {
                if (el?.utm_document_data?.email?.toLowerCase().includes(searchString)) {
                    return true;
                } else if (el?.utm_document_data?.objective?.toLowerCase().includes(searchString)) {
                    return true;
                } else if (el?.utm_document_data?.creativeType?.toLowerCase().includes(searchString)) {
                    return true;
                }
            });
            setCreatives(searchedCreative);
        } else {
            setCreatives(data);
        }
    }, [search]);

    useEffect(() => {
        setCreatives(data)
    }, [data]);

    const columns = useMemo(
        () => [
            {
                Header: "Timestamp",
                accessor: "utm_document_data.timestamp",
            },
            {
                Header: "Ad Name",
                accessor: "utm_document_data.adName",
            },
            {
                Header: "Device",
                accessor: "utm_document_data.device",
            },
            {
                Header: "Email",
                accessor: "utm_document_data.email",
            },
            {
                Header: "Objective",
                accessor: "utm_document_data.objective",
            },
            {
                Header: "Creative Type",
                accessor: "utm_document_data.creativeType",
            },
            {
                Header: "Targeting Method",
                accessor: "utm_document_data.targetingMethod",
            },
            {
                Header: "Audience",
                accessor: "utm_document_data.audience",
            },
            {
                Header: "Promotion",
                accessor: "utm_document_data.promotion",
            },
            {
                Header: "Flight Start Date",
                accessor: "utm_document_data.flightStartDate",
            },
            {
                Header: "Flight End Date",
                accessor: "utm_document_data.flightEndDate",
            },
            {
                Header: "Landing Page",
                accessor: "utm_document_data.landingPage",
            },
            {
                Header: "Campaign Name",
                accessor: "utm_document_data.campaignName",
            },
            {
                Header: "UTM Url",
                accessor: "utm_document_data.utmUrl",
            },
        ],
        [data]
    );

    return (
        <div>
            <Heading
                color="#757998"
                fontWeight="600"
                fontFamily="Lora"
                size="lg"
                my={5}
            >
                UTM Document
            </Heading>
            <div className="search scrollable">
                <Stack direction="row" justifyContent="space-between" spacing={4} mt={6} mb={5}>
                    <InputGroup >
                        <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.300" ml={8}/>}
                        />
                        <Input
                            ml={2}
                            borderRadius={4}
                            name="search"
                            type="tel"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </InputGroup>
                    <Button style={{minWidth: "inherit"}}
                            color={"brand.primary"}
                            borderRadius={6}
                            variant={'outlined'}
                            borderColor={'brand.primary'}
                            borderWidth={2}
                            disabled={isLoading}
                            onClick={() => downloadCreatives("utc-format-download")}
                    >
                        {isLoading ? "Downloading..." : "Download"}
                    </Button>
                </Stack>
            </div>
            {creatives?.length !== 0 ? <Datatable data={creatives || []} columns={columns} headerColor={"brand.primary"} paginationDataCount={15}/> : <p style={{textAlign: 'center', marginTop:"360px", fontSize:"1.4rem"}}> Please add creatives to get detailed data </p>}
        </div>
    );
}

export default UTMDocument;
