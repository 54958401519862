import {
  Box,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import InputBox from "../../../../components/InputBox";
import { Checkbox } from "@chakra-ui/react";
import "../../style/CampaignDetails.css";
import { TbEqual } from "react-icons/tb";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { TEXT_COLOR } from "../../../../layout/constant/MenuList";

const CampaignDetails = ({ setFieldValue, values }) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [duration, setDuration] = useState(0);
  useEffect(() => {
    if (
      values?.detail?.campaignBasic?.startDate &&
      values?.detail?.campaignBasic?.endDate
    ) {
      const startDate = moment(values.detail.campaignBasic.startDate);
      const endDate = moment(values.detail.campaignBasic.endDate);
      const diff = endDate.diff(startDate, "days");
      setDuration(diff + 1);
      setFieldValue("detail.campaignBasic.duration", duration);
    }
  }, [
    values.detail.campaignBasic.startDate,
    values.detail.campaignBasic.endDate,
  ]);

  return (
    <Box p={4}>
      <Box mb={6} display="flex" justifyContent="space-between">
        <Heading fontSize="xl" color={TEXT_COLOR}>
          Campaign Details
        </Heading>
        <Heading fontSize="md" color="gray.400">
          7 steps to complete
        </Heading>
      </Box>
      {/*colSpan={windowSize.innerWidth >= "992" ? "2" : "0"}*/}
      <SimpleGrid
        className="flex-for-mobile"
        columns={{ base: 1, md: 2 }}
        gap={4}
      >
        <GridItem colSpan={2}>
          <InputBox
            name="detail.campaignBasic.campaignName"
            placeholder="Campaign Name (Required)"
            onChange={(e) => {
              setFieldValue(
                "detail.campaignBasic.campaignName",
                e.target.value
              );
              setFieldValue("name", e.target.value);
            }}
          />
        </GridItem>
        <GridItem>
          <InputBox
            name="detail.campaignBasic.startDate"
            label="Start Date (Required)"
            type="date"
            onChange={(e) => {
              setFieldValue("detail.campaignBasic.startDate", e.target.value);
              setFieldValue("startDate", e.target.value);
            }}
          />
        </GridItem>
        <GridItem>
          <InputBox
            name="detail.campaignBasic.endDate"
            label="End Date (Required)"
            type="date"
            onChange={(e) => {
              setFieldValue("detail.campaignBasic.endDate", e.target.value);
              setFieldValue("endDate", e.target.value);
            }}
          />
        </GridItem>
        <GridItem
          display="flex"
          className={"equal-none"}
          justifyContent="end"
          alignItems="center"
        >
          <TbEqual />
        </GridItem>
        <GridItem>
          <InputBox
            name="detail.campaignBasic.duration"
            placeholder="Duration (Days)"
            type="number"
            value={duration}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <InputBox
            name="detail.campaignBasic.websiteUrl"
            placeholder="Website URL (Required)"
            type="url"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Box className="url-item">
            <Box display={"flex"}>
              <FormLabel>Link to Landing Page Document</FormLabel>
              <Checkbox colorScheme="yellow"></Checkbox>
            </Box>
            <InputBox
              name="detail.campaignBasic.landingPageDocLink"
              type="url"
            />
          </Box>
          <Box className="url-item">
            <Box display={"flex"}>
              <FormLabel>Mobile Landing Page URL</FormLabel>
              <Checkbox colorScheme="yellow"></Checkbox>
            </Box>
            <InputBox
              name="detail.campaignBasic.landingPageMobileLink"
              type="url"
            />
          </Box>
          <Box className="url-item">
            <Box display={"flex"}>
              <FormLabel>Exit/Thank You Page URL</FormLabel>
              <Checkbox colorScheme="yellow"></Checkbox>
            </Box>
            <InputBox name="detail.campaignBasic.thankYouLink" type="url" />
          </Box>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};

export default CampaignDetails;
