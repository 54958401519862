import React, {useState} from 'react';
import {Button, HStack} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {FiEye} from "react-icons/fi";
import axios from "../../../../helpers/axios";
import {useSelector} from "react-redux";
import {ComingSoon_TYPES} from "../../../../constant";

function RowButton( { url,adName, adId,handleDeleteModel, data,  context, refetch } ) {
    const { SuccessToaster, ErrorToaster } = context;
    const clientId = useSelector((state) => state.client.clientId);
    const isLaunched = data?.row?.original?.is_launched;
    const [loading, setLoading] = useState(false)
    const adUploadType = data?.row?.original?.ad_upload_type;
    const isComingSoon = ComingSoon_TYPES.includes(adUploadType);

    function launchAd(adId, item) {
        console.log("Launch Ad id Working");
        setLoading(true);

        axios
            .post(`/client/${clientId}/${adId}/launchAd`, {
              withCredentials: false,
            })
            .then((res) => {
              if (res.status === 200 ) {
                  refetch();
                  SuccessToaster(res.data?.data?.message);
                  console.log("item?.data?.is_launched",item?.data)
              }
            })
            .catch((error) => {
                console.log("inside catch ")
              ErrorToaster(
                  error.response?.data?.message || "Something went wrong!"
              );
            })
            .finally(() => {
                setLoading(false);
            });
    }



    return (
        <div>
            <HStack>
                <Button
                    as={Link}
                    size="sm"
                    variant="outline"
                    aria-label="View details"
                    icon={<FiEye />}
                    to={url}
                    color="#3B82F6"
                    py={5}
                    w={100}
                >
                    View / Edit
                </Button>
                <Button
                    size="sm"
                    variant="outline"
                    aria-label="Delete"
                    icon={<FiEye />}
                    color="#FF8189"
                    py={5}
                    w={100}
                    onClick={() => handleDeleteModel(adName, adId)}
                >
                    Delete
                </Button>
                <Button
                    size="sm"
                    variant="outline"
                    aria-label="Launch Ad"
                    icon={<FiEye />}
                    color="green"
                    py={5}
                    w={100}
                    isLoading={loading}
                    isDisabled={isComingSoon ||  isLaunched}
                    onClick={() => launchAd(adId, data)}
                >
                    {isComingSoon ? "Coming Soon" : isLaunched ? "Launched" : "Launch Ad"}
                </Button>
            </HStack>
        </div>
    );
}

export default RowButton;
