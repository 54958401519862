import {
    Box,
    Button,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from "@chakra-ui/react";
import * as yup from "yup";
import {Form, FormikProvider, useFormik} from "formik";
import {CheckboxContainer, CheckboxControl, SelectControl} from "formik-chakra-ui";
import {useEffect, useState} from "react";
import {useGlobalContext} from "../store/WrapContext";
import {useGetExtensionData} from "../../../../../../../../hooks/campaign-briefs/useTableData";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import ExtensionEditForm from "./ExtensionEditForm";
import Loading from "../../../../../../../../components/Loading";

const extensionMapData = [
    {
        id: 1,
        text: "Buy a Park Pass",
        desp1: "Skip the Lines and Purchase Your",
        desp2: "Banff National Park Pass Online.",
        url: "https://www.banfflakelouise.com/park-pass-purchase"
    },
    {
        id: 2,
        text: "Things To Do",
        desp1: "Discover a Lifetime's Worth of",
        desp2: "Activities in Banff & Lake Louise.",
        url: "https://www.banfflakelouise.com/things-to-do"
    },
    {
        id: 3,
        text: "Things To Do Hello World",
        desp1: "Discover a Lifetime's Worth of",
        desp2: "Activities in Banff & Lake Louise.",
        url: "https://www.banfflakelouise.com/things-to-do"
    },
    {
        id: 4,
        text: "Things To Do",
        desp1: "Discover a Lifetime's Worth of",
        desp2: "Activities in Banff & Lake Louise.",
        url: "https://www.banfflakelouise.com/things-to-do"
    },
    {
        id: 5,
        text: "Things To Do Hello World",
        desp1: "Discover a Lifetime's Worth of",
        desp2: "Activities in Banff & Lake Louise.",
        url: "https://www.banfflakelouise.com/things-to-do"
    },
]

const extensionData = [
    {
        location: ["Bantt", "Bantt 2", "Paris", "Londan"],
        callout: ["Bantt Resturant", "Coffee"],
    }
]

function ExtensionModelContent({whichModel, onClose}) {

    const {data, setExtension, initialExtension, extension, setInitialExtensionData} = useGlobalContext()
    const [isLoading, setIsLoading] = useState(true)
    const {id, adUploadId: microsoftId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {
        data: ExtensionData,
        refetch
    } = useGetExtensionData(clientId, id, microsoftId, setInitialExtensionData, setIsLoading)
    const [isEditFormOpen, setIsEditFormOpen] = useState(false)
    const [editFormValue, setEditFormValue] = useState([])

    function onOpenExtensionEditForm(values) {
        setEditFormValue(values)
        setIsEditFormOpen(true)
    }

    useEffect(() => {
        refetch()
    }, [isEditFormOpen]);

    let initialValues = {
        call: extension.call.length > 0 ? extension.call : [],
        location: [],
        callout: extension.callout.length > 0 ? extension.callout : []
    };

    useEffect(() => {
        if (extension.callout.length > 0) {
            initialValues.callout = extension.callout
        }
    }, [extension]);

    const validationSchema = yup.object({
        call: yup.array(),
        location: yup.array(),
        callout: yup.array()
    });


    function onSubmit(values) {
        setExtension(values)
        onClose()
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    const {
        errors,
        values,
        isValid,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
        setErrors,
    } = formik;

    if (isLoading) {
        return (
            <ModalContent maxW="1200px">
                <Loading/>
            </ModalContent>
        )
    }


    return (
        <ModalContent maxW="1200px">
            <FormikProvider value={formik}>
                <Form>
                    <ModalHeader>{whichModel}</ModalHeader>
                    <ModalCloseButton/>
                    {
                        !isEditFormOpen && (
                            <>
                                <ModalBody>
                                    <Box display={"inline-flex"}>
                                        {
                                            initialExtension?.locationData?.length > 0 && (
                                                <CheckboxContainer marginBottom={4} name="location" label="Location">
                                                    {
                                                        initialExtension.locationData.map((item, index) => {
                                                            return (
                                                                <CheckboxControl key={index} name="location" value={item}>
                                                                    {item}
                                                                </CheckboxControl>
                                                            )
                                                        })
                                                    }
                                                </CheckboxContainer>
                                            )
                                        }
                                    </Box>

                                    <CheckboxContainer marginBottom={4} name="callout" label="Callout">
                                        {
                                            initialExtension?.calloutData?.map((item, index) => {
                                                return (
                                                    <CheckboxControl key={index} name="callout" value={item}>
                                                        {item}
                                                    </CheckboxControl>
                                                )
                                            })
                                        }
                                    </CheckboxContainer>
                                    <CheckboxContainer marginBottom={4} name="call" label="Call">
                                        {
                                            initialExtension?.callData?.map((item, index) => {
                                                return (
                                                    <CheckboxControl name="call" key={index} value={String(item)}>
                                                        {item}
                                                    </CheckboxControl>
                                                )
                                            })
                                        }
                                    </CheckboxContainer>
                                    <CheckboxContainer name="structured_snippet" label="Structured Snippet">
                                        {
                                            initialExtension?.structuredSnippetData?.map((item, index) => {
                                                return (
                                                    <CheckboxControl name="structured_snippet" key={index}
                                                                     value={String(item)}>
                                                        {item}
                                                    </CheckboxControl>
                                                )
                                            })
                                        }
                                    </CheckboxContainer>
                                    <Button marginTop={5}
                                            onClick={() => onOpenExtensionEditForm(initialExtension)}>Edit</Button>
                                </ModalBody>
                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>
                                    <Button variant='ghost' disabled={!isValid} type="submit">Add</Button>
                                </ModalFooter>
                            </>
                        )
                    }

                </Form>
            </FormikProvider>
            <ModalBody>
                {
                    isEditFormOpen &&
                    <ExtensionEditForm setIsEditFormOpen={setIsEditFormOpen} editFormValue={editFormValue}
                                       setEditFormValue={setEditFormValue}
                                       extensionId={initialExtension?.extensionId}/>
                }
            </ModalBody>

        </ModalContent>
    )
}

export default ExtensionModelContent
