import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
    handleFacebookPreviewData: null,
    handleInstagramPreviewData: null,
};

export const metaVideoSlice = createSlice({
    name: AD_UPLOAD_TYPE.META_VIDEO,
    initialState,
    reducers: {
        settingMetaVideoFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingMetaVideoFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingMetaVideoPreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        settingMetaInstagramVideoPreviewData: (state, action) => {
            state.handleInstagramPreviewData = action.payload
        },
        settingMetaFacebookVideoPreviewData: (state, action) => {
            state.handleFacebookPreviewData = action.payload
        },
        deleteAllMetaVideoField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null;
        }
    },
});

export const { settingMetaVideoFormDetail, settingMetaVideoFormMedia, settingMetaVideoPreviewData, deleteAllMetaVideoField, settingMetaFacebookVideoPreviewData, settingMetaInstagramVideoPreviewData} = metaVideoSlice.actions;

// The reducer
export const metaVideoReducer = metaVideoSlice.reducer;
