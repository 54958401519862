import { CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  Progress,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import calculateAspectRatios from "calculate-aspect-ratio";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactPlayer from "react-player";
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import {
  BLUE_BUTTON_COLOR,
  GREY_TEXT_COLOR,
  TEXT_COLOR,
} from "../../../../../../../layout/constant/MenuList";

export function SnapchatVideoAdFileUpload({
  values,
  setFieldValue,
  errors,
  touched,
  setErrors,
}) {
  const [fileURL, setFileURL] = useState("");
  const toast = useToast();

  useEffect(() => {
    if (values.fileURL) {
      console.log('values.fileURL', values.fileURL)
      let videoURL = values?.fileURL[0]?.imageUrl
        ? `${process.env.REACT_APP_API_URL}/uploads${values.fileURL[0]?.imageUrl}`
        : null;
      if (!videoURL) {
        console.log('values.fileURL', values.fileURL)
        videoURL = URL.createObjectURL(values.fileURL[0]);
      } else {
        // setErrors({})
      }
      setFileURL(videoURL);
    }
  }, [values]);

  const onDrop = useCallback(async (accFiles, rejFiles) => {
    accFiles.map((file) => {
      var fileType = file.type.split("/").shift();
      if (fileType === "video") {
        const objectURL = URL.createObjectURL(file);
        const mySound = new Audio([objectURL]);

        var video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          return video;
        };
        video.src = URL.createObjectURL(file);

        if (file) {
          mySound.addEventListener("canplaythrough", () => {
            const size = 1024 * 1024 * 1024;
            const ratio = calculateAspectRatios(
              video.videoHeight,
              video.videoWidth
            );
            const fileName =
              file.name.substring(0, file.name.lastIndexOf(".")) || file.name;
            if (
              (ratio === "16:9" || ratio === "1:1" || ratio === "9:16") &&
              file.size <= size
            ) {
              setFieldValue("fileURL", [file]);
            } else {
              toast({
                status: "error",
                variant: "top-accent",
                position: "top-right",
                description: "Please upload a valid video.",
              });
            }
          });
        }
      }
    });
  }, []);

  const acceptVideoFiles = {
    "video/avi": [".avi"],
    "video/mov": [".mov"],
    "video/mp4": [".mp4"],
    "video/m4v": [".m4v"],
    "video/mpeg": [".mpeg"],
    "video/mpg": [".mpg"],
    "video/ogv": [".ogv"],
    "video/webm": [".webm"],
    "video/wmv": [".wmv"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptVideoFiles,
    maxSize: 1024 * 1024 * 1024,
    disabled: values.fileURL && true,
  });

  return (
    <>
      <Box className="upload-main">
        <Grid
          padding="20px"
          border={"2px dashed"}
          borderColor={(errors.fileURL && touched.fileURL) ? "red.500" : "gray.200"}
        >
          <Grid>
            <div {...getRootProps()}>
              <Input {...getInputProps()} />

              <div className="file-upload-content">
                <Image
                  boxSize="100px"
                  objectFit="cover"
                  src={FileUploadIcon}
                  alt="upload-icon"
                />
                <Heading
                  fontSize="xl"
                  display="flex"
                  alignItems="center"
                  color={GREY_TEXT_COLOR}
                  my={4}
                >
                  Drag Video Files to Upload
                  <Tooltip
                    label={
                      <Box>
                        <Text color="#000">
                          <b>Video file type:-</b> .mov or .mp4.
                        </Text>
                        <Text color="#000">
                          <b>Ratio:-</b> 16:9, 1:1 or 9:16
                        </Text>
                        <Text color="#000">
                          <b>Video maximum file size:-</b> up to 500MB
                        </Text>
                        <Text color="#000">
                          <b>Length:-</b> 3-180 sec
                        </Text>
                      </Box>
                    }
                    closeDelay={500}
                    bg="#e2e8f0"
                  >
                    <InfoOutlineIcon
                      marginLeft="2"
                      height="15px"
                      width="15px"
                      marginTop="2px"
                    />
                  </Tooltip>
                </Heading>
                <Text fontSize="sm" mb={4} color={GREY_TEXT_COLOR}>
                  - OR -
                </Text>
                <Button
                  bgColor={BLUE_BUTTON_COLOR}
                  disabled={values.fileURL && true}
                  color="white"
                  size="small"
                  px={10}
                  py={2}
                  borderRadius={100}
                >
                  Browse Files
                </Button>
              </div>
            </div>
          </Grid>
          {errors.fileURL && (
            <Text color="red.500" marginTop="2" textAlign={"center"}>
              Video File is required
            </Text>
          )}
        </Grid>

        {values.fileURL && (
          <Box className="file-box" mx={5} paddingY={5}>
            <Box display={"flex"} marginTop="5" marginBottom="5" justifyContent="center">
              <ReactPlayer
                className="react-player"
                url={fileURL}
                width="300px"
                height="170px"
                controls
              />
              <Grid>
                <CloseIcon
                  marginLeft="-19px"
                  zIndex="999"
                  marginTop="4px"
                  padding="2px"
                  bg="rgba(255, 255, 255, 0.45)"
                  cursor="pointer"
                  className="close-icon"
                  size="small"
                  onClick={() => setFieldValue("fileURL", "")}
                />
              </Grid>
            </Box>
            <Text textAlign={"right"} display={"block"} color={"green"}>
              100% Completed
            </Text>
            <Progress className="loading" value={100} colorScheme="green" size="sm" />
          </Box>
        )}
      </Box>
    </>
  );
}
