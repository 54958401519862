import {Box, Button, Container, Flex, Grid, GridItem, Heading, Spacer, Text} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PREVIEW_BG_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {deleteTheField} from "../../../../../../../store/adUploads/index"
import {deleteAllYoutubeShortsFields} from "./store";
import ReactPlayer from "react-player";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";

const TiktokVideoAdPreview = ({ activeStep, steps, prevStep, nextStep, label, context}) => {

    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const { id: campaignId, adUploadId } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    const {handlePreviewData, formDetails} = useSelector((state) => state.youtubeShortsAd)
    const {creative,selectedSocialMedia} = useSelector((state)=> state.adUpload)

    useEffect(() => {
        setAds(handlePreviewData)
    }, [handlePreviewData]);

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if(adUploadId) {
            navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
                state: {isShowUpload: true}
            })
            dispatch(deleteAllYoutubeShortsFields())
            dispatch(deleteTheField())
        }
        else {
            navigate(`/campaign-briefs/${campaignId}/ad-upload`)
            dispatch(deleteAllYoutubeShortsFields())
            dispatch(deleteTheField())
        }
    }

    async function handleSubmit() {
        try {
            setAppLoading(true);
            let payload = {
                name: formDetails.adName,
                description: formDetails.description,
                detail: {
                    name: formDetails.adName,
                    headline: formDetails.headline,
                    long_headline: formDetails.long_headline,
                    description: formDetails.description,
                    channel_name: formDetails.channel_name,
                    channel_icon: formDetails.channelIcon,
                    youtubeVideoLink: formDetails.youtubeVideoLink,
                    call_to_action: {
                        type: formDetails?.type
                    },
                    link: formDetails.link,
                    creative,
                    channel: selectedSocialMedia
                },
            };
            if (!adUploadId) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.YOUTUBE_SHORTS_VIDEO,
                };
            }
            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSuccessModal(true);
                    }
                    setAppLoading(false);
                })
                .catch((error) => {
                    setErrorModal(true);
                    setDescription(error.response.data.message);
                    setAppLoading(false);
                });
        } catch (error) {
            setAppLoading(false);
        }
    }

    const getSlider = () => {
        if (formDetails.youtubeVideoLink?.length > 0) {
            return formDetails.youtubeVideoLink.map((el, ind) => (
                <Text
                    height={1}
                    borderRadius={5}
                    px={4}
                    mx={1}
                    mt={8}
                    key={ind}
                    background={ind === index ? "grey" : "silver"}
                    onClick={() => setIndex(ind)}
                    cursor="pointer"
                />
            ));
        }
    };

    console.log('Okay', formDetails.channelIcon)

    return (
        <>
            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                {appLoading && <FullPageLoader />}
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                    <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
                        {ads?.[index]?.filename}
                    </Heading>
                    <Box
                    borderWidth="2px"
                    borderColor="gray"
                    p={10}
                    background={PREVIEW_BG_COLOR}
                    >
                        <Flex
                            templateColumns="repeat(12, 1fr)"
                            justifyContent="space-between"
                        >
                            <Button
                                leftIcon={
                                    <ArrowBackIcon color="brand.primary" w={10} h={10}/>
                                }
                                py={8}
                                variant="ghost"
                                position={"absolute"}
                                top={"50%"}
                                style={{transform: "translateY(-50%)"}}
                                onClick={() => setIndex(index > 1 ? index - 1 : 0)}
                            />
                            <Spacer/>
                            <Box
                                display={"flex"}
                                position={"absolute"}
                                bottom={"11%"}
                                left={"52%"}
                                style={{transform: "translateX(-50%)"}}
                            >
                                {getSlider()}
                            </Box>
                            <Spacer/>
                            <Button
                                leftIcon={
                                    <ArrowForwardIcon color="brand.primary" w={10} h={10}/>
                                }
                                py={8}
                                variant="ghost"
                                position={"absolute"}
                                top={"50%"}
                                right={"12%"}
                                style={{transform: "translateY(-50%)"}}
                                onClick={() =>
                                    setIndex(
                                        formDetails.youtubeVideoLink && Object.keys(formDetails.youtubeVideoLink)?.length > index + 1
                                            ? index + 1
                                            : index
                                    )
                                }
                            />
                        </Flex>
                    <Container height="100%">
                        <Flex alignItems="center" flexDirection="row" justify="center">
                            <div className="youtube-shorts-container">
                                <ReactPlayer
                                    className='react-player videos'
                                    url={formDetails.youtubeVideoLink[index]}
                                    width={392}
                                    height={695}
                                    // playing={playingPreview}
                                    playing={true}
                                    loop={true}
                                    // onPlay={() => {
                                    //     setPlayingPreview(true)
                                    // }}
                                />
                                <div className="overlay-box">

                                </div>
                                <div className="youtube-shorts-rights">
                                    <div className="icons-items youtube-shorts-icons">
                                        <span className="icons">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`}
                                                alt=""
                                                id="likes-icons"
                                            />
                                        </span>
                                        <span className="icon-labels likes right-labels" data-likes="71.1k">71.1k</span>
                                    </div>
                                    <div className="icons-items youtube-shorts-icons">
                                        <span className="icons">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/thumbs-down.png`}
                                                alt=""
                                                id="likes-icons"
                                            />
                                        </span>
                                        <span className="icon-labels likes right-labels" data-likes="71.1k">71.1k</span>
                                    </div>
                                    <div className="icons-items youtube-shorts-icons">
                                        <span className="icons">
                                            <img src={`${process.env.PUBLIC_URL}/images/comments.png`} alt=""
                                                 id="comments-icons"/>
                                        </span>
                                        <span className="icon-labels comments right-labels"
                                              data-likes="1281">1281</span>
                                    </div>
                                    <div className="icons-items right-icons">
                                        <span className="icons">
                                            <img src={`${process.env.PUBLIC_URL}/images/share.png`} style={{ width: "30px", height: "30px" }}
                                                 alt=""/>
                                        </span>
                                        <span className="icon-labels shares right-labels">232</span>
                                    </div>
                                </div>
                                <div className="ads-details youtube-shorts-ads-details">
                                    <div style={{ display: "flex", gap: "10px", justifyItems: "center"}}>
                                        <div>
                                            <img
                                              src={`${formDetails?.channelIcon?.imageUrl ? process.env.REACT_APP_API_URL + "/uploads" + formDetails?.channelIcon?.imageUrl : "/images/display.png"}`}
                                              alt=""
                                              height={"35px"}
                                              width={"35px"}
                                            />
                                        </div>
                                        <div>
                                            <h1>{formDetails?.channel_name ? formDetails.channel_name : "Kedet"}</h1>
                                            <h6>Ad</h6>
                                        </div>
                                    </div>
                                    <h2>{formDetails.headline}</h2>
                                    <h2>{formDetails.long_headline}</h2>
                                    <h2>{formDetails?.description}</h2>
                                    <div className="youtube-shorts-bottom">
                                        <button>{youtubeShortsTypes.find((item) => item.key === formDetails?.type)?.name}</button>
                                    </div>
                                </div>
                            </div>
                        </Flex>
                    </Container>
                </Box>

                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        {/*<Button mr={4} colorScheme='blue' variant='solid' borderRadius={0} px={10}>*/}
                        {/*    Save Draft*/}
                        {/*</Button>*/}
                        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                            { adUploadId ? 'Update' : 'Submit'}
                        </Button>
                    </Flex>

                </GridItem>
                <SuccessModal
                    isOpen={isSuccessModalOpen}
                    onClose={() => onCloseSuccessModal()}
                />
                <ErrorModal
                    isOpen={isErrorModalOpen}
                    onClose={() => setErrorModal(false)}
                    description={description}
                />
            </Grid>
        </>
    );
};

export default WithAppContext(TiktokVideoAdPreview);
