// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-input {
  border-color: transparent !important;
  box-shadow: none !important;
}

.login-label {
  background: transparent !important;
}

.login-controller {
  border: 1px solid rgba(167, 169, 189, 0.25);
  border-left: 4px solid #3182ce;
  padding: 20px 0 6px 0;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/floatingStyle.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,2BAA2B;AAC7B;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,2CAA2C;EAC3C,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".login-input {\n  border-color: transparent !important;\n  box-shadow: none !important;\n}\n\n.login-label {\n  background: transparent !important;\n}\n\n.login-controller {\n  border: 1px solid rgba(167, 169, 189, 0.25);\n  border-left: 4px solid #3182ce;\n  padding: 20px 0 6px 0;\n}\n\ninput:-internal-autofill-selected {\n  background-color: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
