import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon, Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Form, FormikProvider, useFormik } from "formik";
import { InputControl, SelectControl } from "formik-chakra-ui";
import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import { useUpdateAdUploadStatus } from "../../../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import WithAppContext from "../../../../../../../helpers/appContext";
import {
  dv360AdUploadalueLengths,
  microsoftCSVInitialValue,
  googleCsvLengths,
  instagramImageAdInitialValue,
} from "../../../../../constant/InitialValues";
import { useGlobalContext } from "./store/WrapContext";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import {
  AD_UPLOAD_STATUS_COLOR,
  AD_UPLOAD_TYPE,
} from "../../../../../../../constant";
import instance from "../../../../../../../helpers/axios";
import { Flex } from "@chakra-ui/layout";
import TooltipComponent from "../../TooltipComponent";
import { useRecoilValue } from "recoil";
import { profile } from "../../../../../../../atoms/authAtom";

const MicrosoftCSVAdDetails = (props) => {
  const {
    data,
    url,
    method,
    campaignId,
    context,
    prevStep,
    nextStep,
  } = props;

  const { id, adUploadId: microsoftId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const navigate = useNavigate();
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [link, setlink] = useState("");
  const { mutate } = useUpdateAdUploadStatus();
  const { setAppLoading } = context;

  const { data: formData, refetch } = useGetAdUpload(clientId, id, microsoftId);
  const {creative, selectedSocialMedia} = useSelector((state)=> state.adUpload)
  const {
    access_info: { roles, clients: userClients },
  } = useRecoilValue(profile);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(roles.includes("Client"));
  }, [roles]);

  useEffect(() => {
    refetch();
  }, []);

  let schema = yup.object().shape({
    adName: yup.string().required("Ad Name is require"),
    siteLink: yup
      .mixed()
      .required("Sitelink file is required")
      .test("fileFormat", "CSV file only", (value) => {
        return value && ["text/csv"].includes(value.type);
      }),
    extension: yup
      .mixed()
      .required("Extension file is required")
      .test("fileFormat", "CSV file only", (value) => {
        return value && ["text/csv"].includes(value.type);
      }),
    master: yup
      .mixed()
      .required("Master file is required")
      .test("fileFormat", "CSV file only", (value) => {
        return value && ["text/csv"].includes(value.type);
      }),
  });

  // text/csv

  useEffect(() => {
    if (formData && formData?.adUpload?.id) {
      const { name } = data;
      setFieldValue("adName", name);
      delete schema.fields.siteLink;
      delete schema.fields.extension;
      delete schema.fields.master;
    }
  }, [formData]);

  const formik = useFormik({
    initialValues: microsoftCSVInitialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        setAppLoading(true);
        const detailData = {creative, channel: selectedSocialMedia}
        const formData = new FormData();
        formData.append("name", values.adName);
        formData.append("siteLink", values.siteLink);
        formData.append("extension", values.extension);
        formData.append("master", values.master);
        formData.append("ad_upload_type", AD_UPLOAD_TYPE.MICROSOFT_CSV);
        formData.append("detail", JSON.stringify(detailData));
        await instance({
          method: method,
          url: url,
          withCredentials: false,
          data: formData,
        })
          .then((res) => {
            if (res.status === 200) {
              setSuccessModal(true);
              setAppLoading(false);
            } else {
              setlink(res.data.message);
              setErrorModal(true);
            }
          })
          .catch((error) => {
            setlink(error.response.data.message);
            setErrorModal(true);
            setAppLoading(false);
          });
      } catch (error) {
          setlink(error);
          setErrorModal(true);
          setAppLoading(false);
      }
    },
  });

  const {
    errors,
    values,
    isValid,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setErrors,
  } = formik;

  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
        state: {isShowUpload: true},
    });
  };

  async function handleUpdate() {
    try {
      setAppLoading(true);
      const detailData = {creative, channel: selectedSocialMedia}
      await instance({
        method: method,
        url: url,
        withCredentials: false,
        data: {
          name: values.adName,
          detail: detailData,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            // setSuccessModal(true);
            setAppLoading(false);
            nextStep();
          }
        })
        .catch((error) => {
          setlink(error.response.data.message);
          setErrorModal(true);
          setAppLoading(false);
        });
    } catch (error) {
      setAppLoading(false);
    }
  }

  function sendSiteLink(data) {}

  function sendExtension(data) {}

  function sendMaster(data) {}

  return (
    <>
      <Grid className="fb-upload-detail-form">
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel htmlFor="adName" display={"none"}>
                Ad Name - max 512 characters
              </FormLabel>
              <div className="input-box">
                <InputControl
                  id="adName"
                  name="adName"
                  placeholder=""
                  style={{ width: "50%" }}
                  inputProps={{
                    variant: "outline",
                    type: "text",
                    placeholder: "Ad Name - max 512 characters",
                  }}
                  onBlur={handleBlur}
                />
                <TooltipComponent
                  text={
                    "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                  }
                />

                <CircularProgress
                  max={googleCsvLengths.adName}
                  value={values.adName.length}
                  color={
                    values.adName.length > googleCsvLengths.adName
                      ? "red.400"
                      : "green.400"
                  }
                >
                  <CircularProgressLabel>
                    {values.adName.length > googleCsvLengths.adName
                      ? googleCsvLengths.adName - values.adName.length
                      : values.adName.length}
                  </CircularProgressLabel>
                </CircularProgress>
              </div>
            </FormControl>
            {!microsoftId && (
              <>
                <FormControl>
                  <FormLabel htmlFor="adName">Site Link</FormLabel>
                  <Box mb={1}>
                    <input
                      className={
                        errors.siteLink && touched.siteLink ? "error" : null
                      }
                      type="file"
                      name="siteLink"
                      accept=".csv"
                      onChange={(event) => {
                        setFieldValue("siteLink", event.currentTarget.files[0]);
                        sendSiteLink(event.currentTarget.files[0]);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                    <Button
                        leftIcon={ <img src={`${process.env.PUBLIC_URL}/images/download.svg`} height={15} width={15}/>}
                        justifyContent={'center'}
                        mr={4}
                        variant="solid"
                        borderRadius={15}
                        px={5}
                        size={'sm'}
                    >
                      <Link href="/samples/sample_sitelink.csv" download>
                        Sample sitelink.csv
                      </Link>
                    </Button>
                  </Box>
                  {errors.siteLink && touched.siteLink ? (
                    <Text color="red.500">{errors.siteLink}</Text>
                  ) : null}
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="adName">Extension</FormLabel>
                  <Box mb={1}>
                    <input
                      className={
                        errors.extension && touched.extension ? "error" : null
                      }
                      type="file"
                      name="extension"
                      accept=".csv"
                      onChange={(event) => {
                        setFieldValue(
                          "extension",
                          event.currentTarget.files[0]
                        );
                        sendExtension(event.currentTarget.files[0]);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                    <Button
                        leftIcon={ <img src={`${process.env.PUBLIC_URL}/images/download.svg`} height={15} width={15}/>}
                        justifyContent={'center'}
                        mr={4}
                        variant="solid"
                        borderRadius={15}
                        px={5}
                        size={'sm'}
                    >
                      <Link href="/samples/sample_extension.csv" download>
                         Sample extension.csv
                      </Link>
                    </Button>
                  </Box>
                  {errors.extension && touched.extension ? (
                    <Text color="red.500">{errors.extension}</Text>
                  ) : null}
                </FormControl>
                <FormControl mb={5}>
                  <FormLabel htmlFor="adName">Master</FormLabel>
                  <Box mb={1}>
                    <input
                      className={
                        errors.master && touched.master ? "error" : null
                      }
                      type="file"
                      name="master"
                      accept=".csv"
                      onChange={(event) => {
                        setFieldValue("master", event.currentTarget.files[0]);
                        sendMaster(event.currentTarget.files[0]);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                    <Button
                        leftIcon={ <img src={`${process.env.PUBLIC_URL}/images/download.svg`} height={15} width={15}/>}
                        justifyContent={'center'}
                        mr={4}
                        variant="solid"
                        borderRadius={15}
                        px={5}
                        size={'sm'}
                    >
                      <Link href="/samples/sample_master.csv" download>
                         Sample master.csv
                      </Link>
                    </Button>
                  </Box>
                  {errors.master && touched.master ? (
                    <Text color="red.500">{errors.master}</Text>
                  ) : null}
                </FormControl>
              </>
            )}

            <Flex width="100%" justify="flex-end" py={10}>
              {!microsoftId && (
                <Button
                  mr={4}
                  onClick={prevStep}
                  colorScheme="blue"
                  variant="outline"
                  borderRadius={0}
                  px={10}
                >
                  Back
                </Button>
              )}
              {data?.id ? (
                <Button
                  mr={4}
                  colorScheme="green"
                  onClick={handleUpdate}
                  variant="solid"
                  borderRadius={0}
                  px={10}
                >
                  Next
                </Button>
              ) : (
                <Button
                  mr={4}
                  colorScheme="green"
                  type="submit"
                  variant="solid"
                  borderRadius={0}
                  px={10}
                >
                  Next
                </Button>
              )}
            </Flex>
          </Form>
        </FormikProvider>
      </Grid>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={link}
      />
    </>
  );
};

export default WithAppContext(MicrosoftCSVAdDetails);
