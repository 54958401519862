import {Flex} from "@chakra-ui/layout";
import {Button} from "@chakra-ui/react";
import React from "react";
import {useParams} from "react-router-dom";

function FormStepButton({errors, activeStep, steps, prevStep, nextStep}) {

        const { id } = useParams();

        return (
                <Flex width="100%" justify="flex-end" py={10}>
                    <Button
                        isDisabled={activeStep === 0}
                        mr={4}
                        onClick={prevStep}
                        colorScheme='blue' variant='outline' borderRadius={0} px={10}
                    >
                        Back
                    </Button>
                    {
                        !(activeStep === steps?.length - 1) && (
                            <Button mr={4} onClick={nextStep} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                                Next
                            </Button>
                        )
                    }
                    {
                        activeStep === steps?.length - 1 && (
                            <>
                                <Button
                                    mr={4}
                                    colorScheme='blue' variant='solid' borderRadius={0} px={10}
                                    display={id ? "none" : "block"}
                                >
                                    Save Draft
                                </Button>
                                <Button
                                    type="submit"
                                    mr={4}
                                    colorScheme='green' variant='solid' borderRadius={0} px={10}
                                >
                                    Submit
                                </Button>
                            </>
                        )
                    }
                </Flex>
        )
}

export default FormStepButton