import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const dynamicFacebookImageSlice = createSlice({
    name: AD_UPLOAD_TYPE.DYNAMIC_FACEBOOK,
    initialState,
    reducers: {
        settingDynamicFacebookImageFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingDynamicFacebookImageFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingDynamicFacebookImagePreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllDynamicFacebookImageField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingDynamicFacebookImageFormDetail, settingDynamicFacebookImageFormMedia, settingDynamicFacebookImagePreviewData, deleteAllDynamicFacebookImageField} = dynamicFacebookImageSlice.actions;

// The reducer
export const dynamicFacebookImageReducer = dynamicFacebookImageSlice.reducer;
