import {CloseIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {
    Box, Button,
    Flex, Grid, Heading, Image, Input, Progress, Text, Tooltip, useToast
} from "@chakra-ui/react";
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import ReactPlayer from 'react-player'
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import {BLUE_BUTTON_COLOR, GREY_TEXT_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {
    LINKEDIN_MAX_VIDEO_DURATION, LINKEDIN_MIN_VIDEO_DURATION,
    LINKEDIN_VIDEO_MAX_HEIGHT,
    LINKEDIN_VIDEO_MAX_WIDTH,
    LINKEDIN_VIDEO_MIN_HEIGHT, LINKEDIN_VIDEO_MIN_WIDTH,
    LINKEDIN_VIDEO_SIZE
} from "../../../../../../../constant";

export function LinkedInVideoAdFileUpload({
                                              values,
                                              setFieldValue,
                                              errors,
                                              touched,
                                              setErrors,
                                              playingDetails,
                                              setPlayingDetails,
                                          }) {
    const [fileURL, setFileURL] = useState('')
    const toast = useToast()

    useEffect(() => {
        if (values.fileURL && values.fileURL.length > 0) {
            console.log('values.fileURL', values)
            let videoURL = values?.fileURL[0]?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads${values.fileURL[0]?.imageUrl}` : null;
            if (!videoURL) {
                videoURL = URL.createObjectURL(values.fileURL[0])
            } else {
                // setErrors({})
            }
            setFileURL(videoURL)
        }
    }, [values])

    const onDrop = useCallback(async (accFiles, rejFiles) => {
        accFiles.map((file) => {
            var fileType = file.type.split("/").shift();
            if (fileType === "video") {
                const objectURL = URL.createObjectURL(file);
                const mySound = new Audio([objectURL]);

                var video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    return video
                }
                video.src = URL.createObjectURL(file);

                if (file) {
                    mySound.addEventListener("canplaythrough", () => {
                        const size = LINKEDIN_VIDEO_SIZE
                        const ratio = video.videoHeight / video.videoWidth;
                        const fileName = file.name?.substring(0, file.name.lastIndexOf('.')) || file.name;
                        if (ratio > 0.563 &&
                            ratio < 1.778 &&
                            video.videoWidth > LINKEDIN_VIDEO_MIN_WIDTH &&
                            video.videoWidth < LINKEDIN_VIDEO_MAX_WIDTH &&
                            video.videoHeight > LINKEDIN_VIDEO_MIN_HEIGHT &&
                            video.videoHeight < LINKEDIN_VIDEO_MAX_HEIGHT &&
                            video.duration > LINKEDIN_MIN_VIDEO_DURATION &&
                            video.duration < LINKEDIN_MAX_VIDEO_DURATION
                        ) {
                            setFieldValue('fileURL', [file])
                        } else {
                            toast({
                                status: 'error',
                                variant: 'top-accent',
                                position: 'top-right',
                                description: 'Please upload valid video with valid aspect ratio and duration.'
                            })
                        }
                    });
                }
            }
        });
        rejFiles.map((file) => {
            console.log('Rejected', file)
        })
    }, []);

    const acceptVideoFiles = {
        "video/mp4": [".mp4"],
    };

    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop,
        accept: acceptVideoFiles,
        maxSize: LINKEDIN_VIDEO_SIZE,
        disabled: values.fileURL && values.fileURL.length,
    });

    // dropZone Errors
    useEffect(() => {
        fileRejections && fileRejections.map(({errors}) => {
            errors.map((err) => {
                toast({
                    status: 'error',
                    variant: 'top-accent',
                    position: 'top-right',
                    description: err.code + ',' + err.message || 'Please upload valid video.'
                })
            })
        })
    }, [fileRejections])

    return (
        <>
            <Box className="upload-main">
                <Grid padding="20px" paddingY={"40px"} border={"2px dashed"}
                      borderColor={(errors.fileURL && touched.fileURL) ? 'red.500' : 'gray.200'}>
                    <Grid>
                        <div {...getRootProps()}>
                            <Input {...getInputProps()} />

                            <div className="file-upload-content">
                                <Image
                                    boxSize="100px"
                                    objectFit="cover"
                                    src={FileUploadIcon}
                                    alt="upload-icon"
                                />
                                <Heading
                                    fontSize="xl"
                                    display="flex"
                                    alignItems="center"
                                    color={GREY_TEXT_COLOR}
                                    my={4}
                                >
                                    Drag Video Files to Upload
                                    <Tooltip label={
                                        <Box>
                                            <Text color="#000"><b>Video file type:-</b> .mp4. </Text>
                                            <Text color="#000"><b>Ratio:-</b> Must be between 0.563 and 1.778</Text>
                                            <Text color="#000"><b>Video maximum file size:-</b> upto 200 MB</Text>
                                            <Text color="#000"><b>Video Duration:-</b> 3 seconds - 30 minutes </Text>
                                            <Text color="#000"><b>Video File Size:-</b> 75 KB (min) - 200 MB (max) </Text>
                                        </Box>} closeDelay={500} bg='#e2e8f0'>
                                        <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                                    </Tooltip>
                                </Heading>
                                <Text
                                    fontSize="sm"
                                    mb={4}
                                    color={GREY_TEXT_COLOR}
                                >
                                    - OR -
                                </Text>
                                <Button bgColor={BLUE_BUTTON_COLOR} color="white" size="small" px={10} py={2}
                                        borderRadius={100}>Browse Files</Button>
                            </div>
                        </div>
                    </Grid>
                    {(errors.fileURL) &&
                    <Text color="red.500" marginTop="2" textAlign="center">
                        Video File is required
                    </Text>
                    }
                </Grid>

                {values.fileURL && values.fileURL.length > 0 &&
                <Box alignItems={"center"} gap={"16px"} px={10} className="file-box" mx={5}>
                    <Box display={"flex"} marginTop="5" marginBottom="5" justifyContent='center' position={"relative"}>
                        <ReactPlayer
                            className='react-player'
                            url={fileURL}
                            width="300px"
                            height="170px"
                            controls
                            playing={playingDetails}
                            onPlay={() => {
                                setPlayingDetails(true)
                            }}
                        />
                        <Grid>
                            <CloseIcon
                                marginLeft="-19px"
                                zIndex="999"
                                marginTop="4px"
                                padding="2px"
                                bg="rgba(255, 255, 255, 0.45)"
                                cursor="pointer"
                                className="close-icon"
                                size="small"
                                onClick={() => setFieldValue('fileURL', '')}
                            />
                        </Grid>
                    </Box>
                    <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
                    <Progress
                        className="loading"
                        value={100}
                        colorScheme='green'
                        size='sm'
                    />

                </Box>

                }

            </Box>

        </>
    );
}
