import React from 'react';
import {Heading} from "@chakra-ui/react";
import {TEXT_COLOR} from "../../../layout/constant/MenuList";
import Plot from "react-plotly.js";

function PieChart({ result }) {
    if (!result || result.length === 0 || Object.keys(result[0]).length < 2) return null;

    const labels = result.map(row => row[Object.keys(row)[0]]);
    const data = result.map(row => row[Object.keys(row)[1]]);

    return (
        <div>
            <Heading fontSize="md" my={4} color={TEXT_COLOR}>
                Pie Chart Visualization
            </Heading>
            <Plot
                data={[
                    {
                        labels: labels,
                        values: data,
                        type: 'pie'
                    }
                ]}
                layout={{title: 'Pie Chart'}}
            />
        </div>
    );
}

export default PieChart;
