import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  SkeletonCircle,
  SkeletonText, Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";

function YoutubeHome({formDetails, appLoading, ads, index, handleIndex, businessName}) {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%'>
                <Box width="100%" height="65%" borderTop="1px solid gray.300" borderBottom="1px solid gray.300"
                     boxShadow='sm' bg='white'>
                  <Flex display="flex" gap="5" padding='6' height="50%" bg="gray.300" position="relative" borderTopRadius="25px">
                    <Image src={`${process.env.PUBLIC_URL}/images/youtube_icon.svg`} height="50px" width="50px" color="#0077ff"
                    position="absolute" top="40%" left="43%"
                    />
                  </Flex>
                  <Box mt={2} height="50%">
                    {(ads && ads.length !== 0) ?
                      <Box overflow="hidden" width={"100%"} height={"100%"}>
                        <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                                onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                          {
                            ads.map((item) => {
                              return (
                                <SwiperSlide>
                                  <Image
                                    height="100%"
                                    width={"100%"}
                                    objectFit={"fill"}
                                    src={item?.imageUrl}
                                    className="previewImage"
                                  />
                                </SwiperSlide>
                              )
                            })
                          }
                        </Swiper>
                      </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                    }
                  </Box>
                  <Flex width="100%" bg="#c3ddf1" justifyContent="space-between" px={2} py={1} cursor="pointer"
                        _hover={{textDecoration: 'underline'}}>
                    <Text color="#0077ff"
                          fontWeight="700">{youtubeShortsTypes.find((i) => i.key === formDetails.call_to_action?.type)?.name}</Text>
                    <Image src={`${process.env.PUBLIC_URL}/images/share_icon.svg`} height="20px" width="20px" color="#0077ff"/>
                  </Flex>
                  <Flex justifyContent="space-between" mx={3} my={2}>
                    <Box fontWeight="600"> {Array.isArray(formDetails.headlines) ? formDetails.headlines[0] : formDetails.headline} </Box>
                    <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Back Button" height={4} color="red"
                           width={4}/>
                  </Flex>
                  <Box mx={3} my={1} fontSize="14px"> {Array.isArray(formDetails.descriptions) ? formDetails.descriptions[0] : formDetails.description} </Box>
                  <Flex justifyItems="baseline" my={2}>
                    <Box mx={3} fontWeight="bolder" fontSize="14px"> Ad </Box>
                    <Box fontSize="14px"><li>{businessName}</li></Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default YoutubeHome;
