import React, {useState} from 'react';
import {
  Box,
  Button,
  Textarea,
  Input,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  CloseButton,
  VStack, Flex, Text, css, Grid, Image, Spacer, Progress,
} from '@chakra-ui/react';
import instance from "../helpers/axios";
import {useSelector} from "react-redux";
import {useRecoilValue} from "recoil";
import {profile} from "../atoms/authAtom";
import {Form, FormikProvider, useFormik} from "formik";
import {googleCSVInitialValue} from "../pages/CampaignBriefs/constant/InitialValues";
import {AD_UPLOAD_TYPE} from "../constant";
import * as yup from "yup";
import {RadioGroupControl, TextareaControl} from "formik-chakra-ui";
import WithAppContext from "../helpers/appContext";
import DefaultIcon from "../assets/images/jpg.svg";
import {CloseIcon, DownloadIcon} from "@chakra-ui/icons";

const HelpForm = ({onClose, context}) => {
  const [formHeight, setFormHeight] = useState('auto'); // Dynamically set form height
  const [imageError, setImageError] = useState(null);
  const clientId = useSelector((state) => state.client.clientId);
  const {id: userId} = useRecoilValue(profile);
  const {setAppLoading, SuccessToaster, ErrorToaster, appLoading} = context;

  const feedbackInitialValues = {
    message: '',
    reporting_issue_type: '',
    screenshots: [],
  }

  const schema = yup.object().shape({
    message: yup.string().trim().min(3).max(125).required().label("Issue message"),
    reporting_issue_type: yup.string().trim().required().label("Issue type"),
    screenshots: yup.array().optional().label("screenshots"),
  });

  const formik = useFormik({
    initialValues: feedbackInitialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        // Prepare form data
        const formData = new FormData();
        formData.append('client_id', clientId);
        formData.append('user_id', userId);
        formData.append('message', values.message);
        formData.append('reporting_issue_type', values.reporting_issue_type);
        await values.screenshots.forEach((item => {
          const el = item.file ? item.file : item;
          formData.append("screenshots", el);
        }))

        // Send form data to backend endpoint
        await instance({
          method: "POST",
          url: '/feedback',
          withCredentials: false,
          data: formData
        })
          .then((res) => {
            SuccessToaster("Feedback submitted successfully.");
          })
          .catch((error) => {
            console.log('Error', error)
            ErrorToaster(error?.response?.data?.message || "Something went wrong.");
          });

        onClose();
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle error, display error message to the user, etc.
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isValid,
    setFieldValue,
  } = formik;

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const selectedFiles = Array.from(files);
    if((selectedFiles.length + values.screenshots.length) > 3){
      return setImageError("Please select a maximum of 3 files");
    }else {
      // Check file types
      const invalidFiles = selectedFiles.filter(file => !file.type.includes('image/jpeg') && !file.type.includes('image/png'));
      if (invalidFiles.length > 0) {
        setImageError("Only .jpg and .png files are allowed. Please select a valid file.");
      }
    

    else {
      setImageError(null);
      setFieldValue('screenshots', [...selectedFiles, ...values.screenshots]);
    }
  }
  };

  const removeImage = (index) => {
    const newScreenshots = values.screenshots.filter((image, i) => i !== index);
    setFieldValue('screenshots', newScreenshots)
  }

  return (
    <Box
      position="relative"
      p="4"
      bg="white"
      borderRadius="md"
      maxHeight={formHeight} // Set the form height dynamically
      overflowY="auto"
    >
      <CloseButton
        position="absolute"
        top="1"
        right="1"
        size="sm"
        onClick={onClose}
        mt="1"
        mr="1"
        zIndex="1"
      />

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <VStack align={'end'} spacing="4" ml="4">
            <FormControl>
              <FormLabel>Tell us what you need help with:</FormLabel>
              <TextareaControl
                id={'message'}
                name={'message'}
                size="md"
                value={values.message}
                textareaProps={{
                  variant: "outline",
                  type: "text",
                  placeholder: "Example: I'm having trouble accessing the reports."
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Choose the best description of this issue:</FormLabel>
              <RadioGroupControl value={values.reporting_issue_type} name='reporting_issue_type'>
                <Flex gap={3}>
                  <Radio value="reporting">Reporting Issue</Radio>
                  <Radio value="others">Other</Radio>
                </Flex>
              </RadioGroupControl>
            </FormControl>

            {values.reporting_issue_type === 'reporting' && (
              <>
                <Box fontStyle="italic" color="gray.500" fontSize="sm">
                  Suggested because you selected: "Reporting Issue"
                </Box>
                <Box color="gray.700" ml="4" fontSize="sm"> {/* Add margin for consistent spacing */}
                  <p><u>Troubleshooting Steps:</u></p>
                  <ul>
                    <li>For Windows users: Hold down Ctrl and then press F5 on your keyboard.</li>
                    <li>For Mac users: Hold down Cmd and Shift and then press R on your keyboard.</li>
                  </ul>
                  <br/>
                  <p><u>Login via New Incognito Tab:</u></p>
                  <ul>
                    <li>Click on the three vertical/horizontal points on the top right of your browser.</li>
                    <li>Select "New Incognito Tab" from the menu.</li>
                  </ul>
                </Box>
              </>
            )}

            <FormControl>
              <FormLabel>Attach files (.jpg and .png only)</FormLabel>
              <Input
                padding={'3px'}
                size={'sm'}
                multiple
                type="file"
                accept=".jpeg, .jpg, .png"
                onChange={handleFileSelect}
                isDisabled={values.screenshots.length === 3}
              />
              {imageError ? (
                  <Text color="red.500" marginTop="1" css={css({
                    fontStyle: "normal",
                    fontSize: "13px",
                  })}>
                    {imageError}
                  </Text>
              ) : null}
            </FormControl>

            <FormControl>
              <Flex gap={3} wrap={'wrap'}>
                {values.screenshots.length > 0 &&
                values.screenshots.map((item, index) => {
                  return (
                      <Box>
                        <Flex marginTop="5">
                          <Box>

                            <Image
                                boxSize="50px"
                                objectFit="cover"
                                src={DefaultIcon}
                                alt="Dan Abramov"
                            />
                          </Box>
                          <Grid marginLeft="2">
                            <Grid>
                              <Text
                                  fontSize="15px"
                                  textOverflow="ellipsis"
                                  overflow='hidden'
                                  whiteSpace="nowrap"
                                  className="file-name"
                              >
                                {item.name}
                              </Text>
                            </Grid>
                          </Grid>
                          <Spacer />
                          <Grid>
                            <Grid justifyContent="center">
                              <CloseIcon
                                  cursor="pointer"
                                  className="close-icon"
                                  size="small"
                                  onClick={() => removeImage(index)}
                              />
                            </Grid>
                          </Grid>
                        </Flex>
                      </Box >
                  )
                })
                }
              </Flex>
            </FormControl>
            <FormControl>
              <Button type='submit' colorScheme="blue">
                Submit
              </Button>
            </FormControl>
          </VStack>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default WithAppContext(HelpForm);
