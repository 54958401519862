// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-main{
    width: 450px;
    max-height: 500px;
    overflow: auto;
}

.review-btn{
    padding: 6px 14px;
    border-radius: 4px;
    background: #3B82F6;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/layout/components/notificationStyle.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".notification-main{\n    width: 450px;\n    max-height: 500px;\n    overflow: auto;\n}\n\n.review-btn{\n    padding: 6px 14px;\n    border-radius: 4px;\n    background: #3B82F6;\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
