import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {Form, FormikProvider, useFormik} from "formik";
import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import {InputControl, SelectControl} from "formik-chakra-ui";
import {facebookVideoAdValueLengths,} from "../../../../../constant/InitialValues";
import {facebookAccountIds,} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {settingDynamicFacebookBothFormDetail, settingDynamicFacebookBothFormMedia,} from "./store";
import TooltipComponent from "../../TooltipComponent";
import {Input} from "@chakra-ui/input";

const initialValue = {
    adName: "",
    // primaryText: primaryTextInputs,
    primaryText: [""],
    // description: "",
    // headline: "",
    landingPageURL: "",
    facebookAccountId: "",
};

const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    primaryText: yup
        .array()
        .of(
            yup.string().trim().min(3).max(125).required().label("Primary text")
        )
        .min(1, "At least one primary text is required")
        .max(5, "Maximum of 5 primary texts allowed")
        .required()
        .label("Primary Text"),
    // headline: yup.string().trim().min(3).max(32).required().label("Headline"),
    // description: yup
    //   .string()
    //   .trim()
    //   .min(3)
    //   .max(125)
    //   .required()
    //   .label("Description"),
    headlines: yup
        .array()
        .of(
            yup.string().required().label("Headline")
        )
        .required()
        .label("Headline"),
    descriptions: yup
        .array()
        .of(
            yup.string().required().label("Description")
        )
        .required()
        .label("Description"),
    facebookAccountId: yup.string().required().label("Type"),
    landingPageURL: yup
        .string()
        .trim()
        .required("Landing page url is required")
        .max(255)
        .matches(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
            "Landing page url is not valid!"
        )
        .label("Landing page url"),
});

function DynamicFacebookBothAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.dynamicFacebookBothAd);
  const [primaryTextInputs, setPrimaryTextInputs] = useState([""]);
  const [headlineInputs, setHeadlineInputs] = useState([""]);
  const [descriptionInputs, setDescriptionInputs] = useState([""]);
  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);


  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);


  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingDynamicFacebookBothFormDetail({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.name,
          // description: data?.adUpload?.detail?.link_description,
          descriptions: data?.adUpload?.detail?.descriptions,
          primaryText: data?.adUpload?.detail?.message,
          // headline: data?.adUpload?.detail?.headline,
          headlines: data?.adUpload?.detail?.headlines,
          landingPageURL: data?.adUpload?.detail?.call_to_action?.value?.link,
          facebookAccountId: data?.adUpload?.detail?.call_to_action?.type,
        })
      );
      dispatch(
        settingDynamicFacebookBothFormMedia({
          selectedVideos: data?.adUpload?.detail?.selectedVideos || [],
          selectedThumbnails: data?.adUpload?.detail?.selectedThumbnails || [],
          selectedImages: data?.adUpload?.detail?.selectedImages || [],
        })
      );
    } else {
      dispatch(
          settingDynamicFacebookBothFormMedia({
            selectedVideos: [],
            selectedThumbnails: [],
            selectedImages: [],
          })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        settingDynamicFacebookBothFormDetail({ ...data?.adUpload?.detail, ...values })
      );
      nextStep();
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isValid,
    handleBlur,
    setFieldValue,
  } = formik;

  console.log('Form Details', formDetails);
  useEffect(() => {
    setFieldValue("adName", formDetails?.adName || "");
    setFieldValue("primaryText", formDetails?.primaryText || []);
    // setFieldValue("headline", formDetails?.headline || "");
    // setFieldValue("description", formDetails?.description || "");
    setFieldValue("headlines", formDetails.headlines || autoFillData?.headlines || [""]);
    setFieldValue("descriptions", formDetails.descriptions || autoFillData?.descriptions || [""]);
    setFieldValue("landingPageURL", formDetails?.landingPageURL || "");
    setFieldValue("type", formDetails?.type || "");
    setFieldValue("facebookAccountId", formDetails?.facebookAccountId || "");
    setPrimaryTextInputs(formDetails?.primaryText || [""])
    setDescriptionInputs(formDetails.descriptions || [""])
    setHeadlineInputs(formDetails.headlines || [""])
  }, [formDetails, autoFillData]);

  const handlePrimaryTextChange = (e, index) => {
    const newInputs = [...primaryTextInputs];
    newInputs[index] = e.target.value;
    setPrimaryTextInputs(newInputs);
  };

  const handleAddPrimaryTextInput = () => {
    if (primaryTextInputs?.length < 5) {
      setPrimaryTextInputs([...primaryTextInputs, ""]);
    }
  };

  const handleRemovePrimaryTextInput = (indexToRemove) => {
    const updatedInputs = primaryTextInputs.filter((_, index) => index !== indexToRemove);
    setPrimaryTextInputs(updatedInputs);
  };

    const handleHeadlineChange = (e, index) => {
        const newInputs = [...headlineInputs];
        newInputs[index] = e.target.value;
        setHeadlineInputs(newInputs);
    };

    const handleAddHeadlineInput = () => {
        if (headlineInputs.length < 5) {
            setHeadlineInputs([...headlineInputs, ""]);
        }
    };

    const handleRemoveHeadlineInput = (indexToRemove) => {
        const updatedInputs = headlineInputs.filter((_, index) => index !== indexToRemove);
        setHeadlineInputs(updatedInputs);
    };

    const handleDescriptionChange = (e, index) => {
        const newInputs = [...descriptionInputs];
        newInputs[index] = e.target.value;
        setDescriptionInputs(newInputs);
    };

    const handleAddDescriptionInput = () => {
        if (descriptionInputs.length < 5) {
            setDescriptionInputs([...descriptionInputs, ""]);
        }
    };

    const handleRemoveDescriptionInput = (indexToRemove) => {
        const updatedInputs = descriptionInputs.filter((_, index) => index !== indexToRemove);
        setDescriptionInputs(updatedInputs);
    };

  useEffect(() => {
    setFieldValue("primaryText", primaryTextInputs);
  }, [primaryTextInputs]);

    useEffect(() => {
        setFieldValue("headlines", headlineInputs);
    }, [headlineInputs]);

    useEffect(() => {
        setFieldValue("descriptions", descriptionInputs);
    }, [descriptionInputs]);

  return (
    <>

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 512 characters",
                      }}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={facebookVideoAdValueLengths?.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        facebookVideoAdValueLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        facebookVideoAdValueLengths?.adName
                          ? facebookVideoAdValueLengths?.adName -
                            values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>

              <GridItem mb={2}>
                  <FormLabel mb={0}>Primary Texts</FormLabel>
                {primaryTextInputs && primaryTextInputs.map((text, index) => {
                  return (
                      <FormControl key={index} mb={2}>
                        <div className="input-box">
                          <Input
                              id={`primaryText${index}`}
                              name={`primaryText${index}`}
                              inputProps={{
                                variant: "outline",
                                type: "text",
                                placeholder: `Primary Text ${index + 1} - max 125 characters`
                              }}
                              placeholder={`Primary Text ${index + 1} - max 125 characters`}
                              onChange={(e) => handlePrimaryTextChange(e, index)}
                              value={text}
                          />
                          <CircularProgress
                              max={facebookVideoAdValueLengths?.primaryText}
                              value={text.length}
                              color={
                                text.length > facebookVideoAdValueLengths?.primaryText
                                    ? "red.400"
                                    : "blue.400"
                              }
                          >
                            <CircularProgressLabel>
                              {text.length > facebookVideoAdValueLengths?.primaryText
                                  ? facebookVideoAdValueLengths?.primaryText - text.length
                                  : text.length}
                            </CircularProgressLabel>
                          </CircularProgress>
                          {primaryTextInputs.length > 1 && (
                              <Button onClick={() => handleRemovePrimaryTextInput(index)} ml={2}>
                                -
                              </Button>
                          )}
                        </div>
                      </FormControl>
                  )})}
                {primaryTextInputs.length < 5 && (
                    <Button onClick={handleAddPrimaryTextInput} ml={2}>
                      +
                    </Button>
                )}
              </GridItem>
              {/*<GridItem mb={2}>*/}
              {/*  <FormControl>*/}
              {/*    <FormLabel htmlFor="headline" mb={0}>Headline</FormLabel>*/}
              {/*    <div className="input-box">*/}
              {/*      <InputControl*/}
              {/*        id="headline"*/}
              {/*        name="headline"*/}
              {/*        placeholder=""*/}
              {/*        inputProps={{*/}
              {/*          variant: "outline",*/}
              {/*          type: "text",*/}
              {/*          placeholder: " Headline - max 32 characters",*/}
              {/*        }}*/}
              {/*      />*/}
              {/*      <CircularProgress*/}
              {/*        max={facebookVideoAdValueLengths?.headline}*/}
              {/*        value={values?.headline?.length}*/}
              {/*        color={*/}
              {/*          values?.headline?.length >*/}
              {/*          facebookVideoAdValueLengths?.headline*/}
              {/*            ? "red.400"*/}
              {/*            : "green.400"*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <CircularProgressLabel>*/}
              {/*          {values?.headline?.length >*/}
              {/*          facebookVideoAdValueLengths?.headline*/}
              {/*            ? facebookVideoAdValueLengths?.headline -*/}
              {/*              values?.headline?.length*/}
              {/*            : values?.headline?.length}*/}
              {/*        </CircularProgressLabel>*/}
              {/*      </CircularProgress>*/}
              {/*    </div>*/}
              {/*  </FormControl>*/}
              {/*</GridItem>*/}
              {/*<GridItem mb={2}>*/}
              {/*  <FormControl>*/}
              {/*    <FormLabel htmlFor="description" mb={0}>Description</FormLabel>*/}
              {/*    <div className="input-box">*/}
              {/*      <InputControl*/}
              {/*        id="description"*/}
              {/*        name="description"*/}
              {/*        inputProps={{*/}
              {/*          variant: "outline",*/}
              {/*          type: "text",*/}
              {/*          placeholder: "Description - max 18 characters",*/}
              {/*        }}*/}
              {/*      />*/}
              {/*      <CircularProgress*/}
              {/*        max={facebookVideoAdValueLengths?.description}*/}
              {/*        value={values?.description?.length}*/}
              {/*        color={*/}
              {/*          values?.description?.length >*/}
              {/*          facebookVideoAdValueLengths?.description*/}
              {/*            ? "red.400"*/}
              {/*            : "green.400"*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <CircularProgressLabel>*/}
              {/*          {values?.description?.length >*/}
              {/*          facebookVideoAdValueLengths?.description*/}
              {/*            ? facebookVideoAdValueLengths?.description -*/}
              {/*              values?.description?.length*/}
              {/*            : values?.description?.length}*/}
              {/*        </CircularProgressLabel>*/}
              {/*      </CircularProgress>*/}
              {/*    </div>*/}
              {/*  </FormControl>*/}
              {/*</GridItem>*/}
                <GridItem mb={2}>
                    <FormLabel mb={0}>Headlines</FormLabel>
                    {headlineInputs && headlineInputs.map((text, index) => {
                        return (
                            <FormControl key={index} mb={2}>
                                <div className="input-box">
                                    <Input
                                        id={`headlines${index}`}
                                        name={`headlines${index}`}
                                        inputProps={{
                                            variant: "outline",
                                            type: "text",
                                            placeholder: `Headline ${index + 1} - max 32 characters`
                                        }}
                                        placeholder={`Headline ${index + 1} - max 32 characters`}
                                        onChange={(e) => handleHeadlineChange(e, index)}
                                        value={text}
                                    />
                                    <CircularProgress
                                        max={facebookVideoAdValueLengths?.headlines}
                                        value={text.length}
                                        color={
                                            text.length > facebookVideoAdValueLengths?.headlines
                                                ? "red.400"
                                                : "blue.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {text.length > facebookVideoAdValueLengths?.headlines
                                                ? facebookVideoAdValueLengths?.headline - text.length
                                                : text.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                    {headlineInputs.length > 1 && (
                                        <Button onClick={() => handleRemoveHeadlineInput(index)} ml={2}>
                                            -
                                        </Button>
                                    )}
                                </div>
                            </FormControl>
                        )})}
                    {headlineInputs.length < 5 && (
                        <Button onClick={handleAddHeadlineInput} ml={2}>
                            +
                        </Button>
                    )}
                </GridItem>
                <GridItem mb={2}>
                    <FormLabel mb={0}>Descriptions</FormLabel>
                    {descriptionInputs && descriptionInputs.map((text, index) => {
                        return (
                            <FormControl key={index} mb={2}>
                                <div className="input-box">
                                    <Input
                                        id={`descriptions${index}`}
                                        name={`descriptions${index}`}
                                        inputProps={{
                                            variant: "outline",
                                            type: "text",
                                            placeholder: `Description ${index + 1} - max 125 characters`
                                        }}
                                        placeholder={`Description ${index + 1} - max 125 characters`}
                                        onChange={(e) => handleDescriptionChange(e, index)}
                                        value={text}
                                    />
                                    <CircularProgress
                                        max={facebookVideoAdValueLengths?.descriptions}
                                        value={text.length}
                                        color={
                                            text.length > facebookVideoAdValueLengths?.descriptions
                                                ? "red.400"
                                                : "blue.400"
                                        }
                                    >
                                        <CircularProgressLabel>
                                            {text.length > facebookVideoAdValueLengths?.descriptions
                                                ? facebookVideoAdValueLengths?.description - text.length
                                                : text.length}
                                        </CircularProgressLabel>
                                    </CircularProgress>
                                    {descriptionInputs.length > 1 && (
                                        <Button onClick={() => handleRemoveDescriptionInput(index)} ml={2}>
                                            -
                                        </Button>
                                    )}
                                </div>
                            </FormControl>
                        )})}
                    {descriptionInputs.length < 5 && (
                        <Button onClick={handleAddDescriptionInput} ml={2}>
                            +
                        </Button>
                    )}
                </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="landingPageURL" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="landingPageURL"
                      name="landingPageURL"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: " Landing Page URL - max 255 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.landingPageURL}
                      value={values?.landingPageURL?.length}
                      color={
                        values?.landingPageURL?.length >
                        facebookVideoAdValueLengths?.landingPageURL
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.landingPageURL?.length >
                        facebookVideoAdValueLengths?.landingPageURL
                          ? facebookVideoAdValueLengths?.landingPageURL -
                            values?.landingPageURL?.length
                          : values?.landingPageURL?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="facebookAccountId" mb={0}>Call to Action</FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="facebookAccountId"
                      name="facebookAccountId"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("facebookAccountId", e.target.value);
                      }}
                    >
                      {facebookAccountIds.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default DynamicFacebookBothAdDetails;
