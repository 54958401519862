import {Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader} from "@chakra-ui/react";
import {useGlobalContext} from "../store/WrapContext";
import MobileView from "./MobileView";
import DesktopPreview from "./DesktopPreview";
import {useEffect} from "react";

function PreviewModelContext({whichModel, onClose}) {

    return (
        <ModalContent maxW="1200px">
            <ModalHeader> {whichModel} </ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <h1 style={{fontWeight: 600}}>Mobile Preview</h1>
                <MobileView/>
                <h1 style={{fontWeight: 600}}>Desktop Preview</h1>
                <DesktopPreview/>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </ModalContent>
    )
}

export default PreviewModelContext