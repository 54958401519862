import {DV360_IMAGE_SIZE, LINKEDIN_IMAGE_SIZE} from "../constant";

export const checkIfFilesAreTooBig = (files) => {
    let valid = false
    if (files) {
        files.map(file => {
            const fileSize = file?.size || file?.files?.size
            if (fileSize < DV360_IMAGE_SIZE) {
                valid = true
            }
        })
    }
    return valid
}

export const checkIfFilesAreTooBigForLinkedin = (files) => {
    let valid = false
    if (files) {
        files.map(file => {
            const fileSize = file?.size || file?.files?.size
            if (fileSize < LINKEDIN_IMAGE_SIZE) {
                valid = true
            }
            return valid
        })
    }
    return true
}

export const checkIfFilesAreCorrectType = (files) => {
    let valid = true
    if (files) {
        files.map(file => {
            if (!['application/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(file.type || file.files?.mimetype || file.mimetype)) {
                valid = false
            }
        })
    }
    return valid
}

export const checkIfFilesAreCorrectTypeForLinkedin = (files) => {
    let valid = true
    if (files) {
        files.map(file => {
            if (!['application/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(file.type || file.files?.mimetype || file.mimetype)) {
                valid = false
            }
        })
    }
    return valid
}

export const checkIfFilesAreCorrectVideoType = (files) => {
    let valid = true
    if (files) {
        files.map(file => {
            if (!['video/*', 'video/mp4'].includes(file.type || file.files?.mimetype || file.mimetype)) {
                valid = false
            }
        })
    }
    return valid
}

export const validateFileSize = (size) => {
    return (files) => {
        let valid = false
        if (files) {
            files.map(file => {
                if (file.size < size) {
                    valid = true
                }
            })
        }
        return valid
    }
}
