import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useGlobalContext} from "./store/WrapContext";
import {useEffect, useMemo} from "react";
import {Text} from "@chakra-ui/react";
import {usePagination, useSortBy, useTable} from "react-table";
import {useGetMasterData} from "../../../../../../../hooks/campaign-briefs/useTableData";

function GoogleTableData() {

    const {id, googleId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const contextData = useGlobalContext()

    const {data: masterTableData, refetch} = useGetMasterData(clientId, id, googleId)

    useEffect(() => {
        refetch()
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: 'Campaign',
                accessor: 'campaign', // accessor is the "key" in the data
            },
            {
                Header: 'Ad Group',
                accessor: 'ad_group', // accessor is the "key" in the data
            },
            {
                Header: 'Heading 1',
                accessor: 'headline_1',
            },
            {
                Header: 'Heading 2',
                accessor: 'headline_2',
            },
            {
                Header: 'Heading 3',
                accessor: 'headline_3',
            },
            {
                Header: 'Heading 4',
                accessor: 'headline_4',
            },
            {
                Header: 'Heading 5',
                accessor: 'headline_5',
            },
            {
                Header: 'Heading 6',
                accessor: 'headline_6',
            },
            {
                Header: 'Heading 7',
                accessor: 'headline_7',
            },
            {
                Header: 'Heading 8',
                accessor: 'headline_8',
            },
            {
                Header: 'Heading 9',
                accessor: 'headline_9',
            },
            {
                Header: 'headline_9_character_count',
                accessor: 'headline_9_character_count'
            },
            {
                Header: 'Heading 10',
                accessor: 'headline_10',
            },
            {
                Header: 'headline_10_character_count',
                accessor: 'headline_10_character_count'
            },
            {
                Header: 'Heading 11',
                accessor: 'headline_11',
            },
            {
                Header: 'headline_11_char_count',
                accessor: 'headline_11_char_count'
            },
            {
                Header: 'Heading 12',
                accessor: 'headline_12',
            },
            {
                Header: 'headline_12_char_count',
                accessor: 'headline_12_char_count'
            },
            {
                Header: 'Heading 13',
                accessor: 'headline_13',
            },
            {
                Header: 'headline_13_char_count',
                accessor: 'headline_13_char_count'
            },
            {
                Header: 'Heading 14',
                accessor: 'headline_14',
            },
            {
                Header: 'headline_14_char_count',
                accessor: 'headline_14_char_count'
            },
            {
                Header: 'Description 1',
                accessor: 'description_1',
            },
            {
                Header: 'Description 2',
                accessor: 'description_2',
            },
            {
                Header: 'Description 3',
                accessor: 'description_3',
            },
            {
                Header: 'Description 4',
                accessor: 'description_4',
            },
            {
                Header: () => <Text>Actions</Text>,
                accessor: "actions",
            }
        ],
        []
    )

    const data = []

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: {pageIndex},
        rows
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0, pageSize: 5},
            autoResetHiddenColumns: false,
            autoResetSortBy: false,
        },
    );


    return <h1>Google Table Data</h1>
}

export default GoogleTableData