import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box, Circle,
  Container, Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image, SkeletonCircle, SkeletonText,
  Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import {useSelector} from "react-redux";

function GmailOut({formDetails, ads, handleIndex, businessName, handleGmailState}) {
  const {handlePreviewData} = useSelector((state) => state.performanceMaxAd)

  const selectedLogos = (Array.isArray(handlePreviewData?.selectedLogos) && handlePreviewData?.selectedLogos[0]?.imageUrl) ?
      (handlePreviewData?.selectedLogos[0]?.imageUrl) :
      process.env.PUBLIC_URL + "/images/display.png";

  return (
    <>
      <Flex backgroundColor='white' textAlign='center' m={6} mx={2}
            border={"2px solid"} borderColor="gray.300"
            alignSelf="center"
            justifyContent="space-between"
      >
        <Flex alignItems="center" gap={2}>
          <Image src={`${process.env.PUBLIC_URL}/images/three_line_icon.svg`} alt="Back Button" height={8}
                 width={8} pl={2}/>
          <Text fontSize="small" color="gray.500" fontWeight="500">Search in mail</Text>
        </Flex>
        <Flex alignItems="center" gap={5} pr={2}>
          <SkeletonCircle size='5'/>
        </Flex>
      </Flex>
      <Box width="100%" bg='white' overflow="hidden">
        <Text px={2} fontWeight="500" color="gray.600">{('Promotions').toUpperCase()}</Text>

        <Box display="flex" gap="3" px={6} pt={6} cursor="pointer" onClick={() => handleGmailState()}>
          <Image src={selectedLogos} height={8}
                 width={8} rounded="full"/>
          <Box width="70%">
            <Flex gap={2} fontWeight="700" fontSize="11px" isTruncated="true">Sponsored <li>{businessName}</li></Flex>
            <Text fontSize={'small'} fontWeight="600" isTruncated="true">{formDetails.headlines[0]}</Text>
            <Text fontSize={'small'} isTruncated="true">{formDetails.descriptions[0]}</Text>
          </Box>
          <Box>
            <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Back Button" height={5}
                   width={5}/>
            <Image src={`${process.env.PUBLIC_URL}/images/star_icon.png`} alt="Back Button" height={5}
                   width={5} mt={5}/>

          </Box>
        </Box>

        {[...Array(7)].map((_, index) => (
          <>
            <Box key={index} display="flex" gap="3" pl={6} my={3}>
              <SkeletonCircle size='8'/>
              <Box width="70%">
                <Flex justifyContent="space-between">
                  <SkeletonText mt='1' noOfLines={1} spacing='3' skeletonHeight='1.5' width="50%" rounded=""/>
                </Flex>
                <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='1.5' rounded="lg" width="70%"/>
                <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='1.5' rounded="lg"/>
              </Box>
            </Box>
            <Divider />
          </>
        ))}
      </Box>
    </>
  );
}

export default GmailOut;
