import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";

function YoutubeShort({ formDetails, appLoading, ads, index, handleIndex, businessName }) {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader />}
      <GridItem w="full" colSpan={{ base: 6, lg: 6 }}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%">
            <Flex alignItems="center" flexDirection="row" justify="center">
              <div className="youtube-shorts-container">
                <Box
                  className='react-player videos'
                  width={392}
                  height={695}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  zIndex="-1"
                >
                  <div style={{ width: '100%' }}>
                    {(ads && ads.length !== 0) ?
                      <Swiper navigation={true} modules={[Navigation]} style={{ height: '100%' }}
                              onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                        {ads.map((item) => (
                          <SwiperSlide key={item.imageUrl}>
                            <Image
                              borderTopRadius="25"
                              height="100%"
                              width={"100%"}
                              objectFit={"fill"}
                              src={item?.imageUrl}
                              className="previewImage"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden" />
                    }
                  </div>
                </Box>
                <div className="overlay-box">

                </div>
                <div className="youtube-shorts-rights">
                  <div className="icons-items youtube-shorts-icons">
                    <span className="icons">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`}
                          alt=""
                          id="likes-icons"
                        />
                    </span>
                    <span className="icon-labels likes right-labels" data-likes="71.1k">71.1k</span>
                  </div>
                  <div className="icons-items youtube-shorts-icons">
                      <span className="icons">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/thumbs-down.png`}
                            alt=""
                            id="likes-icons"
                          />
                      </span>
                    <span className="icon-labels likes right-labels" data-likes="71.1k">71.1k</span>
                  </div>
                  <div className="icons-items youtube-shorts-icons">
                      <span className="icons">
                          <img src={`${process.env.PUBLIC_URL}/images/comments.png`} alt=""
                               id="comments-icons"/>
                      </span>
                    <span className="icon-labels comments right-labels"
                          data-likes="1281">1281</span>
                  </div>
                  <div className="icons-items right-icons">
                      <span className="icons">
                          <img src={`${process.env.PUBLIC_URL}/images/share.png`} style={{ width: "30px", height: "30px" }}
                               alt=""/>
                      </span>
                    <span className="icon-labels shares right-labels">232</span>
                  </div>
                </div>
                <div className="ads-details youtube-shorts-ads-details">
                  <div style={{ display: "flex", gap: "10px", justifyItems: "center"}}>
                    <div>
                      <img
                        src={`${formDetails?.channelIcon?.imageUrl ? process.env.REACT_APP_API_URL + "/uploads" + formDetails?.channelIcon?.imageUrl : "/images/display.png"}`}
                        alt=""
                        height={"35px"}
                        width={"35px"}
                      />
                    </div>
                    <div>
                      <h1>{formDetails.channel_name ? formDetails.channel_name : "Kedet"}</h1>
                      <h6>Ad</h6>
                    </div>
                  </div>
                  {/*<Text mt={3}>{formDetails?.description[0]}</Text>*/}
                  <div className="youtube-shorts-bottom">
                    <button>{youtubeShortsTypes.find((item) => item.key === formDetails?.type)?.name}</button>
                  </div>
                </div>
              </div>
            </Flex>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default YoutubeShort;

