import {
    Box, Button,
    Container, Flex,
    Grid,
    GridItem,
    Heading,
    Image, Spacer, Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PREVIEW_BG_COLOR, TEXT_COLOR, INDICATOR_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useDispatch, useSelector} from "react-redux";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {deleteTheField} from "../../../../../../../store/adUploads/index"
import ReactPlayer from "react-player";
import _ from "lodash";
import {deleteAllDV360VideoField} from "./store";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";
import {fetchVideoMetadata} from "../../LinkedIn/LinkedinVideo/LinkedInVideoAdPreview";
import {CreateMultipleCreatives} from "../../SocialDisplay/SocialDisplayImage/SocialDisplayImageAdPreview";

const DV360VideoAdPreview = ({ activeStep, steps, prevStep, nextStep, label, context}) => {

    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const { id: campaignId, adUploadId } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)
    const { allCampaignIds } = useSelector((state) => state.adUpload);

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    // const { data, refetch } = useGetAdUpload(clientId, campaignId, adUploadId);
    useEffect(() => {
        // refetch()
    }, [])

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    // const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.adUpload)
    const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.dv360Video)
    const {creative,selectedSocialMedia} = useSelector((state)=> state.adUpload)

    const { fileInfoList} = formDetails

    useEffect(() => {
        setAds(handlePreviewData)
    }, [handlePreviewData]);


    const {mutateAsync} = useUploadImage();


    const onHandleSubmit = async (values) => {
        let videoData;
        let mediaInfoList = [];
        let mediaIds = [];
        if (_.isEqual(fileInfoList, values.fileURL)) {
            // const { detail } = data;
            videoData = { audio: formDetails.audio };
        } else {
            try {
                const videoFile = await mutateAsync({
                    clientId: clientId,
                    campaignBriefId: campaignId,
                    adFile: values.fileURL,
                    type: "dv360",
                });

                mediaInfoList = videoFile && await fetchVideoMetadata(videoFile.fileInfoList)
                console.log("mediaInfoList ", mediaInfoList);
                mediaIds = videoFile && videoFile.fileInfoList.map((el) => el.imageHash);

                videoData = {
                    audio: {
                        mediaIds: mediaIds,
                        fileInfoList: mediaInfoList,
                    },
                };
            } catch (e) {
                setAppLoading(false);
            }
        }
        return {
            name: formDetails.adName,
            detail: {
                name: formDetails.adName,
                landingPageURL: formDetails.landingPageURL,
                ...videoData,
                creative,
                channel: selectedSocialMedia
            },
        };
    };


    const navigate = useNavigate();
    const dispatch = useDispatch()


    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if(adUploadId) {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
                    state: {isShowUpload: true}
                })
            }
            dispatch(deleteAllDV360VideoField())
            dispatch(deleteTheField())

        }
        else {
            // navigate(`/campaign-briefs/${campaignId}`, {
            //     state: {isShowUpload: true}
            // })
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`)
            }
            dispatch(deleteAllDV360VideoField())
            dispatch(deleteTheField())

        }
    }

    async function handleSubmit() {
        try {
            setAppLoading(true);
            let payload = await onHandleSubmit(mediaDetails);
            if (!adUploadId) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.DV360VIDEO,
                };
            }

            if(allCampaignIds?.length){
                CreateMultipleCreatives(allCampaignIds, method, url, clientId, payload, setSuccessModal, setErrorModal, setAppLoading, setDescription, dispatch);
            } else {
                await instance({
                    method: method,
                    url: url,
                    withCredentials: false,
                    data: payload,
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setSuccessModal(true);
                        }
                        setAppLoading(false);
                    })
                    .catch((error) => {
                        // setLandingPageURL(error.response.data.message);
                        setErrorModal(true);
                        setAppLoading(false);
                        setDescription(error?.response?.data?.message);
                    });
            }
        } catch (error) {
            setAppLoading(false);
        }
    }

    return (
        <>
            {/*{*/}
            {/*    adUploadId && (*/}
            {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
            {/*            <Heading fontSize="xl" color={"#757998"}>*/}
            {/*                Please review the ad details carefully.*/}
            {/*            </Heading>*/}
            {/*            <Heading fontSize="md" color="gray.400">*/}
            {/*                1 steps to complete*/}
            {/*            </Heading>*/}
            {/*        </Box>*/}
            {/*    )*/}
            {/*}*/}

            {/*{*/}
            {/*    !adUploadId && (*/}
            {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
            {/*            <Heading fontSize="xl" color={"#757998"}>*/}
            {/*                Please review your ad.*/}
            {/*            </Heading>*/}
            {/*            <Heading fontSize="md" color="gray.400">*/}
            {/*                1 steps to complete*/}
            {/*            </Heading>*/}
            {/*        </Box>*/}
            {/*    )*/}
            {/*}*/}

            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                {appLoading && <FullPageLoader />}
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                    <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
                        {ads?.[index]?.filename}
                    </Heading>
                    <Box
                        borderWidth="2px"
                        borderColor="gray"
                        p={10}
                        background={PREVIEW_BG_COLOR}
                        position={"relative"}
                    >
                        <Container height="100%">
                            <Flex
                                alignItems="center"
                                flexDirection="row"
                                justify="center"
                            >
                                <ReactPlayer
                                    className='react-player'
                                    url={handlePreviewData}
                                    // width={vidWidth}
                                    controls
                                    // playing={playingPreview}
                                    // onPlay={() => { setPlayingPreview(true) }}
                                />
                            </Flex>
                        </Container>
                    </Box>

                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        {/*<Button mr={4} colorScheme='blue' variant='solid' borderRadius={0} px={10}>*/}
                        {/*    Save Draft*/}
                        {/*</Button>*/}
                        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                            { adUploadId ? 'Update' : 'Submit'}
                        </Button>
                    </Flex>

                </GridItem>
                <SuccessModal
                    isOpen={isSuccessModalOpen}
                    onClose={() => onCloseSuccessModal()}
                />
                <ErrorModal
                    isOpen={isErrorModalOpen}
                    onClose={() => setErrorModal(false)}
                    description={description}
                />
            </Grid>
        </>
    );
};

export default WithAppContext(DV360VideoAdPreview);
