import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";

const initialState = {
  formDetails: {},
  mediaDetails: [],
  handlePreviewData: null,
};

export const discoverySlice = createSlice({
  name: AD_UPLOAD_TYPE.DISCOVERY,
  initialState,
  reducers: {
    settingDiscoveryForm: (state, action) => {
      state.formDetails = action.payload
    },
    settingDiscoveryMedia: (state, action) => {
      state.mediaDetails = action.payload
    },
    settingDiscoveryPreview: (state, action) => {
      state.handlePreviewData = action.payload
    },
    deleteAllDiscoveryField: (state, action) => {
      state.formDetails = {};
      state.mediaDetails = [];
      state.handlePreviewData = null
    }
  },
});

export const { settingDiscoveryForm, settingDiscoveryMedia, settingDiscoveryPreview, deleteAllDiscoveryField} = discoverySlice.actions;

// The reducer
export const discoveryReducer = discoverySlice.reducer;
