import {CloseIcon, DownloadIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {
    Box, Button,
    Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip, useToast
} from "@chakra-ui/react";
import FileSaver from 'file-saver';
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import DefaultImageIcon from "../../../../../../../assets/images/default-image-icon.png";
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import WithAppContext from "../../../../../../../helpers/appContext";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {GREY_TEXT_COLOR, TEXT_COLOR, BLUE_BUTTON_COLOR} from "../../../../../../../layout/constant/MenuList";
import {DV360_IMAGE_SIZE, FB_IMAGE_SIZE, PINTEREST_IMAGE_SIZE} from "../../../../../../../constant";
import DefaultIcon from "../../../../../../../assets/images/jpg.svg";


const DynamicInstagramThumbnailAdFileUpload = ({values, setFieldValue, setErrors, errors, context, onDrop}) => {
    const [fileURLs, setFileURLs] = useState([])
    const {ErrorToaster} = context;

    useEffect(() => {
        if (values && values.length > 0) {
            const urls = values.map((image) => {
                return `${process.env.REACT_APP_API_URL}/uploads/${image?.file?.filename}`;
            });
            setFileURLs(urls)
            setErrors('selectedImages', {})
            console.log('After', errors)
        }
    }, [values])

    const acceptImageFiles = {
        "image/png": [".png"],
        "image/jpeg": [".jpg"],
    };

    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop,
        accept: acceptImageFiles,
        maxSize: 30 * 1024 * 1024, // 10MB
        disabled: values.thumbnailURL && true,
    });

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    // dropZone Errors
    useEffect(() => {
        fileRejections && fileRejections.map(({errors}) => {
            errors.map((err) => ErrorToaster(err.code + ',' + err.message));
        })
    }, [fileRejections])

    return (
        <>
            <Box className="upload-main" mt={10}>
                <Grid border='2px'
                      borderColor={(errors.selectedImages) ? 'red.500' : 'gray.200'}
                      padding="20px" borderStyle="dashed">
                    <Grid>
                        <div {...getRootProps()}>
                            <Input {...getInputProps()} />

                            <div className="file-upload-content">
                                <Image
                                    boxSize="100px"
                                    objectFit="cover"
                                    src={FileUploadIcon}
                                    alt="upload-icon"
                                />
                                <Heading
                                    fontSize="xl"
                                    display="flex"
                                    alignItems="center"
                                    color={GREY_TEXT_COLOR}
                                    my={4}
                                >
                                    Drag thumbnail files to upload
                                    <Tooltip label={
                                        <Box>
                                            <Text color="#000"><b>Image file type:</b> JPG or PNG</Text>
                                            <Text color="#000"><b>Resolution:</b> At least 1080 x 1080 pixels</Text>
                                            <Text color="#000"><b>Image maximum file size:</b> 30 MB</Text>
                                        </Box>} closeDelay={500} bg='#e2e8f0'>
                                        <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                                    </Tooltip>
                                </Heading>
                                <Text
                                    fontSize="sm"
                                    mb={4}
                                    color={GREY_TEXT_COLOR}
                                >
                                    - OR -
                                </Text>
                                <Button size="small" bgColor={BLUE_BUTTON_COLOR} color="white"
                                        disabled={values.thumbnailURL && true} px={10} py={2} borderRadius={100}>Browse
                                    Files</Button>
                            </div>
                        </div>
                    </Grid>
                    {(errors.selectedImages) &&
                    <Text color="red.500" marginTop="2" textAlign={"center"}>
                        Thumbnail File is required
                    </Text>
                    }
                </Grid>

                <div className="image-listing">

                    {values && values.length > 0 && (
                        values.map((thumbnail, index) => {
                            console.log('thumbnail', thumbnail.file, values)
                            return (
                                <Grid className="file-box" key={index}>
                                    <Flex marginTop="5" marginBottom="5">
                                        <Image
                                            boxSize="50px"
                                            objectFit="cover"
                                            src={DefaultIcon}
                                            alt="Dan Abramov"
                                        />
                                        <Grid marginLeft="2">
                                            <Grid>
                                                <Text
                                                    fontSize="15px"
                                                    textOverflow="ellipsis"
                                                    overflow='hidden'
                                                    whiteSpace="nowrap"
                                                    className="file-name"
                                                >
                                                    {thumbnail.file.originalname}
                                                </Text>
                                            </Grid>
                                            <Text fontSize="sm">
                                                {formatBytes(thumbnail.file.size)}
                                            </Text>
                                        </Grid>
                                        <Spacer/>
                                        <Grid>
                                            <Grid justifyContent="center">
                                                <CloseIcon
                                                    cursor="pointer"
                                                    className="close-icon"
                                                    size="small"
                                                    onClick={() => {
                                                        setFieldValue('selectedImages', values.filter((_, i) => i !== index));
                                                    }}
                                                />
                                            </Grid>
                                            <Grid>
                                                <DownloadIcon
                                                    onClick={() => FileSaver.saveAs(fileURLs[index], thumbnail.file.originalname)}
                                                    cursor="pointer"
                                                    className="close-icon"
                                                    size="small"
                                                    w={6}
                                                    h={6}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Flex>
                                    <Box marginTop={"-20px"}>
                                        <Text textAlign={"right"} display={"block"} color={"green"}>100%
                                            Completed</Text>
                                        <Progress
                                            className="loading"
                                            value={100}
                                            colorScheme='green'
                                            size='sm'
                                        />
                                    </Box>
                                </Grid>
                            )
                        })
                    )}
                </div>
            </Box>

        </>
    );
}

export default WithAppContext(DynamicInstagramThumbnailAdFileUpload)
