import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useCallback, useEffect} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import {
    FACEBOOK_IMAGE_WIDTH, FB_VIDEO_DURATION, INSTAGRAM_IMAGE_HEIGHT,
    INSTAGRAM_IMAGE_WIDTH, INSTAGRAM_VIDEO_THUMBNAIL_HEIGHT, INSTAGRAM_VIDEO_THUMBNAIL_WIDTH, INSTAGRAM_VIDEO_WIDTH_MIN
} from "../../../../../../../constant";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {settingDynamicInstagramVideoMedia} from "./store";
import InstagramVideoAdFileUpload from "./DynamicInstagramVideoAdFileUpload";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import WithAppContext from "../../../../../../../helpers/appContext";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import calculateAspectRatios from "calculate-aspect-ratio";
import InstagramThumbnailAdFileUpload from "./DynamicInstagramThumbnailFileupload";
import DynamicInstagramThumbnailAdFileUpload from "./DynamicInstagramThumbnailFileupload";
import DynamicInstagramVideoAdFileUpload from "./DynamicInstagramVideoAdFileUpload";


function DynamicInstagramVideoAdMedia({activeStep, steps, prevStep, nextStep, label, context}) {
    const {adUploadId} = useParams()

    const {mediaDetails} = useSelector((state) => state.dynamicInstagramVideoAd)

    const schema = yup.object().shape({
        selectedVideos: yup.array().of(
            yup.object()
        ).min(1, 'At least one video must be selected'
        ).max(2, 'Maximum of 2 videos can be selected'),
        selectedImages: yup.array().of(
            yup.object()
        ).min(1, 'At least one video must be selected'
        ).max(2, 'Maximum of 2 videos can be selected'),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            selectedVideos: [],
            selectedImages: []
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingDynamicInstagramVideoMedia(values))
            nextStep()
        }),
        initialErrors: {
            selectedVideos: true,
            selectedImages: true
        }
    });
    const {setAppLoading, SuccessToaster, ErrorToaster, appLoading} = context;
    const clientId = useSelector((state) => state.client.clientId);
    const {id} = useParams();
    const {mutateAsync} = useUploadImage();

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setErrors,
        setFieldValue,
    } = formik;


    useEffect(() => {
        setFieldValue("selectedImages", mediaDetails?.selectedImages || []);
        setFieldValue("selectedVideos", mediaDetails?.selectedVideos || []);
    }, [mediaDetails])

    const onDrop = useCallback(async (accFiles) => {
        accFiles.map((file) => {
            const fileType = file.type.split("/").shift();
            if (fileType === "image") {
                const img = new Image()
                img.onload = async () => {
                    const {naturalWidth: width, naturalHeight: height} = img
                    if (width <= INSTAGRAM_VIDEO_THUMBNAIL_WIDTH && height <= INSTAGRAM_VIDEO_THUMBNAIL_HEIGHT) {
                        ErrorToaster('Please valid Image Minimum Width: 500 pixels')
                    } else {
                        setAppLoading(true);
                        await mutateAsync(
                            {
                                clientId: clientId,
                                campaignBriefId: id,
                                adFile: accFiles,
                                type: "multipleFbAdImages",
                            }, {
                                onSuccess: (data) => {
                                    const dataHere = [...values.selectedImages, ...data];
                                    if (dataHere.length > 2) {
                                        ErrorToaster('Maximum of 2 images can be selected')
                                    } else {
                                        setFieldValue('selectedImages', dataHere);
                                        SuccessToaster('Image Upload Successfully.')
                                    }
                                    setAppLoading(false);
                                },
                                onError: (error) => {
                                    // ErrorToaster(error)
                                    setAppLoading(false);
                                }
                            });
                    }
                }
                img.src = URL.createObjectURL(file)
            } else {
                const objectURL = URL.createObjectURL(file);
                const mySound = new Audio([objectURL]);

                let video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    return video
                }
                video.src = URL.createObjectURL(file);
                mySound.addEventListener("canplaythrough", async () => {
                    const ratio = calculateAspectRatios(video.videoHeight, video.videoWidth);
                    if (!ratio >= parseInt('1:1') && !ratio <= parseInt('9:16')) {
                        ErrorToaster('Please valid aspect ratio.')
                    } else if (mySound.duration > FB_VIDEO_DURATION) {
                        ErrorToaster('Please valid Video Duration: 1 second to 60 minutes.')
                    } else if (video.videoWidth <= INSTAGRAM_VIDEO_WIDTH_MIN) {
                        ErrorToaster('Please valid Video Minimum Width: 500 pixels')
                    } else {
                        setAppLoading(true);
                        await mutateAsync(
                            {
                                clientId: clientId,
                                campaignBriefId: id,
                                adFile: accFiles,
                                type: 'multipleFbAdVideos',
                            }, {
                                onSuccess: (data) => {
                                    const dataHere = [...values.selectedVideos, ...data];
                                    if (dataHere.length > 2) {
                                        ErrorToaster('Maximum of 2 videos can be selected')
                                    } else {
                                        setFieldValue('selectedVideos', dataHere);
                                        SuccessToaster('Video Uploaded Successfully.')
                                    }
                                    setAppLoading(false);
                                },
                                onError: (error) => {
                                    // ErrorToaster(error)
                                    setAppLoading(false);
                                }
                            });
                    }
                })
            }
        })
    }, [values]);

    function handleOnClose(index) {
        const udpatedVideos = values.selectedVideos.filter((_, i) => i !== index);
        setFieldValue('selectedVideos', udpatedVideos);
        console.log('udpatedVideos', udpatedVideos)
    }

    useEffect(() => {
        if (values.selectedVideos?.length !== values.selectedImages?.length) {
            if (values.selectedVideos?.length < values.selectedImages?.length) {
                setErrors({"selectedVideos": "Please select one more video."})
            }
            if (values.selectedVideos?.length > values.selectedImages?.length) {
                setErrors({"selectedImages": "Please select one more thumbnail."})
            }
        }
    }, [values])

    return (
        <>
            {appLoading && <FullPageLoader/>}
            <Box className="file-upload-box">
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <DynamicInstagramVideoAdFileUpload
                            getHashArray={(value) => {
                                setFieldValue('videoURL', value)
                            }}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            type="facebook-video"
                            values={values.selectedVideos}
                            setErrors={setErrors}
                            onDrop={onDrop}
                            handleOnClose={handleOnClose}
                        />
                        <DynamicInstagramThumbnailAdFileUpload
                            getHashArray={(value) => {
                                setFieldValue('thumbnailURL', value)
                            }}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            type="facebook-video-thumbnail"
                            values={values.selectedImages}
                            setErrors={setErrors}
                            onDrop={onDrop}
                        />
                        <Flex width="100%" justify="flex-end" py={10}>
                            <Button
                                mr={4}
                                onClick={prevStep}
                                colorScheme='blue' variant='outline' borderRadius={0} px={10}
                            >
                                Back
                            </Button>
                            <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}
                                    isDisabled={errors.selectedVideos || errors.selectedImages}>
                                Next
                            </Button>
                        </Flex>
                    </Form>

                </FormikProvider>

            </Box>
        </>
    )
}

export default WithAppContext(DynamicInstagramVideoAdMedia)
