import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading, Text,
} from "@chakra-ui/react";
import {InputControl, SelectControl} from "formik-chakra-ui";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import {
  settingDiscoveryForm, settingDiscoveryMedia
} from "./store";
import {Input} from "@chakra-ui/input";
import {
  discoveryAdValueLengths,
  facebookVideoAdValueLengths,
  youtubeShortsValueLengths
} from "../../../../constant/InitialValues";
import WithAppContext from "../../../../../../helpers/appContext";
import {useGetAdUpload} from "../../../../../../hooks/campaign-briefs/useGetAdUpload";
import TooltipComponent from "../TooltipComponent";
import {youtubeShortsTypes} from "../../../../constant/SelectValues";

const initialValue = {
  adName: "",
  displayName: [],
  descriptions: [""],
  headlines: [""],
  long_headlines: [""],
  link: "",
  type: "",
  channel_name: "",
};

function DiscoveryAdDetails({
                                  activeStep,
                                  steps,
                                  prevStep,
                                  nextStep,
                                  label,
                                  autoFillData,
                                  context
                                }) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.discoveryAd);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);
  const [longHeadlineInputs, setLongHeadlineInputs] = useState([""]);
  const [headlineInputs, setHeadlineInputs] = useState([""]);
  const [descriptionInputs, setDescriptionInputs] = useState([""]);


  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingDiscoveryForm({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          descriptions: data?.adUpload?.detail?.descriptions,
          headlines: data?.adUpload?.detail?.headlines,
          long_headlines: data?.adUpload?.detail?.long_headlines,
          link: data?.adUpload?.detail?.call_to_action.link,
          type: data?.adUpload?.detail?.call_to_action?.type,
          channel_name: data?.adUpload?.detail?.channel_name,
        })
      );
      dispatch(
          settingDiscoveryMedia({
            selectedImages: data?.adUpload?.detail?.selectedImages,
            selectedLogos: data?.adUpload?.detail?.selectedLogos,
          })
      )
    }
  }, [data]);

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    adName: yup
      .string()
      .trim()
      .min(4)
      .max(255)
      .required()
      .matches(
        /^[!@$%^&#()-=+{};:,<.>0-9a-zA-Z\s]+$/i,
        "Emojis are not allowed as ad Name"
      )
      .label("Ad name"),
    headlines: yup
      .array()
      .of(
        yup.string().trim().min(3).max(40).required().label("Headline")
      )
      .min(1, "At least one headline is required")
      .max(5, "Maximum of 5 headlines allowed")
      .required()
      .label("Headline"),
    channel_name: yup.string()
      .trim()
      .max(25)
      .optional()
      .label("Channel Name"),
    long_headlines: yup
      .array()
      .of(
        yup.string().trim().min(3).max(125).required().label("Long Headline")
      )
      .min(1, "At least one long headline is required")
      .max(5, "Maximum of 5 long headlines allowed")
      .required()
      .label("Long Headline"),
    descriptions: yup
      .array()
      .of(
        yup.string().trim().min(3).max(90).required().label("Description")
      )
      .min(1, "At least one description is required")
      .max(5, "Maximum of 5 descriptions allowed")
      .required()
      .label("Description"),
    link: yup
      .string()
      .trim()
      .transform((value) => (value ? value.toLowerCase() : value))
      .required("Landing page url is required.")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      ).label('Landing page url'),
    type: yup.string().max(125).optional().label("Call to action").transform((value) => (value ? value.toLowerCase() : value)),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
        dispatch(
          settingDiscoveryForm({
            ...data?.adUpload?.detail,
            ...values,
          })
        );
      nextStep();
    },
  });

  const { errors, values, touched, handleSubmit, isValid, setFieldValue } = formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("headlines", formDetails.headlines || autoFillData?.headlines || [""]);
    setFieldValue("long_headlines", formDetails.long_headlines || autoFillData?.long_headlines || [""]);
    setFieldValue("descriptions", formDetails.descriptions || autoFillData?.descriptions || [""]);
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
    setFieldValue("channel_name", formDetails.channel_name || autoFillData?.channel_name || "");
    setFieldValue("type", formDetails.type || autoFillData?.type || "");
    setDescriptionInputs(formDetails.descriptions || [""])
    setHeadlineInputs(formDetails.headlines || [""])
    setLongHeadlineInputs(formDetails.long_headlines || [""])
  }, [formDetails, autoFillData]);

  const handleLongHeadlineChange = (e, index) => {
    const newInputs = [...longHeadlineInputs];
    newInputs[index] = e.target.value;
    setLongHeadlineInputs(newInputs);
  };

  const handleAddLongHeadlineInput = () => {
    if (longHeadlineInputs.length < 5) {
      setLongHeadlineInputs([...longHeadlineInputs, ""]);
    }
  };

  const handleRemoveLongHeadlineInput = (indexToRemove) => {
    const updatedInputs = longHeadlineInputs.filter((_, index) => index !== indexToRemove);
    setLongHeadlineInputs(updatedInputs);
  };

  const handleHeadlineChange = (e, index) => {
    const newInputs = [...headlineInputs];
    newInputs[index] = e.target.value;
    setHeadlineInputs(newInputs);
  };

  const handleAddHeadlineInput = () => {
    if (headlineInputs.length < 5) {
      setHeadlineInputs([...headlineInputs, ""]);
    }
  };

  const handleRemoveHeadlineInput = (indexToRemove) => {
    const updatedInputs = headlineInputs.filter((_, index) => index !== indexToRemove);
    setHeadlineInputs(updatedInputs);
  };

  const handleDescriptionChange = (e, index) => {
    const newInputs = [...descriptionInputs];
    newInputs[index] = e.target.value;
    setDescriptionInputs(newInputs);
  };

  const handleAddDescriptionInput = () => {
    if (descriptionInputs.length < 5) {
      setDescriptionInputs([...descriptionInputs, ""]);
    }
  };

  const handleRemoveDescriptionInput = (indexToRemove) => {
    const updatedInputs = descriptionInputs.filter((_, index) => index !== indexToRemove);
    setDescriptionInputs(updatedInputs);
  };

  useEffect(() => {
    setFieldValue("long_headlines", longHeadlineInputs);
  }, [longHeadlineInputs]);

  useEffect(() => {
    setFieldValue("headlines", headlineInputs);
  }, [headlineInputs]);

  useEffect(() => {
    setFieldValue("descriptions", descriptionInputs);
  }, [descriptionInputs]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 255 characters",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />
                    <CircularProgress
                      max={youtubeShortsValueLengths.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        youtubeShortsValueLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        youtubeShortsValueLengths?.adName
                          ? youtubeShortsValueLengths?.adName -
                          values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Headlines</FormLabel>
                {headlineInputs && headlineInputs?.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`headlines${index}`}
                          name={`headlines${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Headline ${index + 1} - max 40 characters`
                          }}
                          placeholder={`Headline ${index + 1} - max 40 characters`}
                          onChange={(e) => handleHeadlineChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={discoveryAdValueLengths?.headlines}
                          value={text.length}
                          color={
                            text.length > discoveryAdValueLengths?.headlines
                              ? "red.400"
                              : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            {text.length > discoveryAdValueLengths?.headlines
                              ? discoveryAdValueLengths?.headlines - text.length
                              : text.length}
                          </CircularProgressLabel>
                        </CircularProgress>
                        {headlineInputs.length > 1 && (
                          <Button onClick={() => handleRemoveHeadlineInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )
                })}
                {headlineInputs.length < 5 && (
                  <Button onClick={handleAddHeadlineInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Descriptions</FormLabel>
                {descriptionInputs && descriptionInputs.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`descriptions${index}`}
                          name={`descriptions${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Description ${index + 1} - max 90 characters`
                          }}
                          placeholder={`Description ${index + 1} - max 90 characters`}
                          onChange={(e) => handleDescriptionChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={discoveryAdValueLengths?.descriptions}
                          value={text.length}
                          color={
                            text.length > discoveryAdValueLengths?.descriptions
                              ? "red.400"
                              : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            {text.length > discoveryAdValueLengths?.descriptions
                              ? discoveryAdValueLengths?.descriptions - text.length
                              : text.length}
                          </CircularProgressLabel>
                        </CircularProgress>
                        {descriptionInputs.length > 1 && (
                          <Button onClick={() => handleRemoveDescriptionInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )
                })}
                {descriptionInputs.length < 5 && (
                  <Button onClick={handleAddDescriptionInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Long Headlines</FormLabel>
                {longHeadlineInputs && Array.isArray(longHeadlineInputs) && longHeadlineInputs.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`long_headlines${index}`}
                          name={`long_headlines${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Long Headline ${index + 1} - max 125 characters`
                          }}
                          placeholder={`Long Headline ${index + 1} - max 125 characters`}
                          onChange={(e) => handleLongHeadlineChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={facebookVideoAdValueLengths?.long_headlines}
                          value={text.length}
                          color={
                            text.length > facebookVideoAdValueLengths?.long_headlines
                              ? "red.400"
                              : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            {text.length > facebookVideoAdValueLengths?.long_headlines
                              ? facebookVideoAdValueLengths?.long_headlines - text.length
                              : text.length}
                          </CircularProgressLabel>
                        </CircularProgress>
                        {longHeadlineInputs.length > 1 && (
                          <Button onClick={() => handleRemoveLongHeadlineInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )
                })}
                {longHeadlineInputs.length < 5 && (
                  <Button onClick={handleAddLongHeadlineInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="channel_name" mb={0}>Channel Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="channel_name"
                      name="channel_name"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Channel Name - max 25 characters",
                      }}
                      onChange={(e) =>
                        setFieldValue("channel_name", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={discoveryAdValueLengths?.channel_name}
                      value={values?.channel_name?.length}
                      color={
                        values?.channel_name?.length >
                        discoveryAdValueLengths?.channel_name
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.channel_name?.length >
                        discoveryAdValueLengths?.channel_name
                          ? discoveryAdValueLengths?.channel_name -
                          values?.channel_name?.length
                          : values?.channel_name?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL",
                      }}
                      onChange={(e) =>
                        setFieldValue("link", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={youtubeShortsValueLengths?.link}
                      value={values?.link?.length}
                      color={
                        values?.link?.length >
                        youtubeShortsValueLengths?.link
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.link?.length >
                        youtubeShortsValueLengths?.link
                          ? youtubeShortsValueLengths?.link -
                          values?.link?.length
                          : values?.link?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="type" mb={0}>Call to Action</FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="type"
                      name="type"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("type", e.target.value);
                      }}
                    >
                      {youtubeShortsTypes.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            <Button
              mr={4}
              colorScheme="green"
              type="submit"
              variant="solid"
              borderRadius={0}
              px={10}
              isDisabled={Object.keys(errors).length > 0}
            >
              Next
            </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default WithAppContext(DiscoveryAdDetails);
