import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Container, css,
    Flex,
    GridItem,
    Heading,
    Image,
    SkeletonCircle,
    Spacer, Tab,
    TabList, TabPanel, TabPanels, Tabs,
    Text
} from "@chakra-ui/react"
import WithAppContext from "../../../../../../../helpers/appContext";
import {useDispatch, useSelector} from "react-redux";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import {deleteTheField} from "../../../../../../../store/adUploads";
import {useNavigate, useParams} from "react-router-dom";
import {deleteAllSocialDisplayVideoField} from "./store";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import instance from "../../../../../../../helpers/axios";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import SDImage970_250 from "../components/SDImage970_250";
import SDImage728_90 from "../components/SDImage728_90";
import SDImage300_600 from "../components/SDImage300_600";
import SDImage320_480 from "../components/SDImage320_480";
import SDImage336_280 from "../components/SDImage336_280";
import SDImage250_250 from "../components/SDImage250_250";
import OuterUiForPreview from "../components/OuterUIForPreview";
import SdImage300250 from "../components/SDImage300_250";
import {fetchVideoMetadata} from "../../LinkedIn/LinkedinVideo/LinkedInVideoAdPreview";
import {CreateMultipleCreatives} from "../SocialDisplayImage/SocialDisplayImageAdPreview";

function SocialDisplayVideoAdPreview(props) {
    const {activeStep, steps, prevStep, nextStep, label, context} = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id: campaignId, adUploadId} = useParams();
    const { formDetails, mediaDetails, handlePreviewData } = useSelector((state) => state.socialDisplayVideoAd);
    const clientId = useSelector((state) => state.client.clientId);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {creative, selectedSocialMedia} = useSelector((state) => state.adUpload)
    const { allCampaignIds } = useSelector((state) => state.adUpload);
    // let dimensions = mediaDetails?.dimensions

    const {mutateAsync} = useUploadImage();

    console.log('formDetails', formDetails)
    console.log('mediaDetails', mediaDetails)
    console.log('handlePreviewData', handlePreviewData)

    const onSubmitImages = async (multiFiles) => {

        let files = [];

        if(multiFiles[0].filename){
            files = [...multiFiles]
        }

        if(!multiFiles[0].filename){
            await mutateAsync(
                {
                    clientId: clientId,
                    campaignBriefId: campaignId,
                    adFile: multiFiles,
                    type: "socialDisplay",
                },
                {
                    onSuccess: (data, variables, context) => {
                        setAppLoading(false)
                        files = data.fileInfoList;
                    },
                    onError: (error) => {
                        setAppLoading(false)
                        setDescription(error?.response?.data?.message);
                    }
                }
            );
        }

        return files;
    }

    const onCloseSuccessModal = () => {
        setSuccessModal(false)
        if (adUploadId) {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
                    state: {isShowUpload: true}
                })
            }
            dispatch(deleteAllSocialDisplayVideoField())
            dispatch(deleteTheField())
        } else {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`)
            }
            dispatch(deleteAllSocialDisplayVideoField())
            dispatch(deleteTheField())
        }
    }

    async function handleSubmit() {
        setAppLoading(true);
        const image = await onSubmitImages(mediaDetails.fileURL);

        const videoInfoList = await fetchVideoMetadata(image);
        console.log("videoInfoList ", videoInfoList);

        let payload = {
            name: formDetails.adName,
            // description: formDetails.description,
            description: formDetails.descriptions[0],
            title: formDetails.title,
            link: formDetails.link,
            detail: {
                name: formDetails.adName,
                // headline: formDetails.headline,
                headlines: formDetails.headlines,
                caption: formDetails.caption,
                shortCaption: formDetails.shortCaption,
                // description: formDetails.description,
                descriptions: formDetails.descriptions,
                // long_headline: formDetails.long_headline,
                // youtubeVideoLink: formDetails.youtubeVideoLink,
                channel_name: formDetails.channel_name,
                title: formDetails.title,
                call_to_action: {
                    type: formDetails.type,
                    link: formDetails.link,
                },
                channel_icon: formDetails.channelIcon,
                selectedVideos: videoInfoList,
                // selectedLogos: logoFileInfoList,
                creative,
                channel: selectedSocialMedia
            },
        };

        console.log('payload', payload)

        if (!adUploadId) {
            payload = {
                ...payload,
                ad_upload_type: AD_UPLOAD_TYPE.SOCIAL_DISPLAY_VIDEO,
            };
        }

        if(allCampaignIds?.length){
            CreateMultipleCreatives(allCampaignIds, method, url, clientId, payload, setSuccessModal, setErrorModal, setAppLoading, setDescription, dispatch);
        } else {
            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSuccessModal(true);
                        setAppLoading(false)
                    }
                })
                .catch((error) => {
                    setDescription(error.response.data.message);
                    setErrorModal(true);
                    setAppLoading(false)
                });
        }
    }

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    return (
        <>
            <Tabs isFitted variant='enclosed'>
                <TabList>
                    <Tab>
                        970x250
                    </Tab>
                    <Tab>
                        728x90
                    </Tab>
                    <Tab>
                        250x250
                    </Tab>
                    <Tab>
                        336x280
                    </Tab>
                    <Tab>
                        320x480
                    </Tab>
                    <Tab>
                        300x600
                    </Tab>
                    <Tab>
                        300x250
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <OuterUiForPreview>
                            <SDImage970_250 formDetails={formDetails} mediaDetails={mediaDetails} handlePreviewData={handlePreviewData} video={true}/>
                        </OuterUiForPreview>
                    </TabPanel>
                    <TabPanel>
                        <OuterUiForPreview>
                            <SDImage728_90 formDetails={formDetails} mediaDetails={mediaDetails} handlePreviewData={handlePreviewData} video={true}/>
                        </OuterUiForPreview>
                    </TabPanel>
                    <TabPanel>
                        <OuterUiForPreview>
                            <SDImage250_250 formDetails={formDetails} mediaDetails={mediaDetails} handlePreviewData={handlePreviewData} video={true}/>
                        </OuterUiForPreview>
                    </TabPanel>
                    <TabPanel>
                        <OuterUiForPreview>
                            <SDImage336_280 formDetails={formDetails} mediaDetails={mediaDetails} handlePreviewData={handlePreviewData} video={true}/>
                        </OuterUiForPreview>
                    </TabPanel>
                    <TabPanel>
                        <OuterUiForPreview>
                            <SDImage320_480 formDetails={formDetails} mediaDetails={mediaDetails} handlePreviewData={handlePreviewData} video={true}/>
                        </OuterUiForPreview>
                    </TabPanel>
                    <TabPanel>
                        <OuterUiForPreview>
                            <SDImage300_600 formDetails={formDetails} mediaDetails={mediaDetails} handlePreviewData={handlePreviewData} video={true}/>
                        </OuterUiForPreview>
                    </TabPanel>
                    <TabPanel>
                        <OuterUiForPreview>
                            <SdImage300250 formDetails={formDetails} mediaDetails={mediaDetails} handlePreviewData={handlePreviewData} video={true}/>
                        </OuterUiForPreview>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Flex width="100%" justify="flex-end" py={10}>
                <Button
                    mr={4}
                    onClick={prevStep}
                    colorScheme='blue' variant='outline' borderRadius={0} px={10}
                >
                    Back
                </Button>
                <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                    {adUploadId ? 'Update' : 'Submit'}
                </Button>
            </Flex>
            <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => onCloseSuccessModal()}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModal(false)}
                description={description}
            />
        </>
    );
}


function RenderImageComponent({ dimensions }) {
    const { width, height } = dimensions;
    const { formDetails, mediaDetails, handlePreviewData } = useSelector((state) => state.socialDisplayVideoAd);

    switch (`${width}x${height}`) {
        case '970x250':
            return <SDImage970_250 formDetails={formDetails} mediaDetails={mediaDetails} />;
        case '728x90':
            return <SDImage728_90 formDetails={formDetails} mediaDetails={mediaDetails} />;
        case '250x250':
            return <SDImage250_250 formDetails={formDetails} mediaDetails={mediaDetails} />;
        case '336x280':
            return <SDImage336_280 formDetails={formDetails} mediaDetails={mediaDetails} />;
        case '320x480':
            return <SDImage320_480 formDetails={formDetails} mediaDetails={mediaDetails} />;
        case '300x600':
            return <SDImage300_600 formDetails={formDetails} mediaDetails={mediaDetails} />;
        default:
            return <SDImage300_600 formDetails={formDetails} mediaDetails={mediaDetails} />;
    }
}

export default  WithAppContext(SocialDisplayVideoAdPreview);
