import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const audioSlice = createSlice({
    name: "audio",
    initialState,
    reducers: {
        settingAudioFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingAudioFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingAudioPreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllAudioField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingAudioFormDetail, settingAudioFormMedia, settingAudioPreviewData, deleteAllAudioField} = audioSlice.actions;

// The reducer
export const audioReducer = audioSlice.reducer;
