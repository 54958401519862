import { useQuery } from 'react-query'
import axios from '../../helpers/axios'

export const useDeleteAdUpload = (clientId, campaignId, adId) => {
    return useQuery(['campaign', clientId], async () => {
        if(adId){
            return axios
                .delete(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adId}`, {
                    withCredentials: false,
                })
                .then((res) => {
                    console.log("res", res);
                    // if (res.status === 200) {
                    //     return res.data.data
                    // }
                    return res
                })
                .catch((err) => {
                    console.log("err", err);
                    return err.response
                })
        }
    })
}
