import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box, Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  SkeletonCircle,
  SkeletonText, Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import {useSelector} from "react-redux";

function YoutubeVideoInFeed({formDetails, appLoading, ads, index}) {
  const {handlePreviewData} = useSelector((state) => state.performanceMaxAd)
  console.log(`handlePreview`, handlePreviewData);

  const selectedLogos = (Array.isArray(handlePreviewData?.selectedLogos) && handlePreviewData?.selectedLogos[0]?.imageUrl) ?
      (handlePreviewData?.selectedLogos[0]?.imageUrl) :
      process.env.PUBLIC_URL + "/images/display.png";

  console.log(`selectedLogos`, selectedLogos, handlePreviewData?.selectedLogos[0]?.imageUrl, formDetails)

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%'>
                <Box backgroundColor='gray.300' borderBottom='1px #e3e8f1 solid' textAlign='center' borderTopRadius="25px"
                     alignSelf="center">
                  <Flex justifyItems="center" px={3} justifyContent="space-between" py={3} backgroundColor="white"
                        borderTopRadius="25">
                    <Flex alignItems="center" justifyContent="center">
                      <Image src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="YouTube-Icon" height={8}
                             width={8}/>
                      <Box ml={1} fontWeight="bolder">YouTube</Box>
                    </Flex>
                    <Flex alignItems="center" justifyContent="center">
                      <SkeletonCircle size='6'/>
                    </Flex>
                  </Flex>
                </Box>
                <Box width="100%" height="55%" bg='white' px={2}>
                  <Box display="flex" gap="5" padding='4' height="15%">
                    <SkeletonCircle size='10'/>
                    <SkeletonText mt='1' noOfLines={2} spacing='3' skeletonHeight='3' width="70%"/>
                  </Box>

                  <Flex mx={3} mt={4} fontWeight="600" alignItems="center">
                    <Image src={selectedLogos} height={12}
                           width={12} rounded="full"/>
                    <Box width="90%">
                      <Flex mx={3} mt={8} fontWeight="600" justifyContent="space-between" alignItems="center">
                        <Text fontSize="14px">{formDetails.long_headlines[0]}</Text>
                        <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Back Button" height={4}
                               width={4}/>
                      </Flex>
                      <Text mx={3} mt={4} fontSize="12px"> {formDetails.descriptions[0]} </Text>
                      <Text fontSize="12px" mx={3} fontWeight="500"> Sponsored </Text>
                    </Box>
                  </Flex>

                  <Center>
                    <button
                      style={{color: "#ffffff", backgroundColor: "#007be3", border: "1px solid #006dff", fontSize: '14px', fontWeight: "500", width: "100%", margin: "2vh 4vh", padding: "3px"}}>
                      {youtubeShortsTypes.find((i) => i.key === formDetails.call_to_action?.type)?.name}
                    </button>
                  </Center>
                </Box>
                <Box height="25%" bg='gray.300' mx={2}/>
                <Flex justifyContent="space-around" pt={8}>
                  <SkeletonCircle />
                  <SkeletonCircle />
                  <SkeletonCircle />
                  <SkeletonCircle />
                  <SkeletonCircle />
                </Flex>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default YoutubeVideoInFeed;
