import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    campaignDetails: {},
    campaignBudget: {},
    objective: "",
    demographics: {},
    mediaStrategies: {},
    businessDetails: {},
    demographicDetails: {},
    mediaStrategyDetails: {},
    notes: [],
    cloneDetails: []
}

export const adCampaignSlice = createSlice({
    name: "AdCampaign",
    initialState,
    reducers: {
        settingCampaignDetails: (state, action) => {
            state.campaignDetails = action.payload
        },
        settingCampaignObjective: (state, action) => {
            state.objective = action.payload
        },
        settingCampaignBudget: (state, action) => {
            state.campaignBudget = action.payload
        },
        settingDemographics: (state, action) => {
            state.demographics = action.payload
        },
        settingMediaStrategies: (state, action) => {
            state.mediaStrategies = action.payload
        },
        settingBusinessDetails: (state, action) => {
            state.businessDetails = action.payload
        },
        settingDemographyDetails: (state, action) => {
            state.demographicDetails = action.payload
        },
        settingMediaStrategyDetails: (state, action) => {
            state.mediaStrategyDetails = action.payload
        },
        settingNotes: (state, action) => {
            state.notes = action.payload
        },
        settingCloneDetails: (state, action) => {
            state.cloneDetails = action.payload
        },
        deleteAllAdCampaignFields: (state, action) => {
            state.campaignDetails = {};
            state.objective = "";
            state.campaignBudget = {};
            state.demographics = {};
            state.mediaStrategies = {};
            state.businessDetails = {};
            state.demographicDetails = {};
            state.mediaStrategyDetails = {};
            state.notes = [];
            state.cloneDetails = [];
        }
    }
})

export const {
    settingCampaignDetails,
    settingCampaignObjective,
    settingCampaignBudget,
    settingDemographics,
    settingMediaStrategies,
    deleteAllAdCampaignFields,
    settingBusinessDetails,
    settingDemographyDetails,
    settingMediaStrategyDetails,
    settingNotes,
    settingCloneDetails
} = adCampaignSlice.actions

export const adCampaignReducer = adCampaignSlice.reducer;
