import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const dynamicFacebookVideoSlice = createSlice({
    name: AD_UPLOAD_TYPE.DYNAMIC_FBVIDEO,
    initialState,
    reducers: {
        settingDynamicFacebookVideoFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingDynamicFacebookVideoFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingDynamicFacebookVideoPreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllDynamicFacebookVideoField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null
        }
    },
});

export const { settingDynamicFacebookVideoFormDetail, settingDynamicFacebookVideoFormMedia, settingDynamicFacebookVideoPreviewData, deleteAllDynamicFacebookVideoField} = dynamicFacebookVideoSlice.actions;

// The reducer
export const dynamicFacebookVideoReducer = dynamicFacebookVideoSlice.reducer;
