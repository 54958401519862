import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";

const initialState = {
  formDetails: {},
  mediaDetails: [],
  handlePreviewData: null,
};

export const performanceMaxSlice = createSlice({
  name: AD_UPLOAD_TYPE.PERFORMANCE_MAX,
  initialState,
  reducers: {
    settingPerformanceMaxForm: (state, action) => {
      state.formDetails = action.payload
    },
    settingPerformanceMaxMedia: (state, action) => {
      state.mediaDetails = action.payload
    },
    settingPerformanceMaxPreview: (state, action) => {
      state.handlePreviewData = action.payload
    },
    deleteAllPerformanceMaxField: (state, action) => {
      state.formDetails = {};
      state.mediaDetails = [];
      state.handlePreviewData = null
    }
  },
});

export const { settingPerformanceMaxForm, settingPerformanceMaxMedia, settingPerformanceMaxPreview, deleteAllPerformanceMaxField} = performanceMaxSlice.actions;

// The reducer
export const performanceMaxReducer = performanceMaxSlice.reducer;
