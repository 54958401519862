import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from "@chakra-ui/modal";
import {Button, useToast} from "@chakra-ui/react";
import {Form, Formik} from "formik";
import React, {useState} from "react";
import * as yup from "yup";
import {facebookCarouselFileInitialValues} from "../../../../../constant/InitialValues";
import {UploadImageFile} from './UploadImageFile';
import WithAppContext from "../../../../../../../helpers/appContext";

const DemandGenCarouselFileUpload = ({
                                       cardModalType,
                                       isShowCardModal,
                                       setIsShowCardModal,
                                       createCarouselAd,
                                       initialModalData,
                                       setInitialModalData,
                                       activeCarouselCardId,
                                       setActiveCarouselCardId,
                                       context
                                     }) => {
  const [formData, setFromData] = useState(initialModalData);
  const {setAppLoading, SuccessToaster, ErrorToaster, appLoading} = context;

  const imageValidationSchema = yup.object().shape({
    name: yup.string().trim().min(3).max(125).required().label('Name'),
    description: yup.string().trim().min(3).max(125).required().label('Description'),
    imageURL: yup.object().required().nullable(),
    link: yup.string().trim().required().label("Landing page url")
      .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      ),
  });
  const videoValidationSchema = yup.object().shape({
    name: yup.string().trim().min(3).max(125).required().label('Name'),
    description: yup.string().trim().min(3).max(125).required().label('Description'),
    videoURL: yup.object().required().nullable(),
    imageURL: yup.object().required().nullable(),
    link: yup.string().trim().required().label("Landing page url")
      .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      ),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialModalData}
      validationSchema={cardModalType === 'image' ? imageValidationSchema : videoValidationSchema}
    >
      {({dirty, isValid, isSubmitting, values, errors, touched, setFieldValue, resetForm,}) => {
        const onDrop = (accFiles) => {
          const files = values.imageURL ? [...values.imageURL, ...accFiles] : [...accFiles];
          if (files.length > 3) {
            ErrorToaster("Maximum 3 images are allowed");
          } else {
            setFieldValue("imageURL", files);
          }
        }

        const onClearData = () => {
          setFromData(facebookCarouselFileInitialValues)
          setInitialModalData(facebookCarouselFileInitialValues)
          setIsShowCardModal(false)
          resetForm()
          setActiveCarouselCardId(null)
        }

        const onSubmitData = async (values) => {
          createCarouselAd({...values, fileType: cardModalType})
          onClearData()
        }

        return (
          <Form autoComplete="off">
            <Modal isOpen={isShowCardModal} size="xl" onClose={onClearData}>
              <ModalOverlay/>
              <ModalContent>
                <ModalHeader>Add {cardModalType} card</ModalHeader>
                <ModalBody>
                  {cardModalType === 'image' &&
                  <UploadImageFile
                    setIsShowCardModal={setIsShowCardModal}
                    onDrop={onDrop}
                    touched={touched}
                    errors={errors}
                    setFromData={setFromData}
                    setFieldValue={setFieldValue}
                    values={values}
                    formData={formData}
                  />
                  }
                </ModalBody>
                <ModalFooter marginTop="5" justifyContent="flex-end">
                  <Button
                    type="submit"
                    onClick={() => onSubmitData(values)}
                  >{activeCarouselCardId !== null ? 'Update' : 'Add'}
                  </Button>
                  <Button marginLeft="20px" onClick={onClearData}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
}


export default WithAppContext(DemandGenCarouselFileUpload)
