import {useGlobalContext} from "../store/WrapContext";
import "../css/mobileView.css"
import _ from 'lodash';
import {IoMdCall} from "react-icons/io";
import {useEffect, useState} from "react";

function MobileView() {
    const {selectedData, siteLinks, extension, headline, description} = useGlobalContext()
    const {call, location, callout} = extension

    let randomHeadline = _.sampleSize(headline, 3)
    let randomDescription = _.sampleSize(description, 2)

    const [randomHeadlineState, setRandormHeadlineState] = useState(randomHeadline)
    const [randomDescriptionState, setRandomDescriptionState] = useState(randomDescription)

    useEffect(() => {
        setInterval(() => {
            setRandormHeadlineState(_.sampleSize(headline, 3))
            setRandomDescriptionState(_.sampleSize(description, 2))
        }, 5000)
    }, [])


    return (
        <div style={{display: "flex", justifyContent: "center", margin: "20px 0"}}>
            <div className="mobile">
                <div className="mobile-top">
                    <div className="mobile-top-header">
                        <b>Ad</b>
                        <span>i</span>
                    </div>
                    <div className="header">
                        {
                            randomHeadline && randomHeadline.length > 0 && randomHeadlineState.map((item, index) => {
                                return <span className='fade'>{`${item} ${index > 1 ? "" : "|"} `}</span>
                            })
                        }
                    </div>
                    <div className="description">
                        {
                            randomDescriptionState && randomDescriptionState.length > 0 && randomDescriptionState.map((item, index) => {
                                    return (
                                        <span className='fade' key={index}>{item} </span>
                                    )
                                }
                            )
                        }
                        {
                            callout && callout.length > 0 && callout.map((item, index, el) => {
                                return <span>{item}{!(el.length === (index + 1)) ? "," : null} </span>

                            })
                        }

                        {
                            location && location.length > 0 && location.map((item, index, el) => {
                                return <span>{item}{!(el.length === (index + 1)) ? "," : null} </span>

                            })
                        }
                    </div>
                </div>
                {
                    siteLinks.length > 0 && <div className="sitelink">
                        <ul>
                            {
                                siteLinks.map((item) => {
                                    const {sitelink_text} = item
                                    return (
                                        <li> {sitelink_text} </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }

                {
                    extension?.call.length > 0 && <div className="call">
                        <IoMdCall/> <span>9377024392</span>
                    </div>
                }
            </div>
        </div>

    )
}

export default MobileView