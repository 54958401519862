import React, {useEffect} from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box, Center,
  Container, Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  SkeletonCircle,
  SkeletonText, Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import {CloseIcon} from "@chakra-ui/icons";
import {useSelector} from "react-redux";

function YoutubeVideoInStream({formDetails, appLoading, index, handleIndex, businessName, youtubeVideoLink}) {
  const [ads, setAds] = React.useState([]);
  const {handlePreviewData} = useSelector((state) => state.performanceMaxAd)

  const selectedLogos = (Array.isArray(handlePreviewData?.selectedLogos) && handlePreviewData?.selectedLogos[0]?.imageUrl) ?
      (handlePreviewData?.selectedLogos[0]?.imageUrl) :
      process.env.PUBLIC_URL + "/images/display.png";

  useEffect(() => {
    const imageUrls = youtubeVideoLink.map((item) => {
      let video_id = item.split('v=')[1];
      let ampersandPosition = video_id?.indexOf('&');
      if(ampersandPosition && ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
      return {imageUrl: `https://img.youtube.com/vi/${video_id}/sddefault.jpg`}
    })
    setAds(imageUrls)
  }, [youtubeVideoLink]);

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%' overflow='hidden'>
                <Box width="100%" height="100%" boxShadow='sm' bg='white' borderTopRadius="25" position="relative" className={"Ok"} zIndex="1">
                  <Box>
                    {(ads && ads.length !== 0) ?
                      <Box overflow="hidden" width={"100%"}>
                        <Swiper navigation={true} modules={[Navigation]}
                                onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                          {
                            ads.map((item) => {
                              return (
                                <SwiperSlide>
                                  <Image
                                    borderTopRadius="25"
                                    height="70%"
                                    width={"100%"}
                                    objectFit={"fill"}
                                    src={item?.imageUrl}
                                    className="previewImage"
                                  />
                                </SwiperSlide>
                              )
                            })
                          }
                        </Swiper>
                      </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                    }
                  </Box>
                  <Flex position="absolute" top="30%" right="0%" bg="black" zIndex="2" border="1px solid white" boxShadow="0px 1px 6px #ffffff" p={4} py={2}
                        justifyContent="center" alignItems="center" gap={1}>
                    <Text color="white" fontSize="10px">Skip Ad </Text>
                    <Image src={`${process.env.PUBLIC_URL}/images/play_skip_forward_icon.svg`} alt="Play-Icon" height={4}
                           width={4}/>
                  </Flex>
                  <Box position="absolute" bg="yellow" zIndex="2"px={20} py={0.5} my={-1}
                       justifyContent="center" alignItems="center" gap={1} />
                  <Flex width="100%" justifyContent="space-between" alignItems="center" px={5} pt={3} gap={3}>
                    <Flex gap={2}>
                      <Image src={selectedLogos} height={9}
                             width={9} rounded="full"/>
                      <Box>
                        <Text fontSize="12px" fontWeight="bolder">{formDetails.headlines[0]}</Text>
                        <Text fontSize="14px" fontWeight="500">Sponsored</Text>
                      </Box>
                    </Flex>
                    <Flex>
                      <button style={{color: "#ffffff", backgroundColor: "#007be3", padding: '1vh 2vh', fontSize: '14px', fontWeight: "500"}}>{youtubeShortsTypes.find((i) => i.key === formDetails.call_to_action?.type)?.name}</button>
                    </Flex>
                  </Flex>
                  <Box p={3}>
                    <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="100%"/>
                    <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="80%"/>
                    <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="60%"/>
                  </Box>
                  <Flex p={3} justifyContent="space-around">
                    {[...Array(5)].map((_, index) => (
                      <SkeletonCircle size={6}/>
                    ))}
                  </Flex>
                  <Divider />
                  <Flex p={3} gap={3} alignItems="center">
                    <SkeletonCircle size={8}/>
                    <SkeletonText noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="60%"/>
                  </Flex>
                  <Divider />
                {[...Array(4)].map((_, index) => (
                  <Flex justifyContent="space-between" px={1} mt={4}>
                    <SkeletonText mt='1' noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="20%"/>
                    <SkeletonText mt='1' noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="30%"/>
                  </Flex>
                ))}
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default YoutubeVideoInStream;
