import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
    handleInstaPreviewData: null,
    handleFacebookPreviewData: null,
};

export const metaImageSlice = createSlice({
    name: AD_UPLOAD_TYPE.META_IMAGE,
    initialState,
    reducers: {
        settingMetaImageFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingMetaImageFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingMetaImagePreviewData: (state, action) => {
            state.handlePreviewData = action.payload
        },
        settingMetaInstagramImagePreviewData: (state, action) => {
            state.handleInstaPreviewData = action.payload
        },
        settingMetaFacebookImagePreviewData: (state, action) => {
            state.handleFacebookPreviewData = action.payload
        },
        deleteAllMetaImageField: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = [];
            state.handlePreviewData = null;
            state.handleInstaPreviewData = null;
            state.handleFacebookPreviewData = null
        }
    },
});

export const { settingMetaImageFormDetail, settingMetaImageFormMedia, settingMetaImagePreviewData, settingMetaInstagramImagePreviewData, settingMetaFacebookImagePreviewData, deleteAllMetaImageField} = metaImageSlice.actions;

// The reducer
export const metaImageReducer = metaImageSlice.reducer;
