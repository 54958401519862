import { createSlice } from "@reduxjs/toolkit";
import { AD_UPLOAD_TYPE } from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: null,
    handlePreviewData: null,
};

export const snapchatVideoSlice = createSlice({
    name: AD_UPLOAD_TYPE.SNAPCHAT_VIDEO,
    initialState,
    reducers: {
        settingSnapchatVideoFormDetail: (state, action) => {
            state.formDetails = action.payload;
        },
        settingSnapchatVideoFormMedia: (state, action) => {
            state.mediaDetails = action.payload;
        },
        settingSnapchatVideoHandlePreview: (state, action) => {
            state.handlePreviewData = action.payload;
        },
        deleteAllSnapchatVideoFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const {deleteAllSnapchatVideoFields, settingSnapchatVideoFormDetail, settingSnapchatVideoFormMedia, settingSnapchatVideoHandlePreview} = snapchatVideoSlice.actions;


export const snapchatVideoReducer = snapchatVideoSlice.reducer;
