import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: null,
    handlePreviewData: null,
};

export const linkedInVideoSlice = createSlice({
    name: AD_UPLOAD_TYPE.LINKEDIN_VIDEO,
    initialState,
    reducers: {
        settingLinkedInVideoFormDetail: (state, action) => {
            state.formDetails = action.payload
        },
        settingLinkedInVideoFormMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingLinkedInVideoHandlePreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllLinkedInVideoFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { deleteAllLinkedInVideoFields,settingLinkedInVideoFormDetail, settingLinkedInVideoFormMedia, settingLinkedInVideoHandlePreview } = linkedInVideoSlice.actions;

// The reducer
export const linkedInVideoReducer = linkedInVideoSlice.reducer;
