import DV360ImageAdPreview from "./DV360/DV360Image/DV360ImageAdPreview";
import {useSelector} from "react-redux";
import React from "react";
import DV360YoutubePreview from "./DV360/DV360YoutubeVideo/DV360YoutubePreview";
import PinterestImageAdPreview from "./Pintrest/PinterestImage/PinterestImageAdPreview";
import DV360VideoAdPreview from "./DV360/DV360Video/DV360VideoAdPreview";
import PinterestVideoAdPreview from "./Pintrest/PinterestVideo/PinterestVideoAdPreview";
import TiktokVideoAdPreview from "./Tiktok/TiktokVideo/TiktokVideoAdPreview";
import DV360AudioPreview from "./DV360/DV360Audio/DV360AudioPreview";
import PinterestCarouselAdPreview from "./Pintrest/PinterestCarousel/PinterestCarouselAdPreview";
import FacebookImageAdPreview from "./Facebook/FacebookImage/FacebookImageAdPreview";
import InstagramImageAdPreview from "./Instagram/InstagramImage/InstagramImageAdPreview";
import InstagramVideoAdPreview from "./Instagram/InstagramVideo/InstagramVideoAdPreview";
import FacebookVideoAdPreview from "./Facebook/FacebookVideo/FacebookVideoAdPreview";
import FacebookCarouselAdPreview from "./Facebook/FacebookCarousel/FacebookCarouselAdPreview";
import InstagramCarouselAdPreview from "./Instagram/InstagramCarousel/InstagramCarouselAdPreview";
import GoogleAdsTableData from "./Google/GoogleCsv/GoogleAdsTableData";
import {WrapContext} from "./Google/GoogleCsv/store/WrapContext";
import {MicrosoftWrapContext} from "./Microsoft/MicrosoftCsv/store/WrapContext";
import MetaCarouselAdPreview from "./Meta/MetaCarousel/MetaCarouselAdPreview";
import MetaVideoAdPreview from "./Meta/MetaVideo/MetaVideoAdPreview";
import MetaImageAdPreview from "./Meta/MetaImage/MetaImageAdPreview";
import DynamicFacebookVideoAdPreview from "./Facebook/DynamicFacebookVideo/DynamicFacebookVideoAdPreview";
import DynamicFacebookImageAdPreview from "./Facebook/DynamicFBImage/DynamicFacebookImageAdPreview";
import DynamicFacebookBothAdPreview from "./Facebook/DynamicFacebookBoth/DynamicFacebookBothAdPreview";
import DynamicInstagramImageAdPreview from "./Instagram/DynamicInstagramImage/DynamicInstagramImageAdPreview";
import DynamicInstagramVideoAdPreview from "./Instagram/DynamicInstagramVideo/DynamicInstagramVideoAdPreview";
import DynamicInstagramBothAdPreview from "./Instagram/DynamicInstagramBoth/DynamicInstagramBothAdPreview";
import YoutubeShortsAdPreview from "./Youtube/YoutubeShorts/YoutubeShortsAdPreview";
import DemandGenImageAdPreview from "./DemandGen/DemandGenImage/DemandGenImageAdPreview";
import DemandGenVideoAdPreview from "./DemandGen/DemandGenVideo/DemandGenVideoAdPreview";
import DemandGenCarouselAdPreview from "./DemandGen/DemandGenCarousel/DemandGenCarouselAdPreview";
import PerformanceMaxAdPreview from "./Performance/PerformanceMaxAdPreview";
import SocialDisplayImageAdPreview from "./SocialDisplay/SocialDisplayImage/SocialDisplayImageAdPreview";
import MicrosoftAdsTableData from "./Microsoft/MicrosoftCsv/MicrosoftAdsTableData";
import AudioPreview from "./Audio/AudioPreview";
import SocialDisplayVideoAdPreview from "./SocialDisplay/SocialDisplayVideo/SocialDisplayVideoAdPreview";
import LinkedInImageAdPreview from "./LinkedIn/LinkedinImage/LinkedInImageAdPreview";
import LinkedInVideoAdPreview from "./LinkedIn/LinkedinVideo/LinkedInVideoAdPreview";
import DiscoveryAdPreview from "./Discovery/DiscoveryAdPreview";
import SnapchatVideoAdPreview from "./Snapchat/SnapchatVideo/SnapchatVideoAdPreview"
import SnapchatImageAdPreview from "./Snapchat/SnapchatImage/SnapchatImageAdPreview";

function AdPreview({ activeStep, steps, prevStep, nextStep, label}) {
    const {mediaType, editMediaType} = useSelector((state) => state.adUpload)

    if(mediaType === "dv360" || editMediaType === "DV360") {
        return (
            <DV360ImageAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    if(mediaType === "PINTEREST_IMAGE" || editMediaType === "PinterestImage") {
        return (
            <PinterestImageAdPreview activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "PINTEREST_VIDEO" || editMediaType === "PinterestVideo") {
        return (
            <PinterestVideoAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "PINTEREST_CAROUSEL" || editMediaType === "PinterestCarousel") {
        return (
            <PinterestCarouselAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "TIKTOK_VIDEO" || editMediaType === "TikTokVideo") {
        return (
            <TiktokVideoAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "SNAPCHAT_VIDEO" || editMediaType === "SnapchatVideo") {
        return (
            <SnapchatVideoAdPreview activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
        )
    }
    if(mediaType === "SNAPCHAT_IMAGE" || editMediaType === "SnapchatImage") {
        return (
            <SnapchatImageAdPreview activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_VIDEO" || editMediaType === "InstagramVideo") {
        return (
            <InstagramVideoAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_IMAGE" || editMediaType === "InstagramImage") {
        return (
            <InstagramImageAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_CAROUSEL" || editMediaType === "InstagramCarousel") {
        return (
            <InstagramCarouselAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_VIDEO" || editMediaType === "FBVideo") {
        return (
            <FacebookVideoAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_IMAGE" || editMediaType === "FACEBOOK") {
        return (
            <FacebookImageAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_CAROUSEL" || editMediaType === "FBCarousel") {
        return (
            <FacebookCarouselAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    // Dynamic
    if(mediaType === "DYNAMIC_FACEBOOK_VIDEO" || editMediaType === "DynamicFBVideo") {
        return (
            <DynamicFacebookVideoAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "DYNAMIC_FACEBOOK_IMAGE" || editMediaType === "DYNAMIC_FACEBOOK") {
        return (
            <DynamicFacebookImageAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "DYNAMIC_FACEBOOK_BOTH" || editMediaType === "DynamicFBBoth") {
        return (
            <DynamicFacebookBothAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    if(mediaType === "DYNAMIC_INSTAGRAM_IMAGE" || editMediaType === "DynamicInstagramImage") {
        return (
            <DynamicInstagramImageAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "DYNAMIC_INSTAGRAM_VIDEO" || editMediaType === "DynamicInstagramVideo") {
        return (
            <DynamicInstagramVideoAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "DYNAMIC_INSTAGRAM_BOTH" || editMediaType === "DynamicInstagramBoth") {
        return (
            <DynamicInstagramBothAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    // Meta
    if(editMediaType === "META_IMAGE" || mediaType === "MetaImage") {
        return <MetaImageAdPreview activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    if(mediaType === "META_VIDEO" || editMediaType === "MetaVideo") {
        return <MetaVideoAdPreview activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    if(mediaType === "META_CAROUSEL" || editMediaType === "MetaCarousel") {
        return <MetaCarouselAdPreview activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    if(editMediaType === "DV360YoutubeVideo" || mediaType === "DV360_YOUTUBE_VIDEO") {
        return <DV360YoutubePreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(editMediaType === "DV360Video" || mediaType === "DV360_VIDEO") {
        return <DV360VideoAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}  />
    }

    if(editMediaType === "DV360Audio" || mediaType === "DV360_AUDIO") {
        return <DV360AudioPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}  />
    }

    if(editMediaType === "Google" || mediaType === "display ads") {
        return <WrapContext><GoogleAdsTableData activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} /></WrapContext>
    }

    if(editMediaType === "Microsoft" || mediaType === "MICROSOFT") {
        return <MicrosoftWrapContext><MicrosoftAdsTableData activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} /></MicrosoftWrapContext>
    }

    if(editMediaType === "YOUTUBE_SHORTS_VIDEO" || mediaType === "YOUTUBE_SHORTS_VIDEO") {
        return <YoutubeShortsAdPreview activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    // Demand Gen

    if(mediaType === "DEMAND_GEN_IMAGE" || editMediaType === "DemandGenImage") {
        return (
          <DemandGenImageAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "DEMAND_GEN_VIDEO" || editMediaType === "DemandGenVideo") {
        return (
          <DemandGenVideoAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "DEMAND_GEN_CAROUSEL" || editMediaType === "DemandGenCarousel") {
        return (
          <DemandGenCarouselAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    // Performance Max
    if(mediaType === "PERFORMANCE_MAX" || editMediaType === "PerformanceMax") {
        return (
          <PerformanceMaxAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    // Social display
    if(mediaType === "SOCIAL_DISPLAY_IMAGE") {
        return (
            <SocialDisplayImageAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "SOCIAL_DISPLAY_VIDEO") {
        return (
            <SocialDisplayVideoAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    // Audio
    if(editMediaType === "Audio" || mediaType === "AUDIO") {
        return <AudioPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}  />
    }

    // Linkedin
    if(editMediaType === "LinkedinImage" || mediaType === "LINKEDIN_IMAGE") {
        return <LinkedInImageAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}  />
    }
    if(editMediaType === "LinkedinVideo" || mediaType === "LINKEDIN_VIDEO") {
        return <LinkedInVideoAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}  />
    }


    // Discovery
    if(mediaType === "DISCOVERY" || editMediaType === "Discovery") {
        return (
            <DiscoveryAdPreview  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
}

export default AdPreview
