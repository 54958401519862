import React, {useState, useRef, useEffect, useMemo} from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Flex,
    Text,
    Tooltip,
    IconButton,
    Button,
    HStack, Collapse,
} from "@chakra-ui/react";

import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    getExpandedRowModel,
    ColumnDef,
    flexRender, getSortedRowModel,
} from '@tanstack/react-table';
import {FaSort} from "react-icons/fa";
import {GrLinkNext, GrLinkPrevious} from "react-icons/gr";
import {MdFileDownload} from "react-icons/md";

export default function CampaignDataTable({data, newColumns, auditTrue, paginationDataCount}) {
    const [expanded, setExpanded] = useState({});

    const table = useReactTable({
        data,
        columns: newColumns,
        state: {
            expanded,
        },
        onExpandedChange: setExpanded,
        getSubRows: row => row?.subRows,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    table.getState().pagination.pageSize = 5;

    return (
        <div className="p-2">
            <div className="h-2"/>
            <TableContainer>
                <Table variant="simple" size="sm">
                    <Thead backgroundColor="brand.primary" height="10" borderRadius="xl">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Th key={header.id} colSpan={header.colSpan} cursor="pointer"
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {header.isPlaceholder ? null : (
                                                <HStack color="white">
                                                    <Text fontFamily="body">
                                                        {["Actions"].includes(flexRender(header.column.columnDef.header, header.getContext()))
                                                            ? ""
                                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                                    </Text>
                                                    {!(["Actions", "Approval"].includes(flexRender(header.column.columnDef.header, header.getContext()))) ?
                                                        <FaSort/> : ""}
                                                </HStack>
                                            )}
                                        </Th>
                                    )
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody height="70px">
                        {table.getRowModel().rows.map((row) => (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <Tooltip
                                            label={cell.renderValue()}
                                            hasArrow
                                            arrowSize={15}
                                            placement="top-start"
                                            margin={-4}
                                            color="gray.800"
                                            bg="gray.100"
                                        >
                                            <Td key={cell.id} fontSize="xs">
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        </Tooltip>
                                    )
                                })}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <div className="h-2"/>
            <Flex justifyContent="space-between">
                <Flex justifyContent="start" m={4} alignItems="center">
                    <Flex>
                        <Tooltip label="Previous Page">
                            <IconButton
                                size="sm"
                                variant="ghost"
                                color="white !important"
                                onClick={() => table.previousPage()}
                                isDisabled={!table.getCanPreviousPage()}
                                background={
                                    !!table.getCanPreviousPage()
                                        ? "brand.primary !important"
                                        : "#F8F9FA !important"
                                }
                                icon={
                                    <GrLinkPrevious
                                        color={!!table.getCanPreviousPage() ? "#FFFFFF" : "#A7A9BD"}
                                        h={6}
                                        w={6}
                                    />
                                }
                            />
                        </Tooltip>
                    </Flex>
                    <Flex alignItems="center" mx={3}>
                        <Text flexShrink="0">
                            {/* Page{" "} */}
                            <Text
                                borderColor="brand.primary"
                                borderRadius={5}
                                color="brand.primary"
                                px="18px"
                                py="3px"
                                borderWidth={2}
                                fontWeight="bold"
                                as="span"
                            >
                                {table.getState().pagination.pageIndex + 1}
                            </Text>{" "}
                            <span style={{margin: "auto 8px"}}>/</span>
                            <Text p={1} color="brand.primary" fontWeight="bold" as="span">
                                {table.getPageCount()}
                            </Text>
                        </Text>
                    </Flex>
                    <Flex>
                        <Tooltip label="Next Page">
                            <IconButton
                                size="sm"
                                variant="ghost"
                                color="white !important"
                                onClick={() => table.nextPage()}
                                isDisabled={!table.getCanNextPage()}
                                background={
                                    !!table.getCanNextPage()
                                        ? "brand.primary !important"
                                        : "#F8F9FA !important"
                                }
                                icon={
                                    <GrLinkNext
                                        color={!!table.getCanNextPage() ? "#FFFFFF" : "#A7A9BD"}
                                        h={6}
                                        w={6}
                                    />
                                }
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
                {auditTrue ? (
                    <Button
                        backgroundColor="brand.primary"
                        rightIcon={<MdFileDownload/>}
                    >
                        Download Data
                    </Button>
                ) : (
                    ""
                )}
            </Flex>
            <div className="flex items-center gap-2">
                {/*<button*/}
                {/*    className="border rounded p-1"*/}
                {/*    onClick={() => table.setPageIndex(0)}*/}
                {/*    disabled={!table.getCanPreviousPage()}*/}
                {/*>*/}
                {/*    {'<<'}*/}
                {/*</button>*/}
                {/*<button*/}
                {/*    className="border rounded p-1"*/}
                {/*    onClick={() => table.previousPage()}*/}
                {/*    disabled={!table.getCanPreviousPage()}*/}
                {/*>*/}
                {/*    {'<'}*/}
                {/*</button>*/}
                {/*<button*/}
                {/*    className="border rounded p-1"*/}
                {/*    onClick={() => table.nextPage()}*/}
                {/*    disabled={!table.getCanNextPage()}*/}
                {/*>*/}
                {/*    {'>'}*/}
                {/*</button>*/}
                {/*<button*/}
                {/*    className="border rounded p-1"*/}
                {/*    onClick={() => table.setPageIndex(table.getPageCount() - 1)}*/}
                {/*    disabled={!table.getCanNextPage()}*/}
                {/*>*/}
                {/*    {'>>'}*/}
                {/*</button>*/}
                {/*<span className="flex items-center gap-1">*/}
                {/*  <div>Page</div>*/}
                {/*  <strong>*/}
                {/*    {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}*/}
                {/*  </strong>*/}
                {/*</span>*/}
                {/*        <span className="flex items-center gap-1">*/}
                {/*  | Go to page:*/}
                {/*  <input*/}
                {/*      type="number"*/}
                {/*      defaultValue={table.getState().pagination.pageIndex + 1}*/}
                {/*      onChange={(e) => {*/}
                {/*          const page = e.target.value ? Number(e.target.value) - 1 : 0;*/}
                {/*          table.setPageIndex(page);*/}
                {/*      }}*/}
                {/*      className="border p-1 rounded w-16"*/}
                {/*  />*/}
                {/*</span>*/}
                {/*<select*/}
                {/*    value={table.getState().pagination.pageSize}*/}
                {/*    onChange={(e) => {*/}
                {/*        table.setPageSize(Number(e.target.value));*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {[1, 2, 3, 4, 5].map((pageSize) => (*/}
                {/*        <option key={pageSize} value={pageSize}>*/}
                {/*            Show {pageSize}*/}
                {/*        </option>*/}
                {/*    ))}*/}
                {/*</select>*/}
            </div>
        </div>
    );
}
