import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import { Button, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { metaCarouselFileInitialValues } from "../../../../../constant/InitialValues";
import { UploadImageFile } from './UploadImageFile';
import { UploadVideoFile } from './UploadVideoFile';
import calculateAspectRatios from 'calculate-aspect-ratio';
import { useUploadImage } from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FB_IMAGE_SIZE, FB_VIDEO_DURATION, FB_VIDEO_SIZE } from "../../../../../../../constant";
import WithAppContext from "../../../../../../../helpers/appContext";

const MetaCarouselFileUpload = ({
                                cardModalType,
                                isShowCardModal,
                                setIsShowCardModal,
                                createCarouselAd,
                                initialModalData,
                                setInitialModalData,
                                activeCarouselCardId,
                                setActiveCarouselCardId,
                                context
                            }) => {
    const [formData, setFromData] = useState(initialModalData);
    const toast = useToast()
    const { mutateAsync } = useUploadImage();
    const clientId = useSelector((state) => state.client.clientId);
    const { id } = useParams();

    const imageValidationSchema = yup.object().shape({
        description: yup.string().trim().min(3).max(18).label("Description"),
        headline: yup.string().trim().min(3).max(32).required().label("Headline"),
        imageURL: yup.object().required().label("Photo"),
        landingPageURL: yup.string().trim().required().label("Landing page url")
            .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                "Landing page url is not valid!"
            ),
    });
    const videoValidationSchema = yup.object().shape({
        description: yup.string().trim().min(3).max(18).label("Description"),
        headline: yup.string().trim().min(3).max(32).required().label("Headline"),
        // videoURL: yup.object().required().label("Video"),
        // thumbnailURL: yup.object().required().label("Thumbnail"),
        landingPageURL: yup.string().trim().required().label("Landing page url")
            .matches(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                "Landing page url is not valid!"
            ),
    });
    const { setAppLoading } = context
    return (
        <Formik
            enableReinitialize
            initialValues={initialModalData}
            validationSchema={cardModalType === 'image' ? imageValidationSchema : videoValidationSchema}
        >
            {({ dirty, isValid, isSubmitting, values, errors, touched, setFieldValue, resetForm, }) => {
                const onDrop = async (accFiles, rejFiles) => {
                    accFiles.map(async (file) => {
                        let fileType = file.type.split("/").shift();
                        setAppLoading(true)
                        if (fileType === "video") {
                            const objectURL = URL.createObjectURL(file);
                            const mySound = new Audio([objectURL]);

                            let video = document.createElement('video');
                            video.preload = 'metadata';
                            video.onloadedmetadata = function () {
                                window.URL.revokeObjectURL(video.src);
                                return video
                            }
                            video.src = URL.createObjectURL(file);
                            mySound.addEventListener("canplaythrough", async () => {
                                const ratio = calculateAspectRatios(video.videoHeight, video.videoWidth);
                                if (video.duration <= FB_VIDEO_DURATION && (ratio === '1:1') && file.size <= FB_VIDEO_SIZE) {
                                    await mutateAsync(
                                        {
                                            clientId: clientId,
                                            campaignBriefId: id,
                                            adFile: accFiles,
                                            type: "fbAdVideos",
                                        }, {
                                            onSuccess: (data, variables, context) => {
                                                setFieldValue('videoURL', data.file)
                                                setFieldValue('videoFile', data)
                                                setAppLoading(false)
                                                toast({
                                                    status: 'success',
                                                    variant: 'top-accent',
                                                    position: 'top-right',
                                                    description: 'Video Upload Successfully.'
                                                })
                                            },
                                            onError: () => {
                                                setAppLoading(false);
                                            }
                                        });
                                } else {
                                    toast({
                                        status: 'error',
                                        variant: 'top-accent',
                                        position: 'top-right',
                                        description: 'Please upload valid file.'
                                    })
                                    setAppLoading(false)
                                }
                            });
                        }

                        if (fileType === "image") {
                            const img = new Image()
                            img.onload = async () => {
                                const { naturalWidth: width, naturalHeight: height } = img
                                if (width >= 1048 && height >= 1048 && file.size <= FB_IMAGE_SIZE) {
                                    await mutateAsync(
                                        {
                                            clientId: clientId,
                                            campaignBriefId: id,
                                            adFile: accFiles,
                                            type: "fbAdImages",
                                        }, {
                                            onSuccess: (data, variables, context) => {
                                                if (cardModalType === 'video') {
                                                    setFieldValue('thumbnailURL', data.file)
                                                } else {
                                                    setFieldValue('imageURL', data.file)
                                                }
                                                setFieldValue('imageFile', data)
                                                setAppLoading(false)
                                                toast({
                                                    status: 'success',
                                                    variant: 'top-accent',
                                                    position: 'top-right',
                                                    description: 'Image Upload Successfully.'
                                                })
                                            },
                                            onError: () => {
                                                setAppLoading(false);
                                            }
                                        });
                                } else {
                                    setAppLoading(false)
                                    toast({
                                        status: 'error',
                                        variant: 'top-accent',
                                        position: 'top-right',
                                        description: 'Please upload valid file.'
                                    })
                                }
                            }
                            img.src = URL.createObjectURL(file)
                        }
                    });
                }

                const onClearData = () => {
                    setFromData(metaCarouselFileInitialValues)
                    setInitialModalData(metaCarouselFileInitialValues)
                    setIsShowCardModal(false)
                    resetForm()
                    setActiveCarouselCardId(null)
                }

                const onSubmitData = async (values) => {
                    createCarouselAd({ ...values, fileType: cardModalType })
                    onClearData()
                }

                return (
                    <Form autoComplete="off">
                        <Modal isOpen={isShowCardModal} size="xl" onClose={onClearData}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Add {cardModalType} card</ModalHeader>
                                <ModalBody>
                                    {cardModalType === 'video' &&
                                    <UploadVideoFile
                                        onDrop={onDrop}
                                        setIsShowCardModal={setIsShowCardModal}
                                        touched={touched}
                                        errors={errors}
                                        setFromData={setFromData}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        formData={formData}
                                    />
                                    }
                                    {cardModalType === 'image' &&
                                    <UploadImageFile
                                        setIsShowCardModal={setIsShowCardModal}
                                        onDrop={onDrop}
                                        touched={touched}
                                        errors={errors}
                                        setFromData={setFromData}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        formData={formData}
                                    />
                                    }
                                </ModalBody>
                                <ModalFooter marginTop="5" justifyContent="flex-end">
                                    <Button
                                        disabled={Object.keys(errors).length}
                                        type="submit"
                                        onClick={() => onSubmitData(values)}
                                    >{activeCarouselCardId !== null ? 'Update' : 'Add'}
                                    </Button>
                                    <Button marginLeft="20px" onClick={onClearData}>Close</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </Form>
                );
            }}
        </Formik>
    );
}


export default WithAppContext(MetaCarouselFileUpload)