import React from 'react';
import Display from "./Display";
import {Navigation} from "swiper";
import {Box, Image, Text} from "@chakra-ui/react";
import {Flex} from "@chakra-ui/layout";
import {Swiper, SwiperSlide} from "swiper/react";
import RightArrowIcon from "../../../../../../../layout/components/RightArrowIcon";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";

function DisplayNativeAd373X310({formDetails, appLoading, ads, index, handleIndex, businessName}) {
  const selectedLogos = (Array.isArray(formDetails?.selectedLogos) && formDetails?.selectedLogos[0]?.imageUrl) ?
    (process.env.REACT_APP_API_URL + "/uploads/" + formDetails?.selectedLogos[0]?.imageUrl) :
    process.env.PUBLIC_URL + "/images/display.png";

  return (
    <Display>
      <Box>
        <Box mt={4} height="45vh">
          {(ads && ads.length !== 0) ?
            <Box overflow="hidden" width={"100%"} height={"100%"}>
              <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                      onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                {
                  ads.map((item) => {
                    return (
                      <SwiperSlide>
                        <Image
                          height="100%"
                          width={"100%"}
                          objectFit={"cover"}
                          src={item?.imageUrl}
                          className="previewImage"
                        />
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
          }
        </Box>
        <Flex width="100%" bg="#c3ddf1" justifyContent="space-between" px={2} py={1} cursor="pointer"
                            _hover={{textDecoration: 'underline'}}>
          <Text color="#0077ff"
                fontWeight="500">{formDetails.headlines[0]}
          </Text>
        </Flex>
        <Flex mx={3} mt={1} fontWeight="400" justifyContent="space-between" alignItems="center" gap={2}>
          <Box width="85%">
            <Text my={1}>{formDetails.descriptions[0]}</Text>
            <Box color="gray" fontSize="14px"> {businessName} </Box>
          </Box>
          <Box bg="#cb0000" borderRadius="100px" p={2} opacity="40%">
            <RightArrowIcon color="white" height="28px" width="28px"/>
          </Box>
        </Flex>
      </Box>
    </Display>
  );
}

export default DisplayNativeAd373X310;
