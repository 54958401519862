import React, { useEffect, useState } from 'react';
import {
    Box,
    Card, CardHeader,
    CardBody,
    Heading,
    Stack, StackDivider, Text, Tag, Wrap, WrapItem, Grid, GridItem, Button, Flex,
} from "@chakra-ui/react";
import instance from "../../helpers/axios";
import {useGetSingleCampaign} from "../../hooks/campaign-briefs/useGetSingleCampaign";

function CampaignBrief({ clientId, id }) {
    const [isLoading, setIsLoading] = useState(false)
    const [creatives, setCreatives] = useState({
        detail: {
            businessDetails: {},
            campaignDetails: {},
            demographics: {},
            mediaStrategies: {},
            campaignBudget: {
                selectedChannels: []
            }
        }
    });
    const { data, refetch } = useGetSingleCampaign(clientId, id);

    useEffect(() => {
        getSingleCampaignBrief()
    }, [])

    useEffect(() => {
        if (data) {
            setCreatives(data.campaign)
        }
    }, [data]);


    function getSingleCampaignBrief() {
        refetch().then((res) => {
            console.log('response ================>', res.data.campaign);
            if (res.data.campaign) {
                setCreatives(res.data.campaign)
            }
        })
    }

    const { detail } = creatives;

    const { businessDetails, campaignDetails, campaignBudget, demographics, mediaStrategies } = detail || {};

    async function downloadCreatives() {
        setIsLoading(true);
        await instance({
            method: "post",
            url: `client/${clientId}/campaign-brief/${id}/download-campaign-creatives`,
            withCredentials: false,
            responseType: "blob",
        })
            .then((resp) => {
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `Creative ${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }

    return (
        <div>
            <Flex justifyContent="space-between" alignItems="center">
                <Heading
                    color="#757998"
                    fontWeight="600"
                    fontFamily="Lora"
                    size='lg'
                    my={5}
                >
                    Campaign Brief
                </Heading>
                <Button style={{minWidth: "inherit"}}
                        color={"brand.primary"}
                        borderRadius={6}
                        variant={'outlined'}
                        borderColor={'brand.primary'}
                        borderWidth={2}
                        disabled={isLoading}
                        onClick={() => downloadCreatives()}
                >
                    {isLoading ? "Downloading..." : "Download"}
                </Button>
            </Flex>
            <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                <GridItem>
                    <Card mb={4}>
                        <CardHeader>
                            <Heading size='md'>Business Details</Heading>
                        </CardHeader>
                        <CardBody>
                            <Stack divider={<StackDivider />} spacing='4'>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Business Name
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {businessDetails && businessDetails.name}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Business Industry
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {businessDetails && businessDetails.industry}
                                    </Text>
                                </Box>
                            </Stack>
                        </CardBody>
                    </Card>
                    <Card mb={4}>
                        <CardHeader>
                            <Heading size='md'>Campaign Budget Details</Heading>
                        </CardHeader>
                        <CardBody>
                            <Stack divider={<StackDivider />} spacing='4'>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Campaign Name
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                            {campaignDetails && campaignDetails.name}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Campaign Duration
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {campaignBudget && campaignBudget.duration}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Campaign Start Date
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                            {campaignBudget && campaignBudget.startDate}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Campaign End Date
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {campaignBudget && campaignBudget.endDate}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Campaign Total Budget
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {campaignBudget && campaignBudget.totalBudget}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Campaign Channels
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        <Wrap spacing={3}>
                                            {campaignBudget && campaignBudget.selectedChannels && campaignBudget.selectedChannels.map(({name}) => (
                                                <WrapItem>
                                                    <Tag size='md' key={name} variant='solid' colorScheme='blue'>
                                                        {name}
                                                    </Tag>
                                                </WrapItem>
                                            ))}
                                        </Wrap>
                                    </Text>
                                </Box>
                            </Stack>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem>
                    <Card mb={4}>
                        <CardHeader>
                            <Heading size='md'>Media Strategies Details</Heading>
                        </CardHeader>
                        <CardBody>
                            <Stack divider={<StackDivider />} spacing='4'>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Language
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {mediaStrategies && mediaStrategies.language}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Device
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {mediaStrategies && mediaStrategies.device}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Competitors
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {mediaStrategies && mediaStrategies.competitors}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Brand Safety
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        <Wrap spacing={3}>
                                            {mediaStrategies && mediaStrategies.brandSafety && mediaStrategies.brandSafety.map((label) => (
                                                <WrapItem>
                                                    <Tag size='md' key={label} variant='solid' colorScheme='blue' borderRadius='full'>
                                                        {label}
                                                    </Tag>
                                                </WrapItem>
                                            ))}
                                        </Wrap>
                                    </Text>
                                </Box>
                            </Stack>
                        </CardBody>
                    </Card>
                    <Card mb={4}>
                        <CardHeader>
                            <Heading size='md'>Demographics Details</Heading>
                        </CardHeader>
                        <CardBody>
                            <Stack divider={<StackDivider />} spacing='4'>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Audience
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {demographics && demographics.audience}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Campaign Geography
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {demographics && demographics.geography}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Campaign Location Group
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {demographics && demographics.location && demographics.location.locationGroup}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Campaign Countries
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        <Wrap spacing={3}>
                                            {demographics && demographics.location && demographics.location.countries && demographics.location.countries.map(({label}) => (
                                                <WrapItem>
                                                    <Tag size='md' key={label} variant='outline' colorScheme='blue'>
                                                        {label}
                                                    </Tag>
                                                </WrapItem>
                                            ))}
                                        </Wrap>
                                    </Text>
                                </Box>
                            </Stack>
                        </CardBody>
                    </Card>
                </GridItem>
            </Grid>
        </div>
    );
}

export default CampaignBrief;
