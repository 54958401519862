import React, {useEffect, useMemo, useState} from 'react';
import Datatable from "../../components/Datatable";
import {
    Button,
    Heading, HStack,
    Input,
    InputGroup,
    InputLeftElement, Link,
    Stack, Text, VStack,
} from "@chakra-ui/react";
import {DownloadIcon, SearchIcon} from "@chakra-ui/icons";
import instance from "../../helpers/axios";
import {titleCase} from "../../helpers/utils";
import _ from "lodash";

function CreativeDownload({ data, clientId, id }) {
    const [creatives, setCreatives] = useState([])
    const [search, setSearch] = useState();
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setCreatives(data)
    }, [data]);

    useEffect(() => {
        if (search?.trim()) {
            const searchedCreative = data.filter((el) => {
                if (el?.creative?.email?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.creative?.objective?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.creative?.creativeType?.toLowerCase().includes(search.trim())) {
                    return true;
                }
            });
            setCreatives(searchedCreative);
        } else {
            setCreatives(data);
        }
    }, [search]);

    async function downloadCreatives(e, creativeId, fileName) {
        await instance({
            method: "get",
            url: `client/${clientId}/campaign-brief/${id}/ad-upload/${creativeId}/download-resources`,
            withCredentials: false,
            responseType: "blob",
        })
            .then((response) => {
                if (response.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${fileName}.zip`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch((error) => {
                console.error('Error downloading the file', error);
            });
    }

    async function downloadAllCreatives() {
        setIsLoading(true);
        await instance({
            method: "get",
            url: `client/${clientId}/campaign-brief/${id}/ad-upload/${id}/download-all-resources`,
            withCredentials: false,
            responseType: "blob",
        })
            .then((resp) => {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `Creative ${Date.now()}.zip`);
                    document.body.appendChild(link);
                    link.click();
                document.body.removeChild(link);
                    setIsLoading(false);
                })
            .catch((err) => {
                console.error('Error downloading the file', err);
                setIsLoading(false);
            });
    }

    const renderItemsWithNumbers = (items) => {
        return items.map((item, index) => (
            <Text key={index}>
                {`${index + 1}. ${item}`}
            </Text>
        ));
    };

    const renderImagesWithNumbers = (items) => {
        return items.map((item, index) => (
            <Text key={index}>
                {`${index + 1}. ${item.filename}`}
            </Text>
        ));
    };

    const renderCarouselWithNumbers = (itemSet) => {
        return itemSet.map((items, index) => (
            <VStack key={`card-${index}`} align="start" spacing={2}>
                <Text>{`Card ${index + 1} attachments:`}</Text>
                {items.imageURL.map((item, subIndex) => (
                    <Text key={`item-${subIndex}`}>
                        {`${subIndex + 1}. ${item.filename}`}
                    </Text>
                ))}
            </VStack>
        ));
    };

    const renderAudioWithNumbers = (items) => {
        return items.map((item, index) => (
            <Text key={index}>
                {`${index + 1}. ${item.filename}`}
            </Text>
        ));
    };

    const renderAttachmentWithNumbers = (items) => {
        return items.map((item, index) => (
            <Text key={index}>
                {`${index + 1}. ${item.imageFile.originalname}`}
            </Text>
        ));
    };

    const columns = useMemo(
        () => [
            {
                Header: "Channel",
                accessor: "creative.detail.channel",
                Cell: (data) => {
                    return (
                        <Text>{titleCase(data.value)?.replace("_", " ")}</Text>
                    );
                },
            },
            {
                Header: "Creative Name",
                accessor: "creative.name",
            },
            {
                Header: "Title",
                accessor: "creative.detail.title",
            },
            {
                Header: "Headline",
                accessor: "creative.detail.headline",
                Cell: (data) => {
                    const headlines = data.row.original.creative.detail.headlines;
                    if (!_.isEmpty(headlines)) {
                        return (
                            <VStack align="start" spacing={2}>
                                {renderItemsWithNumbers(headlines)}
                            </VStack>
                        );
                    } else if (data.value) {
                        return <Text>{data.value}</Text>;
                    } else {
                        return <Text>-</Text>;
                    }
                },
            },
            {
                Header: "Description",
                accessor: "creative.detail.description",
                Cell: (data) => {
                    const descriptions = data.row.original.creative.detail.descriptions;
                    if (!_.isEmpty(descriptions)) {
                        return (
                            <VStack align="start" spacing={2}>
                                {renderItemsWithNumbers(descriptions)}
                            </VStack>
                        );
                    } else if (data.value) {
                        return <Text>{data.value}</Text>;
                    } else {
                        return <Text>-</Text>;
                    }
                },
            },
            {
                Header: "Attachments",
                accessor: "creative.detail.attachments",
                Cell: (data) => {
                    const details = data.row.original.creative.detail

                    if (!_.isEmpty(details.images)) {
                        return (
                            <VStack align="start" spacing={2}>
                                {renderImagesWithNumbers(details.images)}
                            </VStack>
                        );
                    } else if (!_.isEmpty(details.selectedImages)) {
                        return (
                            <VStack align="start" spacing={2}>
                                {renderImagesWithNumbers(details.selectedImages)}
                            </VStack>
                        );
                    } else if (!_.isEmpty(details.fileInfoList)) {
                        return (
                            <VStack align="start" spacing={2}>
                                {renderImagesWithNumbers(details.fileInfoList)}
                            </VStack>
                        );
                    } else if (!_.isEmpty(details.carouselCards)) {
                        return (
                            <VStack align="start" spacing={2}>
                                {renderCarouselWithNumbers(details.carouselCards)}
                            </VStack>
                        );
                    } else if (!_.isEmpty(details.audio)) {
                        return (
                            <VStack align="start" spacing={2}>
                                {renderAudioWithNumbers(details.audio.fileInfoList)}
                            </VStack>
                        );
                    } else if (!_.isEmpty(details.child_attachments)) {
                        return (
                            <VStack align="start" spacing={2}>
                                {renderAttachmentWithNumbers(details.child_attachments)}
                            </VStack>
                        );
                    } else if (!_.isEmpty(details.videoFiles)) {
                        return (
                            <VStack align="start" spacing={2}>
                                {renderImagesWithNumbers(details.videoFiles)}
                            </VStack>
                        );
                    } else if (!_.isEmpty(details.thumbnailFile)) {
                        return (
                            <VStack align="start" spacing={2}>
                                {/*{renderImagesWithNumbers(details.thumbnailFile)}*/}

                                <Text>
                                    {`${details.thumbnailFile.filename}`}
                                </Text>
                            </VStack>
                        );
                    }
                    else {
                        return <Text>-</Text>
                    }
                },
            },
            {
                Header: "Download",
                accessor: "actions",
                Cell: (data) => {
                    return (
                        <HStack>
                            <Button
                                as={Link}
                                size="sm"
                                variant="outline"
                                aria-label="download"
                                icon={<DownloadIcon />}
                                color="#3B82F6"
                                py={4}
                                w={24}
                                isDisabled={isLoading}
                                onClick={(e) => downloadCreatives(e, data.row.original.campaignId, data.row.original.am_pm_naming.adName)}
                            >
                                Download
                            </Button>
                        </HStack>
                    );
                },
            }
        ],
        [data]
    );

    return (
        <div>
            <Heading
                color="#757998"
                fontWeight="600"
                fontFamily="Lora"
                size="lg"
                my={5}
            >
                Creatives
            </Heading>
            <div className="search scrollable">
                <Stack direction="row" justifyContent="space-between" spacing={4} mt={6} mb={5}>
                    <InputGroup >
                        <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.300" ml={8}/>}
                        />
                        <Input
                            ml={2}
                            borderRadius={4}
                            name="search"
                            type="tel"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </InputGroup>
                    <Button style={{minWidth: "inherit"}}
                            color={"brand.primary"}
                            borderRadius={6}
                            variant={'outlined'}
                            borderColor={'brand.primary'}
                            borderWidth={2}
                            disabled={isLoading}
                            onClick={() => downloadAllCreatives("ad-serving-download")}
                    >
                        {isLoading ? "Downloading..." : "Download"}
                    </Button>
                </Stack>
            </div>
            {creatives?.length !== 0 ? <Datatable data={creatives || []} columns={columns} headerColor={"brand.primary"} paginationDataCount={15}/> : <p style={{textAlign: 'center', marginTop:"360px", fontSize:"1.4rem"}}> Please add creatives to get detailed data </p>}
        </div>
    );
}

export default CreativeDownload;
