import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
    Box,
    Button,
    CircularProgress,
    CircularProgressLabel,
    css,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading, Text,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
    linkedInAdUploadValueLengths, youtubeShortsValueLengths,
} from "../../../../../constant/InitialValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import { settingLinkedInFormDetails, settingLinkedInMedia } from "./store";
import TooltipComponent from "../../TooltipComponent";
import {Input} from "@chakra-ui/input";
import {CloseIcon} from "@chakra-ui/icons";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import {settingSocialDisplayImageForm} from "../../SocialDisplay/SocialDisplayImage/store";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";

const initialValue = {
  adName: "",
  headline: "",
  introductory_text: "",
  description: "",
  link: "",
  channel_name: "",
  channel_icon: null,
  type: ""
};

function LinkedInImageAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData
}) {
  const { id, adUploadId } = useParams();
  const {mutateAsync} = useUploadImage();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.linkedInImageAd);

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(linkedInAdUploadValueLengths.adName).required().label("Ad name"),
    headline: yup.string().trim().min(3).max(linkedInAdUploadValueLengths.headline).required().label("Headline"),
    introductory_text: yup.string().trim().min(3).max(linkedInAdUploadValueLengths.introductory_text).required().label("Introductory Text"),
    description: yup.string().trim().min(3).max(linkedInAdUploadValueLengths.description).optional().label("Description"),
    link: yup
      .string()
      .trim()
      .required("Landing page url is required")
      .max(linkedInAdUploadValueLengths.link)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      )
      .label("Landing page url"),
    channel_name: yup.string()
        .trim()
        .max(30)
        .optional()
        .label("Channel Name"),
    channel_icon: yup
        .mixed()
        .test(
            "fileType",
            "Only image files are allowed",
            (value) => {
              if (!value) return true; // Allow empty value
              const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
              return allowedTypes.includes(value?.type || value?.mimetype);
            }
        )
        .nullable(true)
        .label("Channel Icon"),
    type: yup.string().max(125).optional().label("Call to action"),
  });

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingLinkedInFormDetails({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          headline: data?.adUpload?.detail?.headline,
          introductory_text: data?.adUpload?.detail?.introductory_text,
          description: data?.adUpload?.detail?.description,
          link: data?.adUpload?.detail?.call_to_action.link,
          type: data?.adUpload?.detail?.call_to_action?.type,
          channel_name: data?.adUpload?.detail?.channel_name,
          channelIcon: data?.adUpload?.detail?.channel_icon,
          channel_icon: data?.adUpload?.detail?.channel_icon?.files,
        })
      );
      dispatch(
        settingLinkedInMedia({
          imageURL: data?.adUpload?.detail?.images,
        })
      );
      // dispatch(settingEditMediaType(data?.adUpload?.ad_upload_type));
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
      console.log('Submitting...')
        if (values.channel_icon?.name) {
          console.log('Inside if')
          await mutateAsync(
                {
                    clientId: clientId,
                    campaignBriefId: id,
                    adFile: [values.channel_icon],
                    type: "linkedinChannelIcon",
                }, {
                    onSuccess: (data, variables, context) => {
                      console.log('Success0', data)
                      dispatch(
                        settingLinkedInFormDetails({
                                ...data?.adUpload?.detail,
                                ...values,
                                imageURL: data?.adUpload?.detail.images,
                                channelIcon: data.fileInfoList[0],
                                channel_icon: null,
                            })
                        );
                    },
                    onError: (err) => {
                      console.log('Error')
                      console.log('error OnError', err)
                    }
                });
        } else {
          console.log('Inside else part')

          dispatch(
                settingLinkedInFormDetails({
                    ...values,
                    imageURL: data?.adUpload?.detail.images
                })
            );
        }
      nextStep();
    },
  });

  const { errors, values, touched, handleSubmit, isValid, setFieldValue } =
    formik;

    console.log('Values', values)

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("headline", formDetails.headline || autoFillData?.headline || "");
    setFieldValue("introductory_text", formDetails.introductory_text || autoFillData?.introductory_text || "");
    setFieldValue("description", formDetails.description || autoFillData?.description || "");
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
    setFieldValue("channel_name", formDetails.channel_name || autoFillData?.channel_name || "");
    setFieldValue("channel_icon", formDetails.channelIcon?.files || "");
    setFieldValue("channelIcon", formDetails.channelIcon || "");
    setFieldValue("type", formDetails.type || autoFillData?.type || "");
  }, [formDetails, autoFillData]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFieldValue('channel_icon', file);
    };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Ad Name - max 255 characters ( Your first 40 characters are what usually show up in feeds. )",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={linkedInAdUploadValueLengths.adName}
                      value={values.adName.length}
                      color={
                        values.adName.length >
                        linkedInAdUploadValueLengths.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.adName.length >
                        linkedInAdUploadValueLengths.adName
                          ? linkedInAdUploadValueLengths.adName -
                            values.adName.length
                          : values.adName.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="headline" mb={0}>Headline</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="headline"
                      name="headline"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Headline - max 70 characters ( Your first 40 characters are what usually show up in feeds. )",
                      }}
                      onChange={(e) => setFieldValue("headline", e.target.value)}
                    />
                    <CircularProgress
                      max={linkedInAdUploadValueLengths.headline}
                      value={values.headline.length}
                      color={
                        values.headline.length >
                        linkedInAdUploadValueLengths.headline
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.headline.length >
                        linkedInAdUploadValueLengths.headline
                          ? linkedInAdUploadValueLengths.headline -
                            values.headline.length
                          : values.headline.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="introductory_text" mb={0}>Introductory Text</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="introductory_text"
                      name="introductory_text"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Introductory Text - max 150 characters",
                      }}
                      onChange={(e) => setFieldValue("introductory_text", e.target.value)}
                    />
                    <CircularProgress
                      max={linkedInAdUploadValueLengths.introductory_text}
                      value={values.introductory_text.length}
                      color={
                        values.introductory_text.length >
                        linkedInAdUploadValueLengths.introductory_text
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.introductory_text.length >
                        linkedInAdUploadValueLengths.introductory_text
                          ? linkedInAdUploadValueLengths.introductory_text -
                            values.introductory_text.length
                          : values.introductory_text.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" mb={0}>Description</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="description"
                      name="description"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Description - max 70 characters ( People will usually see the first 50 characters when they click on your Pin. )",
                      }}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={linkedInAdUploadValueLengths.description}
                      value={values.description.length}
                      color={
                        values.description.length >
                        linkedInAdUploadValueLengths.description
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.description.length >
                        linkedInAdUploadValueLengths.description
                          ? linkedInAdUploadValueLengths.description -
                            values.description.length
                          : values.description.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL - max 255 characters",
                      }}
                      onChange={(e) => setFieldValue("link", e.target.value)}
                    />
                    <CircularProgress
                      max={linkedInAdUploadValueLengths.link}
                      value={values.link.length}
                      color={
                        values.link.length > linkedInAdUploadValueLengths.link
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.link.length > linkedInAdUploadValueLengths.link
                          ? linkedInAdUploadValueLengths.link -
                            values.link.length
                          : values.link.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
                <GridItem mb={2}>
                    <FormControl>
                        <FormLabel htmlFor="channel_name" mb={0}>Channel Name</FormLabel>
                        <div className="input-box">
                            <InputControl
                                id="channel_name"
                                name="channel_name"
                                placeholder=""
                                inputProps={{
                                    variant: "outline",
                                    type: "text",
                                    placeholder: "Channel Name (Optional) - max 90 characters",
                                }}
                                onChange={(e) =>
                                    setFieldValue("channel_name", e.target.value)
                                }
                            />
                            <CircularProgress
                                max={youtubeShortsValueLengths?.channel_name}
                                value={values?.channel_name?.length}
                                color={
                                    values?.channel_name?.length >
                                    youtubeShortsValueLengths?.channel_name
                                        ? "red.400"
                                        : "green.400"
                                }
                            >
                                <CircularProgressLabel>
                                    {values?.channel_name?.length >
                                    youtubeShortsValueLengths?.channel_name
                                        ? youtubeShortsValueLengths?.channel_name -
                                        values?.channel_name?.length
                                        : values?.channel_name?.length}
                                </CircularProgressLabel>
                            </CircularProgress>
                        </div>
                    </FormControl>
                </GridItem>
                <GridItem mb={2}>
                    <FormControl>
                        <FormLabel htmlFor="channel_icon" mb={0}>Channel Icon</FormLabel>
                        <div className="input-box">
                            <Box py={2}>
                                <Input
                                    id="channel_icon"
                                    name="channel_icon"
                                    type="file"
                                    accept="image/*"
                                    display="none"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="channel_icon" style={{cursor: "pointer"}}>
                                    <Box
                                        as="span"
                                        padding="8px 16px"
                                        border="1px solid #ccc"
                                        borderRadius="4px"
                                        fontSize="0.825rem"
                                        color="#706f7b"
                                        _hover={{borderColor: "blue.400"}}
                                    >
                                        Upload Channel Icon
                                    </Box>
                                </label>
                            </Box>
                            {values.channel_icon && (
                                <>
                                    <Text>{values.channel_icon.name || values.channel_icon.originalname}</Text>
                                    <Grid justifyContent="center">
                                        <CloseIcon
                                            cursor="pointer"
                                            className="close-icon"
                                            height={'12px'}
                                            width={'12px'}
                                            onClick={() => {
                                                setFieldValue('channel_icon', null);
                                                setFieldValue('channelIcon', null);
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                        </div>
                        {errors.channel_icon && <Text color='#E53E3E' fontSize="14px" mt="8px">{errors.channel_icon}</Text>}
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl>
                        <FormLabel htmlFor="type" mb={0}>Call to Action (Optional)</FormLabel>
                        <div className="input-box">
                            <SelectControl
                                id="type"
                                name="type"
                                selectProps={{
                                    placeholder: "-- Select One --",
                                    variant: "outline",
                                    border: "2px",
                                    borderRadius: 0,
                                    borderColor: "gray",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    color: "#757998",
                                    marginRight: "100px",
                                }}
                                onChange={(e) => {
                                    setFieldValue("type", e.target.value);
                                }}
                            >
                                {youtubeShortsTypes.map((el) => (
                                    <option value={el.key} key={el.key}>
                                        {el.name}
                                    </option>
                                ))}
                            </SelectControl>
                            <CircularProgress opacity={0}>
                                <CircularProgressLabel>0</CircularProgressLabel>
                            </CircularProgress>
                        </div>
                    </FormControl>
                </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default LinkedInImageAdDetails;
