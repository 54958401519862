import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { Form, FormikProvider, useFormik } from "formik";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import ErrorModal from "../../../../../../components/PopupModal/ErrorModal";
import SuccessModal from "../../../../../../components/PopupModal/SuccessModal";
import instance from "../../../../../../helpers/axios";
import { useUpdateAdUploadStatus } from "../../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import {
  facebookCarouselAdUploadInitialValues,
  fbCarouselAdUploadValueLengths,
  facebookCarouselFileInitialValues,
  instagramCarouselInitialValues,
  instagramCarouselFileInitialValues,
} from "../../../../constant/InitialValues";
import { facebookAccountIds } from "../../../../constant/SelectValues";
import Main from "./UploadFiles/main";
import DefaultImageIcon from "../../../../../../assets/images/image-default-icon.png";
import DefaultVideoIcon from "../../../../../../assets/images/video-default-icon.png";
import {
  AD_UPLOAD_STATUS_COLOR,
  AD_UPLOAD_TYPE,
} from "../../../../../../constant";
import _ from "lodash";
import { useCreateAdPreview } from "../../../../../../hooks/campaign-briefs/useCreateAdPreview";
import WithAppContext from "../../../../../../helpers/appContext";
import FacebookAdSetModel from "../../Facebook/FacebookAdSetModel";

const instagramCarouselSchema = yup.object().shape({
  adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
  type: yup.string().required().label("Type"),
  link: yup
    .string()
    .trim()
    .required("Landing page url is required.")
    .label("Landing page url")
    .matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      "Landing page url is not valid!"
    ),
  carouselCards: yup.array().min(2).max(10).required().label("Carousel cards"),
});

const InstagramCarouselAdDetails = ({
  data,
  url,
  method,
  clientId,
  campaignId,
  handlePreviewData,
  setTabIndex,
  tabIndex,
  context,
}) => {
  const [formData, setFromData] = useState(instagramCarouselInitialValues);
  const [hashArray, setHashArray] = useState([]);
  const navigate = useNavigate();
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [description, setDescription] = useState("");
  const [isShowCardModal, setIsShowCardModal] = useState(false);
  const [cardModalType, seCardModalType] = useState("image");
  const [initialModalData, setInitialModalData] = useState(
    instagramCarouselFileInitialValues
  );
  const [activeCarouselCardId, setActiveCarouselCardId] = useState(null);
  const { setAppLoading, ErrorToaster } = context;

  const {
    isOpen: isFacebookModelOpen,
    onOpen: onFacebookModelOpen,
    onClose: onFacebookModelClose,
  } = useDisclosure();

  const { mutate } = useUpdateAdUploadStatus();
  const { mutateAsync } = useCreateAdPreview();

  const formik = useFormik({
    initialValues: formData,
    validationSchema: instagramCarouselSchema,
    onSubmit: async (values) => {
      setAppLoading(true);
      setFromData(values);
      const child_attachments = values.carouselCards.map((el) => {
        return {
          name: el.name,
          link: el.link,
          description: el.description,
          image_hash: el.imageFile?.imageHash || el.image_hash || el.imageFile,
          imageFile: el.imageURL || el.imageFile,
          video_id: el.videoFile?.video_id || el.video_id || el.videoFile,
          videoFile: el.videoURL || el.videoFile,
          fileType: el.fileType,
          call_to_action: {
            type: values.type || el.call_to_action.type,
            value: {
              link: el.link || el.call_to_action.value.link,
            },
          },
        };
      });
      let payload = {
        name: values.adName,
        detail: {
          name: values.adName,
          link: values.link,
          child_attachments,
          multi_share_optimized: true,
        },
      };
      if (!data?.id) {
        payload = {
          ...payload,
          ad_upload_type: AD_UPLOAD_TYPE.INSTAGRAM_CAROUSEL,
        };
      }
      await instance({
        method: method,
        url: url,
        withCredentials: false,
        data: payload,
      })
        .then((res) => {
          if (res.status === 200) {
            setAppLoading(false);
            setSuccessModal(true);
            navigate(`/campaign-briefs/${campaignId}`, {
              state: { isShowUpload: true },
            });
          }
        })
        .catch((error) => {
          setAppLoading(false);
          setDescription(error.response.data.message);
          setErrorModal(true);
        });
      setAppLoading(false);
    },
  });

  const { errors, values, touched, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    if (tabIndex === 1 && values.carouselCards.length >= 2) {
      const getImages = async () => {
        setAppLoading(true);
        const child_attachments = values.carouselCards.map((el) => {
          return {
            name: el.name,
            description: el.description,
            link: el.link || el.link,
            image_hash:
              el.imageFile?.imageHash || el.image_hash || el.imageFile,
            video_id: el.videoFile?.video_id || el.video_id || el.videoFile,
            call_to_action: {
              type: values.type || el.call_to_action.type,
              value: {
                link: el.link || el.call_to_action.value.link,
              },
            },
          };
        });

        const payload = {
          name: values.adName,
          object_story_spec: {
            link_data: {
              link: values.link,
              child_attachments,
              multi_share_optimized: true,
            },
          },
        };
        const previewsImages = [];
        try {
          await mutateAsync(
              {
                clientId: clientId,
                campaignBriefId: campaignId,
                data: payload,
                type: "instagram",
              },
              {
                onSuccess: (data) => {
                  const previews = data && data.previews;
                  if (previews) {
                    Object.keys(previews).forEach((value, index) => {
                      previewsImages.push(Object.values(previews)[index]);
                    });
                  } else {
                    ErrorToaster("Please fill all required ad details");
                  }
                  handlePreviewData(previewsImages);
                  setAppLoading(false);
                },
                onError: (error) => {
                  ErrorToaster(error);
                  setAppLoading(false);
                },
              }
          );
        } catch (e) {
          setAppLoading(false);
        }
        setAppLoading(false);
      };
      getImages();
    }
  }, [tabIndex]);

  useEffect(() => {
    if (data && data.id) {
      const { name, detail } = data;
      setFieldValue("adName", name);
      setFieldValue("link", detail.link);
      setFieldValue("type", detail.child_attachments[0].call_to_action.type);
      setFieldValue("carouselCards", detail.child_attachments);
    }
  }, [data]);
  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    navigate(`/campaign-briefs/${campaignId}`, {
      state: { isShowUpload: true },
    });
  };

  const createCarouselAd = (data) => {
    let CarouselCardsData = [...values.carouselCards];
    if (activeCarouselCardId !== null) {
      CarouselCardsData[activeCarouselCardId] = data;
    } else {
      CarouselCardsData.push(data);
    }
    setFieldValue("carouselCards", CarouselCardsData);
    setActiveCarouselCardId(null);
  };

  const onDelete = (id) => {
    let CarouselCardsData = [...values.carouselCards];
    CarouselCardsData.splice(id, 1);
    setFieldValue("carouselCards", CarouselCardsData);
  };

  const adUploadStatusColor = (item) => {
    return item
      ? AD_UPLOAD_STATUS_COLOR[_.toUpper(item)]
      : AD_UPLOAD_STATUS_COLOR["OTHER"];
  };

  const handleStatus = (status) => {
    setAppLoading(true);
    try {
      mutate(
          {
            clientId,
            campaignId,
            adUploadId: data?.id,
            status,
          },
          {
            onSuccess: () => {
              setAppLoading(false);
              navigate(`/campaign-briefs/${campaignId}`, {
                state: { isShowUpload: true },
              });
            },
            onError: () => {
              setAppLoading(false);
            },
          }
      );
    } catch (e) {
      setAppLoading(false);
    }
    setAppLoading(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" my={4} mb={7}>
        <Heading color={"gray"} fontSize="xl">
          Current status:
          <span style={{ marginLeft: "10px" }}>
            <Icon
              viewBox="0 0 200 200"
              mr={2}
              color={adUploadStatusColor(data && data.status)}
            >
              <path
                fill="currentColor"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
            {(data && data.status) || "Draft"}
          </span>
        </Heading>
        {data && _.toUpper(data.status) === "CREATED" && (
          <Box display="flex">
            <Button
              size="sm"
              mr={2}
              colorScheme="green"
              backgroundColor="green.400"
              borderRadius={4}
              disabled={!clientId || !campaignId || !data?.id}
              onClick={() => handleStatus("Approved")}
            >
              Approve
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              backgroundColor="red.400"
              borderRadius={4}
              disabled={!clientId || !campaignId || !data?.id}
              onClick={() => handleStatus("Rejected")}
            >
              Reject
            </Button>
          </Box>
        )}
      </Box>
      <Grid className="fb-upload-detail-form">
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Grid
              gap={4}
              className="fb-upload"
              templateColumns="repeat(2, 1fr)"
            >
              <GridItem w="100%">
                <FormControl>
                  <FormLabel htmlFor="adName">
                    Ad Name - max 512 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      value={values.adName}
                      placeholder=""
                      inputprops={{
                        variant: "outline",
                        type: "text",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <CircularProgress
                      max={fbCarouselAdUploadValueLengths?.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        fbCarouselAdUploadValueLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        fbCarouselAdUploadValueLengths?.adName
                          ? fbCarouselAdUploadValueLengths?.adName -
                            values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </Grid>
            <GridItem
              w="100%"
              justifyContent="end"
              display="flex"
              alignItems="center"
              marginBottom={errors.carouselCards ? "8px" : "20px"}
            >
              <Button
                variant="outline"
                onClick={() => {
                  seCardModalType("image");
                  setIsShowCardModal(true);
                }}
              >
                Add Image Card
              </Button>
              <Button
                variant="outline"
                marginLeft="10px"
                onClick={() => {
                  seCardModalType("video");
                  setIsShowCardModal(true);
                }}
              >
                Add Video Card
              </Button>
            </GridItem>
            <GridItem
              w="100%"
              display="flex"
              justifyContent="flex-end"
              marginBottom="20px"
              color="#e53e3e"
              fontSize="14px"
              css={css({
                float: "right",
              })}
            >
              {errors.carouselCards && errors.carouselCards}
            </GridItem>
            <Grid
              w="100%"
              gap={4}
              marginBottom="20px"
              className="fb-upload"
              // templateColumns='repeat(8, 1fr)'
              display="flex"
              flexWrap="wrap"
            >
              {values.carouselCards &&
                values.carouselCards.map((item, index) => {
                  let fileType = "";
                  let fileData = {};
                  fileType = item.fileType;
                  if (fileType === "video") {
                    fileData = {
                      name: item.name,
                      description: item.description,
                      link: item.link,
                      videoURL: item.videoURL || item.videoFile,
                      videoFile: item.videoFile || item.video_id,
                      imageURL: item.imageURL || item.imageFile,
                      imageFile: item.image_hash,
                    };
                  } else {
                    fileData = {
                      name: item.name,
                      description: item.description,
                      link: item.link,
                      imageURL: item.imageURL || item.imageFile,
                      imageFile: item.image_hash,
                    };
                  }

                  return (
                    <Box
                      key={index}
                      //  backgroundColor="rgba(0, 0, 0, 0.39)"
                      borderWidth="1px"
                      width="110px"
                      borderRadius="10px"
                      display="flex"
                      justifyContent="space-evenly"
                      overflow="hidden"
                    >
                      <Flex
                        alignItems="center"
                        onClick={() => {
                          setInitialModalData(fileData);
                          seCardModalType(fileType);
                          setIsShowCardModal(true);
                          setActiveCarouselCardId(index);
                        }}
                      >
                        {fileType === "image" && (
                          <Image
                            height="80px"
                            width="80px"
                            objectFit="contain"
                            src={DefaultImageIcon}
                            alt="Default Image Icon"
                            cursor="pointer"
                          />
                        )}
                        {fileType === "video" && (
                          <Image
                            height="80px"
                            width="80px"
                            objectFit="contain"
                            src={DefaultVideoIcon}
                            alt="Default Video Icon"
                            cursor="pointer"
                          />
                        )}
                      </Flex>
                      <CloseIcon
                        marginTop="4px"
                        padding="2px"
                        bg="rgba(255, 255, 255, 0.45)"
                        cursor="pointer"
                        className="close-icon"
                        size="small"
                        onClick={() => {
                          onDelete(index);
                        }}
                      />
                    </Box>
                  );
                })}
            </Grid>

            <Grid
              gap={4}
              className="fb-upload"
              templateColumns="repeat(2, 1fr)"
            >
              <GridItem w="100%">
                <FormControl>
                  <FormLabel htmlFor="link">Landing page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      value={values.link}
                      placeholder=""
                      inputprops={{
                        variant: "outline",
                        type: "text",
                      }}
                      onChange={(e) => setFieldValue("link", e.target.value)}
                    />
                    <Box h="48px" w="48px" />
                  </div>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="type">Type</FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="type"
                      name="type"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => setFieldValue("type", e.target.value)}
                    >
                      {facebookAccountIds.map((el) => (
                        <option value={el.key} key={el.key}>
                          {" "}
                          {el.name}{" "}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </Grid>
            <GridItem
              w="100%"
              display="flex"
              justifyContent="flex-end"
              paddingRight="65"
              css={css({
                float: "right",
              })}
            >
              <Button
                size="small"
                css={css({
                  background: "#24a0ed !important",
                  borderRadius: "32px",
                  width: "134px",
                  height: "33px",
                  marginRight: "10px",
                })}
                disabled={values.carouselCards.length < 2 && values.type}
                onClick={() => setTabIndex(1)}
              >
                Preview
              </Button>
              <Button
                size="small"
                css={css({
                  background: "#4CAF50 !important",
                  borderRadius: "32px",
                  width: "134px",
                  height: "33px",
                })}
                type="submit"
                disabled={values.carouselCards.length < 2 && values.type}
              >
                {data?.id ? "Update Ad" : "Submit Ad"}
              </Button>
            </GridItem>
            <Main
              setHashArray={setHashArray}
              hashArray={hashArray}
              type="facebookCarousel"
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              cardModalType={cardModalType}
              isShowCardModal={isShowCardModal}
              setIsShowCardModal={setIsShowCardModal}
              createCarouselAd={createCarouselAd}
              initialModalData={initialModalData}
              setInitialModalData={setInitialModalData}
              activeCarouselCardId={activeCarouselCardId}
              setActiveCarouselCardId={setActiveCarouselCardId}
            />
          </Form>
        </FormikProvider>
      </Grid>

      <FacebookAdSetModel
        isOpen={isFacebookModelOpen}
        onClose={onFacebookModelClose}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={description}
      />
    </>
  );
};

export default WithAppContext(InstagramCarouselAdDetails);
