import React from "react";
import { useDropzone } from 'react-dropzone';
import { CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Button,
    Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip, Box
} from "@chakra-ui/react";
import FileUploadIcon from "../../../../../../assets/images/upload.svg";
import {BLUE_BUTTON_COLOR, GREY_TEXT_COLOR, TEXT_COLOR} from "../../../../../../layout/constant/MenuList";

export function UploadAudioFile({ files, onDelete, onDrop, errors, touched }) {
    const acceptAudioFiles = {
        "image/ogg": [".ogg"],
        "image/mp3": [".mp3"],
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptAudioFiles,
        maxSize: 1024 * 1024 * 1024,
        disabled: files.length !== 0 && true,
    });

    return (
        <>
         <Box className="upload-main">
            <Grid border='2px' borderColor={(errors.audioFiles && touched.audioFiles) ? 'red.500' : 'gray.200'}
                padding="20px" borderStyle="dashed">
                <div {...getRootProps()}>
                    <Input {...getInputProps()} />
                    <div className="file-upload-content">
                        <Image
                            boxSize="100px"
                            objectFit="cover"
                            src={FileUploadIcon}
                            alt="upload-icon"
                        />
                        <Heading
                            fontSize="xl"
                            display="flex"
                            alignItems="center"
                            color={GREY_TEXT_COLOR}
                            my={4}
                          >
                               Drag audio files to upload
                               <Tooltip label={
                                   <Box>
                                      <Text color="#000"><b>Audio file type:-</b> .ogg or .mp3 </Text>
                                      <Text color="#000"><b>Audio maximum file size:-</b> upto 1GB</Text>
                                      <Text color="#000"><b>Duration:-</b> upto 30 seconds</Text>
                                   </Box>} closeDelay={500} bg='#e2e8f0'>
                                   <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                               </Tooltip>
                               </Heading>
                               <Text
                                   fontSize="sm"
                                   my={4}
                                   color={GREY_TEXT_COLOR}
                               >
                                   - OR -
                               </Text>
                                <Button
                                bgColor={BLUE_BUTTON_COLOR} color="white" size="small" px={10} py={2}
                                disabled={files.length !== 0 && true} borderRadius={100}>Browse Files</Button>
                    </div>
                </div>
                  {(errors.audioFiles && touched.audioFiles) &&
                                <Text color="red.500" marginTop="2" textAlign="center">
                                    Audio File is required
                                </Text>
                            }
            </Grid>
             <div className="image-listing">

            {files.map((fileWrapper, index) => {
                let videoURL = fileWrapper?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads${fileWrapper?.imageUrl}` : null;
                if (!videoURL) {
                    videoURL = URL.createObjectURL(fileWrapper.file)
                }
                return (
                    <Box className="file-box" padding={"20px"} alignItems={"center"} gap={"16px"} key={index}>
                        <Flex marginTop="5" marginBottom="5">
                            <audio
                                src={videoURL}
                                controls
                            />
                            <Spacer />
                            <Grid>
                                <CloseIcon
                                    cursor="pointer"
                                    className="close-icon"
                                    size="small"
                                    onClick={() => {
                                        if (fileWrapper.file) {
                                            onDelete(fileWrapper.file)
                                        } else {
                                            onDelete(fileWrapper)
                                        }
                                    }
                                    }
                                />
                            </Grid>
                        </Flex>
                        <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
                       <Progress
                                                       className="loading"
                                                       value={100}
                                                       colorScheme='green'
                                                       size='sm'
                                                   />
                    </Box>
                );
            })}
                         </div>

            </Box>
        </>
    );
}
