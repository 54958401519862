import {useSelector} from "react-redux";
import DV360AdDetails from "../DV360/DV360AdDetails";
import {useGetAdUpload} from "../../../../../hooks/campaign-briefs/useGetAdUpload";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import DV360ImageAdDetails from "./DV360/DV360Image/DV360ImageAdDetails";
import DV360AudioAdDetails from "./DV360/DV360Audio/DV360AudioAdDetails";
import DV360YoutubeVideoDetails from "./DV360/DV360YoutubeVideo/DV360YoutubeVideoDetails";
import DV360VideoAdDetails from "./DV360/DV360Video/DV360VideoAdDetails";
import PinterestImageAdDetails from "./Pintrest/PinterestImage/PinterestImageAdDetails";
import PinterestVideoAdDetails from "./Pintrest/PinterestVideo/PinterestVideoAdDetails";
import TiktokVideoAdDetails from "./Tiktok/TiktokVideo/TiktokVideoAdDetails";
import SnapchatVideoAdDetails  from "./Snapchat/SnapchatVideo/SnapchatVideoAdDetails";
import SnapchatImageAdDetails from "./Snapchat/SnapchatImage/SnapchatImageAdDetails";
import PinterestCarouselAdDetails from "../StepByStepAdUploadComponent/Pintrest/PinterestCarousel/PinterestCarouselAdDetails";
import GoogleAdDetails from "./Google/GoogleCsv/GoogleAdDetails";
import FacebookImageAdDetails from "./Facebook/FacebookImage/FacebookImageAdDetails";
import FacebookVideoAdDetails from "./Facebook/FacebookVideo/FacebookVideoAdDetails";
import InstagramImageAdDetails from "./Instagram/InstagramImage/InstagramImageAdDetails";
import FacebookCarouselAdDetails from "./Facebook/FacebookCarousel/FacebookCarouselAdDetails";
import InstagramVideoAdDetails from "./Instagram/InstagramVideo/InstagramVideoAdDetails";
import InstagramCarouselAdDetails from "./Instagram/InstagramCarousel/InstagramCarouselAdDetails";
import instance from "../../../../../helpers/axios";
import MetaCarouselAdDetails from "./Meta/MetaCarousel/MetaCarouselAdDetails";
import MetaVideoAdDetails from "./Meta/MetaVideo/MetaVideoAdDetails";
import MetaImageAdDetails from "./Meta/MetaImage/MetaImageAdDetails";
import DynamicFacebookImageAdDetails from "./Facebook/DynamicFBImage/DynamicFacebookImageAdDetails";
import DynamicFacebookVideoAdDetails from "./Facebook/DynamicFacebookVideo/DynamicFacebookVideoAdDetails";
import DynamicFacebookBothAdDetails from "./Facebook/DynamicFacebookBoth/DynamicFacebookBothAdDetails";
import DynamicInstagramImageAdDetails from "./Instagram/DynamicInstagramImage/DynamicInstagramImageAdDetails";
import DynamicInstagramVideoAdDetails from "./Instagram/DynamicInstagramVideo/DynamicInstagramVideoAdDetails";
import DynamicInstagramBothAdDetails from "./Instagram/DynamicInstagramBoth/DynamicInstagramBothAdDetails";
import YoutubeShortsAdDetails from "./Youtube/YoutubeShorts/YoutubeShortsAdDetails";
import DemandGenImageAdDetails from "./DemandGen/DemandGenImage/DemandGenImageAdDetails";
import DemandGenVideoAdDetails from "./DemandGen/DemandGenVideo/DemandGenVideoAdDetails";
import DemandGenCarouselAdDetails from "./DemandGen/DemandGenCarousel/DemandGenCarouselAdDetails";
import PerformanceMaxAdDetails from "./Performance/PerformanceMaxAdDetails";
import SocialDisplayImageAdDetails from "./SocialDisplay/SocialDisplayImage/SocialDisplayImageAdDetails";
import SocialDisplayVideoAdDetails from "./SocialDisplay/SocialDisplayVideo/SocialDisplayVideoAdDetails";
import MicrosoftAdDetails from "./Microsoft/MicrosoftCsv/MicrosoftAdDetails";
import AudioAdDetails from "./Audio/AudioAdDetails";
import LinkedInImageAdDetails from "./LinkedIn/LinkedinImage/LinkedInImageAdDetails";
import LinkedInVideoAdDetails from "./LinkedIn/LinkedinVideo/LinkedInVideoAdDetails";
import DiscoveryAdDetails from "./Discovery/DiscoveryAdDetails";

function AdDetailsForm({ activeStep, steps, prevStep, nextStep, label, adCopyData, popupDetail , setAdCopyData, setShowAdDetailsForm}) {
    const { id } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const [autoFillData, setAutoFillData] = useState({});
    const {mediaType, editMediaType} = useSelector((state) => state.adUpload);

    function getCreativeType() {
        let suffix;
        if (mediaType?.split("_" || " ")?.length === 1) {
            suffix = mediaType?.split(" ")[0] === 'dv360' ? "Static" : "Google Display Ad"
            return suffix
        } else {
            let parts = mediaType?.split("_");
            let suffix = parts && parts[parts.length - 1].toUpperCase();
            switch (suffix) {
                case 'AUDIO':
                    return 'Audio';
                case 'YOUTUBE':
                    return 'Youtube Video';
                case 'IMAGE':
                    return 'Static';
                case 'VIDEO':
                    return 'Video';
                case 'CAROUSEL':
                    return 'Carousel';
                case "BOTH":
                    return "Image + Video";
                default:
                    return "Static";
            }
        }
    }

    useEffect(()=> {
        fetchData()
    }, [mediaType, editMediaType]);

    const fetchData = async () => {
        try {
            const response = await instance({
                method: "POST",
                url: `/client/${clientId}/campaign-brief/get-autofill`,
                withCredentials: false,
                data: {
                    campaignId: id,
                    creativeType: getCreativeType(),
                },
            });

            if (response.status === 200) {
                setAutoFillData( response?.data?.data?.detail || {});
            }
        } catch (error) {
            console.error('Error', error);
        }
    };

    if(editMediaType === "DV360" || mediaType === "dv360") {
        return (
            <DV360ImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
        )
    }
    if(editMediaType === "PinterestImage" || mediaType === "PINTEREST_IMAGE") {
        return (
            <PinterestImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
        )
    }
    if(editMediaType === "PinterestVideo" || mediaType === "PINTEREST_VIDEO") {
        return (
            <PinterestVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
        )
    }
    if(editMediaType === "PinterestCarousel" || mediaType === "PINTEREST_CAROUSEL") {
        return (
            <PinterestCarouselAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
        )
    }
    if(editMediaType === "TiktokVideo" || mediaType === "TIKTOK_VIDEO") {
        return (
            <TiktokVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
        )
    }
    if(editMediaType === "SnapchatVideo" || mediaType === "SNAPCHAT_VIDEO") {
        return (
            <SnapchatVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
        )
    }
    if(editMediaType === "SnapchatImage" || mediaType === "SNAPCHAT_IMAGE") {
        return (
            <SnapchatImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData} />
        )
    }                                                                                                                              
    if(editMediaType === "InstagramVideo" || mediaType === "INSTAGRAM_VIDEO") {
        return (
            <InstagramVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData} adCopyData={adCopyData} popupDetail={popupDetail} setAdCopyData={setAdCopyData} setShowAdDetailsForm={setShowAdDetailsForm}/>
        )
    }
    if(editMediaType === "InstagramImage" || mediaType === "INSTAGRAM_IMAGE") {
        return (
            <InstagramImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData} adCopyData={adCopyData} popupDetail={popupDetail} setAdCopyData={setAdCopyData} setShowAdDetailsForm={setShowAdDetailsForm}/>
        )
    }
    if(editMediaType === "InstagramCarousel" || mediaType === "INSTAGRAM_CAROUSEL") {
        return (
            <InstagramCarouselAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
        )
    }

    // Meta
    if(editMediaType === "META_IMAGE" || mediaType === "MetaImage") {
        return <MetaImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    if(mediaType === "META_VIDEO" || editMediaType === "MetaVideo") {
        return <MetaVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    if(mediaType === "META_CAROUSEL" || editMediaType === "MetaCarousel") {
        return <MetaCarouselAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    // skipping for now
    if(mediaType === "DV360_AUDIO") {
        return <DV360AudioAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    if(editMediaType === "DV360YoutubeVideo" || mediaType === "DV360_YOUTUBE_VIDEO") {
        return <DV360YoutubeVideoDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    if( editMediaType === "DV360Video" || mediaType === "DV360_VIDEO") {
        return <DV360VideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData} />
    }

    if(editMediaType === "DV360Audio" || mediaType === "DV360_AUDIO") {
        return <DV360AudioAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    if(editMediaType === "FACEBOOK" || mediaType === "FACEBOOK_IMAGE") {
        return <FacebookImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData} adCopyData={adCopyData} popupDetail={popupDetail} setAdCopyData={setAdCopyData} setShowAdDetailsForm={setShowAdDetailsForm}/>
    }

    if(mediaType === "FACEBOOK_VIDEO" || editMediaType === "FBVideo") {
        return <FacebookVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData} adCopyData={adCopyData} popupDetail={popupDetail} setAdCopyData={setAdCopyData} setShowAdDetailsForm={setShowAdDetailsForm}/>
    }

    if(mediaType === "FACEBOOK_CAROUSEL" || editMediaType === "FBCarousel") {
        return <FacebookCarouselAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData} adCopyData={adCopyData} popupDetail={popupDetail} setAdCopyData={setAdCopyData} setShowAdDetailsForm={setShowAdDetailsForm}/>
    }

    // Dynamic Creatives
    if(mediaType === "DYNAMIC_FACEBOOK_IMAGE" || editMediaType === "DYNAMIC_FACEBOOK") {
        return <DynamicFacebookImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    if(mediaType === "DYNAMIC_FACEBOOK_VIDEO" || editMediaType === "DynamicFBVideo") {
        return <DynamicFacebookVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }
    if(mediaType === "DYNAMIC_FACEBOOK_BOTH" || editMediaType === "DynamicFBBoth") {
        return <DynamicFacebookBothAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    if(editMediaType === "DynamicInstagramImage" || mediaType === "DYNAMIC_INSTAGRAM_IMAGE") {
        return <DynamicInstagramImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }
    if(editMediaType === "DynamicInstagramVideo" || mediaType === "DYNAMIC_INSTAGRAM_VIDEO") {
        return <DynamicInstagramVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }
    if(mediaType === "DYNAMIC_INSTAGRAM_BOTH" || editMediaType === "DynamicInstagramBoth") {
        return <DynamicInstagramBothAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    // Google
    if(mediaType === "display ads" || editMediaType === "Google") {
        return <GoogleAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    // Microsoft
    if(mediaType === "MICROSOFT" || editMediaType === "Microsoft") {
        return <MicrosoftAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    // Youtube Shorts

    if(mediaType === "YOUTUBE_SHORTS_VIDEO" || editMediaType === "YOUTUBE_SHORTS_VIDEO") {
        return <YoutubeShortsAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    // Demand Gen
    if(editMediaType === "DemandGenImage" || mediaType === "DEMAND_GEN_IMAGE") {
        return <DemandGenImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }
    if(editMediaType === "DemandGenVideo" || mediaType === "DEMAND_GEN_VIDEO") {
        return <DemandGenVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }
    if(editMediaType === "DemandGenCarousel" || mediaType === "DEMAND_GEN_CAROUSEL") {
        return <DemandGenCarouselAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    // Performance Max
    if(editMediaType === "PerformanceMax" || mediaType === "PERFORMANCE_MAX") {
        return <PerformanceMaxAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    // Social Display
    if(editMediaType === "SocialDisplayImage" || mediaType === "SOCIAL_DISPLAY_IMAGE") {
        return <SocialDisplayImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData} adCopyData={adCopyData} popupDetail={popupDetail} setAdCopyData={setAdCopyData} setShowAdDetailsForm={setShowAdDetailsForm}/>
    }
    if(editMediaType === "SocialDisplayVideo" || mediaType === "SOCIAL_DISPLAY_VIDEO") {
        return <SocialDisplayVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData} adCopyData={adCopyData} popupDetail={popupDetail} setAdCopyData={setAdCopyData} setShowAdDetailsForm={setShowAdDetailsForm}/>
    }

    // Audio
    if(editMediaType === "Audio" || mediaType === "AUDIO") {
        return <AudioAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    // Linkedin
    if(editMediaType === "LinkedinImage" || mediaType === "LINKEDIN_IMAGE") {
        return <LinkedInImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }
    if(editMediaType === "LinkedinVideo" || mediaType === "LINKEDIN_VIDEO") {
        return <LinkedInVideoAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }

    // Discovery
    if(editMediaType === "Discovery" || mediaType === "DISCOVERY") {
        return <DiscoveryAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} autoFillData={autoFillData}/>
    }
}

export default AdDetailsForm
