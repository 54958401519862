import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  settingSnapchatVideoFormMedia,
  settingSnapchatVideoHandlePreview,
} from "./store"; // Make sure to replace this path with the correct one
import {SnapchatVideoAdFileUpload} from "./SnapchatVideoAdFileUpload"; // Update the import path to your actual file upload component

function SnapchatVideoAdMedia({ activeStep, steps, prevStep, nextStep, label }) {
  const { adUploadId } = useParams();

  const { mediaDetails } = useSelector((state) => state.snapchatVideoAd);

  const schema = yup.object().shape({
    fileURL: yup.array().required().label("Video"),
  });

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      fileURL: null,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(settingSnapchatVideoFormMedia(values));
      if (values.fileURL) {
        let videoURL = values?.fileURL[0]?.imageUrl
          ? `${process.env.REACT_APP_API_URL}/uploads${values.fileURL[0]?.imageUrl}`
          : null;
        if (!videoURL) {
          videoURL = URL.createObjectURL(values.fileURL[0]);
        }
        dispatch(settingSnapchatVideoHandlePreview(videoURL));
      }
      nextStep();
    },
  });

  const { errors, values, touched, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    setFieldValue("fileURL", mediaDetails?.fileURL ? mediaDetails?.fileURL : null);
  }, [mediaDetails]);

  return (
    <Box className="file-upload-box">
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <SnapchatVideoAdFileUpload
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            type="snapchat"
            values={values}
          />
          <Flex width="100%" justify="flex-end" py={10}>
            <Button
              mr={4}
              onClick={prevStep}
              colorScheme="blue"
              variant="outline"
              borderRadius={0}
              px={10}
            >
              Back
            </Button>
            <Button
              mr={4}
              colorScheme="green"
              type="submit"
              variant="solid"
              borderRadius={0}
              px={10}
            >
              Next
            </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </Box>
  );
}

export default SnapchatVideoAdMedia;
