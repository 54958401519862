import { Heading, VStack, Box, Text, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { profile } from "../../atoms/authAtom";
import { useGetDashboardComments } from "../../hooks/dashboard/useGetDashboardComments";
import { Comments } from "./components/Comments";
import { useSelector } from "react-redux";

export const Greeting = () => {
  let myDate = new Date();
  let hours = myDate.getHours();
  let greet;

  if (hours < 12) greet = "morning";
  else if (hours >= 12 && hours <= 17) greet = "afternoon";
  else if (hours >= 17 && hours <= 24) greet = "evening";

  return <span>Good {greet}</span>;
};

const Dashboard = () => {
  const { name } = useRecoilValue(profile);
  const [commentDashboardData, setCommentDashboardData] = useState({
    comments: [],
    clientData: [],
  });
  const clientId = useSelector((state) => state.client.clientId);

  const { mutate, data } = useGetDashboardComments();
  useEffect(() => {
    mutate();
  }, [clientId, mutate]);

  useEffect(() => {
    if (data) {
      setCommentDashboardData(data);
    }
  }, [data]);

  const { clientData, comments } = commentDashboardData;

  if (comments.length > 0) {
    for (let i = 0; i < comments?.length; i++) {
      comments[i].clientData = clientData[i];
    }
  }

  return (
    <Box>
      <VStack align="stretch" spacing={1}>
        <Heading
          color="#757998"
          fontSize={{
            base: "xl",
            md: "2xl",
          }}
          fontWeight="500"
        >
          <Greeting />, {name}.
        </Heading>
        <Text
          fontSize={{
            base: "md",
            md: "lg",
          }}
          fontWeight={"500"}
          color={"brand.primary"}
        >
          Here’s what you missed:
        </Text>
      </VStack>
      <HStack justify="space-between" mt="8" spacing="0">
        <Box>
          <Comments data={comments} />
        </Box>
        <Box
          border={"2px solid rgba(167, 169, 189, 0.2)"}
          borderRadius={"lg"}
          minH={"400px"}
          p={{
            base: 8,
            md: 10,
          }}
        >
          <Text
            color={"brand.primary"}
            fontWeight={"bold"}
            marginBottom={"20px"}
          >
            What's new?
          </Text>
          <Box color={"#757998"}>
            <Text>
              September 19<sup>th</sup> 2023{" "}
            </Text>
            <ul style={{ paddingLeft: "40px" }}>
              <li>v12.1 update</li>
              <li> Implemented a minimized sidebar.</li>
              <li> Frontend modifications</li>
              <li>
                <a
                  href="https://docs.google.com/document/d/1IB8lMVpmknd2e7sJ1LaBleMFPo1JjOdVvwlTQvWapH0/edit#heading=h.lluk4dipbjf5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Read more...</u>
                </a>
              </li>
            </ul>
            <Text mt="4">
              September 15<sup>th</sup> 2023{" "}
            </Text>
            <ul style={{ paddingLeft: "40px" }}>
              <li>v12.0 update</li>
              <li>Looker Studio is integrated with the Kedet</li>
              <li>
                <a
                  href="https://docs.google.com/document/d/1IB8lMVpmknd2e7sJ1LaBleMFPo1JjOdVvwlTQvWapH0/edit#heading=h.cl6savrhh17t"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Read more...</u>
                </a>
              </li>
            </ul>
          </Box>
        </Box>
      </HStack>
    </Box>
  );
};

export default Dashboard;
