import {CloseIcon, DownloadIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {
    Box, Button,
    Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip, useToast
} from "@chakra-ui/react";
import FileSaver from 'file-saver';
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import DefaultImageIcon from "../../../../../assets/images/default-image-icon.png";
import FileUploadIcon from "../../../../../assets/images/file-upload-icon.png";
import WithAppContext from "../../../../../helpers/appContext";
import {TEXT_COLOR} from "../../../../../layout/constant/MenuList";
import {DV360_IMAGE_SIZE} from "../../../../../constant";

const DV360AdFileUpload = ({errors, touched, values, setFieldValue, context}) => {
    const toast = useToast()

    const onDrop = (accFiles) => {
        accFiles.map((file) => {
            setFieldValue('imageURL', [...values.imageURL, file]);
        });
    }

    const acceptImageFiles = {
        "image/png": [".png"],
        "image/jpeg": [".jpg"],
        "image/gif": [".gif"],
    };

    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop,
        accept: acceptImageFiles,
        maxSize: DV360_IMAGE_SIZE, // 10MB
        disabled: values.imageURL.length >= 12, // 12 max count
    });

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleRemoveFile = (idx) => {
        const arr = [...values.imageURL];
        const newArr = arr.filter((el, index) => index !== idx);
        setFieldValue('imageURL', newArr);
    }

    // dropZone Errors
    useEffect(() => {
        fileRejections && fileRejections.map(({errors}) => {
            errors.map((err) => {
                toast({
                    status: 'error',
                    variant: 'top-accent',
                    position: 'top-right',
                    description: err.code + ',' + err.message || 'Please upload valid image.'
                })
            })
        })
    }, [fileRejections])

    return (
        <>
            <Grid padding="20px" borderStyle="dashed" border='2px'
                  borderColor={(errors.imageURL && touched.imageURL) ? 'red.500' : 'gray.200'}>
                <div {...getRootProps()}>
                    <Input {...getInputProps()} />

                    <div className="file-upload-content">
                        <Image
                            boxSize="100px"
                            objectFit="cover"
                            src={FileUploadIcon}
                            alt="upload-icon"
                        />
                        <Heading
                            fontSize="xl"
                            display="flex"
                            alignItems="center"
                            color={TEXT_COLOR}
                        >
                            Drag and drop files
                            <Tooltip label={
                                <Box>
                                    <Text color="#000"><b>Image file type:</b> jpg, png and jpeg</Text>
                                    <Text color="#000"><b>Image maximum file size:</b> 10 MB</Text>
                                    <Text color="#000"><b>Image maximum files:</b> 12</Text>
                                </Box>} closeDelay={500} bg='#e2e8f0'>
                                <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                            </Tooltip>
                        </Heading>
                        <Heading
                            fontSize="sm"
                            mb={1}
                            color={TEXT_COLOR}
                        >
                            or
                        </Heading>
                        <Button size="small">Browse</Button>
                    </div>
                </div>
            </Grid>
            {(errors.imageURL && touched.imageURL) &&
                <Text color="red.500" marginTop="1">
                    {errors.imageURL || 'please valid file'}
                </Text>
            }
            {
                values.imageURL.map((file, index) => {
                    let imgURL = '';
                    let fileName = '';
                    if (file && file.fieldname) {
                        imgURL = `${process.env.REACT_APP_API_URL}/uploads/${file.filename}`;
                        fileName = file.originalname;
                    } else {
                        imgURL = URL.createObjectURL(file)
                        fileName = file.name
                    }

                    return (
                        <Grid className="file-box" key={index}>
                            <Flex marginTop="5" marginBottom="5">
                                <Image
                                    boxSize="50px"
                                    objectFit="cover"
                                    src={DefaultImageIcon}
                                    alt="Dan Abramov"
                                />
                                <Grid marginLeft="2">
                                    <Grid alignItems='center' display="flex">
                                        <Text
                                            fontSize="lg"
                                            className="file-name"
                                        >
                                            {fileName}
                                        </Text>
                                    </Grid>
                                    {file &&
                                        <Text fontSize="sm">
                                            {formatBytes(file.size)}
                                        </Text>
                                    }
                                </Grid>
                                <Spacer/>
                                <Grid>
                                    <Grid justifyContent="center">
                                        <CloseIcon
                                            cursor="pointer"
                                            className="close-icon"
                                            size="small"
                                            onClick={() => handleRemoveFile(index)}
                                        />
                                    </Grid>
                                    <Grid>
                                        <DownloadIcon
                                            onClick={() => 

                                                FileSaver.saveAs(imgURL, fileName)}
                                            cursor="pointer"
                                            className="close-icon"
                                            size="small"
                                            w={6}
                                            h={6}
                                        />
                                    </Grid>
                                </Grid>
                            </Flex>
                            <Progress
                                className="loading"
                                value={100}
                            />
                        </Grid>
                    );
                })
            }
        </>
    );
}

export default WithAppContext(DV360AdFileUpload)
