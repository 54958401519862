import React from 'react';
import {
  Box, Circle,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text
} from "@chakra-ui/react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../../constant/SelectValues";

function GmailIn({formDetails, ads, handleIndex, index1, businessName, handleGmailState, carouselCards}) {

  const selectedLogos = (Array.isArray(formDetails?.selectedLogos) && formDetails?.selectedLogos[0]?.imageUrl) ?
    (process.env.REACT_APP_API_URL + "/uploads/" + formDetails?.selectedLogos[0]?.imageUrl) :
    process.env.PUBLIC_URL + "/images/display.png";

  return (
    <>
      <Box backgroundColor='gray.300' borderBottom='1px #e3e8f1 solid' textAlign='center'
           borderTopRadius="25px"
           alignSelf="center">
        <Flex justifyItems="center" px={3} justifyContent="space-between" py={3} backgroundColor="white"
              borderTopRadius="25">
          <Flex alignItems="center" justifyContent="center">
            <Image src={`${process.env.PUBLIC_URL}/images/back_icon.png`} alt="Back Button" height={6}
                   width={8} onClick={() => handleGmailState()} cursor="pointer"/>
          </Flex>
          <Flex alignItems="center" justifyContent="center" gap={5}>
            <Image src={`${process.env.PUBLIC_URL}/images/ad_icon.svg`} alt="Back Button" height={6}
                   width={6}/>
            <Image src={`${process.env.PUBLIC_URL}/images/star_icon.png`} alt="Back Button" height={6}
                   width={6}/>
            <Image src={`${process.env.PUBLIC_URL}/images/garbage_icon.png`} alt="Back Button" height={5}
                   width={5}/>
            <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Back Button" height={5}
                   width={5}/>
          </Flex>
        </Flex>
      </Box>
      <Box width="100%" boxShadow='sm' bg='white' borderBottomRadius="25px">
        <Box display="flex" gap="5" p={6} pb={0} height="20%">
          <Image src={selectedLogos} height={12}
                 width={12} rounded="full"/>
          <Box>
            <Text fontWeight="500">{businessName}</Text>
            <Text fontSize={'small'}>To me</Text>
          </Box>
        </Box>
        <Box>
          {(ads && ads.length !== 0) ?
            <Flex overflow="hidden" width={"100%"}>
              <Swiper navigation={true} modules={[Navigation]}
                      onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                {
                  ads.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <Box
                          height="100%"
                          width="90%"
                          borderRadius="lg"
                          boxShadow="md"
                          bg="white"
                          margin="5"
                          alignItems="center"
                        >
                          <Image
                            height="100%"
                            width="100%"
                            objectFit="cover"
                            src={item[index1]?.imageUrl}
                            className="previewImage"
                          />
                          <Text height="20%" width="100%" textAlign="center" mt={2}>{carouselCards[index].headline}</Text>
                        </Box>
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </Flex> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
          }
        </Box>
        <Box mx={8} fontWeight="600"> {formDetails.headline} </Box>
        <Box mx={8} mb={4} fontSize="14px"> {formDetails.description} </Box>
        <Box bg="#3b6dc8" textColor="white" textAlign="center" borderRadius="25px" mx={4} py={1} mb={4}>
          <button>{youtubeShortsTypes.find((item) => item.key === formDetails?.type)?.name}</button>
        </Box>
      </Box>
    </>
  );
}

export default GmailIn;
