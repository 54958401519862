import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, useDisclosure, Text, GridItem, FormControl, FormLabel, Select, Grid, Button, useToast, Checkbox,
} from '@chakra-ui/react'
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom'
import axios from "../../helpers/axios";
import ErrorModal from "../../components/PopupModal/ErrorModal";
import {deleteAllAdCampaignFields} from "../../store/adCampaign";
import ConfirmationModal from "../../components/PopupModal/ConfirmationModal";

function ConcentAgreementDialog({onClose, isOpen}) {
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [errorSubmit, setErrorSubmit] = useState("");
    const detail = useSelector((state) => state.adCampaign)
    const clientId = useSelector((state) => state.client.clientId);
    const [campId, setCampId] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()

    function handleSubmitCampaignDetails() {
        const payload = {
            name: detail.campaignDetails.campaignName,
            startDate: detail.campaignBudget.startDate,
            endDate: detail.campaignBudget.endDate,
            business_id: detail.businessDetails.id,
            detail
        }
        axios.post(`/client/${clientId}/campaign-brief`, payload)
            .then((res) => {
                setIsSuccessModalOpen(true)
                toast({
                        isClosable: true,
                        status: "success",
                        variant: "top-accent",
                        position: "top-right",
                        description: "Campaign created Successfully",
                    })
                setCampId(res.data.data.id)
                    dispatch(deleteAllAdCampaignFields())
                    onClose()
                }
            ).catch(err => {
            setIsErrorModalOpen(true)
            setErrorSubmit(err.response.data.message);
            onClose()
        })
    }

    function onCloseSuccessModal(){
        setIsSuccessModalOpen(false)
        navigate("/campaign-briefs")
    }

    function onCloseconfirmationModal() {
        setIsSuccessModalOpen(false);
        navigate(`/campaign-briefs/${campId}/ad-upload/new`);
    }

    return (
        <>
            <Modal onClose={onClose} isOpen={isOpen} isCentered size='5xl'>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Consent Agreement</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Text mb={2} fontSize={'sm'}>
                            By agreeing to this message, I, hereby grant permission to Kedet and
                            War Room to make a changes to the selected audiences, budget and advanced setting for the
                            purpose of optimizing the campaign.These modifications may be made without seeking further
                            consent and approval.
                        </Text>
                        <Text mb={2} fontSize={'sm'}>
                            I understand that the intension behind these changes is to improve the effectiveness and
                            efficiency of the campaign, aiming to achieve the desired outcomes and maximize the retuen
                            of investment, I acknowledge that Kedet and War Room process the necessary expertise and
                            exprience to make informed decisions regarding these optimizations.
                        </Text>
                        <Text mb={2} fontSize={'sm'}>
                            Furthermore, I trust that any adjustments made by Kedet and War Room will align with
                            campaign's overall objectives and adhere to best practices in the field. I accept that their
                            actions are best interest of optimizing results and enhancing the campaign's performance.
                        </Text>
                        <Text mb={2} fontSize={'sm'}>
                            This consent agreement will remains in effect untill conclusion of campaign, unless
                            otherwise started in writing. I reserve the right to revoke this consent agreement at any
                            time by providing written notice to Kedet and War Room.
                        </Text>
                        <Text mb={4} fontSize={'sm'}>
                            By accepting this message, I confirmed that I have read and understood the terms of this
                            consent agreement and agree to its conditions.
                        </Text>
                        <Checkbox>
                            <span style={{fontSize: "14px"}}>I have diligently reviewed the terms and conditions and willingly accept them as I proceed with creating the campaign.</span>
                        </Checkbox>

                    </ModalBody>
                    <ModalFooter>
                        <Button display={'flex'} justifyContent={'flex-end'}
                                fontFamily={"poppins"} px={8} py={5} _hover={{bg: ''}}
                                color={"#FFFFFF"} bgColor={"#1F95FF"} border={"2px"} borderColor="#1F95FF"
                                style={{margin: "1.4rem 0 1.4rem auto"}} variant='' onClick={handleSubmitCampaignDetails}>Agree</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                description={errorSubmit}
            />
            <ConfirmationModal
                isOpen={isSuccessModalOpen}
                onClose={() => onCloseSuccessModal()}
                onConfirm= {() => onCloseconfirmationModal()}
            />
        </>
    )
}

export default ConcentAgreementDialog
