import {Box, Button} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import SocialDisplayImageAdFileUpload from "./SocialDisplayImageAdFileUpload";
import {settingSocialDisplayImageMedia, settingSocialDisplayImagePreview} from "./store";
import {checkIfFilesAreCorrectType, checkIfFilesAreTooBig} from "../../../../../../../helpers/fileValidation";
import ImageCropper from "../components/ImageCropper";
import {cropImage} from "../components/CropUtils";
import _ from "lodash";

function SocialDisplayImageAdMedia({activeStep, steps, prevStep, nextStep, label}) {
    const { mediaDetails } = useSelector((state) => state.socialDisplayImageAd)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0
    });
    const [imgMetaData, setImageMetadata] = useState(mediaDetails.imgMetaData || {});

    const schema = yup.object().shape({
        imageURL: yup.array()
            .required("You need to provide a file")
            .test("fileSize", "The file is too large", checkIfFilesAreTooBig)
            .test(
                "type", "Only the following formats are accepted: .jpeg, .jpg, .gif, .png",
                checkIfFilesAreCorrectType
            ).min(1).max(12).required(),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            imageURL: [],
            thumbnail_url: ''
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingSocialDisplayImageMedia({...values, dimensions: dimensions, imgMetaData: imgMetaData}))
            if (values && values.imageURL) {
                const files = values.imageURL.map((file) => {
                    if (file && file.filename) {
                        return {
                            imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${file.imageUrl}`,
                            filename: file.filename
                        }
                    } else {
                        return {
                            imageUrl: URL.createObjectURL(file),
                            filename: file.name
                        }
                    }
                })
                dispatch(settingSocialDisplayImagePreview(files));
            }
            nextStep()
        })
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;

    let imageUrl = ''
    // if(!values.imageURL[0]){
    //     imageUrl = ''
    // }
    // else if(values.imageURL[0] && values.imageURL[0]?.files){
    //     imageUrl = `${process.env.REACT_APP_API_URL}/uploads/${values.imageURL[0]?.files?.filename}`
    // }else {
    //     imageUrl = URL.createObjectURL(values?.imageURL[0])
    // }

    useEffect(() => {
        setFieldValue("imageURL", mediaDetails?.imageURL ? mediaDetails?.imageURL : [])
    }, [mediaDetails])

    // useEffect(() => {
    //     if(values?.imageURL[0] && !_.isEmpty(values?.imageURL[0]?.metadata)){
    //         setDialogOpen(false)
    //         const croppedAreaPixels = values?.imageURL[0]?.metadata
    //         setImageMetadata(croppedAreaPixels);
    //         cropImage(imageUrl, croppedAreaPixels, setCroppedImage)
    //     }
    // }, [values])

    function handleCloseDialog() {
        setDialogOpen(false)
        setCroppedImage(null)
    }

    // useEffect(() => {
    //     if (croppedImage) {
    //         const blob = base64toBlob(croppedImage);
    //         const imageUrl = blob && URL.createObjectURL(blob);
    //         setFieldValue('thumbnail_url', imageUrl);
    //     }
    // }, [croppedImage])

    const base64toBlob = (base64String) => {
        const parts = base64String.split(';base64,');

        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);

        const rawLength = raw.length;
        const array = new Uint8Array(new ArrayBuffer(rawLength));

        for(let i = 0; i < rawLength; ++i) {
            array[i] = raw.charCodeAt(i);
        }

        return new Blob([array], { type: contentType });
    }

    useEffect(() => {
        // if(!values?.imageURL[0]?.files){
            if (Array.isArray(values?.imageURL) && values?.imageURL[0]) {
                let img = new Image();
                img.src = imageUrl
                img.onload = async () => {
                    const {naturalWidth: width, naturalHeight: height} = img
                    setDimensions({ width, height })
                }
            } else {
                setFieldValue('thumbnail_url', null);
            }
        // }
    }, [values]);

    return (
        <Box className="file-upload-box">
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <SocialDisplayImageAdFileUpload
                        getHashArray={(value) => {
                            setFieldValue('imageURL', value)
                        }}
                        setOpen={setDialogOpen}
                        setDialogOpen={setDialogOpen}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        type="social_display_image"
                        values={values}
                        croppedImage={values?.thumbnail_url}
                    />

            {/*---------------------------------------------------DIALOG----------------------------------------------------*/}

                    {/*{Array.isArray(values?.imageURL) && values?.imageURL[0] && <ImageCropper*/}
                    {/*    open={dialogOpen}*/}
                    {/*    setOpen={setDialogOpen}*/}
                    {/*    image={imageUrl}*/}
                    {/*    onComplete={(imagePromise) => {*/}
                    {/*        imagePromise.then((image) => {*/}
                    {/*            const imageURL = URL.createObjectURL(image);*/}
                    {/*            setFieldValue('thumbnail_url', imageURL);*/}
                    {/*            handleCloseDialog()*/}
                    {/*        }).catch((e) => {*/}
                    {/*            console.log('Error', e)*/}
                    {/*        });*/}
                    {/*    }}*/}
                    {/*    dimensions={{*/}
                    {/*        width: dimensions.width,*/}
                    {/*        height: dimensions.height*/}
                    {/*    }}*/}
                    {/*    croppedImage={croppedImage}*/}
                    {/*    setCroppedImage={setCroppedImage}*/}
                    {/*    setImageMetadata={setImageMetadata}*/}
                    {/*    handleCloseDialog={handleCloseDialog}*/}
                    {/*    containerStyle={{*/}
                    {/*        position: "relative",*/}
                    {/*        display: "flex",*/}
                    {/*        justifyContent: "center",*/}
                    {/*        width: 650,*/}
                    {/*        height: 650,*/}
                    {/*        background: "#333"*/}
                    {/*    }}*/}
                    {/*/>}*/}

                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}
                                // isDisabled={!values.thumbnail_url}
                        >
                            Next
                        </Button>
                    </Flex>
                </Form>
            </FormikProvider>
        </Box>
    )
}

export default SocialDisplayImageAdMedia;
