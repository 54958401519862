import {Box, Button, Container, Flex, Grid, GridItem, Heading, Spacer} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PREVIEW_BG_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {deleteTheField} from "../../../../../../../store/adUploads/index"
import {deleteAllSnapchatVideoFields} from "./store";
import ReactPlayer from "react-player";
import _ from "lodash";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";
import {BsThreeDotsVertical} from "react-icons/bs";
import {FaAngleRight} from "react-icons/fa6";
import {fetchVideoMetadata} from "../../LinkedIn/LinkedinVideo/LinkedInVideoAdPreview";
import {CreateMultipleCreatives} from "../../SocialDisplay/SocialDisplayImage/SocialDisplayImageAdPreview";

const SnapchatVideoAdPreview = ({ activeStep, steps, prevStep, nextStep, label, context }) => {

    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const { id: campaignId, adUploadId } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const { setAppLoading, appLoading } = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)
    const { allCampaignIds } = useSelector((state) => state.adUpload);

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    const { handlePreviewData, formDetails, mediaDetails } = useSelector((state) => state.snapchatVideoAd)
    const { creative, selectedSocialMedia } = useSelector((state) => state.adUpload)

    const { fileInfoList } = formDetails

    console.log('formDetails', formDetails)

    useEffect(() => {
        setAds(handlePreviewData)
    }, [handlePreviewData]);

    const { mutateAsync } = useUploadImage();

    const onHandleSubmit = async (values) => {
        let videoData;
        let mediaInfoList = [];
        let mediaIds = [];

        console.log('mediaDetails', mediaDetails, values.fileURL)
        if (_.isEqual(fileInfoList, values.fileURL)) {
            console.log('fileInfoList', fileInfoList)
            videoData = formDetails;
        } else {

            console.log('values.fileURL', values.fileURL)
            const videoFile = await mutateAsync({
                clientId: clientId,
                campaignBriefId: campaignId,
                adFile: values.fileURL,
                type: "Snapchat",
            });
            console.log('videoFile', videoFile)

            mediaInfoList = videoFile && await fetchVideoMetadata(videoFile.fileInfoList)

            console.log("mediaInfoList ", mediaInfoList);
            mediaIds = videoFile && videoFile.fileInfoList.map((el) => el.imageHash);

            videoData = {
                mediaIds: mediaIds,
                fileInfoList: mediaInfoList,
            };
        }


        return {
            name: formDetails.adName,
            displayName: formDetails.displayName,
            description: formDetails.description,
            detail: {
                name: formDetails.adName,
                displayName: formDetails.displayName,
                description: formDetails.description,
                channel_name: formDetails.channel_name,
                channel_icon: formDetails.channelIcon,
                link: formDetails.link,
                ...videoData,
                creative,
                channel: selectedSocialMedia
            },
        };
    };

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if (adUploadId) {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
                    state: { isShowUpload: true }
                })
            }
            dispatch(deleteAllSnapchatVideoFields())
            dispatch(deleteTheField())
        }
        else {
            if(allCampaignIds?.length){
                navigate(`/campaign-briefs`)
            }else {
                navigate(`/campaign-briefs/${campaignId}/ad-upload`)
            }

            dispatch(deleteAllSnapchatVideoFields())
            dispatch(deleteTheField())
        }
    }

    async function handleSubmit() {
        try {
            setAppLoading(true);
            let payload = await onHandleSubmit(mediaDetails);
            if (!adUploadId) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.SNAPCHAT_VIDEO,
                };
            }

            if(allCampaignIds?.length){
                CreateMultipleCreatives(allCampaignIds, method, url, clientId, payload, setSuccessModal, setErrorModal, setAppLoading, setDescription, dispatch);
            } else {
                await instance({
                    method: method,
                    url: url,
                    withCredentials: false,
                    data: payload,
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setSuccessModal(true);
                        }
                        setAppLoading(false);
                    })
                    .catch((error) => {
                        setErrorModal(true);
                        setDescription(error.response.data.message);
                        setAppLoading(false);
                    });
            }
        } catch (error) {
            setAppLoading(false);
        }
    }

    return (
        <>

            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="snapchat-upload">
                {appLoading && <FullPageLoader />}
                <GridItem w="full" colSpan={{ base: 6, lg: 6 }}>
                    <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
                        {ads?.[index]?.filename}
                    </Heading>
                    <Box
                        borderWidth="2px"
                        borderColor="gray"
                        p={10}
                        background={PREVIEW_BG_COLOR}
                    >

                        <Container height="100%">
                            <Flex alignItems="center" flexDirection="row" justify="center">

                                <div className="containers">
                                    <div className="header-container">
                                        <div className="left-section">
                                            <img src={`${formDetails?.channelIcon?.imageUrl ? process.env.REACT_APP_API_URL + "/uploads" + formDetails?.channelIcon?.imageUrl : "/images/display.png"}`}
                                                 alt=""
                                                 className="round-image" />
                                        </div>
                                        <div className="center-section">
                                            <h2>{formDetails?.channel_name ? formDetails.channel_name : "Kedet"}</h2>
                                            <p className="detail">{formDetails?.description}</p>
                                        </div>
                                        <div className="right-section">
                                            <div className="dots-icon"><BsThreeDotsVertical /></div>
                                        </div>
                                    </div>
                                    <ReactPlayer
                                        className='react-player videos'
                                        url={handlePreviewData}
                                        width={430}
                                        height={530}
                                        playing={true}
                                        loop={true}

                                    />

                                    <div className="footer">
                                        <div className="content-wrapper">
                                            <div className="logo">
                                                <img src={`${formDetails?.channelIcon?.imageUrl ? process.env.REACT_APP_API_URL + "/uploads" + formDetails?.channelIcon?.imageUrl : "/images/display.png"}`}
                                                     alt="" className="logo" /></div>
                                            <div className="text-content">
                                            <h2>{formDetails?.channel_name ? formDetails.channel_name : "Kedet"}</h2>
                                            <p>{formDetails?.description}</p>
                                            </div>
                                            <Spacer />
                                           <div className="arrowimage">
                                            <FaAngleRight/>
                                           </div>

                                        </div>

                                       <div className="space">Ad</div>
                                       <div className="">.</div>
                                    </div>

                                </div>

                            </Flex>
                        </Container>
                    </Box>
                    <Spacer p={5} />
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                            { adUploadId ? 'Update' : 'Submit'}
                        </Button>
                    </Flex>

                </GridItem>
            </Grid>
            <SuccessModal
                    isOpen={isSuccessModalOpen}
                    onClose={() => onCloseSuccessModal()}
                />
                <ErrorModal
                    isOpen={isErrorModalOpen}
                    onClose={() => setErrorModal(false)}
                    description={description}
                />
        </>
    );
};

export default WithAppContext(SnapchatVideoAdPreview);
