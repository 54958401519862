import { Box, Heading, Grid, GridItem } from "@chakra-ui/react";
import InputBox from "../../../../components/InputBox";
import { ADDITIONAL_QUESTIONS } from "../../../../constant";
import {TEXT_COLOR} from "../../../../layout/constant/MenuList";
import React from "react";

const AdditionalQuestions = ({ ...props }) => {
    return (
        <Box p={4}>
            <Box display="flex" justifyContent="space-between" mb={6}>
                <Heading fontSize="xl" color={TEXT_COLOR}>
                    Additional Questions
                </Heading>
                <Heading fontSize="md" color="gray.400">
                    1 steps to complete
                </Heading>
            </Box>
            <Grid className="flex-for-mobile" templateColumns="repeat(12, 1fr)" gap={4}>
                {ADDITIONAL_QUESTIONS.map((question) => (
                    <GridItem colSpan={6} key={question.id}>
                        <InputBox name={question.name} placeholder={question.label} />
                    </GridItem>
                ))}
            </Grid>
        </Box>
    );
};

export default AdditionalQuestions;
