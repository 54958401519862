import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box, Button, Circle,
  Container, Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image, Skeleton, SkeletonCircle, SkeletonText, Spacer,
  Text
} from "@chakra-ui/react";

function GmailMonitorOut({formDetails, ads, handleIndex, businessName, handleGmailState}) {
  const selectedLogos = (Array.isArray(formDetails?.selectedLogos) && formDetails?.selectedLogos[0]?.imageUrl) ?
    (process.env.REACT_APP_API_URL + "/uploads/" + formDetails?.selectedLogos[0]?.imageUrl) :
    process.env.PUBLIC_URL + "/images/display.png";

  console.log('formDetails', formDetails)
  return (
    <>
      <Flex bg="transparent" height="15%" borderTopRadius="25px" alignItems="center" px={2}>
        <Image src={`${process.env.PUBLIC_URL}/images/three_line_icon.svg`} alt="Back Button" height={8}
               width={8} pl={2}/>
        <Image src={`${process.env.PUBLIC_URL}/images/gmail.png`} alt="Gmail Icon" height={8}
               width={10} pl={2}/>
        <Text color="gray.500" fontWeight="600" fontSize="20px" px={2}>Gmail</Text>
        <Flex bg={'gray.300'} height="40%" width="40%" borderRadius="5px" justifyItems="center" alignItems="center">
          <Image src={`${process.env.PUBLIC_URL}/images/search_1.png`} alt="Gmail Icon" height={4}
                 width={6} pl={2}/>
        </Flex>
        <Spacer/>
        <SkeletonCircle size="6"/>
      </Flex>
      <Flex bg="transparent" height="85%" borderTopRadius="25px" alignItems="center" overflow="hidden">
        <Box bg="transparent" width="30%" height="100%" borderTopRadius="25px" alignItems="center">
          <Skeleton height="20%" width="60%" borderRadius="10px" m={4} px={2}/>
          <Skeleton height="10%" width="90%" borderRightRadius="10px"/>
          {[...Array(3)].map((_, index) => (
            <Box m={4} px={2}>
              <Flex gap={2} alignItems="center">
                <SkeletonCircle size="6"/>
                <SkeletonText noOfLines={1} skeletonHeight='3' width="50%"/>
              </Flex>
            </Box>
          ))}
        </Box>
        <Box bg="white" width="68%" height="100%" borderTopRadius="25px" onClick={() => handleGmailState()} cursor="pointer">
          <Flex justifyItems="center" justifyContent="space-between" px={3} gap={8} py={3} backgroundColor="white" m={2}
                mb={5} border="2px solid #dddddd">
            <Flex alignItems="center" justifyContent="center" gap={5}>
              <Flex gap={2} alignItems="center">
                <Image src={`${process.env.PUBLIC_URL}/images/star_icon.png`} alt="Back Button" height={5}
                       width={5}/>
                <Text fontWeight="bold" fontSize="small">Business Name</Text>
              </Flex>
              <Flex gap={2} alignItems="center">
                <Image src={`${process.env.PUBLIC_URL}/images/ad_icon.svg`} alt="Ad Icon" height={5}
                       width={5}/>
                <Text fontWeight="bold" fontSize="small">Headline</Text>
                <Text fontSize="small"> - Description</Text>
              </Flex>
            </Flex>
            <Box>
              <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Back Button" height={4}
                     color="red"
                     width={4}/>
            </Box>
          </Flex>
          {[...Array(9)].map((_, index) => (
            <>
              <Flex key={index} gap="3" pl={6} my={2}>
                <SkeletonCircle size='7'/>
                <SkeletonCircle size='7'/>
                <SkeletonText noOfLines={1} skeletonHeight='6' rounded="lg" width="15%"/>
                <SkeletonText noOfLines={1} skeletonHeight='6' rounded="lg" width="40%"/>
                <SkeletonText noOfLines={1} skeletonHeight='6' rounded="lg" width="10%" ml={20}/>
              </Flex>
              <Divider/>
            </>
          ))}
        </Box>
      </Flex>
    </>
  );
}

export default GmailMonitorOut;
