import {useSelector} from "react-redux";
import DV360ImageAdMedia from "./DV360/DV360Image/DV360ImageAdMedia";
import DV360AudioAdMedia from "./DV360/DV360Audio/DV360AudioAdMedia";
import React from "react";
import DV360YoutubeVideoAdMedia from "./DV360/DV360YoutubeVideo/DV360YoutubeVideoAdMedia";
import PinterestImageAdMedia from "./Pintrest/PinterestImage/PinterestImageAdMedia";
import DV360VideoAdMedia from "./DV360/DV360Video/DV360VideoAdMedia";
import PinterestVideoAdMedia from "./Pintrest/PinterestVideo/PinterestVideoAdMedia";
import TiktokVideoAdMedia from "./Tiktok/TiktokVideo/TiktokVideoAdMedia";
import PinterestCarouselAdMedia from "./Pintrest/PinterestCarousel/PinterestCarouselAdMedia";
import InstagramImageAdMedia from "./Instagram/InstagramImage/InstagramImageAdMedia";
import FacebookImageAdMedia from "./Facebook/FacebookImage/FacebookImageAdMedia";
import InstagramVideoAdMedia from "./Instagram/InstagramVideo/InstagramVideoAdMedia";
import FacebookVideoAdMedia from "./Facebook/FacebookVideo/FacebookVideoAdMedia";
import FacebookCarouselAdMedia from "./Facebook/FacebookCarousel/FacebookCarouselAdMedia";
import MetaCarouselAdMedia from "./Meta/MetaCarousel/MetaCarouselAdMedia";
import InstagramCarouselAdMedia from "./Instagram/InstagramCarousel/InstagramCarouselAdMedia";
import MetaVideoAdMedia from "./Meta/MetaVideo/MetaVideoAdMedia";
import MetaImageAdMedia from "./Meta/MetaImage/MetaImageAdMedia";
import DynamicFacebookVideoAdMedia from "./Facebook/DynamicFacebookVideo/DynamicFacebookVideoAdMedia"
import DynamicFacebookImageAdMedia from "./Facebook/DynamicFBImage/DynamicFacebookImageAdMedia";
import DynamicFacebookBothAdMedia from "./Facebook/DynamicFacebookBoth/DynamicFacebookBothAdMedia";
import DynamicInstagramImageAdMedia from "./Instagram/DynamicInstagramImage/DynamicInstagramImageAdMedia";
import DynamicInstagramVideoAdMedia from "./Instagram/DynamicInstagramVideo/DynamicInstagramVideoAdMedia";
import DynamicInstagramBothAdMedia from "./Instagram/DynamicInstagramBoth/DynamicInstagramBothAdMedia";
import DemandGenImageAdMedia from "./DemandGen/DemandGenImage/DemandGenImageAdMedia";
import DemandGenCarouselAdMedia from "./DemandGen/DemandGenCarousel/DemandGenCarouselAdMedia";
import PerformanceMaxAdMedia from "./Performance/PerformanceMaxAdMedia";
import SocialDisplayImageAdMedia from "./SocialDisplay/SocialDisplayImage/SocialDisplayImageAdMedia";
import SocialDisplayVideoAdMedia from "./SocialDisplay/SocialDisplayVideo/SocialDisplayVideoAdMedia";
import AudioAdMedia from "./Audio/AudioAdMedia";
import LinkedInImageAdMedia from "./LinkedIn/LinkedinImage/LinkedInImageAdMedia";
import LinkedInVideoAdMedia from "./LinkedIn/LinkedinVideo/LinkedInVideoAdMedia";
import DiscoveryAdMedia from "./Discovery/DiscoveryAdMedia";
import SnapchatVideoAdMedia from "./Snapchat/SnapchatVideo/SnapchatVideoAdMedia"
import SnapchatImageAdMedia from "./Snapchat/SnapchatImage/SnapchatImageAdMedia";

function AdMediaForm({activeStep, steps, prevStep, nextStep, label}) {

    const {selectedSocialMedia, mediaType, editMediaType} = useSelector((state) => state.adUpload);
    const data = useSelector((state) => state.adUpload);

    if(mediaType === "dv360" || editMediaType === "DV360") {
        return (
            <DV360ImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "PINTEREST_IMAGE" || editMediaType === "PinterestImage") {
        return (
            <PinterestImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "PINTEREST_CAROUSEL" || editMediaType === "PinterestCarousel") {
        return (
            <PinterestCarouselAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "PINTEREST_VIDEO" || editMediaType === "PinterestVideo") {
        return (
            <PinterestVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "TIKTOK_VIDEO" || editMediaType === "TikTokVideo") {
        return (
            <TiktokVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "SNAPCHAT_VIDEO" || editMediaType === "SNAPCHAT_VIDEO") {
        return (
            <SnapchatVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "SNAPCHAT_IMAGE" || editMediaType === "SnapchatImage") {
        return (
            <SnapchatImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_VIDEO" || editMediaType === "InstagramVideo") {
        return (
            <InstagramVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_IMAGE" || editMediaType === "InstagramImage") {
        return (
            <InstagramImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "INSTAGRAM_CAROUSEL" || editMediaType === "InstagramCarousel") {
        return (
            <InstagramCarouselAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_IMAGE" || editMediaType === "FACEBOOK") {
        return (
            <FacebookImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_CAROUSEL" || editMediaType === "FBCarousel") {
        return (
            <FacebookCarouselAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "FACEBOOK_VIDEO" || editMediaType === "FBVideo") {
        return (
            <FacebookVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    // Dynamic Creatives
    if(mediaType === "DYNAMIC_FACEBOOK_IMAGE" || editMediaType === "DYNAMIC_FACEBOOK") {
        return <DynamicFacebookImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }
    if(mediaType === "DYNAMIC_FACEBOOK_VIDEO" || editMediaType === "DynamicFBVideo") {
        return <DynamicFacebookVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }
    if(mediaType === "DYNAMIC_FACEBOOK_BOTH" || editMediaType === "DynamicFBBoth") {
        return <DynamicFacebookBothAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    if(mediaType === "DYNAMIC_INSTAGRAM_IMAGE" || editMediaType === "DynamicInstagramImage") {
        return <DynamicInstagramImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }
    if(mediaType === "DYNAMIC_INSTAGRAM_VIDEO" || editMediaType === "DynamicInstagramVideo") {
        return <DynamicInstagramVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }
    if(mediaType === "DYNAMIC_INSTAGRAM_BOTH" || editMediaType === "DynamicInstagramBoth") {
        return <DynamicInstagramBothAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }
    // Meta
    if(mediaType === "META_IMAGE" || editMediaType === "MetaImage") {
        return (
            <MetaImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "META_CAROUSEL" || editMediaType === "MetaCarousel") {
        return (
            <MetaCarouselAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }
    if(mediaType === "META_VIDEO" || editMediaType === "MetaVideo") {
        return (
            <MetaVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
        )
    }

    // skipping for now
    if(mediaType === "DV360_AUDIO") {
        return <DV360AudioAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(editMediaType === "DV360YoutubeVideo" || mediaType === "DV360_YOUTUBE_VIDEO") {
        return <DV360YoutubeVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label}/>
    }

    if(editMediaType === "DV360Video" || mediaType === "DV360_VIDEO") {
        return <DV360VideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    if(editMediaType === "DV360Audio" || mediaType === "DV360_AUDIO") {
        return <DV360AudioAdMedia  activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    // Demand Gen
    if(mediaType === "DEMAND_GEN_IMAGE" || editMediaType === "DemandGenImage") {
        return <DemandGenImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }
    if(mediaType === "DEMAND_GEN_CAROUSEL" || editMediaType === "DemandGenCarousel") {
        return <DemandGenCarouselAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    // Performance Max
    if(mediaType === "PERFORMANCE_MAX" || editMediaType === "PerformanceMax") {
        return <PerformanceMaxAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    // Social Display
    if(mediaType === "SOCIAL_DISPLAY_IMAGE" || editMediaType === "SocialDisplayImage") {
        return <SocialDisplayImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }
    if(mediaType === "SOCIAL_DISPLAY_VIDEO" || editMediaType === "SocialDisplayVideo") {
        return <SocialDisplayVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    // Audio
    if(mediaType === "AUDIO" || editMediaType === "Audio") {
        return <AudioAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    // Linkedin
    if(mediaType === "LinkedinImage" || editMediaType === "LINKEDIN_IMAGE") {
        return <LinkedInImageAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }
    if(mediaType === "LinkedinVideo" || editMediaType === "LINKEDIN_VIDEO") {
        return <LinkedInVideoAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

    // Discovery
    if(mediaType === "DISCOVERY" || editMediaType === "Discovery") {
        return <DiscoveryAdMedia activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
    }

}

export default AdMediaForm
