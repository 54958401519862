import { Avatar, Box, Image } from "@chakra-ui/react";
import moment from "moment";
import {useState} from "react";



const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    if(text.length < 150) {
        return (
            <p className="text">
                {text}
            </p>
        )
    }

    return (
        <p className="text">
            "
            {isReadMore ? text?.slice(0, 150) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? <span style={{ cursor: "pointer", textDecoration: "underline"}}> read more...</span> : <span style={{ cursor: "pointer", textDecoration: "underline"}}> show less</span>}
            </span>
            "
        </p>
    );
};

export const Comments = ({ data }) => {

    return (
        <>
            {data?.map((el, index) => (

                <Box
                    border="1.5px solid rgba(167, 169, 189, 0.2)"
                    borderColor="gray.300"
                    borderRadius={"5px"}
                    key={index}
                    w="100%"
                    p={6}
                    mb={4}
                >
                    <Box display="flex" alignItems={"flex-start"}>
                        <Box position="relative" >
                            <Avatar
                                size="md"
                                src={
                                    `${process.env.REACT_APP_API_URL}/uploads/${el?.user?.other_info?.profile_pic_url}`
                                }
                                name={el?.user?.name}
                            />
                            <Box position="absolute" width="28px" style={{ bottom: 0, left: "50%", transform: "translate(-50%, 80%)" }} height="30px"  borderRadius="50%" display={"flex"} alignItems="center" justifyContent="center" background="white">
                                <Image  src={`${process.env.REACT_APP_API_URL}/uploads/${el?.clientData?.other_info?.profile_logo_url}`} />
                            </Box>
                        </Box>
                        <Box flexGrow={1} ml={10} mr={10} fontSize="16px">
                            <Box  marginBottom={"5px"}><Box as={"span"} color={"#565871"} fontWeight="600">{el?.user?.name}</Box> <Box as={"span"} color={"#757998"} fontWeight="600"> mentioned you in a comment in </Box> <br /> <Box fontWeight="600" as={"span"} color={"#79A9FF"}>{el?.campaign?.name}</Box></Box>
                            <Box fontStyle="Lora" color="#757998" fontWeight={600}><ReadMore>"{el?.content}"</ReadMore></Box>
                        </Box>
                        <Box fontSize="14px" color="#A7A9BD" minWidth={"150px"} fontWeight={"600"} textAlign={"right"}>
                            {
                                moment(el?.created_at).fromNow()
                            }
                            {/*{moment(el?.created_at).format(*/}
                            {/*    "MMMM Do YYYY, h:mm a"*/}
                            {/*)}*/}
                        </Box>
                    </Box>
                </Box>
            ))}
        </>
    );
};
