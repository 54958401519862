import {CloseIcon, DownloadIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {
    Box, Button,
    Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip, useToast
} from "@chakra-ui/react";
import FileSaver from 'file-saver';
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import JPGIcon from "../../../../../../../assets/images/jpg.svg";
import PNGIcon from "../../../../../../../assets/images/png.svg";
import {SOCIAL_DISPLAY_IMAGE_SIZE} from "../../../../../../../constant";
import {BLUE_BUTTON_COLOR, GREY_TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import WithAppContext from "../../../../../../../helpers/appContext";
import _ from "lodash";

const SocialDisplayImageAdFileUpload = ({errors, touched, values, setFieldValue, context, setDialogOpen, croppedImage}) => {
    const toast = useToast()

    const onDrop = (accFiles) => {
        accFiles.map((file) => {
            setFieldValue('imageURL', [...values.imageURL, file]);
        });
    }

    const acceptImageFiles = {
        "image/png": [".png"],
        "image/jpeg": [".jpg"],
    };

    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop,
        accept: acceptImageFiles,
        maxSize: SOCIAL_DISPLAY_IMAGE_SIZE,
        disabled: values.imageURL.length >= 1
    });

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleRemoveFile = (idx) => {
        const arr = [...values.imageURL];
        const newArr = arr.filter((el, index) => index !== idx);
        setFieldValue('imageURL', newArr);
    }

    const handleCropImg = (index) => {
        setDialogOpen(true);
        if(!_.isEmpty(values?.imageURL[0]?.metadata)){
            const newData = [{...values.imageURL[0], metadata: {}}];
            setFieldValue('imageURL', newData)
        }
    }

    // dropZone Errors
    useEffect(() => {
        fileRejections && fileRejections.map(({errors}) => {
            errors.map((err) => {
                toast({
                    status: 'error',
                    variant: 'top-accent',
                    position: 'top-right',
                    description: err.code + ',' + err.message || 'Only .png and .jpg format files are allowed.'
                })
            })
        })
    }, [fileRejections])


    return (
        <>
            <Box className="upload-main"  >
                <Grid padding="20px" paddingY={"80px"} border={"2px dashed"}
                      borderColor={(errors.imageURL && touched.imageURL) ? 'red.500' : 'gray.200'}>
                    <div {...getRootProps()}>
                        <Input {...getInputProps()} />

                        <div className="file-upload-content">
                            <Image
                                boxSize="100px"
                                objectFit="cover"
                                src={FileUploadIcon}
                                alt="upload-icon"
                            />
                            <Heading
                                fontSize="xl"
                                display="flex"
                                alignItems="center"
                                color={GREY_TEXT_COLOR}
                                my={4}
                            >
                                Drag Image Files to Upload
                                <Tooltip label={
                                    <Box>
                                        <Text color="#000"><b>Image file type:</b> jpg, png and jpeg</Text>
                                        <Text color="#000"><b>Image maximum file size:</b> 10 MB</Text>
                                        <Text color="#000"><b>Accepted file size:</b>970x250, 300x250, 300x600, 320x480, 250x250, 336x280</Text>
                                    </Box>} closeDelay={500} bg='#e2e8f0'>
                                    <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                                </Tooltip>
                            </Heading>
                            <Text
                                fontSize="sm"
                                mb={4}
                                color={GREY_TEXT_COLOR}
                            >
                                - OR -
                            </Text>
                            <Button bgColor={BLUE_BUTTON_COLOR} color="white" size="small" px={10} py={2} disabled={values.imageURL.length >= 1} borderRadius={100}>Browse Files</Button>
                        </div>
                    </div>
                    {(errors.imageURL && touched.imageURL) &&
                    <Text color="red.500" marginTop="2" textAlign="center">
                        {errors.imageURL || 'please valid file'}
                    </Text>
                    }
                </Grid>

                <div className="image-listing">
                    {
                        values.imageURL.map((file, index) => {
                            let imgURL = '';
                            let fileName = '';
                            if (file && file.filename) {
                                imgURL = `${process.env.REACT_APP_API_URL}/uploads/${file.imageUrl}`;
                                fileName = file.filename;
                            } else {
                                imgURL = URL.createObjectURL(file)
                                fileName = file.name
                            }
                            const fileType = file?.type || file?.files?.type;
                            const fileMimeType = file?.mimetype || file?.files?.mimetype;
                            const fileSize = file?.size || file?.files?.size;

                            return (
                              <>
                                <Box display={"flex"} alignItems={"center"} gap={"16px"} className="file-box" key={index}>
                                    {
                                        fileType === "image/jpeg" && <Image
                                            boxSize="50px"
                                            objectFit="cover"
                                            src={JPGIcon}
                                            alt="Dan Abramov"
                                        />
                                    }
                                    {
                                        fileType === "image/png" && <Image
                                            boxSize="50px"
                                            objectFit="cover"
                                            src={PNGIcon}
                                            alt="Dan Abramov"
                                        />
                                    }
                                    {
                                        fileMimeType === "image/jpeg" && <Image
                                            boxSize="50px"
                                            objectFit="cover"
                                            src={JPGIcon}
                                            alt="Dan Abramov"
                                        />

                                    }
                                    {
                                        fileMimeType === "image/png" && <Image
                                            boxSize="50px"
                                            objectFit="cover"
                                            src={PNGIcon}
                                            alt="Dan Abramov"
                                        />

                                    }
                                    <Box flexGrow={1}>
                                        <Flex marginTop="5"  marginBottom="5">
                                            <Grid marginLeft="2">
                                                <Grid alignItems='center' display="flex">
                                                    <Text
                                                        fontSize="lg"
                                                        className="file-name"
                                                    >
                                                        {fileName}
                                                    </Text>
                                                </Grid>
                                                {file &&
                                                <Text fontSize="sm">
                                                    {formatBytes(fileSize)}
                                                </Text>
                                                }
                                            </Grid>
                                            <Spacer/>
                                            <Grid>
                                                <Grid justifyContent="center">
                                                    <CloseIcon
                                                        cursor="pointer"
                                                        className="close-icon"
                                                        size="small"
                                                        onClick={() => handleRemoveFile(index)}
                                                    />
                                                </Grid>
                                                <Grid>
                                                    <DownloadIcon
                                                        onClick={() => FileSaver.saveAs(imgURL, fileName)}
                                                        cursor="pointer"
                                                        className="close-icon"
                                                        size="small"
                                                        w={6}
                                                        h={6}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Flex>
                                        <Box marginTop={"-20px"}>
                                            <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
                                            <Progress
                                                className="loading"
                                                value={100}
                                                colorScheme='green'
                                                size='sm'
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                  {/*{croppedImage ? (<img src={croppedImage} alt="" height="400px" width="500px"/>) : (<Text color='red' fontWeight='500'>First crop the image to proceed!!</Text>)}*/}
                              </>
                            );
                        })
                    }
                </div>
            </Box>

        </>
    );
}

export default WithAppContext(SocialDisplayImageAdFileUpload)
