import {CloseIcon, DownloadIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {
    Box, Button,
    Flex, Grid, Heading, Image, Input, Progress, Spacer, Text, Tooltip, useToast
} from "@chakra-ui/react";
import FileSaver from 'file-saver';
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import DefaultImageIcon from "../../../../../../../assets/images/default-image-icon.png";
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import WithAppContext from "../../../../../../../helpers/appContext";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {GREY_TEXT_COLOR, TEXT_COLOR, BLUE_BUTTON_COLOR} from "../../../../../../../layout/constant/MenuList";
import {DV360_IMAGE_SIZE, PINTEREST_IMAGE_SIZE} from "../../../../../../../constant";
import JPGIcon from "../../../../../../../assets/images/jpg.svg";
import PNGIcon from "../../../../../../../assets/images/png.svg";

const DemandGenImageAdFileUpload = ({errors, touched, values, setFieldValue, type, maxLength, setErrors, context}) => {
    const toast = useToast()
    const {ErrorToaster} = context;

    const onDrop = (accFiles) => {
        const files = [...values[type], ...accFiles];
        if (files.length <= maxLength) {
            setFieldValue(type, files);
        } else {
            ErrorToaster(`Maximum ${maxLength} ${type.replace('selected', '')} allowed.`)
        }
    }

    const acceptImageFiles = {
        "image/png": [".png"],
        "image/jpeg": [".jpg"],
    };

    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop,
        accept: acceptImageFiles,
        maxSize: PINTEREST_IMAGE_SIZE, // 10MB
        disabled: values[type].length >= maxLength // 12 max count
    });

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleRemoveFile = (idx) => {
        const arr = [...values[type]];
        const newArr = arr.filter((el, index) => index !== idx);
        setFieldValue(type, newArr);
    }

    // dropZone Errors
    useEffect(() => {
        fileRejections && fileRejections.map(({errors}) => {
            errors.map((err) => {
                toast({
                    status: 'error',
                    variant: 'top-accent',
                    position: 'top-right',
                    description: err.code + ',' + err.message || 'Please upload valid image.'
                })
            })
        })
    }, [fileRejections])


    return (
      <>
          <Box className="upload-main" mb={6}>
              <Grid padding="20px" paddingY={"80px"} border={"2px dashed"}
                    borderColor={(errors[type] && touched[type]) ? 'red.500' : 'gray.200'}>
                  <div {...getRootProps()}>
                      <Input {...getInputProps()} />

                      <div className="file-upload-content">
                          <Image
                            boxSize="100px"
                            objectFit="cover"
                            src={FileUploadIcon}
                            alt="upload-icon"
                          />
                          <Heading
                            fontSize="xl"
                            display="flex"
                            alignItems="center"
                            color={GREY_TEXT_COLOR}
                            my={4}
                          >
                              Drag {type.replace('selected', '')} to Upload
                              <Tooltip label={
                                  <Box>
                                      <Text color="#000"><b>Image file type:</b> jpg, png and jpeg</Text>
                                      <Text color="#000"><b>Image maximum file size:</b> 10 MB</Text>
                                      <Text color="#000"><b>Image maximum files:</b> 12</Text>
                                  </Box>} closeDelay={500} bg='#e2e8f0'>
                                  <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
                              </Tooltip>
                          </Heading>
                          <Text
                            fontSize="sm"
                            mb={4}
                            color={GREY_TEXT_COLOR}
                          >
                              - OR -
                          </Text>
                          <Button bgColor={BLUE_BUTTON_COLOR} color="white" size="small" px={10} py={2} disabled={values[type].length >= 1} borderRadius={100}>Browse Files</Button>
                      </div>
                  </div>
                  {(errors[type] && touched[type]) &&
                  <Text color="red.500" marginTop="2" textAlign="center">
                      {errors[type] || 'please valid file'}
                  </Text>
                  }
              </Grid>

              <div className="image-listing">
                  {
                      values && Object.keys(values[type])?.length && values[type]?.map((file, index) => {
                          console.log('File for type ', type, file)
                          let imgURL = '';
                          let fileName = '';
                          if (file && file.filename) {
                              imgURL = `${process.env.REACT_APP_API_URL}/uploads/${file.imageUrl}`;
                              fileName = file.filename;
                          } else {
                              imgURL = URL.createObjectURL(file)
                              fileName = file.name
                          }
                          const fileSize = file.size || file.files.size

                          return (
                            <Box display={"flex"} alignItems={"center"} gap={"16px"} className="file-box" key={index}>
                                {
                                    file?.type === "image/jpeg" && <Image
                                      boxSize="50px"
                                      objectFit="cover"
                                      src={JPGIcon}
                                      alt="Dan Abramov"
                                    />
                                }
                                {
                                    file?.type === "image/png" && <Image
                                      boxSize="50px"
                                      objectFit="cover"
                                      src={PNGIcon}
                                      alt="Dan Abramov"
                                    />
                                }
                                {
                                    file?.files?.mimetype === "image/jpeg" && <Image
                                      boxSize="50px"
                                      objectFit="cover"
                                      src={JPGIcon}
                                      alt="Dan Abramov"
                                    />

                                }
                                {
                                    file?.files?.mimetype === "image/png" && <Image
                                      boxSize="50px"
                                      objectFit="cover"
                                      src={PNGIcon}
                                      alt="Dan Abramov"
                                    />

                                }
                                <Box flexGrow={1}>
                                    <Flex marginTop="5"  marginBottom="5">
                                        <Grid marginLeft="2">
                                            <Grid alignItems='center' display="flex">
                                                <Text
                                                  fontSize="lg"
                                                  className="file-name"
                                                >
                                                    {fileName}
                                                </Text>
                                            </Grid>
                                            {file &&
                                            <Text fontSize="sm">
                                                {formatBytes(fileSize)}
                                            </Text>
                                            }
                                        </Grid>
                                        <Spacer/>
                                        <Grid>
                                            <Grid justifyContent="center">
                                                <CloseIcon
                                                  cursor="pointer"
                                                  className="close-icon"
                                                  size="small"
                                                  onClick={() => handleRemoveFile(index)}
                                                />
                                            </Grid>
                                            <Grid>
                                                <DownloadIcon
                                                  onClick={() => FileSaver.saveAs(imgURL, fileName)}
                                                  cursor="pointer"
                                                  className="close-icon"
                                                  size="small"
                                                  w={6}
                                                  h={6}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Flex>
                                    <Box marginTop={"-20px"}>
                                        <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
                                        <Progress
                                          className="loading"
                                          value={100}
                                          colorScheme='green'
                                          size='sm'
                                        />
                                    </Box>

                                </Box>

                            </Box>
                          );
                      })
                  }
              </div>
          </Box>

      </>
    );
}

export default WithAppContext(DemandGenImageAdFileUpload)
