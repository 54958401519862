import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Form, FormikProvider, useFormik} from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading, Text,
} from "@chakra-ui/react";
import {InputControl, SelectControl, TextareaControl} from "formik-chakra-ui";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {
    settingSocialDisplayVideoForm, settingSocialDisplayVideoMedia
} from "./store";
import {Input} from "@chakra-ui/input";
import {CloseIcon} from "@chakra-ui/icons";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {socialDisplayLengths} from "../../../../../constant/InitialValues";
import WithAppContext from "../../../../../../../helpers/appContext";
import TooltipComponent from "../../TooltipComponent";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";

const initialValue = {
  adName: "",
  displayName: "",
  description: "",
  headline: "",
  caption: "",
  link: "",
  type: "",
  channel_name: "",
  channel_icon: null,
};

function SocialDisplayVideoAdDetails({
                                       activeStep,
                                       steps,
                                       prevStep,
                                       nextStep,
                                       label,
                                       autoFillData,
                                       context,
                                       adCopyData,
                                       popupDetail
                                     }) {
  const {id, adUploadId} = useParams();
  const {mutateAsync} = useUploadImage();

  const clientId = useSelector((state) => state.client.clientId);
  const {formDetails} = useSelector((state) => state.socialDisplayVideoAd);

  const {data, refetch} = useGetAdUpload(clientId, id, adUploadId);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingSocialDisplayVideoForm({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          description: data?.adUpload?.detail?.description,
          headline: data?.adUpload?.detail?.headline,
          caption: data?.adUpload?.detail?.caption,
          link: data?.adUpload?.detail?.call_to_action?.link,
          type: data?.adUpload?.detail?.call_to_action?.type,
          channel_name: data?.adUpload?.detail?.channel_name,
          channelIcon: data?.adUpload?.detail?.channel_icon,
          channel_icon: data?.adUpload?.detail?.channel_icon?.files,
        })
      );
        dispatch(
            settingSocialDisplayVideoMedia({
                fileURL: data?.adUpload?.detail?.selectedImages,
            })
        )
    }
  }, [data]);

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    adName: yup
      .string()
      .trim()
      .min(4)
      .max(255)
      .required()
      .matches(
        /^[!@$%^&#()-=+{};:,<.>0-9a-zA-Z\s]+$/i,
        "Emojis are not allowed as ad Name"
      )
      .label("Ad name"),
    headline: yup.string()
      .trim()
      .max(30)
      .required()
      .label("Headline"),
    channel_name: yup.string()
      .trim()
      .max(30)
      .optional()
      .label("Channel Name"),
    caption: yup.string()
      .trim()
      .test('maxLines', 'Caption must be at most 3 lines', (value) => {
        if (!value) return true;
        const lines = value.split('\n');
        const maxLines = 3;
        return lines.length <= maxLines;
      })
      .min(3)
      .max(90)
      .required()
      .label("Caption"),
    description: yup
      .string()
      .trim()
      .min(3)
      .max(90)
      .required()
      .label("Description"),
    link: yup
      .string()
      .trim()
      .required("Landing page url is required.")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      ).label('Landing page url'),
    channel_icon: yup
      .mixed()
      .test(
        "fileType",
        "Only image files are allowed",
        (value) => {
          if (!value) return true; // Allow empty value
          const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
          return allowedTypes.includes(value?.type || value?.mimetype);
        }
      )
      .nullable(true)
      .label("Channel Icon"),
    type: yup.string().max(125).required().label("Call to action"),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (values.channel_icon?.name) {
        await mutateAsync(
          {
            clientId: clientId,
            campaignBriefId: id,
            adFile: [values.channel_icon],
            type: "youtubeShortsChannelIcon",
          }, {
            onSuccess: (data, variables, context) => {
              dispatch(
                settingSocialDisplayVideoForm({
                  ...data?.adUpload?.detail,
                  ...values,
                  channelIcon: data.fileInfoList[0],
                  channel_icon: null,
                })
              );
            },
            onError: (err) => {
              console.log('error OnError', err)
            }
          });
      } else {
        dispatch(
          settingSocialDisplayVideoForm({
            ...data?.adUpload?.detail,
            ...values,
            channel_icon: null
          })
        );
      }
      nextStep();
    },
  });

  const {errors, values, touched, handleSubmit, isValid, setFieldValue} =
    formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("headline", adCopyData?.headline ||formDetails.headline || autoFillData?.headline || "");
    setFieldValue("caption", adCopyData?.caption ||formDetails.caption || autoFillData?.caption || "");
    setFieldValue("description", adCopyData?.description ||formDetails.description || autoFillData?.description || "");
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
    setFieldValue("channel_name", formDetails.channel_name || autoFillData?.channel_name || "");
    setFieldValue("channel_icon", formDetails.channelIcon?.files || "");
    setFieldValue("channelIcon", formDetails.channelIcon || "");
    setFieldValue("type",  popupDetail.cta || formDetails.type || autoFillData?.type || "");
  }, [formDetails, autoFillData , adCopyData , popupDetail]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFieldValue('channel_icon', file);
  };

  console.log('Error', errors)

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{base: 6, lg: 4}}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    htmlFor="adName"
                    color="gray"
                    fontSize="sm"
                    display={"none"}
                  >
                    Ad Name - max 255 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 255 characters",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />
                    <CircularProgress
                      max={socialDisplayLengths.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        socialDisplayLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        socialDisplayLengths?.adName
                          ? socialDisplayLengths?.adName -
                          values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    htmlFor="headline"
                    color="gray"
                    fontSize="sm"
                    display={"none"}
                  >
                    Headline - max 30 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="headline"
                      name="headline"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Headline - max 30 characters",
                      }}
                      onChange={(e) =>
                        setFieldValue("headline", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={socialDisplayLengths.headline}
                      value={values?.headline?.length}
                      color={
                        values?.headline?.length >
                        socialDisplayLengths?.headline
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.headline?.length >
                        socialDisplayLengths?.headline
                          ? socialDisplayLengths?.headline -
                          values?.headline?.length
                          : values?.headline?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" display={"none"}>
                    Description
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="description"
                      name="description"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Description - max 30 characters",
                      }}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={socialDisplayLengths?.description}
                      value={values?.description?.length}
                      color={
                        values?.description?.length >
                        socialDisplayLengths?.description
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.description?.length >
                        socialDisplayLengths?.description
                          ? socialDisplayLengths?.description -
                          values?.description?.length
                          : values?.description?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="caption" display={"none"}>
                    Caption
                  </FormLabel>
                  <div className="input-box">
                    <TextareaControl
                      id="caption"
                      name="caption"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Caption - max 90 characters",
                      }}
                      onChange={(e) =>
                        setFieldValue("caption", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={socialDisplayLengths?.caption}
                      value={values?.caption?.length}
                      color={
                        values?.caption?.length >
                        socialDisplayLengths?.caption
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.caption?.length >
                        socialDisplayLengths?.caption
                          ? socialDisplayLengths?.caption -
                          values?.caption?.length
                          : values?.caption?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="channel_name" display={"none"}>
                    Channel Name
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="channel_name"
                      name="channel_name"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Channel Name (Optional) - max 90 characters",
                      }}
                      onChange={(e) =>
                        setFieldValue("channel_name", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={socialDisplayLengths?.channel_name}
                      value={values?.channel_name?.length}
                      color={
                        values?.channel_name?.length >
                        socialDisplayLengths?.channel_name
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.channel_name?.length >
                        socialDisplayLengths?.channel_name
                          ? socialDisplayLengths?.channel_name -
                          values?.channel_name?.length
                          : values?.channel_name?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="channel_icon" display={"none"}>
                    Channel Icon
                  </FormLabel>
                  <div className="input-box">
                    <Box py={2}>
                      <Input
                        id="channel_icon"
                        name="channel_icon"
                        type="file"
                        accept="image/*"
                        display="none"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="channel_icon" style={{cursor: "pointer"}}>
                        <Box
                          as="span"
                          padding="8px 16px"
                          border="1px solid #ccc"
                          borderRadius="4px"
                          fontSize="0.825rem"
                          color="#706f7b"
                          _hover={{borderColor: "blue.400"}}
                        >
                          Upload Channel Icon
                        </Box>
                      </label>
                    </Box>
                    {values.channel_icon && (
                      <>
                        <Text>{values.channel_icon.name || values.channel_icon.originalname}</Text>
                        <Grid justifyContent="center">
                          <CloseIcon
                            cursor="pointer"
                            className="close-icon"
                            height={'12px'}
                            width={'12px'}
                            onClick={() => {
                              setFieldValue('channel_icon', null);
                              setFieldValue('channelIcon', null);
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </div>
                  {errors.channel_icon && <Text color='#E53E3E' fontSize="14px" mt="8px">{errors.channel_icon}</Text>}
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" display={"none"}>
                    Landing Page URL
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL",
                      }}
                      onChange={(e) =>
                        setFieldValue("link", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={socialDisplayLengths?.link}
                      value={values?.link?.length}
                      color={
                        values?.link?.length >
                        socialDisplayLengths?.link
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.link?.length >
                        socialDisplayLengths?.link
                          ? socialDisplayLengths?.link -
                          values?.link?.length
                          : values?.link?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="type">Call to Action (Optional)</FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="type"
                      name="type"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("type", e.target.value);
                      }}
                    >
                      {youtubeShortsTypes.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            <Button
              mr={4}
              colorScheme="green"
              type="submit"
              variant="solid"
              borderRadius={0}
              px={10}
              isDisabled={Object.keys(errors).length > 0}
            >
              Next
            </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
);
}

export default WithAppContext(SocialDisplayVideoAdDetails);
