import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useCallback, useEffect} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import {AD_UPLOAD_TYPE, META_IMAGE_HEIGHT, META_IMAGE_WIDTH} from "../../../../../../../constant";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import MetaImagAdFileUpload from "./MetaImageAdFileUpload";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import WithAppContext from "../../../../../../../helpers/appContext";
import {settingMetaImageFormMedia, settingMetaImagePreviewData} from "./store";
import FullPageLoader from "../../../../../../../components/FullPageLoader";


function MetaImageAdMedia({activeStep, steps, prevStep, nextStep, label, context}) {

    const { adUploadId } = useParams()

    const { mediaDetails } = useSelector((state) => state.metaImageAd)

    const schema = yup.object().shape({
        thumbnailURL: yup.object().required().nullable(),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            thumbnailURL: null
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingMetaImageFormMedia({thumbnailFile: values?.thumbnailFile, thumbnailURL: values?.thumbnailURL,...values}))
            nextStep()
        })
    });

    const {setAppLoading, SuccessToaster, ErrorToaster,appLoading} = context
    const clientId = useSelector((state) => state.client.clientId);
    const {id} = useParams();
    const {mutateAsync} = useUploadImage();

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setErrors,
        setFieldValue,
    } = formik;


    useEffect(() => {
        setFieldValue("thumbnailURL", mediaDetails?.thumbnailURL ? mediaDetails?.thumbnailURL : null);
        setFieldValue("thumbnailFile", mediaDetails?.thumbnailFile ? mediaDetails?.thumbnailFile: null);
    }, [mediaDetails])


    const onDrop = useCallback(async (accFiles) => {
        accFiles.map((file) => {
            const img = new Image()
            setAppLoading(true)
            img.onload = async () => {
                const {naturalWidth: width, naturalHeight: height} = img
                if (width < META_IMAGE_WIDTH && height < META_IMAGE_HEIGHT) {
                    ErrorToaster('Minimum 600px image width and height')
                    setAppLoading(false)
                } else {
                    setAppLoading(true);
                    try {
                        await mutateAsync(
                            {
                                clientId: clientId,
                                campaignBriefId: id,
                                adFile: accFiles,
                                type: "fbAdImages",
                            }, {
                                onSuccess: (data) => {
                                    setFieldValue('thumbnailURL', data.file)
                                    setFieldValue('thumbnailFile', data)
                                    setAppLoading(false);
                                    SuccessToaster('Image Upload Successfully.')
                                },
                                onError: (error) => {
                                    setAppLoading(false);
                                }
                            });
                    } catch (e) {
                        setAppLoading(false);
                    }
                }
            }
            img.src = URL.createObjectURL(file)
        })
    }, []);

    return (
    <>
        {appLoading && <FullPageLoader/>}

        <Box className="file-upload-box">
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <MetaImagAdFileUpload
                        getHashArray={(value) => {
                            setFieldValue('thumbnailURL', value)
                        }}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        type="facebookImage"
                        values={values}
                        setErrors={setErrors}
                        onDrop={onDrop}
                    />
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                            Next
                        </Button>
                    </Flex>
                </Form>
            </FormikProvider>
        </Box>
    </>
    )
}

export default WithAppContext(MetaImageAdMedia)
