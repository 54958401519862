import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box, Circle,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image, SkeletonCircle, SkeletonText,
  Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import {useSelector} from "react-redux";

function GmailOut({formDetails, ads, handleIndex, businessName, handleGmailState}) {
  const {handlePreviewData} = useSelector((state) => state.demandGenImageAd)

  const selectedLogos = (Array.isArray(handlePreviewData?.selectedLogos) && handlePreviewData?.selectedLogos[0]?.imageUrl) ?
      (handlePreviewData?.selectedLogos[0]?.imageUrl) :
      process.env.PUBLIC_URL + "/images/display.png";

  return (
    <>
      <Flex backgroundColor='gray.100' textAlign='center' m={6}
            borderRadius="25px"
            alignSelf="center"
            justifyContent="space-between"
      >
        <Flex alignItems="center" gap={2}>
          <Image src={`${process.env.PUBLIC_URL}/images/three_line_icon.svg`} alt="Back Button" height={8}
                 width={8} pl={2}/>
          <Text fontSize="small" color="gray.500" fontWeight="500">Search in mail</Text>
        </Flex>
        <Flex alignItems="center" gap={5} pr={2}>
          <SkeletonCircle size='5'/>
        </Flex>
      </Flex>
      <Box width="100%" bg='white'>
        <Box display="flex" gap="3" pl={6} height="20%">
          <SkeletonCircle size='8'/>
          <Box width="60%">
            <Flex justifyContent="space-between">
              <SkeletonText mt='1' noOfLines={1} spacing='3' skeletonHeight='1.5' width="50%" rounded=""/>
            </Flex>
            <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='1.5' rounded="lg"/>
            <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='1.5' rounded="lg"/>
          </Box>
          <Box width="15%">
            <SkeletonText mt='1' noOfLines={1} spacing='3' skeletonHeight='1.5' width="80%" rounded="lg"/>
          </Box>
        </Box>

        <Box display="flex" gap="3" p={6} height="20%" pb={4} cursor="pointer" onClick={() => handleGmailState()}>
          <Image src={selectedLogos} height={8}
                 width={8} rounded="full"/>
          <Box width="70%">
            <Flex fontWeight="500" gap={2} fontSize="11px" isTruncated="true">Sponsored <li>{businessName}</li></Flex>
            <Text fontSize={'small'} fontWeight="600" isTruncated="true">{Array.isArray(formDetails.headlines) ? formDetails.headlines[0] : formDetails.headline}</Text>
            <Text fontSize={'small'} isTruncated="true">{Array.isArray(formDetails.descriptions) ? formDetails.descriptions[0] : formDetails.description}</Text>
          </Box>
          <Box>
            <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Back Button" height={5}
                   width={5}/>
            <Image src={`${process.env.PUBLIC_URL}/images/star_icon.png`} alt="Back Button" height={5}
                   width={5} mt={5}/>

          </Box>
        </Box>

        {[...Array(6)].map((_, index) => (
          <Box key={index} display="flex" gap="3" pl={6} height="20%" my={6}>
            <SkeletonCircle size='8'/>
            <Box width="60%">
              <Flex justifyContent="space-between">
                <SkeletonText mt='1' noOfLines={1} spacing='3' skeletonHeight='1.5' width="50%" rounded=""/>
              </Flex>
              <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='1.5' rounded="lg"/>
              <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='1.5' rounded="lg"/>
            </Box>
            <Box width="15%">
              <SkeletonText mt='1' noOfLines={1} spacing='3' skeletonHeight='1.5' width="80%" rounded="lg"/>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default GmailOut;
