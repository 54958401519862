import DV360AdFileUpload from "../../../DV360/DV360AdFileUpload";
import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useCallback, useEffect} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import {dv360AdUploadInitialValues} from "../../../../../constant/InitialValues";
import {
    AD_UPLOAD_TYPE,
    FACEBOOK_IMAGE_HEIGHT,
    FACEBOOK_IMAGE_WIDTH, FB_VIDEO_DURATION, INSTAGRAM_IMAGE_HEIGHT,
    INSTAGRAM_IMAGE_WIDTH, INSTAGRAM_VIDEO_THUMBNAIL_HEIGHT, INSTAGRAM_VIDEO_THUMBNAIL_WIDTH, INSTAGRAM_VIDEO_WIDTH_MIN
} from "../../../../../../../constant";
import instance from "../../../../../../../helpers/axios";
import * as yup from "yup";
import {checkIfFilesAreCorrectType, checkIfFilesAreTooBig} from "../../../../../../../helpers/fileValidation";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {settingFormMedia, settingHandlePreviewData} from "../../../../../../../store/adUploads/index"
import {TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useParams} from "react-router-dom";
import {settingInstagramVideoMedia, settingInstagramVideoPreview} from "./store";
import InstagramVideoAdFileUpload from "./InstagramVideoAdFileUpload";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import WithAppContext from "../../../../../../../helpers/appContext";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import calculateAspectRatios from "calculate-aspect-ratio";
import InstagramThumbnailAdFileUpload from "./InstagramThumbnailFileupload";


function InstagramVideoAdMedia({activeStep, steps, prevStep, nextStep, label, context}) {
    const { adUploadId } = useParams()

    const { mediaDetails } = useSelector((state) => state.instagramVideoAd)

    const schema = yup.object().shape({
        thumbnailURL: yup.object().required().nullable(),
        videoURL: yup.object().required().nullable(),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            thumbnailURL: null,
            thumbnailFile: null,
            videoURL: null,
            videoFile: null,
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingInstagramVideoMedia(values))
            nextStep()
        })
    });
    const {setAppLoading, SuccessToaster, ErrorToaster,appLoading} = context;
    const clientId = useSelector((state) => state.client.clientId);
    const {id} = useParams();
    const {mutateAsync} = useUploadImage();

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setErrors,
        setFieldValue,
    } = formik;


    useEffect(() => {
        // setFieldValue("videoURL", mediaDetails?.videoFile ? mediaDetails.videoFile : null);
        setFieldValue("videoURL", mediaDetails?.videoURL ? mediaDetails.videoURL : null);
        setFieldValue("thumbnailURL", mediaDetails?.thumbnailURL ? mediaDetails.thumbnailURL : null);
        setFieldValue("thumbnailFile", mediaDetails?.thumbnailFile ? mediaDetails.thumbnailFile : null);
        setFieldValue("videoFile", mediaDetails?.videoFile ? mediaDetails.video_id || mediaDetails.videoFile : null);
    },[mediaDetails])

    const onDrop = useCallback(async (accFiles) => {
        accFiles.map((file) => {
            const fileType = file.type.split("/").shift();
            if (fileType === "image") {
                const img = new Image()
                img.onload = async () => {
                    const {naturalWidth: width, naturalHeight: height} = img
                    if (width <= INSTAGRAM_VIDEO_THUMBNAIL_WIDTH && height <= INSTAGRAM_VIDEO_THUMBNAIL_HEIGHT) {
                        ErrorToaster('Please valid Image Minimum Width: 500 pixels')
                    } else {
                        setAppLoading(true);
                        await mutateAsync(
                            {
                                clientId: clientId,
                                campaignBriefId: id,
                                adFile: accFiles,
                                type: "fbAdImages",
                            }, {
                                onSuccess: (data) => {
                                    setFieldValue('thumbnailURL', data.file)
                                    setFieldValue('thumbnailFile', data)
                                    setAppLoading(false);
                                    SuccessToaster('Image Upload Successfully.')
                                },
                                onError: (error) => {
                                    // ErrorToaster(error)
                                    setAppLoading(false);
                                }
                            });
                    }
                }
                img.src = URL.createObjectURL(file)
            } else {
                const objectURL = URL.createObjectURL(file);
                const mySound = new Audio([objectURL]);

                let video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    return video
                }
                video.src = URL.createObjectURL(file);
                mySound.addEventListener("canplaythrough", async () => {
                    const ratio = calculateAspectRatios(video.videoHeight, video.videoWidth);
                    if (!ratio >= parseInt('1:1') && !ratio <= parseInt('9:16')) {
                        ErrorToaster('Please valid aspect ratio.')
                    } else if (mySound.duration > FB_VIDEO_DURATION) {
                        ErrorToaster('Please valid Video Duration: 1 second to 60 minutes.')
                    } else if (video.videoWidth <= INSTAGRAM_VIDEO_WIDTH_MIN) {
                        ErrorToaster('Please valid Video Minimum Width: 500 pixels')
                    } else {
                        setAppLoading(true);
                        await mutateAsync(
                            {
                                clientId: clientId,
                                campaignBriefId: id,
                                adFile: accFiles,
                                type: 'fbAdVideos',
                            }, {
                                onSuccess: (data) => {
                                    setFieldValue('videoURL', data.file)
                                    setFieldValue('videoFile', data)
                                    setAppLoading(false);
                                    SuccessToaster('Video Uploaded Successfully.')
                                },
                                onError: (error) => {
                                    // ErrorToaster(error)
                                    setAppLoading(false);
                                }
                            });
                    }
                })
            }
        })
    }, []);

    return (
        <>
            {appLoading && <FullPageLoader/>}
            <Box className="file-upload-box">
                {/*{*/}
                {/*    adUploadId && (*/}
                {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
                {/*            <Heading fontSize="xl" color={"#757998"}>*/}
                {/*                Please review the ad details carefully.*/}
                {/*            </Heading>*/}
                {/*            <Heading fontSize="md" color="gray.400">*/}
                {/*                2 steps to complete*/}
                {/*            </Heading>*/}
                {/*        </Box>*/}
                {/*    )*/}
                {/*}*/}

                {/*{*/}
                {/*    !adUploadId && (*/}
                {/*        <Box display="flex" mb={6} justifyContent="space-between" alignItems="center">*/}
                {/*            <Heading fontSize="xl" color={"#757998"}>*/}
                {/*                Please upload your ad creative.*/}
                {/*            </Heading>*/}
                {/*            <Heading fontSize="md" color="gray.400">*/}
                {/*                2 steps to complete*/}
                {/*            </Heading>*/}
                {/*        </Box>*/}
                {/*    )*/}
                {/*}*/}

                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <InstagramVideoAdFileUpload
                            getHashArray={(value) => {
                                setFieldValue('videoURL', value)
                            }}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            type="facebook-video"
                            values={values}
                            setErrors={setErrors}
                            onDrop={onDrop}
                        />
                        <InstagramThumbnailAdFileUpload
                            getHashArray={(value) => {
                                setFieldValue('thumbnailURL', value)
                            }}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            type="facebook-video-thumbnail"
                            values={values}
                            setErrors={setErrors}
                            onDrop={onDrop}
                        />
                        <Flex width="100%" justify="flex-end" py={10}>
                            <Button
                                mr={4}
                                onClick={prevStep}
                                colorScheme='blue' variant='outline' borderRadius={0} px={10}
                            >
                                Back
                            </Button>
                            <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                                Next
                            </Button>
                        </Flex>
                    </Form>

                </FormikProvider>

            </Box>
        </>
    )
}

export default WithAppContext(InstagramVideoAdMedia)
