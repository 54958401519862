import { Form, FormikProvider, useFormik } from "formik";
import { Box, Button, FormControl, FormLabel } from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import instance from "../../../../../../../../helpers/axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import WithAppContext from "../../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../../components/PopupModal/ErrorModal";
import * as yup from "yup";

function SitelinkEditForm({
  formValue,
  setIsEditFormOpen,
  isEditFormOpen,
  onClose,
  context,
}) {
  const { setAppLoading, SuccessToaster, ErrorToaster } = context;

  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);

  const clientId = useSelector((state) => state.client.clientId);
  const { id: campaignId, microsoftId, instagramId } = useParams();

  let schema = yup.object().shape({
    sitelink_text: yup.string().required("Sitelink Text is Required"),
    sitelink_description_1: yup
      .string()
      .required("Sitelink description 1 is Required"),
    sitelink_description_2: yup
      .string()
      .required("Sitelink description 2 is Required"),
    sitelink_final_url: yup.string().required("URL is Required"),
  });

  async function handleUpdate(values) {
    delete values.other_info;
    delete values.state;
    delete values.state_reason;
    delete values.updated_at;
    delete values.ad_upload_id;
    delete values.created_at;
    delete values.sitelinks;
    delete values.FALSE;

    await instance({
      method: "PUT",
      url: `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${microsoftId}/sitelink/${values.id}`,
      withCredentials: false,
      data: values,
    })
      .then((res) => {
        if (res.status === 200) setSuccessModal(true);
        setIsEditFormOpen(false);
        setAppLoading(false);
      })
      .catch((error) => {
        setErrorModal(true);
        setAppLoading(false);
      });
  }

  let formik = useFormik({
    initialValues: formValue,
    onSubmit: handleUpdate,
    validationSchema: schema,
  });

  const navigate = useNavigate();

  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    // window.location.reload()
    // navigate(`/campaign-brief/${campaignId}/ad-upload/google/${microsoftId}`, {
    //     state: {isShowUpload: true},
    // });
  };

  function handleCancel() {
    setIsEditFormOpen(false);
  }

  const {
    errors,
    values,
    isValid,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setErrors,
  } = formik;

  return (
    <Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off">
          <FormControl marginBottom={2}>
            <FormLabel htmlFor="sitelink_text">Site Text</FormLabel>
            <div className="input-box">
              <InputControl
                id="sitelink_text"
                name="sitelink_text"
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onBlur={handleBlur}
              />
            </div>
          </FormControl>
          <FormControl marginBottom={2}>
            <FormLabel htmlFor="sitelink_description_1">
              Description 1
            </FormLabel>
            <div className="input-box">
              <InputControl
                id="sitelink_description_1"
                name="sitelink_description_1"
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onBlur={handleBlur}
              />
            </div>
          </FormControl>
          <FormControl marginBottom={2}>
            <FormLabel htmlFor="sitelink_description_2">
              Description 2
            </FormLabel>
            <div className="input-box">
              <InputControl
                id="sitelink_description_2"
                name="sitelink_description_2"
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onBlur={handleBlur}
              />
            </div>
          </FormControl>
          <FormControl marginBottom={2}>
            <FormLabel htmlFor="sitelink_final_url">Url</FormLabel>
            <div className="input-box">
              <InputControl
                id="sitelink_final_url"
                name="sitelink_final_url"
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onBlur={handleBlur}
              />
            </div>
          </FormControl>
          <Button type="submit" marginTop={4}>
            Update
          </Button>
          <Button marginTop={4} marginLeft={4} onClick={handleCancel}>
            Cancel
          </Button>
        </Form>
      </FormikProvider>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
      />
    </Box>
  );
}

export default WithAppContext(SitelinkEditForm);
