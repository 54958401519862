// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile {
  width: 500px;
  padding: 20px;
  border: 1px solid black;
  background: #ececec;
}

.mobile-top {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: white;
  padding: 10px;
}

.mobile-top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  font-size: 20px;
  color: #1a0dab;
}

.sitelink {
  padding: 10px;
}

.fade {
  animation-name: fadeIn;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.sitelink ul {
  list-style: none;
  color: #1a0dab;
}

.call {
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  border-top: 1px solid white;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CampaignBriefs/components/AdUpload/Google/css/mobileView.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,mCAAmC;AACrC;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B","sourcesContent":[".mobile {\n  width: 500px;\n  padding: 20px;\n  border: 1px solid black;\n  background: #ececec;\n}\n\n.mobile-top {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  background: white;\n  padding: 10px;\n}\n\n.mobile-top-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.header {\n  font-size: 20px;\n  color: #1a0dab;\n}\n\n.sitelink {\n  padding: 10px;\n}\n\n.fade {\n  animation-name: fadeIn;\n  animation-duration: 5s;\n  animation-iteration-count: infinite;\n}\n\n@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n  30% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.sitelink ul {\n  list-style: none;\n  color: #1a0dab;\n}\n\n.call {\n  display: flex;\n  gap: 10px;\n  padding: 10px;\n  align-items: center;\n  border-top: 1px solid white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
