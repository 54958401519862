import {Box, Button, css, Grid, GridItem, Heading, Image, useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {checkIfFilesAreCorrectType, checkIfFilesAreTooBig} from "../../../../../../../helpers/fileValidation";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {
  demandGenCarouselFileInitialValues,
  facebookCarouselFileInitialValues
} from "../../../../../constant/InitialValues";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import DefaultImageIcon from "../../../../../../../assets/images/image-default-icon.png";
import DefaultVideoIcon from "../../../../../../../assets/images/video-default-icon.png";
import {CloseIcon} from "@chakra-ui/icons";
import WithAppContext from "../../../../../../../helpers/appContext";
import DemandGenCarouselFileUpload from "./DemandGenCarouselFileUpload";
import {settingDemandGenMedia} from "./store";
import {settingDemandGenPreview} from "./store";

const initialValue = {
  carouselCards: []
}

const validationSchema = yup.object().shape({
  carouselCards: yup.array().min(2).max(10).required().label("Carousel cards"),
});

function DemandGenCarouselAdMedia({activeStep, steps, prevStep, nextStep, label, context}) {
  const [formData, setFormData] = useState(initialValue)
  const [cardModalType, seCardModalType] = useState('image');
  const [isShowCardModal, setIsShowCardModal] = useState(false);
  const [initialModalData, setInitialModalData] = useState(demandGenCarouselFileInitialValues);
  const [activeCarouselCardId, setActiveCarouselCardId] = useState(null);

  const {mediaDetails} = useSelector((state) => state.demandGenCarouselAd)
  const [hashArray, setHashArray] = useState([]);
  const {setAppLoading, appLoading} = context;

  console.log("MEd", mediaDetails)

  const dispatch = useDispatch();

  const createCarouselAd = (data) => {
    let CarouselCardsData = [...values.carouselCards]
    if (activeCarouselCardId !== null) {
      CarouselCardsData[activeCarouselCardId] = data
    } else {
      CarouselCardsData.push(data)
    }
    setFieldValue('carouselCards', CarouselCardsData)
    setActiveCarouselCardId(null)
  }

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values => {
      dispatch(settingDemandGenMedia(values))
      if (values && values.carouselCards.length > 0) {
        const files = values.carouselCards.map((imageURLs) => {
          return imageURLs.imageURL.map((file) => {
            if (file && file.filename) {
              return {
                imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${file.files.filename}`,
                filename: file.filename
              }
            } else {
              return {
                imageUrl: URL.createObjectURL(file),
                filename: file.name
              }
            }
          })
        })
        dispatch(settingDemandGenPreview(files));
      }
      nextStep()
    })
  });


  const {
    errors,
    values,
    touched,
    handleSubmit,
    isValid,
    setFieldValue,
    setErrors,
  } = formik;

  useEffect(() => {
    setFieldValue('carouselCards', mediaDetails?.carouselCards ? mediaDetails?.carouselCards : []);
  }, [mediaDetails])

  const onDelete = (id) => {
    let CarouselCardsData = [...values.carouselCards]
    CarouselCardsData.splice(id, 1)
    setFieldValue('carouselCards', CarouselCardsData)
  }

  return (
    <Box className="file-upload-box">
      {
        appLoading && <FullPageLoader/>
      }
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <GridItem w='100%' justifyContent="end" display="flex" alignItems="center"
                    marginBottom={errors.carouselCards ? '8px' : "20px"}>
            <Button variant='outline' onClick={() => {
              seCardModalType('image')
              setIsShowCardModal(true)
            }}>Add Image Card</Button>
          </GridItem>
          <Grid
            w='100%'
            gap={4}
            marginBottom="20px"
            className="fb-upload"
            // templateColumns='repeat(8, 1fr)'
            display="flex"
            flexWrap="wrap"
          >
            {values.carouselCards && values.carouselCards.map((item, index) => {
              let fileData = {};

              fileData = {
                name: item.name,
                description: item.description,
                headline: item.headline,
                landingPageURL: item.landingPageURL,
                link: item.link,
                imageURL: item.imageURL || item.imageFile,
                imageFile: item.image_hash,
              }

              return (
                <Box key={index}
                  //  backgroundColor="rgba(0, 0, 0, 0.39)"
                     borderWidth='1px'
                     width="110px" borderRadius="10px" display="flex" justifyContent="space-evenly"
                     overflow="hidden">
                  <Flex
                    alignItems='center'
                    onClick={() => {
                      setInitialModalData(fileData)
                      seCardModalType('image')
                      setIsShowCardModal(true)
                      setActiveCarouselCardId(index)
                    }}>
                    <Image
                      height="80px"
                      width="80px"
                      objectFit="contain"
                      src={DefaultImageIcon}
                      alt="Default Image Icon"
                      cursor="pointer"
                    />
                  </Flex>
                  <CloseIcon
                    marginTop="4px"
                    padding="2px"
                    bg="rgba(255, 255, 255, 0.45)"
                    cursor="pointer"
                    className="close-icon"
                    size="small"
                    onClick={() => {
                      onDelete(
                        index
                      )
                    }
                    }
                  />
                </Box>
              )
            })}
          </Grid>
          <GridItem w='100%'
                    display="flex"
                    justifyContent="flex-end"
                    marginBottom="20px"
                    color="#e53e3e"
                    fontSize="14px"
                    css={css({
                      float: "right",
                    })}
          >
            {errors.carouselCards && errors.carouselCards}
          </GridItem>
          <DemandGenCarouselFileUpload
            setHashArray={setHashArray}
            hashArray={hashArray}
            type="demandGenCarousel"
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            cardModalType={cardModalType}
            isShowCardModal={isShowCardModal}
            setIsShowCardModal={setIsShowCardModal}
            createCarouselAd={createCarouselAd}
            initialModalData={initialModalData}
            setInitialModalData={setInitialModalData}
            activeCarouselCardId={activeCarouselCardId}
            setActiveCarouselCardId={setActiveCarouselCardId}
          />
          <GridItem
            w="full"
            colSpan={{base: 6, lg: 2}}
          >
          </GridItem>
          <Flex width="100%" justify="flex-end" py={10}>
            <Button
              mr={4}
              onClick={prevStep}
              colorScheme='blue' variant='outline' borderRadius={0} px={10}

            >
              Back
            </Button>
            <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
              Next
            </Button>
          </Flex>
        </Form>

      </FormikProvider>

    </Box>
  )
}

export default WithAppContext(DemandGenCarouselAdMedia)
