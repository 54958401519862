import React from 'react';
import {Box} from "@chakra-ui/react";

function RightArrowIcon({color = "#000000", height = "20px", width = "20px"}) {
  return (
    <Box>
      <svg className="feather feather-chevron-right" fill="none" height={height} stroke={color} strokeLinecap="round"
           strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
        <polyline points="9 18 15 12 9 6"/>
      </svg>
    </Box>
  );
}

export default RightArrowIcon;
