import {
    EMPTY_DATA,
    EMPTY_EXTENSION_DATA,
    EMPTY_SITELINK_DATA,
    SELECTED_DATA,
    SET_EXTENSION_DATA, SET_INITIAL_EXTENSION, SET_INITIAL_MASTERDATA, SET_INITIAL_SITELINK,
    SET_SITELINK_DATA
} from "./type";

const reducer = (state, action) => {

    if (action.type === SET_INITIAL_MASTERDATA) {
        return {...state, initialMasterData: action.payload}
    }

    if (action.type === SET_INITIAL_SITELINK) {
        return {...state, initialSiteLink: action.payload}
    }

    if (action.type === SET_INITIAL_EXTENSION) {
        const data = action.payload
        if (data) {
            const extensionId = data[0].id
            const location = data[0].location
            const callout = data[0].callout
            const call = data[0].call
            const structured_snippet = data[0].structured_snippets === null ? "" : data[0].structured_snippets
            let calloutData = callout.length > 0 ? callout?.split(",") : []
            let callData = call.length > 0 ? call?.split(",") : []
            let locationData = location.length > 0 ? location?.split(",") : []
            let structuredSnippetData = structured_snippet.length > 0 ? structured_snippet?.split(",") : []
            return {
                ...state,
                initialExtension: {calloutData, callData, locationData, structuredSnippetData, extensionId}
            }
        }
    }

    if (action.type === SELECTED_DATA) {
        let gettingSelectedData = state.initialMasterData.filter((item) => item.id === action.payload)
        const {
            headline_1,
            headline_2,
            headline_3,
            headline_4,
            headline_5,
            headline_6,
            headline_7,
            headline_8,
            headline_9,
            headline_10,
            headline_11,
            headline_12,
            headline_13
        } = gettingSelectedData[0]

        const {description_1, description_2, description_3, description_4} = gettingSelectedData[0]

        let description = [description_1, description_2, description_3, description_4].filter((e) => e)

        let headingField = [headline_1,
            headline_2,
            headline_3,
            headline_4,
            headline_5,
            headline_6,
            headline_7,
            headline_8,
            headline_9,
            headline_10,
            headline_11,
            headline_12,
            headline_13
        ]

        let removingTheEmptyString = headingField.filter((e) => e)

        return {...state, headline: removingTheEmptyString, description}
    }

    if (action.type === SET_SITELINK_DATA) {
        let data = state.initialSiteLink.filter(
            (item) => {
                return action.payload.some((array11) => {
                    return array11 === String(item.id)
                })
            });

        return {...state, siteLinks: data}
    }

    if (action.type === SET_EXTENSION_DATA) {
        return {...state, extension: action.payload}
    }

    if (action.type === EMPTY_SITELINK_DATA) {
        return {...state, siteLinks: []}
    }

    if (action.type === EMPTY_EXTENSION_DATA) {
        return {
            ...state, extension: {
                call: [],
                location: [],
                callout: []
            }
        }
    }

    return state
}

export default reducer
