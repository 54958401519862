import React from 'react';
import Display from "./Display";
import {Navigation} from "swiper";
import {Box, Grid, Image, Text} from "@chakra-ui/react";
import {Flex} from "@chakra-ui/layout";
import {Swiper, SwiperSlide} from "swiper/react";
import RightArrowIcon from "../../../../../../../layout/components/RightArrowIcon";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";

function DisplayNativeAd373X160({formDetails, appLoading, ads, index, handleIndex, businessName}) {
  const selectedLogos = (Array.isArray(formDetails?.selectedLogos) && formDetails?.selectedLogos[0]?.imageUrl) ?
    (process.env.REACT_APP_API_URL + "/uploads/" + formDetails?.selectedLogos[0]?.imageUrl) :
    process.env.PUBLIC_URL + "/images/display.png";

  return (
    <Display>
      <Box height="15vh">
        <Flex justifyContent="space-between" m={2} >
          <Text fontSize="14px" fontWeight="600"> {formDetails.headlines[0]} </Text>
        </Flex>
        <Grid templateColumns="repeat(3, 1fr)" height="25%" m={2} gap={2}>
          {(ads && ads.length !== 0) ?
            <Box overflow="hidden" width={"100%"} height={"100%"}>
              <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                      onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                {
                  ads.map((item) => {
                    return (
                      <SwiperSlide>
                        <Image
                          height="100%"
                          width={"100%"}
                          objectFit={"cover"}
                          src={item?.imageUrl}
                          className="previewImage"
                        />
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
          }
          <Box>
            <Flex justifyContent="space-between" my={2} width="100px" overflow="hidden">
              <Text fontSize="14px" fontWeight="600"> {formDetails.descriptions[0]} </Text>
            </Flex>
            <Flex justifyItems="baseline" my={2}>
              <Text color="white" bg="#d7d763" textAlign="center" px={1} mr={1} fontSize="14px">Ad</Text>
              <Text fontSize="14px">{businessName}</Text>
            </Flex>
          </Box>
          <Flex alignItems="end">
            <button style={{color: "#006dff", border: "1px solid #006dff", padding: '0.5vh 2vh', fontSize: '14px', fontWeight: "500"}}>{youtubeShortsTypes.find((i) => i.key === formDetails.call_to_action?.type)?.name}</button>
          </Flex>
        </Grid>
      </Box>
    </Display>
  );
}

export default DisplayNativeAd373X160;
