import { BrowserRouter, Route, Routes as Routers } from "react-router-dom";
import Layout from "../layout";
import Clients from "../pages/Clients";
import ConfigManager from "../pages/ConfigManager";
import ConfigDetails from "../pages/ConfigManager/ConfigDetails/ConfigDetails";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Users from "../pages/Users";
import UserDetails from "../pages/Users/UserDetails";
import NotFound from "../pages/_404";
import CampaignBriefs from "../pages/CampaignBriefs";
import PrivateRoutes from "./PrivateRoutes";
import Settings from "../pages/Settings";
import CreateCampaign from "../pages/CampaignBriefs/components/CreateCampaign";
import FacebookAdUpload from "../pages/CampaignBriefs/components/AdUpload/Facebook/image/FacebookAdUpload";
import FacebookVideoAdUpload from "../pages/CampaignBriefs/components/AdUpload/Facebook/video/FacebookVideoAdUpload";
import ClientDetails from "../pages/Clients/components/ClientDetails";
import DV360AdUpload from "../pages/CampaignBriefs/components/AdUpload/DV360/DV360AdUpload";
import DV360AdUploadAudio from "../pages/CampaignBriefs/components/AdUpload/DV360Audio/DV360AdUploadAudio";
import FacebookCarouselAdUpload from "../pages/CampaignBriefs/components/AdUpload/Facebook/carousel/FacebookCarouselAdUpload";
import DV360AdUploadVideo from "../pages/CampaignBriefs/components/AdUpload/DV360Video/DV360AdUploadVideo";
import DV360AdUploadDisplayVideo from "../pages/CampaignBriefs/components/AdUpload/DV360DisplayVideo/DV360AdUploadDisplayVideo";
import InstagramCarouselAd from "../pages/CampaignBriefs/components/AdUpload/Instagram/carousel/InstagramCarouselAd";
import PinterestImageAdUpload from "../pages/CampaignBriefs/components/AdUpload/Pinterest/PinterestImage/PinterestImageAdUpload";
import PinterestVideoAdUpload from "../pages/CampaignBriefs/components/AdUpload/Pinterest/PinterestVideo/PinterestVideoAdUpload";
import PinterestCarouselAdUpload from "../pages/CampaignBriefs/components/AdUpload/Pinterest/PinterestCarousel/PinterestCarouselAdUpload";
import TikTokVideoAdUpload from "../pages/CampaignBriefs/components/AdUpload/TikTok/TiktokVideo/TikTokVideoAdUpload";
import MasterEdit from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Google/GoogleCsv/MasterEdit";
import Google from "../pages/CampaignBriefs/components/AdUpload/Google/Google";
import FloatingLogin from "../pages/Login/FloatingLogin";
import Profile from "../pages/Profile/Profile";
import NewCreateCampaign from "../pages/CampaignBriefs/components/NewCreateCampaign";
import EditCampaign from "../pages/CampaignBriefs/components/EditCampaign";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import ClientDetailForClient from "../pages/Clients/components/ClientDetailForClient";
import InstagramVideoAd from "../pages/CampaignBriefs/components/AdUpload/Instagram/Video/InstagramVideoAd";
import InstagramImageAd from "../pages/CampaignBriefs/components/AdUpload/Instagram/Image/InstagramImageAd";
import CreateAdCampaign from "../pages/AdCampaigns/CreateAdCampaign";
import SpreadSheetConfig from "../pages/ConfigManager/SpreadSheetConfig";
import Reports from "../pages/LookerStudio/Reports";
import LookerReports from "../pages/LookerStudio/LookerReports";
import SelectAdType from "../pages/CampaignBriefs/components/AdUpload/SelectAdType";
import StepByStepAdUpload from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUpload";
import CampaignCreatives from "../pages/AdCampaigns/CampaignCreatives";
import Feedback from "../pages/Feedback/Feedback";
import MicrosoftMasterEdit
  from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Microsoft/MicrosoftCsv/MasterEdit";
import AiAnalytics from "../pages/AI/AiAnalytics";
import PrivateAnalytics from "../pages/AI/PrivateAnalytics";
import NotificationsHistory from "../pages/History/NotificationsHistory";

const Routes = () => {
  return (
    <BrowserRouter>
      <Routers>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route path="/my-profile" element={<Profile />} />
            <Route path="/" element={<Dashboard />} exact />
            <Route path="/campaign-briefs" element={<CampaignBriefs />} exact />
            <Route
              path="/campaign-briefs/new"
              element={<CreateAdCampaign />}
              exact
            />
            <Route
              path="/campaign-briefs/:id"
              element={<CreateAdCampaign />}
              exact
            />
            {/*<Route*/}
            {/*    path="/campaign-briefs/new"*/}
            {/*    element={<CreateCampaign/>}*/}
            {/*    exact*/}
            {/*/>*/}
            <Route
                path="/campaign-briefs/:id/ad-upload"
                element={<StepByStepAdUpload/>}
                exact
            />
            <Route
                path="/client/:clientId/campaign-briefs/:id/campaign-creatives"
                element={<CampaignCreatives/>}
                exact
            />
            {/* dv360 */}
            <Route
              path="/campaign-brief/:id/ad-upload/new/dv360"
              element={<DV360AdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/dv360/:dv360Id"
              element={<DV360AdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/dv360/:dv360Id"
              element={<DV360AdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/new/dv360-audio"
              element={<DV360AdUploadAudio />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/dv360-audio/:dv360AudioId"
              element={<DV360AdUploadAudio />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/new/dv360-youtube-video"
              element={<DV360AdUploadVideo />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/new/dv360-video"
              element={<DV360AdUploadDisplayVideo />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/dv360-youtube-video/:dv360VideoId"
              element={<DV360AdUploadVideo />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/dv360-video/:dv360DisplayVideoId"
              element={<DV360AdUploadDisplayVideo />}
              exact
            />
            {/* facebook */}
            <Route
              path="/campaign-brief/:id/ad-upload/new/fb"
              element={<FacebookAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/fb/:fbId"
              element={<FacebookAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/new/fb/video"
              element={<FacebookVideoAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/fb/video/:facebookVideoAdId"
              element={<FacebookVideoAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/new/fb/carousel"
              element={<FacebookCarouselAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/fb/carousel/:fbId"
              element={<FacebookCarouselAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/fb/:fbId"
              element={<FacebookAdUpload />}
              exact
            />
            {/* Pinterest */}
            <Route
              path="/campaign-brief/:id/ad-upload/new/pinterest"
              element={<PinterestImageAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/pinterest/:pinterestId"
              element={<PinterestImageAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/new/pinterest-video"
              element={<PinterestVideoAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/pinterest-video/:pinterestId"
              element={<PinterestVideoAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/new/pinterest-carousel"
              element={<PinterestCarouselAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/pinterest-carousel/:pinterestId"
              element={<PinterestCarouselAdUpload />}
              exact
            />
            {/** Tiktok */}
            <Route
              path="/campaign-brief/:id/ad-upload/new/tiktok-video"
              element={<TikTokVideoAdUpload />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/tiktok-video/:tiktokId"
              element={<TikTokVideoAdUpload />}
              exact
            />

            {/*Google*/}

            <Route
              path="/campaign-brief/:id/ad-upload/google"
              element={<Google />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/google/:googleId"
              element={<Google />}
              exact
            />

            {/*<Route*/}
            {/*    path="/campaign-brief/:id/ad-upload/google/:googleId/editMaster"*/}
            {/*    element={<MasterEdit/>}*/}
            {/*    exact*/}
            {/*/>*/}

            <Route
              path="/campaign-brief/:id/ad-upload/google/:googleId/editMaster"
              element={<MasterEdit />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/microsoft/:microsoftId/editMaster"
              element={<MicrosoftMasterEdit />}
              exact
            />
            {/* instagram */}
            <Route
              path="/campaign-brief/:id/ad-upload/instagram/image"
              element={<InstagramImageAd />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/instagram/image/:instagramId"
              element={<InstagramImageAd />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/instagram/video"
              element={<InstagramVideoAd />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/instagram/video/:instagramId"
              element={<InstagramVideoAd />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/instagram/carousel"
              element={<InstagramCarouselAd />}
              exact
            />
            <Route
              path="/campaign-brief/:id/ad-upload/instagram/carousel/:instagramId"
              element={<InstagramCarouselAd />}
              exact
            />
            {/*<Route*/}
            {/*  path="/campaign-briefs/:id"*/}
            {/*  element={<CreateCampaign />}*/}
            {/*  exact*/}
            {/*/>*/}
            <Route
              path="/campaign-briefs/:id/edit-adUpload/:adUploadId"
              element={<EditCampaign />}
              exact
            />
            <Route
              path="/campaign-briefs/:id/ad-upload/new"
              element={<SelectAdType />}
              exact
            />
            {/* Done */}
            <Route path="/users" element={<Users />} exact />
            <Route path="/user" element={<UserDetails />} exact />
            <Route path="/user/:id" element={<UserDetails />} exact />
            <Route path="/config" element={<ConfigManager />} exact />
            <Route
              path="/spreadsheet-config"
              element={<SpreadSheetConfig />}
              exact
            />
            {/* Unable to render */}
            <Route path="/config/:id" element={<ConfigDetails />} exact />
            <Route path="/clients" element={<Clients />} exact />
            <Route path="/client/new" element={<ClientDetails />} exact />
            <Route path="/client/:id" element={<ClientDetails />} exact />
            <Route path="/settings" element={<Settings />} exact />

            <Route
              path="/looker-studio-config"
              element={<LookerReports />}
              exact
            />

            <Route
              path="/feedback-list"
              element={<Feedback />}
              exact
            />

            <Route
              path="/ai-analytics"
              element={<PrivateAnalytics><AiAnalytics /></PrivateAnalytics>}
              exact
            />

            <Route
              path="/notifications-history"
              element={<NotificationsHistory />}
              exact
            />

            <Route path="/reports" element={<Reports />} exact />

            <Route
              path="/company-profile"
              element={<ClientDetailForClient />}
              exact
            />
          </Route>
        </Route>

        <Route path="/login" element={<FloatingLogin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/logins" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routers>
    </BrowserRouter>
  );
};

export default Routes;
