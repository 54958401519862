import {Box, Button, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {checkIfFilesAreCorrectType, checkIfFilesAreTooBig} from "../../../../../../../helpers/fileValidation";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {settingLinkedInVideoHandlePreview, settingLinkedInVideoFormMedia} from "./store";
import {LinkedInVideoAdFileUpload} from "./LinkedInVideoAdFileUpload";
import LinkedinVideoThumbnailFileUpload from "./LinkedinVideoThumbnailFileUpload";


function LinkedInVideoAdMedia({activeStep, steps, prevStep, nextStep, label}) {

    const { adUploadId } = useParams()

    const { mediaDetails } = useSelector((state) => state.linkedInVideoAd)

    console.log('mediaDetails', mediaDetails)

    const schema = yup.object().shape({
        imageURL: yup.array()
            .nullable()
            .required("You need to provide a file")
            .test("fileSize", "The file is too large", checkIfFilesAreTooBig)
            .test(
                "type", "Only the following formats are accepted: .jpeg, .jpg, .gif, .png",
                checkIfFilesAreCorrectType
            ).min(1).max(12).required(),
        fileURL: yup.array().required().label('Video'),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            imageURL: [],
            fileURL: null
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingLinkedInVideoFormMedia(values))
            let files, videoURL;
            console.log('values---------->', values)
            if (values && values.imageURL) {
                files = values.imageURL.map((file) => {
                    if (file && file.imageUrl) {
                        return {
                            imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${file.imageUrl}`,
                            filename: file.filename
                        }
                    } else {
                        return {
                            imageUrl: URL.createObjectURL(file),
                            filename: file.name
                        }
                    }
                })
            }
            if (values && values.fileURL) {
                videoURL = values?.fileURL.map((file) => {
                    if (file && file.imageUrl) {
                        return {
                            videoURL: `${process.env.REACT_APP_API_URL}/uploads${file?.imageUrl}`,
                            filename: file.filename
                        }
                    } else {
                        return {
                            videoURL: URL.createObjectURL(file),
                            filename: file.name
                        }
                    }
                })
            }
            dispatch(settingLinkedInVideoHandlePreview({
                thumbnail: files, video: videoURL
            }));
            console.log('thumbnail---------->', files)
            console.log('video---------->', videoURL)
            nextStep()
        })
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
    } = formik;

    useEffect(() => {
        setFieldValue("imageURL", mediaDetails?.imageURL ? mediaDetails?.imageURL : [])
        setFieldValue("fileURL", mediaDetails?.fileURL ? mediaDetails?.fileURL : null)
    }, [mediaDetails])

    console.log('Values', values)


    return (
        <Box className="file-upload-box">
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <LinkedInVideoAdFileUpload
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        type="linkedin-video"
                        values={values}
                    />
                    <LinkedinVideoThumbnailFileUpload
                        getHashArray={(value) => {
                            setFieldValue('imageURL', value)
                        }}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        type="linkedin-thumbnail"
                        values={values}
                    />
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                            Next
                        </Button>
                    </Flex>
                </Form>

            </FormikProvider>

        </Box>
    )
}

export default LinkedInVideoAdMedia
