import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
    Box,
    Button,
    CircularProgress,
    CircularProgressLabel,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    Flex, Text,
} from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import {
    snapchatAdUploadValueLengths
} from "../../../../../constant/InitialValues";
import * as yup from "yup";
import {
  settingSnapchatVideoFormDetail,
  settingSnapchatVideoFormMedia,
} from "./store";
import TooltipComponent from "../../TooltipComponent";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {Input} from "@chakra-ui/input";
import {CloseIcon} from "@chakra-ui/icons";

const initialValue = {
  adName: "",
  displayName: "",
  description: "",
  channel_name: "",
  channel_icon: null,
};

function SnapchatVideoAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData
}) {
  const { id, adUploadId } = useParams();
  const {mutateAsync} = useUploadImage();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.snapchatVideoAd);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingSnapchatVideoFormDetail({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          description: data?.adUpload?.detail?.description,
          displayName: data?.adUpload?.detail?.displayName,
          link: data?.adUpload?.detail?.link,
          channel_name: data?.adUpload?.detail?.channel_name,
          channelIcon: data?.adUpload?.detail?.channel_icon,
          channel_icon: data?.adUpload?.detail?.channel_icon?.files,
        })
      );
      dispatch(
        settingSnapchatVideoFormMedia({
          fileURL: data?.adUpload?.detail?.audio?.fileInfoList,
        })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    adName: yup
      .string()
      .trim()
      .min(4)
      .max(25)
      .required()
      .matches(
        /^[!@$%^&#()-=+{};:,<.>0-9a-zA-Z\s]+$/i,
        "Emojis are not allowed as ad Name"
      )
      .label("Ad name"),
    displayName: yup
      .string()
      .trim()
      .min(2)
      .max(20)
      .required()
      .matches(
        /^[!@$%^&#()-=+{};:,<.>0-9a-zA-Z\s]+$/i,
        "Emojis are not allowed as display name"
      )
      .label("Display name"),
    description: yup
      .string()
      .trim()
      .min(1)
      .max(34)
      .required()
      .matches(
        /^[!@$%^&()-=+;:,<.>0-9a-zA-Z\s]+$/i,
        "Emojis and Special characters consists {} and # is not allowed in the description"
      )
      .label("Description"),
    link: yup
        .string()
        .trim()
        .required("Landing page url is required.")
        .max(255)
        .matches(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
            "Landing page url is not valid!"
        ).label('Landing page url'),

    channel_name: yup.string()
        .trim()
        .max(30)
        .optional()
        .label("Brand Name"),

    channel_icon: yup
        .mixed()
        .test(
            "fileType",
            "Only image files are allowed",
            (value) => {
              if (!value) return true; // Allow empty value
              const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
              return allowedTypes.includes(value?.type || value?.mimetype);
            }
        )
        .nullable(true)
        .label("Channel Icon"),
  });

    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: schema,
        onSubmit: async (values) => {
            if (values.channel_icon?.name) {
                await mutateAsync(
                    {
                        clientId: clientId,
                        campaignBriefId: id,
                        adFile: [values.channel_icon],
                        type: "youtubeShortsChannelIcon",
                    }, {
                        onSuccess: (data, variables, context) => {
                            dispatch(
                                settingSnapchatVideoFormDetail({
                                    ...data?.adUpload?.detail,
                                    ...values,
                                    channelIcon: data.fileInfoList[0],
                                    channel_icon: null,
                                })
                            );
                        },
                        onError: (err) => {
                            console.log('error OnError', err)
                        }
                    });
            } else {
                dispatch(
                    settingSnapchatVideoFormDetail({
                        ...data?.adUpload?.detail,
                        ...values,
                        channel_icon: null
                    })
                );
            }
            nextStep();
        },
    });

  const { errors, values, touched, handleSubmit, isValid, setFieldValue } =
    formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("displayName", formDetails.displayName || autoFillData?.title || "");
    setFieldValue("description", formDetails.description || autoFillData?.description || "");
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
    setFieldValue("channel_name", formDetails.channel_name || autoFillData?.channel_name || "");
    setFieldValue("channel_icon", formDetails.channelIcon?.files || "");
    setFieldValue("channelIcon", formDetails.channelIcon || "");
  }, [formDetails, autoFillData]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFieldValue('channel_icon', file);
    };
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - Up to 25 characters with spaces",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />
                    <CircularProgress
                      max={snapchatAdUploadValueLengths.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        snapchatAdUploadValueLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        snapchatAdUploadValueLengths?.adName
                          ? snapchatAdUploadValueLengths?.adName -
                            values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="displayName" mb={0}>Display Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="displayName"
                      name="displayName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Display Name - max 20 characters",
                      }}
                      onChange={(e) =>
                        setFieldValue("displayName", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={snapchatAdUploadValueLengths.displayName}
                      value={values?.displayName?.length}
                      color={
                        values?.displayName?.length >
                        snapchatAdUploadValueLengths?.displayName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.displayName?.length >
                        snapchatAdUploadValueLengths?.displayName
                          ? snapchatAdUploadValueLengths?.displayName -
                            values?.displayName?.length
                          : values?.displayName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" mb={0}>Description</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="description"
                      name="description"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Description - Up to 34 characters with spaces",
                      }}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={snapchatAdUploadValueLengths?.description}
                      value={values?.description?.length}
                      color={
                        values?.description?.length >
                        snapchatAdUploadValueLengths?.description
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.description?.length >
                        snapchatAdUploadValueLengths?.description
                          ? snapchatAdUploadValueLengths?.description -
                            values?.description?.length
                          : values?.description?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "url",
                        placeholder: "Landing Page URL",
                      }}
                      onChange={(e) =>
                        setFieldValue("link", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={snapchatAdUploadValueLengths?.link}
                      value={values?.link?.length}
                      color={
                        values?.link?.length >
                        snapchatAdUploadValueLengths?.link
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.link?.length >
                        snapchatAdUploadValueLengths?.link
                          ? snapchatAdUploadValueLengths?.link -
                            values?.link?.length
                          : values?.link?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>

            </GridItem>
              <GridItem mb={2}>
                  <FormControl>
                      <FormLabel htmlFor="channel_name" mb={0}>Brand Name</FormLabel>
                      <div className="input-box">
                          <InputControl
                              id="channel_name"
                              name="channel_name"
                              placeholder=""
                              inputProps={{
                                  variant: "outline",
                                  type: "text",
                                  placeholder: "Brand Name (Optional) - max 90 characters",
                              }}
                              onChange={(e) =>
                                  setFieldValue("channel_name", e.target.value)
                              }
                          />
                          <CircularProgress
                              max={snapchatAdUploadValueLengths?.channel_name}
                              value={values?.channel_name?.length}
                              color={
                                  values?.channel_name?.length >
                                  snapchatAdUploadValueLengths?.channel_name
                                      ? "red.400"
                                      : "green.400"
                              }
                          >
                              <CircularProgressLabel>
                                  {values?.channel_name?.length >
                                  snapchatAdUploadValueLengths?.channel_name
                                      ? snapchatAdUploadValueLengths?.channel_name -
                                      values?.channel_name?.length
                                      : values?.channel_name?.length}
                              </CircularProgressLabel>
                          </CircularProgress>
                      </div>
                  </FormControl>
              </GridItem>
              <GridItem mb={2}>
                  <FormControl>
                      <FormLabel htmlFor="channel_icon" mb={0}>Channel Icon</FormLabel>
                      <div className="input-box">
                          <Box py={2}>
                              <Input
                                  id="channel_icon"
                                  name="channel_icon"
                                  type="file"
                                  accept="image/*"
                                  display="none"
                                  onChange={handleFileChange}
                              />
                              <label htmlFor="channel_icon" style={{ cursor: "pointer" }}>
                                  <Box
                                      as="span"
                                      padding="8px 16px"
                                      border="1px solid #ccc"
                                      borderRadius="4px"
                                      fontSize="0.825rem"
                                      color="#706f7b"
                                      _hover={{ borderColor: "blue.400" }}
                                  >
                                      Upload Channel Icon
                                  </Box>
                              </label>
                          </Box>
                          {values.channel_icon && (
                              <>
                                  <Text >{values.channel_icon.name || values.channel_icon.originalname}</Text>
                                  <Grid justifyContent="center">
                                      <CloseIcon
                                          cursor="pointer"
                                          className="close-icon"
                                          height={'12px'}
                                          width={'12px'}
                                          onClick={() => {
                                              setFieldValue('channel_icon', null);
                                              setFieldValue('channelIcon', null);
                                          }}
                                      />
                                  </Grid>
                              </>
                          )}
                      </div>
                      {errors.channel_icon && <Text color='#E53E3E' fontSize="14px" mt="8px">{errors.channel_icon}</Text>}
                  </FormControl>
              </GridItem>
          </Grid>
          <Box mt={4}>  
          <Flex width="100%" justify="flex-end" py={10}>
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}

export default SnapchatVideoAdDetails;
