import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { Form, FormikProvider, useFormik } from "formik";
import { InputControl, SelectControl } from "formik-chakra-ui";
import _ from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ErrorModal from "../../../../../../components/PopupModal/ErrorModal";
import SuccessModal from "../../../../../../components/PopupModal/SuccessModal";
import {
  AD_UPLOAD_STATUS_COLOR,
  AD_UPLOAD_TYPE,
  INSTAGRAM_IMAGE_HEIGHT,
  INSTAGRAM_IMAGE_WIDTH,
} from "../../../../../../constant";
import instance from "../../../../../../helpers/axios";
import { useUpdateAdUploadStatus } from "../../../../../../hooks/campaign-briefs/useUpdateAdUploadStatus";
import { useUploadImage } from "../../../../../../hooks/campaign-briefs/useUploadImage";
import { useCreateAdPreview } from "../../../../../../hooks/campaign-briefs/useCreateAdPreview";
import WithAppContext from "../../../../../../helpers/appContext";
import InstagramImageFileUpload from "./InstagramImageFileUpload";
import {
  facebookVideoAdValueLengths,
  instagramImageAdInitialValue,
} from "../../../../constant/InitialValues";
import { facebookAccountIds } from "../../../../constant/SelectValues";

const InstagramImageAdDetails = (props) => {
  const {
    data,
    setTabIndex,
    tabIndex,
    handlePreviewData,
    url,
    method,
    campaignId,
    context,
  } = props;
  const { id } = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const navigate = useNavigate();
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [link, setlink] = useState("");
  const { mutateAsync } = useUploadImage();
  const { mutate } = useUpdateAdUploadStatus();
  const { mutateAsync: mutateAsyncPreview } = useCreateAdPreview();
  const { setAppLoading, SuccessToaster, ErrorToaster } = context;

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    primaryText: yup
      .string()
      .trim()
      .min(3)
      .max(125)
      .required()
      .label("Primary text"),
    thumbnailURL: yup.object().required().nullable(),
    type: yup.string().required().label("Type"),
    link: yup
      .string()
      .trim()
      .required("Landing page url is required.")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      )
      .label("Landing page url"),
  });

  const formik = useFormik({
    initialValues: instagramImageAdInitialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        setAppLoading(true);
        let payload = {
          name: values.adName,
          detail: {
            message: values.primaryText,
            link: values.link,
            image_hash: values.thumbnailFile.imageHash,
            thumbnailFile: values.thumbnailURL,
            call_to_action: {
              type: values.type,
              value: {
                link: values.link,
              },
            },
          },
        };
        if (!data?.id) {
          payload = {
            ...payload,
            ad_upload_type: AD_UPLOAD_TYPE.INSTAGRAM_IMAGE,
          };
        }
        await instance({
          method: method,
          url: url,
          withCredentials: false,
          data: payload,
        })
          .then((res) => {
            if (res.status === 200) setSuccessModal(true);
            setAppLoading(false);
          })
          .catch((error) => {
            setlink(error.response.data.message);
            setErrorModal(true);
            setAppLoading(false);
          });
      } catch (error) {
        setAppLoading(false);
      }
    },
  });

  const {
    errors,
    values,
    isValid,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setErrors,
  } = formik;

  useEffect(() => {
    if (data && data.id) {
      const { name, detail } = data;
      setFieldValue("adName", name);
      setFieldValue("primaryText", detail.message);
      setFieldValue("thumbnailURL", detail.thumbnailFile);
      setFieldValue("link", detail.call_to_action.value.link);
      setFieldValue("type", detail.call_to_action.type);
      setFieldValue("thumbnailFile", detail.image_hash);
    }
  }, [data]);
  // show preview
  useEffect(() => {
    if (tabIndex === 1) {
      const getImages = async () => {
        setAppLoading(true);
        const payload = {
          name: values.adName,
          object_story_spec: {
            link_data: {
              message: values.primaryText,
              link: values.link,
              image_hash:
                values.thumbnailFile.imageHash || values.thumbnailFile,
              call_to_action: {
                type: values.type,
                value: {
                  link: values.link,
                },
              },
            },
          },
        };
        const previewsImages = [];
        try {
          await mutateAsyncPreview(
              {
                clientId: clientId,
                campaignBriefId: campaignId,
                data: payload,
                type: "instagram",
              },
              {
                onSuccess: (data) => {
                  if (data && data.previews) {
                    for (const i in Object.keys(data.previews)) {
                      previewsImages.push(Object.values(data.previews)[i]);
                    }
                  } else {
                    ErrorToaster("Please fill all required ad details");
                  }
                  handlePreviewData(previewsImages);
                  setAppLoading(false);
                },
                onError: (error) => {
                  ErrorToaster(error);
                  setAppLoading(false);
                },
              }
          );
        } catch (e) {
          setAppLoading(false);
        }
      };
      getImages();
    }
  }, [tabIndex]);

  const adUploadStatusColor = (item) => {
    return item
      ? AD_UPLOAD_STATUS_COLOR[_.toUpper(item)]
      : AD_UPLOAD_STATUS_COLOR["OTHER"];
  };

  const handleStatus = (status) => {
    try {
      mutate(
          {
            clientId,
            campaignId,
            adUploadId: data?.id,
            status,
          },
          {
            onSuccess: () => {
              navigate(`/campaign-briefs/${campaignId}`, {
                state: { isShowUpload: true },
              });
            },
          }
      );
    } catch (e) {
      setAppLoading(false);
    }
  };

  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    navigate(`/campaign-briefs/${campaignId}`, {
      state: { isShowUpload: true },
    });
  };

  const onDrop = useCallback(async (accFiles) => {
    accFiles.map((file) => {
      const img = new Image();
      img.onload = async () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        if (
          width >= INSTAGRAM_IMAGE_WIDTH &&
          height >= INSTAGRAM_IMAGE_HEIGHT
        ) {
          await mutateAsync(
            {
              clientId: clientId,
              campaignBriefId: id,
              adFile: accFiles,
              type: "fbAdImages",
            },
            {
              onSuccess: (data) => {
                setFieldValue("thumbnailURL", data.file);
                setFieldValue("thumbnailFile", data);
                setAppLoading(false);
                SuccessToaster("Image Upload Successfully.");
              },
              onError: () => {
                setAppLoading(false);
              },
            }
          );
        } else {
          ErrorToaster("1080 X 1080 pixel getter then height and width");
        }
      };
      img.src = URL.createObjectURL(file);
    });
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={4}
        mb={10}
      >
        <Heading color={"gray"} fontSize="xl">
          Current status:
          <span style={{ marginLeft: "10px" }}>
            <Icon
              viewBox="0 0 200 200"
              mr={2}
              color={adUploadStatusColor(data && data.status)}
            >
              <path
                fill="currentColor"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
            {(data && data.status) || "Draft"}
          </span>
        </Heading>
        {data && _.toUpper(data.status) === "CREATED" && (
          <Box display="flex">
            <Button
              size="sm"
              mr={2}
              colorScheme="green"
              backgroundColor="green.400"
              borderRadius={4}
              disabled={!clientId || !campaignId || !data?.id}
              onClick={() => handleStatus("Approved")}
            >
              Approve
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              backgroundColor="red.400"
              borderRadius={4}
              disabled={!clientId || !campaignId || !data?.id}
              onClick={() => handleStatus("Rejected")}
            >
              Reject
            </Button>
          </Box>
        )}
      </Box>
      <Grid className="fb-upload-detail-form">
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Grid
              templateColumns="repeat(6, 1fr)"
              gap={4}
              className="fb-upload"
            >
              <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="adName">
                      Ad Name - max 512 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="adName"
                        name="adName"
                        placeholder=""
                        inputProps={{
                          variant: "outline",
                          type: "text",
                        }}
                        onBlur={handleBlur}
                      />
                      <CircularProgress
                        max={facebookVideoAdValueLengths.adName}
                        value={values.adName.length}
                        color={
                          values.adName.length >
                          facebookVideoAdValueLengths.adName
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values.adName.length >
                          facebookVideoAdValueLengths.adName
                            ? facebookVideoAdValueLengths.adName -
                              values.adName.length
                            : values.adName.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>

                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="primaryText">
                      Primary Text - max 125 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="primaryText"
                        name="primaryText"
                        placeholder=""
                        inputProps={{
                          variant: "outline",
                          type: "text",
                        }}
                      />
                      <CircularProgress
                        max={facebookVideoAdValueLengths.primaryText}
                        value={values.primaryText.length}
                        color={
                          values.primaryText.length >
                          facebookVideoAdValueLengths.primaryText
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values.primaryText.length >
                          facebookVideoAdValueLengths.primaryText
                            ? facebookVideoAdValueLengths.primaryText -
                              values.primaryText.length
                            : values.primaryText.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="link">
                      Landing Page URL - max 255 characters
                    </FormLabel>
                    <div className="input-box">
                      <InputControl
                        id="link"
                        name="link"
                        placeholder=""
                        inputProps={{
                          variant: "outline",
                          type: "text",
                        }}
                      />
                      <CircularProgress
                        max={facebookVideoAdValueLengths.link}
                        value={values.link.length}
                        color={
                          values.link.length > facebookVideoAdValueLengths.link
                            ? "red.400"
                            : "green.400"
                        }
                      >
                        <CircularProgressLabel>
                          {values.link.length > facebookVideoAdValueLengths.link
                            ? facebookVideoAdValueLengths.link -
                              values.link.length
                            : values.link.length}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel htmlFor="type">Type</FormLabel>
                    <div className="input-box">
                      <SelectControl
                        id="type"
                        name="type"
                        selectProps={{
                          placeholder: "-- Select One --",
                          variant: "outline",
                          border: "2px",
                          borderRadius: 0,
                          borderColor: "gray",
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#757998",
                          marginRight: "100px",
                        }}
                        onChange={(e) => {
                          setFieldValue("type", e.target.value);
                        }}
                      >
                        {facebookAccountIds.map((el) => (
                          <option value={el.key} key={el.key}>
                            {el.name}
                          </option>
                        ))}
                      </SelectControl>
                      <CircularProgress opacity={0}>
                        <CircularProgressLabel>0</CircularProgressLabel>
                      </CircularProgress>
                    </div>
                  </FormControl>
                </GridItem>
                <GridItem
                  css={css({
                    float: "right",
                    marginRight: "68px",
                  })}
                >
                  <Button
                    size="small"
                    css={css({
                      background: "#24a0ed !important",
                      borderRadius: "32px",
                      width: "134px",
                      height: "33px",
                      marginRight: "10px",
                    })}
                    onClick={() => setTabIndex(1)}
                    disabled={!isValid}
                  >
                    Preview
                  </Button>
                  <Button
                    size="small"
                    css={css({
                      background: "#4CAF50 !important",
                      borderRadius: "32px",
                      width: "134px",
                      height: "33px",
                    })}
                    type="submit"
                    disabled={!isValid}
                  >
                    {data?.id ? "Update Ad" : "Submit Ad"}
                  </Button>
                </GridItem>
              </GridItem>
              <GridItem w="full" colSpan={{ base: 6, lg: 2 }}>
                <Box className="file-upload-box" marginTop="20px">
                  <InstagramImageFileUpload
                    type="facebook-video-thumbnail"
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setErrors={setErrors}
                    onDrop={onDrop}
                  />
                </Box>
              </GridItem>
            </Grid>
          </Form>
        </FormikProvider>
      </Grid>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={link}
      />
    </>
  );
};

export default WithAppContext(InstagramImageAdDetails);
