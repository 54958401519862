import {
    Box,
    Button,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading, Icon,
    Image,
    Spacer,
    Text,
} from "@chakra-ui/react";
import React, {useEffect, useRef , useState} from "react";
import {
    PREVIEW_BG_COLOR,
    TEXT_COLOR,
    INDICATOR_BG_COLOR,
} from "../../../../../../../layout/constant/MenuList";
import {ArrowBackIcon, ArrowForwardIcon, RepeatIcon} from "@chakra-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {deleteTheField} from "../../../../../../../store/adUploads/index";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {useCreateAdPreview} from "../../../../../../../hooks/campaign-briefs/useCreateAdPreview";
import {
    deleteAllDynamicFacebookVideoField,
    settingDynamicFacebookVideoPreviewData,
} from "./store";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";
import {fetchImageMetadata} from "../../DemandGen/DemandGenImage/DemandGenImageAdPreview";
import {fetchVideoMetadata} from "../../LinkedIn/LinkedinVideo/LinkedInVideoAdPreview";

const MOBILE_SCREEN_COLOR = '#1f1c1c'

const DynamicFacebookVideoAdPreview = ({

                                    activeStep,
                                    steps,
                                    prevStep,
                                    nextStep,
                                    label,
                                    context,
                                }) => {
    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const [selectedImage, setSelectedImage] = useState(0);
    const {id: campaignId, adUploadId} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {mutateAsync: mutateAsyncPreview} = useCreateAdPreview();
    const {setAppLoading, SuccessToaster, ErrorToaster, appLoading} = context;
    const [iframeLoad, setIframeLoad] = useState(true)
    const {
        access_info: {roles, clients: userClients},
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    const {data, refetch} = useGetAdUpload(clientId, campaignId, adUploadId);

    useEffect(() => {
        if (adUploadId) {
            refetch();
        }
    }, []);

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.dynamicFacebookVideoAd)
    const {creative, selectedSocialMedia} = useSelector((state) => state.adUpload)

    useEffect(() => {
        setAds(handlePreviewData);
    }, [handlePreviewData]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onCloseSuccessModal = () => {
        setSuccessModal(false);

        if (adUploadId) {
            navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
                state: {isShowUpload: true},
            });
            dispatch(deleteAllDynamicFacebookVideoField());
        } else {
            navigate(`/campaign-briefs/${campaignId}/ad-upload`);
            dispatch(deleteAllDynamicFacebookVideoField());
        }
    };

    const [errorSubmit, setErrorSubmit] = useState("");

    async function handleSubmit() {

        const imageInfo = await fetchImageMetadata(mediaDetails.selectedImages);
        console.log("imageInfo ", imageInfo);

        const videoInfo =  await fetchVideoMetadata(mediaDetails.selectedVideos);
        console.log("videoInfo ", videoInfo);

        try {
            setAppLoading(true);
            let payload = {
                name: formDetails.adName,
                description: formDetails.description,
                detail: {
                    isDynamic: true,
                    message: formDetails.primaryText,
                    headline: formDetails.headline,
                    link_description: formDetails.description,
                    selectedImages: imageInfo,
                    selectedVideos: videoInfo,
                    call_to_action: {
                        type: formDetails.facebookAccountId,
                        value: {
                            link: formDetails.landingPageURL,
                        },
                    },
                    creative,
                    channel: selectedSocialMedia
                },
            };
            if (!adUploadId) {
                payload = {
                    ...payload,
                    ad_upload_type: AD_UPLOAD_TYPE.DYNAMIC_FBVIDEO,
                };
            }

            await instance({
                method: method,
                url: url,
                withCredentials: false,
                data: payload,
            })
                .then((res) => {
                    if (res.status === 200) setSuccessModal(true);
                    setAppLoading(false);
                })
                .catch((error) => {
                    setErrorSubmit(error.response.data.message);
                    setErrorModal(true);
                    setAppLoading(false);
                });
        } catch (error) {
            setAppLoading(false);
            setIframeLoad(false)
        }
    }

    const getSlider = () => {
        if (ads?.length) {
            const slides = ads.map((el, ind) => (
                <Text
                    height={1}
                    borderRadius={5}
                    px={4}
                    mx={1}
                    mt={8}
                    key={ind}
                    background={ind === index ? "grey" : "silver"}
                    onClick={() => setIndex(ind)}
                    cursor="pointer"
                />
            ));
            return slides;
        }
    };

    const getImages = async (currentImage, currentVideo, message) => {
        setAppLoading(true);
        const payload = {
            name: formDetails.adName,
            object_story_spec: {
                video_data: {
                    message: message,
                    link_description: formDetails.description,
                    image_url:
                        currentImage.imageUrl ||
                        currentImage,
                    video_id:
                        currentVideo?.video_id || currentVideo,
                    call_to_action: {
                        type: formDetails.facebookAccountId,
                        value: {
                            link: formDetails.landingPageURL,
                        },
                    },
                },
            },
        };

        const previewsImages = [];
        try {
            await mutateAsyncPreview(
                {
                    clientId: clientId,
                    campaignBriefId: campaignId,
                    data: payload,
                    type: "facebook",
                },
                {
                    onSuccess: (data) => {
                        if (data && data.previews) {
                            for (const i in Object.keys(data.previews)) {
                                previewsImages.push(Object.values(data.previews)[i]);
                            }
                        } else {
                            ErrorToaster("Please fill all required ad details");
                        }
                        // handlePreviewData(previewsImages);
                        dispatch(settingDynamicFacebookVideoPreviewData(previewsImages));
                        setAppLoading(false);
                    },
                    onError: (error) => {
                        ErrorToaster(error);
                        setAppLoading(false);
                    },
                }
            );
        } catch (e) {
            setAppLoading(false);
            setIframeLoad(false);
        }
    };

    // show preview
    useEffect(() => {
        getImages(mediaDetails.selectedImages[0], mediaDetails.selectedVideos[0], formDetails.primaryText[0]);
    }, []);

    function handleGetPreviewData(name, index) {
        setSelectedImage(index);
        const currentImage = mediaDetails.selectedImages.find((item) => item.filename === name)
        const currentVideo = mediaDetails.selectedVideos[index]
        getImages(currentImage, currentVideo, formDetails.primaryText[0]).then(r => console.log('Ended', r))
    }

    function display(){
        const randomIndex = Math.floor(Math.random() * formDetails.primaryText.length);
        const message = formDetails.primaryText[randomIndex];
        getImages(mediaDetails.selectedImages[0], mediaDetails.selectedVideos[0], message);
    }

    const frame = useRef(null)

    return (
        <>
            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                    {/*<Heading fontSize="lg" my={4} color={TEXT_COLOR}>*/}
                    {/*    {ads?.[index]?.filename}*/}
                    {/*</Heading>*/}
                    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
                        <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                            <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
                                {ads?.[index]?.formatName || ads?.[index]?.filename}
                            </Heading>
                            <Flex alignItems="center" gap={5}>
                                {mediaDetails.selectedImages.map((imageData, index) => (
                                    <Image
                                        key={index}
                                        boxSize="100px"
                                        objectFit="cover"
                                        src={imageData.imageUrl}
                                        alt={`Image Preview ${index}`}
                                        cursor="pointer"
                                        onClick={() => handleGetPreviewData(imageData.filename, index)}
                                        mb={10}
                                        style={{
                                            padding: '5px', // Add white space around the image
                                            border: selectedImage === index ? "2px solid blue" : "none" // Add border if selected
                                        }}
                                    />
                                ))}
                            </Flex>
                            <Flex alignItems={"center"} justifyContent={"end"}>
                                <Button
                                    onClick={display}
                                    colorScheme="blue"
                                    variant="solid"
                                    borderRadius={0}
                                    px={5}
                                    mb={5}
                                >
                                    <Icon as={RepeatIcon} boxSize={4} mr={0}></Icon>
                                </Button>
                            </Flex>
                            <Box
                                borderWidth="2px"
                                borderColor="gray"
                                p={10}
                                background={PREVIEW_BG_COLOR}
                                position={"relative"}
                            >
                                <Container height="100%">
                                    <Flex
                                        alignItems="center"
                                        flexDirection="row"
                                        justify="center"
                                    >
                                        <Container height="100%" centerContent position="relative" width="auto">
                                            <Box>
                                                <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                                                     height="75px"
                                                     borderRadius="50" pt={3} m={4} alignSelf="center"
                                                     position="absolute" top={'20%'} left="-1"/>
                                                <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                                                     height="75px"
                                                     borderRadius="50" pt={3} m={4} alignSelf="center"
                                                     position="absolute" top={'35%'} left="-1"/>
                                                <Box backgroundColor={MOBILE_SCREEN_COLOR} textAlign='center' width="5px"
                                                     height="75px"
                                                     borderRadius="50" pt={3} m={3} alignSelf="center"
                                                     position="absolute" top={'30%'} right="0"/>
                                                <Box height='650px'
                                                     backgroundColor={MOBILE_SCREEN_COLOR}
                                                     border='1px #e3e8f1 solid'
                                                     borderRadius="25"
                                                     overflow="auto">
                                                    <Flex flexDirection="column">
                                                        <Box backgroundColor='white' textAlign='center' width="75px"
                                                             height="5px" borderRadius="25" pt={3} m={3} alignSelf="center"/>
                                                    </Flex>
                                                    <Box backgroundColor='white' border='1px #e3e8f1 solid'
                                                         alignSelf="center" mx='3'>
                                                        <Box bg='white'>
                                                            <Flex justifyContent="center">
                                                                { (iframeLoad || appLoading) && (
                                                                    <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex={1}>
                                                                        <FullPageLoader/>
                                                                    </Box>
                                                                )}
                                                                {data && data.images ? (
                                                                    <iframe
                                                                        src={`${process.env.REACT_APP_API_URL}/uploads/${ads?.[index]?.imageUrl}`}
                                                                        title={ads?.[index]?.filename}
                                                                        height={600}
                                                                        scrolling="no"
                                                                        ref={frame}
                                                                        onLoad={() => setIframeLoad(false)}
                                                                    />
                                                                ) : (
                                                                    <iframe
                                                                        src={ads?.[index]?.src}
                                                                        title={ads?.[index]?.filename}
                                                                        height={580}
                                                                        scrolling="no"
                                                                        ref={frame}
                                                                        onLoad={() => setIframeLoad(false)}
                                                                    />
                                                                )}
                                                            </Flex>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Container>
                                    </Flex>
                                </Container>
                                <Flex
                                    templateColumns="repeat(12, 1fr)"
                                    justifyContent="space-between"
                                >
                                    <Button
                                        leftIcon={
                                            <ArrowBackIcon color="brand.primary" w={10} h={10}/>
                                        }
                                        py={8}
                                        variant="ghost"
                                        position={"absolute"}
                                        top={"50%"}
                                        style={{transform: "translateY(-50%)"}}
                                        onClick={() => setIndex(index > 1 ? index - 1 : 0)}
                                    />
                                    <Spacer/>
                                    <Box
                                        display={"flex"}
                                        position={"absolute"}
                                        bottom={"-14px"}
                                        left={"50%"}
                                        style={{transform: "translateX(-50%)"}}
                                    >
                                        {getSlider()}
                                    </Box>
                                    <Spacer/>
                                    <Button
                                        leftIcon={
                                            <ArrowForwardIcon color="brand.primary" w={10} h={10}/>
                                        }
                                        py={8}
                                        variant="ghost"
                                        position={"absolute"}
                                        top={"50%"}
                                        right={"0%"}
                                        style={{transform: "translateY(-50%)"}}
                                        onClick={() =>
                                            setIndex(
                                                ads && Object.keys(ads)?.length > index + 1
                                                    ? index + 1
                                                    : index
                                            )
                                        }
                                    />
                                </Flex>
                            </Box>
                        </GridItem>
                    </Grid>
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme="blue"
                            variant="outline"
                            or
                            borderRadius={0}
                            px={10}
                        >
                            Back
                        </Button>
                        <Button
                            mr={4}
                            onClick={handleSubmit}
                            colorScheme="green"
                            variant="solid"
                            borderRadius={0}
                            px={10}
                            isDisabled={iframeLoad || appLoading}
                        >
                            {adUploadId ? "Update" : "Submit"}
                        </Button>
                    </Flex>
                </GridItem>
                <SuccessModal
                    isOpen={isSuccessModalOpen}
                    onClose={() => onCloseSuccessModal()}
                />
                <ErrorModal
                    isOpen={isErrorModalOpen}
                    onClose={() => setErrorModal(false)}
                    description={description}
                />
            </Grid>
            <SuccessModal
                isOpen={isSuccessModalOpen}
                onClose={() => onCloseSuccessModal()}
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setErrorModal(false)}
                description={errorSubmit}
            />
        </>
    );
};

export default WithAppContext(DynamicFacebookVideoAdPreview);
