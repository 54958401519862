import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image as ChakraImage,
  Spacer, Tab, TabList, TabPanel, TabPanels, Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  PREVIEW_BG_COLOR,
  TEXT_COLOR,
  INDICATOR_BG_COLOR,
} from "../../../../../../../layout/constant/MenuList";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { AD_UPLOAD_TYPE } from "../../../../../../../constant";
import { useUploadImage } from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import { useNavigate, useParams } from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import { deleteTheField } from "../../../../../../../store/adUploads/index";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { useRecoilValue } from "recoil";
import { profile } from "../../../../../../../atoms/authAtom";
import Mobile from "../component/Mobile";
import Desktop from "../component/Desktop";
import {fetchImageMetadata} from "../../DemandGen/DemandGenImage/DemandGenImageAdPreview";
import {CreateMultipleCreatives} from "../../SocialDisplay/SocialDisplayImage/SocialDisplayImageAdPreview";

const DV360ImageAdPreview = ({

  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  context,
}) => {
  const [index, setIndex] = useState(0);
  const [ads, setAds] = useState([]);
  const [method, setMethod] = useState();
  const [url, setUrl] = useState();
  const { id: campaignId, adUploadId } = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const { setAppLoading, appLoading } = context;
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [description, setDescription] = useState("");
  const {
    access_info: { roles, clients: userClients },
  } = useRecoilValue(profile);
  const [isClient, setIsClient] = useState(false);
  const { allCampaignIds } = useSelector((state) => state.adUpload);

  useEffect(() => {
    setIsClient(roles.includes("Client"));
  }, [roles]);

  const { data, refetch } = useGetAdUpload(clientId, campaignId, adUploadId);

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  useEffect(() => {
    if (adUploadId) {
      setMethod("PUT");
      setUrl(
        `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
      );
    } else {
      setMethod("POST");
      setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
    }
  }, [adUploadId, clientId]);

  const { handlePreviewData, formDetails, mediaDetails ,creative,selectedSocialMedia} = useSelector((state) => state.adUpload)
;
  const { fileInfoList } = formDetails;

  useEffect(() => {
    setAds(handlePreviewData);
  }, [handlePreviewData]);

  const { mutateAsync } = useUploadImage();

  const onSubmitImages = async (multiFiles) => {
    let payload = [];
    let otherFile = [];
    let files = [];

    multiFiles.forEach((file) => {
      if (file?.fieldname) {
        otherFile = [...fileInfoList];
      } else {
        payload.push(file);
      }
    });

    if (payload && payload.length) {
      await mutateAsync(
        {
          clientId: clientId,
          campaignBriefId: campaignId,
          adFile: payload,
          type: AD_UPLOAD_TYPE.DV360,
        },
        {
          onSuccess: (data, variables, context) => {
            payload = data.fileInfoList;
            setAppLoading(false);
          },
          onError: () => {
            setAppLoading(false);
          },
        }
      );
      files = [...otherFile, ...payload];
    } else {
      files = [...otherFile];
    }
    return files;
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCloseSuccessModal = () => {
    setSuccessModal(false);

    if (adUploadId) {
      if(allCampaignIds?.length){
        navigate(`/campaign-briefs`)
      }else {
        navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
          state: { isShowUpload: true },
        });
      }
      dispatch(deleteTheField());
        }
        else {
      // navigate(`/campaign-briefs/${campaignId}`, {
      //     state: {isShowUpload: true}
      // })
      if(allCampaignIds?.length){
        navigate(`/campaign-briefs`)
      }else {
        navigate(`/campaign-briefs/${campaignId}/ad-upload`);
      }
      dispatch(deleteTheField());
    }
  };

  async function handleSubmit() {
    setAppLoading(true);
    const files = await onSubmitImages(mediaDetails.imageURL);
    const mediaIds = files.map((el) => el.imageHash);

    const fileInfoList = await fetchImageMetadata(files);

    console.log("fileInfoList ", fileInfoList);

    let payload = {
      name: formDetails.adName,
      description: formDetails.description,
      detail: {
        name: formDetails.adName,
        creativeType: formDetails.creativeType,
        description: formDetails.description,
        displayName: formDetails.displayName,
        hostingSource: formDetails.hostingSource,
        mediaIds: mediaIds,
                fileInfoList,
                creative, channel: selectedSocialMedia
      },
    };

    if (!adUploadId) {
      payload = {
        ...payload,
        ad_upload_type: AD_UPLOAD_TYPE.DV360,
      };
    }

    if(allCampaignIds?.length){
      CreateMultipleCreatives(allCampaignIds, method, url, clientId, payload, setSuccessModal, setErrorModal, setAppLoading, setDescription, dispatch);
    } else {
      await instance({
        method: method,
        url: url,
        withCredentials: false,
        data: payload,
      })
          .then((res) => {
            if (res.status === 200) {
              setSuccessModal(true);
              setAppLoading(false);
            }
          })
          .catch((error) => {
            setDescription(error?.response?.data?.message);
            setErrorModal(true);
            setAppLoading(false);
          });
    }
  }

  return (
    <>
      <Tabs isFitted variant='enclosed' colorScheme='blue'>
        <TabList>
          <Tab>
            <Box align="center">
              <ChakraImage src={`${process.env.PUBLIC_URL}/images/phone_icon.png`} alt="Phone Icon" height={8} width={8} margin={2}/>
              <h1>Mobile</h1>
            </Box>
          </Tab>
          <Tab>
            <Box align="center">
              <ChakraImage src={`${process.env.PUBLIC_URL}/images/computer_icon.png`} alt="Computer Icon" height={8} width={8} margin={2}/>
              <h1>Desktop</h1>
            </Box>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Mobile
              formDetails={formDetails}
              appLoading={appLoading}
              ads={ads}
              index={index}
              setIndex={setIndex}
            />
          </TabPanel>
          <TabPanel>
            <Desktop
              formDetails={formDetails}
              appLoading={appLoading}
              ads={ads}
              index={index}
              setIndex={setIndex}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Flex width="100%" justify="flex-end" py={10}>
        <Button
          mr={4}
          onClick={prevStep}
          colorScheme="blue"
          variant="outline"
          borderRadius={0}
          px={10}
        >
          Back
        </Button>
        {/*<Button mr={4} colorScheme='blue' variant='solid' borderRadius={0} px={10}>*/}
        {/*    Save Draft*/}
        {/*</Button>*/}
        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
          { adUploadId ? 'Update' : 'Submit'}
        </Button>
      </Flex>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={description}
      />
    </>
  );
};

export default WithAppContext(DV360ImageAdPreview);
