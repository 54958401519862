import { createSlice } from "@reduxjs/toolkit";
import {adUploadSlice} from "../../../../../../../../store/adUploads";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const linkedInImageSlice = createSlice({
    name: AD_UPLOAD_TYPE.LINKEDIN_IMAGE,
    initialState,
    reducers: {
        settingLinkedInFormDetails: (state, action) => {
            state.formDetails = action.payload
        },
        settingLinkedInMedia: (state, action) => {
            state.mediaDetails = action.payload
        },
        settingLinkedInPreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllLinkedInImageFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { deleteAllLinkedInImageFields,settingLinkedInFormDetails,settingLinkedInMedia,settingLinkedInPreview} = linkedInImageSlice.actions;

// The reducer
export const linkedInImageReducer = linkedInImageSlice.reducer;
