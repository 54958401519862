import React, {useState} from 'react';
import {Button, HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, RadioGroup, Radio,
    // Input, FormLabel
} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {FiEye} from "react-icons/fi";
import axios from "../../../../helpers/axios";
import {useSelector} from "react-redux";
import {ComingSoon_TYPES} from "../../../../constant";

function RowButton( { url,adName, adId,handleDeleteModel, data,  context, refetch } ) {
    const { SuccessToaster, ErrorToaster } = context;
    const clientId = useSelector((state) => state.client.clientId);
    const isLaunched = data?.row?.original?.is_launched;
    const [loading, setLoading] = useState(false)
    const adUploadType = data?.row?.original?.ad_upload_type;
    const isComingSoon = ComingSoon_TYPES.includes(adUploadType);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("maximizeConversionValue");
    // const [inputValues, setInputValues] = useState({
    //     cpcBidCeilingMicros: "",
    //     cpcBidFloorMicros: "",
    //     targetRoas: "",
    //     targetCpaMicros: "",
    // });

    function launchAd(adId, inputData) {
        console.log("Launch Ad id Working");
        setLoading(true);

        axios
            .post(`/client/${clientId}/${adId}/launchAd`, {
              ...inputData,
              withCredentials: false,
            })
            .then((res) => {
              if (res.status === 200 ) {
                  refetch();
                  SuccessToaster(res.data?.data?.message);
              }
            })
            .catch((error) => {
                console.log("inside catch ")
              ErrorToaster(
                  error.response?.data?.message || "Something went wrong!"
              );
            })
            .finally(() => {
                setLoading(false);
            });
    }


    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setInputValues((prev) => ({ ...prev, [name]: value }));
    // };

    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };

    // const resetModalData = () => {
    //     setSelectedOption("maximizeConversionValue");
    //     setInputValues({
    //         cpcBidCeilingMicros: "",
    //         cpcBidFloorMicros: "",
    //         targetRoas: "",
    //         targetCpaMicros: "",
    //     });
    // };

    const handleLaunchAd = () => {
        const inputData = {
            // ...inputValues,
            selectedOption,
        };
        launchAd(adId, inputData);
        setIsOpen(false);
        // resetModalData();
    };

    // const handleClose = () => {
    //     setIsOpen(false);
    //     resetModalData();
    // };

    return (
        <div>
            <HStack>
                <Button
                    as={Link}
                    size="sm"
                    variant="outline"
                    aria-label="View details"
                    icon={<FiEye />}
                    to={url}
                    color="#3B82F6"
                    py={5}
                    w={100}
                >
                    View / Edit
                </Button>
                <Button
                    size="sm"
                    variant="outline"
                    aria-label="Delete"
                    icon={<FiEye />}
                    color="#FF8189"
                    py={5}
                    w={100}
                    onClick={() => handleDeleteModel(adName, adId)}
                >
                    Delete
                </Button>
                <Button
                    size="sm"
                    variant="outline"
                    aria-label="Launch Ad"
                    icon={<FiEye />}
                    color="green"
                    py={5}
                    w={100}
                    isLoading={loading}
                    isDisabled={isComingSoon || isLaunched}
                    onClick={() => adUploadType === 'PerformanceMax' ? setIsOpen(true) : launchAd(adId)}
                >
                    {isComingSoon ? "Coming Soon" : isLaunched ? "Launched" : "Launch Ad"}
                </Button>
                <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Choose Bidding Strategy</ModalHeader>
                        <ModalBody>
                            <RadioGroup onChange={handleOptionChange} value={selectedOption} mb={5}>
                                <Radio value="maximizeConversionValue">Maximize Conversion Value</Radio>
                                <Radio value="maximizeConversions">Maximize Conversions</Radio>
                            </RadioGroup>

                            {/*{selectedOption === "maximizeConversionValue" && (*/}
                            {/*    <>*/}
                            {/*        <FormLabel htmlFor="cpcBidCeilingMicros" mb={0}>CPC Bid Ceiling Micros</FormLabel>*/}
                            {/*        <Input type="number" placeholder="CPC Bid Ceiling Micros" name="cpcBidCeilingMicros" value={inputValues.cpcBidCeilingMicros} onChange={handleInputChange} mb={3} />*/}

                            {/*        <FormLabel htmlFor="cpcBidFloorMicros" mb={0}>CPC Bid Floor Micros</FormLabel>*/}
                            {/*        <Input type="number" placeholder="CPC Bid Floor Micros" name="cpcBidFloorMicros" value={inputValues.cpcBidFloorMicros} onChange={handleInputChange} mb={3} />*/}

                            {/*        <FormLabel htmlFor="targetRoas" mb={0}>Target ROAS</FormLabel>*/}
                            {/*        <Input type="number" placeholder="Target ROAS" name="targetRoas" value={inputValues.targetRoas} onChange={handleInputChange} mb={3} />*/}
                            {/*    </>*/}
                            {/*)}*/}

                            {/*{selectedOption === "maximizeConversions" && (*/}
                            {/*    <>*/}
                            {/*        <FormLabel htmlFor="cpcBidCeilingMicros" mb={0}>CPC Bid Ceiling Micros</FormLabel>*/}
                            {/*        <Input type="number" placeholder="CPC Bid Ceiling Micros" name="cpcBidCeilingMicros" value={inputValues.cpcBidCeilingMicros} onChange={handleInputChange} mb={3} />*/}

                            {/*        <FormLabel htmlFor="cpcBidFloorMicros" mb={0}>CPC Bid Floor Micros</FormLabel>*/}
                            {/*        <Input type="number" placeholder="CPC Bid Floor Micros" name="cpcBidFloorMicros" value={inputValues.cpcBidFloorMicros} onChange={handleInputChange} mb={3} />*/}

                            {/*        <FormLabel htmlFor="targetCpaMicros" mb={0}>Target CPA Micros</FormLabel>*/}
                            {/*        <Input type="number" placeholder="Target CPA Micros" name="targetCpaMicros" value={inputValues.targetCpaMicros} onChange={handleInputChange} mb={3} />*/}
                            {/*    </>*/}
                            {/*)}*/}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleLaunchAd} isLoading={loading}>Launch Ad</Button>
                            <Button
                                onClick={()=> setIsOpen(false)}
                                // onClick={handleClose}
                                variant="ghost"
                            >Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </HStack>
        </div>
    );
}

export default RowButton;
