import {Box, Button} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {settingPerformanceMaxMedia, settingPerformanceMaxPreview} from "./store";
import PerformanceMaxAdFileUpload from "./PerformanceMaxAdFileUpload";
import {checkIfFilesAreCorrectType} from "../../../../../../helpers/fileValidation";


function PerformanceMaxAdMedia({activeStep, steps, prevStep, nextStep, label}) {
    const { mediaDetails } = useSelector((state) => state.performanceMaxAd)
    const schema = yup.object().shape({
        selectedImages: yup.array()
            .nullable()
            .required("You need to provide a file")
            .test(
                "type", "Only the following formats are accepted: .jpeg, .jpg, .gif, .png",
                checkIfFilesAreCorrectType
            ).min(1, "At least one Image must be selected").max(20, "At max 20 Images can be selected").required(),
        selectedLogos: yup.array()
            .nullable()
            .required("You need to provide a file")
            .test(
                "type", "Only the following formats are accepted: .jpeg, .jpg, .gif, .png",
                checkIfFilesAreCorrectType
            ).min(1, "At least one Logo must be selected").max(5, "At max 5 Logos can be selected").required(),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            selectedImages: [],
            selectedLogos: [],
        },
        validationSchema: schema,
        onSubmit: (values => {
            dispatch(settingPerformanceMaxMedia(values))
            let allFiles = {
                selectedImages: [],
                selectedLogos: [],
            }
            if (values && values.selectedImages) {
                allFiles.selectedImages = values.selectedImages.map((file) => {
                    if (file && file.filename) {
                        return {
                            imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${file.files.filename}`,
                            filename: file.filename
                        }
                    } else {
                        return {
                            imageUrl: URL.createObjectURL(file),
                            filename: file.name
                        }
                    }
                })
            }
            if (values && values.selectedLogos) {
                allFiles.selectedLogos = values.selectedLogos.map((file) => {
                    if (file && file.filename) {
                        return {
                            imageUrl: `${process.env.REACT_APP_API_URL}/uploads/${file.files.filename}`,
                            filename: file.filename
                        }
                    } else {
                        return {
                            imageUrl: URL.createObjectURL(file),
                            filename: file.name
                        }
                    }
                })
            }
            dispatch(settingPerformanceMaxPreview(allFiles));
            nextStep()
        })
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
        setErrors
    } = formik;

    useEffect(() => {
        setFieldValue("selectedImages", mediaDetails?.selectedImages ? mediaDetails?.selectedImages : [])
        setFieldValue("selectedLogos", mediaDetails?.selectedLogos ? mediaDetails?.selectedLogos : [])
    }, [mediaDetails])

    return (
        <Box className="file-upload-box">
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <PerformanceMaxAdFileUpload
                        getHashArray={(value) => {
                            setFieldValue('selectedImages', value)
                        }}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        type="selectedImages"
                        maxLength={20}
                        values={values}
                        setErrors={setErrors}
                    />
                    <PerformanceMaxAdFileUpload
                        getHashArray={(value) => {
                            setFieldValue('selectedLogos', value)
                        }}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        type="selectedLogos"
                        maxLength={5}
                        values={values}
                        setErrors={setErrors}
                    />
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                        <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}>
                            Next
                        </Button>
                    </Flex>
                </Form>

            </FormikProvider>

        </Box>
    )
}

export default PerformanceMaxAdMedia
