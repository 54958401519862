import React, {useEffect, useState} from 'react';
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Heading, Table, TableContainer, Tbody, Td,
    Text, Th, Thead, Tr,
    useToast,
    VStack
} from "@chakra-ui/react";
import {InputControl} from "formik-chakra-ui";
import instance from "../../helpers/axios";
import {useSelector} from "react-redux";
import ScatterPlot from "./components/ScatterPlot";
import PieChart from "./components/PieChart";
import LineChart from "./components/LineChart";
import BarChart from "./components/BarChart";

const initialValue = {
    userQuestion: ""
}

export function getLocalStorageClient() {
    const clients = localStorage.getItem("clients");
    if (clients) {
        const clientData = JSON.parse(localStorage.getItem("clients"));
        return {
            is_ai_enabled: clientData.is_ai_enabled,
            ai_dataset_name: clientData.ai_dataset_name
        };
    }
    return null;
}

const AiAnalytics = () => {
    const [result, setResult] = useState(null);
    const [humanResponse, setHumanResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast()
    const { currentClientDetails } = useSelector((state) => state.currentClientDetail)

    const schema = yup.object().shape({
        userQuestion: yup.string().required().label("User Question"),
    });

    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: schema,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                await instance({
                    method: "POST",
                    url: '/ai/ask',
                    withCredentials: false,
                    data: {...values, datasetName: currentClientDetails.ai_dataset_name}
                }).then((res) => {
                    if (res.status === 200) {
                        if (res.data.error) {
                            toast({
                                status: 'error',
                                variant: 'top-accent',
                                position: 'top-right',
                                description: res.data.error
                            })
                        } else {
                            setResult(res.data.rows);
                            setHumanResponse(res.data.humanResponse);
                        }
                    }

                }).catch((err) => {
                    return err.response
                })
            } catch (error) {
                console.error('Error', error);
                toast({
                    status: 'error',
                    variant: 'top-accent',
                    position: 'top-right',
                    description: 'An error occurred. Please try again.'
                })
            } finally {
                setIsLoading(false);
            }
        },
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        handleBlur,
        setFieldValue,
    } = formik;

    const renderTable = () => {
        if (!result || result.length === 0) return null;

        return (
            <TableContainer>
                <Table size='sm' variant='striped' className="data-table">
                    <Thead>
                        <Tr>
                            {Object.keys(result[0]).map((key) => (
                                <Th key={key}>{key}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {result.map((row, index) => (
                            <Tr key={index}>
                                {Object.values(row).map((value, i) => (
                                    <Td key={i} fontSize="13px">{value}</Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        );
    };

    const formatHumanResponse = (response) => {
        return response.split('\n').map((line, index) => {
            if (line.trim().startsWith('- ')) {
                return <li key={index}>{line.replace('- ', '')}</li>;
            }
            if (line.trim() === '') {
                return <br key={index}/>;
            }
            return <p key={index}>{line}</p>;
        });
    };

    useEffect(() => {
        const data = getLocalStorageClient()
        setFieldValue('datasetName', data.ai_dataset_name)
    }, [])

    return (
        <Box maxW="1300px" mx="auto" py={5}>
            <Heading mb={6} textAlign="center">
                Your AI Data Analyst
            </Heading>
            {(humanResponse || result) && (
                <Box
                    className='fade-in'
                    border="1px solid #dbdbdb"
                    p={4}
                    mt={4}
                    // background={'#e2f2ff'}
                    boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
                    borderRadius="lg"
                    h={'60vh'}
                    overflowY="auto"
                    id="style-3"
                >
                    {humanResponse && (
                        <Box>
                            <Heading size="md" mb={2} color="#333">
                                Kedet AI Response:
                            </Heading>
                            <Text color="#666" fontSize="sm">
                                {formatHumanResponse(humanResponse)}
                            </Text>
                        </Box>
                    )}
                    {result && (
                        <Box mt={6}>
                            <Heading size="md" mb={2} color="#333">
                                Data Table:
                            </Heading>
                            {/* Render your data table, charts, etc. */}

                            {renderTable()}
                            {<BarChart result={result} />}
                            {<LineChart result={result} />}
                            {<PieChart result={result} />}
                            {<ScatterPlot result={result} />}
                        </Box>
                    )}
                </Box>
            )}
            <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                    <VStack spacing={4} align="stretch" mt={4}>
                        <FormControl>
                            <FormLabel htmlFor="userQuestion">
                                Ask your question about the Skibig3:
                            </FormLabel>
                            <InputControl
                                id="userQuestion"
                                name="userQuestion"
                                placeholder="e.g. What are the top 5 products with the highest conversion rate in the last month?"
                            />
                        </FormControl>
                        <Button type="submit" colorScheme="blue" size="md" isLoading={isLoading}>
                            Submit
                        </Button>
                    </VStack>
                </Form>
            </FormikProvider>
        </Box>
    );
};

export default AiAnalytics;
