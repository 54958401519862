import {
    Box, Button,
    Container, Flex,
    Grid,
    GridItem,
    Heading,
    Image, Link, Spacer, Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PREVIEW_BG_COLOR, TEXT_COLOR, INDICATOR_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {deleteTheField} from "../../../../../../../store/adUploads/index"
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {FaAngleDown} from "react-icons/fa";
import {BsThreeDots} from "react-icons/bs";
import {RiUpload2Fill} from "react-icons/ri";
import {GrLink} from "react-icons/gr"
import {settingSnapchatFormDetails, settingSnapchatMedia, settingSnapchatPreview, deleteAllSnapchatImageFields} from "./store";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaAngleRight } from "react-icons/fa6";
import {fetchImageMetadata} from "../../DemandGen/DemandGenImage/DemandGenImageAdPreview";


const SnapchatImageAdPreview = ({ activeStep, steps, prevStep, nextStep, label, context}) => {

    const [index, setIndex] = useState(0);
    const [ads, setAds] = useState([]);
    const [method, setMethod] = useState();
    const [url, setUrl] = useState();
    const { id: campaignId, adUploadId } = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const {setAppLoading, appLoading} = context;
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [description, setDescription] = useState("");
    const {
        access_info: { roles, clients: userClients },
    } = useRecoilValue(profile);
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(roles.includes("Client"));
    }, [roles]);

    const { data, refetch } = useGetAdUpload(clientId, campaignId, adUploadId);

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (adUploadId) {
            setMethod("PUT");
            setUrl(
                `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
            );
        } else {
            setMethod("POST");
            setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
        }
    }, [adUploadId, clientId]);

    const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.snapchatImageAd)
    const {creative,selectedSocialMedia} = useSelector((state)=> state.adUpload)
    const { fileInfoList} = formDetails
    useEffect(() => {
        setAds(handlePreviewData)
    }, [handlePreviewData]);

    const getSlider = () => {
        if (ads?.length) {
            const slides = ads.map((el, ind) => (
                <Text
                    height={1}
                    borderRadius={5}
                    px={4}
                    mx={1}
                    mt={8}
                    key={ind}
                    background={ind === index ? INDICATOR_BG_COLOR : "silver"}
                    onClick={() => setIndex(ind)}
                    cursor="pointer"
                />
            ));
            return slides;
        }
    };

    const {mutateAsync} = useUploadImage();

    const onSubmitImages = async (multiFiles) => {
        let payload = [];
        let otherFile = [];
        let files = [];

        multiFiles.forEach((file) => {
            if (file?.fieldname) {
                if (Array.isArray(fileInfoList)) {
                    otherFile = [...fileInfoList];
                } else {

                    console.error("fileInfoList ");
                }
            } else {
                payload.push(file);
            }
        });


        if(payload && payload.length){
            await mutateAsync(
                {
                    clientId: clientId,
                    campaignBriefId: campaignId,
                    adFile: payload,
                    type: "snapchat"
                },
                {
                    onSuccess: (data, variables, context) => {
                        payload = data.fileInfoList;
                        console.log('fileInfoList', fileInfoList)
                        setAppLoading(false)
                    },
                    onError: (error) => {
                        setAppLoading(false)
                        setDescription(error?.response?.data?.message);
                    }
                }
            );
            files = [...otherFile, ...payload]
        }else{
            files = [...otherFile]
        }
        return files;
    }

    const navigate = useNavigate();
    const dispatch = useDispatch()


    const onCloseSuccessModal = () => {
        setSuccessModal(false)

        if(adUploadId) {
            navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
                state: {isShowUpload: true}
            })
            dispatch(deleteAllSnapchatImageFields())
            dispatch(deleteTheField())
        }
        else {
            navigate(`/campaign-briefs/${campaignId}/ad-upload`)
            dispatch(deleteAllSnapchatImageFields())
            dispatch(deleteTheField())
        }
    }

    async function handleSubmit() {
        setAppLoading(true);
        const files = await onSubmitImages(mediaDetails.imageURL);
        const mediaIds = files.map((el) => el.imageHash);

        const fileInfoList = await fetchImageMetadata(files);
        console.log("fileInfoList ", fileInfoList);

        let payload = {
            name: formDetails.adName,
            description: formDetails.description,
            title: formDetails.title,
            link: formDetails.link,
            detail: {
                name: formDetails.adName,
                description: formDetails.description,
                title: formDetails.title,
                link: formDetails.link,
                channel_name: formDetails.channel_name,
                channel_icon: formDetails.channelIcon,
                mediaIds: mediaIds,
                fileInfoList,
                creative,
                channel: selectedSocialMedia
            },
        };

        if (!adUploadId) {
            payload = {
                ...payload,
                ad_upload_type: AD_UPLOAD_TYPE.SNAPCHAT_IMAGE,
            };
        }


        await instance({
            method: method,
            url: url,
            withCredentials: false,
            data: payload,
        })
            .then((res) => {
                if (res.status === 200) {
                    setSuccessModal(true);
                    setAppLoading(false)
                }
            })
            .catch((error) => {
                setDescription(error.response.data.message);
                setErrorModal(true);
                setAppLoading(false)
            });
    }

    return (
        <>
            <Grid templateColumns="repeat(6, 1fr)" gap={4} className="snapchat-upload">
                {appLoading && <FullPageLoader />}
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                    <Heading fontSize="lg" my={4} color={"#757998"}>
                        {ads?.[index]?.filename}
                    </Heading>
                    <Box
                        borderWidth="2px"
                        borderColor="gray"
                        p={10}
                        background={PREVIEW_BG_COLOR}
                        position={"relative"}
                    >
                          <Container height="100%">
                            <Flex alignItems="center" flexDirection="row" justify="center">

                                <div className="containers2">
                                    <div className="header-container">
                                        <div className="left-section">
                                            <img src={`${formDetails?.channelIcon?.imageUrl ? process.env.REACT_APP_API_URL + "/uploads" + formDetails?.channelIcon?.imageUrl : "/images/display.png"}`}
                                                 alt=""
                                                 className="round-image" />
                                        </div>
                                        <div className="center-section">
                                            <h2>{formDetails?.channel_name ? formDetails.channel_name : "Kedet"}</h2>
                                            <p className="detail">{formDetails?.description}</p>
                                        </div>
                                        <div className="right-section">
                                            <div className="dots-icon"><BsThreeDotsVertical /></div>
                                        </div>
                                    </div>
                                   
                                    <Swiper navigation={true} modules={[Navigation]} style={{ height: "100%", width:"100%"}}>
                                        {ads.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <Image
                                                    minHeight="md"
                                                    src={item?.imageUrl}
                                                    alt={`Ad Image ${index + 1}`}
                                                    className="previewImage"
                                                    width="100%"
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                   
                                    <div className="footer2">
                                        <div className="content-wrapper">
                                            <div className="logo2">
                                                <img src={`${formDetails?.channelIcon?.imageUrl ? process.env.REACT_APP_API_URL + "/uploads" + formDetails?.channelIcon?.imageUrl : "/images/display.png"}`}
                                                     alt="" className="squreimage"/>
                                            </div>
                                            <div className="text-content">
                                            <h2>{formDetails?.channel_name ? formDetails.channel_name : "Kedet"}</h2>
                                            <p>{formDetails?.description}</p>
                                            </div>

                                           <div className="arrowimage">
                                            <FaAngleRight/>
                                           </div>
                                          
                                        </div>

                                       <div className="space">Ad</div>
                                       <div className="">.</div>
                                    </div>

                                </div>
    
                            </Flex>
                        </Container>
                    </Box>
                </GridItem>
                <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            onClick={prevStep}
                            colorScheme='blue' variant='outline' borderRadius={0} px={10}
                        >
                            Back
                        </Button>
                      
                        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
                            { adUploadId ? 'Update' : 'Submit'}
                        </Button>
                    </Flex>
                </GridItem>
            </Grid>
            <SuccessModal isOpen={isSuccessModalOpen} onClose={onCloseSuccessModal}/>
            <ErrorModal isOpen={isErrorModalOpen} onClose={() => setErrorModal(false)} description={description}/>
        </>
    );
}

export default WithAppContext(SnapchatImageAdPreview);
