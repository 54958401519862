import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Heading } from "@chakra-ui/layout";
import {
    displayCreativeTypes,
    facebookCreativeTypes,
    googleCreativeTypes,
    instagramCreativeTypes,
    pinterestCreativeTypes,
    tiktokCreativeTypes,
    metaCreativeTypes,
    dynamicFacebookCreativeTypes,
    dynamicInstagramCreativeTypes,
    youtubeShortsTypes,
    demandGenTypes,
    snapchatCreativeTypes,
    performanceMaxTypes, socialDisplayTypes, microsoftCreativeTypes, audioTypes, linkedinTypes, discoveryTypes,
} from "../../../../../constant";
import {
    Box,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Image,
    Text,
    Select, Switch, Button,
    useDisclosure, FormErrorMessage
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/input";
import AdDetailsForm from "../StepByStepAdUploadComponent/AdDetailsForm";
import { useParams } from "react-router-dom";
import { useGetAdUpload } from "../../../../../hooks/campaign-briefs/useGetAdUpload";
import { titleCase } from "../../../../../helpers/utils";
import axios from "../../../../../helpers/axios";
import {facebookAccountIds} from "../../../constant/SelectValues";
import {youtubeShortsTypes as socialDisplayOptions} from "../../../constant/SelectValues";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function CreativeAiBotDetail({ activeStep, steps, prevStep, nextStep, label , popupDetail , setPopupDetail , adCopyData ,  setAdCopyData ,  setShowAdDetailsForm , handleGenerateAIBotClick }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const clientId = useSelector((state) => state.client.clientId);
    const { id, adUploadId } = useParams();
    const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);
    const { campaignBudget } = useSelector((state) => state.adCampaign);
    const adCampaignData = useSelector((state) => state.adCampaign);
    const { selectedSocialMedia, creative } = useSelector((state) => state.adUpload);
    const initialValue = {
        promotion: creative?.promotion || "",

    };

    const validationSchema = Yup.object().shape({
        url: Yup.string().url('Invalid URL format').required('URL is required'),
        promotion: Yup.string().required('Promotion is required'),
        audienceDemographics: Yup.string().required('Audience Demographics is required'),
        cta: Yup.string().required('Call to Action is required'),
    });


    const generateAdCopy = async (id) => {
        setLoading(true)
        try {
            const res = await axios.post(`/client/${clientId}/campaign-brief/${id}/ad-upload/generate-ad-copy`, {
                popupDetail
            });

            if (res.status === 200) {
                console.log("inside then");
                //console.log("res inside generateAdCopy", res.data);
                setAdCopyData(res.data);
                setShowAdDetailsForm(true)
                setLoading(false)
            }
        } catch (error) {
            console.log("inside catch", error);
            setLoading(false)
        }
    };


    const handleSubmit = async () => {
        await generateAdCopy(id , popupDetail);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('{ ...popupDetail, [name]: value }', { ...popupDetail, [name]: value })
        setPopupDetail({ ...popupDetail, [name]: value });
    };


    return (
        <div>

                <div>
                    <Box>
                        <Grid
                            templateColumns={{
                                base: "1fr",
                                md: "repeat(2, 1fr)",
                                lg: "repeat(4,1fr)",
                            }}
                            gap={6}
                        >

                            <GridItem colSpan={{ base: 2, md: 2 }}>
                            </GridItem>

                        </Grid>
                    </Box>

                    <Box>
                        {['facebook', 'instagram', 'social_display'].includes(selectedSocialMedia) && (
                            <Formik
                                initialValues={popupDetail}
                                validationSchema={validationSchema}
                               onSubmit={handleSubmit}
                                enableReinitialize

                            >
                                {({   handleBlur, errors, touched }) => (
                                    <Form>
                                        <FormControl mb={2} isInvalid={!!errors.url && touched.url}>
                                            <FormLabel htmlFor="url">URL</FormLabel>
                                            <Input

                                                name="url"
                                                placeholder="URL"
                                                value={popupDetail.url}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <FormErrorMessage>{errors.url}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl mb={2} isInvalid={!!errors.promotion && touched.promotion}>
                                            <FormLabel htmlFor="promotion">Promotion</FormLabel>
                                            <Input

                                                name="promotion"
                                                placeholder="Promotion"
                                                value={popupDetail.promotion}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <FormErrorMessage>{errors.promotion}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl mb={2} isInvalid={!!errors.audienceDemographics && touched.audienceDemographics}>
                                            <FormLabel htmlFor="audienceDemographics">Audience Demographics</FormLabel>
                                            <Input

                                                name="audienceDemographics"
                                                placeholder="Audience Demographics"
                                                value={popupDetail.audienceDemographics}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <FormErrorMessage>{errors.audienceDemographics}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl mb={2} isInvalid={!!errors.cta && touched.cta}>
                                            <FormLabel htmlFor="cta">Call to Action</FormLabel>
                                            <Select

                                                name="cta"
                                                value={popupDetail.cta}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <option value="" disabled>-- Select One --</option>
                                                {selectedSocialMedia === 'facebook' &&
                                                facebookAccountIds.map((el) => (
                                                    <option value={el.key} key={el.key}>
                                                        {el.name}
                                                    </option>
                                                ))}
                                                {selectedSocialMedia === 'instagram' &&
                                                facebookAccountIds.map((el) => (
                                                    <option value={el.key} key={el.key}>
                                                        {el.name}
                                                    </option>
                                                ))}
                                                {selectedSocialMedia === 'social_display' &&
                                                socialDisplayOptions.map((el) => (
                                                    <option value={el.key} key={el.key}>
                                                        {el.name}
                                                    </option>
                                                ))}
                                            </Select>
                                            <FormErrorMessage>{errors.cta}</FormErrorMessage>
                                        </FormControl>

                                        <Flex justify="space-between" width="100%" mt="5">
                                        <Button onClick={handleGenerateAIBotClick}  type="submit">Manual Edit</Button>
                                        <Button isLoading={loading}  type="submit">Kedet Ai Bot</Button>
                                        </Flex>
                                    </Form>
                                )}
                            </Formik>
                        )}


                    </Box>

                </div>

        </div>
    );
};

export default CreativeAiBotDetail;
