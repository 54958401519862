import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    handlePreviewData: null,
};

export const youtubeShortsSlice = createSlice({
    name: AD_UPLOAD_TYPE.YOUTUBE_SHORTS_VIDEO,
    initialState,
    reducers: {
        settingYoutubeShortsFormDetail: (state, action) => {
            state.formDetails = action.payload

        },
        settingYoutubeShortsHandlePreview: (state, action) => {
            state.handlePreviewData = action.payload
        },
        deleteAllYoutubeShortsFields: (state, action) => {
            state.formDetails = {};
            state.handlePreviewData = null;
        }
    },
});

export const { deleteAllYoutubeShortsFields, settingYoutubeShortsFormDetail, settingYoutubeShortsHandlePreview } = youtubeShortsSlice.actions;

// The reducer
export const youtubeShortsReducer = youtubeShortsSlice.reducer;
