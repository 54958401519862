import {Box, Button, useToast} from "@chakra-ui/react";
import React, {useCallback, useEffect, useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import calculateAspectRatios from "calculate-aspect-ratio";
import {FB_IMAGE_SIZE, FB_VIDEO_DURATION, FB_VIDEO_SIZE} from "../../../../../../../constant";
import WithAppContext from "../../../../../../../helpers/appContext";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {settingDynamicFacebookBothFormMedia} from "./store";
import {DynamicFacebookBothAdVideoUpload} from "./DynamicFacebookBothAdVideoUpload"
import {DynamicFacebookBothAdThumbnailUpload} from "./DynamicFacebookBothAdThumbnailUpload";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import DynamicFacebookBothAdFileUpload from "./DynamicFacebookBothAdFileUpload";

function DynamicFacebookBothAdMedia({activeStep, steps, prevStep, nextStep, label, context}) {

    const {adUploadId} = useParams()
    const toast = useToast()

    const {id} = useParams();
    const clientId = useSelector((state) => state.client.clientId);
    const navigate = useNavigate();
    const [isSuccessModalOpen, setSuccessModal] = useState(false);
    const [isErrorModalOpen, setErrorModal] = useState(false);
    const [landingPageURL, setLandingPageURL] = useState("");
    const {mutateAsync} = useUploadImage();

    const {mediaDetails} = useSelector((state) => state.dynamicFacebookBothAd)
    const {setAppLoading, SuccessToaster, ErrorToaster, appLoading} = context;

    const schema = yup.object().shape({
        selectedVideos: yup.array().of(
            yup.object()
        ).min(1, 'At least one video must be selected'
        ).max(2, 'Maximum of 2 videos can be selected'),
        selectedImages: yup.array().of(
            yup.object()
        ).min(1, 'At least one image must be selected'
        ).max(12, 'Maximum of 12 images can be selected'),
        selectedThumbnails: yup.array().of(
            yup.object()
        ).min(1, 'At least one thumbnail must be selected'
        ).max(2, 'Maximum of 2 thumbnails can be selected'),
    });

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            selectedVideos: [],
            selectedImages: [],
            selectedThumbnails: [],
        },
        validationSchema: schema,
        onSubmit: (values => {
            console.log('Values On Submit', values)
            dispatch(settingDynamicFacebookBothFormMedia(values))
            nextStep()
        }),
        initialErrors: {
            selectedVideos: true,
            selectedImages: true,
            selectedThumbnails: true,
        }
    });


    const {
        errors,
        values,
        touched,
        handleSubmit,
        isValid,
        setFieldValue,
        setErrors,
    } = formik;

    const onDrop = useCallback(async (accFiles, rejFiles, type) => {
        accFiles.map((file) => {
            let fileType = file.type.split("/").shift();
            if (fileType === "video") {
                const objectURL = URL.createObjectURL(file);
                const mySound = new Audio([objectURL]);

                let video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    return video
                }
                video.src = URL.createObjectURL(file);

                if (file) {
                    mySound.addEventListener("canplaythrough", async () => {
                        const ratio = calculateAspectRatios(video.videoHeight, video.videoWidth);
                        if (!ratio >= parseInt('1:1') && !ratio <= parseInt('5:4')) {
                            ErrorToaster('Please valid aspect ratio.')
                        } else if (mySound.duration > FB_VIDEO_DURATION) {
                            ErrorToaster('Please valid Video Duration: 1 second to 60 minutes.')
                        } else if (file.size > FB_VIDEO_SIZE) {
                            ErrorToaster('Please valid video file size')
                        } else {
                            setAppLoading(true);
                            try {
                                await mutateAsync(
                                    {
                                        clientId: clientId,
                                        campaignBriefId: id,
                                        adFile: accFiles,
                                        type: 'multipleFbAdVideos',
                                    }, {
                                        onSuccess: (data, variables, context) => {
                                            console.log('dataHere Okay 2--->', values.selectedVideos)
                                            console.log('dataHere Okay 3--->', data)
                                            const dataHere = [...values.selectedVideos, ...data];
                                            console.log('dataHere Okay 1--->', dataHere)
                                            if (dataHere.length > 2) {
                                                ErrorToaster('Maximum of 2 videos can be selected')
                                            } else {
                                                setFieldValue('selectedVideos', dataHere);
                                                SuccessToaster('Video Uploaded Successfully.')
                                            }
                                            setAppLoading(false);
                                        },
                                        onError: (err) => {
                                            setAppLoading(false);
                                        }
                                    });
                            } catch (e) {
                                setAppLoading(false);
                            }
                        }
                    });
                }
            } else {
                const img = new Image()
                img.onload = async () => {
                    const {naturalWidth: width, naturalHeight: height} = img
                    if (type === "Thumbnail" && width < 1048 && height < 1048) {
                        ErrorToaster('Please valid height and width.')
                    } else if (type === "Thumbnail" && file.size > FB_IMAGE_SIZE) {
                        ErrorToaster('Please valid file size')
                    } else {
                        setAppLoading(true);
                        try {
                            await mutateAsync(
                                {
                                    clientId: clientId,
                                    campaignBriefId: id,
                                    adFile: accFiles,
                                    type: "multipleFbAdImages",
                                }, {
                                    onSuccess: (data, variables, context) => {
                                        const dataHere = type === "Thumbnail"
                                          ? [...values.selectedThumbnails, ...data]
                                          : [...values.selectedImages, ...data];
                                        if (type === "Thumbnail" && dataHere.length > 2) {
                                            ErrorToaster('Maximum of 2 thumbnails can be selected')
                                        } else if (type === "Image" && dataHere.length > 12) {
                                            ErrorToaster('Maximum of 12 Images can be selected')
                                        } else {
                                            if (type === "Image") {
                                                setFieldValue('selectedImages', dataHere);
                                            } else if (type === "Thumbnail") {
                                                setFieldValue('selectedThumbnails', dataHere);
                                            }
                                            SuccessToaster('Image Upload Successfully.')
                                        }
                                        setAppLoading(false);
                                    },
                                    onError: () => {
                                        setAppLoading(false);
                                    }
                                });
                        } catch (e) {
                            setAppLoading(false);
                        }
                    }
                }
                img.src = URL.createObjectURL(file)
            }
        });
    }, [values]);

    function handleOnClose(index) {
        const udpatedVideos = values.selectedVideos.filter((_, i) => i !== index);
        setFieldValue('selectedVideos', udpatedVideos);
        console.log('udpatedVideos', udpatedVideos)
    }

    useEffect(() => {
        if (values.selectedVideos?.length !== values.selectedThumbnails?.length) {
            if (values.selectedVideos?.length < values.selectedThumbnails?.length) {
                setErrors({"selectedVideos": "Please select one more video."})
            }
            if (values.selectedVideos?.length > values.selectedThumbnails?.length) {
                setErrors({"selectedThumbnails": "Please select one more thumbnail."})
            }
        }
    }, [values])

    useEffect(() => {
        setFieldValue("selectedImages", mediaDetails?.selectedImages || []);
        setFieldValue("selectedVideos", mediaDetails?.selectedVideos || []);
        setFieldValue("selectedThumbnails", mediaDetails?.selectedThumbnails || []);
    }, [mediaDetails])

    return (
        <>
            {
                appLoading && <FullPageLoader/>
            }
            <Box className="file-upload-box">

                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <DynamicFacebookBothAdVideoUpload
                            type="facebook-video"
                            values={values.selectedVideos}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            setErrors={setErrors}
                            onDrop={onDrop}
                            handleOnClose={handleOnClose}
                        />
                        <DynamicFacebookBothAdThumbnailUpload
                            type="facebook-video-thumbnail"
                            values={values.selectedThumbnails}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            setErrors={setErrors}
                            onDrop={onDrop}
                        />
                        <DynamicFacebookBothAdFileUpload
                            type="facebook-image"
                            values={values.selectedImages}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            setErrors={setErrors}
                            onDrop={onDrop}
                        />
                        <Flex width="100%" justify="flex-end" py={10}>
                            <Button
                                mr={4}
                                onClick={prevStep}
                                colorScheme='blue' variant='outline' borderRadius={0} px={10}

                            >
                                Back
                            </Button>
                            <Button mr={4} colorScheme='green' type="submit" variant='solid' borderRadius={0} px={10}
                                    isDisabled={errors.selectedVideos || errors.selectedImages || errors.selectedThumbnails}>
                                Next
                            </Button>
                        </Flex>
                    </Form>

                </FormikProvider>

            </Box>
        </>
    )
}

export default WithAppContext(DynamicFacebookBothAdMedia)
