import React, {useState} from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image, Skeleton,
  SkeletonCircle, SkeletonText,
  Spacer,
  Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR, TEXT_COLOR} from "../../../../../../../../layout/constant/MenuList";
import {useSelector} from "react-redux";
import ReactPlayer from "react-player";

const MOBILE_SCREEN_COLOR = '#1f1c1c';

function LinkedInImageDesktop({formDetails, ads, index, type = "image"}) {
  const [showMore, setShowMore] = useState(false);
  const toggleText = () => {
    setShowMore(!showMore);
  };

  const selectedLogos = formDetails?.channelIcon?.imageUrl ? `${process.env.REACT_APP_API_URL}/uploads/` + formDetails.channelIcon.imageUrl :
    process.env.PUBLIC_URL + "/images/display.png";

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={TEXT_COLOR}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          width="auto"
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%">
            <Flex
              alignItems="center"
              flexDirection="row"
              justify="center"
            >
              <Container  height="100%" centerContent position="relative" width="auto">
                <Box>
                  <Box height='600px' 
                       width={{ base: '100%', md: '800px' }}
                       backgroundColor={MOBILE_SCREEN_COLOR}
                       border='1px #e3e8f1 solid'
                       borderRadius="25">
                    <Box backgroundColor='white' border='1px #e3e8f1 solid'
                         alignSelf="center" m='5' height="94%" overflow="auto" style={{scrollbarWidth: "none"}}>
                      <Box bg='white'>
                        <Flex mx={24} justifyContent="space-between" p={2}>
                          <Image src={`${process.env.PUBLIC_URL}/images/linkedin.png`}
                                 boxSize="26px"
                                 objectFit="cover"
                                 alt="upload-icon"/>
                          <Flex gap={4} justifyContent="center" alignItems="center">
                            <Flex gap={2}>
                              <SkeletonCircle size="6"/>
                              <SkeletonCircle size="6"/>
                              <SkeletonCircle size="6"/>
                              <SkeletonCircle size="6"/>
                              <SkeletonCircle size="6"/>
                              <SkeletonCircle size="6"/>
                            </Flex>
                            <Skeleton height="20px" width="20px" />
                            <Skeleton height="20px" width="20px" />
                          </Flex>
                        </Flex>
                        <Box
                          backgroundColor='white'
                          alignSelf="center"
                          height="94%"
                          m={2}
                          display="grid"
                          gridTemplateColumns="10% 20% 40% 20% 10%"
                        >
                          {/* First empty column */}
                          <Box bg='white'/>

                          {/* Second empty column */}
                          <Box bg='white'>
                            <Box height="50%" border='2px #e3e8f1 solid' borderRadius="10%">
                              <Flex justifyContent="center" flexDirection={"column"} alignItems="center">
                                <Box> <SkeletonCircle mt={5} size="10"/> </Box>
                                <Skeleton height="14px" width="50%" mt={2}/>
                                <SkeletonText mt='5' noOfLines={3} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='5' noOfLines={2} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='5' noOfLines={1} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='3' noOfLines={1} skeletonHeight='1' width="70%"/>
                              </Flex>
                            </Box>
                            <Box height="50%" border='2px #e3e8f1 solid' borderRadius="10%" mt={2}>
                              <Flex justifyContent="center" flexDirection={"column"} alignItems="center">
                                <SkeletonText mt='5' noOfLines={1} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='5' noOfLines={6} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='5' noOfLines={1} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='5' noOfLines={3} skeletonHeight='2' width="70%"/>
                              </Flex>
                            </Box>
                          </Box>

                          {/* Main content column */}
                          <Box bg='white'>
                            <Box backgroundColor='white' border='2px #e3e8f1 solid'
                                 alignSelf="center" mx='3'
                                 borderTopRadius="5%"
                            >
                              <Flex justifyContent="space-between" gap="5" px={3} py={2}>
                                <Box fontSize="small">
                                  <strong>War Room</strong> likes this
                                </Box>
                                <Flex fontSize={'small'} fontWeight="700" color="#0088ff" alignItems="center">
                                  <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Back Button"
                                         height={4}
                                         width={4}
                                  />
                                </Flex>
                              </Flex>
                              <hr/>
                              <Flex justifyContent="space-between" gap="5" px={3} py={2}>
                                <Flex gap={4}>
                                  <Image src={selectedLogos} height={12}
                                         width={12} rounded="full"/>
                                  <Box>
                                    <Text fontSize={'medium'} fontWeight="700">{formDetails.channel_name}</Text>
                                    <Text fontSize={'11px'} color={'gray'}>248 followers</Text>
                                    <Text fontSize={'11px'} color={'gray'}>Promoted</Text>
                                  </Box>
                                </Flex>
                                <Flex fontSize={'medium'} fontWeight="700" color="#0088ff" alignItems="center">
                                  + follow
                                </Flex>
                              </Flex>
                              <Box px={3} color={"gray"} fontSize="12px" fontWeight="500">
                                {showMore ? formDetails.introductory_text : formDetails.introductory_text?.substring(0, 100)}
                                {formDetails.introductory_text?.length > 100 && (
                                  <span onClick={toggleText} style={{
                                    cursor: 'pointer',
                                    color: 'gray',
                                    paddingLeft: "8px",
                                    fontWeight: '700'
                                  }}
                                        onMouseEnter={(e) => {
                                          e.target.style.color = '#0088ff';
                                        }}
                                        onMouseLeave={(e) => {
                                          e.target.style.color = 'gray';
                                        }}
                                  >
                              {showMore ? 'See Less' : '... See More'}
                                  </span>
                                )}
                              </Box>
                              <Box bg='white' pt={2}>
                                <Flex justifyContent="center">
                                  {type === 'image' && ads && ads.length !== 0 && (
                                      <Box>
                                        <Image
                                            src={ads[index]?.imageUrl}
                                            alt={ads[index]?.filename}
                                        />
                                      </Box>
                                  )}
                                  {type === 'video' && ads && ads.length !== 0 && (
                                      <Box objectFit="cover">
                                        <ReactPlayer
                                            light={<Image
                                                src={ads.thumbnail[0].imageUrl}
                                                alt={ads[index]?.filename}
                                            />}
                                            url={ads.video[0].videoURL}
                                            playing={true} loop={true}
                                            height="auto"
                                            width="auto"
                                        />
                                      </Box>
                                  )}
                                </Flex>
                              </Box>
                              <Box px={3} pt={2} fontSize={'medium'} fontWeight="600">{formDetails.headline}</Box>
                              <Box px={3} fontSize={'small'} fontWeight="400"
                                   color={"gray"}>{formDetails.link?.split('/')[formDetails.link?.split('/')?.length - 1]}</Box>
                              <hr style={{margin: "10px 0 0 0"}}/>
                              <Box>
                                <Image
                                  src={`${process.env.PUBLIC_URL}/images/screenshot_for_linkedin_monitor.png`}
                                  alt={ads[index]?.filename}
                                />
                              </Box>
                            </Box>
                          </Box>

                          {/* Fourth empty column */}
                          <Box bg='white'>
                            <Box height="70%" border='2px #e3e8f1 solid' borderRadius="10%" px={4} py={2}>
                              <Flex flexDirection={"column"}>
                                <Skeleton height="14px" width="30%" mt={2}/>
                                <SkeletonText mt='3' noOfLines={2} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='3' noOfLines={2} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='3' noOfLines={2} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='3' noOfLines={2} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='3' noOfLines={2} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='3' noOfLines={2} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='3' noOfLines={2} skeletonHeight='2' width="70%"/>
                                <SkeletonText mt='3' noOfLines={2} skeletonHeight='2' width="70%"/>
                              </Flex>
                            </Box>
                            <Box height="30%" border='2px #e3e8f1 solid' borderRadius="10%" mt={2}>
                              <Flex justifyContent="center" flexDirection={"column"} alignItems="center">
                                <SkeletonText mt='3' noOfLines={1} skeletonHeight='2' width="70%"/>
                                <Flex justifyContent="center" alignItems="center" gap={3}>
                                  <Box> <SkeletonCircle mt={5} size="10"/> </Box>
                                  <Box> <SkeletonCircle mt={5} size="10"/> </Box>
                                </Flex>
                                <SkeletonText mt='3' noOfLines={2} skeletonHeight='2' width="70%"/>
                              </Flex>
                            </Box>
                          </Box>

                          {/* Fifth empty column */}
                          <Box bg='white'/>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box height='20px' width="950px"
                     backgroundColor={MOBILE_SCREEN_COLOR}
                     border='1px #e3e8f1 solid'
                     borderBottomRadius="25"
                     boxShadow="1px 2px 13px gray"
                />
              </Container>
            </Flex>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default LinkedInImageDesktop;
