import React from 'react';
import axios from "../../helpers/axios";
import {useQuery} from "react-query";

export const useGetFeedbacks = () => {
    return useQuery(['feedback'], async () => {
        return axios
            .get(`/feedback`, {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200) {
                    return res.data.data?.feedbacks
                }
            })
            .catch((err) => {
                return err.response
            })
    })
}

