import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import { dv360AdUploadalueLengths } from "../../../../constant/InitialValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import {
  settingAudioFormDetail,
  settingAudioFormMedia,
} from "./store";
import TooltipComponent from "../TooltipComponent";

export const initialValue = {
  adName: "",
  landingPageURL: "",
};

export default function AudioAdDetails({ activeStep, steps, prevStep, nextStep, label, autoFillData }) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.audioAd);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    landingPageURL: yup
      .string()
      .trim()
      .required("Landing page url is required")
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid! for example https://example.com"
      ),
  });

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingAudioFormDetail({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          landingPageURL: data?.adUpload?.detail?.landingPageURL,
          fileInfoListAudio: data?.adUpload?.detail?.audio?.fileInfoList,
          fileInfoListThumbnail:
            data?.adUpload?.detail?.thumbnail?.fileInfoList,
        })
      );
      dispatch(
        settingAudioFormMedia({
          audioFiles: data?.adUpload?.detail?.audio?.fileInfoList,
          audioThumbnail: data?.adUpload?.detail?.thumbnail?.fileInfoList,
        })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        settingAudioFormDetail({
          fileInfoListAudio: data?.adUpload?.detail?.audio?.fileInfoList,
          fileInfoListThumbnail:
            data?.adUpload?.detail?.thumbnail?.fileInfoList,
          ...data?.adUpload?.detail,
          ...values,
        })
      );
      nextStep();
    },
  });

  const { errors, values, touched, handleSubmit, isValid, setFieldValue } =
    formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("landingPageURL", formDetails.landingPageURL || autoFillData?.link || "");
  }, [formDetails, autoFillData]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      value={values.adName}
                      placeholder=""
                      inputprops={{
                        variant: "outline",
                        type: "text",
                      }}
                      inputProps={{
                        placeholder: "Ad Name - max 512 characters",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={dv360AdUploadalueLengths.adName}
                      value={values.adName.length}
                      color={
                        values.adName.length > dv360AdUploadalueLengths.adName
                          ? "red.400"
                          : "blue.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.adName.length > dv360AdUploadalueLengths.adName
                          ? dv360AdUploadalueLengths.adName -
                            values.adName.length
                          : values.adName.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="landingPageURL" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="landingPageURL"
                      name="landingPageURL"
                      value={values.landingPageURL}
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL",
                      }}
                      onChange={(e) =>
                        setFieldValue("landingPageURL", e.target.value)
                      }
                    />
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}
