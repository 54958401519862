import {useSelector} from "react-redux";
import DV360AdDetails from "../DV360/DV360AdDetails";
import {useGetAdUpload} from "../../../../../hooks/campaign-briefs/useGetAdUpload";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import DV360ImageAdDetails from "./DV360/DV360Image/DV360ImageAdDetails";

function EditAdDetailsForm({activeStep, steps, prevStep, nextStep, label}) {

    const {selectedSocialMedia, mediaType} = useSelector((state) => state.adUpload);
    const clientId = useSelector((state) => state.client.clientId);

    return  <DV360ImageAdDetails activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />

}

export default EditAdDetailsForm