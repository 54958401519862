import React from 'react';
import Display from "./Display";
import {Navigation} from "swiper";
import {Box, Image, Text} from "@chakra-ui/react";
import {Flex} from "@chakra-ui/layout";
import {Swiper, SwiperSlide} from "swiper/react";
import RightArrowIcon from "../../../../../../../layout/components/RightArrowIcon";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import {useSelector} from "react-redux";

function DisplayImageAd300x250({formDetails, appLoading, ads, index, handleIndex, businessName}) {
  const {handlePreviewData} = useSelector((state) => state.performanceMaxAd)

  const selectedLogos = (Array.isArray(handlePreviewData?.selectedLogos) && handlePreviewData?.selectedLogos[0]?.imageUrl) ?
      (handlePreviewData?.selectedLogos[0]?.imageUrl) :
      process.env.PUBLIC_URL + "/images/display.png";

  return (
    <Display>
      <Box>
        <Box mt={4} height="45vh">
          {(ads && ads.length !== 0) ?
            <Box overflow="hidden" width={"100%"} height={"100%"}>
              <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                      onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                {
                  ads.map((item) => {
                    return (
                      <SwiperSlide>
                        <Image
                          height="100%"
                          width={"100%"}
                          objectFit={"cover"}
                          src={item?.imageUrl}
                          className="previewImage"
                        />
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
          }
        </Box>
        <Text my={1} mx={3} fontWeight="700" fontSize="20px">{formDetails.headlines[0]}</Text>
        <Flex mx={3} my={3} fontWeight="400" justifyContent="space-between" alignItems="center">
          <Image src={selectedLogos} height={12}
                 width={12} rounded="full"/>
          <Text my={1}>{formDetails.descriptions[0]}</Text>
          <Box bg="#cb0000" borderRadius="100px" p={2} opacity="40%">
            <RightArrowIcon color="white" height="24px" width="24px"/>
          </Box>
        </Flex>
      </Box>
    </Display>
  );
}

export default DisplayImageAd300x250;
