import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {Form, FormikProvider, useFormik} from "formik";
import {
    Box,
    Button,
    CircularProgress,
    CircularProgressLabel,
    css,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
} from "@chakra-ui/react";
import {InputControl, SelectControl, TextareaControl} from "formik-chakra-ui";
import {
    facebookVideoAdValueLengths,
} from "../../../../../constant/InitialValues";
import {
    facebookAccountIds,
} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {settingDynamicInstagramVideoForm, settingDynamicInstagramVideoMedia} from "./store";
import TooltipComponent from "../../TooltipComponent";
import {Input} from "@chakra-ui/input";

const initialValue = {
    adName: "",
    headlines: [""],
    descriptions: [""],
    primaryText: [""],
    type: "",
    link: "",
};

const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    primaryText: yup
        .array()
        .of(
            yup.string().trim().min(3).max(125).required().label("Primary text")
        )
        .min(1, "At least one primary text is required")
        .max(5, "Maximum of 5 primary texts allowed")
        .required()
        .label("Primary Text"),
    headlines: yup
        .array()
        .optional()
        .label("Headlines"),
    descriptions: yup
        .array()
        .optional()
        .label("Descriptions"),
    type: yup.string().required().label("Type"),
    link: yup
        .string()
        .trim()
        .required("Landing page url is required.")
        .label("Landing page url")
        .matches(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
            "Landing page url is not valid!"
        ),
});

function DynamicInstagramVideoAdDetails({
                                            activeStep,
                                            steps,
                                            prevStep,
                                            nextStep,
                                            label,
                                            autoFillData
                                        }) {
    const {id, adUploadId} = useParams();

    const clientId = useSelector((state) => state.client.clientId);
    const {formDetails} = useSelector((state) => state.dynamicInstagramVideoAd);
    const [primaryTextInputs, setPrimaryTextInputs] = useState([""]);
    const [headlineInputs, setHeadlineInputs] = useState([""]);
    const [descriptionInputs, setDescriptionInputs] = useState([""]);

    const {data, refetch} = useGetAdUpload(clientId, id, adUploadId);

    useEffect(() => {
        if (adUploadId) {
            refetch();
        }
    }, [adUploadId]);

    useEffect(() => {
        if (adUploadId) {
            dispatch(
                settingDynamicInstagramVideoForm({
                    ...data?.adUpload?.detail,
                    adName: data?.adUpload?.name,
                    primaryText: data?.adUpload?.detail?.message,
                    descriptions: data?.adUpload?.detail?.descriptions,
                    headlines: data?.adUpload?.detail?.headlines,
                    link: data?.adUpload?.detail?.link,
                    type: data?.adUpload?.detail?.call_to_action?.type,
                })
            );
            dispatch(
                settingDynamicInstagramVideoMedia({
                    selectedVideos: data?.adUpload?.detail?.selectedVideos,
                    selectedImages: data?.adUpload?.detail?.selectedImages,
                })
            );
            // dispatch(settingEditMediaType(data?.adUpload?.ad_upload_type));
        } else {
            dispatch(
                settingDynamicInstagramVideoMedia({
                    selectedVideos: [],
                    selectedImages: [],
                })
            );
        }
    }, [data]);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: schema,
        onSubmit: (values) => {
            dispatch(
                settingDynamicInstagramVideoForm({...data?.adUpload?.detail, ...values})
            );
            nextStep();
        },
    });

    const {errors, values, touched, handleSubmit, isValid, setFieldValue} =
        formik;

    useEffect(() => {
        setFieldValue("adName", formDetails?.adName || autoFillData?.name || "");
        setFieldValue("primaryText", formDetails?.primaryText || autoFillData?.primaryText || [""]);
        setFieldValue("headlines", formDetails.headlines || autoFillData?.headlines || [""]);
        setFieldValue("descriptions", formDetails.descriptions || autoFillData?.descriptions || [""]);
        setFieldValue("type", formDetails?.type || autoFillData?.type || "");
        setFieldValue("link", formDetails?.link || autoFillData?.link || "");
        setPrimaryTextInputs(formDetails.primaryText || [""])
        setDescriptionInputs(formDetails.descriptions || [""])
        setHeadlineInputs(formDetails.headlines || [""])
    }, [formDetails, autoFillData]);

    const handlePrimaryTextChange = (e, index) => {
        const newInputs = [...primaryTextInputs];
        newInputs[index] = e.target.value;
        setPrimaryTextInputs(newInputs);
    };

    const handleAddPrimaryTextInput = () => {
        if (primaryTextInputs.length < 5) {
            setPrimaryTextInputs([...primaryTextInputs, ""]);
        }
    };

    const handleRemovePrimaryTextInput = (indexToRemove) => {
        const updatedInputs = primaryTextInputs.filter((_, index) => index !== indexToRemove);
        setPrimaryTextInputs(updatedInputs);
    };

    const handleHeadlineChange = (e, index) => {
        const newInputs = [...headlineInputs];
        newInputs[index] = e.target.value;
        setHeadlineInputs(newInputs);
    };

    const handleAddHeadlineInput = () => {
        if (headlineInputs.length < 5) {
            setHeadlineInputs([...headlineInputs, ""]);
        }
    };

    const handleRemoveHeadlineInput = (indexToRemove) => {
        const updatedInputs = headlineInputs.filter((_, index) => index !== indexToRemove);
        setHeadlineInputs(updatedInputs);
    };

    const handleDescriptionChange = (e, index) => {
        const newInputs = [...descriptionInputs];
        newInputs[index] = e.target.value;
        setDescriptionInputs(newInputs);
    };

    const handleAddDescriptionInput = () => {
        if (descriptionInputs.length < 5) {
            setDescriptionInputs([...descriptionInputs, ""]);
        }
    };

    const handleRemoveDescriptionInput = (indexToRemove) => {
        const updatedInputs = descriptionInputs.filter((_, index) => index !== indexToRemove);
        setDescriptionInputs(updatedInputs);
    };

    useEffect(() => {
        setFieldValue("primaryText", primaryTextInputs);
        setFieldValue("headlines", headlineInputs);
        setFieldValue("descriptions", descriptionInputs);
    }, [primaryTextInputs, headlineInputs, descriptionInputs]);

    return (
        <>

            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid gap={4} className="fb-upload">
                        <GridItem w="full" colSpan={{base: 6, lg: 4}}>
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel
                                        htmlFor="adName"
                                        color="gray"
                                        fontSize="sm"
                                        display={"none"}
                                    >
                                        Ad Name - max 512 characters
                                    </FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="adName"
                                            name="adName"
                                            placeholder=""
                                            inputProps={{
                                                variant: "outline",
                                                type: "text",
                                                placeholder: "  Ad Name - max 512 characters",
                                            }}
                                            onChange={(e) => setFieldValue("adName", e.target.value)}
                                        />
                                        <TooltipComponent
                                            text={
                                                "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                                            }
                                        />

                                        <CircularProgress
                                            max={facebookVideoAdValueLengths.adName}
                                            value={values.adName.length}
                                            color={
                                                values.adName.length >
                                                facebookVideoAdValueLengths.adName
                                                    ? "red.400"
                                                    : "green.400"
                                            }
                                        >
                                            <CircularProgressLabel>
                                                {values.adName.length >
                                                facebookVideoAdValueLengths.adName
                                                    ? facebookVideoAdValueLengths.adName -
                                                    values.adName.length
                                                    : values.adName.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                            <GridItem mb={2}>
                                {primaryTextInputs && primaryTextInputs.map((text, index) => {
                                    return (
                                        <FormControl key={index} mb={2}>
                                            <FormLabel htmlFor={`primaryText${index}`} display={"none"}>
                                                Primary Text - max 125 characters
                                            </FormLabel>
                                            <div className="input-box">
                                                <Input
                                                    id={`primaryText${index}`}
                                                    name={`primaryText${index}`}
                                                    inputProps={{
                                                        variant: "outline",
                                                        type: "text",
                                                        placeholder: `Primary Text ${index + 1} - max 125 characters`
                                                    }}
                                                    placeholder={`Primary Text ${index + 1} - max 125 characters`}
                                                    onChange={(e) => handlePrimaryTextChange(e, index)}
                                                    value={text}
                                                />
                                                <CircularProgress
                                                    max={facebookVideoAdValueLengths?.primaryText}
                                                    value={text.length}
                                                    color={
                                                        text.length > facebookVideoAdValueLengths?.primaryText
                                                            ? "red.400"
                                                            : "blue.400"
                                                    }
                                                >
                                                    <CircularProgressLabel>
                                                        {text.length > facebookVideoAdValueLengths?.primaryText
                                                            ? facebookVideoAdValueLengths?.primaryText - text.length
                                                            : text.length}
                                                    </CircularProgressLabel>
                                                </CircularProgress>
                                                {primaryTextInputs.length > 1 && (
                                                    <Button onClick={() => handleRemovePrimaryTextInput(index)} ml={2}>
                                                        -
                                                    </Button>
                                                )}
                                            </div>
                                        </FormControl>
                                    )})}
                                {primaryTextInputs.length < 5 && (
                                    <Button onClick={handleAddPrimaryTextInput} ml={2}>
                                        +
                                    </Button>
                                )}
                            </GridItem>
                            <GridItem mb={2}>
                                {headlineInputs && headlineInputs.map((text, index) => {
                                    return (
                                        <FormControl key={index} mb={2}>
                                            <FormLabel htmlFor={`headlines${index}`} display={"none"}>
                                                Headline - max 125 characters
                                            </FormLabel>
                                            <div className="input-box">
                                                <Input
                                                    id={`headlines${index}`}
                                                    name={`headlines${index}`}
                                                    inputProps={{
                                                        variant: "outline",
                                                        type: "text",
                                                        placeholder: `Headline ${index + 1} (Optional) - max 32 characters`
                                                    }}
                                                    placeholder={`Headline ${index + 1} (Optional) - max 32 characters`}
                                                    onChange={(e) => handleHeadlineChange(e, index)}
                                                    value={text}
                                                />
                                                <CircularProgress
                                                    max={facebookVideoAdValueLengths?.headlines}
                                                    value={text.length}
                                                    color={
                                                        text.length > facebookVideoAdValueLengths?.headlines
                                                            ? "red.400"
                                                            : "blue.400"
                                                    }
                                                >
                                                    <CircularProgressLabel>
                                                        {text.length > facebookVideoAdValueLengths?.headlines
                                                            ? facebookVideoAdValueLengths?.headlines - text.length
                                                            : text.length}
                                                    </CircularProgressLabel>
                                                </CircularProgress>
                                                {headlineInputs.length > 1 && (
                                                    <Button onClick={() => handleRemoveHeadlineInput(index)} ml={2}>
                                                        -
                                                    </Button>
                                                )}
                                            </div>
                                        </FormControl>
                                    )})}
                                {headlineInputs.length < 5 && (
                                    <Button onClick={handleAddHeadlineInput} ml={2}>
                                        +
                                    </Button>
                                )}
                            </GridItem>
                            <GridItem mb={2}>
                                {descriptionInputs && descriptionInputs.map((text, index) => {
                                    return (
                                        <FormControl key={index} mb={2}>
                                            <FormLabel htmlFor={`descriptions${index}`} display={"none"}>
                                                Description - max 125 characters
                                            </FormLabel>
                                            <div className="input-box">
                                                <Input
                                                    id={`descriptions${index}`}
                                                    name={`descriptions${index}`}
                                                    inputProps={{
                                                        variant: "outline",
                                                        type: "text",
                                                        placeholder: `Description ${index + 1} (Optional) - max 125 characters`
                                                    }}
                                                    placeholder={`Description ${index + 1} (Optional) - max 125 characters`}
                                                    onChange={(e) => handleDescriptionChange(e, index)}
                                                    value={text}
                                                />
                                                <CircularProgress
                                                    max={facebookVideoAdValueLengths?.descriptions}
                                                    value={text.length}
                                                    color={
                                                        text.length > facebookVideoAdValueLengths?.descriptions
                                                            ? "red.400"
                                                            : "blue.400"
                                                    }
                                                >
                                                    <CircularProgressLabel>
                                                        {text.length > facebookVideoAdValueLengths?.descriptions
                                                            ? facebookVideoAdValueLengths?.descriptions - text.length
                                                            : text.length}
                                                    </CircularProgressLabel>
                                                </CircularProgress>
                                                {descriptionInputs.length > 1 && (
                                                    <Button onClick={() => handleRemoveDescriptionInput(index)} ml={2}>
                                                        -
                                                    </Button>
                                                )}
                                            </div>
                                        </FormControl>
                                    )})}
                                {descriptionInputs.length < 5 && (
                                    <Button onClick={handleAddDescriptionInput} ml={2}>
                                        +
                                    </Button>
                                )}
                            </GridItem>
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel htmlFor="link" display={"none"}>
                                        Landing Page URL - max 255 characters
                                    </FormLabel>
                                    <div className="input-box">
                                        <InputControl
                                            id="link"
                                            name="link"
                                            placeholder=""
                                            inputProps={{
                                                variant: "outline",
                                                type: "text",
                                                placeholder: "Landing Page URL - max 255 characters",
                                            }}
                                            onChange={(e) => setFieldValue("link", e.target.value)}
                                        />
                                        <CircularProgress
                                            max={facebookVideoAdValueLengths.link}
                                            value={values.link.length}
                                            color={
                                                values.link.length > facebookVideoAdValueLengths.link
                                                    ? "red.400"
                                                    : "blue.400"
                                            }
                                        >
                                            <CircularProgressLabel>
                                                {values.link.length > facebookVideoAdValueLengths.link
                                                    ? facebookVideoAdValueLengths.link -
                                                    values.link.length
                                                    : values.link.length}
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                            <GridItem mb={2}>
                                <FormControl>
                                    <FormLabel htmlFor="type">Call to Action</FormLabel>
                                    <div className="input-box">
                                        <SelectControl
                                            id="type"
                                            name="type"
                                            selectProps={{
                                                placeholder: "-- Select One --",
                                                variant: "outline",
                                                border: "2px",
                                                borderRadius: 0,
                                                borderColor: "gray",
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                                color: "#757998",
                                                marginRight: "100px",
                                            }}
                                            onChange={(e) => {
                                                setFieldValue("type", e.target.value);
                                            }}
                                        >
                                            {facebookAccountIds.map((el) => (
                                                <option value={el.key} key={el.key}>
                                                    {el.name}
                                                </option>
                                            ))}
                                        </SelectControl>
                                        <CircularProgress opacity={0}>
                                            <CircularProgressLabel>0</CircularProgressLabel>
                                        </CircularProgress>
                                    </div>
                                </FormControl>
                            </GridItem>
                        </GridItem>
                    </Grid>
                    <Flex width="100%" justify="flex-end" py={10}>
                        <Button
                            mr={4}
                            colorScheme="green"
                            type="submit"
                            variant="solid"
                            borderRadius={0}
                            px={10}
                        >
                            Next
                        </Button>
                    </Flex>
                </Form>
            </FormikProvider>
        </>
    );
}

export default DynamicInstagramVideoAdDetails;
