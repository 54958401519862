import {
  Box,
  CloseButton,
  Flex,
  Text,
  useColorModeValue,
  VStack,
  Image,
  Select,
} from "@chakra-ui/react";
import {
  MenuList,
  UserMenuList,
  DeveloperMenuList,
  ClientMenuList,
} from "../constant/MenuList";
import NavItem from "./NavItem";
import {For} from "react-haiku";
import {profile} from "../../atoms/authAtom";
import {useGetClientDetailsOnClick} from "../../hooks/clients/useGetClientDetails";
import {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {useDispatch, useSelector} from "react-redux";
import {getClients, updateCurrentClient,} from "../../store/client/clientThunk";
import {useLocation} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {FiActivity} from "react-icons/fi";
import {settingCurrentClientDetail} from "../../pages/AI/store";
import {ROLES} from "../../constant";

export function getLocalStorageClient() {
  const clients = localStorage.getItem("clients");
  if (clients) {
    const clientData = JSON.parse(localStorage.getItem("clients"));
    return {
      id: clientData.id,
      name: clientData.name,
      state: clientData.state,
      is_ai_enabled: clientData.is_ai_enabled,
      ai_dataset_name: clientData.ai_dataset_name
    };
  }
  return null;
}

const Sidebar = ({ onClose, ...rest }) => {
  const [getCurrentClient, setGetCurrentClient] = useState(
    getLocalStorageClient()
  );

  var BORDER_COLOR = useColorModeValue("gray.100", "gray.800");
  var BG_COLOR = useColorModeValue("gray.100", "gray.900");

  const [isAdmin, setIsAdmin] = useState(false);
  const [isDeveloper, setIsDeveloper] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [isClientChanged, setClientChange] = useState(false);
  const [developerMenuList, setDeveloperMenuList] = useState(DeveloperMenuList);
  const [menuList, setMenuList] = useState(MenuList);
  const [menuClientList, setClientMenuList] = useState(ClientMenuList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getCurrentClient) {
      setGetCurrentClient(getCurrentClient?.id);
    }
  }, [getCurrentClient]);

  useEffect(() => {
    const data = getLocalStorageClient()
    dispatch(settingCurrentClientDetail(data))
  }, [])

  const { access_info } = useRecoilValue(profile);

  const userClients =
    access_info?.clients?.length !== 0 ? access_info?.clients : [];
  const roles = access_info?.roles?.length !== 0 ? access_info?.roles : [];

  useEffect(() => {
    if (userClients && userClients?.length !== 0) {
      dispatch(getClients(userClients));
      setSelectedClient(getCurrentClient?.id || userClients[0].id);
      dispatch(updateCurrentClient(getCurrentClient?.id || userClients[0].id));
    }
  }, [userClients]);

  const clients = useSelector((state) => state.client.clients);

  useEffect(() => {
    if (roles && roles.length !== 0) {
      setIsAdmin(roles.includes(ROLES.ADMIN));
      setIsDeveloper(roles.includes(ROLES.DEVELOPER));
      setIsClient(roles.includes(ROLES.CLIENT));
    }
  }, [roles]);

  const { mutate } = useGetClientDetailsOnClick();

  function switchClient(e) {
    if (e.target.value.length) {
      dispatch(updateCurrentClient(e.target.value));
      setSelectedClient(e.target.value);
      mutate(
        {
          id: e.target.value,
        },
        {
          onSuccess: (data, variables, context) => {
            dispatch(settingCurrentClientDetail(data.client));
            data.client && localStorage.setItem("clients", JSON.stringify(data.client));
            setClientChange(!isClientChanged)
          },
        }
      );
    } else {
      localStorage.removeItem("clientId");
      localStorage.removeItem("clients");
    }
  }

  const location = useLocation();

  useEffect(() => {
    const data = getLocalStorageClient();
    if (data) {
      const newMenuItem = {
        id: uuidv4(),
        name: "AI Analytics",
        path: "/ai-analytics",
        icon: FiActivity,
      };
      if (data.is_ai_enabled) {
        if ((isDeveloper && (developerMenuList[2].path !== "/ai-analytics")) ||
            (isAdmin && (menuList[2].path !== "/ai-analytics")) ||
            (isClient && (menuClientList[2].path !== "/ai-analytics"))) {
          isDeveloper && setDeveloperMenuList((prevList) => [
            ...prevList.slice(0, 2),
            newMenuItem,
            ...prevList.slice(2),
          ]);
          isAdmin && setMenuList((prevList) => [
            ...prevList.slice(0, 2),
            newMenuItem,
            ...prevList.slice(2),
          ]);
          isClient && setClientMenuList((prevList) => [
            ...prevList.slice(0, 1),
            newMenuItem,
            ...prevList.slice(1),
          ]);
        }
      } else {
        isDeveloper && setDeveloperMenuList(DeveloperMenuList);
        isAdmin && setMenuList(MenuList);
        isClient && setClientMenuList(ClientMenuList);
      }
    }
  }, [isClientChanged, isDeveloper, isAdmin, isClient]);

  return (
    <>
      {location.pathname === "/reports" ? (
        <Box
          h="full"
          as="aside"
          pos="fixed"
          borderRight="1px"
          paddingTop={6}
          transition="0.6s ease"
          width={{ base: "full", md: 12 }}
          bg={BG_COLOR}
          borderColor={BORDER_COLOR}
          {...rest}
        >
          <Flex
            mt={"1.5"}
            direction="column"
            overflow="hidden"
            height="auto"
            overflowY="auto"
          >
            <VStack align="stretch" fontFamily={"poppins"}>
              {isDeveloper
                ? developerMenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      onClick={onClose}
                    />
                  ))
                : isAdmin
                ? menuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      onClick={onClose}
                    />
                  ))
                : isClient
                ? menuClientList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      onClick={onClose}
                    />
                  ))
                : UserMenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      onClick={onClose}
                    />
                  ))}
            </VStack>
          </Flex>
        </Box>
      ) : (
        <Box
          h="full"
          as="aside"
          pos="fixed"
          borderRight="1px"
          transition="0.6s ease"
          width={{ base: "full", md: 60 }}
          bg={BG_COLOR}
          borderColor={BORDER_COLOR}
          {...rest}
        >
          <Flex
            alignItems="center"
            px="6"
            pt={4}
            mb="5"
            justifyContent={{ base: "space-between", md: "center" }}
          >
            <Text
              fontSize="large"
              fontWeight="semibold"
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              marginBottom={"10px"}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/Kedet Logo.png`}
                width={"120px"}
              />
              <Text
                style={{
                  fontSize: "10px",

                  marginTop: "-14px",
                }}
                color="brand.primary"
              >
                Powered by War Room.
              </Text>
            </Text>
            <CloseButton
              display={{ base: "flex", md: "none" }}
              onClick={onClose}
            />
          </Flex>
          <Flex
            mt={"1.5"}
            direction="column"
            overflow="hidden"
            height="auto"
            overflowY="auto"
          >
            <VStack align="stretch" fontFamily={"poppins"}>
              <Box px={4} mb={6}>
                {clients.length === 1 ? (
                  <Text
                    textAlign="center"
                    py={2}
                    borderRadius={8}
                    fontSize={15}
                    borderColor="brand.primary"
                    color="brand.primary"
                    borderWidth={2}
                    fontWeight={600}
                  >
                    {clients[0].name}
                  </Text>
                ) : (
                  <Select
                    fontWeight={600}
                    fontSize={15}
                    borderColor="brand.primary"
                    color="brand.primary"
                    borderWidth={2}
                    value={selectedClient}
                    onChange={(e) => switchClient(e)}
                  >
                    <For
                      each={clients}
                      render={(client) => (
                        <option value={client.id}>{client.name}</option>
                      )}
                    />
                  </Select>
                )}
              </Box>

              {isDeveloper
                ? developerMenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      title={link.name}
                      onClick={onClose}
                    />
                  ))
                : isAdmin
                ? menuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      title={link.name}
                      onClick={onClose}
                    />
                  ))
                : isClient
                ? menuClientList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      title={link.name}
                      onClick={onClose}
                    />
                  ))
                : UserMenuList.map((link) => (
                    <NavItem
                      key={link.id}
                      path={link.path}
                      icon={link.icon}
                      title={link.name}
                      onClick={onClose}
                    />
                  ))}
            </VStack>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
