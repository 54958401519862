import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {Form, FormikProvider, useFormik} from "formik";
import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import {InputControl, SelectControl} from "formik-chakra-ui";
import {facebookVideoAdValueLengths,} from "../../../../../constant/InitialValues";
import {facebookAccountIds,} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import {Flex} from "@chakra-ui/layout";
import {settingDynamicFacebookVideoFormDetail, settingDynamicFacebookVideoFormMedia,} from "./store";
import TooltipComponent from "../../TooltipComponent";
import {Input} from "@chakra-ui/input";

function DynamicFacebookVideoAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.dynamicFacebookVideoAd);
  const [primaryTextInputs, setPrimaryTextInputs] = useState([""]);
  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  const initialValue = {
    adName: "",
    primaryText: primaryTextInputs,
    description: "",
    headline: "",
    landingPageURL: "",
    facebookAccountId: "",
  };

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
    primaryText: yup
        .array()
        .of(
            yup.string().trim().min(3).max(125).required().label("Primary text")
        )
        .min(1, "At least one primary text is required")
        .max(5, "Maximum of 5 primary texts allowed")
        .required()
        .label("Primary Text"),
    headline: yup.string()
        .trim().min(3).max(32).required().label("Headline"),
    description: yup
      .string()
      .trim()
      .min(3)
      .max(125)
      .required()
      .label("Description"),
    facebookAccountId: yup.string().required().label("Type"),
    landingPageURL: yup
      .string()
      .trim()
      .required("Landing page url is required")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      )
      .label("Landing page url"),
  });

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingDynamicFacebookVideoFormDetail({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.name,
          description: data?.adUpload?.detail?.link_description,
          primaryText: data?.adUpload?.detail?.message,
          headline: data?.adUpload?.detail?.headline,
          landingPageURL: data?.adUpload?.detail?.call_to_action?.value?.link,
          facebookAccountId: data?.adUpload?.detail?.call_to_action?.type,
        })
      );
      dispatch(
        settingDynamicFacebookVideoFormMedia({
          selectedVideos: data?.adUpload?.detail?.selectedVideos,
          selectedImages: data?.adUpload?.detail?.selectedImages,
        })
      );
    } else {
      dispatch(
          settingDynamicFacebookVideoFormMedia({
            selectedVideos: [],
            selectedImages: [],
          })
      );
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        settingDynamicFacebookVideoFormDetail({ ...values, ...data?.adUpload?.detail })
      );
      nextStep();
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isValid,
    handleBlur,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("primaryText", formDetails.primaryText || autoFillData?.primaryText || []);
    setFieldValue("headline", formDetails.headline || autoFillData?.headline || "");
    setFieldValue("description", formDetails.description || autoFillData?.description || "");
    setFieldValue("landingPageURL", formDetails.landingPageURL || autoFillData?.landingPageURL || "");
    setFieldValue("type", formDetails.type || autoFillData?.type || "");
    setFieldValue("facebookAccountId", formDetails.facebookAccountId || autoFillData?.facebookAccountId || "");
    setPrimaryTextInputs(formDetails.primaryText || formDetails.message || [""])
  }, [formDetails, autoFillData]);

  const handlePrimaryTextChange = (e, index) => {
    const newInputs = [...primaryTextInputs];
    newInputs[index] = e.target.value;
    setPrimaryTextInputs(newInputs);
  };

  const handleAddPrimaryTextInput = () => {
    if (primaryTextInputs?.length < 5) {
      setPrimaryTextInputs([...primaryTextInputs, ""]);
    }
  };

  const handleRemovePrimaryTextInput = (indexToRemove) => {
    const updatedInputs = primaryTextInputs.filter((_, index) => index !== indexToRemove);
    setPrimaryTextInputs(updatedInputs);
    setPrimaryTextInputs(updatedInputs)
  };

  useEffect(() => {
    setFieldValue("primaryText", primaryTextInputs);
  }, [primaryTextInputs]);

  return (
    <>

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    display={"none"}
                    htmlFor="adName"
                    color="gray"
                    fontSize="sm"
                  >
                    Ad Name - max 512 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 512 characters",
                      }}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={facebookVideoAdValueLengths?.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        facebookVideoAdValueLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        facebookVideoAdValueLengths?.adName
                          ? facebookVideoAdValueLengths?.adName -
                            values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>

              <GridItem mb={2}>
                {primaryTextInputs && primaryTextInputs.map((text, index) => {
                  return (
                      <FormControl key={index} mb={2}>
                        <FormLabel htmlFor={`primaryText${index}`} display={"none"}>
                          Primary Text - max 125 characters
                        </FormLabel>
                        <div className="input-box">
                          <Input
                              id={`primaryText${index}`}
                              name={`primaryText${index}`}
                              inputProps={{
                                variant: "outline",
                                type: "text",
                                placeholder: `Primary Text ${index + 1} - max 125 characters`
                              }}
                              placeholder={`Primary Text ${index + 1} - max 125 characters`}
                              onChange={(e) => handlePrimaryTextChange(e, index)}
                              value={text}
                          />
                          <CircularProgress
                              max={facebookVideoAdValueLengths?.primaryText}
                              value={text.length}
                              color={
                                text.length > facebookVideoAdValueLengths?.primaryText
                                    ? "red.400"
                                    : "blue.400"
                              }
                          >
                            <CircularProgressLabel>
                              {text.length > facebookVideoAdValueLengths?.primaryText
                                  ? facebookVideoAdValueLengths?.primaryText - text.length
                                  : text.length}
                            </CircularProgressLabel>
                          </CircularProgress>
                          {primaryTextInputs.length > 1 && (
                              <Button onClick={() => handleRemovePrimaryTextInput(index)} ml={2}>
                                -
                              </Button>
                          )}
                        </div>
                      </FormControl>
                  )})}
                {primaryTextInputs.length < 5 && (
                    <Button onClick={handleAddPrimaryTextInput} ml={2}>
                      +
                    </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="headline" display={"none"}>
                    Headline - max 32 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="headline"
                      name="headline"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: " Headline - max 32 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.headline}
                      value={values?.headline?.length}
                      color={
                        values?.headline?.length >
                        facebookVideoAdValueLengths?.headline
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.headline?.length >
                        facebookVideoAdValueLengths?.headline
                          ? facebookVideoAdValueLengths?.headline -
                            values?.headline?.length
                          : values?.headline?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" display={"none"}>
                    Description - max 18 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="description"
                      name="description"
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Description - max 18 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.description}
                      value={values?.description?.length}
                      color={
                        values?.description?.length >
                        facebookVideoAdValueLengths?.description
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.description?.length >
                        facebookVideoAdValueLengths?.description
                          ? facebookVideoAdValueLengths?.description -
                            values?.description?.length
                          : values?.description?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="landingPageURL" display={"none"}>
                    Landing Page URL - max 255 characters
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="landingPageURL"
                      name="landingPageURL"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: " Landing Page URL - max 255 characters",
                      }}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths?.landingPageURL}
                      value={values?.landingPageURL?.length}
                      color={
                        values?.landingPageURL?.length >
                        facebookVideoAdValueLengths?.landingPageURL
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.landingPageURL?.length >
                        facebookVideoAdValueLengths?.landingPageURL
                          ? facebookVideoAdValueLengths?.landingPageURL -
                            values?.landingPageURL?.length
                          : values?.landingPageURL?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="facebookAccountId">
                    Call to Action
                  </FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="facebookAccountId"
                      name="facebookAccountId"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("facebookAccountId", e.target.value);
                      }}
                    >
                      {facebookAccountIds.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default DynamicFacebookVideoAdDetails;
