import { createSlice } from "@reduxjs/toolkit";
import {AD_UPLOAD_TYPE} from "../../../../../../../../constant";

const initialState = {
  formDetails: {},
  mediaDetails: [],
  handlePreviewData: null,
};

export const socialDisplayVideoSlice = createSlice({
  name: AD_UPLOAD_TYPE.SOCIAL_DISPLAY_VIDEO,
  initialState,
  reducers: {
    settingSocialDisplayVideoForm: (state, action) => {
      state.formDetails = action.payload
    },
    settingSocialDisplayVideoMedia: (state, action) => {
      state.mediaDetails = action.payload
    },
    settingSocialDisplayVideoPreview: (state, action) => {
      state.handlePreviewData = action.payload
    },
    deleteAllSocialDisplayVideoField: (state, action) => {
      state.formDetails = {};
      state.mediaDetails = [];
      state.handlePreviewData = null
    }
  },
});

export const { settingSocialDisplayVideoForm, settingSocialDisplayVideoMedia, settingSocialDisplayVideoPreview, deleteAllSocialDisplayVideoField} = socialDisplayVideoSlice.actions;

// The reducer
export const socialDisplayVideoReducer = socialDisplayVideoSlice.reducer;
