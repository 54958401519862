import {CloseIcon, DownloadIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import {
  Box,
  Button, CircularProgress,
  CircularProgressLabel, Flex, FormControl,
  FormLabel, Grid, GridItem, Heading, Image, Input, Progress, Spacer, Text, Tooltip
} from "@chakra-ui/react";
import FileSaver from 'file-saver';
import {InputControl} from "formik-chakra-ui";
import React, {useEffect, useState} from "react";
import {useDropzone} from 'react-dropzone';
import DefaultImageIcon from "../../../../../../../assets/images/default-image-icon.png";
import FileUploadIcon from "../../../../../../../assets/images/upload.svg";
import {BLUE_BUTTON_COLOR, GREY_TEXT_COLOR, TEXT_COLOR} from "../../../../../../../layout/constant/MenuList";
import {fbCarouselAdUploadValueLengths} from "../../../../../constant/InitialValues";
import JPGIcon from "../../../../../../../assets/images/jpg.svg";
import PNGIcon from "../../../../../../../assets/images/png.svg";

export function UploadImageFile({onDrop, errors, touched, values, setFieldValue}) {
  const [fileInfo, setFileInfo] = useState([]);

  const acceptImageFiles = {
    "image/png": [".png"],
    "image/jpeg": [".jpg"],
  };

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: acceptImageFiles,
    maxSize: 30 * 1024 * 1024,
    disabled: values.imageURL && values.imageURL.length === 3,
  });

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    if (values.imageURL && values.imageURL.length > 0) {
      const files = []
      values.imageURL.map((file) => {
        let getFileURL = file && `${process.env.REACT_APP_API_URL}/uploads/${file.filename}`;
        const getFileName = file.originalname;
        files.push({getFileURL, getFileName});
      })
      setFileInfo(files);
    }
  }, [values]);

  console.log("Values", values.imageURL)

  return (
    <>
      <Grid
        border='2px'
        borderColor={(errors.imageURL && touched.imageURL) ? 'red.500' : 'gray.200'}
        padding="20px"
        borderStyle="dashed"
        marginTop="20px"
      >
        <div {...getRootProps()}>
          <Input {...getInputProps()} />
          <Box className="file-upload-content" justifyContent="center" display="flex" flexDirection="column"
               alignItems="center" textAlign="center">
            <Image
              boxSize="100px"
              objectFit="cover"
              src={FileUploadIcon}
              alt="upload-icon"
            />
            <Text fontSize="lg" display="flex" alignItems="center" my={4} color={GREY_TEXT_COLOR}>
              Drag image files to upload
              <Tooltip label={
                <Box>
                  <Text color="#000"><b>Image file type:</b> JPG or PNG</Text>
                  <Text color="#000"><b>Resolution:</b> At least 1080 x 1080 pixels</Text>
                  <Text color="#000"><b>Image maximum file size:</b> 30 MB</Text>
                </Box>} closeDelay={500} bg='#e2e8f0'>
                <InfoOutlineIcon marginLeft="2" height="15px" width="15px" marginTop="2px"/>
              </Tooltip>
            </Text>
            <Text fontSize="sm" mb={4} color={GREY_TEXT_COLOR}>
              - OR -
            </Text>
            <Button size="small" onClick={() => {
            }} disabled={values.imageURL && true} color={"white"} bgColor={BLUE_BUTTON_COLOR} px={10} py={2}
                    borderRadius={100}>Browse Files</Button>
          </Box>
        </div>
      </Grid>
      {(errors.imageURL && touched.imageURL) &&
      <Text color="red.500" marginTop="1">
        Card image is required
      </Text>
      }
      {values.imageURL && values.imageURL.length > 0 && values.imageURL.map((image, index) =>
        <Grid className="file-box">
          <Flex marginTop="5" marginBottom="5">
            {image.type === "image/jpeg" && <Image
              boxSize="50px"
              objectFit="cover"
              src={JPGIcon}
              alt="Dan Abramov"
            />}
            {image.type === "image/png" && <Image
              boxSize="50px"
              objectFit="cover"
              src={PNGIcon}
              alt="Dan Abramov"
            />}
            {image.files?.mimetype === "image/jpeg" && <Image
              boxSize="50px"
              objectFit="cover"
              src={JPGIcon}
              alt="Dan Abramov"
            />}
            {image.files?.mimetype === "image/png" && <Image
              boxSize="50px"
              objectFit="cover"
              src={PNGIcon}
              alt="Dan Abramov"
            />}
            <Grid marginLeft="2">
              <Grid>
                <Text fontSize="lg" className="file-name">
                  {image.name || image.filename}
                </Text>
              </Grid>
              <Text fontSize="sm">
                {formatBytes(image.size || image?.files?.size)}
              </Text>
            </Grid>
            <Spacer/>
            <Grid>
              <Grid justifyContent="center">
                <CloseIcon
                  cursor="pointer"
                  className="close-icon"
                  size="small"
                  onClick={() => {
                    setFieldValue('imageURL', values.imageURL.filter((_, i) => i !== index));
                  }}
                />
              </Grid>
              <Grid>
                <DownloadIcon
                  onClick={() => FileSaver.saveAs(fileInfo[index].fileURL, fileInfo[index].fileName)}
                  cursor="pointer"
                  className="close-icon"
                  size="small"
                  w={6}
                  h={6}
                />
              </Grid>
            </Grid>
          </Flex>
          <Box marginTop={"-20px"}>
            <Text textAlign={"right"} display={"block"} color={"green"}>100% Completed</Text>
            <Progress
              className="loading"
              value={100}
              colorScheme='green'
              size='sm'
            />
          </Box>
        </Grid>
      )}

      <Grid
        gap={4}
        className="fb-upload"
        marginTop="20px"
      >
        <GridItem w='100%'>
          <FormControl>
            <FormLabel
              htmlFor="landingPageURL"
              color="gray"
              fontSize="sm"
            >
              Landing page URL
            </FormLabel>
            <Flex className="input-box" marginRight="15px">
              <InputControl
                id="landingPageURL"
                name="landingPageURL"
                value={values.landingPageURL}
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onChange={(e) => setFieldValue('landingPageURL', e.target.value)}/>
              <Box h="48px" w="48px"/>
            </Flex>
          </FormControl>
        </GridItem>
        <GridItem w='100%'>
          <FormControl>
            <FormLabel
              htmlFor="headline"
              color="gray"
              fontSize="sm"
            >
              Headline - max 32 characters
            </FormLabel>
            <Flex className="input-box">
              <InputControl
                id="headline"
                name="headline"
                value={values.headline}
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onChange={(e) => setFieldValue('headline', e.target.value)}
              />
              <CircularProgress
                marginLeft="15px"
                max={fbCarouselAdUploadValueLengths?.headline}
                value={values?.headline?.length}
                color={values?.headline?.length > fbCarouselAdUploadValueLengths?.headline ? "red.400" : "green.400"}
              >
                <CircularProgressLabel>
                  {values?.headline?.length > fbCarouselAdUploadValueLengths?.headline ? fbCarouselAdUploadValueLengths?.headline - values?.headline?.length : values?.headline?.length}
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
          </FormControl>
        </GridItem>
        <GridItem w='100%'>
          <FormControl>
            <FormLabel
              htmlFor="description"
              color="gray"
              fontSize="sm"
            >
              Description - max 18 characters
            </FormLabel>
            <Flex className="input-box" justifyContent='center' alignItems="center">
              <InputControl
                id="description"
                name="description"
                value={values.description}
                placeholder=""
                inputprops={{
                  variant: "outline",
                  type: "text",
                }}
                onChange={(e) => setFieldValue('description', e.target.value)}
              />
              <CircularProgress
                marginLeft="15px"
                max={fbCarouselAdUploadValueLengths?.description}
                value={values?.description?.length}
                color={values?.description?.length > fbCarouselAdUploadValueLengths?.description ? "red.400" : "green.400"}
              >
                <CircularProgressLabel>
                  {values?.description?.length > fbCarouselAdUploadValueLengths?.description ? fbCarouselAdUploadValueLengths?.description - values?.description?.length : values?.description?.length}
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
          </FormControl>
        </GridItem>
      </Grid>
    </>
  );
}
