import { useQuery } from 'react-query'
import axios from '../../helpers/axios'
import {useRecoilState} from "recoil";
import {profile} from "../../atoms/authAtom";

export const useGetUserDetails = (id) => {
    const [profileUpdate, setProfileUpdate] = useRecoilState(profile);

    return useQuery(['user', id], () => {

            return axios.get(`/user/${id}`, {
                withCredentials: false
            })
                .then(res => {
                    if (res.status === 200)
                    return res.data.data.user;
                })
                .catch((err) => {
                    throw err.response.data.message
                })
        },
        {
            onSuccess: (data) => {
                if (profileUpdate.id === data.id) {
                    setProfileUpdate(data);
                }
            },
        }
    );
};
