import { createSlice } from "@reduxjs/toolkit";
import { adUploadSlice } from "../../../../../../../../store/adUploads";
import { AD_UPLOAD_TYPE } from "../../../../../../../../constant";

const initialState = {
    formDetails: {},
    mediaDetails: [],
    handlePreviewData: null,
};

export const snapchatImageSlice = createSlice({
    name: AD_UPLOAD_TYPE.SNAPCHAT_IMAGE,
    initialState,
    reducers: {
        settingSnapchatFormDetails: (state, action) => {
            state.formDetails = action.payload;
        },
        settingSnapchatMedia: (state, action) => {
            state.mediaDetails = action.payload;
        },
        settingSnapchatPreview: (state, action) => {
            state.handlePreviewData = action.payload;
        },
        deleteAllSnapchatImageFields: (state, action) => {
            state.formDetails = {};
            state.mediaDetails = null;
            state.handlePreviewData = null;
        }
    },
});

export const { deleteAllSnapchatImageFields, settingSnapchatFormDetails, settingSnapchatMedia, settingSnapchatPreview } = snapchatImageSlice.actions;

export const snapchatImageReducer = snapchatImageSlice.reducer;
