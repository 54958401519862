import {
    Box,
    Button, Checkbox,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    Image,
    Menu, MenuButton, MenuItem, MenuList, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack,
    SimpleGrid,
    Text
} from "@chakra-ui/react";
import {TEXT_COLOR} from "../../../../../../layout/constant/MenuList";
import React, {useEffect, useState} from "react";
import {selectSocialMedia, selectMediaType, defaultMediaTypeNull} from "../../../../../../store/adUploads";
import {useDispatch, useSelector} from "react-redux";
import {Select} from "chakra-react-select";
import {DeviceOptions, LocationOptions, TargettingLocationRadius} from "../../../../constant/SelectValues";
import {Flex} from "@chakra-ui/layout";
import {
    CampaignAgeGroup,
    CampaignTargettingOptions,
    TargettingOptionRelationshipGender, TargettingOptionRelationshipType
} from "../../../../../../constant";
import {Form, Formik} from "formik";
import {ChevronDownIcon, CloseIcon} from "@chakra-ui/icons";
import {Input} from "@chakra-ui/input";
import {SubmitButton} from "formik-chakra-ui";
import * as yup from "yup";
import {
    deleteAllAdCampaignFields, settingBusinessDetails, settingCampaignBudget,
    settingCampaignDetails, settingCampaignObjective, settingDemographics,
    settingDemographyDetails, settingMediaStrategies, settingMediaStrategyDetails, settingNotes
} from "../../../../../../store/adCampaign";
import * as Yup from "yup";
import {useGetSingleCampaign} from "../../../../../../hooks/campaign-briefs/useGetSingleCampaign";
import {useParams} from "react-router-dom";
import axios from "../../../../../../helpers/axios";
import _ from "lodash";

const creatives = [
    {
        name: "Display Ads",
        image: "image.svg",
        type: "display ads"
    }
];

const initialValue = {
    creativeType: "",
    targetingMethod: "",
    startDate: "",
    endDate: "",
};

const schema = yup.object().shape({
    creativeType: yup.string().required().label("Creative Type"),
    targetingMethod: yup.string().required().label("Targeting Method"),
    startDate: Yup.date()
        .required()
        .label("Start Date")
        .max(Yup.ref("endDate"), "Start date can't be after End date"),
    endDate: Yup.date()
        .min(Yup.ref("startDate"), "End date can't be before Start date")
        .label("End Date"),
});

function GoogleType({activeStep, steps, prevStep, nextStep, label}) {
    const clientId = useSelector((state) => state.client.clientId);
    const { id } = useParams();

    const { data, refetch } = useGetSingleCampaign(clientId, id);

    const { mediaType ,selectedSocialMedia} = useSelector((state) => state.adUpload);
    const { demographicDetails } = useSelector((state) => state.adCampaign);
    const [selectedTab, setSelectTab] = useState(mediaType);
    const [formData, setFormData] = useState(initialValue);
    const [selectedMethod, setSelectedMethod] = useState()
    const [targetingMethods, setTargetingMethods] = useState([])


    const genderOptions = ["Male", "Female", "Unknown"];
    const dispatch = useDispatch();
    console.log(demographicDetails)
    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (id && data) {
            setAllData();
            getSingleBusiness(data.campaign.business_id);
            getTargetingMethod(selectedSocialMedia)
        } else {
            dispatch(deleteAllAdCampaignFields());
        }
    }, [data, id]);

    function getSingleBusiness(id) {
        axios
            .get(`/client/business/${id}`, {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200 && !_.isEmpty(res.data.data.business)) {
                    dispatch(settingBusinessDetails(res.data.data.business));
                }
            })
            .catch((err) => {
                return err.response;
            });
    }

    function getTargetingMethod(selectedSocialMedia) {
        axios
            .post(`/targeting-methods`, {
                channel: selectedSocialMedia,
            })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res)
                    setTargetingMethods(res);
                }
            })
            .catch((err) => {
                return err.response;
            });
    }

    function setAllData() {
        const { detail } = data.campaign;
        dispatch(settingCampaignDetails(detail.campaignDetails));
        dispatch(settingCampaignObjective(detail.objective));
        dispatch(settingCampaignBudget(detail.campaignBudget));
        dispatch(settingDemographics(detail.demographics));
        dispatch(settingMediaStrategies(detail.mediaStrategies));
        dispatch(settingDemographyDetails(detail.demographicDetails));
        dispatch(settingMediaStrategyDetails(detail.mediaStrategyDetails));
        dispatch(settingNotes(detail.notes));
    }

    useEffect(() => {
        dispatch(defaultMediaTypeNull())
        setSelectTab("display ads")
        dispatch(selectMediaType("display ads"))
    },[])

    const activeTab = {
        border: "2px solid #3B82F6",
        boxSizing: "border-box"
    }

    function handleSubmitFormData(values) {
        dispatch(selectMediaType(values.creativeType))
        dispatch(settingDemographyDetails({ ...values }));
    }

    function handleClick(index, type) {
        handleSelect(index, type)
        nextStep()
    }

    function handleSelect(index, type) {
        setSelectTab(type);
        dispatch(selectMediaType(type))
    }

    return (
        <Box w='100%' p={4}>

            <Formik
                initialValues={formData}
                validationSchema={schema}
                onSubmit={handleSubmitFormData}
                enableReinitialize
            >
                {({ values, errors, setFieldValue, touched }) => (
                    <Box as={Form} noValidate>
                        <Box >
                            <Grid
                                templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }}
                                gap={6}
                            >
                                <GridItem colSpan={{ base: 4, md: 2 }} ><Heading mb={{base: 0, md: 14}}>{selectedSocialMedia}</Heading></GridItem>
                                <GridItem colSpan={{ base: 4, md: 1 }}>
                                    <FormControl isRequired>
                                        <FormLabel>Start Date</FormLabel>
                                        <Input
                                            placeholder="Start Date"
                                            fontSize={15}
                                            type="date"
                                            name="startDate"
                                            isInvalid={errors.startDate && touched.startDate}
                                            value={values.startDate}
                                            // onChange={(e) => {
                                            //     setBudgetDetails({
                                            //         ...budgetDetails,
                                            //         startDate: e.target.value,
                                            //     });
                                            //     setFieldValue("startDate", e.target.value);
                                            // }}
                                        />
                                        {errors.startDate && touched.startDate && (
                                            <Text color="red.500" fontSize="sm" mt={2}>
                                                {errors.startDate}
                                            </Text>
                                        )}
                                        {/*{isError && (*/}
                                        {/*    <Text color="red.500" fontSize="sm" mt={2}>*/}
                                        {/*        Start date should be earlier then End date*/}
                                        {/*    </Text>*/}
                                        {/*)}*/}
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={{ base: 4, md: 1 }}>
                                    <FormControl isRequired>
                                        <FormLabel>End Date</FormLabel>
                                        <Input
                                            placeholder="End Date"
                                            name="endDate"
                                            type="date"
                                            isInvalid={errors.endDate && touched.endDate}
                                            value={values.endDate}
                                            // onChange={(e) => {
                                            //     setBudgetDetails({
                                            //         ...budgetDetails,
                                            //         endDate: e.target.value,
                                            //     });
                                            //     setFieldValue("endDate", e.target.value);
                                            // }}
                                        />
                                        {errors.endDate && touched.endDate && (
                                            <Text color="red.500" fontSize="sm" mt={2}>
                                                {errors.endDate}
                                            </Text>
                                        )}
                                        {/*{isError && (*/}
                                        {/*    <Text color="red.500" fontSize="sm" mt={2}>*/}
                                        {/*        End date should be greater then Start date*/}
                                        {/*    </Text>*/}
                                        {/*)}*/}
                                    </FormControl>
                                </GridItem>
                            </Grid>
                        </Box>
                    <Box >
                        <Grid
                            templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
                            gap={6}
                        >
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                                <FormControl isRequired>
                                    <Flex gap={1}>
                                        <Image
                                            mt={1}
                                            src={`${process.env.PUBLIC_URL}/images/gender.svg`}
                                            height="18px"
                                        />
                                        <FormLabel>Creative type</FormLabel>
                                    </Flex>
                                    <Select
                                        id="creativeType"
                                        name="creativeType"
                                        placeholder="Select Creative Type"
                                        fontWeight={500}
                                        variant="outline"
                                        border="2px"
                                        borderRadius={1}
                                        borderColor="#E7E7E9"
                                        value={values.creativeType}
                                        onChange={(e) =>
                                            setFieldValue("creativeType", e.target.value)
                                        }
                                        isInvalid={errors.creativeType && touched.creativeType}
                                    >
                                        {creatives.map((objective) => {
                                           return <option
                                                className="campaign-option"
                                                key={objective.name}
                                                value={objective.type}
                                            >
                                                {objective.name}
                                            </option>
                                        })}
                                    </Select>
                                    {errors.creativeType && touched.creativeType && (
                                        <Text color="red.500" fontSize="sm" mt={2}>
                                            {errors.creativeType}
                                        </Text>
                                    )}
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}></GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                                <FormControl isRequired>
                                    <Flex gap={1}>
                                        <Image
                                            mt={1}
                                            src={`${process.env.PUBLIC_URL}/images/gender.svg`}
                                            height="18px"
                                        />
                                        <FormLabel>Targeting Method</FormLabel>
                                    </Flex>
                                    <Select
                                        id="targetingMethod"
                                        name="targetingMethod"
                                        placeholder="Select Targeting Method"
                                        fontWeight={500}
                                        variant="outline"
                                        border="2px"
                                        borderRadius={1}
                                        borderColor="#E7E7E9"
                                        value={values.targetingMethod}
                                        onChange={(e) =>
                                            setFieldValue("targetingMethod", e.target.value)
                                        }
                                        isInvalid={errors.targetingMethod && touched.targetingMethod}
                                    >
                                        {genderOptions.map((objective) => (
                                            <option
                                                className="campaign-option"
                                                key={objective}
                                                value={objective}
                                            >
                                                {objective}
                                            </option>
                                        ))}
                                    </Select>
                                    {errors.targetingMethod && touched.targetingMethod && (
                                        <Text color="red.500" fontSize="sm" mt={2}>
                                            {errors.targetingMethod}
                                        </Text>
                                    )}
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <SubmitButton
                            display={"flex"}
                            justifyContent={"flex-end"}
                            fontFamily={"poppins"}
                            px={8}
                            py={5}
                            _hover={{ bg: "" }}
                            color={"#FFFFFF"}
                            bgColor={"#1F95FF"}
                            border={"2px"}
                            borderColor="#1F95FF"
                            style={{ margin: "1.4rem 0 1.4rem auto" }}
                        >
                            Save
                        </SubmitButton>
                    </Box>
                    </Box>
                )}
            </Formik>

            {/*<SimpleGrid columns={[1, 1, 1, 2]} spacing='20px'>*/}
            {/*    {*/}
            {/*        type.map((item, index) => {*/}
            {/*            const {type, image, name} = item;*/}
            {/*            return (*/}
            {/*                <Box*/}
            {/*                    cursor={"pointer"}*/}
            {/*                    border={"2px solid rgba(167, 169, 189, 0.2)"}*/}
            {/*                    borderRadius={"10px"}*/}
            {/*                    height='80px'*/}
            {/*                    display={"flex"}*/}
            {/*                    p={4}*/}
            {/*                    key={item.name}*/}
            {/*                    style={selectedTab === type ? activeTab : null}*/}
            {/*                    onClick={() => handleClick(index, type)}*/}
            {/*                >*/}
            {/*                    <Box display={"flex"} alignItems={"center"} gap={"10px"} color={"#757998"}>*/}
            {/*                        <Image*/}
            {/*                            src={`${process.env.PUBLIC_URL}/images/${image}`}*/}
            {/*                            height="40px"*/}
            {/*                        /> {name}</Box>*/}
            {/*                </Box>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</SimpleGrid>*/}
        </Box>
    )
}

export default GoogleType