import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
    Box,
    Button,
    CircularProgress,
    CircularProgressLabel,
    css,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading, Text,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  snapchatAdUploadValueLengths,
} from "../../../../../constant/InitialValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import { settingSnapchatFormDetails, settingSnapchatMedia } from "./store";
import TooltipComponent from "../../TooltipComponent";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {Input} from "@chakra-ui/input";
import {CloseIcon} from "@chakra-ui/icons";

const initialValue = {
  adName: "",
  title: "",
  description: "",
  link: "",
  channel_name: "",
  channel_icon: null,
};

function SnapchatImageAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData
}) {
  const { id, adUploadId } = useParams();
  const {mutateAsync} = useUploadImage();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.snapchatImageAd);

  const schema = yup.object().shape({
    adName: yup.string().trim().min(3).max(25).required().label("Ad name"),
    title: yup.string().trim().min(3).max(25).required().label("Title"),
    description: yup.string().trim().min(3).max(34).required().label("Description"),
    link: yup
      .string()
      .trim()
      .required("Landing page url is required")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      )
      .label("Landing page url"),
    channel_name: yup.string()
        .trim()
        .max(30)
        .optional()
        .label("Channel Name"),

    channel_icon: yup
        .mixed()
        .test(
            "fileType",
            "Only image files are allowed",
            (value) => {
              if (!value) return true; // Allow empty value
              const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
              return allowedTypes.includes(value?.type || value?.mimetype);
            }
        )
        .nullable(true)
        .label("Channel Icon"),
  });

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);

  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (adUploadId) {
      const imageFile = data?.adUpload?.detail?.fileInfoList.map(
        (el) => el?.files
      );
      dispatch(
        settingSnapchatFormDetails({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          title: data?.adUpload?.detail?.title,
          description: data?.adUpload?.detail?.description,
          link: data?.adUpload?.detail?.link,
          channel_name: data?.adUpload?.detail?.channel_name,
          channelIcon: data?.adUpload?.detail?.channel_icon,
          channel_icon: data?.adUpload?.detail?.channel_icon?.files,
        })
      );
      dispatch(
        settingSnapchatMedia({
          imageURL: imageFile,
          fileInfoList: data?.adUpload?.detail?.fileInfoList,
        })
      );
      // dispatch(settingEditMediaType(data?.adUpload?.ad_upload_type));
    }
  }, [data]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (values.channel_icon?.name) {
        await mutateAsync(
            {
              clientId: clientId,
              campaignBriefId: id,
              adFile: [values.channel_icon],
              type: "youtubeShortsChannelIcon",
            }, {
              onSuccess: (data, variables, context) => {
                dispatch(
                    settingSnapchatFormDetails({
                      ...data?.adUpload?.detail,
                      ...values,
                      channelIcon: data.fileInfoList[0],
                      channel_icon: null,
                    })
                );
              },
              onError: (err) => {
                console.log('error OnError', err)
              }
            });
      } else {
        dispatch(
            settingSnapchatFormDetails({
              ...data?.adUpload?.detail,
              ...values,
              channel_icon: null
            })
        );
      }
      nextStep();
    },
  });

  const { errors, values, touched, handleSubmit, isValid, setFieldValue } =
    formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("title", formDetails.title || autoFillData?.title || "");
    setFieldValue("description", formDetails.description || autoFillData?.description || "");
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
    setFieldValue("channel_name", formDetails.channel_name || autoFillData?.channel_name || "");
    setFieldValue("channel_icon", formDetails.channelIcon?.files || "");
    setFieldValue("channelIcon", formDetails.channelIcon || "");
  }, [formDetails, autoFillData]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFieldValue('channel_icon', file);
    };

  return (
    <>
      
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    htmlFor="adName"
                    color="gray"
                    fontSize="sm"
                    display={"none"}
                  >
                    Ad Name - Up to 25 characters with spaces
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Ad Name - Up to 25 characters with spaces"
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={snapchatAdUploadValueLengths.adName}
                      value={values.adName.length}
                      color={
                        values.adName.length >
                        snapchatAdUploadValueLengths.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.adName.length >
                        snapchatAdUploadValueLengths.adName
                          ? snapchatAdUploadValueLengths.adName -
                            values.adName.length
                          : values.adName.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel
                    htmlFor="title"
                    color="gray"
                    fontSize="sm"
                    display={"none"}
                  >
                    Title - max 100 characters ( Your first 40 characters are
                    what usually show up in feeds. )
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="title"
                      name="title"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Title - max 20 characters ",
                      }}
                      onChange={(e) => setFieldValue("title", e.target.value)}
                    />
                    <CircularProgress
                      max={snapchatAdUploadValueLengths.title}
                      value={values.title.length}
                      color={
                        values.title.length >
                        snapchatAdUploadValueLengths.title
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.title.length >
                        snapchatAdUploadValueLengths.title
                          ? snapchatAdUploadValueLengths.title -
                            values.title.length
                          : values.title.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="description" display={"none"}>
                    Description - max 500 characters ( People will usually see
                    the first 50 characters when they click on your Pin. )
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="description"
                      name="description"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Headline - Up to 34 characters with spaces",
                      }}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={snapchatAdUploadValueLengths.description}
                      value={values.description.length}
                      color={
                        values.description.length >
                        snapchatAdUploadValueLengths.description
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.description.length >
                        snapchatAdUploadValueLengths.description
                          ? snapchatAdUploadValueLengths.description -
                            values.description.length
                          : values.description.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" display={"none"}>
                    Landing page url - max 255 characters ( Use a unique
                    landing page url. )
                  </FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder:
                          "Landing page url - max 255 characters ( Use a unique landing page url. )",
                      }}
                      onChange={(e) => setFieldValue("link", e.target.value)}
                    />
                    <CircularProgress
                      max={255}
                      value={values.link.length}
                      color={values.link.length > 255 ? "red.400" : "green.400"}
                    >
                      <CircularProgressLabel>
                        {values.link.length > 255
                          ? 255 - values.link.length
                          : values.link.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
              <GridItem mb={2}>
                  <FormControl>
                      <FormLabel htmlFor="channel_name" display={"none"}>
                          Channel Name
                      </FormLabel>
                      <div className="input-box">
                          <InputControl
                              id="channel_name"
                              name="channel_name"
                              placeholder=""
                              inputProps={{
                                  variant: "outline",
                                  type: "text",
                                  placeholder: "Channel Name (Optional) - max 90 characters",
                              }}
                              onChange={(e) =>
                                  setFieldValue("channel_name", e.target.value)
                              }
                          />
                          <CircularProgress
                              max={snapchatAdUploadValueLengths?.channel_name}
                              value={values?.channel_name?.length}
                              color={
                                  values?.channel_name?.length >
                                  snapchatAdUploadValueLengths?.channel_name
                                      ? "red.400"
                                      : "green.400"
                              }
                          >
                              <CircularProgressLabel>
                                  {values?.channel_name?.length >
                                  snapchatAdUploadValueLengths?.channel_name
                                      ? snapchatAdUploadValueLengths?.channel_name -
                                      values?.channel_name?.length
                                      : values?.channel_name?.length}
                              </CircularProgressLabel>
                          </CircularProgress>
                      </div>
                  </FormControl>
              </GridItem>
              <GridItem mb={2}>
                  <FormControl>
                      <FormLabel htmlFor="channel_icon" display={"none"}>
                          Channel Icon
                      </FormLabel>
                      <div className="input-box">
                          <Box py={2}>
                              <Input
                                  id="channel_icon"
                                  name="channel_icon"
                                  type="file"
                                  accept="image/*"
                                  display="none"
                                  onChange={handleFileChange}
                              />
                              <label htmlFor="channel_icon" style={{ cursor: "pointer" }}>
                                  <Box
                                      as="span"
                                      padding="8px 16px"
                                      border="1px solid #ccc"
                                      borderRadius="4px"
                                      fontSize="0.825rem"
                                      color="#706f7b"
                                      _hover={{ borderColor: "blue.400" }}
                                  >
                                      Upload Channel Icon
                                  </Box>
                              </label>
                          </Box>
                          {values.channel_icon && (
                              <>
                                  <Text >{values.channel_icon.name || values.channel_icon.originalname}</Text>
                                  <Grid justifyContent="center">
                                      <CloseIcon
                                          cursor="pointer"
                                          className="close-icon"
                                          height={'12px'}
                                          width={'12px'}
                                          onClick={() => {
                                              setFieldValue('channel_icon', null);
                                              setFieldValue('channelIcon', null);
                                          }}
                                      />
                                  </Grid>
                              </>
                          )}
                      </div>
                      {errors.channel_icon && <Text color='#E53E3E' fontSize="14px" mt="8px">{errors.channel_icon}</Text>}
                  </FormControl>
              </GridItem>
          </Grid>

          <Flex width="100%" justify="flex-end" py={10}>
              
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default SnapchatImageAdDetails;
