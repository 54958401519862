import {
  Box,
  Button,
  Center,
  css,
  Flex,
  Heading,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { For } from "react-haiku";
import { ROLES } from "../../../constant";
import { useGetClientDetailsOnClick } from "../../../hooks/clients/useGetClientDetails";
import { BiArrowBack, BiPlusCircle } from "react-icons/bi";
import { TEXT_COLOR } from "../../../layout/constant/MenuList";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import CreateFacebookCampaign from "./FbCampaigns/CreateFacebookCampaignModel";
import CreateDV360Campaign from "./Dv360Campaings/CreateDV360CampaignModel";
import { Comment } from "./Comment";
import { profile } from "../../../atoms/authAtom";
import { useDispatch, useSelector } from "react-redux";
import { useGetCampaign } from "../../../hooks/campaign-briefs/useGetCampaign";
import { updateCampaignFbObjective } from "../../../store/campaign/campaignThunk";
import "./CampaignDetailsStepByStep/FormStepButton";
import { ContextWrapper } from "../ContextStore/ContextWrapper";
import { v4 as uuidv4 } from "uuid";
import { useGetAdUpload } from "../../../hooks/campaign-briefs/useGetAdUpload";
import EditAd from "./AdUpload/EditAd";
import { deleteAllStateFromRedux } from "./AdUpload/deleteAllStateFromRedux";
import VectorIcon from "../../../assets/images/Vector.png";
import AuditLogsList from "../components/AdUpload/AuditLogsList";

const EditCampaign = () => {
  const [tabIndex, SetTabIndex] = useState(0);
  const [isRemoveTabs, SetIsRemoveTabs] = useState(null);
  const dispatch = useDispatch();
  const { isOpen: isFacebookModelOpen, onClose: onFacebookModelClose } =
    useDisclosure();
  const { isOpen: isDV360odelOpen, onClose: onDV360ModelClose } =
    useDisclosure();

  const clientId = useSelector((state) => state.client.clientId);

  const navigate = useNavigate();
  const location = useLocation();

  const { id, adUploadId } = useParams();
  const { mutate } = useGetClientDetailsOnClick();
  const { data: adSingleData, refetch } = useGetAdUpload(
    clientId,
    id,
    adUploadId
  );

  function backToCampaignBreif() {
    navigate(`/campaign-briefs/${id}/ad-upload`, { state: "backToAdUpload" });
    deleteAllStateFromRedux(dispatch);
  }

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { data: campaignData, mutate: mutateGetCampaign } = useGetCampaign();
  const {
    access_info: { roles },
  } = useRecoilValue(profile);

  useEffect(() => {
    if (roles) {
      let isMatch = roles.find((item) => item.includes(ROLES.CLIENT));
      if (isMatch) {
        SetIsRemoveTabs(false);
      } else {
        SetIsRemoveTabs(true);
      }
    }
  }, [roles]);

  useEffect(() => {
    if (clientId) {
      mutate({ id: clientId });
      mutateGetCampaign({
        clientId,
        campaignId: id,
      });
    }
  }, [clientId, id, mutate, mutateGetCampaign]);

  useEffect(() => {
    if (campaignData?.campaign?.detail?.objective) {
      dispatch(
        updateCampaignFbObjective(campaignData.campaign.detail.objective)
      );
    }
  }, [campaignData, dispatch]);

  useEffect(() => {
    if (location.state && location.state.isShowUpload) {
      setTimeout(() => {
        SetTabIndex(4);
      }, 1000);
    }
  }, [location.state]);

  useEffect(() => {
    localStorage.removeItem("pageIndex");
  }, []);

  const EDIT_CAMPAIGN_BRIEFS_TABS = [
    {
      id: uuidv4(),
      title: "Briefs",
      hideTabForClient: false,
    },
    {
      id: uuidv4(),
      title: "Audit Logs",
      hideTabForClient: false,
    },
  ];

  return (
    <>
      <Flex  gap={4}>
        <Box className="create-campaign-main">
          <Heading fontSize="sm" mb={7} color={TEXT_COLOR}>
            <Flex
                mt={{ base: "8", md: "2" }}
                style={{ transform: "translateX(-1.5rem)" }}
                onClick={backToCampaignBreif}
                cursor={"pointer"}
                px="4"
            >
              {/*<Center marginRight={2}>*/}
              {/*  <BiArrowBack size={16} />*/}
              {/*</Center>*/}
              <Center>
                <Button
                  leftIcon={<Image src={VectorIcon} />}
                  color="brand.primary"
                  fontWeight={500}
                  border="2px"
                  borderRadius={0}
                  pr={6}
                  fontFamily={"poppins"}
                  bgColor={"white"}
                  variant={"outline"}
                >
                  Back to Channels
                </Button>
              </Center>
            </Flex>
          </Heading>
          {/*{adSingleData?.adUpload?.status === "Created" ? (*/}
          {/*  <Heading*/}
          {/*    fontSize="30px"*/}
          {/*    mb={4}*/}
          {/*    color={"#757998"}*/}
          {/*    fontFamily={"Lora"}*/}
          {/*    fontWeight={600}*/}
          {/*  >*/}
          {/*    /!*Edit Campaign*!/*/}
          {/*    {adSingleData?.adUpload?.name}*/}
          {/*    <span> &#8226;</span>*/}
          {/*    <span style={{ color: "#FFC108" }}> Draft</span>*/}
          {/*  </Heading>*/}
          {/*) : adSingleData?.adUpload?.status === "Approved" ? (*/}
          {/*  <Heading*/}
          {/*    fontSize="30px"*/}
          {/*    mb={4}*/}
          {/*    color={"#757998"}*/}
          {/*    fontFamily={"Lora"}*/}
          {/*    fontWeight={600}*/}
          {/*  >*/}
          {/*    /!*Edit Campaign*!/*/}
          {/*    {adSingleData?.adUpload?.name}*/}
          {/*    <span> &#8226;</span>*/}
          {/*    <span style={{ color: "#63C68B" }}> Approved</span>*/}
          {/*  </Heading>*/}
          {/*) : (*/}
          {/*  <Heading*/}
          {/*    fontSize="30px"*/}
          {/*    mb={4}*/}
          {/*    color={"#757998"}*/}
          {/*    fontFamily={"Lora"}*/}
          {/*    fontWeight={600}*/}
          {/*  >*/}
          {/*    /!*Edit Campaign*!/*/}
          {/*    {adSingleData?.adUpload?.name}*/}
          {/*    <span> &#8226;</span>*/}
          {/*    <span style={{ color: "#FF8189" }}> Rejected</span>*/}
          {/*  </Heading>*/}
          {/*)}*/}
          {/*<Tabs*/}
          {/*  index={tabIndex}*/}
          {/*  size="sm"*/}
          {/*  w="full"*/}
          {/*  overflow="hidden"*/}
          {/*  fontFamily={"poppins"}*/}
          {/*  fontWeight={600}*/}
          {/*>*/}
          {/*  <TabList*/}
          {/*    overflowX="auto"*/}
          {/*    css={css({*/}
          {/*      scrollbarWidth: "none",*/}
          {/*      "::-webkit-scrollbar": { display: "none" },*/}
          {/*      WebkitOverflowScrolling: "touch",*/}
          {/*      boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",*/}
          {/*      border: "0 none",*/}
          {/*    })}*/}
          {/*  >*/}
          {/*    <For*/}
          {/*      each={EDIT_CAMPAIGN_BRIEFS_TABS}*/}
          {/*      render={(tab, index) => {*/}
          {/*        const isShowTab =*/}
          {/*          tab.hideTabForClient && !isRemoveTabs ? false : true;*/}
          {/*        if (isShowTab) {*/}
          {/*          return (*/}
          {/*            <Tab*/}
          {/*              key={index}*/}
          {/*              onClick={() => {*/}
          {/*                SetTabIndex(index);*/}
          {/*              }}*/}
          {/*              whiteSpace="nowrap"*/}
          {/*              fontFamily={"poppins"}*/}
          {/*              isDisabled={*/}
          {/*                !id &&*/}
          {/*                (tab.title === "AD uploads" ||*/}
          {/*                  tab.title === "Comments")*/}
          {/*              }*/}
          {/*            >*/}
          {/*              {tab.title}*/}
          {/*            </Tab>*/}
          {/*          );*/}
          {/*        }*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </TabList>*/}

          {/*  <TabPanels>*/}
          {/*    <TabPanel>*/}
          {/*      <ContextWrapper>*/}
          {/*        <EditAd />*/}
          {/*      </ContextWrapper>*/}
          {/*    </TabPanel>*/}
          {/*    <TabPanel>*/}
          {/*      <AuditLogsList />*/}
          {/*    </TabPanel>*/}
          {/*  </TabPanels>*/}
          {/*</Tabs>*/}
          <EditAd />
        </Box>
        {/*<Box*/}
        {/*  position="fixed"*/}
        {/*  right="0"*/}
        {/*  width="22%"*/}
        {/*  padding={5}*/}
        {/*  colSpan={{ base: 6, lg: tabIndex === 0 ? 2 : 0 }}*/}
        {/*>*/}
        {/*  {id && tabIndex === 0 && (*/}
        {/*    <Comment clientId={clientId} campaignId={id} />*/}
        {/*  )}*/}
        {/*</Box>*/}

        {/*<CreateDV360Campaign*/}
        {/*  isOpen={isDV360odelOpen}*/}
        {/*  onClose={onDV360ModelClose}*/}
        {/*  clientId={clientId}*/}
        {/*  data={campaignData?.campaign}*/}
        {/*/>*/}

        {/*<CreateFacebookCampaign*/}
        {/*  isOpen={isFacebookModelOpen}*/}
        {/*  onClose={onFacebookModelClose}*/}
        {/*  clientId={clientId}*/}
        {/*  data={campaignData?.campaign}*/}
        {/*/>*/}
      </Flex>
    </>
  );
};

export default EditCampaign;
