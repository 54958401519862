import React from 'react';
import {
  Box, Center, Circle,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link, Skeleton,
  SkeletonCircle,
  SkeletonText, Text
} from "@chakra-ui/react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {useSelector} from "react-redux";

function GoogleSearchAd({formDetails, appLoading, ads, index, handleIndex, businessName, carouselCards}) {
  const {handlePreviewData} = useSelector((state) => state.performanceMaxAd)

  const selectedLogos = (Array.isArray(handlePreviewData?.selectedLogos) && handlePreviewData?.selectedLogos[0]?.imageUrl) ?
      (handlePreviewData?.selectedLogos[0]?.imageUrl) :
      process.env.PUBLIC_URL + "/images/display.png";

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%' overflowY="scroll">
                <Flex justifyItems="center" px={3} justifyContent="space-between" backgroundColor="white"
                      alignItems="center"
                      borderTopRadius="25">
                  <Image src={`${process.env.PUBLIC_URL}/images/three_line_icon.svg`} alt="Three Line Icon" height={8}
                         width={8}/>
                  <Image src={`${process.env.PUBLIC_URL}/images/google_icon.png`} alt="Back Button" height={16}
                         width={24}/>
                  <SkeletonCircle size='7'/>
                </Flex>
                <Box width="100%" pb={6} bg='white'>
                  <Box bg={'gray.400'} border="1px solid" borderColor="gray.200" textColor="white" display="flex"
                       justifyContent="space-between"
                       justifyItems="center" borderRadius="25px" m={5} mb={2} p={3} px={5}>
                    <Image src={`${process.env.PUBLIC_URL}/images/search_1.png`} alt="Gmail Icon" height={4}
                           width={4}/>
                  </Box>
                  <Flex justifyContent="space-around">
                    {[...Array(5)].map((_, index) => (
                      <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='1.5' rounded="lg" width="12%"/>
                    ))}
                  </Flex>
                  <Box height="3px" mt={2} ml={2} bg="gray.400" boxShadow="sm" width="15%"/>
                  <Center>
                    <Box height="1px" width="100%" bg="gray.100" boxShadow="sm"/>
                  </Center>
                  <Box border="1px solid" borderColor="gray.100" boxShadow="0px 2px 4px #cbcbcb" my={4} mx={3}>
                    <Box m={4}>
                      <Text fontSize="12px" fontWeight="500">Sponsored</Text>
                    </Box>
                    <Flex mx={4} alignItems="center" gap={2}>
                      <Image src={selectedLogos} height={8}
                             width={8} rounded="full"/>
                      <Box width="100%">
                        <Text fontSize="14px" fontWeight="600">{formDetails.channel_name}</Text>
                        <Text fontSize="12px">{formDetails.link}</Text>
                      </Box>
                    </Flex>
                    <Flex mx={4} mt={2} alignItems="center" gap={2}>
                      <Text>{`${formDetails.headlines[0]} - ${formDetails.headlines[0]}`}</Text>
                    </Flex>
                    <Flex justifyContent="center" height="50vh">
                      {(ads && ads.length !== 0) ?
                        <Box overflow="hidden" width={"100%"} height={"100%"} alignItems="center">
                          <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                                  onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                            {
                              ads.map((item, index) => {
                                return (
                                  <SwiperSlide>
                                    <Box
                                      height="90%"
                                      width="90%"
                                      boxShadow="lg"
                                      bg="white"
                                      margin="5"
                                      borderRadius="10px"
                                    >
                                      <Image
                                        height="100%"
                                        width="100%"
                                        objectFit="cover"
                                        src={item?.imageUrl}
                                        className="previewImage"
                                        borderRadius="10px"
                                      />
                                    </Box>
                                  </SwiperSlide>
                                )
                              })
                            }
                          </Swiper>
                        </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                      }
                    </Flex>
                    <Text mx={4} mb={4}>{formDetails?.description}.</Text>
                  </Box>
                </Box>
                <Flex justifyContent="center" p={2} m={4} bg="gray.100" border="1px solid"
                      borderColor="gray.100" boxShadow="0px 2px 4px #cbcbcb">
                  <SkeletonText mt='1' noOfLines={3} spacing='3' skeletonHeight='3' width="70%"/>
                  <SkeletonText mt='1' noOfLines={3} spacing='3' skeletonHeight='3' width="70%"/>
                </Flex>
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default GoogleSearchAd;
