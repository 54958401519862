import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box, Button,
  Container, Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  SkeletonCircle,
  SkeletonText, Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";

function YoutubeHome({formDetails, appLoading, ads, index, handleIndex, businessName}) {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
      {appLoading && <FullPageLoader/>}
      <GridItem w="full" colSpan={{base: 6, lg: 6}}>
        <Heading fontSize="lg" my={4} color={"#757998"}>
          {ads?.[index]?.filename}
        </Heading>
        <Box
          borderWidth="2px"
          borderColor="gray"
          p={10}
          background={PREVIEW_BG_COLOR}
          position={"relative"}
        >
          <Container height="100%" centerContent>
            <Box height='700px' width='400px' backgroundColor='white' border='1px #e3e8f1 solid' borderRadius="25">
              <Flex flexDirection="column">
                <Box backgroundColor='gray.300' textAlign='center' width="75px" height="5px"
                     borderRadius="25" pt={3} m={3} alignSelf="center"/>
              </Flex>
              <Box border='1px #e3e8f1 solid' borderRadius="25"
                   alignSelf="center" mx='3' height='93%'>
                <Box width="100%" height="65%" borderTop="1px solid gray.300" borderBottom="1px solid gray.300"
                     boxShadow='sm' bg='white'>
                  <Flex display="flex" gap="5" padding='6' height="20%" bg="gray.200" borderTopRadius="25px"/>
                  <Box p={3}>
                    <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="100%"/>
                    <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="80%"/>
                    <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="60%"/>
                  </Box>
                  <Flex p={3} justifyContent="space-around">
                    {[...Array(5)].map((_, index) => (
                    <SkeletonCircle size={6}/>
                    ))}
                  </Flex>
                  <Divider />
                  <Flex p={3} gap={3} alignItems="center">
                    <SkeletonCircle size={8}/>
                    <SkeletonText noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="60%"/>
                  </Flex>
                  <Divider />
                  <Grid templateColumns="repeat(2, 1fr)" height="25%" m={2} gap={2}>
                    {(ads && ads.length !== 0) ?
                      <Box overflow="hidden" width={"100%"} height={"100%"}>
                        <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                                onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                          {
                            ads.map((item) => {
                              return (
                                <SwiperSlide>
                                  <Image
                                    height="100%"
                                    width={"100%"}
                                    objectFit={"fill"}
                                    src={item?.imageUrl}
                                    className="previewImage"
                                  />
                                </SwiperSlide>
                              )
                            })
                          }
                        </Swiper>
                      </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                    }
                    <Box>
                      <Flex justifyContent="space-between" my={2}>
                        <Text fontSize="14px" fontWeight="600"> {formDetails.headlines[0]} </Text>
                      </Flex>
                      <Flex justifyItems="baseline" my={2}>
                        <Text color="white" bg="#d7d763" textAlign="center" px={1} mr={1} fontSize="14px">Ad</Text>
                        <Text fontSize="14px">{businessName}</Text>
                      </Flex>
                      <button style={{color: "#006dff", border: "1px solid #006dff", padding: '1vh 2vh', fontSize: '14px', fontWeight: "500"}}>{youtubeShortsTypes.find((i) => i.key === formDetails.call_to_action?.type)?.name}</button>
                    </Box>
                  </Grid>
                </Box>
                {[...Array(4)].map((_, index) => (
                <Flex justifyContent="space-between" px={1} mt={4}>
                  <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="20%"/>
                  <SkeletonText mt='2' noOfLines={1} spacing='3' skeletonHeight='2' rounded="lg" width="30%"/>
                </Flex>
                ))}
              </Box>
            </Box>
          </Container>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default YoutubeHome;
