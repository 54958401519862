import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Heading } from "@chakra-ui/layout";
import {
    displayCreativeTypes,
    facebookCreativeTypes,
    googleCreativeTypes,
    instagramCreativeTypes,
    pinterestCreativeTypes,
    tiktokCreativeTypes,
    metaCreativeTypes,
    dynamicFacebookCreativeTypes,
    dynamicInstagramCreativeTypes,
    youtubeShortsTypes,
    demandGenTypes,
    snapchatCreativeTypes,
    performanceMaxTypes, socialDisplayTypes, microsoftCreativeTypes, audioTypes, linkedinTypes, discoveryTypes,
} from "../../../../../constant";
import {
    Box,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Image,
    Text,
    Select, Switch, Button,
    useDisclosure
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/input";
import AdDetailsForm from "../StepByStepAdUploadComponent/AdDetailsForm";
import { useParams } from "react-router-dom";
import { useGetAdUpload } from "../../../../../hooks/campaign-briefs/useGetAdUpload";
import { titleCase } from "../../../../../helpers/utils";
import axios from "../../../../../helpers/axios";
import {facebookAccountIds} from "../../../constant/SelectValues";
import {youtubeShortsTypes as socialDisplayOptions} from "../../../constant/SelectValues";


function CreativeAiBotDetail({ activeStep, steps, prevStep, nextStep, label , popupDetail  , setAdCopyData , setPopupDetail  }) {
    const dispatch = useDispatch();
    const clientId = useSelector((state) => state.client.clientId);
    const { id, adUploadId } = useParams();
    const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);
    const { campaignBudget } = useSelector((state) => state.adCampaign);
    const adCampaignData = useSelector((state) => state.adCampaign);
    const { selectedSocialMedia, creative } = useSelector((state) => state.adUpload);
    const initialValue = {
        promotion: creative?.promotion || "",

    };

    //const [adCopyData, setAdCopyData] = useState(null);
    const [formData, setFormData] = useState(initialValue);
    const [creativeOptions, setCreativeOptions] = useState([]);
    const [isUpcoming, setIsUpcoming] = useState(false);
    const { isOpen , onOpen, onClose } = useDisclosure()

    console.log("data?.adUpload?.detail?.call_to_action?.value?.link",adCampaignData)

    const generateAdCopy = async (id) => {
        try {
            const res = await axios.post(`/client/${clientId}/campaign-brief/${id}/ad-upload/generate-ad-copy`, {
                popupDetail
            });

            if (res.status === 200) {
                console.log("inside then");
                //console.log("res inside generateAdCopy", res.data);
                setAdCopyData(res.data);
            }
        } catch (error) {
            console.log("inside catch", error);
        }
    };


    const handleSubmit = async () => {
        await generateAdCopy(id , popupDetail);
        onClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('{ ...popupDetail, [name]: value }', { ...popupDetail, [name]: value })
        setPopupDetail({ ...popupDetail, [name]: value });
    };

    console.log('popupDetail', popupDetail)

    return (
        <div>

                <div>
                    <Box>
                        <Grid
                            templateColumns={{
                                base: "1fr",
                                md: "repeat(2, 1fr)",
                                lg: "repeat(4,1fr)",
                            }}
                            gap={6}
                        >

                            <GridItem colSpan={{ base: 2, md: 2 }}>
                            </GridItem>

                        </Grid>
                    </Box>

                    <Box>
                        {['facebook', 'instagram', 'social_display'].includes(selectedSocialMedia) && (
                            <form >
                                <Text mb="3px">URL</Text>
                                <Input
                                    placeholder="URL"
                                    name="url"
                                    value={popupDetail.url}
                                    onChange={handleChange}
                                    color="00000"
                                    mb={2}
                                    required
                                />

                                <Text mb="3px">Promotion</Text>
                                <Input
                                    placeholder="Promotion"
                                    name="promotion"
                                    value={popupDetail.promotion}
                                    onChange={handleChange}
                                    color="00000"
                                    mb={2}
                                    required
                                />

                                <Text mb="3px">Audience Demographics</Text>
                                <Input
                                    name="audienceDemographics"
                                    value={popupDetail.audienceDemographics}
                                    placeholder="Audience Demographics"
                                    onChange={handleChange}
                                    color="00000"
                                    mb={2}
                                    required
                                />

                                <FormLabel htmlFor="cta">Call to Action</FormLabel>
                                <Select
                                    id="cta"
                                    name="cta"
                                    value={popupDetail.cta}
                                    onChange={handleChange}
                                    variant="outline"
                                    border="2px"
                                    borderRadius={0}
                                    borderColor="gray"
                                    fontWeight="600"
                                    fontSize="14px"
                                    lineHeight="16px"
                                    color="#757998"
                                    mb={2}
                                    required
                                >
                                    <option value="" disabled>-- Select One --</option>
                                    {selectedSocialMedia === 'facebook' &&
                                    facebookAccountIds.map((el) => (
                                        <option value={el.key} key={el.key}>
                                            {el.name}
                                        </option>
                                    ))}
                                    {selectedSocialMedia === 'instagram' &&
                                    facebookAccountIds.map((el) => (
                                        <option value={el.key} key={el.key}>
                                            {el.name}
                                        </option>
                                    ))}
                                    {selectedSocialMedia === 'social_display' &&
                                    socialDisplayOptions.map((el) => (
                                        <option value={el.key} key={el.key}>
                                            {el.name}
                                        </option>
                                    ))}
                                </Select>



                            </form>
                        )}

                    </Box>


                </div>

        </div>
    );
};

export default CreativeAiBotDetail;
