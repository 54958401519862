import React from 'react';
import {
    Container,
    Grid,
    GridItem,
    Heading,
    Image,
    Skeleton,
    SkeletonCircle,
    Spacer
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../../layout/constant/MenuList";
import {Box, Flex} from "@chakra-ui/layout";
import {range} from "lodash";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";

function YoutubeHomeMonitor({formDetails, appLoading, ads, index, handleIndex, businessName}) {
    const arr = [...range(1, 20)]

    return (
        <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                <Heading fontSize="lg" my={4} color={"#757998"}>
                    Youtube - Home
                </Heading>
                <Box
                    borderWidth="2px"
                    borderColor="gray"
                    p={10}
                    background={PREVIEW_BG_COLOR}
                    position={"relative"}
                >
                    <Container centerContent>
                        <Box minH='600px' h='600px' minW='900px' backgroundColor='white' border='3px #e3e8f1 solid' borderRadius="25" p='4'>
                            <Box h='100%' alignSelf="center" backgroundColor='white' border='3px #e3e8f1 solid' borderRadius="25" overflow='hidden'>
                                <Box bg='' borderBottom='3px #e3e8f1 solid' p='3'>
                                    <Flex alignItems='center' gap='3'>
                                        <Image src={`${process.env.PUBLIC_URL}/images/three_line_icon.svg`} alt="Back Button" height={8}
                                               width={8} pl={2} />
                                        <Flex alignItems="center" justifyContent="center">
                                            <Image src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="YouTube-Icon" height={8}
                                                   width={8}/>
                                            <Box ml={1} fontWeight="bolder">YouTube</Box>
                                        </Flex>
                                        <Flex alignItems='center'>
                                            <Box bg='' h='30px' w='240px' border='1px #e3e8f1 solid' borderLeftRadius='7'></Box>
                                            <Box bg='gray.300' h='30px' w='45px' borderRightRadius='7'></Box>

                                        </Flex>
                                        <SkeletonCircle size="6"/>
                                        <Spacer />
                                        <SkeletonCircle size="5"/>
                                    </Flex>
                                </Box>
                                <Grid
                                    templateColumns='repeat(10, 1fr)'
                                    gap={4}
                                    overflow='hidden'
                                >
                                    <GridItem colSpan={2} >
                                        {[1,2,3,4].map((_, index) => (
                                            <Flex key={index} alignItems='center' gap='3' p='2'>
                                                <SkeletonCircle size="4"/>
                                                <SkeletonCircle size='4' flex='1'/>
                                            </Flex>
                                        ))}
                                        <hr/>
                                        {[1,2,3,4,5,6].map((_, index) => (
                                            <Flex key={index} alignItems='center' gap='3' p='2'>
                                                <SkeletonCircle size="4"/>
                                                <SkeletonCircle size='4' flex='1'/>
                                            </Flex>
                                        ))}
                                        <hr/>
                                        {[1,2,3,4,5,6,7,8].map((_, index) => (
                                            <Flex key={index} alignItems='center' gap='3' p='2'>
                                                <SkeletonCircle size="4"/>
                                                <SkeletonCircle size='4' flex='1'/>
                                            </Flex>
                                        ))}
                                        <hr/>
                                    </GridItem>
                                    <GridItem colSpan={8} p='4'>
                                        <Grid templateColumns='repeat(4, 1fr)' gap={4} >
                                            {(ads && ads.length !== 0) ?
                                                <GridItem overflow="auto" width={"100%"} height={"100%"}>
                                                    <Swiper navigation={true} modules={[Navigation]}
                                                            onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                                                        {
                                                            ads.map((item) => {
                                                                return (
                                                                    <SwiperSlide>
                                                                        <Image
                                                                            height="90px"
                                                                            width={"100%"}
                                                                            objectFit={"fill"}
                                                                            src={item?.imageUrl}
                                                                            className="previewImage"
                                                                            borderRadius='5px'
                                                                        />
                                                                    </SwiperSlide>
                                                                )
                                                            })
                                                        }
                                                    </Swiper>
                                                    <Flex mt='3' gap='2' alignItems='center'>
                                                        <SkeletonCircle size="6"/>
                                                        <Flex direction='column'>
                                                            <Box fontSize='12px' fontWeight="600" title={formDetails.headlines?.[0] || formDetails.headline}>
                                                                {(formDetails.headlines?.[0] || formDetails.headline)?.slice(0, 15)}...
                                                            </Box>
                                                            <Box fontSize="10px" title={formDetails.descriptions?.[0] || formDetails.description}>
                                                                {(formDetails.descriptions?.[0] || formDetails.description)?.slice(0, 15)}...
                                                            </Box>
                                                        </Flex>
                                                    </Flex>
                                                </GridItem> : <GridItem minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                                            }


                                            {arr.map((_, index) => (
                                                <GridItem key={index} w='100%' mb='2'>
                                                    <Skeleton height='90px' borderRadius='5'/>
                                                    <Flex mt='3' gap='2' alignItems='center'>
                                                        <SkeletonCircle size="6"/>
                                                        <Flex direction='column' gap='2'>
                                                            <Skeleton height='10px' w='80px'/>
                                                            <Skeleton height='10px' w='114px'/>
                                                        </Flex>
                                                    </Flex>
                                                </GridItem>
                                            ))}
                                        </Grid>
                                    </GridItem>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </GridItem>
        </Grid>
    );
}

export default YoutubeHomeMonitor;
