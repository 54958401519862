import {
  Box, Button, Divider, Flex,
  Image as ChakraImage, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text
} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AD_UPLOAD_TYPE} from "../../../../../../../constant";
import instance from "../../../../../../../helpers/axios";
import {useNavigate, useParams} from "react-router-dom";
import WithAppContext from "../../../../../../../helpers/appContext";
import SuccessModal from "../../../../../../../components/PopupModal/SuccessModal";
import ErrorModal from "../../../../../../../components/PopupModal/ErrorModal";
import {useGetAdUpload} from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import {useRecoilValue} from "recoil";
import {profile} from "../../../../../../../atoms/authAtom";
import {useUploadImage} from "../../../../../../../hooks/campaign-briefs/useUploadImage";
import {deleteTheField} from "../../../../../../../store/adUploads";
import YoutubeHome from "./components/YoutubeHome";
import Gmail from "./components/Gmail";
import Discover from "./components/Discover";
import {deleteAllDemandGenCarouselFields} from "./store";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import GmailMonitor from "../components/GmailMonitor";

export async function fetchCarouselImageMetadata(imageFiles, carouselCards) {
  const imageFileInfoPromises = imageFiles.map((imageArray, index) => {
    const { imageURL, ...rest } = carouselCards[index];

    const nestedImagePromises = imageArray.map((el) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          const { naturalWidth, naturalHeight } = img;
          console.log('width and height', naturalWidth, naturalHeight);
          resolve({
            ...el,
            // imageURL: el.imageUrl,
            metadata: { height: naturalHeight, width: naturalWidth },
          });
        };
        img.src = `${process.env.REACT_APP_API_URL}/uploads${el.imageUrl}`;
      });
    });

    return Promise.all(nestedImagePromises).then((nestedImageInfo) => ({
      ...rest,
      // nestedImageInfo.metadata,
      imageURL: nestedImageInfo,
    }));
  });

  const imageFileInfoList = await Promise.all(imageFileInfoPromises);

  console.log('imageFileInfoList from fetchImageMetadata fn --->', imageFileInfoList);
  return imageFileInfoList;
}


const DemandGenCarouselAdPreview = ({activeStep, steps, prevStep, nextStep, label, context}) => {
  const [index, setIndex] = useState(0);
  const [index1, setIndex1] = useState(0);
  const [ads, setAds] = useState([]);
  const [method, setMethod] = useState();
  const [url, setUrl] = useState();
  const {id: campaignId, adUploadId} = useParams();
  const clientId = useSelector((state) => state.client.clientId);
  const {setAppLoading, appLoading} = context;
  const [isSuccessModalOpen, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setErrorModal] = useState(false);
  const [description, setDescription] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const tabRefs = useRef([]);

  useEffect(() => {
    if (tabRefs.current[tabIndex]) {
      tabRefs.current[tabIndex].focus();
    }
  }, [tabIndex]);

  const {
    access_info: {roles, clients: userClients},
  } = useRecoilValue(profile);
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(roles.includes("Client"));
  }, [roles]);

  const {data, refetch} = useGetAdUpload(clientId, campaignId, adUploadId);

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (adUploadId) {
      setMethod("PUT");
      setUrl(
        `/client/${clientId}/campaign-brief/${campaignId}/ad-upload/${adUploadId}`
      );
    } else {
      setMethod("POST");
      setUrl(`/client/${clientId}/campaign-brief/${campaignId}/ad-upload`);
    }
  }, [adUploadId, clientId]);

  const {handlePreviewData, formDetails, mediaDetails} = useSelector((state) => state.demandGenCarouselAd)
  const {creative, selectedSocialMedia} = useSelector((state) => state.adUpload)
  const {businessDetails} = useSelector((state) => state.adCampaign)
  const {carouselCards} = mediaDetails

  useEffect(() => {
    setAds(handlePreviewData)
  }, [handlePreviewData]);

  const {mutateAsync} = useUploadImage();

  const onSubmitImages = async (multiFiles) => {
    let payload = [];
    let otherFile = [];
    let files = [];

    multiFiles.forEach((file) => {
      if (file?.filename) {
        otherFile = [...otherFile, file]
      } else {
        payload.push(file);
      }
    })

    if (payload && payload.length) {
      await mutateAsync(
        {
          clientId: clientId,
          campaignBriefId: campaignId,
          adFile: payload,
          type: "demandGen",
        },
        {
          onSuccess: (data, variables, context) => {
            payload = data.fileInfoList;
            setAppLoading(false)
          },
          onError: (error) => {
            setAppLoading(false)
            setDescription(error?.response?.data?.message);
          }
        }
      );
      files = [...otherFile, ...payload]
    } else {
      files = [...otherFile]
    }
    return files;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch()


  const onCloseSuccessModal = () => {
    setSuccessModal(false)

    if (adUploadId) {
      navigate(`/campaign-briefs/${campaignId}/ad-upload`, {
        state: {isShowUpload: true}
      })
      dispatch(deleteAllDemandGenCarouselFields())
      dispatch(deleteTheField())
    } else {
      navigate(`/campaign-briefs/${campaignId}/ad-upload`)
      dispatch(deleteAllDemandGenCarouselFields())
      dispatch(deleteTheField())
    }
  }
  setAppLoading(false);

  async function handleSubmit() {
    setAppLoading(true);

    const imageFiles = [];
    for (const carousel of carouselCards) {
      const result = await onSubmitImages(carousel.imageURL);
      imageFiles.push(result);
    }

    // const imageFileInfoList = imageFiles.map((el, index) => {
    //   const {imageURL, ...rest} = carouselCards[index];
    //   const img = new Image();
    //   let height, width;
    //   img.onload = async () => {
    //     const {naturalWidth, naturalHeight} = img
    //     height = naturalHeight;
    //     width = naturalWidth;
    //     console.log('width and height', width, height)
    //   }
    //   img.src = `${process.env.REACT_APP_API_URL}/uploads${el.imageUrl}`
    //
    //   return {
    //     ...rest,
    //     imageURL: [...el],
    //     metadata: {height, width},
    //   }
    // });

    const imageFileInfoList = await fetchCarouselImageMetadata(imageFiles, carouselCards);

    console.log("imageFileInfoList in dg-carousel",imageFileInfoList);

    let payload = {
      name: formDetails.adName,
      description: formDetails.description,
      title: formDetails.title,
      link: formDetails.link,
      detail: {
        name: formDetails.adName,
        headline: formDetails.headline,
        description: formDetails.description,
        title: formDetails.title,
        call_to_action: {
          type: formDetails.type,
          link: formDetails.link,
        },
        carouselCards: imageFileInfoList,
        creative,
        channel: selectedSocialMedia
      },
    };

    if (!adUploadId) {
      payload = {
        ...payload,
        ad_upload_type: AD_UPLOAD_TYPE.DEMAND_GEN_CAROUSEL,
      };
    }

    await instance({
      method: method,
      url: url,
      withCredentials: false,
      data: payload,
    })
      .then((res) => {
        if (res.status === 200) {
          setSuccessModal(true);
          setAppLoading(false)
        }
      })
      .catch((error) => {
        setDescription(error.response.data.message);
        setErrorModal(true);
        setAppLoading(false)
      });
  }

  const handleIndex = i => setIndex(i)

  const getSlider = () => {
    if (ads?.length) {
      const slides = ads.map((el, ind) => (
        <Text
          height={1}
          borderRadius={5}
          px={4}
          mx={1}
          mt={8}
          key={ind}
          background={ind === index1 ? "grey" : "silver"}
          onClick={() => setIndex1(ind)}
          cursor="pointer"
        />
      ));
      return slides;
    }
  };

  const SliderComponent = () => {
    return (
      <Flex templateColumns="repeat(12, 1fr)" justifyContent="end" alignItems="center">
        {currentTab === 2 && <>
          <Tabs variant='line' index={tabIndex}>
            <TabList>
              <Tab ref={(el) => (tabRefs.current[0] = el)} onClick={() => setTabIndex(0)}>
                <ChakraImage src={`${process.env.PUBLIC_URL}/images/phone_icon.png`} alt="Phone-Icon" height={8} width={8}/>
              </Tab>
              <Tab ref={(el) => (tabRefs.current[1] = el)} onClick={() => setTabIndex(1)}>
                <ChakraImage src={`${process.env.PUBLIC_URL}/images/computer_icon.png`} alt="Computer-Icon" height={8}
                       width={8}/>
              </Tab>
            </TabList>
          </Tabs>
          <Box height='50px' mx={2}>
            <Divider orientation='vertical' border="1px solid"/>
          </Box>
        </>}
        <Button
          leftIcon={<ArrowBackIcon color="gray" w={10} h={10}/>}
          py={8}
          variant="ghost"
          onClick={() => setIndex1(index1 > 1 ? index1 - 1 : 0)}
        />
        <Button
          leftIcon={
            <ArrowForwardIcon color="gray" w={10} h={10}/>
          }
          py={8}
          variant="ghost"
          onClick={() =>
            setIndex1(
              ads && Object.keys(ads)?.length > index1 + 1
                ? index1 + 1
                : index1
            )
          }
        />
      </Flex>
    );
  };

  return (
    <>
      <Tabs isFitted variant='enclosed' onChange={(i) => setCurrentTab(i)}>
        <TabList>
          <Tab>
            <Box align="center">
              <ChakraImage src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="YouTube-Icon" height={8} width={8}
                     margin={2}/>
              <h1>YouTube</h1>
            </Box>
          </Tab>
          <Tab>
            <Box align="center">
              <ChakraImage src={`${process.env.PUBLIC_URL}/images/discover.png`} alt="Discover-Icon" height={8} width={8}
                     margin={2}/>
              <h1>Discover</h1>
            </Box>
          </Tab>
          <Tab>
            <Box align="center">
              <ChakraImage src={`${process.env.PUBLIC_URL}/images/gmail.png`} alt="Gmail-Icon" height={8} width={8}
                     margin={2}/>
              <h1>Gmail</h1>
            </Box>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <SliderComponent/>
            <YoutubeHome
              formDetails={formDetails}
              carouselCards={carouselCards}
              appLoading={appLoading}
              ads={ads}
              index={index}
              index1={index1}
              handleIndex={handleIndex}
              businessName={businessDetails.name}
            />
          </TabPanel>
          <TabPanel>
            <SliderComponent/>
            <Discover
              formDetails={formDetails}
              carouselCards={carouselCards}
              appLoading={appLoading}
              ads={ads}
              index={index}
              index1={index1}
              handleIndex={handleIndex}
              businessName={businessDetails.name}
            />
          </TabPanel>
          <TabPanel>
            <SliderComponent/>
            {!tabIndex ? <Gmail
              formDetails={formDetails}
              carouselCards={carouselCards}
              appLoading={appLoading}
              ads={ads}
              index={index}
              index1={index1}
              handleIndex={handleIndex}
              businessName={businessDetails.name}
            /> : <GmailMonitor
              formDetails={formDetails}
              carouselCards={carouselCards}
              appLoading={appLoading}
              ads={ads}
              index={index}
              index1={index1}
              handleIndex={handleIndex}
              businessName={businessDetails.name}
            />}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Flex width="100%" justify="flex-end" py={10}>
        <Button
          mr={4}
          onClick={prevStep}
          colorScheme='blue' variant='outline' borderRadius={0} px={10}
        >
          Back
        </Button>
        <Button mr={4} onClick={handleSubmit} colorScheme='green' variant='solid' borderRadius={0} px={10}>
          {adUploadId ? 'Update' : 'Submit'}
        </Button>
      </Flex>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => onCloseSuccessModal()}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setErrorModal(false)}
        description={description}
      />
    </>
  );
};

export default WithAppContext(DemandGenCarouselAdPreview);
