import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAdUpload } from "../../../../../../../hooks/campaign-briefs/useGetAdUpload";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { InputControl, SelectControl, TextareaControl } from "formik-chakra-ui";
import {
  demandGenAdValueLengths,
  facebookVideoAdValueLengths,
} from "../../../../../constant/InitialValues";
import {
  facebookAccountIds,
} from "../../../../../constant/SelectValues";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import { settingDemandGenFormDetails, settingDemandGenMedia } from "./store";
import TooltipComponent from "../../TooltipComponent";
import {settingEditMediaType} from "../../../../../../../store/adUploads";
import {Input} from "@chakra-ui/input";

const initialValue = {
  adName: "",
  headlines: [""],
  descriptions: [""],
  type: "",
  link: "",
};

const schema = yup.object().shape({
  adName: yup.string().trim().min(3).max(512).required().label("Ad name"),
  headlines: yup
      .array()
      .of(
          yup.string().trim().min(3).max(40).required().label("Headlines")
      )
      .required()
      .label("Headlines"),
  descriptions: yup
      .array()
      .of(
          yup.string().trim().min(3).max(90).required().label("Descriptions")
      )
      .required()
      .label("Descriptions"),
  type: yup.string().required().label("Type").transform((value) => (value ? value.toLowerCase() : value)),
  link: yup
    .string()
    .required("Landing page url is required.")
    .max(255)
    .matches(
       /https?:\/\/(www\.)?[-a-z0-9@:%._\+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-a-z0-9()@:%_\+.~#?&//=]*)/,
       "Landing page url is not valid and must contain only lowercase letters!"
    )
    .label("Landing page url"),
});

function DemandGenImageAdDetails({
  activeStep,
  steps,
  prevStep,
  nextStep,
  label,
  autoFillData
}) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.demandGenImageAd);
  const [headlinesInputs, setHeadlinesInputs] = useState([""]);
  const [descriptionsInputs, setDescriptionsInputs] = useState([""]);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);
  useEffect(() => {
    if (adUploadId) {
      refetch();
    }
  }, [adUploadId]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(
        settingDemandGenFormDetails({ ...data?.adUpload?.detail, ...values })
      );
      nextStep();
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isValid,
    handleBlur,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("headlines", formDetails.headlines || autoFillData?.headlines || [""]);
    setFieldValue("descriptions", formDetails.descriptions || autoFillData?.descriptions || [""]);
    setFieldValue("type", formDetails.type || autoFillData?.type || "");
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
    setDescriptionsInputs(formDetails.descriptions || [""])
    setHeadlinesInputs(formDetails.headlines || [""])
  }, [formDetails, autoFillData]);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
          settingDemandGenFormDetails({
            ...data?.adUpload?.detail,
            adName: data?.adUpload?.detail?.name,
            descriptions: data?.adUpload?.detail?.descriptions,
            headlines: data?.adUpload?.detail?.headlines,
            type: data?.adUpload?.detail?.call_to_action?.type,
            link: data?.adUpload?.detail?.call_to_action?.link,
          })
      );
      dispatch(
          settingDemandGenMedia({
            selectedImages: data?.adUpload?.detail?.selectedImages,
            selectedLogos: data?.adUpload?.detail?.selectedLogos,
            thumbnailFile: data?.adUpload?.detail?.image_hash,
          })
      );
      dispatch(settingEditMediaType(data?.adUpload?.ad_upload_type));
    }
  }, [data]);

  const handleHeadlinesChange = (e, index) => {
    const newInputs = [...headlinesInputs];
    newInputs[index] = e.target.value;
    setHeadlinesInputs(newInputs);
  };

  const handleAddHeadlinesInput = () => {
    if (headlinesInputs.length < 5) {
      setHeadlinesInputs([...headlinesInputs, ""]);
    }
  };

  const handleRemoveHeadlinesInput = (indexToRemove) => {
    const updatedInputs = headlinesInputs.filter((_, index) => index !== indexToRemove);
    setHeadlinesInputs(updatedInputs);
  };

  const handleDescriptionsChange = (e, index) => {
    const newInputs = [...descriptionsInputs];
    newInputs[index] = e.target.value;
    setDescriptionsInputs(newInputs);
  };

  const handleAddDescriptionsInput = () => {
    if (descriptionsInputs.length < 5) {
      setDescriptionsInputs([...descriptionsInputs, ""]);
    }
  };

  const handleRemoveDescriptionsInput = (indexToRemove) => {
    const updatedInputs = descriptionsInputs.filter((_, index) => index !== indexToRemove);
    setDescriptionsInputs(updatedInputs);
  };

  useEffect(() => {
    setFieldValue("headlines", headlinesInputs);
  }, [headlinesInputs]);

  useEffect(() => {
    setFieldValue("descriptions", descriptionsInputs);
  }, [descriptionsInputs]);

  console.log('headliness', headlinesInputs)
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "  Ad Name - max 512 characters",
                      }}
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />

                    <CircularProgress
                      max={facebookVideoAdValueLengths.adName}
                      value={values.adName.length}
                      color={
                        values.adName.length >
                        facebookVideoAdValueLengths.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.adName.length >
                        facebookVideoAdValueLengths.adName
                          ? facebookVideoAdValueLengths.adName -
                            values.adName.length
                          : values.adName.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Headlines</FormLabel>
                {Array.isArray(headlinesInputs) && headlinesInputs.map((text, index) => {
                  return (
                      <FormControl key={index} mb={2}>
                        <div className="input-box">
                          <Input
                              id={`headlines${index}`}
                              name={`headlines${index}`}
                              inputProps={{
                                variant: "outline",
                                type: "text",
                                placeholder: `Headlines ${index + 1} - max 40 characters`
                              }}
                              placeholder={`Headlines ${index + 1} - max 40 characters`}
                              onChange={(e) => handleHeadlinesChange(e, index)}
                              value={text}
                          />
                          <CircularProgress
                              max={demandGenAdValueLengths?.headlines}
                              value={text.length}
                              color={
                                text.length > demandGenAdValueLengths?.headlines
                                    ? "red.400"
                                    : "blue.400"
                              }
                          >
                            <CircularProgressLabel>
                              {text.length > demandGenAdValueLengths?.headlines
                                  ? demandGenAdValueLengths?.headlines - text.length
                                  : text.length}
                            </CircularProgressLabel>
                          </CircularProgress>
                          {headlinesInputs.length > 1 && (
                              <Button onClick={() => handleRemoveHeadlinesInput(index)} ml={2}>
                                -
                              </Button>
                          )}
                        </div>
                      </FormControl>
                  )})}
                {headlinesInputs.length < 5 && (
                    <Button onClick={handleAddHeadlinesInput} ml={2}>
                      +
                    </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Descriptions</FormLabel>
                {Array.isArray(descriptionsInputs) && descriptionsInputs.map((text, index) => {
                  return (
                      <FormControl key={index} mb={2}>
                        <div className="input-box">
                          <Input
                              id={`descriptions${index}`}
                              name={`descriptions${index}`}
                              inputProps={{
                                variant: "outline",
                                type: "text",
                                placeholder: `Descriptions ${index + 1} - max 90 characters`
                              }}
                              placeholder={`Descriptions ${index + 1} - max 90 characters`}
                              onChange={(e) => handleDescriptionsChange(e, index)}
                              value={text}
                          />
                          <CircularProgress
                              max={demandGenAdValueLengths?.descriptions}
                              value={text.length}
                              color={
                                text.length > demandGenAdValueLengths?.descriptions
                                    ? "red.400"
                                    : "blue.400"
                              }
                          >
                            <CircularProgressLabel>
                              {text.length > demandGenAdValueLengths?.descriptions
                                  ? demandGenAdValueLengths?.descriptions - text.length
                                  : text.length}
                            </CircularProgressLabel>
                          </CircularProgress>
                          {descriptionsInputs.length > 1 && (
                              <Button onClick={() => handleRemoveDescriptionsInput(index)} ml={2}>
                                -
                              </Button>
                          )}
                        </div>
                      </FormControl>
                  )})}
                {descriptionsInputs.length < 5 && (
                    <Button onClick={handleAddDescriptionsInput} ml={2}>
                      +
                    </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL - max 255 characters",
                      }}
                      onChange={(e) => setFieldValue("link", e.target.value)}
                    />
                    <CircularProgress
                      max={facebookVideoAdValueLengths.landingPageURL}
                      value={values.link.length}
                      color={
                        values.link.length > facebookVideoAdValueLengths.landingPageURL
                          ? "red.400"
                          : "blue.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values.link.length > facebookVideoAdValueLengths.landingPageURL
                          ? facebookVideoAdValueLengths.landingPageURL -
                            values.link.length
                          : values.link.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="type" mb={0}>Call to Action</FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="type"
                      name="type"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("type", e.target.value);
                      }}
                    >
                      {facebookAccountIds.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
              <Button
                mr={4}
                colorScheme="green"
                type="submit"
                variant="solid"
                borderRadius={0}
                px={10}
              >
                Next
              </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default DemandGenImageAdDetails;
