import { Step, Steps, useSteps } from 'chakra-ui-steps';
import React, {useEffect, useState} from "react";
import SocialPlatform from "./StepByStepAdUploadComponent/SocialPlatform";
import AdUploadFormStepButton from "./StepByStepAdUploadComponent/AdUploadFormStepButton";
import AdMediaForm from "./StepByStepAdUploadComponent/AdMediaForm";
import AdPreview from "./StepByStepAdUploadComponent/AdPreview";
import {useDispatch, useSelector} from "react-redux";
import {Center, css, Flex, Text} from "@chakra-ui/react";
import {BiArrowBack} from "react-icons/bi";
import {useNavigate, useParams} from "react-router-dom";
import CreativeType from "./SetupCampaign/CreativeType";
import {useAdUploadList} from "../../../../hooks/campaign-briefs/useAdUploadList";
import {deleteTheField, handleAllCampaignIds} from "../../../../store/adUploads";

function SelectAdType({SetTabIndex}) {
    const navigate = useNavigate()
    const {id} = useParams()
    const dispatch = useDispatch()
    const { nextStep, prevStep, reset, activeStep, setStep } = useSteps({
        initialStep: 0,
    });
    const clientId = useSelector((state) => state.client.clientId);
    const { allCampaignIds } = useSelector((state) => state.adUpload);

    const {data, refetch} = useAdUploadList(clientId, id)

    useEffect(() => {
        refetch()
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const allIdsParam = searchParams.get('allIds');

        if (allIdsParam) {
            const campaignIds = allIdsParam.split(',');
            dispatch(handleAllCampaignIds(campaignIds))
        }
    }, []);

    const totalStep = [1,2,3,4,5];
    const totalStepForDV360YoutubeStep = [1,2,3,4];

    const {mediaType} =  useSelector((state) => state.adUpload)


    const steps = [
        { label: "Select Channel", content: <SocialPlatform activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Social Platform" /> },
        { label: "Creative Type", content: <CreativeType activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Creative Type" /> },
        // { label: "Ad Type", content: <SocialMediaPlatformType activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Type"/> },
        // { label: "Ad Details", content: <AdDetailsForm activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Details"/> },
        { label: "Creative Media", content: <AdMediaForm activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Media" /> },
        { label: "Preview", content: <AdPreview  activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Ad Media" />},
    ];

    const DV360YoutubeStep = [
        { label: "Social Platform", content: <SocialPlatform activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Social Platform" /> },
        { label: "Creative Type", content: <CreativeType activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Creative Type" /> },
        // { label: "Ad Details", content: <AdDetailsForm activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Details"/> },
        { label: "Preview", content: <AdPreview   activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Media" />},
        // { label: "Empty", content: <h1>Completed</h1>},
    ]

    const Google = [
        { label: "Social Platform", content: <SocialPlatform activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Social Platform" /> },
        // { label: "Ad Type", content: <SocialMediaPlatformType activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Type"/> },
        // { label: "Ad Details", content: <AdDetailsForm  activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Details"/> },
        { label: "Creative Type", content: <CreativeType activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Creative Type" /> },
        // { label: "Empty", content: <h1>Completed</h1>},

        // { label: "Client Detail 5", content: <AdPreview SetTabIndex={SetTabIndex}  activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Ad Media" />},
    ]

    const Microsoft = [
        { label: "Social Platform", content: <SocialPlatform activeStep={activeStep} steps={totalStepForDV360YoutubeStep} prevStep={prevStep} nextStep={nextStep} label="Social Platform" /> },
        { label: "Creative Type", content: <CreativeType activeStep={activeStep} steps={totalStep} prevStep={prevStep} nextStep={nextStep} label="Creative Type" /> },
    ]

    const [initialStep, setInitialStep] = useState(steps);


    useEffect(() => {

        if(mediaType === "DV360_YOUTUBE_VIDEO" || mediaType === "YOUTUBE_SHORTS_VIDEO" || mediaType === "DEMAND_GEN_VIDEO") {
            setInitialStep(DV360YoutubeStep)
        } else if(mediaType === "display ads") {
            setInitialStep(Google)
        }  else if(mediaType === "MICROSOFT") {
            setInitialStep(Microsoft)
        } else {
            setInitialStep(steps)
        }

    }, [mediaType])

    function handleClick(){
        if(allCampaignIds?.length){
            navigate(`/campaign-briefs`)
        }else {
            data?.adUploads?.length !== 0 ? navigate(`/campaign-briefs/${id}/ad-upload`) : navigate(`/campaign-briefs`)
        }
        dispatch(deleteTheField())
        dispatch(handleAllCampaignIds(null))
    }

    return (
        <>
            <div className="form-step-main " style={{width: "95%"}} >
                <Flex
                    mt={{base: "0", md: "2"}}
                    mb={5}
                    style={{transform: "translateX(-1.5rem)"}}
                    onClick={handleClick}
                    cursor={"pointer"}
                    px="8"
                >
                    <Center marginRight={2}>
                        <BiArrowBack size={16}/>
                    </Center>
                    <Center>
                        <Text
                            css={css({
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "14px",
                            })}
                        >
                            Back to Creatives
                        </Text>
                    </Center>
                </Flex>
                <Steps activeStep={activeStep} mt={16}>
                    {initialStep.map(({ label, content }) => {
                            return (
                                <Step key={label} label={label} pb={5} >
                                    {content}
                                    <AdUploadFormStepButton activeStep={activeStep} steps={steps} prevStep={prevStep} nextStep={nextStep} label={label} />
                                </Step>
                            )
                        }
                    )}
                </Steps>
            </div>
        </>
    )
}

export default SelectAdType
