import React from 'react';
import FullPageLoader from "../../../../../../../components/FullPageLoader";
import {
  Box, Circle,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text
} from "@chakra-ui/react";
import {PREVIEW_BG_COLOR} from "../../../../../../../layout/constant/MenuList";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {youtubeShortsTypes} from "../../../../../constant/SelectValues";
import {useSelector} from "react-redux";

function GmailIn({formDetails, ads, handleIndex, businessName, handleGmailState}) {
  const {handlePreviewData} = useSelector((state) => state.performanceMaxAd)

  console.log(`handleOre`, handlePreviewData)

  const selectedLogos = (Array.isArray(handlePreviewData?.selectedLogos) && handlePreviewData?.selectedLogos[0]?.imageUrl) ?
      (handlePreviewData?.selectedLogos[0]?.imageUrl) :
      process.env.PUBLIC_URL + "/images/display.png";

  return (
    <>
      <Box backgroundColor='gray.300' borderBottom='1px #e3e8f1 solid' textAlign='center'
           borderTopRadius="25px"
           alignSelf="center">
        <Flex justifyItems="center" px={3} justifyContent="space-between" py={5} backgroundColor="white"
              borderTopRadius="25">
          <Flex alignItems="center" justifyContent="center">
            <Image src={`${process.env.PUBLIC_URL}/images/back_icon.png`} alt="Back Button" height={6}
                   width={8} onClick={() => handleGmailState()} cursor="pointer"/>
          </Flex>
          <Flex alignItems="center" justifyContent="center" gap={5}>
            <Image src={`${process.env.PUBLIC_URL}/images/ad_icon.svg`} alt="Back Button" height={6}
                   width={6}/>
            <Image src={`${process.env.PUBLIC_URL}/images/star_icon.png`} alt="Back Button" height={6}
                   width={6}/>
            <Image src={`${process.env.PUBLIC_URL}/images/garbage_icon.png`} alt="Back Button" height={5}
                   width={5}/>
            <Image src={`${process.env.PUBLIC_URL}/images/three_dots_icon.png`} alt="Back Button" height={5}
                   width={5}/>
          </Flex>
        </Flex>
      </Box>
      <Box height="90%" pb={6} boxShadow='sm' bg='white' overflowY="scroll" borderBottomRadius="25px">
        <Box display="flex" gap="5" p={6} height="20%">
          <Image src={selectedLogos} height={12}
                 width={12} rounded="full"/>
          <Box>
            <Text fontWeight="500">{businessName}</Text>
            <Text fontSize={'small'}>To me</Text>
          </Box>
        </Box>
        <Box mt={2} height="50%">
          {(ads && ads.length !== 0) ?
            <Box overflow="hidden" width={"100%"} height={"100%"}>
              <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                      onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                {
                  ads.map((item) => {
                    return (
                      <SwiperSlide>
                        <Image
                          height="100%"
                          width={"100%"}
                          objectFit={"fill"}
                          src={item?.imageUrl}
                          className="previewImage"
                        />
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
          }
        </Box>
        <Box mx={8} my={4} fontWeight="600"> {formDetails.headlines[0]} </Box>
        <Box mx={8} mb={4} fontSize="14px"> {formDetails.descriptions[0]} </Box>
        <Box bg="#3b6dc8" textColor="white" textAlign="center" borderRadius="25px" mx={4} py={1} mb={4}>
          <button>{youtubeShortsTypes.find((item) => item.key === formDetails?.type)?.name}</button>
        </Box>
      </Box>
    </>
  );
}

export default GmailIn;
