import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Datatable from "../../components/Datatable";
import { useGetClientList } from "../../hooks/clients/useGetClientList";
import Actions from "./components/Actions";
import "../../pages/CampaignBriefs/style/AdUploadList.css";
import { TEXT_COLOR } from "../../layout/constant/MenuList";
import { IoMdAddCircle } from "react-icons/io";
import UserTableNameWithProfile from "../Users/components/UserTableNameWithProfile";
import { BsThreeDotsVertical } from "react-icons/bs";
import SearchAndButton from "../../components/SearchAndButton";
import instance from "../../helpers/axios";
import DeleteModel from "../../components/PopupModal/DeleteModel";

const Clients = () => {
  const { data, refetch } = useGetClientList();
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [clientName, setClientName] = useState(null);
  const [deletableId, setDeletableId] = useState(null);

  function handleDeleteModel(data) {
     setDeletableId(data.id);
     setDeleteModal(true);
     setClientName(data.name);
  }

    function onDestroyModel() {
        setDeleteModal(false);
    }

    function onCloseDeleteModal() {
        return instance({
            method: 'DELETE',
            url: `/client/${deletableId}`,
        })
            .then((res) => {
                if (res.status === 200) {
                    refetch();
                    setDeleteModal(false);
                } else {
                    throw new Error('Not authorized')
                }
            })
            .catch((err) => {
                console.log(err.response)
                throw err
            })
    }

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setClients(data?.clients);
  }, [data]);

  useEffect(() => {
    if (search?.trim()) {
      const searchedClients = data?.clients.filter((el) => {
        if (el?.description?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (el?.name?.toLowerCase().includes(search.trim())) {
          return true;
        } else if (
          el?.detail?.industry?.toLowerCase().includes(search.trim())
        ) {
          return true;
        }
      });
      setClients((prevClients) => searchedClients);
    } else {
      setClients((prevClients) => data?.clients || prevClients);
    }
  }, [data, search]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: (data) => <UserTableNameWithProfile data={data} />,
      },
      {
        Header: "Industry",
        accessor: "detail.industry",
      },
      {
        Header: "State",
        accessor: "state",
        Cell: (data) => {
          return (
            <>
              <Icon
                viewBox="0 0 200 200"
                mr={2}
                color={
                  data.row.original.state === "ACTIVE"
                    ? "#3F7EE6"
                    : data.row.original.state === "INACTIVE"
                    ? "#B5B7C8"
                    : data.row.original.state === "ON_HOLD"
                    ? "#59AB9E"
                    : "#3F7EE6"
                }
              >
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
              {data.row.original.state}
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: (data) => {
          return (
            <VStack justify="end" maxW="20px">
              <Menu>
                <MenuButton as={IconButton} variant="ghost" size="sm">
                  <Icon as={BsThreeDotsVertical} />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={Link}
                    to={`/client/${data.row.original.id}`}
                    aria-label="View details"
                  >
                    View/Edit
                  </MenuItem>
                  <MenuItem onClick={() => handleDeleteModel(data.row.original)}>Delete</MenuItem>
                </MenuList>
              </Menu>
            </VStack>
          );
        },
      },
    ],
    [search, data]
  );

    console.log('clients', clients)

  return (
    <div className="ad-upload-list">
      <DeleteModel
          heading={"Delete Client"}
          name={clientName}
          isOpen={isDeleteModalOpen}
          onClose={() => onCloseDeleteModal()}
          onDestory={() => onDestroyModel()}
      />
      <Heading
        color={TEXT_COLOR}
        fontWeight="500"
        fontSize={{
          base: "xl",
          md: "3xl",
        }}
        my={5}
        mb={7}
      >
        Clients
      </Heading>
      <SearchAndButton
        buttonText="Add Client"
        btnClick={() => navigate("/client/new")}
        setSearch={(e) => setSearch(e.target.value)}
      />

      <Divider />
      <Datatable data={clients || []} columns={columns} />
    </div>
  );
};

export default Clients;
