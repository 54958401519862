// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-main{
    display: grid!important;
    grid-template-columns: 40% 60%!important;
}

@media screen and (max-width: 992px) {
    .upload-main{
        grid-template-columns: 100%!important;
    }
}

.upload-main div[role="presentation"] {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.upload-main div[role="presentation"] > div > img {
    margin: 0 auto!important;
}

.image-listing{
    padding: 20px;
}

.file-box{
    padding: 10px;
    margin: 10px 0;
    font-size: 12px;
    border-radius: 10px;
    border: 2px solid rgba(167, 169, 189, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/DV360/dv360style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wCAAwC;AAC5C;;AAEA;IACI;QACI,qCAAqC;IACzC;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,0CAA0C;AAC9C","sourcesContent":[".upload-main{\n    display: grid!important;\n    grid-template-columns: 40% 60%!important;\n}\n\n@media screen and (max-width: 992px) {\n    .upload-main{\n        grid-template-columns: 100%!important;\n    }\n}\n\n.upload-main div[role=\"presentation\"] {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n}\n\n.upload-main div[role=\"presentation\"] > div > img {\n    margin: 0 auto!important;\n}\n\n.image-listing{\n    padding: 20px;\n}\n\n.file-box{\n    padding: 10px;\n    margin: 10px 0;\n    font-size: 12px;\n    border-radius: 10px;\n    border: 2px solid rgba(167, 169, 189, 0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
