import {combineReducers, configureStore } from "@reduxjs/toolkit";
import {clientReducer} from "./client";
import {campaignReducer} from "./campaign";
import { adUploadReducer } from "./adUploads";
import fullScreenLoader from "./Loading/index";
import {dv360YoutubeVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/DV360/DV360YoutubeVideo/store";
import {dv360VideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/DV360/DV360Video/store";
import {pinterestImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Pintrest/PinterestImage/store";
import {pinterestVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Pintrest/PinterestVideo/store";
import {tiktokVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Tiktok/TiktokVideo/store";
import {dv360AudioReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/DV360/DV360Audio/store";
import {pinterestCarouselReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Pintrest/PinterestCarousel/store";
import {facebookImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Facebook/FacebookImage/store";
import {instagramCarouselReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Instagram/InstagramCarousel/store";
import {instagramImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Instagram/InstagramImage/store";
import {facebookCarouselReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Facebook/FacebookCarousel/store";
import {facebookVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Facebook/FacebookVideo/store";
import {instagramVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Instagram/InstagramVideo/store";
import {adCampaignReducer} from "./adCampaign";
import {metaCarouselReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Meta/MetaCarousel/store";
import {metaImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Meta/MetaImage/store";
import {metaVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Meta/MetaVideo/store";
import {dynamicFacebookImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Facebook/DynamicFBImage/store";
import {dynamicFacebookVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Facebook/DynamicFacebookVideo/store";
import {
    dynamicFacebookBothReducer
} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Facebook/DynamicFacebookBoth/store";
import {dynamicInstagramImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Instagram/DynamicInstagramImage/store";
import {dynamicInstagramVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Instagram/DynamicInstagramVideo/store";
import {dynamicInstagramBothReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Instagram/DynamicInstagramBoth/store";
import {youtubeShortsReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Youtube/YoutubeShorts/store";
import {demandGenImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/DemandGen/DemandGenImage/store";
import {demandGenVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/DemandGen/DemandGenVideo/store";
import {demandGenCarouselReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/DemandGen/DemandGenCarousel/store";
import {performanceMaxReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Performance/store";
import {socialDisplayImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/SocialDisplay/SocialDisplayImage/store";
import {socialDisplayVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/SocialDisplay/SocialDisplayVideo/store";
import {audioReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Audio/store";
import {linkedInImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/LinkedIn/LinkedinImage/store";
import {linkedInVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/LinkedIn/LinkedinVideo/store";
import {currentClientDetailReducer} from "../pages/AI/store";
import {
    discoveryReducer
} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Discovery/store";
import {snapchatVideoReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Snapchat/SnapchatVideo/store";
import {snapchatImageReducer} from "../pages/CampaignBriefs/components/AdUpload/StepByStepAdUploadComponent/Snapchat/SnapchatImage/store";


const combinedReducer = combineReducers({
    client: clientReducer,
    campaign: campaignReducer,
    fullScreenLoader,
    adUpload: adUploadReducer,
    dv360Video: dv360VideoReducer,
    dv360YoutubeVideo: dv360YoutubeVideoReducer,
    pinterestImageAd : pinterestImageReducer,
    pinterestVideoAd : pinterestVideoReducer,
    tiktokVideoAd : tiktokVideoReducer,
    snapchatVideoAd : snapchatVideoReducer,
    snapchatImageAd : snapchatImageReducer,
    dv360Audio: dv360AudioReducer,
    pinterestCarouselAd : pinterestCarouselReducer,
    instagramCarouselAd : instagramCarouselReducer,
    instagramImageAd : instagramImageReducer,
    instagramVideoAd : instagramVideoReducer,
    dynamicInstagramImageAd : dynamicInstagramImageReducer,
    demandGenImageAd : demandGenImageReducer,
    demandGenVideoAd : demandGenVideoReducer,
    demandGenCarouselAd : demandGenCarouselReducer,
    dynamicInstagramVideoAd : dynamicInstagramVideoReducer,
    dynamicInstagramBothAd : dynamicInstagramBothReducer,
    metaCarouselAd : metaCarouselReducer,
    metaImageAd : metaImageReducer,
    metaVideoAd : metaVideoReducer,
    facebookImageAd : facebookImageReducer,
    dynamicFacebookImageAd : dynamicFacebookImageReducer,
    facebookCarouselAd: facebookCarouselReducer,
    facebookVideoAd : facebookVideoReducer,
    dynamicFacebookVideoAd : dynamicFacebookVideoReducer,
    dynamicFacebookBothAd : dynamicFacebookBothReducer,
    adCampaign: adCampaignReducer,
    youtubeShortsAd: youtubeShortsReducer,
    performanceMaxAd: performanceMaxReducer,
    socialDisplayImageAd: socialDisplayImageReducer,
    socialDisplayVideoAd: socialDisplayVideoReducer,
    audioAd: audioReducer,
    linkedInImageAd: linkedInImageReducer,
    linkedInVideoAd: linkedInVideoReducer,
    currentClientDetail: currentClientDetailReducer,
    discoveryAd: discoveryReducer,
});

const rootReducer = (state, action) => {
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
