import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  css,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading, Text,
} from "@chakra-ui/react";
import {InputControl, SelectControl} from "formik-chakra-ui";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import {
  settingPerformanceMaxForm, settingPerformanceMaxMedia
} from "./store";
import {Input} from "@chakra-ui/input";
import {CloseIcon} from "@chakra-ui/icons";
import {
  performanceMaxAdValueLengths,
  youtubeShortsValueLengths
} from "../../../../constant/InitialValues";
import WithAppContext from "../../../../../../helpers/appContext";
import {useGetAdUpload} from "../../../../../../hooks/campaign-briefs/useGetAdUpload";
import TooltipComponent from "../TooltipComponent";
import {youtubeShortsTypes} from "../../../../constant/SelectValues";

const initialValue = {
  adName: "",
  displayName: [],
  descriptions: [""],
  headlines: [""],
  long_headlines: [""],
  link: "",
  type: "",
  channel_name: "",
};

function PerformanceMaxAdDetails({
                                  activeStep,
                                  steps,
                                  prevStep,
                                  nextStep,
                                  label,
                                  autoFillData,
                                  context
                                }) {
  const { id, adUploadId } = useParams();

  const clientId = useSelector((state) => state.client.clientId);
  const { formDetails } = useSelector((state) => state.performanceMaxAd);

  const { data, refetch } = useGetAdUpload(clientId, id, adUploadId);
  const [youtubeVideoLinkInputs, setYoutubeVideoLinkInputs] = useState([""]);
  const [longHeadlineInputs, setLongHeadlineInputs] = useState([""]);
  const [headlineInputs, setHeadlineInputs] = useState(["", "", ""]);
  const [descriptionInputs, setDescriptionInputs] = useState(["", ""]);


  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (adUploadId) {
      dispatch(
        settingPerformanceMaxForm({
          ...data?.adUpload?.detail,
          adName: data?.adUpload?.detail?.name,
          descriptions: data?.adUpload?.detail?.descriptions,
          headlines: data?.adUpload?.detail?.headlines,
          long_headlines: data?.adUpload?.detail?.long_headlines,
          link: data?.adUpload?.detail?.call_to_action.link,
          type: data?.adUpload?.detail?.call_to_action?.type,
          youtubeVideoLink: data?.adUpload?.detail?.youtubeVideoLink,
          channel_name: data?.adUpload?.detail?.channel_name,
        })
      );
      dispatch(
          settingPerformanceMaxMedia({
            selectedSquareImages: data?.adUpload?.detail?.selectedSquareImages,
            selectedImages: data?.adUpload?.detail?.selectedImages,
            selectedLogos: data?.adUpload?.detail?.selectedLogos,
          })
      )
    }
  }, [data]);

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    adName: yup
      .string()
      .trim()
      .min(4)
      .max(255)
      .required()
      .matches(
        /^[!@$%^&#()-=+{};:,<.>0-9a-zA-Z\s]+$/i,
        "Emojis are not allowed as ad Name"
      )
      .label("Ad name"),
    headlines: yup
      .array()
      .of(
        yup.string().trim().min(3).max(30).required().label("Headline")
      )
      .min(3, "At least 3 headlines are required")
      .max(5, "Maximum of 5 headlines allowed")
      .test(
          'at-least-one-short-headline',
          'At least one headline should have a maximum of 15 characters',
          (headlines) => headlines.some((headline) => headline?.length <= 15)
      )
      .test(
          'unique-headlines',
          'All headlines should be unique',
          (headlines) => {
            const uniqueHeadlines = new Set(headlines);
            return uniqueHeadlines?.size === headlines?.length;
          }
      )
      .required()
      .label("Headline"),
    channel_name: yup.string()
      .trim()
      .max(25)
      .required()
      .label("Channel Name"),
    long_headlines: yup
      .array()
      .of(
        yup.string().trim().min(3).max(90).required().label("Long Headline")
      )
      .min(1, "At least one long headline is required")
      .max(5, "Maximum of 5 long headlines allowed")
      .test(
          'unique-long-headlines',
          'All long headlines should be unique',
          (longHeadlines) => {
            const uniqueLongHeadlines = new Set(longHeadlines);
            return uniqueLongHeadlines?.size === longHeadlines?.length;
          }
      )
      .required()
      .label("Long Headline"),
    descriptions: yup
      .array()
      .of(
        yup.string().trim().min(3).max(90).required().label("Description")
      )
      .min(2, "At least 2 descriptions are required")
      .max(5, "Maximum of 5 descriptions allowed")
      .test(
          'at-least-one-short-description',
          'At least one description should have a maximum of 60 characters',
          (descriptions) => descriptions.some((description) => description?.length <= 60)
      )
      .test(
          'unique-descriptions',
          'All descriptions should be unique',
          (descriptions) => {
            const uniqueDescriptions = new Set(descriptions);
            return uniqueDescriptions?.size === descriptions?.length;
          }
      )
      .required()
      .label("Description"),
    link: yup
      .string()
      .trim()
      .required("Landing page url is required.")
      .max(255)
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
        "Landing page url is not valid!"
      ).label('Landing page url'),
    youtubeVideoLink: yup
      .array()
      .of(
        yup.string().trim().matches(
            /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=)?[A-Za-z0-9_-]{11}$/,
            "Enter a valid Youtube video link")
            .label("Youtube Video Link")
        // yup.string().trim().min(3).max(125).required().label("Youtube Video Link")
      )
      // .min(1, "At least one youtube video link is required")
      // .max(5, "Maximum of 5 youtube video links allowed")
      // .required()
      .test(
          'unique-youtube-video-links',
          'All youtube video links should be unique',
          (youtubeVideo) => {
            const uniqueYoutubeVideo = new Set(youtubeVideo);
            return uniqueYoutubeVideo?.size === youtubeVideo?.length;
          }
      )
      .label("Youtube Video Links"),
    type: yup.string().max(125).optional().label("Call to action"),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
        dispatch(
          settingPerformanceMaxForm({
            ...data?.adUpload?.detail,
            ...values,
          })
        );
      nextStep();
    },
  });

  const { errors, values, touched, handleSubmit, isValid, setFieldValue } = formik;

  console.log('Errors', errors)

  useEffect(() => {
    setFieldValue("adName", formDetails.adName || autoFillData?.name || "");
    setFieldValue("headlines", formDetails.headlines || autoFillData?.headlines || [""]);
    setFieldValue("long_headlines", formDetails.long_headlines || autoFillData?.long_headlines || [""]);
    setFieldValue("descriptions", formDetails.descriptions || autoFillData?.descriptions || [""]);
    setFieldValue("youtubeVideoLink", formDetails.youtubeVideoLink || autoFillData?.youtubeVideoLink || [""]);
    setFieldValue("link", formDetails.link || autoFillData?.link || "");
    setFieldValue("channel_name", formDetails.channel_name || autoFillData?.channel_name || "");
    setFieldValue("type", formDetails.type || autoFillData?.type || "");
    setYoutubeVideoLinkInputs(formDetails.youtubeVideoLink || autoFillData?.youtubeVideoLink || [""])
    setDescriptionInputs(formDetails.descriptions || ["", ""])
    setHeadlineInputs(formDetails.headlines || ["", "", ""])
    setLongHeadlineInputs(formDetails.long_headlines || [""])
  }, [formDetails, autoFillData]);

  const handleYoutubeVideoLinkChange = (e, index) => {
    const newInputs = [...youtubeVideoLinkInputs];
    newInputs[index] = e.target.value;
    setYoutubeVideoLinkInputs(newInputs);
  };

  const handleAddYoutubeVideoLinkInput = () => {
    if (youtubeVideoLinkInputs.length < 5) {
      setYoutubeVideoLinkInputs([...youtubeVideoLinkInputs, ""]);
    }
  };

  const handleRemoveYoutubeVideoLinkInput = (indexToRemove) => {
    const updatedInputs = youtubeVideoLinkInputs.filter((_, index) => index !== indexToRemove);
    setYoutubeVideoLinkInputs(updatedInputs);
    setYoutubeVideoLinkInputs(updatedInputs)
  };

  const handleLongHeadlineChange = (e, index) => {
    const newInputs = [...longHeadlineInputs];
    newInputs[index] = e.target.value;
    setLongHeadlineInputs(newInputs);
  };

  const handleAddLongHeadlineInput = () => {
    if (longHeadlineInputs.length < 5) {
      setLongHeadlineInputs([...longHeadlineInputs, ""]);
    }
  };

  const handleRemoveLongHeadlineInput = (indexToRemove) => {
    const updatedInputs = longHeadlineInputs.filter((_, index) => index !== indexToRemove);
    setLongHeadlineInputs(updatedInputs);
  };

  const handleHeadlineChange = (e, index) => {
    const newInputs = [...headlineInputs];
    newInputs[index] = e.target.value;
    setHeadlineInputs(newInputs);
  };

  const handleAddHeadlineInput = () => {
    if (headlineInputs.length < 5) {
      setHeadlineInputs([...headlineInputs, ""]);
    }
  };

  const handleRemoveHeadlineInput = (indexToRemove) => {
    if (headlineInputs.length > 3) {
      const updatedInputs = headlineInputs.filter((_, index) => index !== indexToRemove);
      setHeadlineInputs(updatedInputs);
    }
  };

  const handleDescriptionChange = (e, index) => {
    const newInputs = [...descriptionInputs];
    newInputs[index] = e.target.value;
    setDescriptionInputs(newInputs);
  };

  const handleAddDescriptionInput = () => {
    if (descriptionInputs.length < 5) {
      setDescriptionInputs([...descriptionInputs, ""]);
    }
  };

  const handleRemoveDescriptionInput = (indexToRemove) => {
    if (headlineInputs.length > 2) {
      const updatedInputs = descriptionInputs.filter((_, index) => index !== indexToRemove);
      setDescriptionInputs(updatedInputs);
    }
  };

  useEffect(() => {
    setFieldValue("long_headlines", longHeadlineInputs);
  }, [longHeadlineInputs]);

  useEffect(() => {
    setFieldValue("headlines", headlineInputs);
  }, [headlineInputs]);

  useEffect(() => {
    setFieldValue("descriptions", descriptionInputs);
  }, [descriptionInputs]);

  useEffect(() => {
    setFieldValue("youtubeVideoLink", youtubeVideoLinkInputs);
  }, [youtubeVideoLinkInputs]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{ base: 6, lg: 4 }}>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="adName" mb={0}>Ad Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="adName"
                      name="adName"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Ad Name - max 255 characters",
                      }}
                      onChange={(e) => setFieldValue("adName", e.target.value)}
                    />
                    <TooltipComponent
                      text={
                        "The ad name helps you distinguish between ads and won’t appear in the final ad. Pick something memorable but relevant to your ad."
                      }
                    />
                    <CircularProgress
                      max={performanceMaxAdValueLengths.adName}
                      value={values?.adName?.length}
                      color={
                        values?.adName?.length >
                        performanceMaxAdValueLengths?.adName
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.adName?.length >
                        performanceMaxAdValueLengths?.adName
                          ? performanceMaxAdValueLengths?.adName -
                          values?.adName?.length
                          : values?.adName?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Headlines</FormLabel>
                {headlineInputs && headlineInputs?.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`headlines${index}`}
                          name={`headlines${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Headline ${index + 1} - max 30 characters`
                          }}
                          placeholder={`Headline ${index + 1} - max 30 characters`}
                          onChange={(e) => handleHeadlineChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={index !== 0 ? performanceMaxAdValueLengths?.headlines : 15}
                          value={text.length}
                          color={
                            index !== 0 ? text.length > performanceMaxAdValueLengths?.headlines ? "red.400" : "blue.400"
                                        : text.length > 15 ? "red.400" : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            {index !== 0 ? text.length > performanceMaxAdValueLengths?.headlines ? performanceMaxAdValueLengths?.headlines - text.length : text.length
                                         : text.length > 15 ? 15 - text.length : text.length
                            }
                          </CircularProgressLabel>
                        </CircularProgress>
                        {headlineInputs.length > 3 && (
                          <Button onClick={() => handleRemoveHeadlineInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )
                })}
                {headlineInputs.length < 5 && (
                  <Button onClick={handleAddHeadlineInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Descriptions</FormLabel>
                {descriptionInputs && descriptionInputs.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`descriptions${index}`}
                          name={`descriptions${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Description ${index + 1} - max 125 characters`
                          }}
                          placeholder={`Description ${index + 1} - max 125 characters`}
                          onChange={(e) => handleDescriptionChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={ index !==0 ? performanceMaxAdValueLengths?.descriptions : 60}
                          value={text.length}
                          color={ index !== 0 ? text.length > performanceMaxAdValueLengths?.descriptions ? "red.400" : "blue.400"
                                              : text.length > 60 ? "red.400" : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            { index !== 0 ? text.length > performanceMaxAdValueLengths?.descriptions ? performanceMaxAdValueLengths?.descriptions - text.length : text.length
                                          : text.length > 60 ? 60 - text.length : text.length
                            }
                          </CircularProgressLabel>
                        </CircularProgress>
                        {descriptionInputs.length > 2 && (
                          <Button onClick={() => handleRemoveDescriptionInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )
                })}
                {descriptionInputs.length < 5 && (
                  <Button onClick={handleAddDescriptionInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Long Headlines</FormLabel>
                {longHeadlineInputs && Array.isArray(longHeadlineInputs) && longHeadlineInputs.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`long_headlines${index}`}
                          name={`long_headlines${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Long Headline ${index + 1} - max 125 characters`
                          }}
                          placeholder={`Long Headline ${index + 1} - max 125 characters`}
                          onChange={(e) => handleLongHeadlineChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={performanceMaxAdValueLengths?.long_headlines}
                          value={text.length}
                          color={
                            text.length > performanceMaxAdValueLengths?.long_headlines
                              ? "red.400"
                              : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            {text.length > performanceMaxAdValueLengths?.long_headlines
                              ? performanceMaxAdValueLengths?.long_headlines - text.length
                              : text.length}
                          </CircularProgressLabel>
                        </CircularProgress>
                        {longHeadlineInputs.length > 1 && (
                          <Button onClick={() => handleRemoveLongHeadlineInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )
                })}
                {longHeadlineInputs.length < 5 && (
                  <Button onClick={handleAddLongHeadlineInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormLabel mb={0}>Youtube Video Links</FormLabel>
                {youtubeVideoLinkInputs && youtubeVideoLinkInputs.map((text, index) => {
                  return (
                    <FormControl key={index} mb={2}>
                      <div className="input-box">
                        <Input
                          id={`youtubeVideoLink${index}`}
                          name={`youtubeVideoLink${index}`}
                          inputProps={{
                            variant: "outline",
                            type: "text",
                            placeholder: `Youtube Video Link ${index + 1} - max 125 characters`
                          }}
                          placeholder={`Youtube Video Link ${index + 1} - max 125 characters`}
                          onChange={(e) => handleYoutubeVideoLinkChange(e, index)}
                          value={text}
                        />
                        <CircularProgress
                          max={performanceMaxAdValueLengths?.youtubeVideoLink}
                          value={text.length}
                          color={
                            text.length > performanceMaxAdValueLengths?.youtubeVideoLink
                              ? "red.400"
                              : "blue.400"
                          }
                        >
                          <CircularProgressLabel>
                            {text.length > performanceMaxAdValueLengths?.youtubeVideoLink
                              ? performanceMaxAdValueLengths?.youtubeVideoLink - text.length
                              : text.length}
                          </CircularProgressLabel>
                        </CircularProgress>
                        {youtubeVideoLinkInputs.length > 1 && (
                          <Button onClick={() => handleRemoveYoutubeVideoLinkInput(index)} ml={2}>
                            -
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  )})}
                {youtubeVideoLinkInputs.length < 5 && (
                  <Button onClick={handleAddYoutubeVideoLinkInput} ml={2}>
                    +
                  </Button>
                )}
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="channel_name" mb={0}>Channel Name</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="channel_name"
                      name="channel_name"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Channel Name - max 90 characters",
                      }}
                      onChange={(e) =>
                        setFieldValue("channel_name", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={performanceMaxAdValueLengths?.channel_name}
                      value={values?.channel_name?.length}
                      color={
                        values?.channel_name?.length >
                        performanceMaxAdValueLengths?.channel_name
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.channel_name?.length >
                        performanceMaxAdValueLengths?.channel_name
                          ? performanceMaxAdValueLengths?.channel_name -
                          values?.channel_name?.length
                          : values?.channel_name?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem mb={2}>
                <FormControl>
                  <FormLabel htmlFor="link" mb={0}>Landing Page URL</FormLabel>
                  <div className="input-box">
                    <InputControl
                      id="link"
                      name="link"
                      placeholder=""
                      inputProps={{
                        variant: "outline",
                        type: "text",
                        placeholder: "Landing Page URL",
                      }}
                      onChange={(e) =>
                        setFieldValue("link", e.target.value)
                      }
                    />
                    <CircularProgress
                      max={youtubeShortsValueLengths?.link}
                      value={values?.link?.length}
                      color={
                        values?.link?.length >
                        youtubeShortsValueLengths?.link
                          ? "red.400"
                          : "green.400"
                      }
                    >
                      <CircularProgressLabel>
                        {values?.link?.length >
                        youtubeShortsValueLengths?.link
                          ? youtubeShortsValueLengths?.link -
                          values?.link?.length
                          : values?.link?.length}
                      </CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel htmlFor="type" mb={0}>Call to Action</FormLabel>
                  <div className="input-box">
                    <SelectControl
                      id="type"
                      name="type"
                      selectProps={{
                        placeholder: "-- Select One --",
                        variant: "outline",
                        border: "2px",
                        borderRadius: 0,
                        borderColor: "gray",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#757998",
                        marginRight: "100px",
                      }}
                      onChange={(e) => {
                        setFieldValue("type", e.target.value);
                      }}
                    >
                      {youtubeShortsTypes.map((el) => (
                        <option value={el.key} key={el.key}>
                          {el.name}
                        </option>
                      ))}
                    </SelectControl>
                    <CircularProgress opacity={0}>
                      <CircularProgressLabel>0</CircularProgressLabel>
                    </CircularProgress>
                  </div>
                </FormControl>
              </GridItem>
            </GridItem>
          </Grid>
          <Flex width="100%" justify="flex-end" py={10}>
            <Button
              mr={4}
              colorScheme="green"
              type="submit"
              variant="solid"
              borderRadius={0}
              px={10}
              isDisabled={Object.keys(errors).length > 0}
            >
              Next
            </Button>
          </Flex>
        </Form>
      </FormikProvider>
    </>
  );
}

export default WithAppContext(PerformanceMaxAdDetails);
