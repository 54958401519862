import React from 'react';
import {Container, Grid, GridItem, Heading, Image, Skeleton, SkeletonCircle, Spacer, Text} from "@chakra-ui/react";
import {Box, Flex} from "@chakra-ui/layout";
import {PREVIEW_BG_COLOR} from "../../../../../../../../layout/constant/MenuList";
import {range} from "lodash";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";

function YoutubeWatchMonitor(props) {
    const {formDetails, appLoading, ads, index, handleIndex, businessName} = props;
    const arr = [...range(1, 20)]

    return (
        <Grid templateColumns="repeat(6, 1fr)" gap={4} className="fb-upload">
            <GridItem w="full" colSpan={{base: 6, lg: 6}}>
                <Heading fontSize="lg" my={4} color={"#757998"}>
                    Youtube - Watch
                </Heading>
                <Box
                    borderWidth="2px"
                    borderColor="gray"
                    p={10}
                    background={PREVIEW_BG_COLOR}
                    position={"relative"}
                >
                    <Container centerContent>
                        <Box minH='600px' h='600px' minW='900px' backgroundColor='white' border='3px #e3e8f1 solid' borderRadius="25" p='4'>
                            <Box h='100%' alignSelf="center" backgroundColor='white' border='3px #e3e8f1 solid' borderRadius="25" overflow='hidden'>
                                <Box bg='' borderBottom='3px #e3e8f1 solid' p='3'>
                                    <Flex alignItems='center' gap='3'>
                                        <Image src={`${process.env.PUBLIC_URL}/images/three_line_icon.svg`} alt="Back Button" height={8}
                                               width={8} pl={2} />
                                        <Flex alignItems="center" justifyContent="center">
                                            <Image src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="YouTube-Icon" height={8}
                                                   width={8}/>
                                            <Box ml={1} fontWeight="bolder">YouTube</Box>
                                        </Flex>
                                        <Flex alignItems='center'>
                                            <Box bg='' h='30px' w='240px' border='1px #e3e8f1 solid' borderLeftRadius='7'/>
                                            <Box bg='gray.300' h='30px' w='45px' borderRightRadius='7'/>
                                        </Flex>
                                        <SkeletonCircle size="6"/>
                                        <Spacer />
                                        <SkeletonCircle size="5"/>
                                    </Flex>
                                </Box>
                                <Grid
                                    templateColumns='repeat(12, 1fr)'
                                    gap={6}
                                    overflow='hidden'
                                    p='3'
                                >
                                    <GridItem colSpan={8}>
                                        <Flex direction='column' gap='4'>
                                            <Box>
                                                <Box position='relative' display='flex' justifyContent='center' alignItems='center'>
                                                    <Skeleton height='290px' borderRadius='1' width='100%' />
                                                    <Box position='absolute'>
                                                        <img src={`${process.env.PUBLIC_URL}/images/youtube_icon.svg`} alt="" width='60px' />
                                                    </Box>
                                                </Box>
                                                <Skeleton height='15px' mt='20px' w='60%' borderRadius='4'/>
                                                <Flex mt='4'>
                                                    <Flex gap='2'>
                                                        <SkeletonCircle size="5"/>
                                                        <Flex gap='1' direction='column'>
                                                            <Skeleton height='13px' w='160px' borderRadius='4'/>
                                                            <Skeleton height='6px' w='100px' borderRadius='4'/>
                                                        </Flex>
                                                    </Flex>
                                                    <Spacer />
                                                    <Flex gap='2' alignItems='center'>
                                                        <Skeleton height='15px' w='40px' borderRadius='4'/>
                                                        <Skeleton height='15px' w='40px' borderRadius='4'/>
                                                        <SkeletonCircle size="4"/>
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                            <Box>
                                                <Skeleton height='290px' borderRadius='5' width='100%' />
                                            </Box>
                                        </Flex>
                                    </GridItem>
                                    <GridItem colSpan={4} >
                                        <Flex direction='column' gap='3'>
                                            <Flex gap='2'>
                                                {(ads && ads.length !== 0) ?
                                                    <Box overflow="hidden" width={"90px"} height={"100%"} borderRadius='5'>
                                                        <Swiper navigation={true} modules={[Navigation]} style={{height: '100%'}}
                                                                onSlideChange={(swiper) => handleIndex(swiper.activeIndex)}>
                                                            {
                                                                ads.map((item) => {
                                                                    return (
                                                                        <SwiperSlide>
                                                                            <Image
                                                                                height="90px"
                                                                                width="90px"
                                                                                objectFit={"fill"}
                                                                                src={item?.imageUrl}
                                                                                className="previewImage"
                                                                            />
                                                                        </SwiperSlide>
                                                                    )
                                                                })
                                                            }
                                                        </Swiper>
                                                    </Box> : <Box minHeight="md" flex={1} borderLeftRadius={20} overflow="hidden"/>
                                                }
                                                <Flex direction='column'>
                                                    <Box fontSize='12' fontWeight="600"> {Array.isArray(formDetails.headlines) ? formDetails.headlines[0] : formDetails.headline} </Box>
                                                    <Box fontSize="10"> {Array.isArray(formDetails.descriptions) ? formDetails.descriptions[0] : formDetails.description} </Box>
                                                    <Flex gap='1' fontSize="10"> <Text fontWeight='600'>Sponsored </Text><Text>&bull; {businessName}</Text> </Flex>
                                                    <Flex gap='2' bg='#ddf0fe' mt='1' py='1' px='2' width='85px' borderRadius='50px' fontSize='10' color="#0077ff" cursor='pointer'>
                                                        <Text as='b'>Visit site</Text>
                                                        <Image src={`${process.env.PUBLIC_URL}/images/share_icon.svg`} width="15px" />
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                            {arr.map((_, index) => (
                                                <Flex gap='2'>
                                                    <Skeleton height='90px' borderRadius='5' w='90px'/>
                                                    <Flex direction='column' gap='2'>
                                                        <Skeleton height='12px' w='30px' borderRadius='5'/>
                                                        <Skeleton height='12px' w='50px' borderRadius='5'/>
                                                        <Skeleton height='12px' w='130px' borderRadius='5'/>
                                                        <Skeleton height='12px' w='70px' borderRadius='5'/>
                                                    </Flex>
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </GridItem>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </GridItem>
        </Grid>
    );
}

export default YoutubeWatchMonitor;
