import React, {useEffect, useMemo, useState} from 'react';
import Datatable from "../../components/Datatable";
import {
    Button,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
} from "@chakra-ui/react";
import {SearchIcon} from "@chakra-ui/icons";

function AmPmNaming({ isLoading, downloadCreatives, data }) {
    const [creatives, setCreatives] = useState([])
    const [search, setSearch] = useState();

    useEffect(() => {
        setCreatives(data)
    }, [data]);

    useEffect(() => {
        if (search?.trim()) {
            console.log('Data', data)
            const searchedCreative = data.filter((el) => {
                if (el?.am_pm_naming?.email?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.am_pm_naming?.objective?.toLowerCase().includes(search.trim())) {
                    return true;
                } else if (el?.am_pm_naming?.creativeType?.toLowerCase().includes(search.trim())) {
                    return true;
                }
            });
            setCreatives(searchedCreative);
        } else {
            setCreatives(data);
        }
    }, [search]);

    const columns = useMemo(
        () => [
            {
                Header: "C.B. Name",
                accessor: "am_pm_naming.campaignBriefName",
            },
            {
                Header: "Ad Name",
                accessor: "am_pm_naming.adName",
            },
            {
                Header: "Channel",
                accessor: "am_pm_naming.channel",
            },
            {
                Header: "Device",
                accessor: "am_pm_naming.device",
            },
            {
                Header: "Landing Page",
                accessor: "am_pm_naming.landingPage",
            },
            {
                Header: "Targeting Method",
                accessor: "am_pm_naming.targetingMethod",
            },
            {
                Header: "Location",
                accessor: "am_pm_naming.location",
            },
            {
                Header: "Audience",
                accessor: "am_pm_naming.audience",
            },
            {
                Header: "Timestamp",
                accessor: "am_pm_naming.timestamp",
            },
            {
                Header: "Creative Type",
                accessor: "am_pm_naming.creativeType",
            },
            {
                Header: "Promotion",
                accessor: "am_pm_naming.promotion",
            },
            {
                Header: "Flight Start Date",
                accessor: "am_pm_naming.flightStartDate",
            },
            {
                Header: "Flight End Date",
                accessor: "am_pm_naming.flightEndDate",
            },
            {
                Header: "Task Name",
                accessor: "am_pm_naming.taskName",
            },
            {
                Header: "File Name",
                accessor: "am_pm_naming.fileName",
            },
        ],
        [data]
    );

    return (
        <div>
            <Heading
                color="#757998"
                fontWeight="600"
                fontFamily="Lora"
                size="lg"
                my={5}
            >
                Naming Convention
            </Heading>
            <div className="search scrollable">
                <Stack direction="row" justifyContent="space-between" spacing={4} mt={6} mb={5}>
                    <InputGroup >
                        <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.300" ml={8}/>}
                        />
                        <Input
                            ml={2}
                            borderRadius={4}
                            name="search"
                            type="tel"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </InputGroup>
                    <Button style={{minWidth: "inherit"}}
                            color={"brand.primary"}
                            borderRadius={6}
                            variant={'outlined'}
                            borderColor={'brand.primary'}
                            borderWidth={2}
                            disabled={isLoading}
                            onClick={() => downloadCreatives("ampm-naming-download")}
                    >
                        {isLoading ? "Downloading..." : "Download"}
                    </Button>
                </Stack>
            </div>
            {creatives?.length !== 0 ? <Datatable data={creatives || []} columns={columns} headerColor={"brand.primary"} paginationDataCount={15}/> : <p style={{textAlign: 'center', marginTop:"360px", fontSize:"1.4rem"}}> Please add creatives to get detailed data </p>}
        </div>
    );
}

// Channel, Targeting Method, Location, Audience, Ad Name, File Name (Logic: Campaign Name - Channel - Targeting Method - Location - Audience - Promo - Device - Creative Type - Ad Name), Start Date, End Date, Creative Download, Task Name.

    export default AmPmNaming;
